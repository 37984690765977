import React from "react";
import { Grid, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import Text from "./Text";

const CardHeader = (props) => (
  <Paper className={`${props.className} card-header padding-24`}>
    <Grid container justifyContent="space-between" alignItems="center">
      <Text variant="h5" color="textSecondary">
        {props.text}
      </Text>
      {props.actionButton}
    </Grid>
  </Paper>
);

CardHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CardHeader;
