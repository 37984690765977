import React, { PureComponent } from "react";

class FooterActionBar extends PureComponent {
  render() {
    const { actions } = this.props;
    return <div className="footer-action-bar">{actions}</div>;
  }
}

export default FooterActionBar;
