import React from "react";
import { ReactComponent as Close } from "images/close.svg";
import { Link } from "react-router-dom";
import { CloseIcon, Description, Name, Offer, OfferButton } from "../styles";
import { useButtonStyles } from "../../../../styles";

const List = React.memo(({ removeHandler, offers }) => {
  const classes = useButtonStyles();

  return offers.map(
    ({ image_url, title, short_description, button_text, id, xlink_url }) => (
      <Offer key={id}>
        <img src={image_url} alt="" />

        <CloseIcon onClick={() => removeHandler(id)}>
          <Close />
        </CloseIcon>

        <Name>{title}</Name>

        <Description>{short_description}</Description>
        <Link
          to={xlink_url ? { pathname: xlink_url } : "/sign-in"}
          target={xlink_url ? "_blank" : ""}
        >
          <OfferButton className={classes.button}>{button_text}</OfferButton>
        </Link>
      </Offer>
    )
  );
});

export default List;
