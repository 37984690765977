import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import {
  createGenerateClassName,
  createTheme,
  CssBaseline,
  jssPreset,
  MuiThemeProvider,
} from "@material-ui/core";
import { JssProvider } from "react-jss";
import { create } from "jss";
import { useStore, persistor, history } from "./store/index";
import tapMuiTheme from "./mui/theme";
import App from "./components/containers/App";

import "./styles/application.scss";

const themeSelect = () => {
  return tapMuiTheme;
};

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = "jss-insertion-point";

const theme = createTheme(themeSelect());

const Root = () => {
  const store = useStore();

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </ConnectedRouter>
        </MuiThemeProvider>
      </Provider>
    </JssProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
