import React from "react";
import { ButtonInfo, ButtonInfoContainer, useStyles } from "./styles";
import { ReactComponent as Icon } from "../../../images/icons/iconInfo.svg";
import { checkLink } from "../../../assist/checkLink";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

// Tool tip on the staking page under the details button
// comes from the info component inside index.jsx List component

const Info = React.memo(({ text, arrow = "right", link, ...inputProps }) => {
  const classes = useStyles();

  return (
    <ButtonInfoContainer {...inputProps}>
      <StandardToolTip
        interactive
        title={
          link ? (
            <a target="example" href={checkLink(link)}>
              {link}
            </a>
          ) : (
            text
          )
        }
        placement={arrow}
        arrow
      />
    </ButtonInfoContainer>
  );
});

export default Info;
