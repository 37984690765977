import styled from "styled-components";

export const BackgroundImageWrapper = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .slick-track {
    display: flex;
    align-items: center;
  }

  @media (max-width: 480px) {
    & > * {
      max-height: 70vh;
    }
  }

  @media (max-width: 360px) {
    & > * {
      max-height: 80vh;
    }
  }
`;
