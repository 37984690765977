import React, { useState, useRef, useEffect } from "react";
import { PaperContainer, FlexRow } from "./styles";
import {
  makeStyles,
  Grid,
  Typography,
  Collapse,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "5px",
    backgroundColor: theme.palette.text.secondary,
  },
  accordian: {
    boxShadow: "none",
    "&:not(:last-child)": {
      border: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  accordianSummary: {
    padding: 0,
    margin: 0,
    "&.MuiAccordionSummary-root": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "auto",
    },
  },
  accordianDetails: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontFamily: "Work Sans",
      color: theme.palette.text.secondary,
    },
  },
  expandIcon: {
    padding: 0,
  },
  header: {
    paddingBottom: "20px",
  },
  expander: {
    cursor: "pointer",
    fontFamily: "Poppins",
    color: "#6e43e6",
  },
}));

const ProfilePaper = ({ title, children, expanding }) => {
  const ref = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const expandHandler = () => {
    setExpanded(!expanded);
  };

  const rowHeight = ref?.current?.children[0]?.children[0]?.clientHeight + 25;

  const classes = useStyles();
  return (
    <Grid container item xs={12}>
      <PaperContainer>
        {!!expanding ? (
          <>
            <Grid container justifyContent="space-between" className={classes.header}>
              <Typography variant="h5">{title}</Typography>
              <Typography className={classes.expander} onClick={expandHandler}>
                {!!expanded ? "View Less" : "View All"}
              </Typography>
            </Grid>
            <Collapse elevation={0} in={expanded} collapsedSize={rowHeight ?? 0}>
              <Grid
                container
                justifyContent="center"
                style={{ height: "min-content" }}
                ref={ref}
              >
                {children}
              </Grid>
            </Collapse>
          </>
        ) : (
          <>
            <Grid container justifyContent="flex-start" className={classes.header}>
              <Typography variant="h5">{title}</Typography>
            </Grid>

            <Grid container justifyContent="center" style={{ height: "100%" }} ref={ref}>
              {children}
            </Grid>
          </>
        )}
      </PaperContainer>
    </Grid>
  );
};

export default ProfilePaper;
