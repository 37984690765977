import styled from "styled-components";
import { button, Flex } from "../../../../styles";

export const StepWrapper = styled.div`
  max-width: 530px;
  background-color: #242731;
  border-radius: 16px;
  padding: 16px;
  margin: auto;
  font-family: "Public Sans", sans-serif;

  @media (max-width: 600px) {
    max-width: 85vw;
    padding: 8px;
  }
`;

export const GetLater = styled(button)`
  font-weight: 400;
  font-size: 14px;
  color: #808191;
  border-bottom: 1px solid #808191;
  background-color: inherit;
  position: absolute;
  right: 20px;
  bottom: 58px;
  cursor: pointer;
`;

export const StepActions = styled(Flex)`
  margin-top: 30px;
  padding: 8px;
  align-items: center;
  position: relative;
`;

export const StepCycle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "#AB99FF" : "#fff")};

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const StepCycleWrapper = styled(Flex)`
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`;

export const ImgWrapper = styled.div`
  background-color: #282d39;
  margin-bottom: 28px;
  margin-top: 5px;

  & img {
    border-radius: 8px;
    width: 100%;
  }
`;

export const Name = styled.div`
  text-align: center;
  width: 100%;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Message = styled.div`
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Address = styled.div`
  padding: 40px 50px 40px;

  text-align: center;

  & div {
    display: flex;
    justify-content: space-around;
    border: 1px solid #61646d;
    border-radius: 8px;
    padding: 13px 16px;
  }

  @media (max-width: 600px) {
    padding: 25px 0 25px;
    width: 80%;
    margin: auto;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const AddressWrapper = styled.div`
  width: 100%;
`;

export const AddressSpan = styled.span`
  color: darkgrey;
  font-style: italic;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;

  @media (max-width: 480px) {
    overflow: hidden;
    margin-right: 10px;
  }
`;
