import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { button } from "styles";

export const useStyles = makeStyles((theme) => ({
  menu: {
    backgroundColor: "#28292f !important",
    color: "#fff !important",
    top: "100px !important",
    right: "20px !important",
    left: "auto !important",

    "& > ul > li > span": {
      marginRight: "7px",
    },

    "@media(min-width: 1024px)": {
      display: "none",
    },

    "@media(max-width: 480px)": {
      top: "80px !important",
    },
  },
  mobile: {
    "@media(min-width: 480px) and (max-width: 1024px)": {
      display: "none !important",
    },
  },
  header: {
    backgroundColor: theme.palette.background.default,
    height: "80px",
    transition: "left .3s",
    top: "0",
    position: "fixed",
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#fff",
    zIndex: 3,

    "@media(max-width: 480px)": {
      height: "80px",
      justifyContent: (open) => (open ? "flex-end" : `space-between`),
      width: "100% !important",
      left: "0px !important",
    },
  },

  headerBurger: {
    marginLeft: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export const HeaderCurrency = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  margin-right: 38px;
  max-height: 70px;

  & > span {
    line-height: 20px;
    margin-right: 8px;
    min-width: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    margin-right: 10px;
  }
`;

export const HeaderCountdown = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CountdownWrapper = styled.div`
  border-radius: 8px;
  background-color: #6e43e6;
  padding: 5px 25px;
  max-height: 24px !important;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const ButtonInfo = styled(button)`
  height: 16px;
  width: 12px;
  background-color: #cc0000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 10px;
`;

export const HeaderBinance = styled.div`
  display: inline-flex;
  border-radius: 8px;
  background-color: #35373d;
  padding: 7px 25px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  margin-right: 29px;
  white-space: nowrap;

  & > svg {
    position: absolute;
    right: 8px;
    top: 10px;
    margin-left: 2px;
    fill: lightgreen;
    font-size: 13px;

    ${({ invalidNetwork }) =>
      invalidNetwork &&
      `
      fill: #cc0000;
    `}
  }

  ${({ invalidAddress }) =>
    invalidAddress &&
    `
    background-color: #ED4337;
  `}

  @media (max-width: 480px) {
    display: none;
  }
`;

export const Logo = styled.div`
  margin-left: 34px;
  margin-right: 64px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    margin-right: 30px;
    cursor: pointer;
  }
`;

export const SelectWrapper = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`;
