import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageHeader from "components/pageComponents/PageHeader";
import { ContentName, ContentWrapper } from "components/Marketplace/styles";
import { FlexGrid, ExpButton } from "components/ContentElements/styles";
import { connect as web3Connect } from "actions";
import ExchangeSettingsModalContainer from "../Modal/ExchangeSettingsModal";
import LiquidityModalContainer from "../Modal/LiquidityModal";
import Info from "./Swap/Info";
import Swap from "./Swap/Swap";
import TopPairs from "./Swap/TopPairs";

const Content = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openModalExchangeSettings, setOpenModalExchangeSettings] = useState(null);
  const [openModalLiquidity, setOpenModalLiquidity] = useState(null);
  const [message, setMessage] = useState(false);
  const [topPairs, setTopPairs] = useState(null);
  const web3 = useSelector((state) => state.web3);
  const dispatch = useDispatch();
  const history = useHistory();

  const rejectHandler = () => {
    setOpenModalExchangeSettings(null);
    setOpenModalLiquidity(null);
  };

  const settingsHandler = () => {
    setOpenModalExchangeSettings(true);
  };

  const liquidityHandler = (mode) => {
    setOpenModalLiquidity({ mode });
  };

  const connectHandler = () => {
    dispatch(web3Connect());
  };

  return (
    <ContentWrapper>
      <PageHeader h1="Game X Change" subtitle="Build your gaming wealth" />
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center">
          <FlexGrid>
            <ExpButton variant="contained" color="primary" disabled>
              Swap
            </ExpButton>
            <ExpButton
              variant="contained"
              color="primary"
              onClick={() => history.push("/xchange/liquidity")}
            >
              Liquidity
            </ExpButton>
          </FlexGrid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" spacing={4} alignItems="center">
        <Grid container justifyContent="center" spacing={3} xl={10} lg={11} xs={12}>
          <Grid item container lg={9} md={8} sm={12}>
            <Info />
          </Grid>
          <Grid item container lg={3} md={4} sm={12}>
            <Swap
              connected={web3.connected}
              badNetwork={web3.badNetwork}
              connectHandler={connectHandler}
              settingsHandler={settingsHandler}
              setTopPairs={setTopPairs}
            />
          </Grid>
          <Grid item container xs={12}>
            <TopPairs topPairs={topPairs} />
          </Grid>
        </Grid>
      </Grid>
      {!!openModalExchangeSettings && (
        <ExchangeSettingsModalContainer
          open={!!openModalExchangeSettings}
          rejectHandler={rejectHandler}
        />
      )}
      {!!openModalLiquidity && (
        <LiquidityModalContainer
          open={!!openModalLiquidity}
          message={message}
          rejectHandler={rejectHandler}
          web3={web3}
          mode={openModalLiquidity ? openModalLiquidity.mode : false}
        />
      )}
    </ContentWrapper>
  );
};

export default Content;
