import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const RedirectButton = ({ children }) => {
  const user = useSelector((state) => state.user);

  if (!user.username) {
    return <Link to="/sign-in">{children(user)}</Link>;
  }

  return <>{children(user)}</>;
};

export default RedirectButton;
