import styled from "styled-components";

export const ButtonsNextWrapper = styled.div`
  display: flex;
  width: 200%;

  @media (max-width: 600px) {
    flex-direction: column;

    & > * {
      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
`;

export const DisabledWrapper = styled.div`
  display: flex;
  width: 75%;
  margin: 20px auto -20px auto;

  @media (max-width: 600px) {
    flex-direction: column;

    & > * {
      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
`;
