import {
  ERROR_LOADING_LOGIN_GAMES,
  FINISH_LOADING_LOGIN_GAMES_LIST,
  POPULATE_LOGIN_GAMES_LIST,
  POPULATE_LOGIN_GAMES_LIST_IDS,
  REQUEST_LOGIN_GAMES_LIST,
} from "./types";

export const initialState = {
  gamesList: {
    steamIds: [],
    games: [],
    error: false,
    errors: [],
    isLoading: false,
  },
};

const LoginGamesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN_GAMES_LIST:
      return {
        ...state,
        gamesList: { ...state.gamesList, isLoading: true },
      };

    case POPULATE_LOGIN_GAMES_LIST_IDS:
      return {
        ...state,
        gamesList: {
          ...state.gamesList,
          steamIds: action.data,
        },
      };

    case POPULATE_LOGIN_GAMES_LIST: {
      const gameListAttributes = action.data;

      const games = gameListAttributes.map((game) => ({
        steamGameId: game.steam_game_id,
        title: game.title,
        imageUrl: game.image_url,
        genres: game.genres,
      }));

      return {
        ...state,
        gamesList: {
          ...state.gamesList,
          games,
        },
      };
    }
    case ERROR_LOADING_LOGIN_GAMES:
      return {
        ...state,
        gamesList: {
          ...state.gamesList,
          error: true,
          errors: [...state.gamesList.errors, action.data],
        },
      };

    case FINISH_LOADING_LOGIN_GAMES_LIST:
      return {
        ...state,
        gamesList: {
          ...state.gamesList,
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default LoginGamesListReducer;
