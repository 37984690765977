import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import Menu from "./Menu/Menu";
import { useStyles } from "./NavBar.styles";

const NavBar = React.memo(() => {
  const nav = useSelector((state) => state.nav);
  const classes = useStyles({ open: nav.open });

  return (
    <nav className={classes.drawerContainer}>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: nav.open,
            [classes.drawerClose]: !nav.open,
          }),
        }}
      >
        <Menu />
      </Drawer>
    </nav>
  );
});

export default NavBar;
