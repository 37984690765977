import JSBI from "jsbi";
import Fraction from "./fraction";

const _100_PERCENT = new Fraction(JSBI.BigInt(100));

export default class Percent extends Fraction {
  toSignificant(significantDigits = 5, format, rounding) {
    return this.multiply(_100_PERCENT).toSignificant(significantDigits, format, rounding);
  }

  toFixed(decimalPlaces = 2, format, rounding) {
    return this.multiply(_100_PERCENT).toFixed(decimalPlaces, format, rounding);
  }
}
