import styled from "styled-components";
import { button } from "styles";
import Button from "@material-ui/core/Button";

export const HeaderButton = styled(button)`
  padding: 10px 16px;
  background-color: #7749f8;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  border-radius: 4px;
  align-self: flex-start;
  height: 25px;

  &:disabled {
    background-color: #483d8b;
    cursor: default;
    &:hover {
      background-color: #483d8b;
    }
  }

  @media (max-width: 480px) {
    width: 75%;
    font-size: 14px;
    padding: 7px 10px;
  }

  &:hover {
    background-color: #6053bb;
  }
`;

export const DarkButton = styled(HeaderButton)`
  background-color: #343a40;

  @media (max-width: 480px) {
    min-width: 60px;
  }

  &:hover {
    background-color: #2d3338;
  }
`;

export const SteamButton = styled(Button)`
  box-sizing: border-box;
  padding: 18px 23px;
  color: #ffffff;
  background: linear-gradient(0.25turn, #5d8628, 25%, #416527);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 15px !important;
  line-height: 20px;
  height: 46px;

  @media (max-width: 480px) {
    width: 75%;
    height: 39px;
    font-size: 14px !important;
  }

  & span {
    text-decoration: none !important;
    padding-right: 50px !important;
  }

  &:hover {
    text-decoration: none !important;
  }
`;

export const SteamIcon = styled.img`
  position: absolute;
  bottom: 19px;
  transform: translateY(50%);
  right: 14px;
  cursor: pointer;
`;
