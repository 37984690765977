import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageHeader from "components/pageComponents/PageHeader";
import { ContentName, ContentWrapper } from "components/Marketplace/styles";
import { getPairsForLiquidity } from "utils/web3/exchange";
import { FlexGrid, ExpButton } from "components/ContentElements/styles";
import { connect as web3Connect } from "actions";
import Liquidity from "./Liquidity";
//import ExchangeSettingsModalContainer from "../Modal/ExchangeSettingsModal";
//import LiquidityModalContainer from "../Modal/LiquidityModal";

const Content = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openModalExchangeSettings, setOpenModalExchangeSettings] = useState(null);
  const [openModalLiquidity, setOpenModalLiquidity] = useState(null);
  const [message, setMessage] = useState(false);
  const userPairs = useSelector((state) => state.ExchangeData.liquidityPairs);
  const web3 = useSelector((state) => state.web3);
  const exchange = useSelector((state) => state.Exchange);
  const dispatch = useDispatch();
  const history = useHistory();

  const rejectHandler = () => {
    setOpenModalExchangeSettings(null);
    setOpenModalLiquidity(null);
  };

  const settingsHandler = () => {
    setOpenModalExchangeSettings(true);
  };

  const liquidityHandler = (mode) => {
    setOpenModalLiquidity({ mode });
  };

  const connectHandler = () => {
    dispatch(web3Connect());
  };

  useEffect(() => {
    if (web3.connected && web3.chainId) {
      dispatch(getPairsForLiquidity());
    }
  }, [web3.connected, web3.chainId]);

  return (
    <ContentWrapper>
      <PageHeader h1="Game X Change" subtitle="Build your gaming wealth" />
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center">
          <FlexGrid>
            <ExpButton
              variant="contained"
              color="primary"
              onClick={() => history.push("/xchange")}
            >
              Swap
            </ExpButton>
            <ExpButton variant="contained" color="primary" disabled>
              Liquidity
            </ExpButton>
          </FlexGrid>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-start" spacing={4} alignItems="flex-start">
        <Grid container spacing={3}>
          <Grid item container xs={12} justifyContent="center">
            <Liquidity
              userPairs={userPairs}
              web3={web3}
              connectHandler={connectHandler}
              settingsHandler={settingsHandler}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default Content;
