import {
  ZERO_ADDRESS,
  TOKEN_INFO,
  Exchanges,
  Routers,
  formatToChain,
  getTokenFromAddressByChain,
  WRAPPER_NATIVE,
  exchangeTokenList,
} from "blockchain/tokenInfo";
import {
  getWeb3,
  getContract,
  getContractInstance,
  selectedAddress,
  getAllowance,
  getBalanceOf,
  getTotalSupplyOf,
  currentNetwork,
} from "utils/web3Calls";
import { BN, getDisplayVal, getExchangeTokens, toBaseUnit } from "blockchain/web3Utils";
import { Interface } from "@ethersproject/abi";
import flatMap from "lodash/flatMap";
import { WEB3_ALLOWANCE_SET } from "reducers/redux/Web3/Allowance/types";
import { WEB3_BALANCE_SET } from "reducers/redux/Web3/Balances/types";
import { Bases } from "utils/constants";
import {
  EXCHANGE_SET_PAIRS,
  EXCHANGE_SET_CURRENCIES,
} from "reducers/redux/Exchange/types";
import JSBI from "jsbi";
import { getCurrencyChainPrice } from "utils/apiRequest";
import { performMultiCall } from "./index";
import Pair from "./models/pair";
import Percent from "./models/percent";
import Trade from "./models/trade";
import TokenAmount from "./models/tokenAmount";
import Token, { currencyEquals } from "./models/token";

export const MAX_HOPS = 3;
export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
  JSBI.BigInt(50),
  JSBI.BigInt(10000)
);

export const getBases = (chainId) => {
  const bases = Bases[chainId].map((item) => {
    return formatToChain(item, chainId);
  });
  return bases;
};

export const getPairsData = async (addresses) => {
  const pair = await getContract("SwapPair");
  const iface = new Interface(pair.abi);
  const callData = iface.encodeFunctionData("getReserves");
  return performMultiCall("getReserves", iface, addresses, callData);
};

export function isTradeBetter(tradeA, tradeB, minimumDelta = ZERO_PERCENT) {
  if (tradeA && !tradeB) return false;
  if (tradeB && !tradeA) return true;
  if (!tradeA || !tradeB) return undefined;

  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeB.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error("Trades are not comparable");
  }

  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    return tradeA.executionPrice.lessThan(tradeB.executionPrice);
  }
  return tradeA.executionPrice
    .raw()
    .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
    .lessThan(tradeB.executionPrice);
}

export const getUserLiquidity = async (addresses, chainId) => {
  const pair = await getContract("SwapPair");
  const iface = new Interface(pair.abi);
  const balance = performMultiCall(
    "balanceOf",
    iface,
    addresses,
    iface.encodeFunctionData("balanceOf", [selectedAddress()])
  );
  const reserve = performMultiCall(
    "getReserves",
    iface,
    addresses,
    iface.encodeFunctionData("getReserves")
  );
  const total = performMultiCall(
    "totalSupply",
    iface,
    addresses,
    iface.encodeFunctionData("totalSupply")
  );
  const [totals, reserves, balances] = await Promise.all([total, reserve, balance]);
  return { balances, reserves, totals };
};

export const getSinglePair = async (tokenA, tokenB) => {
  const { chainId } = tokenA;
  let tempTokenA = tokenA;
  let tempTokenB = tokenB;
  if (tokenA.address === ZERO_ADDRESS) {
    tempTokenA = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  } else if (tokenB.address === ZERO_ADDRESS) {
    tempTokenB = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  }
  const reversePair = tokenB.sortsBefore(tokenA);
  const reverseValue = tempTokenB.sortsBefore(tempTokenA);
  const pairAddress = Pair.getAddress(tempTokenA, tempTokenB, Exchanges[chainId]);
  const LPToken = new Token(chainId, pairAddress, 18, "GXC-LP", "Game Exchange LP");
  let tokenAmountA;
  let tokenAmountB;
  let balance = new TokenAmount(LPToken, "0");

  try {
    const pair = await getContractInstance("SwapPair", pairAddress);
    const result = await pair.getReserves();
    balance = await pair.totalSupply();
    balance = new TokenAmount(LPToken, balance);
    tokenAmountA = new TokenAmount(tokenA, result[reverseValue ? 1 : 0]);
    tokenAmountB = new TokenAmount(tokenB, result[reverseValue ? 0 : 1]);
  } catch (e) {
    tokenAmountA = new TokenAmount(tokenA, "0");
    tokenAmountB = new TokenAmount(tokenB, "0");
  }

  const tokenPair = new Pair(tokenAmountA, tokenAmountB, Exchanges[chainId]);
  tokenPair.reversePair = reversePair;
  if (reversePair) {
    if (tokenB.address === ZERO_ADDRESS) {
      tokenPair.token0.native = true;
    } else if (tokenA.address === ZERO_ADDRESS) {
      tokenPair.token1.native = true;
    }
  } else if (tokenA.address === ZERO_ADDRESS) {
    tokenPair.token0.native = true;
  } else if (tokenB.address === ZERO_ADDRESS) {
    tokenPair.token1.native = true;
  }
  tokenPair.balance = balance;

  return tokenPair;
};

export const getSinglePairByAddresses = async (addressA, addressB) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  const unknownTokenAddresses = {};
  const knownTokens = {};

  try {
    knownTokens[addressA] = formatToChain(
      getTokenFromAddressByChain(addressA, chainId),
      chainId
    );
  } catch {
    unknownTokenAddresses[addressA] = true;
  }
  try {
    knownTokens[addressB] = formatToChain(getTokenFromAddressByChain(addressB), chainId);
  } catch {
    unknownTokenAddresses[addressB] = true;
  }
  const fetchedTokens = await getExchangeTokens(
    Object.keys(unknownTokenAddresses),
    chainId
  );
  Object.keys(unknownTokenAddresses).forEach((address, index) => {
    knownTokens[address] = fetchedTokens[index];
  });

  const tokenA = knownTokens[addressA];
  const tokenB = knownTokens[addressB];

  const pairAddress = Pair.getAddress(tokenA, tokenB, Exchanges[chainId]);
  const total = await getTotalSupplyOf(pairAddress);

  let tokenAmountA;
  let tokenAmountB;
  try {
    const pair = await getContractInstance("SwapPair", pairAddress);
    const result = await pair.getReserves();
    tokenAmountA = new TokenAmount(tokenA, result[0]);
    tokenAmountB = new TokenAmount(tokenB, result[1]);
  } catch (e) {
    tokenAmountA = new TokenAmount(tokenA, "0");
    tokenAmountB = new TokenAmount(tokenB, "0");
  }
  const tokenPair = new Pair(tokenAmountA, tokenAmountB, Exchanges[chainId]);
  tokenPair.reversePair = tokenB.sortsBefore(tokenA);
  tokenPair.totalSupply = new TokenAmount(tokenPair.liquidityToken, total);
  return tokenPair;
};

export const addLiquidity = async (pair, amounts, slippage) => {
  const web3 = getWeb3();
  const { token0, token1 } = pair;
  const { chainId } = token0;

  const router = await getContractInstance("SwapRouter", Routers[chainId]);
  const tax = await router.tax();
  const lastBlock = await web3.eth.getBlockNumber();
  const deadline = (await web3.eth.getBlock(lastBlock)).timestamp + 10000000;

  const t0 = toBaseUnit(amounts[0].toString(), token0.decimals);
  const t1 = toBaseUnit(amounts[1].toString(), token1.decimals);
  const t0SlippageFee = t0.mul(new BN(slippage)).div(new BN(10000));
  const t1SlippageFee = t1.mul(new BN(slippage)).div(new BN(10000));
  const t0AfterSlippage = t0.sub(t0SlippageFee);
  const t1AfterSlippage = t1.sub(t1SlippageFee);

  const from = selectedAddress();
  let res;
  if (token0.address === ZERO_ADDRESS) {
    res = router.addLiquidityETH(
      token1.address,
      t1,
      // slippage for if price ratio changes
      t1AfterSlippage,
      t0AfterSlippage,
      from,
      deadline,
      {
        from,
        value: t0.add(tax),
      }
    );
  } else {
    res = router.addLiquidity(
      token0.address,
      token1.address,
      t0,
      t1,
      // slippage for if price ratio changes
      t0AfterSlippage,
      t1AfterSlippage,
      from,
      deadline,
      { from, value: tax }
    );
  }
  /*
    const realAddressA =
      token0.address === ZERO_ADDRESS
        ? TOKEN_INFO[WRAPPER_NATIVE[chainId]].addresses[chainId]
        : token0.address;*/
  return new Promise((resolve, reject) => {
    res
      .on("transactionHash", (hash) => {
        resolve(hash);
      })
      .on("error", (error) => {
        reject(error);
      });
  });
};

export const exchangeTokens = async (trade, fromToken, toToken, slippage) => {
  const web3 = getWeb3();
  const { inputAmount, outputAmount } = trade;
  const inToken = inputAmount.token;
  const outToken = outputAmount.token;

  const outputBeforeSlippage = toBaseUnit(outputAmount.toExact(), outToken.decimals);
  const slippageFee = outputBeforeSlippage.mul(new BN(slippage)).div(new BN(10000));
  const outputAfterSlippage = outputBeforeSlippage.sub(slippageFee);

  const inAmount = toBaseUnit(inputAmount.toExact(), inToken.decimals);

  const { chainId } = inToken;

  const realPath = trade.route.path.map((token) => {
    return token.address;
  });

  const [router, lastBlock] = await Promise.all([
    getContractInstance("SwapRouter", Routers[chainId]),
    web3.eth.getBlockNumber(),
  ]);
  const deadline = (await web3.eth.getBlock(lastBlock)).timestamp + 10000000;
  const tax = await router.tax();

  const from = selectedAddress();
  let res;
  if (fromToken.address === ZERO_ADDRESS) {
    res = router.swapExactETHForTokensSupportingFeeOnTransferTokens(
      outputAfterSlippage,
      realPath,
      from,
      deadline,
      {
        from,
        value: inAmount.add(tax),
      }
    );
  } else if (toToken.address === ZERO_ADDRESS) {
    const afterTax = outputAfterSlippage.sub(tax);
    res = router.swapExactTokensForETHSupportingFeeOnTransferTokens(
      inAmount,
      afterTax,
      realPath,
      from,
      deadline,
      { from }
    );
  } else {
    res = router.swapExactTokensForTokensSupportingFeeOnTransferTokens(
      inAmount,
      outputAfterSlippage,
      realPath,
      from,
      deadline,
      { from, value: tax }
    );
  }
  return new Promise((resolve, reject) => {
    res
      .on("transactionHash", (hash) => {
        resolve(hash);
      })
      .on("error", (error) => {
        reject(error);
      });
  });
};

export const removeLiquidity = async (pair, amount, options) => {
  const web3 = getWeb3();
  const BNBalance = toBaseUnit(amount.toString(), pair.liquidityToken.decimals);
  const withdrawalBalance = BNBalance.mul(new BN(options.percentage))
    .div(new BN(100))
    .toString();
  const minA = "0";
  const minB = "0";

  const { chainId } = pair.liquidityToken;
  const router = await getContractInstance("SwapRouter", Routers[chainId]);
  const tax = await router.tax();
  const lastBlock = await web3.eth.getBlockNumber();
  const deadline = (await web3.eth.getBlock(lastBlock)).timestamp + 10000000;

  const nativeToken = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  let promise;
  if (!options.wrapped && !pair.involvesToken(nativeToken)) {
    promise = router.removeLiquidity(
      pair.token0.address,
      pair.token1.address,
      withdrawalBalance,
      minA,
      minB,
      selectedAddress(),
      deadline,
      { from: selectedAddress(), value: tax }
    );
  } else {
    promise = router.removeLiquidityETHSupportingFeeOnTransferTokens(
      pair.token0.equals(nativeToken) ? pair.token1.address : pair.token0.address,
      withdrawalBalance,
      minA,
      minB,
      selectedAddress(),
      deadline,
      { from: selectedAddress(), value: tax }
    );
  }
  return new Promise((resolve, reject) => {
    promise
      .on("transactionHash", (hash) => {
        resolve(hash);
      })
      .on("error", (error) => {
        reject(error);
      });
  });
};

export const getTradeBalances = (tokenA, tokenB) => async (dispatch) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  const allowanceTo = Routers[chainId];
  const [balance0, balance1, allowance0, allowance1] = await Promise.all([
    getBalanceOf(tokenA.address),
    getBalanceOf(tokenB.address),
    getAllowance(tokenA.address, allowanceTo),
    getAllowance(tokenB.address, allowanceTo),
  ]);
  dispatch({
    type: WEB3_BALANCE_SET,
    contract: tokenA.address,
    balance: balance0.toString(),
  });
  dispatch({
    type: WEB3_BALANCE_SET,
    contract: tokenB.address,
    balance: balance1.toString(),
  });
  dispatch({
    type: WEB3_ALLOWANCE_SET,
    contract: tokenA.address,
    allowance: allowance0.toString(),
    toContract: allowanceTo,
  });
  dispatch({
    type: WEB3_ALLOWANCE_SET,
    contract: tokenB.address,
    allowance: allowance1.toString(),
    toContract: allowanceTo,
  });
};

export const getPairsForTrade = async (tokenA, tokenB) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  let tempTokenA = tokenA;
  let tempTokenB = tokenB;
  if (tokenA.address === ZERO_ADDRESS) {
    tempTokenA = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  } else if (tokenB.address === ZERO_ADDRESS) {
    tempTokenB = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  }

  const bases = getBases(chainId);
  const basePairs = flatMap(bases, (base) => bases.map((otherBase) => [base, otherBase]));
  const allPairCombinations = [
    // the direct pair
    [tempTokenA, tempTokenB],
    // token A against all bases
    ...bases.map((base) => [tempTokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tempTokenB, base]),
    // each base against all bases
    ...basePairs,
  ]
    .filter((tokens) => Boolean(tokens[0] && tokens[1]))
    .filter(([t0, t1]) => t0.address !== t1.address);
  let pairs = [];
  const pairAddresses = allPairCombinations.map(([token1, token2]) => {
    return Pair.getAddress(token1, token2, Exchanges[chainId]);
  });

  const reserves = await getPairsData(pairAddresses, chainId);
  pairs = allPairCombinations
    .map(([token1, token2], index) => {
      const order = token1.address.toLowerCase() < token2.address.toLowerCase();
      if (reserves[index]) {
        const tokenAmountA = new TokenAmount(token1, reserves[index][order ? 0 : 1]);
        const tokenAmountB = new TokenAmount(token2, reserves[index][order ? 1 : 0]);
        return new Pair(tokenAmountA, tokenAmountB, Exchanges[chainId]);
      }
      return false;
    })
    .filter((val) => val);
  return pairs;
  // const allPairs = usePairs(allPairCombinations);
};

export const getTradeExactIn = (trade, amount) => {
  const bnAmount = toBaseUnit(amount.toString(), trade.inputAmount.token.decimals);
  const newInAmount = new TokenAmount(trade.inputAmount.token, bnAmount.toString());
  const bestTradeSoFar = new Trade(trade.route, newInAmount, trade.tradeType);
  const fee = new TokenAmount(
    bestTradeSoFar.outputAmount.token,
    bestTradeSoFar.outputAmount.divToBI(125).raw
  );
  if (trade.fromEth) {
    bestTradeSoFar.fromEth = true;
  } else if (trade.toEth) {
    bestTradeSoFar.toEth = true;
  }
  bestTradeSoFar.outputAmount = bestTradeSoFar.outputAmount.subtract(fee);
  return bestTradeSoFar;
};

export const makeTradeExactIn = (
  amountIn,
  currencyIn,
  currencyOut,
  allowedPairs,
  singleHopOnly = false
) => {
  const { chainId } = currencyIn;
  const nativeToken = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  let realCurrencyIn = currencyIn;
  let realCurrencyOut = currencyOut;

  if (currencyIn.address === ZERO_ADDRESS) {
    realCurrencyIn = nativeToken;
  } else if (currencyOut.address === ZERO_ADDRESS) {
    realCurrencyOut = nativeToken;
  }

  const currencyAmountIn = new TokenAmount(
    realCurrencyIn,
    toBaseUnit(amountIn.toString(), realCurrencyIn.decimals)
  );

  if (currencyAmountIn && realCurrencyOut && allowedPairs.length > 0) {
    if (singleHopOnly) {
      return (
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, realCurrencyOut, {
          maxHops: 1,
          maxNumResults: 1,
        })[0] ?? null
      );
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar = null;
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade =
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, realCurrencyOut, {
          maxHops: i,
          maxNumResults: 1,
        })[0] ?? null;
      // if current trade is best yet, save it
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade;
      }
    }
    if (bestTradeSoFar) {
      const fee = new TokenAmount(
        bestTradeSoFar.outputAmount.token,
        bestTradeSoFar.outputAmount.divToBI(125).raw
      );
      if (currencyIn.address === ZERO_ADDRESS) {
        bestTradeSoFar.fromEth = true;
      } else if (currencyOut.address === ZERO_ADDRESS) {
        bestTradeSoFar.toEth = true;
      }
      bestTradeSoFar.outputAmount = bestTradeSoFar.outputAmount.subtract(fee);
    }
    return bestTradeSoFar;
  }

  return null;
};

export const getTradeExactOut = (trade, toAmount) => {
  const bnAmount = toBaseUnit(toAmount.toString(), trade.outputAmount.token.decimals);
  const newOutAmount = new TokenAmount(trade.outputAmount.token, bnAmount.toString());
  const bestTradeSoFar = new Trade(trade.route, newOutAmount, trade.tradeType);
  const fee = new TokenAmount(
    bestTradeSoFar.inputAmount.token,
    bestTradeSoFar.inputAmount.divToBI(125).raw
  );
  if (trade.fromEth) {
    bestTradeSoFar.fromEth = true;
  } else if (trade.toEth) {
    bestTradeSoFar.toEth = true;
  }
  bestTradeSoFar.inputAmount = bestTradeSoFar.inputAmount.add(fee);
  return bestTradeSoFar;
};

export const makeTradeExactOut = (
  amountOut,
  currencyIn,
  currencyOut,
  allowedPairs,
  singleHopOnly = false
) => {
  const { chainId } = currencyIn;
  const nativeToken = formatToChain(WRAPPER_NATIVE[chainId], chainId);
  let realCurrencyIn = currencyIn;
  let realCurrencyOut = currencyOut;
  if (currencyIn.address === ZERO_ADDRESS) {
    realCurrencyIn = nativeToken;
  } else if (currencyOut.address === ZERO_ADDRESS) {
    realCurrencyOut = nativeToken;
  }

  const currencyAmountOut = new TokenAmount(
    realCurrencyOut,
    toBaseUnit(amountOut.toString(), realCurrencyOut.decimals)
  );
  // console.log(currencyAmountOut);
  if (realCurrencyIn && currencyAmountOut && allowedPairs.length > 0) {
    if (singleHopOnly) {
      return (
        Trade.bestTradeExactOut(allowedPairs, realCurrencyIn, currencyAmountOut, {
          maxHops: 1,
          maxNumResults: 1,
        })[0] ?? null
      );
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar = null;
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade =
        Trade.bestTradeExactOut(allowedPairs, realCurrencyIn, currencyAmountOut, {
          maxHops: i,
          maxNumResults: 1,
        })[0] ?? null;
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade;
      }
    }
    if (bestTradeSoFar) {
      const fee = new TokenAmount(
        bestTradeSoFar.inputAmount.token,
        bestTradeSoFar.inputAmount.divToBI(125).raw
      );
      if (currencyIn.address === ZERO_ADDRESS) {
        bestTradeSoFar.fromEth = true;
      } else if (currencyOut.address === ZERO_ADDRESS) {
        bestTradeSoFar.toEth = true;
      }
      bestTradeSoFar.inputAmount = bestTradeSoFar.inputAmount.add(fee);
    }
    return bestTradeSoFar;
  }
  return null;
};

export const getPairsForLiquidity = () => async (dispatch) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  const router = await getContractInstance("SwapRouter", Routers[chainId]);
  const pageInfo = await router.getUserPairs(selectedAddress(), 0, 200);
  const pairs = pageInfo[0];

  const unknownTokenAddresses = {};
  const knownTokens = {};
  const pairAddresses = pairs.map(({ tokenA, tokenB }) => {
    try {
      knownTokens[tokenA] = formatToChain(
        getTokenFromAddressByChain(tokenA, chainId),
        chainId
      );
    } catch {
      unknownTokenAddresses[tokenA] = true;
    }
    try {
      knownTokens[tokenB] = formatToChain(getTokenFromAddressByChain(tokenB), chainId);
    } catch {
      unknownTokenAddresses[tokenB] = true;
    }
    return Pair.getAddressFromAddress(tokenA, tokenB, Exchanges[chainId]);
  });

  const [fetchedTokens, info] = await Promise.all([
    getExchangeTokens(Object.keys(unknownTokenAddresses), chainId),
    getUserLiquidity(pairAddresses, chainId),
  ]);
  Object.keys(unknownTokenAddresses).forEach((address, index) => {
    knownTokens[address] = fetchedTokens[index];
  });

  const formattedPairs = pairs.map((pair, index) => {
    // pairAddresses[index]
    const reversed = pair[0].toLowerCase() > pair[1].toLowerCase();
    const tokenAmountA = new TokenAmount(
      knownTokens[pair[0]],
      reversed ? info.reserves[index][1] : info.reserves[index][0]
    );

    const tokenAmountB = new TokenAmount(
      knownTokens[pair[1]],
      reversed ? info.reserves[index][0] : info.reserves[index][1]
    );

    const tokenPair = new Pair(tokenAmountA, tokenAmountB, Exchanges[chainId]);
    tokenPair.balance = getDisplayVal(info.balances[index].toString(), 18);
    tokenPair.totalSupply = getDisplayVal(info.totals[index].toString(), 18);
    tokenPair.address = pairAddresses[index];
    return tokenPair;
  });
  const filtered = formattedPairs.filter((pair) => pair.balance > 0);
  dispatch({ type: EXCHANGE_SET_PAIRS, pairs: filtered });
  return filtered;
};

export const getExchangeAllowance = (tokenName) => async (dispatch) => {
  const chainId = await currentNetwork();
  const token = TOKEN_INFO[tokenName].addresses[chainId];
  const conversionAddress = Routers[chainId];
  const allowance = (await getAllowance(token, conversionAddress)).toString();
  const balance = (await getBalanceOf(token)).toString();
  dispatch({
    type: WEB3_ALLOWANCE_SET,
    contract: token,
    toContract: conversionAddress,
    allowance,
  });
  dispatch({
    type: WEB3_BALANCE_SET,
    contract: token,
    balance,
  });
};

export const getCurrencyBySymbol = (symbol) => {
  return Object.keys(TOKEN_INFO).find((key) => TOKEN_INFO[key].symbol === symbol);
};

export const getCurrencyByAddress = (searchAddress) => {
  return Object.keys(TOKEN_INFO).find((key) => {
    for (const [, address] of Object.entries(TOKEN_INFO[key].addresses)) {
      if (searchAddress.toLowerCase() == address.toLowerCase()) {
        return key;
      }
    }
  });
};

export const getCurrenciesWithPrices = () => async (dispatch) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  const tokenList = exchangeTokenList(chainId);
  const newTokenList = {};
  const promises = [];

  tokenList.map((currency, index) => {
    promises[index] = getCurrencyChainPrice({
      chain_id: chainId,
      chain_address:
        currency.address == ZERO_ADDRESS
          ? formatToChain(WRAPPER_NATIVE[chainId], chainId).address
          : currency?.address,
    })
      .then((result) => result)
      .catch((error) => ({ error }));
  });

  Promise.all(promises).then((response) => {
    for (let index = 0; index < promises.length; index += 1) {
      const usdPrice = response[index]?.data.usd_price;
      const { symbol } = tokenList[index];
      tokenList[index].usdPrice = usdPrice;
      newTokenList[symbol] = tokenList[index];
    }
    if (Object.keys(newTokenList).length > 0) {
      dispatch({ type: EXCHANGE_SET_CURRENCIES, currencies: newTokenList });
    }
  });
};

export const getTopPairs = async (pairs, pricedCurrencies) => {
  const liquidityPairs = [];
  pairs.map(async (pair, index) => {
    const { token0 } = pair;
    const { token1 } = pair;
    const fixed0 = parseFloat(pair?.reserve0?.toFixed()); //Amount of tokens for tokenA
    const fixed1 = parseFloat(pair?.reserve1?.toFixed()); //Amount of tokens for tokenB
    const symbol0 = token0.symbol;
    const symbol1 = token1.symbol;
    if (pricedCurrencies[symbol0] && pricedCurrencies[symbol1]) {
      const token0USD = pricedCurrencies[symbol0].usdPrice;
      const token1USD = pricedCurrencies[symbol1].usdPrice;
      const token0PoolValue = Math.round(fixed0 * token0USD);
      const token1PoolValue = Math.round(fixed1 * token1USD);
      const liquidityPoolValue = token0PoolValue + token1PoolValue;

      liquidityPairs[index] = {
        name: `${symbol0}-${symbol1}`,
        liquidity: liquidityPoolValue,
        tokenA: token0USD,
        tokenASymbol: symbol0,
        tokenB: token1USD,
        tokenBSymbol: symbol1,
      };
    } else {
      liquidityPairs[index] = {
        name: `${symbol0}-${symbol1}`,
        liquidity: "???",
        tokenA: 0,
        tokenASymbol: symbol0,
        tokenB: 0,
        tokenBSymbol: symbol1,
      };
    }
  });

  // Sort by liquidity descending
  const sortedPairs = liquidityPairs.sort(function (a, b) {
    return b.liquidity - a.liquidity;
  });

  // Filter out all non-unique pairs
  var uniquePairs = [sortedPairs[0]];
  for (var i = 1; i < sortedPairs.length; i++) {
    if (sortedPairs[i - 1].name !== sortedPairs[i].name) {
      uniquePairs.push(sortedPairs[i]);
    }
  }

  return uniquePairs;
};
