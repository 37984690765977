import styled from "styled-components";
import breakpoints from "utils/breakpoints";
import { Typography } from "@material-ui/core";
import { DashboardContentHeaderText } from "../styles";
import { button, Flex } from "../../../styles";

export const AchievementContentHeaderText = styled(DashboardContentHeaderText)`
  margin-bottom: 15px;
  position: relative;
`;

export const Category = styled(DashboardContentHeaderText)`
  margin-bottom: 19px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 12px;
  }
`;

export const NftButton = styled(button)`
  padding: 10px 16px;
  background-color: #7749f8;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  width: 100%;

  @media (max-width: 480px) {
    font-size: 16px;
  }

  @media (max-width: 330px) {
    padding: 10px 10px;
  }

  &:disabled {
    background-color: #483d8b;
    cursor: default;
  }
`;

export const NftDarkButton = styled.div`
  width: 100%;

  & > button {
    width: 100%;
    background-color: #343a40;

    &:hover {
      background-color: #2c3136;
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const ActionsButton = styled(NftButton)`
  background-color: ${({ bgc }) => (bgc === "dark" ? "#343A40" : "#7749F8")};

  &:hover {
    background-color: ${({ bgc }) => (bgc === "dark" ? "#2d3338" : "#7749F8")};
  }

  &:disabled {
    background-color: #2d3338;
  }
`;

export const AchievementContentHeaderActions = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 480px) {
    justify-content: flex-start;
  }

  & > *:first-child {
    margin-right: 14px;
  }
`;

export const NftText = styled(Typography)`
  line-height: 24px !important;
`;

export const NftDesc = styled.span`
  font-size: 14px;
  font-family: "Work Sans";
  color: #808191;
  overflow: hidden;
`;

export const NftBold = styled(NftText)`
  font-weight: 600 !important;
`;

export const NftCount = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NftActions = styled(Flex)`
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 5px 20px 5px 20px;

  @media (max-width: 480px) {
    flex-direction: column;
  }

  & > * {
    margin-bottom: 5px;
  }
  & > Button {
    margin-bottom: 5px;
    margin-right: 15px;
    width: max-content;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;
export const NftImg = styled.img`
  border-radius: 5px;
`;

export const NftTextBox = styled.div`
  padding: 5px 20px 5px 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: ${({ wide }) => (wide ? "50%" : "100%")};
  & > * {
    padding-top: 5px;
  }
`;

export const NftItem = styled.div`
  display: flex;
  justify-content: "flex-end";
  flex-direction: ${({ wide }) => (wide ? "row" : "column")};
  width: ${({ wide }) => (wide ? "" : "100%")};
  height: ${({ wide }) => (wide ? "210px" : "100%")};
  box-sizing: border-box;
  color: white;
  margin: ${({ wide }) => (wide ? "0 20px 20px 0" : "0")};
  border-radius: 25px;
  background-color: #12161f !important;
  box-shadow: 4px 2px 10px 3px rgb(0 0 0 / 30%), 8px 0px 8px 0px rgb(0 0 0 / 14%),
    12px 0px 12px 0px rgb(0 0 0 / 12%) !important;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  @media (max-width: ${breakpoints.lg}px) {
    margin: ${({ wide }) => (wide ? "0 0 20px 0" : "0")};
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const NftWrapper = styled.div`
  position: relative;
  text-align: center;
  width: ${({ wide }) => (wide ? "50%" : "100%")};
  > * {
    width: 100%;
    height: ${({ wide }) => (wide ? "100%" : "220px")};
    object-fit: cover;
    border-radius: ${({ wide }) => (wide ? "25px 0px 0px 25px" : "25px 25px 0px 0px")};
  }
`;

export const NftBadge = styled.div`
  text-align: center;
  background-color: #484282;
  max-width: max-content;
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  padding: 3px 20px 3px 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
`;
