import styled from "styled-components";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const InputFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:after {
    content: ${({ error }) => (error ? `"${error}"` : '""')};
    display: block; //${({ error }) => (error ? "block" : "none")};
    position: absolute;
    bottom: -24px;
    left: 0;
    height: 20px;
    width: 100%;
    color: #f44336;
  }
`;

export const LabelForm = styled.label`
  font-family: "Inter", sans-serif;
  line-height: 16px;
  font-weight: 500;
  color: #808191;
  margin-bottom: 16px;
`;

export const FormInput = styled(TextField)`
  & input {
    padding-right: ${({ children }) => (children ? "50px !important" : "0")};
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .Mui-error input {
    background-color: transparent !important;
    border: 2px solid red !important;
    padding: 18px 23px !important;
  }

  .MuiInputLabel-formControl {
    transform: none;
  }

  .Mui-focused input {
    background-color: transparent;
    border: 2px solid #4d458b;
    padding: 16px 21px;
  }

  label,
  .MuiFormLabel-root.Mui-focused {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #808191 !important;
    position: static;
  }

  input {
    box-sizing: border-box;
    padding: 18px 23px;
    color: #ffffff;
    background: #2e303a;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 56px;

    &:disabled {
      background-color: #2b2b35;
      cursor: default;
      color: #676d73;
      font-style: italic;
    }
  }
`;

export const InputButton = styled(Button)`
  box-sizing: border-box;
  padding: 18px 23px;
  color: #ffffff;
  background: linear-gradient(0.25turn, #5d8628, 5%, #416527);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 56px;
  width: 100%;

  & span {
    ${({ children }) => (children ? `padding-right:  45px !important` : "")};
  }
`;
