import React, { useState } from "react";
import { Button, Grid, Dialog, Typography, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GXP from "images/header/GXP.png";
import { closeNFTModal } from "reducers/redux/NFTModal/actions";
import { getDisplayVal } from "blockchain";
import { includesInArray } from "utils/helpers";
import { useStyles } from "./styles";

const NFTModal = () => {
  const dispatch = useDispatch();
  const modal = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const nftModal = useSelector((state) => state.NFTModal.data);

  if (!nftModal) {
    return null;
  }

  const {
    image,
    count,
    currentTokenPrice,
    name,
    description,
    longDescription,
    tokenSymbol,
    exchangeRate,
    participationToken,
    participationAmount,
  } = nftModal;

  const rejectHandler = () => {
    dispatch(closeNFTModal());
  };

  const NFTHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog
      fullScreen
      open={!!nftModal}
      onClose={rejectHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.paper }}
    >
      <Grid container className={modal.root}>
        <Grid onClick={rejectHandler} container xs>
          <Grid item className={modal.close}>
            <IconButton onClick={rejectHandler} color="inherit" aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container className={modal.NFTWrapper}>
            {includesInArray(image.split(".").pop(), ["mp4", "mov", "m4v"]) ? (
              <video onClick={(e) => NFTHandler(e)} autoPlay loop className={modal.NFT}>
                <source src={image} type="video/mp4" />
              </video>
            ) : (
              <img src={image} onClick={(e) => NFTHandler(e)} className={modal.NFT} />
            )}
          </Grid>
        </Grid>
        <Divider orientation="vertical" className={modal.verticalDivider} />
        <Grid container wrap="nowrap" direction="column" className={modal.sidebar}>
          <Grid
            direction="column"
            wrap="nowrap"
            container
            className={modal.detailsScroll}
          >
            <Grid container justifyContent="center" className={modal.name}>
              <Typography variant="h3">{name}</Typography>
              {count && (
                <Grid container justifyContent="center" className={modal.nftsRemaining}>
                  <Typography variant="subtitle1">Limited Edition</Typography>
                  <Typography variant="subtitle1">{count} Remaining</Typography>
                </Grid>
              )}
            </Grid>

            <Grid container className={modal.buttons}>
              <Button
                className={!activeTab ? modal.active : null}
                disabled={!activeTab}
                onClick={() => setActiveTab((prev) => --prev)}
              >
                Details
              </Button>
              <Button
                className={activeTab ? modal.active : null}
                disabled={!!activeTab}
                onClick={() => setActiveTab((prev) => ++prev)}
              >
                Metadata
              </Button>
            </Grid>

            <Grid container wrap="nowrap" className={modal.details}>
              {activeTab === 0 && (
                <Typography variant="body2">{longDescription || description}</Typography>
              )}
              {activeTab === 1 && (
                <Typography variant="subtitle1">
                  No Metadata is currently available for this NFT
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            wrap="nowrap"
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={modal.purchaseDetails}
          >
            <Grid container justifyContent="center" className={modal.mobileName}>
              <Typography variant="h3">{name}</Typography>
              {count && (
                <Grid container justifyContent="center" className={modal.nftsRemaining}>
                  <Typography variant="subtitle1">Limited Edition</Typography>
                  <Typography variant="subtitle1">{count} Remaining</Typography>
                </Grid>
              )}
            </Grid>
            {currentTokenPrice && (
              <Grid container direction="column" alignItems="center">
                {tokenSymbol && (
                  <Grid item container justifyContent="center" direction="row">
                    {tokenSymbol === "GXP" && (
                      <img src={GXP} alt="" className={modal.tokenSymbol} />
                    )}
                    <Typography variant="h3">
                      {currentTokenPrice} {tokenSymbol}
                    </Typography>
                  </Grid>
                )}
                {exchangeRate && (
                  <Typography variant="h6" color="textSecondary">
                    ~${currentTokenPrice * exchangeRate}
                  </Typography>
                )}
              </Grid>
            )}

            <Grid container direction="column" alignItems="center">
              <Grid item container justifyContent="center" direction="row">
                <img src={GXP} width="28px" />
                <Typography variant="h6" color="textSecondary">
                  2200 GXP required to be eligible for purchase
                </Typography>
              </Grid>
              {!!participationToken && participationAmount !== 0 && (
                <Grid item container justifyContent="center" direction="row">
                  <Typography variant="h6" color="textSecondary">
                    {getDisplayVal(participationAmount, participationToken.decimals)}{" "}
                    {participationToken.symbol} required to be eligible for purchase
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NFTModal;
