import React from "react";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "reducers/redux/Alert/actions";
import MuiAlert from "@material-ui/lab/Alert";

function AlertContainer() {
  const data = useSelector(({ Alert }) => Alert.data);
  const dispatch = useDispatch();

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  };

  if (!data) return <></>;

  return (
    <Snackbar
      open={!!data}
      autoHideDuration={data.duration || 3000}
      onClose={handleClose}
      anchorOrigin={data.anchor || { vertical: "bottom", horizontal: "left" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={data.severity}
        elevation={6}
        variant="filled"
      >
        {data.text}
      </MuiAlert>
    </Snackbar>
  );
}

export default AlertContainer;
