import React from "react";
import { useSelector } from "react-redux";
import { getDisplayVal } from "blockchain";
import { TOKEN_TYPES } from "blockchain/tokenInfo";
import {
  DialogActions,
  Dialog,
  FormHelperText,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import Text from "components/Text";
import { ReactComponent as Close } from "images/close.svg";
import DarkCoin from "images/header/GXP.png";
import { useStyles } from "../../Dashboard/Achievement/claimGamePoints/styles";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";
import Loader from "../../loaders";

const ClaimModal = ({
  open,
  buttonLoading,
  fromValue,
  handlerChangeValue,
  index,
  closeHandler,
  amountError,
  successHandler,
  expectedReward,
  ...props
}) => {
  const modal = FormStyled();
  const classes = useStyles();

  const stakeInfo = useSelector((state) => state.Staking.stakingList[index]);
  const { contract, secondaryToken, primaryToken, id, type, rewards, rewardToken } =
    stakeInfo;
  const tokenAddr = secondaryToken.address;
  const nftAddr = primaryToken.address;
  const stakeAllowance = useSelector(
    (state) => state.allowances[tokenAddr]?.[contract] || 0
  );
  const secondaryBalance = useSelector((state) => state.balances[tokenAddr] || 0);
  const handlerInfo = {
    token: tokenAddr,
    nftAddr,
    balance: secondaryBalance,
    allowance: stakeAllowance,
    stakeAddress: contract,
    stakeId: id,
    type,
  };

  const renderFormHelper = () => {
    return `${rewards && getDisplayVal(rewards.amount, rewardToken.decimals, true)} ${
      rewardToken.symbol
    }`;
  };

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>
      <DialogTitle>Claim</DialogTitle>

      <ConvertModalContainer>
        <FormControl variant="outlined" className={classes.root}>
          <OutlinedInput
            id="inputFrom"
            value={fromValue}
            type="Number"
            placeholder={99}
            onChange={(e) => handlerChangeValue(e, "claim")}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
              min: 0,
              max: 99,
            }}
            className={`${classes.input}
            ${amountError && classes.error}`}
            labelWidth={0}
            endAdornment={
              <>
                <InputAdornment className={classes.endAdornment} position="end">
                  Max Periods (lower if the gas cost is too high)
                </InputAdornment>
              </>
            }
          />
          <FormHelperText>
            <Text variant="p2" className={classes.balance}>
              Unclaimed: ~{renderFormHelper()}
            </Text>
          </FormHelperText>
        </FormControl>
      </ConvertModalContainer>

      <DialogActions>
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => successHandler("claim", handlerInfo)}
          disabled={rewards?.amount == "0" || buttonLoading || amountError}
        >
          {amountError || `Claim ${rewardToken.symbol}`}
          {buttonLoading && <Loader />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimModal;
