import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import CircularProgress from "@material-ui/core/CircularProgress";
import breakpoints from "utils/breakpoints";
import Content from "../Dashboard/Achievement/Content";
import Logo from "../Logo/Logo";

const Achievement = React.memo(() => {
  const { id } = useParams();
  const { width } = useWindowSize();
  const history = useHistory();

  const seasonalChallenges = useSelector(
    ({ challenges }) => challenges.seasonalGames.byId[id]
  );

  if (!!seasonalChallenges?.status && seasonalChallenges?.status !== "approved")
    history.push("/dashboard");

  return (
    <div>
      {seasonalChallenges ? (
        <>
          {width <= breakpoints.sm ? (
            <Logo
              backgroundImage={seasonalChallenges.image_url}
              title=" "
              description=" "
            />
          ) : (
            <Logo
              backgroundImage={seasonalChallenges.background}
              title={seasonalChallenges.title}
              description={seasonalChallenges.short_description}
            />
          )}
          <Content current={seasonalChallenges} />
        </>
      ) : (
        <CircularProgress color="secondary" size="150px" />
      )}
    </div>
  );
});

export default Achievement;
