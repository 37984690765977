import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, Typography, IconButton, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  Name,
  FlexColumn,
  Subtitle,
  ContainerItemHeader,
  ContainerItemContent,
  ContainerItemFooter,
  ContainerItem,
  RemoveLiquidityInfoBox,
  LiquidityDescription,
  CurrencyText,
  Descriptions,
  Description,
  FooterInfoContainer,
  SubHeader,
} from "components/Exchange/styles";
import ExchangeButton from "components/buttons/ExchangeButton";
import ArrowForwardIosIcon from "@material-ui/icons/DoubleArrow";
import { roundIntToDecimals } from "utils/helpers";
import { Routers } from "blockchain/tokenInfo";
import { getDisplayVal } from "blockchain/web3Utils";
import SimpleRemove from "./SimpleRemove";
import AdvancedRemove from "./AdvancedRemove";
import GlobalSettings from "../GlobalSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "434px",
  },
  iconButton: {
    borderRadius: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    height: "32px",
    width: "32px",
    backgroundColor: "#6e43e6",
  },
  downArrow: {
    width: "100%",

    margin: "10px 0 10px",
  },
  firstProvider: {
    borderRadius: "15px",
    backgroundColor: "#FFB2371F",
    marginBottom: "20px",
    "& div": {
      "& svg": {
        fill: "#FFB237",
      },
    },
  },
  alertTitle: {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Work Sans",
  },
  alertContent: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Work Sans",
    marginBottom: "8px",
  },
  amountButton: {
    borderRadius: "25px",
  },
  slider: {
    margin: "20px 0",
  },
  header: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  arrowForward: {
    position: "absolute",
    top: "15px",
    left: "160px",
  },
}));

const Remove = ({
  pair,
  connected,
  badNetwork,
  connectHandler,
  approveHandler,
  liquidityHandler,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const fromCurrency = pair.token0;
  const pairCurrency = pair.liquidityToken;
  const toCurrency = pair.token1;

  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [approval, setApproval] = useState(0);

  const [percentage, setPercentage] = useState(0);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [fromTotal, setFromTotal] = useState(0);
  const [toTotal, setToTotal] = useState(0);
  const approvals = useSelector((state) => state.allowances);
  const balances = useSelector((state) => state.balances);
  const web3 = useSelector((state) => state.web3);

  useEffect(() => {
    setFromTotal(parseFloat(pair.reserve0.toFixed()) * ratio);
    setToTotal(parseFloat(pair.reserve1.toFixed()) * ratio);
    setExchangeRate(pair.reserve0.div(pair.reserve1));
  }, [ratio, pair]);

  useEffect(() => {
    if (web3.chainId && pair.liquidityToken && balances[pair.liquidityToken.address]) {
      setMaxAmount(
        getDisplayVal(balances[pair.liquidityToken.address], pair.liquidityToken.decimals)
      );
      if (
        approvals[pair.liquidityToken.address] &&
        approvals[pair.liquidityToken.address][Routers[web3.chainId]]
      ) {
        setApproval(
          getDisplayVal(
            approvals[pair.liquidityToken.address][Routers[web3.chainId]],
            pair.liquidityToken.decimals
          )
        );
      }
      const total = parseFloat(pair.totalSupply.toFixed());
      const userShare = parseFloat(
        getDisplayVal(balances[pair.liquidityToken.address], pair.liquidityToken.decimals)
      );

      setRatio(userShare / total);
    }
  }, [pair, web3.chainId, approvals, balances]);

  const calcRatio = (val) => roundIntToDecimals(val * ratio, 6);

  const descriptions = (mode) => {
    let data;
    if (mode == "prices") {
      data = {
        [`1 ${fromCurrency.symbol}`]: `${roundIntToDecimals(1 / exchangeRate, 6)} ${
          toCurrency.symbol
        }`,
        [`1 ${toCurrency.symbol}`]: `${roundIntToDecimals(exchangeRate, 6)} ${
          fromCurrency.symbol
        }`,
      };
    } else {
      data = {
        "Share of Pool": `${Math.round(ratio * 100000) / 1000}%`,
        [`Pooled ${fromCurrency.symbol}`]: calcRatio(fromTotal),
        [`Pooled ${toCurrency.symbol}`]: calcRatio(toTotal),
      };
    }
    const res = [];

    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <CurrencyText>{key}</CurrencyText>
          <LiquidityDescription>{data[key]}</LiquidityDescription>
        </Description>
      );
    });

    return res;
  };

  return (
    <FlexColumn>
      <ContainerItem elevation={3} className={classes.root}>
        <ContainerItemHeader>
          <FlexColumn>
            <IconButton onClick={() => history.push("/xchange/liquidity")}>
              <ArrowBackIcon />
            </IconButton>
          </FlexColumn>
          <FlexColumn>
            <Name>
              Remove {fromCurrency.symbol}-{toCurrency.symbol} Liquidity
            </Name>
            <Subtitle>
              To receive {fromCurrency.symbol} and {toCurrency.symbol}
            </Subtitle>
          </FlexColumn>
          <GlobalSettings />
        </ContainerItemHeader>
        <ContainerItemContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "10px" }}
          >
            <Grid item>
              <Typography className={classes.header}>Amount</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={() => setIsAdvanced(!isAdvanced)}>
                {isAdvanced ? "Simple" : "Advanced"}
              </Button>
            </Grid>
          </Grid>
          {isAdvanced ? (
            <AdvancedRemove
              setAmount={setAmount}
              maxAmount={maxAmount}
              amount={amount}
              primaryCurrency={fromCurrency}
              secondaryCurrency={toCurrency}
              primaryTotal={fromTotal}
              secondaryTotal={toTotal}
              setPercentage={setPercentage}
            />
          ) : (
            <SimpleRemove
              setPercentage={setPercentage}
              setAmount={setAmount}
              maxAmount={maxAmount}
              percentage={percentage}
              amount={amount}
              fromCurrency={fromCurrency}
              toCurrency={toCurrency}
              fromTotal={fromTotal}
              toTotal={toTotal}
            />
          )}
          <SubHeader>PRICES</SubHeader>
          <RemoveLiquidityInfoBox>
            <Grid item style={{ position: "relative" }}>
              <ArrowForwardIosIcon className={classes.arrowForward} />
            </Grid>

            <Descriptions>{descriptions("prices")}</Descriptions>
          </RemoveLiquidityInfoBox>
        </ContainerItemContent>
        <ContainerItemFooter>
          <ExchangeButton
            //loading={buttonIsLoading}
            //allowanceHandler={() => allowanceHandler(saleId)}
            connected={connected}
            badNetwork={badNetwork}
            connectHandler={connectHandler}
            swapHandler={() =>
              liquidityHandler({
                mode: "Remove",
                pair,
                amount,
                percentage,
                fromTotal,
                toTotal,
                exchangeRate,
              })
            }
            approveHandler={() => approveHandler(pairCurrency)}
            fromAmount={amount}
            fromApproval={approval}
            fromCurrency={pairCurrency}
            removing
          />
        </ContainerItemFooter>
      </ContainerItem>
      <FooterInfoContainer>
        <SubHeader>LP tokens in your wallet</SubHeader>
        <Description>
          <Grid container>
            <CurrencyText>
              {fromCurrency.symbol}-{toCurrency.symbol} LP
            </CurrencyText>
          </Grid>

          <LiquidityDescription>{roundIntToDecimals(maxAmount, 6)}</LiquidityDescription>
        </Description>
        <Descriptions>{descriptions("pool")}</Descriptions>
      </FooterInfoContainer>
    </FlexColumn>
  );
};

export default Remove;
