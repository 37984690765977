import { WEB3_ALLOWANCE_SET } from "./types";

const Web3AllowanceReducer = (state = {}, action) => {
  switch (action.type) {
    case WEB3_ALLOWANCE_SET: {
      return {
        ...state,
        [action.contract]: {
          ...state[action.contract],
          [action.toContract]: action.allowance,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default Web3AllowanceReducer;
