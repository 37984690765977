// The goal of this hook is to handle fetching the data needed for App.jsx
// Data will be fetched on first build, but may also be fetched in other situations
// such as when the user logs out and the user store changes

import {
  fetchGames,
  fetchMarketFeatured,
  fetchSeasonalGames,
  fetchVaults,
  fetchLeaderboards,
  steamGetCurrentUser,
  getCurrentUser,
  getReferral,
  getConversionStats,
  loadedFullScreen,
  loadFullScreen,
  LOADING_MEMBERS,
} from "actions";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBearerTokenFromLocalStorage } from "utils/bearerToken";

const getEssentialAppData = (dispatch, { isLoggedIn, gidSteam, userId }) => {
  // first we gather data that is not tied to a user
  dispatch(loadFullScreen(LOADING_MEMBERS.APP_DATA));
  Promise.all([
    dispatch(fetchMarketFeatured()),
    dispatch(fetchGames()),
    dispatch(fetchSeasonalGames()),
    dispatch(fetchLeaderboards()),
  ])
    .then(() => {
      dispatch(loadedFullScreen(LOADING_MEMBERS.APP_DATA));
    })
    .catch(() => {
      dispatch(loadedFullScreen(LOADING_MEMBERS.APP_DATA));
    });

  // check if there is a token available
  // if there is not a token then our work here is done
  // if there is a token then attempt to get current user data
  const localToken = getBearerTokenFromLocalStorage();
  if (!localToken) return;
  if (!isLoggedIn) {
    dispatch(getCurrentUser());
  }
  // if the user is logged in, fetch user specific data
  if (isLoggedIn) {
    dispatch(fetchVaults());
    dispatch(getReferral());
    dispatch(getConversionStats());
    if (gidSteam) {
      dispatch(steamGetCurrentUser(gidSteam)).then((gid) => {
        dispatch(fetchSeasonalGames(gid, userId));
      });
    }
  }
};

const useAppDataFetcher = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const { isLoggedIn, gid_steam: gidSteam, id: userId } = user;

  useLayoutEffect(() => {
    // this is only run on first load and if the user object changes
    getEssentialAppData(dispatch, { isLoggedIn, gidSteam, userId });
  }, [dispatch, isLoggedIn, gidSteam, userId]);
};

export default useAppDataFetcher;
