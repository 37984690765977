import styled from "styled-components";

export const Achievements = styled.div`
  margin-bottom: 24px;
  border-radius: 4px;
  border: ${({ achieved }) => (achieved ? "3px solid #7749f8" : "none")};
`;

export const AchievementsHeader = styled.div`
  height: 55px;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  background-color: ${(props) => (props.achieved ? "#7749f8" : "#6c757d")};
  color: ${(props) => (props.achieved ? "#fff" : "#212529")};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  padding-left: 1.57%;
`;

export const AchievementsBody = styled.div`
  border-radius: inherit;
  background-color: #22272b;
  display: flex;
  flex-direction: row;
  padding: 15px;
  background-color: ${({ achieved }) => (achieved ? "#22272b" : "#303941")};
  justify-content: space-between;
`;

export const Name = styled.div`
  line-height: 24px;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 7px;
`;

export const Description = styled(Name)`
  font-weight: 400;
  line-height: 28px;
`;
