import React from "react";
import { useSelector } from "react-redux";
import ButtonPrev from "../../../../buttons/ButtonPrev";
import { NextButton } from "../../../../buttons/styles";

const Actions = ({ setActiveStep, current, read, open }) => {
  const step = useSelector(({ Signup }) => Signup.activeWindow);

  const handlerChange = (value) => {
    current === step && setActiveStep(value);
  };

  return (
    <>
      <ButtonPrev handler={() => handlerChange("-")} />
      <NextButton
        onClick={() => handlerChange("+")}
        disabled={!(current === step && read && open.terms && open.policy)}
      >
        Accept
      </NextButton>
    </>
  );
};

export default Actions;
