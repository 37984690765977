import * as actions from "actions";

export const isValidUsername = (values) => {
  const promises = [];
  promises.push(actions.isValidUsername(values.username));
  promises.push(actions.isValidEmail(values.email_address));
  return Promise.all(promises).then((result) => {
    if (result[0]) {
      throw { username: "That username is taken" };
    }
    if (result[1]) {
      throw { email_address: "That email is taken" };
    }
    return Promise.resolve();
  });
};
