import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import Chrome from "../../../../assets/icons/browser/chrome.svg";
import Firefox from "../../../../assets/icons/browser/firefox.svg";

const NewWalletMetamask = () => (
  <Grid container>
    <Grid item xs={12} className="mt--64">
      <Typography color="inherit" variant="display2" className="bold">
        Metamask
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--32">
      <Typography className="font-size-16" color="inherit">
        Metamask is a great simple browser plug in wallet, with increasing support across
        platforms. You hold access to your private key and makes it easy access your
        wallet, or recover your password with your safe word.
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--64">
      <Typography variant="subtitle1" className="bold font-size-18" color="inherit">
        1. Select browser and install plug in
      </Typography>
    </Grid>
    <Grid item xs={12} className=" mt--24 metamask-browser-icons">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            className="padding-16 bold font-size-18"
            component={Link}
            target="_blank"
            to="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/"
          >
            Firefox
            <Firefox className="float-right" />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            className="padding-16 bold font-size-18"
            component={Link}
            target="_blank"
            to="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
          >
            Chrome
            <Chrome className="float-right" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--48">
      <Typography variant="subtitle1" className="bold font-size-18" color="inherit">
        2. Create wallet and stay logged in
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--24">
      <Button
        className="padding-16 bold font-size-18"
        variant="contained"
        fullWidth
        component={Link}
        color="primary"
        to="/pub/onboarding/wallet/add"
      >
        Done
      </Button>
    </Grid>
  </Grid>
);

export default NewWalletMetamask;
