import React from "react";
import Logo from "../Logo/Logo";
import Content from "../Leaderboards/Content";

const Leaderboards = () => {
  return (
    <>
      <Logo />
      <Content />
    </>
  );
};

export default Leaderboards;
