import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  layout: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    width: "100vw",
    maxWidth: "100%",
  },
  content: {
    marginTop: "80px",
    flexGrow: 1,
    maxWidth: "100vw",
  },
}));
