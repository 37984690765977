export const SET_BLOCK_CHAIN = "SET_BLOCK_CHAIN";
export const SET_ADDRESS = "SET_ADDRESS";

export const SET_ACTIVE_WINDOW = "SET_ACTIVE_WINDOW";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

export const CLEAR_STEP = "CLEAR_STEP";

export const SUCCESS_AUTH = "SUCCESS_AUTH";
export const SUCCESS_DEAUTH = "SUCCESS_DEAUTH";
