import React from "react";
import { useSelector } from "react-redux";
import { ContentWrapper } from "../Marketplace/styles";
import { ImgText, ImgWrapper } from "./Logo.styles";

const Logo = React.memo(({ backgroundImage, title, description }) => {
  const banner = useSelector(({ etc }) => etc.marketFeature);
  if (banner === undefined) {
    return null;
  }
  return (
    <ContentWrapper>
      <ImgWrapper>
        <img src={backgroundImage || banner.data.feature_background_url} alt="" />
        <ImgText>
          <h2>{title || banner.data.title}</h2>
          <div>{description || banner.data.short_description}</div>
        </ImgText>
      </ImgWrapper>
    </ContentWrapper>
  );
});

export default Logo;
