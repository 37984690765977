import styled from "styled-components";
import { button } from "../../styles";

export const Button = styled(button)`
  padding: 9px 11px;
  border-radius: 8px;
  line-height: 24px;
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  word-break: break-word;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PrevButton = styled(Button)`
  background-color: inherit;
  display: flex;
  align-items: center;
  width: 100%;

  & > svg {
    margin-right: 13px;
  }

  &:hover {
    background-color: #1d212a;
  }
`;
export const NextButton = styled(Button)`
  width: 100%;
  background: ${({ disabled }) => (disabled ? "#54595E" : "#7749F8")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  &:hover {
    background: ${({ disabled }) => (disabled ? "#54595E" : "#6053bb")};
  }

  @media (max-width: 400px) {
    padding: 9px 5px;
    white-space: normal;
    word-break: normal;
  }
`;
export const FormWrapper = styled.div`
  background-color: #242731;
  border-radius: 40px;
  padding: 3rem 3rem 2vw;
  min-width: 500px;
  position: relative;
  height: 100%;

  @media (max-width: 576px) {
    padding-bottom: 48px;
  }

  @media (max-width: 768px) {
    background-color: #1f2128;
    padding: 2rem 2rem 50px;
  }

  @media (max-width: 480px) {
    padding: 0 0 50px;
    min-width: 0px;
  }
`;

export const FormButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 20px;
  width: 100%;
  margin-top: 24px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  background-color: ${({ disabled }) => (disabled ? "#54595E" : "#6C5DD3")};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#54595E" : "#5e51b8")};
  }

  & > span {
    margin-right: 8px;
  }
`;
