import axios from "axios";
import { IS_PROD } from "utils/environments";
import { getBearerTokenFromLocalStorage } from "./bearerToken";

const instance = () => {
  let completeBearerToken = null;
  const localToken = getBearerTokenFromLocalStorage();
  if (localToken) {
    completeBearerToken = `Bearer ${localToken}`;
  }

  return axios.create({
    baseURL: IS_PROD
      ? "https://gxc-api.gamexchange.app/web/v1/steam"
      : "http://70.70.62.21:33336/web/v1/steam",
    headers: {
      common: {
        Authorization: completeBearerToken,
      },
    },
  });
};

export const verifyUser = (info) => instance().get("/auth", { params: info });

// returns the user's steam name, a string
export const getUser = (id) => instance().get(`/name?id=${id}`);

// asks the proxy to verify a user's achievements, reward them with currency.
export const makeUserSteamClaim = (appid) => instance().post(`/game/${appid}`);

// returns the game's store object from the steam API, mostly unmodified
export const fetchSteamGame = (gameid) => instance().get(`/game/${gameid}`);

// returns the game's store object from the steam API, mostly unmodified
export const isPublic = (steamid) => instance().get(`/public/${steamid}`);
