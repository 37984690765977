import React from "react";
import { Grid, Button, Link } from "@material-ui/core";
import Text from "components/Text";

const GamePlayButtons = (props) =>
  props.game.platform.length > 0 && (
    <Grid container className="game-details__store-buttons">
      <Grid item xs={12} className="store-buttons">
        <Text variant="h6">Get the game</Text>
        <Text variant="caption" color="textSecondary" className="normal">
          Go to your game store to install the game
        </Text>
        {props.game.platform.map((platform, index) => (
          <div key={platform}>
            {props.game.game_store_url[index] && (
              <Button
                fullWidth
                className="store-buttons"
                size="large"
                variant="contained"
                color="primary"
                component={Link}
                href={props.game.game_store_url[index]}
                target="_blank"
              >
                <img
                  key={platform}
                  className="mr--8"
                  alt={platform}
                  src={`https://img.tapplatform.io/stat/icons/format/${platform}.svg`}
                />
                {platform} Store
              </Button>
            )}
          </div>
        ))}
      </Grid>
    </Grid>
  );

export default GamePlayButtons;
