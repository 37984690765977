import React from "react";
import { StepCycle, StepCycleWrapper } from "../styles";

const renderSteps = (number, activeStep) => {
  const steps = [];
  for (let i = 0; i <= number; i++) {
    steps.push(<StepCycle active={i === activeStep} key={i} />);
  }
};

const Stepper = ({ length, active }) => (
  <StepCycleWrapper>{renderSteps(length, active)}</StepCycleWrapper>
);

export default Stepper;
