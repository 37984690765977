import { IS_PROD } from "utils/environments";
import Token from "utils/web3/models/token";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const TOKEN_TYPES = {
  ERC20: "ERC20",
  ERC223: "ERC223",
  ERC721: "ERC721",
  ERC1155: "ERC1155",
};

export const BLOCKCHAINS = {
  ethereum: 1,
  binance: 56,
  testEthereum: 1337,
  testBinance: 97,
};

export const CONVERSION_INFO = {
  [BLOCKCHAINS.testEthereum]: "0xEAFeD6B61FA3Ef662C30A22df366F523B82A0313",
  [BLOCKCHAINS.binance]: "0x92d49b359565c4918356fCF13e2F96De95bfa1d9",
};

export const SUPPORTED_BLOCKCHAINS = IS_PROD
  ? [BLOCKCHAINS.binance]
  : [BLOCKCHAINS.testEthereum, BLOCKCHAINS.testBinance];

export const PARTNER_TOKEN_RUN_ADDRESSES = {
  [BLOCKCHAINS.testBinance]: "0x4e36Ef0ff9551bE560774716386587b20919ea08",
  [BLOCKCHAINS.binance]: "0x5F2a39eC02AFfeE6A244233348c67a43C37D5D63",
};

export const STAKES = {
  [BLOCKCHAINS.testEthereum]: {
    [TOKEN_TYPES.ERC721]: "0x001784FE7BB2C5F9D262b662c1F8326235a8377B",
  },
  [BLOCKCHAINS.binance]: {
    [TOKEN_TYPES.ERC721]: "0xc7de991023624b9516c31ba87925f6fcd49ef7a1",
  },
};

export const Payments = {
  [BLOCKCHAINS.binance]: "0xA5B39F9E99c4cBA6eAe7493Afc845Ef514311184",
  [BLOCKCHAINS.testEthereum]: "0xCC253DFCc56557E78ebAe1eC5CD2fC37355e5094",
};

export const Exchanges = {
  [BLOCKCHAINS.testEthereum]: "0x5826F3f15fD71e3e0DE03C13070736664544198c",
  [BLOCKCHAINS.binance]: "0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6",
};

export const Routers = {
  [BLOCKCHAINS.testEthereum]: "0x0E2eb51549caea4C6EcfFF5F7A39BFDc047d766a",
  [BLOCKCHAINS.binance]: "0x441805c6e38033e26a4dd8f1c98572a7d760d013",
};

export const Multicall = {
  [BLOCKCHAINS.testEthereum]: "0x57DFD3c99039ed1B67eB57934A2335409D33899a",
  [BLOCKCHAINS.binance]: "0x38ce767d81de3940CFa5020B55af1A400ED4F657",
};

export const WRAPPER_NATIVE = {
  [BLOCKCHAINS.testEthereum]: "WTETH",
  [BLOCKCHAINS.binance]: "WBNB",
};

export const TOKEN_INFO = {
  BNB: {
    name: "Binance Coin",
    symbol: "BNB",
    decimals: 18,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.binance]: ZERO_ADDRESS,
    },
  },
  WBNB: {
    name: "Wrapped Binance Coin",
    symbol: "WBNB",
    mirrorsNative: [BLOCKCHAINS.binance],
    decimals: 18,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.binance]: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    type: TOKEN_TYPES.ERC20,
  },
  WTETH: {
    name: "Wrapped Test Ethereum",
    symbol: "WTETH",
    decimals: 18,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0x751A991BB372eDf195B214570b87ef956Ae2Aed2",
    },
    type: TOKEN_TYPES.ERC20,
  },
  TETH: {
    name: "Test Ethereum",
    symbol: "TETH",
    decimals: 18,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.testEthereum]: ZERO_ADDRESS,
    },
  },
  GameXChange: {
    name: "Game X Change Potion",
    symbol: "GXP",
    decimals: 18,
    exchange: true,
    exchangeRate: 0.0005,
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xdc1c37D42EC18F18127C0226C2f52816aCF74ea1",
      [BLOCKCHAINS.binance]: "0x8347AC51Ec4eF29324591D55e2b585A38b2ead5B",
    },
    type: TOKEN_TYPES.ERC20,
  },
  "Space Monkey": {
    name: "Space Monkey",
    symbol: "MONKE",
    decimals: 9,
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xdc1c37D42EC18F18127C0226C2f52816aCF74ea1",
      [BLOCKCHAINS.binance]: "0xb422DE11a237d0cbCE17D7d70f53F67ef84394D3",
    },
    type: TOKEN_TYPES.ERC20,
  },
  "Game X Change Gold": {
    name: "Game X Change Gold",
    symbol: "GXG",
    decimals: 18,
    exchangeRate: 0.55,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.binance]: "0xe7173811eCeE49656F213D9592BAaD8e73241676",
    },
    type: TOKEN_TYPES.ERC20,
  },
  GXP_BNB: {
    name: "GXP/BNB LP",
    symbol: "GXP/BNB LP",
    decimals: 18,
    LP: ["GameXChange", "BNB"],
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xdc1c37D42EC18F18127C0226C2f52816aCF74ea1",
      [BLOCKCHAINS.binance]: "0xA0954c7A27B930e07fD333dD1DA7266A95c19361",
    },
  },
  GXG_BNB: {
    name: "GXG/BNB LP",
    symbol: "GXG/BNB LP",
    decimals: 18,
    LP: ["Game X Change Gold", "BNB"],
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xdc1c37D42EC18F18127C0226C2f52816aCF74ea1",
      [BLOCKCHAINS.binance]: "0x491434Ef1A2eAbC8Af1b97857CaADedF39Fb7F74",
    },
  },
  "Binance USD": {
    name: "Binance USD",
    symbol: "BUSD",
    decimals: 18,
    exchangeRate: 1,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.testBinance]: "0x1e9e0dc8Ab62b81d3f5187999847d6Ca0A65b791",
      [BLOCKCHAINS.binance]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
  },
  USDT: {
    name: "Tether USD",
    symbol: "USDT",
    decimals: 18,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.binance]: "0x55d398326f99059fF775485246999027B3197955",
    },
    type: TOKEN_TYPES.ERC20,
  },
  LTNFTs: {
    name: "Limited Time Minting NFTs",
    symbol: "LTNFTs",
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0x525D86D0bd9386eef043122506498Ac3C0432782",
    },
    type: TOKEN_TYPES.ERC721,
  },
  STNFP: {
    name: "Stunt Runner NFP",
    symbol: "STNFP",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/stuntrunner/Stunt+Runner+NFP.mp4",
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xCDfc1711C6A528Cc69bA11A7BE318b59cbCB920a",
      [BLOCKCHAINS.binance]: "0xfb029aD43E62325D4eec1F3868EEb71EeB3CBBAC",
    },
    type: TOKEN_TYPES.ERC721,
  },
  BCCNFP: {
    name: "Blocky Car Clash NFP",
    symbol: "BCCNFP",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/blockycarclash/Blocky+Car+Clash+NFP.mp4",
    addresses: {
      [BLOCKCHAINS.binance]: "0x44fB6e4D78F8c9B3DdC73161306F84Ce64a129e0",
    },
    types: {
      [BLOCKCHAINS.binance]: TOKEN_TYPES.ERC721,
    },
  },
  PCNFP: {
    name: "Panda Commander NFP",
    symbol: "PCNFP",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/pandacommander/Panda+Commander+NFP.mp4",
    addresses: {
      [BLOCKCHAINS.binance]: "0xcEA48573b1045fbff7658b97c3a97a72d4dC462c",
    },
    types: {
      [BLOCKCHAINS.binance]: TOKEN_TYPES.ERC721,
    },
  },
  "Space Monkey NFP": {
    name: "Space Monkey NFP",
    symbol: "SMNFP",
    image: "https://nft-collections.s3.amazonaws.com/spacemonkey/Space+Monkey+NFP.mp4",
    addresses: {
      [BLOCKCHAINS.binance]: "0x6D32cd12B3395B053Ade9036Ef502a019a1E1fD7",
    },
    types: {
      [BLOCKCHAINS.binance]: TOKEN_TYPES.ERC721,
    },
  },
  DracodiceCe: {
    name: "Draco Dice (Celestial)",
    symbol: "dracodice (clst)",
    image: "https://gamexchange1.s3.amazonaws.com/games/draco/celestial.jpg",
    addresses: {
      [BLOCKCHAINS.binance]: "0xd8C310073B1b4BA85B80C01638d1389074d2A2E3",
    },
    type: TOKEN_TYPES.ERC721,
  },
  DracodiceMy: {
    name: "Draco Dice (Mythic)",
    symbol: "dracodice (mthc)",
    image: "https://gamexchange1.s3.amazonaws.com/games/draco/mythic.jpg",
    addresses: {
      [BLOCKCHAINS.binance]: "0x563b79a511f9087978f13B1Ca0c4a0dB0eDa1ab1",
    },
    type: TOKEN_TYPES.ERC721,
  },
  DracodiceLe: {
    name: "Draco Dice (Legendary)",
    symbol: "dracodice (lgnd)",
    image: "https://gamexchange1.s3.amazonaws.com/games/draco/legendary.jpg",
    addresses: {
      [BLOCKCHAINS.binance]: "0xE74ee177Fe5E0D9221c93CCE75812f327878ca1C",
    },
    type: TOKEN_TYPES.ERC721,
  },
  DracodiceEp: {
    name: "Draco Dice (Epic)",
    symbol: "dracodice (epic)",
    image: "https://gamexchange1.s3.amazonaws.com/games/draco/epic.jpg",
    addresses: {
      [BLOCKCHAINS.binance]: "0x2726852Be3A3B7A9Aa9b583E6576fC461bA02aFc",
    },
    type: TOKEN_TYPES.ERC721,
  },
  MTK: {
    name: "Melted Tapkey",
    symbol: "MTK",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/tapkey/Melted+TapKey.gif",
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0xd9f1893Ae0e32eeB699DD2b43a8b61A6a5DD9E1e",
      [BLOCKCHAINS.binance]: "0x24484544a64e67FC190eFb2029f95D837CaA360E",
    },
    type: TOKEN_TYPES.ERC721,
  },
  BDNFP: {
    name: "Blocko Dash NFP",
    symbol: "BDNFP",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/blockodash/Blocko+Dash+NFP.mp4",
    addresses: {
      [BLOCKCHAINS.binance]: "0x275AfDD762a718cdd7d4f87AB06c791271B1B82C",
    },
    type: TOKEN_TYPES.ERC721,
  },
  GXCBP1: {
    name: "GXC Battle Pass S1",
    symbol: "GXCBP1",
    image:
      "https://nft-collections.s3.amazonaws.com/gamexchange/xchallenge/Battlepass+Re-Emergence+S1.mp4",
    addresses: {
      [BLOCKCHAINS.binance]: "0xE2293BE46167771Af8F5fe678D689FC9c2D05A04",
    },
    type: TOKEN_TYPES.ERC721,
  },
  GXCBP2: {
    name: "GXC Battle Pass S2",
    symbol: "GXCBP2",
    addresses: {
      [BLOCKCHAINS.binance]: "0xEd49D6e9f39C3A970446607639A11F0Cc0418C63",
    },
    type: TOKEN_TYPES.ERC721,
  },
  "Victory Points": {
    name: "Victory Points",
    symbol: "VCTY",
    decimals: 4,
    exchange: true,
    addresses: {
      [BLOCKCHAINS.binance]: "0x6ef0f0d85a09a4e3b362dc6ae08a8f6a523e9365",
    },
  },
  TapNFTs: {
    addresses: {
      [BLOCKCHAINS.testEthereum]: "0x58DC9d080940Ceecf3FF01F222Ea62Fc8E0E6DE4",
    },
    name: "TapNFTs",
    type: TOKEN_TYPES.ERC721,
  },
};

export const upgradeToken = TOKEN_INFO.GameXChange;

export const LTNFT_TOKEN_SYMBOL = TOKEN_INFO.LTNFTs.symbol;

export const BLOCKCHAIN_INFO = {
  [BLOCKCHAINS.binance]: {
    name: "Binance",
    stargates: { main: "0x3561f2CE3B2c38aEe6c8d352907d0d2765E0aA32" },
    fee: "14000000000000",
    exchangeRate: 3500,
    type: "metamask",
    chainParameters: {
      chainId: "0x38",
      chainName: "Binance",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
      blockExplorerUrls: ["https://bscscan.com/"],
      iconUrls: [],
    },
  },
  [BLOCKCHAINS.ethereum]: {
    name: "Ethereum",
    stargates: { main: "0xE2FA1BDe1EB2B1D5d13Db2C3a39Aabc15e7928f7" },
    fee: "14000000000000",
    exchangeRate: 3500,
    type: "metamask",
    chainParameters: {
      chainId: "0x1",
      chainName: "Ethereum",
      nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://mainnet.infura.io/v3/694414f07cdb4fc3ba9490e8933b1894"],
      blockExplorerUrls: [],
      iconUrls: [],
    },
  },
  [BLOCKCHAINS.testBinance]: {
    name: "Binance Testnet",
    stargates: { main: "0xb185A0edD2fB473FFc4e834e0f9A88cF7d3b0cad" },
    fee: "60690000000000",
    type: "metamask",
    exchangeRate: 622,
    chainParameters: {
      chainId: "0x61",
      chainName: "Binance Testnet",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
      blockExplorerUrls: ["https://testnet.bscscan.com/"],
      iconUrls: [],
    },
  },
  [BLOCKCHAINS.testEthereum]: {
    name: "Ethereum Testnet",
    stargates: { main: "0xbD418c32cb410d5Da4d38661E9E16bB7b8EcC1CC" },
    fee: "14000000000000",
    exchangeRate: 3500,
    type: "metamask",
    chainParameters: {
      chainId: "0x539",
      chainName: "Ethereum Testnet",
      nativeCurrency: {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["http://159.203.228.102:9545"],
      blockExplorerUrls: [],
      iconUrls: [],
    },
  },
};

/**
 * Gets a token object from an address by chain
 * @param {string} searchAddress
 * @param {number} chainId
 * @returns token
 */
export const getTokenFromAddressByChain = (searchAddress, chainId) => {
  for (const [key, token] of Object.entries(TOKEN_INFO)) {
    if (
      token.addresses[chainId] &&
      searchAddress.toLowerCase() === token.addresses[chainId].toLowerCase()
    ) {
      token.key = key;
      return token;
    }
  }
  return false;
};

export const getTokenByName = (name) => {
  for (const [key, token] of Object.entries(TOKEN_INFO)) {
    if (name.toLowerCase() === token.name.toLowerCase()) {
      token.key = key;
      return token;
    }
  }
  return false;
};

export const getTokenAddressByName = (name, chainId) => {
  const token = getTokenByName(name);
  if (token) {
    return token.addresses[chainId];
  }
  return false;
};

export const formatToChain = (token, chainId) => {
  const realToken = typeof token === "string" ? TOKEN_INFO[token] : token;
  if (!realToken) {
    throw new Error(`Token "${token}" does not exist`);
  }
  return new Token(
    chainId,
    realToken.addresses[chainId],
    realToken.decimals,
    realToken.symbol,
    realToken.name
  );
};

export const getNativeToken = (chainId) => {
  return formatToChain(WRAPPER_NATIVE[chainId]);
};

export const exchangeTokenList = (chainId) => {
  const supportedTokens = [];
  Object.entries(TOKEN_INFO).forEach(([name, token]) => {
    if (!token.exchange || !token.addresses[chainId]) {
      return;
    }
    supportedTokens.push(formatToChain(token, chainId));
  });
  return supportedTokens;
};

export const getAvailableNetworks = () =>
  SUPPORTED_BLOCKCHAINS.map((id) => BLOCKCHAIN_INFO[id]);

export const getTokenImageBySymbol = (symbol) =>
  `https://s3.gamexchange.app/uploads/tokens/${symbol}.png`;

export default TOKEN_INFO;
