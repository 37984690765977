import styled from "styled-components";
import { Flex } from "../../styles";
import { Button } from "@material-ui/core";

export const ContentHeaderName = styled.div`
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 480px) {
    font-size: 48px;
    margin-top: 30px;
    letter-spacing: -1px;
  }

  @media (max-width: 350px) {
    font-size: 40px;
  }
`;

export const ContentHeaderText = styled(ContentHeaderName)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 39px;

  @media (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 5px;
  }
`;

export const ContentWrapper = styled.div`
  margin: 45px 58px 0 67px;

  @media (max-width: 768px) {
    margin: 15px 20px 0 20px;
  }

  @media (max-width: 480px) {
    margin: 0 24px;
  }
`;

export const ContentName = styled(ContentHeaderText)`
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 32px;

  @media (max-width: 480px) {
    margin-bottom: 37px;
  }
`;

export const FlexGrid = styled(Flex)`
  flex-wrap: wrap;

  // style last child in flex row
  @media (min-width: 1440px) and (max-width: 1800px) {
    & > div:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: 1080px) and (max-width: 1439px) {
    & > div:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
export const FlexGridSmall = styled(FlexGrid)`
  margin-right: 24px;
`;

export const ExpButton = styled(Button)`
  padding: 20px 80px !important;
  margin-bottom: 50px !important;
  font-size: 16px !important;
  line-height: 19px !important;
  border-radius: 8px !important;
  flex: 1 1 0;
  white-space: nowrap;

  &:first-child {
    margin-right: 15px;
  }
`;
