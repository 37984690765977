import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Typography, IconButton } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Name,
  FlexColumn,
  Subtitle,
  ContainerItemHeader,
  ContainerItemContent,
  ContainerItemFooter,
  ContainerItem,
  ContainerInfo,
  LiquidityStatsHeader,
  LiquidityStatsSubtitle,
  FooterInfoContainer,
  FooterInfoText,
} from "components/Exchange/styles";
import { TOKEN_INFO, Routers, exchangeTokenList } from "blockchain/tokenInfo";
import ExchangeButton from "components/buttons/ExchangeButton";
import { getSinglePair } from "utils/web3/exchange";
import TokenAmount from "utils/web3/models/tokenAmount";
import { notEmpty } from "utils/helpers";
import { usePrevious } from "utils/customHooks";
import { getBalanceOf, getAllowanceOf } from "actions";
import GlobalSettings from "../GlobalSettings";
import ContainerInput from "../ContainerInput";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "434px",
  },
  iconButton: {
    borderRadius: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    height: "32px",
    width: "32px",
    backgroundColor: "#6e43e6",
  },
  plus: {
    textAlign: "center",
    margin: "10px 0 0",
  },
  firstProvider: {
    borderRadius: "15px",
    backgroundColor: "#FFB2371F",
    marginBottom: "20px",
    "& div": {
      "& svg": {
        fill: "#FFB237",
      },
    },
  },
  alertTitle: {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Work Sans",
  },
  alertContent: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Work Sans",
    marginBottom: "8px",
  },
  LPheader: {
    fontSize: "22px",
  },
  LPsub: {
    marginBottom: "10px",
    fontSize: "16px",
  },
}));

const Add = ({
  connected,
  badNetwork,
  connectHandler,
  settingsHandler,
  liquidityHandler,
  approveHandler,
  success,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const allowances = useSelector((state) => state.allowances);
  const balances = useSelector((state) => state.balances);
  const web3 = useSelector((state) => state.web3);

  const [currencies, setCurrencies] = useState([]);
  const [curA, setACurrency] = useState("");
  const prevCurA = usePrevious(curA);
  const [curAAllowance, setCurAAllowance] = useState("");
  const [curABalance, setCurABalance] = useState("");
  const [curB, setBCurrency] = useState("");
  const prevCurB = usePrevious(curB);
  const [curBAllowance, setCurBAllowance] = useState("");
  const [curBBalance, setCurBBalance] = useState("");
  const [newPair, setNewPair] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [amountA, setAmountA] = useState(0);
  const [amountB, setAmountB] = useState(0);
  const [tokenPair, setTokenPair] = useState(false);
  const [amountInFromCurrency, setAmountInFromCurrency] = useState(true);

  useEffect(() => {
    setCurrencies(exchangeTokenList(web3.chainId));
  }, [web3.chainId]);

  useEffect(() => {
    if (params.from) {
      const CurrencyIndexA = currencies.findIndex((el) => el.address === params.from);
      if (CurrencyIndexA > -1) {
        setACurrency(CurrencyIndexA);
      }
    }
    if (params.to) {
      const CurrencyIndexB = currencies.findIndex((el) => el.address === params.to);
      if (CurrencyIndexB > -1) {
        setBCurrency(CurrencyIndexB);
      }
    }
  }, [currencies]);

  useEffect(() => {
    if (params.lpAmount && tokenPair) {
      const lpShare = params.lpAmount / tokenPair.balance.toFixed();
      let tokenA = 0;
      if (
        tokenPair.token0.equals(currencies[curA]) ||
        tokenPair.token1.equals(currencies[curB])
      ) {
        tokenA = Math.ceil(tokenPair?.reserve0?.toExact() * lpShare);
      } else {
        tokenA = Math.ceil(tokenPair?.reserve1?.toExact() * lpShare);
      }

      setAmountA(tokenA);
    }
  }, [params.lpAmount, tokenPair]);

  useEffect(() => {
    if (tokenPair) {
      const token0 = tokenPair.reversePair ? tokenPair.token1 : tokenPair.token0;
      const token1 = tokenPair.reversePair ? tokenPair.token0 : tokenPair.token1;
      if (allowances[token0.address]) {
        setCurAAllowance(
          parseFloat(
            new TokenAmount(
              token0,
              allowances[token0.address][Routers[web3.chainId]] || "0"
            ).toFixed()
          )
        );
      }
      if (balances[token0.address]) {
        setCurABalance(
          parseFloat(new TokenAmount(token0, balances[token0.address]).toFixed())
        );
      }
      if (allowances[token1.address]) {
        setCurBAllowance(
          parseFloat(
            new TokenAmount(
              token1,
              allowances[token1.address][Routers[web3.chainId]] || "0"
            ).toFixed()
          )
        );
      }
      if (balances[token1.address]) {
        setCurBBalance(
          parseFloat(new TokenAmount(token1, balances[token1.address]).toFixed())
        );
      }
    }
  }, [tokenPair, balances, allowances]);

  useEffect(() => {
    if (tokenPair && web3.connected) {
      dispatch(getBalanceOf(tokenPair.token0.address));
      dispatch(getBalanceOf(tokenPair.token1.address));
      dispatch(getAllowanceOf(tokenPair.token1.address, Routers[web3.chainId]));
      dispatch(getAllowanceOf(tokenPair.token0.address, Routers[web3.chainId]));
    }
  }, [tokenPair, web3.connected]);

  let suggestedAAmount = amountA;
  let suggestedBAmount = amountB;
  if (!newPair) {
    if (amountInFromCurrency) {
      if (exchangeRate > 0) {
        suggestedBAmount = (parseFloat(amountA) / exchangeRate).toPrecision(6) / 1;
      }
    } else if (exchangeRate > 0) {
      suggestedAAmount = (parseFloat(amountB) * exchangeRate).toPrecision(6) / 1;
    }
  }
  // userRate
  let userRate = newPair ? amountA / amountB : exchangeRate;
  if (newPair && (!amountB || !amountA)) {
    userRate = false;
  }

  useEffect(() => {
    if (
      currencies.length > 0 &&
      notEmpty(curB) &&
      notEmpty(curA) &&
      prevCurB !== curA &&
      prevCurA !== curB
    ) {
      getSinglePair(currencies[curA], currencies[curB]).then((res) => {
        setNewPair(res.reserve0.toExact() === "0");
        setTokenPair(res);
      });
    }
  }, [curA, curB, currencies, success]);

  useEffect(() => {
    if (tokenPair) {
      if (
        tokenPair.token0.equals(currencies[curA]) ||
        tokenPair.token1.equals(currencies[curB])
      ) {
        setExchangeRate(tokenPair.reserve0.toExact() / tokenPair.reserve1.toExact());
      } else {
        setExchangeRate(tokenPair.reserve1.toExact() / tokenPair.reserve0.toExact());
      }
    }
  }, [tokenPair]);

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handleAmountAChange = (e) => {
    if (!isNumber(e) && e.length > 0) {
      return;
    }

    setAmountA(e);
    setAmountInFromCurrency(true);
  };

  const handleAmountBChange = (e) => {
    if (!isNumber(e) && e.length > 0) {
      return;
    }

    setAmountB(e);
    setAmountInFromCurrency(false);
  };

  const handleACurrencyChange = (e) => {
    if (e !== curB) {
      setACurrency(e);
    }
  };

  const handleBCurrencyChange = (e) => {
    if (e !== curA) {
      setBCurrency(e);
    }
  };
  let share = "?";
  let total = "";
  if (tokenPair) {
    if (
      tokenPair.token0.equals(currencies[curA]) ||
      tokenPair.token1.equals(currencies[curB])
    ) {
      share =
        Math.round((suggestedAAmount / tokenPair?.reserve0?.toExact()) * 1000000) / 10000;
    } else {
      share =
        Math.round((suggestedAAmount / tokenPair?.reserve1?.toExact()) * 1000000) / 10000;
    }
    total = Math.floor(tokenPair.balance.toFixed() * share) / 100;
  }

  return (
    <FlexColumn>
      <ContainerItem elevation={3} className={classes.root}>
        <ContainerItemHeader>
          <FlexColumn>
            <IconButton onClick={() => history.push("/xchange/liquidity")}>
              <ArrowBackIcon />
            </IconButton>
          </FlexColumn>
          <FlexColumn>
            <Name>Add Liquidity</Name>
            <Subtitle>Add liquidity to receive LP tokens</Subtitle>
          </FlexColumn>
          <GlobalSettings />
        </ContainerItemHeader>
        <ContainerItemContent>
          {newPair && (
            <Alert
              variant="outlined"
              severity="warning"
              className={classes.firstProvider}
            >
              <AlertTitle className={classes.alertTitle}>
                You are the first liquidity provider.
              </AlertTitle>
              <Typography variant="body2" className={classes.alertContent}>
                The ratio of tokens you add will set the price of this pool.
              </Typography>
              <Typography variant="body2" className={classes.alertContent}>
                Once you are happy with the rate click supply to review.
              </Typography>
            </Alert>
          )}
          <ContainerInput
            label="Amount"
            currencyOptions={currencies}
            selectedCurrency={curA}
            onChangeCurrency={(e) => handleACurrencyChange(e.target.value)}
            onChangeAmount={(e) => handleAmountAChange(e.target?.value ?? e)}
            amount={suggestedAAmount || ""}
            balance={curABalance}
            amountInFromCurrency={amountInFromCurrency}
            token={tokenPair.reversePair ? tokenPair.token1 : tokenPair.token0}
          />
          <Typography variant="h5" className={classes.plus}>
            +
          </Typography>
          <ContainerInput
            label="Amount"
            currencyOptions={currencies}
            selectedCurrency={curB}
            onChangeCurrency={(e) => handleBCurrencyChange(e.target.value)}
            onChangeAmount={(e) => handleAmountBChange(e.target?.value ?? e)}
            amount={suggestedBAmount || ""}
            balance={curBBalance}
            amountInFromCurrency={amountInFromCurrency}
            token={tokenPair.reversePair ? tokenPair.token0 : tokenPair.token1}
          />

          {!!userRate && (
            <ContainerInfo>
              {!!total && (
                <>
                  <LiquidityStatsHeader className={classes.LPheader}>
                    {total}
                  </LiquidityStatsHeader>
                  <LiquidityStatsSubtitle className={classes.LPsub}>
                    {tokenPair.reversePair
                      ? `${tokenPair.token1.symbol}/${tokenPair.token0.symbol}`
                      : `${tokenPair.token0.symbol}/${tokenPair.token1.symbol}`}{" "}
                    Pool Tokens
                  </LiquidityStatsSubtitle>
                </>
              )}
              <Grid container justifyContent="space-around">
                <Grid item xs={4}>
                  <LiquidityStatsHeader>
                    {Math.round(userRate * 1000000) / 1000000}
                  </LiquidityStatsHeader>
                  <LiquidityStatsSubtitle>
                    {TOKEN_INFO[currencies[curA].contract_name]?.symbol ||
                      currencies[curA].symbol}{" "}
                    per{" "}
                    {TOKEN_INFO[currencies[curB].contract_name]?.symbol ||
                      currencies[curB].symbol}
                  </LiquidityStatsSubtitle>
                </Grid>
                <Grid item xs={4}>
                  <LiquidityStatsHeader>
                    {Math.round((1 / userRate) * 1000000) / 1000000}
                  </LiquidityStatsHeader>
                  <LiquidityStatsSubtitle>
                    {TOKEN_INFO[currencies[curB]]?.symbol || currencies[curB].symbol} per{" "}
                    {TOKEN_INFO[currencies[curA]]?.symbol || currencies[curA].symbol}
                  </LiquidityStatsSubtitle>
                </Grid>
                <Grid item xs={4}>
                  <LiquidityStatsHeader>{newPair ? "100" : share}%</LiquidityStatsHeader>
                  <LiquidityStatsSubtitle>Share of Pool</LiquidityStatsSubtitle>
                </Grid>
              </Grid>
            </ContainerInfo>
          )}
        </ContainerItemContent>
        <ContainerItemFooter>
          <ExchangeButton
            //loading={buttonIsLoading}
            //allowanceHandler={() => allowanceHandler(saleId)}
            success={success}
            connected={connected}
            badNetwork={badNetwork}
            connectHandler={connectHandler}
            fromAmount={suggestedAAmount}
            fromBalance={curABalance}
            fromApproval={curAAllowance}
            fromCurrency={currencies[curA]}
            toAmount={suggestedBAmount}
            toBalance={curBBalance}
            toApproval={curBAllowance}
            toCurrency={currencies[curB]}
            adding
            swapHandler={() =>
              liquidityHandler({
                mode: "Add",
                pair: tokenPair,
                amounts: [suggestedAAmount, suggestedBAmount],
                share,
              })
            }
            approveHandler={(n) => approveHandler(n)}
          />
        </ContainerItemFooter>
      </ContainerItem>
      <FooterInfoContainer>
        <FooterInfoText>
          By adding liquidity you'll earn 0.17% of all trades on this pair proportional to
          your share of the pool. Fees are added to the pool, accrue in real time and can
          be claimed by withdrawing your liquidity.
        </FooterInfoText>
      </FooterInfoContainer>
    </FlexColumn>
  );
};

export default Add;
