import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { DialogText } from "./styles";
import useScroll from "../../../hooks/useScroll";

const TermsOfService = ({ handler }) => {
  const ref = useScroll(handler);

  return (
    <DialogText ref={ref}>
      <Grid container>
        <Grid item xs={12} className="register__TOS-title">
          <Typography color="inherit" variant="display1">
            Terms of Service
          </Typography>
          {/* content start */}
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="display1">
              User agreement
            </Typography>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              1. REGISTRATION AS A SUBSCRIBER; APPLICATION OF TERMS TO YOU; YOUR ACCOUNT
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change(&quot;Site&quot; or www.tapproject.net) is an online service
                offered by Game X Change - &quot;Truly Simplistic Innovations Inc.&quot;
                (&quot;Game X Change&quot; or &quot;we&quot;).
                <br />
                You become a subscriber of Game X Change (&quot;Subscriber&quot;) by
                registering for a user account with Game X Change. This User Agreement,
                along with the Privacy Policy, applies to all users of Game X Change. Game
                X Change is offered to you on the condition of your acceptance without
                modification of the terms, conditions, and notices contained in this User
                Agreement.Your indication of acceptance of this User Agreement during the
                registration process constitutes your agreement to all such terms of this
                User Agreement.
                <br />
                <br />
                A. Contracting Party
                <br />
                For any interaction with Game X Change your contractual relationship is
                with Game X Change.
                <br />
                All transactions for Subscriptions (defined below) you make on The Tap
                Platform are transactions entered into with Game X Change, unless
                otherwise indicated at the time of the transaction (such as in the case of
                purchases from another Subscriber in a Subscription Marketplace).
                <br />
                <br />
                B. Subscriptions; Content and Services
                <br />
                As a Subscriber you may obtain access to certain services, software and
                content available to Subscribers. Game X Change client software and any
                other software, content, and updates you download or access via The Tap
                Platform, including but not limited to Game X Change or third-party video
                games and in-game content, and any virtual items or currency you trade,
                sell or purchase in a Game X Change Subscription Marketplace are referred
                to in this Agreement as &quot;Content and Services&quot;. The rights to
                access and/or use any Contents and Services accessible through Game X
                Change are referred to in this Agreement as &quot;Subscriptions.&quot;
                <br />
                Each Subscription allows you to access particular Content and Services.
                Some Subscriptions may impose additional terms specific to that
                Subscription (&quot;Subscription Terms&quot;) (for example, an end user
                license agreement specific to a particular game, User Agreement specific
                to a particular product or feature of Game X Change). Game X Change
                Privacy Policy and Game X Change User Agreement are binding on you once
                you indicate your acceptance of them or of this Agreement.
                <br />
                <br />
                C. Your Account
                <br />
                When you complete Game X Change’s registration process, you create a Game
                X Change account (&quot;Account&quot;). You may not reveal, share or
                otherwise allow others to use your password or Account except as otherwise
                specifically authorized by Game X Change. You are responsible for the
                confidentiality of your login and password and for the security of your
                computer system. Game X Change is not responsible for the use of your
                password and Account or for all of the communication and activity on The
                Game X Change that results from use of your login name and password by
                you, by any person to whom you may have intentionally or through
                negligence disclosed your login and/or password in violation of this
                confidentiality provision. Unless it results from Game X Change&apos;s
                negligence or fault, Game X Change is not responsible for the use of your
                Account by a person who fraudulently used your login and password without
                your permission. If you believe that the confidentiality of your login
                and/or password may have been compromised, you must notify Game X Change
                via the support form or contact email at{" "}
                <a href="mailto:team@tapproject.net">team@tapproject.net</a> or{" "}
                <a href="mailto:contact@tapproject.net">contact@tapproject.net</a>. Your
                Account, including any information pertaining to it (e.g.: contact
                information, Account history and Subscriptions, etc.), is strictly
                personal. You may therefore not sell or charge others for the right to use
                your Account, or otherwise transfer your Account. You also may not sell,
                or charge others for the right to use, or otherwise transfer any of your
                Subscriptions, except as expressly permitted by this Agreement or with the
                express consent of Game X Change.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              2. BILLING, PAYMENT AND USE OF BLOCKCHAIN WALLETS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                All charges incurred on Game X Change, and all purchases made with The
                Game X Change Wallet, are final and payable in advance.
                <br />
                <br />
                A. Payment Authorization
                <br />
                When you provide your wallet address and information to Game X Change or
                to one of its processors, you represent to Game X Change that you are the
                authorized user of the address, key or account associated with that
                payment. Game X Change may require you to provide your address or other
                information in order to meet their obligations under applicable tax law.
                Game X Change does not hold access to your private key. The subscriber is
                responsible for protecting and guarding access to their wallet, their Game
                X Change Wallet (defined below) or any other wallets associated to Game X
                Change.
                <br />
                If your use of Game X Change is subject to any type of use or sales tax,
                then Game X Change may also charge you for those taxes, in addition to the
                Subscription and other fees published in the User Agreement. The
                subscriber understands that every conversion is subject to a transactional
                fee and/or gas fee on both the ethereum network and Game X Change. It is
                acknowledged that every transaction is subject to a fee/gas and the
                fees/gas will be taken off during the transaction.
                <br />
                You agree that you will not use IP proxying or other methods to disguise
                the place of your residence, whether to circumvent geographical
                restrictions on game content, to purchase at pricing not applicable to
                your geographical location, or for any other purpose. If you do this, The
                Game X Change may terminate your access to your Account.
                <br />
                <br />
                B. Responsibility for Charges Associated With Your Account
                <br />
                As the Subscription holder, you are responsible for all charges incurred,
                including applicable taxes, and all purchases or conversions made by you
                or anyone that uses your Account, including your family or friends. If you
                cancel your Account, Game X Change reserves the right to collect fees,
                surcharges or costs incurred before cancellation. Any delinquent or unpaid
                Accounts must be settled before Game X Change will allow you to register
                again.
                <br />
                <br />
                C. Game X Change Wallet
                <br />
                Game X Change may make available an account balance associated with your
                Account (the &quot;Game X Change Wallet&quot;). Game X Change Wallet is
                neither a bank account nor any kind of payment instrument. Game X Change
                Wallet is a smart wallet that is written in the form of a smart contract
                on the Ethereum network. Game X Change Wallet does not house your private
                keys. The subscriber remains in full control of his/her own private keys
                and they are used to sign transactions with your smart wallet. Game X
                Change may change or impose different balance and usage limits on Game X
                Change Wallet from time to time.
                <br />
                Your continued use of your Game X Change Account for more than thirty (30)
                days after the execution of such changes, will constitute your acceptance
                of the changes. If you don’t agree to the changes, you may terminate your
                Game X Change Account and/or cease to use your Game X Change Wallet. The
                Game X Change cannot refund any credits remaining on your Game X Change
                wallet as the ability to access an individuals Game X Change Wallet
                remains solely with each user.
                <br />
                You may use Tap Coins to purchase Subscriptions, including making in-game
                purchases where Game X Change Wallet transactions are enabled, and Tap
                Platform Hardware. Funds added to Game X Change Wallet are non-refundable
                and are transferable to other Ethereum based wallets. Tap Coins do not
                constitute a personal property right. Tap Coins can be used to purchase
                Subscriptions, join tournaments, purchase avatars, keys, and be used in
                any functions or related content via Game X Change (including but not
                limited to games and other applications offered through Game X Change
                Store, or in Game X Change Subscription Marketplace) and Hardware. The
                Subscriber understands that if they choose to convert their game score
                into Tap Coins, that score, and associated points, virtual currency,
                skins, assets, or environments, as applicable, will no longer belong to
                the Subscriber and will no longer appear in their game.
                <br />
                <br />
                D. Free Subscriptions
                <br />
                In some cases, Game X Change may offer a free Subscription to certain
                services, software and content. As with all Subscriptions, you are always
                responsible for any Internet service provider, telephone, and other
                connection fees that you may incur when using Game X Change, even when The
                Game X Change offers a free Subscription.
                <br />
                <br />
                E. Third Party Sites
                <br />
                Game X Change may provide links to other third party sites. Some of these
                sites may charge separate fees, which are not included in and are in
                addition to any Subscription or other fees that you may pay to The Tap
                Project. Game X Change may also provide access to third-party vendors, who
                provide content, goods and/or services on Game X Change or the Internet.
                Any separate charges or obligations you incur in your dealings with these
                third parties are your responsibility. Game X Change makes no
                representation or warranty that any service or subscription offered via
                third-party vendors will not change or be suspended or terminated.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              3. ONLINE CONDUCT, CHEATING AND ILLEGAL BEHAVIOR
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Your online conduct and interaction with other Subscribers should be
                guided by common sense and basic etiquette. Depending on User Agreements
                imposed by third parties who host particular games or other services,
                additional requirements may also be applicable to a particular
                Subscription.
                <br />
                Game X Change and the Content and Services may include functionality
                designed to identify software or hardware processes or functionality that
                may give a player an unfair competitive advantage when playing multiplayer
                versions of any Content and Services or modifications of Content and
                Services (&quot;Cheats&quot;). You agree that you will not create Cheats
                or assist third parties in any way to create or use Cheats. You agree that
                you will not directly or indirectly disable, circumvent, or otherwise
                interfere with the operation of software designed to prevent or report the
                use of Cheats. You acknowledge and agree that either Game X Change or any
                online multiplayer host may refuse to allow you to participate in certain
                online multiplayer games if you use Cheats in connection with The Game X
                Change or the Content and Services. Further, you acknowledge and agree
                that an online multiplayer host may report your use of Cheats to The Game
                X Change, and Game X Change may communicate your history of use of Cheats
                to other online multiplayer hosts. Game X Change may terminate your
                Account or a particular Subscription for any conduct or activity that is
                illegal, constitutes a Cheat, or otherwise negatively affects the
                enjoyment of Game X Change by other Subscribers. You acknowledge that The
                Game X Change is not required to provide you notice before terminating
                your Subscription(s) and/or Account.
                <br />
                You may not use Cheats, automation software (bots), mods, hacks, or any
                other unauthorized third-party software, to modify or automate any
                Subscription Marketplace process.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              4. Game X Change COPYRIGHTS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change and contents and elements of Game X Change (including, e.g.
                both editorial content and the code used in programming the platform) are
                protected by Canadian and international copyright laws. Except as
                explicitly provided in this User Agreement, you may not reproduce,
                distribute, transmit, display, prepare derivative works, perform, or use
                in any other way that is prohibited by copyright law without consent of
                the copyright holder, any copyrighted material found on or in The Tap
                Project’s digital properties. You may copy and print content for your
                personal, non-commercial use only, provided that you include all copyright
                and other notices contained in the content and that you do not modify the
                content. To request permission from Game X Change for any other use,
                please email us at info@tapproject.net.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              5. Game X Change MARKS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change, Game X Change program names (including The Tap Platform)
                and logos are trademarks or service marks of Game X Change or its
                partners. Game X Change reserves all rights to these marks. You may not
                use any of Game X Change marks, logos, or graphics without the prior
                written consent of Game X Change. All other trademarks and service marks
                appearing on the digital platform are the property of their respective
                owners, and should be used only with prior written consent from the
                appropriate owner.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              6. PRIVACY AND DATA PROTECTION
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Changeis committed to safeguarding the privacy of your personal
                information. Your use of www.tapproject.net is subject to Game X
                Change&apos;s Privacy Policy. Please review our Privacy Policy, which also
                governs Game X Change and informs users of our data collection practices.
                You can access our Privacy Policy here:
                <a href="https://tapproject.net/#privacy_policy">
                  https://tapproject.net/#privacy_policy
                </a>
                . Our Privacy Policy includes terms for our users based in the European
                Economic Area (EEA) who are subject to the requirements of the EU General
                Data Protection Regulation.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              7. ELECTRONIC COMMUNICATIONS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Visiting www.tapproject.net or sending emails to Game X Change constitutes
                electronic communications. You consent to receive electronic
                communications and you agree that all agreements, notices, disclosures and
                other communications that we provide to you electronically, via email and
                on Game X Change, satisfy any legal requirement that such communications
                be in writing.
                <br />
                Game X Change does not knowingly collect, either online or offline,
                personal information from persons under the age of thirteen. If you are
                under 18, you may use www.tapproject.net only with permission of a parent
                or guardian.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              8. NO UNLAWFUL OR PROHIBITED USE OF INTELLECTUAL PROPERTY
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                You are granted a non-exclusive, non-transferable, revocable license to
                access and use www.tapproject.net strictly in accordance with thisUser
                Agreement. As a condition of your use of Game X Change, you warrant to The
                Game X Change that you will not use Game X Change for any purpose that is
                unlawful or prohibited by this User Agreement. You may not use Game X
                Change in any manner which could damage, disable, overburden, or impair
                Game X Change or interfere with any other party&apos;s use and enjoyment
                of Game X Change. You may not obtain or attempt to obtain any materials or
                information through any means not intentionally made available or provided
                for through Game X Change.
                <br />
                All content included as part of yourSubscription, such as text, graphics,
                logos, images, as well as the compilation thereof, and any software used
                on Game X Change, is the property of Game X Change or its suppliers and
                protected by copyright and other laws that protect intellectual property
                and proprietary rights. You agree to observe and abide by all copyright
                and other proprietary notices, legends or other restrictions contained in
                any such content and will not make any changes thereto.
                <br />
                You will not modify, publish, transmit, reverse engineer, participate in
                the transfer or sale, create derivative works, or in any way exploit any
                of the content, in whole or in part, found on Game X Change. The Tap
                Project’s content is not for resale. Your use of Game X Change does not
                entitle you to make any unauthorized use of any protected content, and in
                particular you will not delete or alter any proprietary rights or
                attribution notices in any content. You will use protected content solely
                for your personal use, and will make no other use of the content without
                the express written permission of Game X Change and the copyright owner.
                You agree that you do not acquire any ownership rights in any protected
                content. We do not grant you any licenses, express or implied, to the
                intellectual property of Game X Change or our licensors except as
                expressly authorized by this User Agreement.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              9. INTERNATIONAL USERS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change is controlled, operated and administered by Game X Change
                from our offices within Canada. If you access Game X Change from a
                location outside Canada, you are responsible for compliance with all local
                laws. You agree that you will not use Game X Change content accessed
                through www.tapproject.net in any country or in any manner prohibited by
                any applicable laws, restrictions or regulations.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              10. INDEMNIFICATION
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                You agree to indemnify, defend and hold harmless Game X Change, its
                officers, directors, employees, agents and third parties, for any losses,
                costs, liabilities and expenses (including reasonable attorney&apos;s
                fees) relating to or arising out of your use of or inability to use The
                Game X Change or services, any user postings made by you, your violation
                of any terms of this User Agreement or your violation of any rights of a
                third party, or your violation of any applicable laws, rules or
                regulations. Game X Change reserves the right, at its own cost, to assume
                the exclusive defense and control of any matter otherwise subject to
                indemnification by you, in which event you will fully cooperate with Game
                X Change in asserting any available defenses.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              11. ARBITRATION
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                In the event the parties are not able to resolve any dispute between them
                arising out of or concerning this User Agreement, or any provisions
                hereof, whether in contract, tort, or otherwise at law or in equity for
                damages or any other relief, then such dispute shall be resolved only by
                final and binding arbitration pursuant to arbitration in the Province of
                British Columbia. The Arbitrator’s award shall be final, and judgment may
                be entered upon it in any court having jurisdiction. In the event that any
                legal or equitable action, proceeding or arbitration arises out of or
                concerns this User Agreement, the prevailing party shall be entitled to
                recover its costs and reasonable attorney&apos;s fees. The parties agree
                to arbitrate all disputes and claims in regards to this User Agreement or
                any disputes arising as a result of this User Agreement, whether directly
                or indirectly, including Tort claims that are a result of this User
                Agreement. The entire dispute, including the scope and enforceability of
                this arbitration provision shall be determined by the Arbitrator. This
                arbitration provision shall survive the termination of this User
                Agreement.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              12. LIABILITY DISCLAIMER
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                The information, software, products, and services included in or available
                through Game X Change may include inaccuracies or typographical errors.
                Changes are periodically added to the information herein. Game X Change
                and/or its suppliers may make improvements and/or changes to The Tap
                Platform at any time.
                <br />
                Game X Change and/or its suppliers make no representations about the
                suitability, reliability, availability, timeliness, and accuracy of the
                information, software, products, services and related graphics contained
                on Game X Change for any purpose. To the maximum extent permitted by
                applicable law, all such information, software, products, services and
                related graphics are provided &quot;as is&quot; without warranty or
                condition of any kind. Game X Change and/or its suppliers hereby disclaim
                all warranties and conditions with regard to this information, software,
                products, services and related graphics, including all implied warranties
                or conditions of merchantability, fitness for a particular purpose, title
                and non-infringement.
                <br />
                To the maximum extent permitted by applicable law, in no event shall The
                Game X Change and/or its suppliers be liable for any direct, indirect,
                punitive, incidental, special, consequential damages or any damages
                whatsoever including, without limitation, damages for loss of use, data or
                profits, arising out of or in any way connected with the use or
                performance of Game X Change, with the delay or inability to use Game X
                Change or related services, the provision of or failure to provide
                services, or for any information, software, products, services and related
                graphics obtained through Game X Change, or otherwise arising out of the
                use of Game X Change, whether based on contract, tort, negligence, strict
                liability or otherwise, even if Game X Change or any of its suppliers has
                been advised of the possibility of damages. If you are dissatisfied with
                any portion of Game X Change, or with thisUser Agreement, your sole and
                exclusive remedy is to discontinue using Game X Change.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              13. LIMITATION OF LIABILITY
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                In no event will Game X Change, its directors, officers, or employees be
                liable for any indirect, consequential, special, incidental, or punitive
                damages, arising out of the use or inability to use the digital platform.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              14. COPYRIGHT AGENT
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                If you believe that your work has been reproduced on our digital platform
                in a way that constitutes copyright infringement, please provide the
                following information in writing to Game X Change:
                <br />
                a. A physical or electronic signature of a person authorized to act on
                behalf of the owner of the copyright.
                <br />
                b. A description of the copyrighted work that you claim has been
                infringed.
                <br />
                c. A description of the material that you claim to be infringing or to be
                the subject of infringing activity and that is to be removed or access to
                which is to be disabled, and information sufficient to permit us to locate
                the material.
                <br />
                d. Your contact information, such as address, telephone number, and e-mail
                address.
                <br />
                e. A statement that you have a good faith belief that use of the material
                in the manner complained of is not authorized by the copyright owner, its
                agent, or the law.
                <br />
                f. A statement that the information in the notification is accurate and,
                under penalty of perjury, that you are the copyright owner or are
                authorized to act on behalf of the owner of a copyright that is allegedly
                infringed.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              15. TERMINATION AND RESTRICTION OF ACCESS
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change reserves the right, in its sole discretion, to terminate or
                restrict your access to Game X Change and the related services or any
                portion thereof at any time, without notice. To the maximum extent
                permitted by law, this agreement is governed by the laws of the Province
                of British Columbia and you hereby consent to the exclusive jurisdiction
                and venue of courts in the Province of British Columbia in all disputes
                arising out of or relating to the use of Game X Change. Use of The Tap
                Platform is unauthorized in any jurisdiction that does not give effect to
                all provisions of this User Agreement, including, without limitation, this
                section.
                <br />
                You agree that no joint venture, partnership, employment, or agency
                relationship exists between you and Game X Change as a result of this
                agreement or use of Game X Change. Game X Change&apos;s performance of
                this agreement is subject to existing laws and legal process, and nothing
                contained in this agreement is in derogation of Game X Change&apos;s right
                to comply with governmental, court and law enforcement requests or
                requirements relating to your use of Game X Change or information provided
                to or gathered by Game X Change with respect to such use. If any part of
                this agreement is determined to be invalid or unenforceable pursuant to
                applicable law including, but not limited to, the warranty disclaimers and
                liability limitations set forth above, then the invalid or unenforceable
                provision will be deemed superseded by a valid, enforceable provision that
                most closely matches the intent of the original provision and the
                remainder of the agreement shall continue in effect.
                <br />
                Unless otherwise specified herein, this agreement constitutes the entire
                agreement between the user and Game X Change with respect to The Tap
                Platform and it supersedes all prior or contemporaneous communications and
                proposals, whether electronic, oral or written, between the user and The
                Game X Change with respect to Game X Change. A printed version of this
                agreement and of any notice given in electronic form shall be admissible
                in judicial or administrative proceedings based upon or relating to this
                agreement to the same extent and subject to the same conditions as other
                business documents and records originally generated and maintained in
                printed form. It is the express wish of the parties that this agreement
                and all related documents be written in English.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Typography color="inherit" variant="body1">
              16. CONTACT US
            </Typography>
            <Grid item xs={12} className="mt--16">
              <Typography color="inherit">
                Game X Change welcomes your questions or comments regarding this User
                Agreement:
                <br />
                Email Address:
                <br />
                info@tapproject.net
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogText>
  );
};

export default TermsOfService;
