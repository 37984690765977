import {
  GET_LTNFTS,
  GET_PLTNFTS,
  GET_NFTS,
  GET_FEATURED_NFTS,
  GET_SPONSORED_NFT,
} from "../actions/types";

const initState = {
  nfts: [],
  ltNfts: [],
  pltNfts: [],
  featuredNfts: [],
  sponsoredNft: "",
};

const nft = (state = initState, action) => {
  switch (action.type) {
    case GET_LTNFTS: {
      return {
        ...state,
        ltNfts: action.nfts,
      };
    }
    case GET_PLTNFTS: {
      return {
        ...state,
        pltNfts: action.nfts,
      };
    }
    case GET_NFTS: {
      return {
        ...state,
        nfts: action.nfts,
      };
    }
    case GET_FEATURED_NFTS: {
      return {
        ...state,
        featuredNfts: action.nfts,
      };
    }
    case GET_SPONSORED_NFT: {
      return {
        ...state,
        sponsoredNft: action.nfts,
      };
    }
    default: {
      return state;
    }
  }
};

export default nft;
