import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import * as Info from "assets/info";
import GameDetails from "components/Dashboard/GameDetails";
import Footer from "components/Footer/index";
import WalletModal from "components/Modal/WalletModal";
import Snackbar from "components/Snackbar";
import useAppDataFetcher from "hooks/useAppDataFetcher";
import usePrevious from "hooks/usePrevious";
import UseRedirect from "hooks/useRedirect";
import useWeb3NetworkData from "hooks/useWeb3NetworkData";
import LayoutComponent from "routers/LayoutComponent";
import PrivateRoute from "routers/PrivateRoute";
import User from "routers/user";
import { getBearerTokenFromLocalStorage } from "utils/bearerToken";
import { IS_PROD } from "utils/environments";
import Publisher from "../../routers/publisher";
import AlertContainer from "../Alert";
import Marketplace from "../Marketplace/Marketplace";
import ModalContainer from "../Modal";
import VaultDetails from "../VaultDetails/VaultDetails";
import Achievement from "./Achievement";
import Dashboard from "./Dashboard";
import EmailVerification from "./EmailVerification";
import Forgot from "./Forgot";
import FullScreenLoading from "./FullScreenLoading";
import GetWallet from "./GetWallet";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignUpSteps from "./SignUpSteps";
import Staking from "./Staking";
import StakingArchive from "./StakingArchive";
import TOS from "./TOS";
import Exchange from "./Exchange";
import AddLiquidity from "./AddLiquidity";
import RemoveLiquidity from "./RemoveLiquidity";
import Liquidity from "./Liquidity";
import Leaderboards from "./Leaderboards";
import Redeem from "./Redeem";

const initFcWidget = (userId) => {
  const externalId = userId;

  // the fresh chat script runs async and therefore it is not guaranteed to exist
  // see index.html
  if (window.fcWidget && IS_PROD) {
    window.fcWidget.init({
      token: "59f029f6-dce9-4a75-9c09-f61493293ac6",
      host: "https://wchat.freshchat.com",
      externalId,
    });
  }
};

const App = (props) => {
  const { location } = props;
  const user = useSelector((state) => state.user);
  const router = useSelector((state) => state.router);

  const prevProps = usePrevious({ location }, {});

  useAppDataFetcher();
  useWeb3NetworkData();

  useEffect(() => {
    initFcWidget(user.id);
  }, [user.id]);

  /*if (location !== prevProps.location) {
    window.scrollTo(0, 0);
  }*/

  const isPub = user.role_id === Info.roles.publisher || false;
  const tapRoutes = () => (
    <Switch>
      <Route path="/forgot" component={Forgot} />
      <LayoutComponent exact path="/staking" component={Staking} />
      <LayoutComponent exact path="/staking/archive" component={StakingArchive} />
      <Route path="/reset_password/:token" component={ResetPassword} />
      <Route path="/account_welcome/:token" component={EmailVerification} />
      <LayoutComponent path="/wallet" component={GetWallet} />
      <LayoutComponent path="/market" component={Dashboard} />
      <LayoutComponent path="/staking" component={Staking} />
      <LayoutComponent exact path="/dashboard" component={Dashboard} />
      <LayoutComponent exact path="/dashboard/:id" component={Achievement} />
      <LayoutComponent exact path="/marketplace" component={Marketplace} />

      <LayoutComponent exact path="/xchange" component={Exchange} />
      <LayoutComponent path="/xchange/liquidity" component={Liquidity} />
      <LayoutComponent
        path="/xchange/add/:from?/:to?/:lpAmount?"
        component={AddLiquidity}
      />
      <LayoutComponent
        path="/xchange/remove/:chainId/:from/:to"
        component={RemoveLiquidity}
      />

      <PrivateRoute path="/vaults/:id" component={VaultDetails} />
      <LayoutComponent path="/game/:id" component={GameDetails} />
      <LayoutComponent path="/leaderboards" component={Leaderboards} />
      <Route path="/TOS" component={TOS} />
      {isPub && <Route path="/pub" component={Publisher} />}
      <PrivateRoute path="/usr" component={User} />
      {!user.isLoggedIn && <Route exact path="/sign-up" render={() => <SignUp />} />}
      {!user.isLoggedIn && <Route exact path="/sign-in" render={() => <SignIn />} />}
      {!user.isLoggedIn && (
        <Route exact path="/referral/:referralcode" render={() => <SignUp />} />
      )}
      <Route exact path="/redeem" render={() => <Redeem />} />
      <Route exact path="/sign-up/steps" render={() => <SignUpSteps />} />
      <Redirect to="/dashboard" />
    </Switch>
  );

  const renderRoutes = () => {
    // if there is a token present and the user is not yet logged in
    // we wait on rendering routes so that the app doesn't navigate to sign in page
    if (!user.isLoggedIn && getBearerTokenFromLocalStorage()) {
      return null;
    }

    return tapRoutes();
  };

  const renderFooter = () => {
    if (!router.location.pathname.includes("/onboarding")) {
      return <Footer />;
    }
    return null;
  };

  return (
    <div>
      <UseRedirect />
      <div
        className={`flex-base ${user.isLoggedIn ? "authenticated" : "unauthenticated"}`}
      >
        <div
          className={`body--container${router.location.pathname.split("/").join(" ")}`}
        >
          {renderRoutes()}
        </div>
        <WalletModal />
        <ModalContainer />
        <AlertContainer />
      </div>
      {renderFooter()}
      <Snackbar />
      <FullScreenLoading />
    </div>
  );
};

export default withRouter(App);
