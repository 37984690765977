import React from "react";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import { ReactComponent as ArrowIcon } from "images/icons/arrow.svg";
import { Arrow, ModalBodyContainer, TotalCount, useStyles } from "./styles";

const ModalBody = ({ fromValue, setFromValue, toValue, setToValue }) => {
  const classes = useStyles();

  const arrowHandler = () => {
    setToValue(fromValue);
  };

  const handlerChangeValue = (e) => {
    const { value } = e.target;

    if (/^-?\d*\.?\d*$/.test(value)) {
      setFromValue(value);
    }
  };

  return (
    <ModalBodyContainer>
      <TotalCount>51239 Score</TotalCount>
      <FormControl variant="outlined" className={classes.root}>
        <OutlinedInput
          id="inputFrom"
          value={fromValue}
          onChange={handlerChangeValue}
          endAdornment={
            <InputAdornment
              className={classes.endAdornment + " " + classes.endAdornmentAfter}
              position="end"
            >
              max
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          className={classes.input}
          labelWidth={0}
        />
      </FormControl>

      <Arrow onClick={arrowHandler}>
        <ArrowIcon />
      </Arrow>

      <FormControl variant="outlined" className={classes.root}>
        <OutlinedInput
          id="inputTo"
          value={toValue}
          endAdornment={
            <InputAdornment className={classes.endAdornment} position="end">
              GXP
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          className={classes.input}
          labelWidth={0}
        />
      </FormControl>
    </ModalBodyContainer>
  );
};

export default ModalBody;
