import React from "react";
import { LinearProgress, Grid } from "@material-ui/core";
import MaxLinesTextContainer from "components/MaxLinesTextContainer";
import { includesInArray } from "utils/helpers";
import {
  NftItem,
  NftText,
  NftDesc,
  NftTextBox,
  NftWrapper,
  NftBold,
  NftCount,
} from "../Dashboard/Achievement/styles";

const NFTContainer = ({ nftData, onClick, wide, children }) => {
  const { saleId, image, name, description, count, countMax } = nftData;
  const progress = Math.round(((countMax - count) / countMax) * 100);

  return (
    <NftItem wide={wide} onClick={onClick} key={saleId}>
      <NftWrapper wide={wide}>
        {includesInArray(image.split(".").pop(), ["mp4", "mov", "m4v"]) ? (
          <video autoPlay loop>
            <source src={image} type="video/mp4" />
          </video>
        ) : (
          <img src={image} alt="NFT" />
        )}
      </NftWrapper>
      <NftTextBox wide={wide}>
        {/*!!wide && <NftBadge>NFT</NftBadge>*/}
        <NftText variant="subtitle1">{name}</NftText>
        {!!count && !!countMax && (
          <Grid container direction="column">
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ margin: "10px 0 5px" }}
            />

            <NftCount>
              <NftText variant="subtitle2" style={{ fontWeight: "600" }}>
                {countMax} TOTAL
              </NftText>
              <NftText variant="subtitle2" style={{ fontWeight: "600" }}>
                {count} LEFT
              </NftText>
            </NftCount>
          </Grid>
        )}
        {description && (
          <MaxLinesTextContainer
            maxLines={4}
            styledComponent={NftDesc}
            style={{ color: "whitesmoke", fontWeight: "400" }}
            text={description}
          />
        )}

        {!!wide && children}
      </NftTextBox>
      {!wide && children}
    </NftItem>
  );
};

export default NFTContainer;
