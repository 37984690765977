import { useSelector } from "react-redux";

const useStakingData = () => {
  const list = useSelector(({ Staking }) => Staking.stakingList);
  const partnerList = useSelector(({ Staking }) => Staking.partnerList);

  return [list, partnerList];
};

export default useStakingData;
