import React from "react";
import { Link } from "react-router-dom";
import PUBG from "images/profile/stemPoint/PUBG.png";
import { useButtonStyles } from "../../../styles";
import {
  GameActions,
  GameDescription,
  GameItemBody,
  InnerTextShadow,
  GamesItem,
} from "../../Dashboard/styles";
import { NftButton } from "../../Marketplace/styles";
import { ChallengeName } from "../styles";

const SteamPoints = ({ onClickHandler, challengeVault }) => {
  const classes = useButtonStyles();
  const { earned_currency: earnedCurrency, gameId: challengeVaultId } = challengeVault;

  return (
    <Link style={{ width: "100%" }} to={`/vaults/${challengeVaultId}`}>
      <GamesItem elevation={3}>
        <img src={PUBG} alt="Unconverted Steam Points" />
        <InnerTextShadow />

        <GameItemBody>
          <ChallengeName>Steam Challenge Vault</ChallengeName>
          <GameDescription>Unconverted Points: {earnedCurrency ?? 0}</GameDescription>
          <GameActions>
            <NftButton
              className={classes.button}
              onClick={(e) => {
                onClickHandler(e, challengeVaultId);
              }}
            >
              Convert
            </NftButton>
          </GameActions>
        </GameItemBody>
      </GamesItem>
    </Link>
  );
};

export default SteamPoints;
