import React from "react";
import { useSelector } from "react-redux";
import { getDisplayVal, doBigMath } from "blockchain";
import { TOKEN_TYPES } from "blockchain/tokenInfo";
import {
  DialogActions,
  Dialog,
  FormHelperText,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import Text from "components/Text";
import { ReactComponent as Close } from "images/close.svg";
import DarkCoin from "images/header/GXP.png";
import { useStyles } from "../../Dashboard/Achievement/claimGamePoints/styles";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";
import Loader from "../../loaders";
import Info from "../../ContentElements/info";

const StakeModal = ({
  open,
  buttonLoading,
  fromValue,
  handlerChangeValue,
  index,
  closeHandler,
  amountError,
  successHandler,
  expectedReward,
  ...props
}) => {
  const modal = FormStyled();
  const classes = useStyles();

  const stakeInfo = useSelector((state) => state.Staking.stakingList[index]);
  const { contract, secondaryToken, primaryToken, id, type } = stakeInfo;
  const tokenAddr = secondaryToken.address;
  const nftAddr = primaryToken.address;
  const stakeAllowance = useSelector(
    (state) => state.allowances[tokenAddr]?.[contract] || 0
  );
  const secondaryBalance = useSelector((state) => state.balances[tokenAddr] || 0);
  const primaryBalance = stakeInfo.userNFTsStaked;
  const handlerInfo = {
    token: tokenAddr,
    nftAddr,
    balance: secondaryBalance,
    allowance: stakeAllowance,
    stakeAddress: contract,
    stakeId: id,
    type,
  };

  const renderFormHelper = () => {
    if (type === TOKEN_TYPES.ERC721) {
      return `${primaryBalance} ${primaryToken.symbol} &
      ${
        secondaryBalance
          ? doBigMath(
              secondaryToken.amount,
              secondaryToken.decimals,
              "*",
              stakeInfo.userNFTsStaked
            )
          : 0
      } ${secondaryToken.symbol}`;
    }
    return `${
      stakeInfo.userNFTsStaked
        ? getDisplayVal(stakeInfo.userNFTsStaked, secondaryToken.decimals, true)
        : 0
    } ${secondaryToken.symbol}`;
  };

  const renderFormPlaceholderHelper = () => {
    if (type === TOKEN_TYPES.ERC721) {
      return `1 ${primaryToken.symbol} + ${getDisplayVal(
        secondaryToken.amount,
        secondaryToken.decimals
      )} ${secondaryToken.symbol}`;
    }
  };

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>
      <DialogTitle>
        Withdraw{" "}
        <Info
          text={`1 ${primaryToken.symbol} and ${getDisplayVal(
            secondaryToken.amount,
            secondaryToken.decimals
          )} ${secondaryToken.symbol} is removed to withdraw`}
        />
      </DialogTitle>

      <ConvertModalContainer>
        <FormControl variant="outlined" className={classes.root}>
          <OutlinedInput
            id="inputFrom"
            value={fromValue}
            placeholder={
              type !== TOKEN_TYPES.ERC721 ? "0" : renderFormPlaceholderHelper()
            }
            disabled={type === TOKEN_TYPES.ERC721}
            onChange={(e) => handlerChangeValue(e, "unstake")}
            endAdornment={
              type !== TOKEN_TYPES.ERC721 && (
                <>
                  <InputAdornment
                    className={classes.endAdornment}
                    position="end"
                    onClick={(e) => handlerChangeValue(e, "max")}
                  >
                    Use Max
                  </InputAdornment>
                  <InputAdornment className={classes.endAdornmentAfter} position="end">
                    <img width="32" src={DarkCoin} alt="" />
                  </InputAdornment>
                </>
              )
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            className={`${classes.input}
            ${amountError && classes.error}`}
            labelWidth={0}
          />
          <FormHelperText>
            <Text variant="p3" className={classes.balance}>
              Deposited: {renderFormHelper()}
            </Text>
          </FormHelperText>
        </FormControl>
      </ConvertModalContainer>

      <DialogActions>
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => successHandler("withdraw", handlerInfo)}
          disabled={stakeInfo.userNFTsStaked == "0" || buttonLoading || amountError}
        >
          {amountError || `Withdraw ${primaryToken.symbol}`}
          {buttonLoading && <Loader />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeModal;
