import React from "react";
import { Grid, Paper, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import * as Web3Math from "blockchain/web3Utils";
import Text from "components/Text";

const EtheriumCard = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <Divider className="thin" />
    </Grid>
    <Grid item xs={12}>
      <Paper className="wallet__etherium-card">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="h5">Total</Text>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Text variant="h5">{Web3Math.round(props.ethBalance, 6)}</Text>
              </Grid>
              <Grid item>
                <Text variant="h5" color="textSecondary">
                  ETH
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12}>
      <Paper className="wallet__etherium-card">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="h5">Gas value</Text>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Text variant="h5">{props.gasPrice}</Text>
              </Grid>
              <Grid item>
                <Text variant="h5" color="textSecondary">
                  GWEI
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

EtheriumCard.propTypes = {
  ethBalance: PropTypes.number,
  gasPrice: PropTypes.number.isRequired,
};
EtheriumCard.defaultProps = {
  ethBalance: 0,
};

export default EtheriumCard;
