import Web3 from "web3";
import * as web3Calls from "utils/web3Calls";
import * as links from "utils/links";
import * as genericAxios from "../utils/genericAxios";
import { BLOCKCHAIN_INFO, BLOCKCHAINS, TOKEN_INFO } from "./tokenInfo";
import * as web3Utils from "./web3Utils";

const { utils } = Web3;
var { BN } = utils;
/**
 * Gets a token object from an address
 * @param {string} searchAddress
 * @returns {Object} token
 */
export const getTokenFromAddress = (searchAddress) => {
  for (const [key, token] of Object.entries(TOKEN_INFO)) {
    for (const [, address] of Object.entries(token.addresses)) {
      if (searchAddress.toLowerCase() == address.toLowerCase()) {
        token.key = key;
        return token;
      }
    }
  }
  return false;
};

/**
 * Gets a token object from an address by chain
 * @param {string} searchAddress
 * @param {string} chainId
 * @returns {Object} token
 */
export const getTokenFromAddressByChain = (searchAddress, chainId) => {
  for (const [key, token] of Object.entries(TOKEN_INFO)) {
    if (
      token.addresses[chainId] &&
      searchAddress.toLowerCase() == token.addresses[chainId].toLowerCase()
    ) {
      token.key = key;
      return token;
    }
  }
  return false;
};

/**
 *
 * @param {*} chain
 * @returns
 */
export const addChain = (chain) => {
  if (typeof chain === "number") {
    if (BLOCKCHAIN_INFO[chain]) {
      chain = BLOCKCHAIN_INFO[chain].chainParameters;
    }
  }
  return web3Utils.addChain(chain);
};

/**
 *
 * @param {*} chain
 * @returns
 */
export const selectedAddress = (chain) => {
  return web3Utils.selectedAddress();
};

/**
 *
 * @param {*} chain
 * @returns
 */
export const getChainBalance = (chain) => {
  return web3Utils.ethBalance();
};

/**
 *
 * @param {*} chain
 * @param {*} address
 * @returns
 */
export const isAddress = (chain, address) => {
  switch (chain) {
    case BLOCKCHAINS.binance:
    case BLOCKCHAINS.ethereum:
    default:
      const realAddress = address.length === 40 ? `0x${address}` : address;
      return web3Utils.isAddress(realAddress) ? realAddress : false;
  }
};

/**
 *
 * @param {*} chain
 * @param {*} txnHash
 * @returns
 */
export const isCompletedTx = (chain, txnHash) => {
  switch (chain) {
    case BLOCKCHAINS.binance:
    case BLOCKCHAINS.ethereum:
    default:
      return web3Utils.isCompletedTx(txnHash);
  }
};

export const getDisplayVal = (amount, decimals, shortFormat) =>
  web3Utils.getDisplayVal(amount, decimals, shortFormat);

export const doBigMath = (amount, decimals, operator, otherNumber) =>
  web3Utils.getDisplayVal(
    web3Utils.doBigMath(amount, operator, otherNumber).toString(),
    decimals
  );

// ERC20 functions

/**
 *
 * @param {*} networkId
 * @param {*} tokenName
 * @param {*} userAddress
 * @returns
 */
export const balanceOfERC20Token = (networkId, tokenName, userAddress = null) => {
  const tokenAddress = TOKEN_INFO[tokenName].addresses[networkId];
  switch (networkId) {
    case BLOCKCHAINS.binance:
    case BLOCKCHAINS.ethereum:
    default:
      return web3Utils.balanceOfERC20Token(tokenAddress, userAddress).then((amount) => {
        /*
        let convertedAmount = new BN(amount);
        const decimals = TokenInfo[tokenName].decimals;
        convertedAmount = convertedAmount.div(new BN(10).pow(new BN(decimals)));
        return convertedAmount.toString();
        */
        return web3Utils.web3.utils.fromWei(amount, "ether");
      });
  }
};

/**
 *
 * @param {*} networkId
 * @param {*} tokenName
 * @param {*} targetAddress
 * @param {*} userAddress
 * @returns
 */
export const allowanceOfERC20Token = (
  networkId,
  tokenName,
  targetAddress,
  userAddress = null
) => {
  const tokenAddress = TOKEN_INFO[tokenName].addresses[networkId];
  switch (networkId) {
    case BLOCKCHAINS.binance:
    case BLOCKCHAINS.ethereum:
    default:
      return web3Utils
        .allowanceOfERC20Token(tokenAddress, targetAddress, userAddress)
        .then((amount) => {
          /*let convertedAmount = amount;
        let decimals = TokenInfo[tokenName].decimals;
        convertedAmount = convertedAmount.div(new BN(10).pow(new BN(decimals)));
        return convertedAmount.toString();
        */
          return web3Utils.web3.utils.fromWei(amount, "ether");
        });
  }
};

/**
 *
 * @param {*} networkId
 * @param {*} tokenName
 * @param {*} targetAddress
 * @param {*} amount
 * @param {*} userAddress
 * @returns
 */
export const allowERC20Token = (
  networkId,
  tokenName,
  targetAddress,
  amount,
  userAddress = null
) => {
  const tokenAddress = TOKEN_INFO[tokenName].addresses[networkId];
  /*
  const decimals = TokenInfo[tokenName].decimals;
  let convertedAmount = new BN(amount);
  convertedAmount = convertedAmount.mul(new BN(10).pow(new BN(decimals)));
  */
  const convertedAmount = web3Utils.web3.utils.toWei(amount.toString(), "ether");
  switch (networkId) {
    case BLOCKCHAINS.binance:
    case BLOCKCHAINS.ethereum:
    default:
      return web3Utils.allowERC20Token(
        tokenAddress,
        targetAddress,
        convertedAmount,
        userAddress
      );
  }
};

// ERC 721 metadata

/**
 *
 * @param {*} tokenName
 * @param {*} networkId
 * @returns
 */
export const getERC721OwnerTokens = async (tokenName, networkId) => {
  const tokens = await web3Utils.getERC721OwnerTokens(tokenName, networkId);
  return tokens;
};

/**
 *
 * @param {*} tokenName
 * @param {*} tokenId
 * @param {*} networkId
 * @returns
 */
export const getERC721Token = async (tokenName, tokenId, networkId) => {
  const url = await web3Utils.getERC721Token(tokenName, tokenId, networkId);
  const { data } = await genericAxios.get(url);
  return data;
};

// LTNFT
export const getPLTNFTInfo = (name, chain_id, id) =>
  web3Calls.getPLTNFTInfo(name, chain_id, id);

//util math function for tokens
/**
 *
 * @param {*} tokenName
 * @param {*} amount
 * @returns
 */
export const getBNValueOfToken = (tokenName, amount) => {
  const decimals = new BN(TOKEN_INFO[tokenName].decimals) || new BN(0);
  return new BN(amount).mul(new BN(10).pow(new BN(decimals)));
};

/**
 *
 * @param {*} chain
 * @param {*} address
 * @returns
 */
export const getSwapURL = (chain) => {
  switch (chain) {
    case BLOCKCHAINS.ethereum:
    //return links.BUY_GXP_UNISWAP_LINK;
    case BLOCKCHAINS.binance:
    default:
      return links.BUY_GXP_XCHANGE_LINK;
  }
};
