import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowPrev } from "../../../../../images/icons/arrowPrev.svg";
import { setBlockchain } from "../../../../../reducers/redux/Signup/actions";
import { ButtonsNextWrapper } from "./styles";
import { NextButton, PrevButton } from "../../../../buttons/styles";

const Buttons = ({ setActiveStep, current }) => {
  const dispatch = useDispatch();
  const step = useSelector(({ Signup }) => Signup.activeWindow);

  const handlerClick = (direction, blockchain) => {
    if (!current === step) return;
    setActiveStep(direction);

    if (direction === "+") {
      dispatch(setBlockchain(blockchain));
    }
  };

  return (
    <>
      <PrevButton onClick={() => handlerClick("-")}>
        <ArrowPrev />
        Previous
      </PrevButton>

      <ButtonsNextWrapper>
        <NextButton
          onClick={() => handlerClick("+", "Continue")}
          disabled={!(current === step)}
        >
          Continue
        </NextButton>
      </ButtonsNextWrapper>
    </>
  );
};

export default Buttons;
