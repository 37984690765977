export const getIconURL = (icon /* , pack */) =>
  `https://img.tapplatform.io/stat/icons/browser/${icon}`;

export const packNames = ["default"];

export const iconPacks = {
  default: {
    requirements: null,
    icons: [
      "001-worms",
      "002-armor",
      "002-world-of-warcraft",
      "003-bag",
      "003-wii-remote-control",
      "004-brickwall",
      "004-sword",
      "005-mushroom",
      "006-compass",
      "006-tetris",
      "007-controller",
      "008-box",
      "008-crown",
      "009-crystal",
      "009-super-mario",
      "010-diamond",
      // "010-space-invaders",
      // "011-bullfighter",
      // "011-snake",
      // "012-Fortification",
      // "012-steering-wheel",
      // "013-diamond",
      // "014-key",
      // "015-letter",
      // "015-pokeball",
      // "016-wizard",
      // "017-carnivorous-plant",
      // "017-spell book",
      // "018-magic wand",
      // "018-pikachu",
      // "019-smartphone",
      // "019-treasure map",
      // "020-oyster",
      // "020-pacman",
      // "021-minecraft",
      // "021-pirate ship",
      // "022-pick",
      // "022-poison",
      // "023-joystick",
      // "023-sword",
      // "024-tokens",
      // "025-ghost",
      // "025-treasure chest",
      // "026-gamepad-3",
      // "026-treasure chest",
      // "027-gamepad-2",
      // "027-trophy",
      // "028-viking",
      // "029-game-console-2",
      // "035-button",
      // "036-arcade-1",
      // "037-bomberman",
      // "040-angry-birds",
      // "badge",
      // "circular-target",
      // "cupid",
      // "dead",
      // "defense",
      // "droid",
      // "fuse",
      // "game-console-10",
      // "game-console-11",
      // "game-console-12",
      // "game-console-13",
      // "game-console-1",
      // "game-console-8",
      // "game-console-9",
      // "game-console",
      // "game-controller-1",
      // "game-controller-2",
      // "game-controller-3",
      // "game-controller-5",
      // "game-controller",
      // "game-control",
      // "gamer",
      // "horseshoe",
      // "jewels",
      // "knight",
      // "lightnings",
      // "lover",
      // "maps-and-flags",
      // "pacman",
      // "races",
      // "racing",
      // "rocket-ship-launch",
      // "sand-clock",
      // "technological-1",
      // "technological",
      // "virtual-reality",
    ],
  },
};
