import { CLOSE_NFT_MODAL, OPEN_NFT_MODAL } from "./types";

export const openNFTModal = (data) => {
  return {
    type: OPEN_NFT_MODAL,
    data,
  };
};

export const closeNFTModal = () => {
  return {
    type: CLOSE_NFT_MODAL,
  };
};
