import React, { PureComponent, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";

// import * as actions from 'src/actions';
import {
  renderSuccessSnackbar,
  renderErrorSnackbar,
  fetchOrgMemberInvites,
  openAndSetSnackbarContent,
  cancelInviteNewMember,
} from "actions/index";
import Text from "components/Text";
import {
  SectionHeading,
  SectionText,
  SectionDivider,
} from "components/containers/settings/Settings";
import { handleApiErrorForReduxForm } from "utils/apiErrors";
import InviteContributorModal from "./InviteContributorModal";

class PubUsersSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inviteModalOpen: false,
    };

    this.handleOpenInviteModal = this.handleOpenInviteModal.bind(this);
    this.handleCloseInviteModal = this.handleCloseInviteModal.bind(this);
    this.handleInviteContributorOnSubmit =
      this.handleInviteContributorOnSubmit.bind(this);
    this.renderErrorSnack = this.renderErrorSnack.bind(this);
    this.handleCancelInvite = this.handleCancelInvite.bind(this);
    this.handleResendInvite = this.handleResendInvite.bind(this);
  }

  handleOpenInviteModal() {
    this.setState({
      inviteModalOpen: true,
    });
  }

  handleCloseInviteModal() {
    this.setState({
      inviteModalOpen: false,
    });
  }

  handleCancelInvite(inviteId) {
    this.props
      .cancelInviteNewMember(this.props.currentOrgId, inviteId)
      .then(() => {
        this.props.openAndSetSnackbarContent({
          msgVariant: "info",
          message: <Text variant="h6">Invite Cancelled</Text>,
        });
      })
      .catch(this.renderErrorSnack);
  }

  handleResendInvite({ email_address }) {
    const data = {
      organization_invite: {
        email_address,
      },
    };
    this.props
      .resendInviteNewMember(this.props.currentOrgId, data)
      .then(() => this.props.renderSuccessSnackbar("Invite Sent"))
      .catch(this.renderErrorSnack);
  }

  handleInviteContributorOnSubmit(data) {
    const postData = {
      organization_invite: {
        ...data,
        status: "pending",
      },
    };
    return this.props
      .pubInviteNewMember(this.props.currentOrgId, postData)
      .then(() => {
        this.props.renderSuccessSnackbar("Invite Sent");
        this.handleCloseInviteModal();
        this.props.fetchOrgMemberInvites(this.props.currentOrgId);
      })
      .catch(handleApiErrorForReduxForm);
  }

  handleUpdateMemberRole({ memberId, role }) {
    const data = {
      organization_member: { role },
    };
    this.props
      .updateOrganizationMember(this.props.currentOrgId, memberId, data)
      .then(() => {
        this.props.renderSuccessSnackbar("Successfully Updated");
      })
      .catch(this.renderErrorSnack);
  }

  renderErrorSnack() {
    this.props.renderErrorSnackbar();
  }

  renderOrgMemberList() {
    const { members, invitees } = this.props.publisher;
    if (!members || members.length === 0) {
      return null;
    }

    return (
      <div>
        <Grid className="mt--64" container>
          <Grid item xs={8}>
            <Typography className="text__light-grey" variant="caption">
              Whom
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className="text__light-grey" variant="caption">
              Permission
            </Typography>
          </Grid>
        </Grid>
        <SectionDivider smallMargin />
        {members
          .sort((memA, memB) => {
            const nameA = memA.last_name.toUpperCase();
            const nameB = memB.last_name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map((member) => (
            <Fragment key={member.user_id}>
              <Grid container>
                <Grid item xs={8}>
                  <SectionHeading>
                    {member.first_name} {member.last_name}
                  </SectionHeading>
                  <SectionText>{member.email_address}</SectionText>
                </Grid>
                <Grid alignItems="center" container item xs={4}>
                  <Select
                    onChange={(event) => {
                      this.handleUpdateMemberRole({
                        event,
                        memberId: member.member_id,
                        role: event.target.value,
                      });
                    }}
                    variant="outlined"
                    fullWidth
                    value={member.role}
                  >
                    <MenuItem value="contributor">Contributor</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <SectionDivider smallMargin />
            </Fragment>
          ))}
        {invitees.map((invitee) => (
          <Fragment key={invitee.invite_id}>
            <Grid container>
              <Grid item xs={8}>
                <SectionHeading>{invitee.email_address}</SectionHeading>
                <SectionText>{invitee.email_address}</SectionText>
              </Grid>
              <Grid
                alignItems="center"
                justifyContent="space-between"
                container
                item
                xs={4}
              >
                <Button
                  onClick={() => {
                    this.handleCancelInvite(invitee.invite_id);
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel Invite
                </Button>
                <Button
                  onClick={() => {
                    this.handleResendInvite(invitee);
                  }}
                  variant="text"
                  color="primary"
                >
                  Send Again
                </Button>
              </Grid>
            </Grid>
            <SectionDivider smallMargin />
          </Fragment>
        ))}
      </div>
    );
  }

  renderInviteContributorModal() {
    if (!this.state.inviteModalOpen) {
      return null;
    }
    return (
      <InviteContributorModal
        onSubmit={this.handleInviteContributorOnSubmit}
        onClose={this.handleCloseInviteModal}
      />
    );
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SectionHeading>Access</SectionHeading>
            <SectionText>
              You can add people who have access to this account. Beside the owner, the
              added users, are normal accounts which can use the Tap platform aswell.
            </SectionText>
          </Grid>
        </Grid>
        {this.renderOrgMemberList()}
        <Button
          onClick={this.handleOpenInviteModal}
          variant="outlined"
          className="mt--24"
        >
          Invite Contributer
        </Button>
        {this.renderInviteContributorModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentOrgId: state.publisher.currentOrgId,
  publisher: state.publisher,
});

const mapDispatchToProps = {
  renderSuccessSnackbar,
  renderErrorSnackbar,
  fetchOrgMemberInvites,
  openAndSetSnackbarContent,
  cancelInviteNewMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(PubUsersSettings);
