import React from "react";
import { Grid, Divider, Paper } from "@material-ui/core";

const LockerCard = (props) => (
  <div>
    <Divider />
    <Paper className="wallet__locker-card">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container justifyContent="center" className="wallet__locker-card__icon">
            <img
              src="https://img.tapplatform.io/stat/Locker-items/tapWalletContract.svg"
              alt="Tap Wallet"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </div>
);

export default LockerCard;
