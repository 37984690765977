import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, ButtonClose, useStyles, WhiteButton } from "./styles";
import { ReactComponent as Close } from "../../images/close.svg";
import ModalBody from "./ModalBody";
import { useButtonStyles } from "../../styles";
import { closeModal } from "../../reducers/redux/Modal/actions";

const ModalContainer = () => {
  const classes = useStyles();
  const style = useButtonStyles();
  const dispatch = useDispatch();
  const state = useSelector(({ Modal }) => Modal.data);

  const closeHandler = () => {
    state.closeHandler && state.closeHandler();
    dispatch(closeModal());
  };

  const successHandler = () => {
    state.successHandler && setTimeout(() => state.successHandler(), state.delay || 200);
    dispatch(closeModal());
  };

  const failedHandler = () => {
    state.failedHandler && state.failedHandler();
    dispatch(closeModal());
  };

  return (
    <Dialog
      open={!!state}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.root }}
    >
      {state && (
        <>
          {!state.noClose && (
            <ButtonClose onClick={closeHandler}>
              <Close />
            </ButtonClose>
          )}

          <ModalBody title={state.header} values={state.values} />

          <DialogActions>
            {state.closeButton && (
              <WhiteButton onClick={failedHandler}>Close</WhiteButton>
            )}

            {state.Confirm && <WhiteButton onClick={closeHandler}>Close</WhiteButton>}
            {!state.noSuccess && (
              <Button onClick={successHandler} className={style.button}>
                {state.successButton ?? "Confirm"}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ModalContainer;
