import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import * as ImgServer from "utils/imgServer";

const MainSlider = ({ setNav, isPlaying, setIsPlaying }) => {
  const params = useParams();
  const games = useSelector((state) => state.games);
  const game = games?.byId[params?.id] || {};
  const galleryImages = game?.additional_images_url;

  if (!game) {
    return;
  }

  const settings = {
    beforeChange: () => {
      setIsPlaying(false);
    },
  };

  return (
    <div className="game-details__player">
      <Slider
        id="mainSlider"
        fade
        ref={(slider) => !!slider && setNav(slider)}
        arrows={false}
        {...settings}
      >
        {game.video_url && (
          <ReactPlayer url={game.video_url} playing={isPlaying} muted loop controls />
        )}
        {galleryImages &&
          galleryImages.map((picture) => (
            <img
              key={picture}
              src={ImgServer.gameImage(game.id, "gallery", picture)}
              alt="uploaded file"
              style={{ minWidth: "1px", minHeight: "1px" }}
            />
          ))}
      </Slider>
    </div>
  );
};

export default MainSlider;
