import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Slider,
  Button,
  IconButton,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import GXPBottle from "assets/icons/GXP.png";
import {
  CurrencyImage,
  RemoveLiquidityInfoBox,
  LiquidityDescription,
  CurrencyText,
  Descriptions,
  Description,
} from "components/Exchange/styles";
import ContainerInput from "../ContainerInput";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "434px",
  },
  iconButton: {
    borderRadius: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    height: "32px",
    width: "32px",
    backgroundColor: "#6e43e6",
  },
  downArrow: {
    width: "100%",

    margin: "10px 0 10px",
  },
  firstProvider: {
    borderRadius: "15px",
    backgroundColor: "#FFB2371F",
    marginBottom: "20px",
    "& div": {
      "& svg": {
        fill: "#FFB237",
      },
    },
  },
  alertTitle: {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Work Sans",
  },
  alertContent: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Work Sans",
    marginBottom: "8px",
  },
  amountButton: {
    borderRadius: "25px",
  },
  slider: {
    margin: "20px 0",
  },
  header: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  infoHeader: {
    fontSize: "14px",
    fontFamily: "Work Sans",
    fontWeight: "600",
    marginBottom: "10px",
  },
  arrowForward: {
    position: "absolute",
    top: "15px",
    left: "160px",
  },
  plus: {
    textAlign: "center",
    margin: "10px 0 10px",
  },
  test: {
    paddingBottom: "25px",
  },
}));

const AdvancedRemove = ({
  setAmount,
  maxAmount,
  amount,
  primaryCurrency,
  secondaryCurrency,
  primaryTotal,
  secondaryTotal,
  setPercentage,
}) => {
  const classes = useStyles();
  const [secondaryAmount, setSecondaryAmount] = useState(0);
  const [primaryAmount, setPrimaryAmount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (amount > maxAmount) {
      return setError(`Not enough LP tokens.`);
    }
    setPercentage(Math.round((amount / maxAmount) * 100));
    setError(false);
  }, [amount]);

  useEffect(() => {
    setPrimaryAmount((amount / maxAmount) * primaryTotal);
    setSecondaryAmount((amount / maxAmount) * secondaryTotal);
  }, []);

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handleAmountChange = (e, input) => {
    if (!isNumber(e) && e.length > 0) {
      return;
    }
    const num = parseFloat(e * 1);
    switch (input) {
      case "Primary":
        setAmount((num / primaryTotal) * maxAmount);
        setPrimaryAmount(num);
        setSecondaryAmount((num / primaryTotal) * secondaryTotal);
        break;
      case "Secondary":
        setAmount((num / secondaryTotal) * maxAmount);
        setPrimaryAmount((num / secondaryTotal) * primaryTotal);
        setSecondaryAmount(num);
        break;
      default:
        setAmount(num);
        setPrimaryAmount((num / maxAmount) * primaryTotal);
        setSecondaryAmount((num / maxAmount) * secondaryTotal);
        break;
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <ContainerInput
        label="LP Tokens"
        onChangeAmount={(e) => handleAmountChange(e.target?.value || e, "From")}
        amount={amount || ""}
        balance={maxAmount}
      />
      <ArrowDownwardIcon className={classes.downArrow} />
      <ContainerInput
        label={primaryCurrency.symbol}
        onChangeAmount={(e) => handleAmountChange(e.target?.value || e, "Primary")}
        amount={primaryAmount || ""}
      />
      <Typography variant="h5" className={classes.plus}>
        +
      </Typography>
      <ContainerInput
        label={secondaryCurrency.symbol}
        selectedCurrency={primaryCurrency}
        onChangeAmount={(e) => handleAmountChange(e.target?.value || e, "Secondary")}
        amount={secondaryAmount || ""}
      />
      <Description>{error}</Description>
    </div>
  );
};

export default AdvancedRemove;
