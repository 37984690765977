import {
  CREDENTIAL_UPDATE,
  CREDENTIAL_CLEAR,
  VALID_USERNAME,
  LOGIN_ERROR,
  FORM_ERROR,
  FORM_SUCCESS,
  VALIDATE_EMAIL,
  STEAM_USER_NAME,
  REFERRAL_CODE,
} from "../actions/types";

const initialUserState = {
  isLoggedIn: false,
};

const user = (state = initialUserState, action) => {
  switch (action.type) {
    case CREDENTIAL_UPDATE: {
      return {
        ...state,
        ...action.user,
        isLoggedIn: true,
      };
    }
    case VALIDATE_EMAIL: {
      return {
        ...state,
        email_address_verified: true,
      };
    }
    case CREDENTIAL_CLEAR: {
      return initialUserState;
    }
    case VALID_USERNAME: {
      return {
        ...state,
        validUsername: action.isvalidUsername,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.loginError,
      };
    }
    case FORM_ERROR: {
      return {
        ...state,
        formError: action.formError,
      };
    }
    case FORM_SUCCESS: {
      return {
        ...state,
        formSuccess: action.formError,
      };
    }
    case STEAM_USER_NAME: {
      return {
        ...state,
        steam_name: action.steamname,
      };
    }
    case REFERRAL_CODE: {
      return {
        ...state,
        referral_code: action.referral,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
