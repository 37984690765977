import styled from "styled-components";
import { button } from "../../styles";

export const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  &::before {
    content: "";
    display: block;
    padding-top: 30%;
  }

  & > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 20px;
  }

  @media (max-width: 1024px) {
    &::before {
      padding-top: 50%;
    }
  }

  @media (max-width: 768px) {
    &::before {
      padding-top: 70%;
    }
  }

  @media (max-width: 600px) {
    &::before {
      padding-top: 80%;
    }
  }
`;

export const ImgText = styled.div`
  font-weight: 500;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  max-width: 700px;
  z-index: 1;
  padding-bottom: 110px;
  padding-left: 64px;

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;
    font-size: 24px;
    padding-left: 30px;
  }

  @media (max-width: 480px) {
    padding-bottom: 50px;
    font-size: 18px;
    padding-left: 30px;
  }
`;

export const ImgButton = styled(button)`
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
`;
