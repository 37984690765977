import React from "react";
import * as Blockchain from "blockchain";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress, Grid, Typography } from "@material-ui/core";
import { openNFTModal } from "reducers/redux/NFTModal/actions";
import ContractButton from "components/buttons/ContractButton";
import mystery from "images/nft/mysteryToken.png";
import { SponsoredNFTContainer, NFTImage, NFTVideo } from "./styles";
import {
  NftText,
  NftDesc,
  NftTextBox,
  NftActions,
  NftBold,
  NftCount,
} from "../../Dashboard/Achievement/styles";

const SponsoredNFT = React.memo(({ sponsoredData }) => {
  const {
    saleId,
    image,
    name,
    description,
    longDescription,
    count,
    countMax,
    payment,
    participationToken,
    participationAddress,
    participationAmount,
    paymentContract,
    marketContract,
    currentTokenPrice,
    mintingHandler,
    getHandler,
    allowanceHandler,
    connectHandler,
  } = sponsoredData;

  const modalData = {
    image,
    count,
    currentTokenPrice: payment
      ? Blockchain.getDisplayVal(currentTokenPrice, payment?.decimals)
      : null,
    name,
    description,
    longDescription,
    tokenSymbol: payment?.symbol,
    exchangeRate: payment?.exchangeRate,
  };
  const progress = Math.round(((countMax - count) / countMax) * 100);

  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.web3);
  const balances = useSelector((state) => state.balances);
  const allowances = useSelector((state) => state.allowances);

  const loading = useSelector((state) => state.loading);
  let buttonIsLoading = false;
  if (loading.partialScreen["nftButtons--is-loading-nfts"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-handling-allowance"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-minting-nft"]) {
    buttonIsLoading = true;
  }
  console.log(marketContract);

  return (
    <SponsoredNFTContainer
      onClick={image && (() => dispatch(openNFTModal(modalData)))}
      container
      direction="column"
      justifyContent="flex-end"
    >
      {image && ["mp4", "png", "jpg", "gif"].includes(image.split(".").pop()) ? (
        image.split(".").pop() === "mp4" ? (
          <NFTVideo autoPlay loop>
            <source src={image} type="video/mp4" />
          </NFTVideo>
        ) : (
          <NFTImage src={image} />
        )
      ) : (
        <NFTImage src={mystery} />
      )}
      <Grid item container direction="column">
        <NftTextBox>
          <Typography variant="h2">
            {name || "No Sponsored NFTs at this time..."}
          </Typography>
          {!!count && !!countMax && (
            <Grid container direction="column">
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{ margin: "30px 0 5px" }}
              />

              <NftCount>
                <NftText variant="h6">{countMax} TOTAL</NftText>
                <NftText variant="h6">{count} LEFT</NftText>
              </NftCount>
            </Grid>
          )}
          <NftDesc style={{ fontSize: "18px", fontWeight: "400", color: "whitesmoke" }}>
            {description}
          </NftDesc>
        </NftTextBox>
        {!!payment && (
          <NftActions>
            <ContractButton
              loading={buttonIsLoading}
              mintHandler={() => mintingHandler(saleId)}
              getHandler={() => getHandler(payment, currentTokenPrice)}
              allowanceHandler={() => allowanceHandler(saleId)}
              web3Connected={web3.connected}
              connectHandler={connectHandler}
              tokenSymbol={payment?.symbol}
              userWalletAllowance={Blockchain.getDisplayVal(
                allowances?.[paymentContract]?.[marketContract] || 0,
                payment?.decimals
              )}
              participationAmount={Blockchain.getDisplayVal(
                participationAmount || 0,
                participationToken?.decimals
              )}
              hasParticipationAmount={participationAddress}
              participationName={participationToken?.symbol}
              currentTokenPrice={Blockchain.getDisplayVal(
                currentTokenPrice,
                payment?.decimals
              )}
              userBalance={Blockchain.getDisplayVal(
                balances?.[paymentContract] || 0,
                payment?.decimals
              )}
              paymentToken={paymentContract}
              needsGXP={web3?.needsGXP}
              variant="h4"
            />
          </NftActions>
        )}
      </Grid>
    </SponsoredNFTContainer>
  );
});

export default SponsoredNFT;
