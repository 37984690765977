import React, { memo, useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { ZERO_ADDRESS } from "blockchain/tokenInfo";
import NumbersFormat from "assist/numbersFormat";

const ContractButton = memo(
  ({
    loading,
    connectHandler,
    web3Connected,
    tokenSymbol,
    userWalletAllowance,
    currentTokenPrice,
    allowanceHandler,
    mintHandler,
    getHandler,
    userBalance,
    paymentToken,
    hasParticipationAmount,
    participationAmount,
    participationName,
    needsGXP,
    variant,
  }) => {
    const [click, setClick] = useState({});
    const [buttonText, setButtonText] = useState("");
    const [disabled, setDisabled] = useState(false);

    const onClickHandler = (e) => {
      e.stopPropagation();
      click();
    };

    useEffect(() => {
      if (loading || paymentToken === ZERO_ADDRESS) {
        setButtonText(<CircularProgress color="inherit" />);
        setDisabled(true);
      } else if (!web3Connected) {
        setButtonText("Unlock Wallet");
        setClick(() => connectHandler);
        setDisabled(false);
      } else if (needsGXP) {
        setButtonText("You must hold 2200 GXP in order to purchase");
        setClick(() => {});
        setDisabled(true);
      } else if (!hasParticipationAmount && participationAmount) {
        setButtonText(
          `Insufficient ${participationName}. You must hold at least ${participationAmount} ${participationName} to bid on this NFT`
        );
        setClick(() => {});
        setDisabled(true);
      } else if (userBalance < currentTokenPrice) {
        setButtonText(`Needs ${currentTokenPrice} ${tokenSymbol}`);
        if (getHandler) {
          setClick(() => getHandler);
          setDisabled(false);
        } else {
          setClick(() => {});
          setDisabled(true);
        }
      } else if (userWalletAllowance < currentTokenPrice) {
        // set allowance
        setButtonText(`Approve ${tokenSymbol}`);
        setClick(() => allowanceHandler);
        setDisabled(false);
      } else if (userBalance > currentTokenPrice) {
        // attempt to buy token
        setButtonText(`Buy ${NumbersFormat(currentTokenPrice)} ${tokenSymbol}`);
        setClick(() => mintHandler);
        setDisabled(false);
      }
    }, [
      web3Connected,
      userWalletAllowance,
      currentTokenPrice,
      allowanceHandler,
      connectHandler,
      hasParticipationAmount,
      loading,
      mintHandler,
      participationAmount,
      participationName,
      paymentToken,
      tokenSymbol,
      userBalance,
    ]);
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => onClickHandler(e)}
        disabled={disabled}
        size="large"
      >
        <Typography variant={variant}>{buttonText}</Typography>
      </Button>
    );
  }
);

export default ContractButton;
