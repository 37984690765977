import React, { Fragment } from "react";
import * as R from "ramda";
import { Grid, Paper, Divider, Button } from "@material-ui/core";
import Text from "components/Text";
import { Link } from "react-router-dom";

const WalletCard = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <Divider className="thin" />
    </Grid>
    <Grid item xs={12}>
      <Paper className="wallet-address-card">
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12} className="mt--16">
            <Text variant="caption" color="textSecondary">
              Linked ETH wallet address
            </Text>
            <Text variant="p1">{R.propOr("None", "eth", props)}</Text>
          </Grid>
          {/* props.role_id !== 2 && (
            <Grid item xs={12} container className="mt--24">
              <Grid item xs={6} md={3}>
                <Text variant="caption" color="textSecondary">
                  Drip Bonus:
                </Text>
                <Text variant="p1">{Web3Math.dripEligible(R.prop("amount", props)) ? "Yes" : "No"}</Text>
              </Grid>
              <Grid item xs={6} md={3}>
                <Text variant="caption" color="textSecondary">
                  Drip Rate:
                </Text>
                <Text variant="p1">{Web3Math.dripRate(R.prop("amount", props))}%</Text>
              </Grid>
            </Grid>
          ) */}
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

WalletCard.propTypes = {};

WalletCard.defaultProps = {};

export default WalletCard;
