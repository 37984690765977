import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { FollowAvatar, FollowBadge } from "./styles";

const useStyles = makeStyles((theme) => ({
  circularBackground: {
    color: theme.palette.secondary,
    position: "absolute",
    width: "100%",
  },
  circularProgress: {
    color: theme.palette.primary.dark,
    position: "absolute",
    width: "100%",
  },
}));

const Avatar = React.memo(({ image }) => {
  const classes = useStyles();
  return (
    <FollowAvatar>
      <CircularProgress
        variant="determinate"
        value={100}
        color="secondary"
        size={"100%"}
        thickness={2}
        className={classes.circularBackground}
      />
      <CircularProgress
        variant="determinate"
        value={80}
        color="primary"
        size={"100%"}
        thickness={2}
        className={classes.circularProgress}
      />
      <FollowBadge color="secondary" overlap="circular" badgeContent=" ">
        <img src={image} alt="" />
      </FollowBadge>
    </FollowAvatar>
  );
});

export default Avatar;
