import React from "react";

const Fillbar = (props) => (
  <div className={`fillbar ${props.className ? props.className : ""}`}>
    <div
      className={`progress bg--${props.color || "gradient-purple"}`}
      style={{ width: `${props.progress}%` }}
    />
  </div>
);

export default Fillbar;
