import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { getCurrencyChainPrice } from "utils/apiRequest";
import { displayFormatWithSuffix } from "blockchain/web3Utils";
import { InfoContainer, InfoHeader, InnerInfoContainer } from "../styles";
import InfoStats from "./InfoStats";

const Info = () => {
  const [gxpInfo, setGXPInfo] = useState(false);
  useEffect(() => {
    getCurrencyChainPrice({ symbol: "GXP" }).then((res) => {
      res.data.max_supply = displayFormatWithSuffix(res.data.max_supply);
      res.data.usd_price = Math.floor(res.data.usd_price * 10000) / 10000;
      res.data.market_cap = displayFormatWithSuffix(res.data.market_cap);
      res.data.supply = displayFormatWithSuffix(res.data.supply);
      setGXPInfo(res.data);
    });
  }, []);
  return (
    <InfoContainer>
      <InfoHeader variant="h4">GXP Stats</InfoHeader>
      {gxpInfo && (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <InnerInfoContainer container direction="row">
            <InfoStats title="GXP Total Supply" value={gxpInfo.max_supply} />
            <InfoStats title="GXP Circulating Supply" value={gxpInfo.supply} />
            <InfoStats title="GXP Price" value={`$${gxpInfo.usd_price}`} />
            <InfoStats title="GXP Market Cap" value={`$${gxpInfo.market_cap}`} />
          </InnerInfoContainer>
          {/*
          <InnerInfoContainer container direction="row">
            <InfoStats title="Total Value Locked" value="1,434,313,000" />
            <InfoStats title="Total Currency Exported" value="12,434,313,000" />
            <InfoStats title="Total Currency Converted" value="123,213,000" />
            <InfoStats title="Total Conversions" value="17,000" />
          </InnerInfoContainer>
           */}
        </Grid>
      )}
    </InfoContainer>
  );
};

export default Info;
