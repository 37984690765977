import {
  CLEAR_LOADER,
  SET_LOADER,
  SET_OPEN_MODAL_STAKE,
  STAKING_ITEM_SUCCESS,
  STAKING_PAGE,
  STAKING_SINGLE,
  STAKE_REWARD,
} from "./types";

const initialState = {
  stakingList: [{}],

  partnerList: [{}],
  loaderStaking: false,
  openStakingModal: null,
};

const StakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAKING_PAGE:
      if (action.active) {
        return {
          ...state,
          stakingList: action.stakeList,
        };
      }
      return {
        ...state,
        stakingArchive: action.stakeList,
      };

    case STAKING_SINGLE: {
      return {
        ...state,
        stakingList: state.stakingList.map((el) => {
          if (el.id == action.stake.id && el.contract == action.stake.contract) {
            return { ...el, ...action.stake };
          }
          return el;
        }),
      };
    }
    case STAKE_REWARD:
      return {
        ...state,
        stakingList: state.stakingList.map((el) => {
          if (el.id === action.id && el.contract === action.contract) {
            return { ...el, rewards: action.rewards };
          }
          return el;
        }),
      };
    case STAKING_ITEM_SUCCESS:
      return {
        ...state,
        stakingList: state.stakingList.map((el) =>
          el.id === action.data.id ? action.data : el
        ),
        partnerList: state.partnerList.map((el) =>
          el.id === action.data.id ? action.data : el
        ),
      };

    case SET_LOADER:
      return {
        ...state,
        loaderStaking: true,
      };

    case CLEAR_LOADER:
      return {
        ...state,
        loaderStaking: false,
      };

    case SET_OPEN_MODAL_STAKE:
      return {
        ...state,
        openStakingModal: action.payload,
      };

    default:
      return state;
  }
};

export default StakingReducer;
