import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Divider, Grid } from "@material-ui/core";
import { fetchGames, fetchVaults } from "actions/index";
import * as Info from "assets/info";
import Text from "components/Text";
import SimpleGameCard from "../Cards/SimpleGameCard";

class Vaults extends Component {
  constructor(props) {
    super(props);
    this.renderVaults = this.renderVaults.bind(this);
  }

  componentDidMount() {
    this.props.fetchGames();
    this.props.fetchVaults();
  }

  renderVaults() {
    return this.props.vaults.map((vault) => {
      if (vault.game_id === Info.challengeGameId) {
        return "";
      }
      return (
        <Grid key={vault.id} item xs={12} sm={6} md={4} lg={3}>
          <SimpleGameCard game={this.props.games[vault.game_id] || {}} vault={vault} />
        </Grid>
      );
    });
  }

  render() {
    return (
      <div className="vaults">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className="vault__header"
        >
          <Grid item xs={12}>
            <Text variant="p2" gutterBottom component={Link} to="/wallet">
              {"< "}
              Return
            </Text>
          </Grid>
          <Grid item>
            <Text variant="h5">Your Games Vaults</Text>
          </Grid>
          <Grid item>
            <Button component={Link} to="/market">
              Add Game
            </Button>
          </Grid>
        </Grid>
        <Divider className="vault__divider" />
        {this.props.games && this.props.vaults && (
          <Grid container spacing={3}>
            {this.renderVaults()}
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  games: state.games.byId,
  vaults: state.vaults.ids.map((id) => state.vaults.byId[id]),
});

const mapDispatchToProps = {
  fetchVaults,
  fetchGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vaults);
