import React from "react";

const Image = (props) => (
  <img
    src={props.src}
    onError={(e) => {
      e.target.onerror = null;
      e.target.src = props.src2;
    }}
    {...props}
  />
);

export default Image;
