import React from "react";
import { Grid, withStyles, IconButton } from "@material-ui/core";
// import { Link } from 'react-router-dom';
import Text from "components/Text";
import { ReactComponent as Discord } from "assets/icons/discord.svg";
import { ReactComponent as Telegram } from "assets/icons/telegram.svg";

const styles = (theme) => ({
  footer: {
    [theme.breakpoints.up("xl")]: {
      padding: "0 68px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0 58px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0 104px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0 53px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0 16px",
    },
  },
});

const Footer = () => (
  <Grid container className="footer mb--16">
    <Grid item lg={8} md={7} sm={5} xs={false}>
      {/* <Text variant="subheading" component="span">
        © Truly Simplistic Innovations
      </Text>
      */}
    </Grid>
    <Grid container>
      {/*<Grid item container justifyContent="center">
        <Grid item>
          <Text>Join us on social media:</Text>
        </Grid>
        <Grid container justifyContent="center" className="footer__icon-group">
          <IconButton href="https://t.me/GameXChange" target="_blank">
            <Telegram />
          </IconButton>
          <IconButton href="https://discord.gg/fBpj8qP" target="_blank">
            <Discord />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Text
            variant="caption"
            align="center"
            component={Link}
            to="/"
            target="_blank"
            color="textSecondary"
          >
            Policy
          </Text>
        </Grid>
        <Grid item xs>
          <Text
            variant="caption"
            align="center"
            component={Link}
            to="https://tapproject.net/#publisher"
            target="_blank"
            color="textSecondary"
          >
            Publisher
          </Text>
        </Grid>
      </Grid>*/}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Footer);
