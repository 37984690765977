import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Steps from "../index";
import img from "../../../../../images/signUp/step1/cropped_logo.png";
import { ImgWrapper, Message, Name } from "../styles";
import Info from "../elements/Info";

const Welcome = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  return (
    <Steps
      ref={ref}
      setActiveStep={setActiveStep}
      step={step}
      length={length}
      current={0}
    >
      <ImgWrapper>
        <img src={img} alt="game x change" />
      </ImgWrapper>

      <Name>Welcome to Game X Change</Name>

      <Message>
        <span>
          The blockchain gaming platform that allows you to play games, earn crypto and
          earn exclusive NFTs. Follow these steps to start playing!
        </span>
        <Info
          text="Cryptocurrency is required to pay for gas(fees) on the blockchain"
          arrow="right"
        />
      </Message>
      <br />
      <Button variant="contained" color="secondary" component={Link} to="/sign-in">
        Already a member? Login here
      </Button>
    </Steps>
  );
});

export default Welcome;
