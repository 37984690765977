import { useRef, useEffect } from "react";

/**
 * Use the previous state value
 * @param {*} value Current state value
 * @param {*} defaultVal Default value of the state
 * @returns
 */
export const usePrevious = (value, defaultVal) => {
  const ref = useRef(defaultVal);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

/**
 * Triggers on every useEffect after the first
 * @param {*} fn Function to call
 * @param {*} inputs Dependencies
 */
export const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, [fn, inputs]);
};
