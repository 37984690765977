import React from "react";
import * as Blockchain from "blockchain";
import { useSelector, useDispatch } from "react-redux";
import { openNFTModal } from "reducers/redux/NFTModal/actions";
import NFTContainer from "components/containers/NFTContainer";
import ContractButton from "components/buttons/ContractButton";
import { NftActions } from "./styles";

const FeaturedNFT = React.memo(({ featuredData }) => {
  const {
    saleId,
    image,
    name,
    description,
    longDescription,
    count,
    countMax,
    payment,
    participationToken,
    participationAddress,
    participationAmount,
    paymentContract,
    marketContract,
    currentTokenPrice,
    mintingHandler,
    getHandler,
    allowanceHandler,
    connectHandler,
  } = featuredData;

  const modalData = {
    image,
    count,
    currentTokenPrice: payment
      ? Blockchain.getDisplayVal(currentTokenPrice, payment?.decimals)
      : null,
    name,
    description,
    longDescription,
    tokenSymbol: payment?.symbol,
    exchangeRate: payment?.exchangeRate,
  };

  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.web3);
  const balances = useSelector((state) => state.balances);
  const allowances = useSelector((state) => state.allowances);

  const loading = useSelector((state) => state.loading);
  let buttonIsLoading = false;
  if (loading.partialScreen["nftButtons--is-loading-nfts"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-handling-allowance"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-minting-nft"]) {
    buttonIsLoading = true;
  }

  return (
    <NFTContainer
      onClick={() => dispatch(openNFTModal(modalData))}
      nftData={featuredData}
      wide
    >
      <NftActions>
        {!!payment && (
          <ContractButton
            loading={buttonIsLoading}
            mintHandler={() => mintingHandler(saleId)}
            getHandler={() => getHandler(payment, currentTokenPrice)}
            allowanceHandler={() => allowanceHandler(saleId)}
            web3Connected={web3.connected}
            connectHandler={connectHandler}
            tokenSymbol={payment?.symbol}
            userWalletAllowance={Blockchain.getDisplayVal(
              allowances?.[paymentContract]?.[marketContract] || 0,
              payment?.decimals
            )}
            participationAmount={Blockchain.getDisplayVal(
              participationAmount || 0,
              participationToken?.decimals
            )}
            hasParticipationAmount={participationAddress}
            participationName={participationToken?.symbol}
            currentTokenPrice={Blockchain.getDisplayVal(
              currentTokenPrice,
              payment?.decimals
            )}
            userBalance={Blockchain.getDisplayVal(
              balances?.[paymentContract] || 0,
              payment?.decimals
            )}
            paymentToken={paymentContract}
            needsGXP={web3?.needsGXP}
          />
        )}
      </NftActions>
    </NFTContainer>
  );
});

export default FeaturedNFT;
