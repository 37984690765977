import React from "react";
import { useSelector } from "react-redux";
import Leaderboard from "../Leaderboard";

const ContentTabs = React.memo(({ activeTab }) => {
  const conversionLeaderboard = useSelector((state) => state.etc.conversionLeaderboard);
  const referralLeaderboard = useSelector((state) => state.etc.referralLeaderboard);

  if (activeTab === 0) {
    return <Leaderboard leaderboard={conversionLeaderboard} />;
  }
  if (activeTab === 1) {
    return <Leaderboard leaderboard={referralLeaderboard} />;
  }

  return <></>;
});

export default ContentTabs;
