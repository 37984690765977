import { CLEAR_BC_CURRENCIES, UPDATE_BC_CURRENCIES } from "./types";

const initialState = {};

const CurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BC_CURRENCIES:
      return {
        ...state,
        ...action.currencies,
      };

    case CLEAR_BC_CURRENCIES:
      return {};
    default:
      return state;
  }
};

export default CurrenciesReducer;
