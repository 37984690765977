import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateUser } from "actions/user";
import { ContentWrapper } from "../styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Welcome from "./steps/step1/Welcome";
import Blockchain from "./steps/step1/Blockchain";
import WalletAddress from "./steps/step1/WalletAddress";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Concept from "./steps/step2/Concept";
import {
  clearSteps,
  setActiveStep,
  setActiveWindow,
} from "../../../reducers/redux/Signup/actions";

const SETTINGS = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  swipe: false,
};

const Content = () => {
  const history = useHistory();
  const activeWindow = useSelector(({ Signup }) => Signup.activeWindow);
  const activeStep = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);
  const dispatch = useDispatch();
  const walletAddress = useSelector(({ Signup }) => Signup.walletAddress);
  const user = useSelector((state) => state.user);
  const [ref, setRef] = useState(null);
  const [debounce, setDebounce] = useState(Date.now());

  useEffect(() => {
    dispatch(clearSteps());
  }, [dispatch]);

  const changeStepHandler = (value, nextStep) => {
    if (debounce + 600 > Date.now()) return;

    if (activeWindow > 0 && value === "-") {
      dispatch(setActiveWindow(activeWindow - 1));
      ref.slickPrev();
      nextStep && dispatch(setActiveStep(activeStep - 1));
      setDebounce(Date.now());
    } else if (value === "+" && activeStep < length - 1) {
      dispatch(setActiveWindow(activeWindow + 1));
      ref.slickNext();
      nextStep && dispatch(setActiveStep(activeStep + 1));
      setDebounce(Date.now());
    } else if (activeStep === 0 && value === "-") {
      history.push("/sign-up");
    } else if (activeStep < length) {
      // handler sign up
      user.eth_wallet_address = walletAddress;
      user.terms_and_privacy_policy_accepted = true;
      dispatch(updateUser(user, "/dashboard"));
    }
  };

  return (
    <ContentWrapper>
      <Slider {...SETTINGS} ref={(sliderRef) => setRef(sliderRef)}>
        <Welcome setActiveStep={changeStepHandler} />
        <Blockchain setActiveStep={changeStepHandler} />
        <WalletAddress setActiveStep={changeStepHandler} />
        <Step1 setActiveStep={changeStepHandler} />
        <Step2 setActiveStep={changeStepHandler} />
        <Concept setActiveStep={changeStepHandler} />
        <Step3 setActiveStep={changeStepHandler} />
      </Slider>
    </ContentWrapper>
  );
};

export default Content;
