import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Burger } from "images/menu/burger.svg";
import React, { memo } from "react";

const useStyles = makeStyles(() => ({
  root: { width: "25px", height: "25px", padding: "8px" },
}));

const NavBurger = memo(({ className, onClick }) => {
  const classes = useStyles();

  return (
    <IconButton className={className} classes={{ root: classes.root }} onClick={onClick}>
      <Burger />
    </IconButton>
  );
});

export default NavBurger;
