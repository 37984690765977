// import moment from "moment";
import { WEB3_ALLOWANCE_SET } from "reducers/redux/Web3/Allowance/types";
import { WEB3_BALANCE_SET, WEB3_BALANCE_CLEAR } from "reducers/redux/Web3/Balances/types";
import {
  TOKEN_INFO,
  CONVERSION_INFO,
  STAKES,
  TOKEN_TYPES,
  Exchanges,
  ZERO_ADDRESS,
} from "blockchain/tokenInfo";
import { Interface } from "@ethersproject/abi";
import { performMultiCall } from "utils/web3/index";
import * as Web3Call from "utils/web3Calls";
import * as Web3Utils from "blockchain/web3Utils";
import * as ApiRequest from "utils/apiRequest";
import appError from "utils/appError";
import * as StakeReducer from "reducers/redux/Staking/types";
import { UPDATE_BC_CURRENCIES } from "reducers/redux/Currency/types";
import {
  WEB3_ETH_BALANCE,
  WEB3_GAS_PRICE,
  WEB3_CLEAR_ERRORS,
  WEB3_CONNECTED,
  WEB3_BAD_NETWORK,
  WEB3_GXP_REQUIREMENTS,
  WEB3_NEEDS_GXP,
} from "./types";
// import { history } from "../store/index";
// import { isArray } from 'util';

export const autoConnect = () => (dispatch) =>
  Web3Call.checkConnection().then((res) => {
    if (Web3Utils.isValidNetwork(res.chainId)) {
      dispatch({
        type: WEB3_CONNECTED,
        connected: res.address,
        chainId: res.chainId,
      });
    } else {
      dispatch({
        type: WEB3_BAD_NETWORK,
        connected: res.address,
      });
    }
  });

// Generic web3 actions
export const connect = () => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.connect().then(({ address, chainId }) => {
      if (Web3Utils.isValidNetwork(chainId)) {
        dispatch({
          type: WEB3_CONNECTED,
          connected: address,
          chainId,
        });
        resolve(address, chainId);
      } else {
        dispatch({
          type: WEB3_BAD_NETWORK,
          connected: address,
        });
        reject(address, chainId);
      }
    });
  });

export const setConnected = (address) => async (dispatch) => {
  const chainId = await Web3Call.currentNetwork();
  dispatch({
    type: WEB3_CONNECTED,
    connected: address,
    chainId,
  });
};

export const currentNetwork = () => Web3Call.currentNetwork();

export const isAddress = (address) => Web3Call.isAddress(address);

export const fromWei = (amount, unit) => Web3Call.fromWei(amount, unit);
export const toWei = (amount, unit) => Web3Call.toWei(amount, unit);

export const getEthBalance = (address) => (dispatch) => {
  Web3Call.getEthBalance(address).then((response) =>
    dispatch({
      type: WEB3_ETH_BALANCE,
      ethBalance: Web3Call.fromWei(response, "ether"),
    })
  );
};

export const getGasPrice = () => (dispatch) =>
  Web3Call.getGasPrice().then((response) =>
    dispatch({
      type: WEB3_GAS_PRICE,
      gasPrice: Math.round(Web3Call.fromWei(response, "gwei") + 2),
    })
  );

export const balanceOfTokenByName = (tokenName, accountAddress, type) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Utils.getTokenAddress(tokenName)
      .then((tokenAddress) => {
        if (!tokenAddress || tokenAddress.length === 0) {
          // console.error(`Invalid token address for ${tokenName}: `, tokenAddress);
          reject();
        } else {
          Web3Call.getBalanceOf(tokenAddress, accountAddress)
            .then((response) => {
              const amount = Math.round(Web3Call.fromWei(response, "ether"));
              if (type) {
                dispatch({
                  type,
                  data: amount,
                });
              }
              dispatch({
                type: WEB3_BALANCE_SET,
                balance: response.toString(),
                contract: tokenAddress,
              });
              resolve(amount);
            })
            .catch((e) => {
              // console.error("Error getting balance of token: ", e);
              reject();
            });
        }
      })
      .catch((e) => {
        // console.error("Error getting token address: ", e);
        reject();
      });
  });

export const balancesOfTokensByName =
  (tokenNames, accountAddress) => async (dispatch) => {
    const chainId = await Web3Call.currentNetwork();
    const pair = await Web3Call.getContract("IERC20");
    const iface = new Interface(pair.abi);
    const tokenPromises = tokenNames.map((tokenName) =>
      Web3Utils.getTokenAddress(tokenName)
    );
    const tokenAddresses = await Promise.all(tokenPromises);
    const balanceOf = iface.encodeFunctionData("balanceOf", [accountAddress]);
    const balances = await performMultiCall(
      "balanceOf",
      iface,
      tokenAddresses,
      balanceOf
    );
    const stakeAddress = STAKES[chainId][TOKEN_TYPES.ERC721];
    const checkAgain = [];
    const checkAgainAddresses = [];
    balances.forEach((balance, index) => {
      if (balance.toString() == 0) {
        checkAgain.push(
          Web3Call.stakeOwnedTokens(stakeAddress, tokenAddresses[index], accountAddress)
        );
        checkAgainAddresses.push(tokenAddresses[index]);
      } else {
        dispatch({
          type: WEB3_BALANCE_SET,
          balance: balance.toString(),
          contract: tokenAddresses[index],
        });
      }
    });
    const checkedAgain = await Promise.all(checkAgain);
    checkedAgain.forEach((balance, index) => {
      dispatch({
        type: WEB3_BALANCE_SET,
        balance: balance.toString(),
        contract: checkAgainAddresses[index],
      });
    });
  };

export const clearBalance = () => (dispatch) => dispatch({ type: WEB3_BALANCE_CLEAR });

export const getBalanceOf = (token, from) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (!token) {
      reject(new Error("Token not live on this network"));
    }
    Web3Call.getBalanceOf(token, from)
      .then((response) => {
        const amount = fromWei(response, "ether");
        dispatch({
          type: WEB3_BALANCE_SET,
          balance: response.toString(),
          contract: token,
        });
        resolve(amount);
      })
      .catch((err) => reject(err.message));
  });

export const getGXPRequirements = (walletAddress) => (dispatch) => {
  ApiRequest.getGXPRequirements().then(async (response) => {
    const requirements = response.data.data[0];
    dispatch({
      type: WEB3_GXP_REQUIREMENTS,
      GXPRequirements: requirements,
    });

    const GXPBalance = await dispatch(
      getBalanceOf(requirements.required_token_address, walletAddress)
    );
    var needsGXP = true;
    const convertedRequiredAmount = Web3Utils.getDisplayVal(
      requirements.required_token_amount,
      18
    );
    if (GXPBalance >= convertedRequiredAmount) {
      needsGXP = false;
    }

    dispatch({
      type: WEB3_NEEDS_GXP,
      needsGXP: !!needsGXP,
    });
  });
};

export const getAllowanceOf = (token, to, from) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.getAllowance(token, to, from)
      .then((response) => {
        dispatch({
          type: WEB3_ALLOWANCE_SET,
          toContract: to,
          contract: token,
          allowance: response.toString(),
        });
        resolve();
      })
      .catch((err) => reject(err.message));
  });

export const approveFor = (token, amount, to, from) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.approveFor(token, amount, to, from)
      .then((response) => resolve(response))
      .catch((err) => reject(err.message));
  });

export const getStakeRewards = (stakeAddress, stakeList, type) => (dispatch) => {
  const checkAllowances = {};
  const checkBalances = {};

  stakeList.forEach((stake) => {
    checkAllowances[stake.secondaryToken.address] = true;
    checkBalances[stake.primaryToken.address] = true;
    checkBalances[stake.secondaryToken.address] = true;
    checkBalances[stake.rewardToken.address] = true;
    Web3Call.estimateRewards(stakeAddress, stake.id).then((reward) => {
      dispatch({
        type: StakeReducer.STAKE_REWARD,
        contract: stakeAddress,
        id: stake.id.toString(),
        rewards: {
          startPeriod: reward.startPeriod.toNumber(),
          periods: reward.periods.toNumber(),
          amount: reward.amount.toString(),
          rewardsPerCycle: reward.rewardForPeriod.toString(),
          feeAmounts: reward.feeAmounts.map((fee) => fee.toString()),
        },
      });
    });
  });
  Object.keys(checkAllowances).forEach((contract) => {
    Web3Call.getAllowance(contract, stakeAddress).then((result) => {
      dispatch({
        type: WEB3_ALLOWANCE_SET,
        toContract: stakeAddress,
        contract,
        allowance: result.toString(),
      });
    });
  });
  Object.keys(checkBalances).forEach((contract) => {
    Web3Call.getBalanceOf(contract).then((result) => {
      dispatch({
        type: WEB3_BALANCE_SET,
        contract,
        balance: result.toString(),
      });
    });
  });
};

// Stake actions
export const getStakePages =
  (type, page, length, active = true) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: StakeReducer.SET_LOADER });
      Web3Call.currentNetwork().then((chainId) => {
        if (!STAKES[chainId]) {
          return;
        }
        const stakeAddress = STAKES[chainId][type];

        Web3Call.getStakePages(stakeAddress, page, length, active).then((info) => {
          const stakeList = Web3Utils.parseStakePage(stakeAddress, info, type, chainId);
          dispatch({
            type: StakeReducer.STAKING_PAGE,
            stakeList,
            active,
          });
          const tokenAddresses = {};
          stakeList.forEach((stake) => {
            tokenAddresses[stake.secondaryToken.address] = true;
            tokenAddresses[stake.rewardToken.address] = true;
          });
          Object.keys(tokenAddresses).forEach((address) => {
            ApiRequest.getCurrencyChainPrice({
              chain_id: chainId,
              chain_address: address,
            }).then((res) => {
              dispatch({
                type: UPDATE_BC_CURRENCIES,
                currencies: {
                  [res.data.chain_address]: { usd_price: res.data.usd_price },
                },
              });
            });
          });
          dispatch({ type: StakeReducer.CLEAR_LOADER });
          if (Web3Call.selectedAddress()) {
            dispatch(getStakeRewards(stakeAddress, stakeList, type));
          }
        });
      });
    });

export const getStakeInfo = (type, index) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: StakeReducer.SET_LOADER });
    Web3Call.currentNetwork().then((chainId) => {
      const stakeAddress = STAKES[chainId][type];
      Web3Call.getStake(stakeAddress, index).then((info) => {
        const stake = Web3Utils.parseStake(stakeAddress, index, info, type, chainId);
        dispatch({
          type: StakeReducer.STAKING_SINGLE,
          stake,
        });
        dispatch(getStakeRewards(stakeAddress, [stake], type));
      });
    });
  });

export const enterStake =
  (type, stakeIndex, stakeTokenAddress, secondaryToken, amount, from) => (dispatch) =>
    new Promise((resolve, reject) =>
      Web3Call.currentNetwork().then((chainId) => {
        const stakeAddress = STAKES[chainId][type];
        Web3Call.stakeERC721For20(
          stakeAddress,
          stakeIndex,
          stakeTokenAddress,
          secondaryToken
        )
          .then((response) => resolve(response))
          .catch((err) => reject(err.message));
      })
    );

export const leaveStake = (type, stakeIndex, tokenAmount) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.currentNetwork().then((chainId) => {
      const stakeAddress = STAKES[chainId][type];
      Web3Call.unstake(stakeAddress, stakeIndex, tokenAmount)
        .then((response) => resolve(response))
        .catch((err) => reject(err.message));
    });
  });

export const claimStake = (type, stakeIndex, maxPeriods) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.currentNetwork().then((chainId) => {
      const stakeAddress = STAKES[chainId][type];
      Web3Call.claimERC721For20Rewards(stakeAddress, stakeIndex, maxPeriods)
        .then((response) => resolve(response))
        .catch((err) => reject(err.message));
    });
  });

export const getStakeOwnedTokens = (tokenAddress, from) => (dispatch) =>
  new Promise((resolve, reject) => {
    Web3Call.currentNetwork().then((chainId) => {
      const stakeAddress = STAKES[chainId][TOKEN_TYPES.ERC721];
      Web3Call.stakeOwnedTokens(stakeAddress, tokenAddress)
        .then((response) => resolve(response))
        .catch((err) => reject(err.message));
    });
  });

// Conversion actions
export const startConversionFromTap = (
  chainId,
  vaultID,
  gameId,
  currencyId,
  value,
  tokenAddress,
  from
) =>
  new Promise((resolve, reject) => {
    ApiRequest.startFromTap(chainId, value, gameId, currencyId, tokenAddress)
      .then(({ data: { sig, data } }) => {
        if (currencyId > 1 || currencyId < 0) {
          reject(Error("error: invalid game, or currency"));
        } else {
          Web3Call.startConversionFromTap(
            data.contractAddress,
            data.tokenAddress,
            data.fromAddress,
            data.amount,
            data.publisher,
            data.toAmount,
            sig
          )
            .then((hash) => {
              // (hash, chainId, vaultId, currencyId)
              ApiRequest.convertFromTap(
                hash,
                chainId,
                vaultID,
                currencyId,
                data.amount,
                data.tokenAddress
              ).then((claimResponse) => {
                resolve({ hash, id: claimResponse.data.id });
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

export const finishConversionFromTap = (txId, vaultId) =>
  new Promise((resolve, reject) =>
    ApiRequest.updateFromTap(txId, vaultId)
      .then(() => resolve())
      .catch((err) => reject(err))
  );

export const getConversionBalance = async (tokenName) => {
  const chainId = await Web3Call.currentNetwork();
  const token = TOKEN_INFO[tokenName].addresses[chainId];
  const conversionAddress = CONVERSION_INFO[chainId];

  return Web3Call.getBalanceOf(token, conversionAddress).then((res) => res.toString());
};

export const getConversionAllowance = (tokenName) => async (dispatch) => {
  const chainId = await Web3Call.currentNetwork();
  const token = TOKEN_INFO[tokenName].addresses[chainId];
  const conversionAddress = CONVERSION_INFO[chainId];
  const allowance = (await Web3Call.getAllowance(token, conversionAddress)).toString();
  dispatch({
    type: WEB3_ALLOWANCE_SET,
    contract: token,
    toContract: conversionAddress,
    allowance,
  });
};

export const startConversionFromIngame = (vaultId, currencyId, value, tokenAddress) =>
  new Promise(async (resolve, reject) => {
    try {
      const chainId = await Web3Call.currentNetwork();
      const response = await ApiRequest.convertToTap(
        vaultId,
        currencyId,
        value,
        tokenAddress,
        chainId
      );
      const { data } = response;
      if (!response) {
        reject(Error("Empty response from Claim API"));
      }
      const conversionAddress = CONVERSION_INFO[chainId];

      const txResponse = await Web3Call.startConversionFromIngame(
        conversionAddress,
        data.contract_address, //tokenAddress
        data.to_amount, //amount
        data.publisher_address,
        data.to_publisher_amount,
        data.signature, //signature
        data.receiver_address //toAddress
      ).catch((error) => {
        reject();
      });

      if (!txResponse) {
        reject(Error("Undefined txResponse"));
      }
      await ApiRequest.updateToTap(data.id, txResponse);

      resolve(txResponse);
    } catch (error) {
      appError("The following error occured starting conversion from ingame..", error);
      reject(error);
    }
  });

export const resumeConversionFromIngame = (transaction) =>
  new Promise((resolve, reject) => {
    Web3Call.startConversionFromIngame(
      CONVERSION_INFO[transaction.chain_id], //stargateAddress
      transaction.contract_address, //tokenAddress
      transaction.to_amount, //amount
      transaction.publisher_address,
      transaction.to_publisher_amount,
      transaction.signature, //signature
      transaction.receiver_address //toAddress
    )
      .then((txResponse) =>
        ApiRequest.updateToTap(transaction.id, txResponse).then(() => {
          resolve(txResponse);
        })
      )
      .catch((error) => {
        reject(error);
      });
  });

// Tapcoin actions
export const clearErrors = () => (dispatch) =>
  dispatch({
    type: WEB3_CLEAR_ERRORS,
  });

/**
 * Fetches whats for sale from the market.
 * @param {uint256} start
 * @param {uint256} length
 * @returns {Array} {listings, indexes}
 */
export const fetchMarketListings = (start, length) =>
  Web3Call.fetchMarketListings(start, length);

/**
 * Fetches a single NFT from the market.
 * @param {uint256} tokenId
 * @returns {Object} listing
 */
export const fetchTokenSales = (tokenId) => Web3Call.fetchTokenSales(tokenId);

/**
 * Buys something for sale from the Market.
 * @param {uint256} saleId
 * @param {uint256} amount
 */
export const buyFromMarket = (saleId, amount, from) =>
  Web3Call.buyFromMarket(saleId, amount, from)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      appError("The following error occured attempting to buy from market..", error);
      return error;
    });

export const getMarketAddress = () => Web3Call.getMarketAddress();
