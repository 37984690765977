import React from "react";
import { ButtonInfo, ButtonInfoContainer, useStyles } from "./styles";
import { ReactComponent as Icon } from "../../../../../images/icons/iconInfo.svg";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const Info = ({ text, arrow = "right" }) => {
  return (
    <ButtonInfoContainer>
      <StandardToolTip title={text} placement={arrow} arrow />
    </ButtonInfoContainer>
  );
};

export default Info;
