import { useSelector } from "react-redux";
import { useCallback } from "react";

const useMoney = () => {
  const currencies = useSelector(({ user }) => user.currencies);

  const getCurrency = useCallback(
    (id) => {
      if (!id) return 0;

      return currencies.find((el) => el.id === id);
    },
    [currencies]
  );

  return { currencies, getCurrency };
};

export default useMoney;
