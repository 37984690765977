import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";

import formHelp from "utils/formHelp";
import * as actions from "actions";
import {
  SectionHeading,
  SectionText,
  SectionDivider,
  SectionField,
} from "components/containers/settings/Settings";
import { FieldFileInput } from "utils/form";
import SaveFooter from "components/SaveFooter";
import * as ImgServer from "utils/imgServer";

const validate = formHelp.gen({
  primary_contact_first_name: true,
  primary_contact_last_name: true,
  primary_contact_phone_number: true,
  primary_contact_email_address: formHelp.validEmailRequired,
});

class PubBuisnessSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data = {}) {
    const postData = {
      organization: data,
    };
    this.props
      .updateOrganization(this.props.currentOrgId, postData)
      .then(() => {
        actions.renderSuccessSnackbar("Changes Saved");
      })
      .catch(actions.renderErrorSnackbar);
  }

  render() {
    const { handleSubmit, reset, invalid, pristine } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={6}>
            <SectionHeading>Brand Logo</SectionHeading>
            <SectionText>Upload PNG file without background at max. 500 kb</SectionText>
            <label htmlFor="file-logo">
              <Button component="span" variant="outlined" className="mt--16">
                Upload A New Logo
              </Button>
            </label>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
            <div className="settings-page__avatar-img">
              <Field
                name="logo"
                nolabel
                src={ImgServer.pubImage(this.props.currentOrgId, "logo", "a")}
                id="file-logo"
                component={FieldFileInput}
              />
            </div>
          </Grid>
        </Grid>
        <SectionDivider />
        <SectionHeading>Company brand channels</SectionHeading>
        <SectionText>Will be displayed to users</SectionText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SectionField label="Website" name="website_url" />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Facebook Url" name="facebook_url" />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Twitter Url" name="twitter_url" />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Instagram Url" name="instagram_url" />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Twitch Url" name="twitch_url" />
          </Grid>
        </Grid>
        <SectionDivider />
        <SectionHeading>Contact Person</SectionHeading>
        <SectionText>
          Your contact information is private and will not be displayed to other users.
        </SectionText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SectionField label="First Name" name="primary_contact_first_name" required />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Last Name" name="primary_contact_last_name" required />
          </Grid>
          <Grid item xs={6}>
            <SectionField
              label="Phone Number"
              name="primary_contact_phone_number"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SectionField
              label="Email Address"
              name="primary_contact_email_address"
              required
            />
          </Grid>
        </Grid>
        <SectionDivider />
        <SectionHeading>Business</SectionHeading>
        <Grid className="mt--16" container spacing={2}>
          <Grid item xs={6}>
            <SectionField label="Brand Name" name="name" />
          </Grid>
          <Grid item xs={6}>
            <SectionField label="Studio Size" name="studio_size" />
          </Grid>
        </Grid>
        <SectionDivider />
        <SectionHeading>Physical address</SectionHeading>
        <SectionText>
          Your address information is private and will not be displayed to users
        </SectionText>
        <SectionField label="Receiver" name="name" disabled />
        <SectionField label="Adress" name="address" />
        <SectionField label="Second Address (optional)" name="secondary_address" />
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <SectionField label="City" name="city" />
          </Grid>
          <Grid xs={6} item>
            <SectionField label="Zip" name="zip_postal_code" />
          </Grid>
        </Grid>
        <SaveFooter
          onSave={handleSubmit(this.handleSubmit)}
          onDiscard={reset}
          discardDisabled={pristine}
          saveDisabled={invalid || pristine}
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  currentOrgId: state.publisher.currentOrgId,
  initialValues: state.publisher.currentOrganization,
});

export default compose(
  connect(mapState, actions),
  reduxForm({ form: "pubBusinessInfo", validate, enableReinitialize: true })
)(PubBuisnessSettings);
