import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Currencies from "./Currencies";

const Content = React.memo(() => {
  return (
    <Grid container item>
      <Grid container item xs={12} direction="column">
        <Currencies />
      </Grid>
    </Grid>
  );
});

export default Content;
