import React from "react";
import * as Blockchain from "blockchain";
import { useDispatch, useSelector } from "react-redux";
import ContractButton from "components/buttons/ContractButton";
import { openNFTModal } from "reducers/redux/NFTModal/actions";
import NFTContainer from "components/containers/NFTContainer";
import { Button, Typography } from "@material-ui/core";
import { NftActions } from "../../Dashboard/Achievement/styles";
import { OuterNFTWrapper } from "../styles.js";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const NFTList = ({ nftData }) => {
  const {
    saleId,
    image,
    name,
    description = "",
    longDescription,
    count,
    countMax,
    payment,
    USDPrice,
    participationToken,
    participationAddress,
    participationAmount,
    paymentContract,
    marketContract,
    currentTokenPrice,
    mintingHandler,
    getHandler,
    allowanceHandler,
    connectHandler,
  } = nftData;

  const modalData = {
    image,
    count,
    currentTokenPrice: Blockchain.getDisplayVal(currentTokenPrice, payment.decimals),
    name,
    description,
    longDescription,
    tokenSymbol: payment.symbol,
    exchangeRate: USDPrice || payment.exchangeRate,
    participationToken,
    participationAmount,
  };

  const dispatch = useDispatch();

  const web3 = useSelector((state) => state.web3);
  const balances = useSelector((state) => state.balances);
  const allowances = useSelector((state) => state.allowances);

  const loading = useSelector((state) => state.loading);
  let buttonIsLoading = false;
  if (loading.partialScreen["nftButtons--is-loading-nfts"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-handling-allowance"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-minting-nft"]) {
    buttonIsLoading = true;
  }

  return (
    <OuterNFTWrapper>
      <NFTContainer onClick={() => dispatch(openNFTModal(modalData))} nftData={nftData}>
        <NftActions>
          <ContractButton
            loading={buttonIsLoading}
            mintHandler={() => mintingHandler(saleId)}
            getHandler={() => getHandler(payment, currentTokenPrice)}
            allowanceHandler={() => allowanceHandler(saleId)}
            web3Connected={web3.connected}
            connectHandler={connectHandler}
            tokenSymbol={payment.symbol}
            userWalletAllowance={Blockchain.getDisplayVal(
              allowances?.[paymentContract]?.[marketContract] || 0,
              payment.decimals
            )}
            participationAmount={Blockchain.getDisplayVal(
              participationAmount || 0,
              participationToken.decimals
            )}
            hasParticipationAmount={participationAddress}
            participationName={participationToken.symbol}
            currentTokenPrice={Blockchain.getDisplayVal(
              currentTokenPrice,
              payment.decimals
            )}
            userBalance={Blockchain.getDisplayVal(
              balances?.[paymentContract] || 0,
              payment.decimals
            )}
            paymentToken={paymentContract}
            needsGXP={web3?.needsGXP}
          />
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => dispatch(openNFTModal(modalData))}
          >
            <Typography>View Details</Typography>
          </Button>
          <div style={{ margin: "auto 0" }}>
            <StandardToolTip
              title="Purchase of this NFP will use your LP tokens"
              variant="body1"
            />
          </div>
        </NftActions>
      </NFTContainer>
    </OuterNFTWrapper>
  );
};

export default NFTList;
