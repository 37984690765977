import {
  SNACKBAR_OPEN,
  SNACKBAR_SET_CONTENT,
  SNACKBAR_CLOSE,
  SNACKBAR_CLEAR_CONTENT,
} from "./types";

export const setSnackbarContent = (content) => ({
  type: SNACKBAR_SET_CONTENT,
  payload: content,
});

export const clearSnackbarContent = () => ({
  type: SNACKBAR_CLEAR_CONTENT,
});

export const openSnackbar = () => ({
  type: SNACKBAR_OPEN,
});

export const openAndSetSnackbarContent = (content) => (dispatch) => {
  dispatch({
    type: SNACKBAR_OPEN,
  });
  dispatch({
    type: SNACKBAR_SET_CONTENT,
    payload: content,
  });
};

export const closeSnackbar = () => ({
  type: SNACKBAR_CLOSE,
});
