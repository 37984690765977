import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, reduxForm } from "redux-form";
import { renderTextField, renderSelectField } from "utils/form";
import Text from "components/Text";
import formHelp from "utils/formHelp";

import LoadingButton from "components/LoadingButton";

const validate = formHelp.gen({
  email_address: formHelp.validEmailRequired,
  role: true,
});

class InviteContributorModal extends PureComponent {
  render() {
    const { handleSubmit, submitting, invalid, error, onClose } = this.props;
    return (
      <Dialog open onClose={onClose}>
        <div className="ma--40">
          <Text variant="h6">Add User</Text>
          <Text className="tc--leSecondary">
            If the person does not already have a players profile, they will get invited
            to make one. They will therefor have access to both the player platform and
            the publisher side.
          </Text>
          <Field
            component={renderTextField}
            label="Invites' email address"
            name="email_address"
            className="mt--24 mb--24"
            fullWidth
          />
          <Field
            component={renderSelectField}
            label="Select permission level"
            name="role"
            className="mt--24"
            fullWidth
          >
            <MenuItem value="contributor">Contributor Access</MenuItem>
            <MenuItem value="admin">Admin Access</MenuItem>
          </Field>
          {error && <Text className="tc--redBase">{error}</Text>}
          <LoadingButton
            className="mt--40"
            variant="outlined"
            fullWidth
            onClick={handleSubmit}
            disabled={invalid}
            loading={submitting}
          >
            Invite Contributor
          </LoadingButton>
        </div>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: "inviteContributor",
  initialValues: { role: "contributor" },
  validate,
})(InviteContributorModal);
