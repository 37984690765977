import {
  CLEAR_STEP,
  SET_ACTIVE_STEP,
  SET_ACTIVE_WINDOW,
  SET_BLOCK_CHAIN,
  SET_ADDRESS,
} from "./types";

export const setBlockchain = (blockchain) => {
  return {
    type: SET_BLOCK_CHAIN,
    blockchain,
  };
};

export const setAddress = (address) => {
  return {
    type: SET_ADDRESS,
    address,
  };
};

export const setActiveWindow = (active) => {
  return {
    type: SET_ACTIVE_WINDOW,
    active,
  };
};

export const setActiveStep = (active) => {
  return {
    type: SET_ACTIVE_STEP,
    active,
  };
};

export const clearSteps = () => {
  return {
    type: CLEAR_STEP,
  };
};
