import React from "react";
import Logo from "../Logo/Logo";
import Content from "../Dashboard/Content";

const Dashboard = React.memo(() => {
  return (
    <div>
      <Logo />
      <Content />
    </div>
  );
});

export default Dashboard;
