import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { Link } from "react-router-dom";
import Steam from "images/settings/steam.svg";
import Text from "components/Text";
import RedirectButton from "../../../buttons/RedirectButton";
import { AchievementContentHeaderActions } from "../styles";
import { HeaderButton, DarkButton, SteamButton, SteamIcon } from "./HeaderActions.styles";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333a40",
    maxWidth: "280px",
    padding: "10px 20px",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontWeight: "400px",
    fontSize: "14px",
    lineHeight: "21px",
    position: "relative",
  },
}));

const HeaderActions = React.memo(
  ({
    activeTab,
    setActiveTab,
    claimHandler,
    steamHandler,
    claimValue,
    loading,
    error,
  }) => {
    const classes = useStyles();
    const ClaimValueRender = () =>
      claimValue ? `Claim ${claimValue} GXG` : "Claim 0 GXG";

    const HeaderButtonRender = () => (
      <>
        {error == "Could not verify ownership of token" ? (
          // <>
          //   <Text variant="s2" style={{ whiteSpace: "breack-spaces", maxWidth: "220px" }}>
          //     You need the battle pass to claim your points, get it here:
          //   </Text>
          <HeaderButton style={{ marginLeft: "12px" }} component={Link} to="/marketplace">
            Get Battle Pass
          </HeaderButton>
        ) : (
          // </>
          <>
            <HeaderButton
              disabled={!claimValue || loading || error}
              onClick={claimHandler}
            >
              {loading ? <CircularProgress /> : <ClaimValueRender />}
            </HeaderButton>
            <Text>{error}</Text>
          </>
        )}
      </>
    );

    const ButtonsRender = () => {
      return (
        <>
          <Link to="/dashboard">
            <DarkButton>Back</DarkButton>
          </Link>
          <RedirectButton>
            {(user) =>
              user.username && !user.gid_steam ? (
                <SteamButton onClick={steamHandler}>
                  Connect to Steam
                  <SteamIcon src={Steam} />
                </SteamButton>
              ) : (
                <div style={{ display: "flex" }}>
                  <HeaderButtonRender />
                  <div style={{ margin: "auto 5px" }}>
                    <StandardToolTip
                      title={
                        "You need the battle pass to claim your points. Get it on the marketplace."
                      }
                    />
                  </div>
                </div>
              )
            }
          </RedirectButton>
        </>
      );
    };

    return (
      <AchievementContentHeaderActions activeTab={activeTab}>
        {activeTab !== 0 ? (
          <DarkButton onClick={() => setActiveTab((prev) => --prev)}>Back</DarkButton>
        ) : (
          <ButtonsRender />
        )}
      </AchievementContentHeaderActions>
    );
  }
);

export default HeaderActions;
