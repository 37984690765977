import { BLOCKCHAINS } from "blockchain/tokenInfo";
import JSBI from "jsbi";
import { IS_PROD } from "./environments";

export const LOADING = "LOADING";
export const NOT_EXISTS = "NOT_EXISTS";
export const EXISTS = "EXISTS";
export const INVALID = "INVALID";

// exports for external consumption
// export const BigintIsh = JSBI | bigint | string;

export const TradeType = {
  EXACT_INPUT: "EXACT_INPUT",
  EXACT_OUTPUT: "EXACT_OUTPUT",
};

export const Rounding = {
  ROUND_DOWN: "ROUND_DOWN",
  ROUND_HALF_UP: "ROUND_HALF_UP",
  ROUND_UP: "ROUND_UP",
};

export const INIT_CODE_HASH = IS_PROD
  ? "0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b"
  : "0x6f0da62465aadc451f63c516879623fd4ce8f8b85d077443c173b75a801863dc";

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

export const Bases = {
  [BLOCKCHAINS.binance]: ["WBNB", "Binance USD", "USDT"],
  [BLOCKCHAINS.testEthereum]: ["WTETH"],
};

export const currency_schemas = [
  {
    market: "coinmarketcap",
    _id: "616f28a057686e422ee31023",
    usd_price: 317.480126292,
    price_source: "manual",
    contract_name: "TETH",
    decimals: 18,
    id: 1,
    __v: 0,
    market_indicator: "12531",
    updatedAt: "2021-12-17T21:28:14.281Z",
    createdAt: "2021-10-25T09:29:58.835Z",
  },
  {
    market: "coinmarketcap",
    _id: "616f28a057686e422ee31023",
    usd_price: 0.00317480126292,
    price_source: "manual",
    contract_name: "WTETH",
    decimals: 18,
    id: 1,
    __v: 0,
    market_indicator: "12531",
    updatedAt: "2021-12-17T21:28:14.281Z",
    createdAt: "2021-10-25T09:29:58.835Z",
  },
  {
    market: "coinmarketcap",
    _id: "616f28a057686e422ee31023",
    usd_price: 0.00317480126292,
    price_source: "manual",
    contract_name: "GameXChange",
    decimals: 18,
    id: 1,
    __v: 0,
    market_indicator: "12531",
    updatedAt: "2021-12-17T21:28:14.281Z",
    createdAt: "2021-10-25T09:29:58.835Z",
  },
  {
    market: "coinmarketcap",
    _id: "6170564fb7e81f4f84eb8ceb",
    usd_price: 2.8470813250603393e-8,
    price_source: "automatic",
    contract_name: "Space Monkey",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/12755.png",
    decimals: 9,
    id: 2,
    __v: 0,
    market_indicator: "12755",
    updatedAt: "2021-11-25T22:18:11.832Z",
    createdAt: "2021-10-26T01:11:21.027Z",
  },
  {
    market: "coinmarketcap",
    _id: "616f28a057686e422ee34444",
    usd_price: 0.00317480126292,
    price_source: "manual",
    contract_name: "Binance USD",
    image:
      "https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.svg",
    decimals: 18,
    id: 3,
    __v: 0,
    market_indicator: "12755",
    updatedAt: "2021-11-25T22:18:11.832Z",
    createdAt: "2021-10-26T01:11:21.027Z",
  },
];
