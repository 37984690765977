import { Grid } from "@material-ui/core";
import React from "react";

const GameGridList = ({ children, keyId }) => {
  return (
    <Grid container spacing={1} justifyContent="flex-start" key={keyId}>
      {children}
    </Grid>
  );
};

export default GameGridList;
