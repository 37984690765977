import styled from "styled-components";

export const SignInWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: stretch;
  overflow-y: auto;
  max-width: 960px;
  padding: 25px;
  margin: auto;
  font-size: 12px;
  box-sizing: border-box;

  & > div {
    height: 100%;
    margin: auto;
  }

  @media (max-width: 768px) {
    padding: 0 2rem;
    align-items: flex-start;

    & > div {
      width: 100%;
      max-width: 70vw;
      margin: auto;
    }
  }

  @media (max-width: 480px) {
    min-height: 0vh;

    & > div {
      margin-top: 10vh;
      max-width: 100vw;
    }
  }
`;

export const ForgotPasswordWrapper = styled(SignInWrapper)`
  max-width: 750px;
`;
