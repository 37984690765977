import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainAvatar from "./MainAvatar";
import { Popover, Grid } from "@material-ui/core";
import * as badgeIcons from "utils/badgeIcons";
import { updateUser } from "actions/user";
import mysteryAvatar from "images/profile/mysteryAvatar.png";

const AvatarPicker = ({ avatarList }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatar, setAvatar] = useState(user.avatar || mysteryAvatar);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeAvatar = (icon) => {
    user.avatar = icon;
    dispatch(updateUser(user)).then(() => {
      setAvatar(icon);
    });
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <MainAvatar avatar={avatar} handleClick={handleClick} />

      <Popover
        avatarList={avatarList}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { maxWidth: "400px", padding: "10px" },
        }}
      >
        <Grid container xs={12} spacing={1} justifyContent="center" alignItems="center">
          {badgeIcons.packNames.map((packName) =>
            badgeIcons.iconPacks[packName].icons.map((icon) => (
              <Grid item>
                <img
                  alt="badge"
                  onClick={() => {
                    handleChangeAvatar(
                      `https://img.tapplatform.io/stat/icons/badge/${icon}.png`
                    );
                  }}
                  src={`https://img.tapplatform.io/stat/icons/badge/${icon}.png`}
                  style={{
                    width: "100%",
                    maxWidth: "40px",
                    maxHeight: "40px",
                    border: "1px solid green",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Popover>
    </div>
  );
};

export default AvatarPicker;
