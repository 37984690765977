import React from "react";
import Text from "components/Text";

//import { store } from "store/index";
import { isStrDefault } from "utils/helpers";
import { openAndSetSnackbarContent } from "./snackbar";

export const renderErrorSnackbar = (msg) => {
  const nxtMsg = isStrDefault(msg, "Something Went Wrong");
  const content = {
    msgVariant: "error",
    message: <Text variant="h6">{nxtMsg}</Text>,
  };
  // const { dispatch } = store;
  // openAndSetSnackbarContent(content)(dispatch);
};

export const renderSuccessSnackbar = (msg = "Success") => {
  const nxtMsg = isStrDefault(msg, "Success");
  const content = {
    msgVariant: "success",
    message: <Text variant="h6">{nxtMsg}</Text>,
  };
  // const { dispatch } = store;
  // openAndSetSnackbarContent(content)(dispatch);
};
