import React, { Component } from "react";
import { Button, Grid, MenuItem, Typography, Divider } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { registerPublisherInfo, resendEmailValidation } from "actions/index";
import * as Info from "assets/info";
import * as Form from "utils/form";
import Text from "components/Text";
import { history } from "../../../store/index";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "entity",
    "name",
    "address",
    "city",
    "zip",
    "country",
    "first_name",
    "last_name",
    "company_phone",
    "email_address",
    "website_url",
    "agreement",
    "logo",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (
    values.primary_contact_email_address &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.primary_contact_email_address
    )
  ) {
    errors.primary_contact_email_address = "Invalid email address";
  }

  return errors;
};

class RegisterCompany extends Component {
  constructor(props) {
    super(props);
    this.getStepContent = this.getStepContent.bind(this);
  }

  componentWillMount() {
    this.setState({
      keyButtonDisabled: false,
    });
  }

  getStepContent(step) {
    switch (step) {
      case "info":
        return this.getFirstStepContent();
      case "details":
        if (!this.props.form.values) {
          history.push("/pub/onboarding/company/info");
        }
        return this.getSecondStepContent();
      case "verify":
        return this.getThirdStepContent();
      default:
        return "Invalid URL";
    }
  }

  getFirstStepContent() {
    return (
      <Grid container className="register pub-register mb--96">
        <Grid item xs={12}>
          <Field
            name="logo"
            imgStyle={{
              height: "174px",
              width: "174px",
              borderRadius: "87px",
              backgroundColor: "rgba(23, 23, 26, 0.3)",
              marginTop: "40px",
            }}
            nolabel
            src="https://img.tapplatform.io/stat/icons/add-image.svg"
            id="file-upload"
            component={Form.FieldFileInput}
          />
          <Text variant="s1" className="mt--24">
            Logo
          </Text>
          <Text variant="p2" color="textSecondary">
            If possible a transparent PNG file. Max 2MB
          </Text>
          <label htmlFor="file-upload">
            <Button component="span" fullWidth variant="outlined" className="mt--24">
              Upload Icon
            </Button>
          </label>
          <Field
            className="mt--40"
            name="organization_type"
            component={Form.renderSelectField}
            label="Individual or legal entity?"
            fullWidth
            required
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="enterprise">Enterprise</MenuItem>
          </Field>
          <Field
            className="mt--40"
            name="name"
            component={Form.renderTextField}
            label="Business Name"
            fullWidth
            required
          />
          <Field
            className="mt--40"
            name="website_url"
            component={Form.renderTextField}
            label="Website Url"
            fullWidth
            required
          />
          <Button
            className="mt--40"
            variant="text"
            color="primary"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            component={Link}
            to="/pub/onboarding/company/details"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }

  getSecondStepContent() {
    return (
      <Grid container className="register pub-register">
        <Grid item xs={12} className="mt--64">
          <Text variant="h4">Address</Text>
          <Divider className="thin mb--32" />
          <Field
            name="address"
            component={Form.renderTextField}
            label="Address"
            fullWidth
            required
          />
          <Field
            className="mt--40"
            name="secondary_address"
            component={Form.renderTextField}
            label="Address line 2 (optional)"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="mt--40">
          <Field
            name="city"
            component={Form.renderTextField}
            label="City"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className="mt--40">
          <Field
            name="zip_postal_code"
            component={Form.renderTextField}
            label="Zip"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className="mt--48">
          <Field
            fullWidth
            name="country"
            component={Form.renderSelectField}
            label="Country of Origin"
            required
          >
            {Info.countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Text variant="h4">Company Contact</Text>
          <Divider className="thin mb--32" />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                name="primary_contact_first_name"
                component={Form.renderTextField}
                label="First Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="primary_contact_last_name"
                component={Form.renderTextField}
                label="Last Name"
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--48">
          <Field
            name="primary_contact_phone_number"
            component={Form.renderTextField}
            label="Phone Number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className="mt--48">
          <Field
            name="primary_contact_email_address"
            component={Form.renderTextField}
            label="Email address"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} className="mt--40" container>
          <Field name="agreement" component={Form.renderCheckbox} required />
          <Text variant="b2" className="checkbox-label">
            I acknowledge that I am legally able to create agreements on behalf of the
            company named in this form.
          </Text>
          <Button
            variant="text"
            color="primary"
            className="mt--40"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((data) => {
              this.setState({
                keyButtonDisabled: true,
              });
              this.props.registerPublisherInfo(data);
            })}
          >
            Next
          </Button>
          <Typography className="text__red">
            {this.props.publisher.registerError}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  getThirdStepContent() {
    return (
      <Grid container className="register pub-register">
        <Grid item xs={12}>
          <Text variant="h4">Confirm your email address</Text>
          <Text variant="p2" color="textSecondary">
            An email with a sign in link was sent to{" "}
            <a href={`mailto:${this.props.user.email_address}`}>
              {this.props.user.email_address}
            </a>
            . Click the link in the email to confirm your email address. A valid email is
            needed to recover your password and send important notifications.
          </Text>
          <Button className="mt--32" fullWidth variant="outlined">
            You can close this window
          </Button>
          <Button
            onClick={() => {
              this.setState({ isSaving: true });
              this.props.resendEmailValidation().then(() => {
                this.setState({ isSaving: false });
              });
            }}
            fullWidth
            variant="text"
            color="secondary"
            className="mt--16"
          >
            {this.state.isSaving ? <LoadingSpinner /> : "Resend email"}
          </Button>
          <Text align="center">
            {this.props.user.formSuccess
              ? this.props.user.formSuccess
              : this.props.user.formError}
          </Text>
        </Grid>
      </Grid>
    );
  }

  render() {
    return <div>{this.getStepContent(this.props.match.params.step)}</div>;
  }
}

const mapStateToProps = (state) => ({
  form: state.form.register,
  publisher: state.publisher,
  user: state.user,
});

const mapDispatchToProps = {
  registerPublisherInfo,
  resendEmailValidation,
};

export default compose(
  reduxForm({ form: "register", validate }),
  connect(mapStateToProps, mapDispatchToProps)
)(RegisterCompany);
