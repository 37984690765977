import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { signOut } from "actions/index";
import { Link } from "react-router-dom";
import Text from "components/Text";
import TapLogo from "assets/icons/brand/Taplogo-white.svg";

class WalletModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCloseModal() {
    this.props.setModal("");
  }

  /**
    -prepare to add rewards
   */
  render() {
    return (
      <Dialog
        open={this.props.etc.modal === "walletInfo"}
        onClose={() => this.handleCloseModal()}
        maxWidth="sm"
        className="wallet-modal mt--40"
        PaperProps={{
          className: `wallet-modal-paper`,
        }}
      >
        <Grid container spacing={3} className="wallet mt--8" justifyContent="center">
          <Grid item container justifyContent="center" xs={12}>
            <Text variant="h4" color="inherit">
              My Wallet
            </Text>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Grid item>
              <Text variant="h2" color="inherit">
                {Math.round(this.props.web3.balance)}
              </Text>
            </Grid>
            <Grid item>
              <TapLogo className="wallet__taplogo" />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              size="large"
              color="primary"
              component={Link}
              to={`https://bscscan.com/address/${this.props.user.eth_wallet_address}`}
              target="_blank"
            >
              View On BscScan
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              size="large"
              color="primary"
              onClick={() => this.handleCloseModal()}
              component={Link}
              to="/wallet"
            >
              My Vaults
            </Button>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button size="large" color="primary" onClick={() => this.props.signOut()}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = {
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletModal);
