import React from "react";
import { FlexGrid } from "../../../Marketplace/styles";
import List from "../list/nfts";
import Actions from "../list/Actions";
import ClaimGamePoints from "../claimGamePoints";

const ContentTabs = React.memo(
  ({ activeTab, setActiveTab, availableNfts, MintHandler, current }) => {
    if (activeTab === 0) {
      return (
        <>
          <div>
            <FlexGrid>
              <List list={availableNfts}>
                {(id, loading, claimed, achieved, achievementName, index) => (
                  <Actions
                    id={id}
                    index={index}
                    loading={loading}
                    claimed={claimed}
                    achieved={achieved}
                    achievementName={achievementName}
                    MintHandler={MintHandler}
                    current={current}
                  />
                )}
              </List>
            </FlexGrid>
          </div>
        </>
      );
    }

    if (activeTab === 1) {
      return <ClaimGamePoints setActiveTab={setActiveTab} current={current} />;
    }

    return <></>;
  }
);

export default ContentTabs;
