import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { renderTextField } from "utils/form";
import * as actions from "actions";
import formHelp from "utils/formHelp";
import * as formHelpAsync from "utils/formHelpAsync";

import {
  SectionHeading,
  SectionText,
  SectionInfo,
} from "components/containers/settings/Settings";
import FormModal from "./FormModal";

class PubSecuritySettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      formModalProps: null,
    };

    this.handleCloseFormModal = this.handleCloseFormModal.bind(this);
    this.renderSuccessSnackbar = this.renderSuccessSnackbar.bind(this);
    this.renderChangeEmailModal = this.renderChangeEmailModal.bind(this);
    this.renderChangeUsernameModal = this.renderChangeUsernameModal.bind(this);
    this.renderChangePassModal = this.renderChangePassModal.bind(this);
  }

  get commonFormModalProps() {
    return {
      initialValues: this.props.user,
      onClose: this.handleCloseFormModal,
      getCurrentUser: this.props.getCurrentUser,
      renderSuccessSnackbar: this.renderSuccessSnackbar,
    };
  }

  handleCloseFormModal() {
    this.setState({
      formModalProps: null,
    });
  }

  renderSuccessSnackbar() {
    actions.renderSuccessSnackbar("Changes Saved");
  }

  renderFormModal() {
    if (!this.state.formModalProps) {
      return null;
    }
    return <FormModal {...this.state.formModalProps} />;
  }

  renderChangeUsernameModal() {
    this.setState({
      formModalProps: {
        modalTitle: "Change Username",
        form: "changeUsername",
        formContent: (
          <div>
            <Field
              component={renderTextField}
              label="New Username"
              name="username"
              className="mt--40"
              fullWidth
            />
            <Field
              component={renderTextField}
              label="Enter Password"
              name="password"
              type="password"
              className="mt--40"
              fullWidth
            />
          </div>
        ),
        submitBtnText: "Change Username",
        action: (data) => {
          const { id } = this.props.user;
          return this.props.updateUsername(id || "", data);
        },
        validate: formHelp.gen({
          username: formHelp.validUsernameRequired,
          password: true,
        }),
        touchOnChange: true,
        asyncValidate: (values) => formHelpAsync.isValidUsername(values),
        ...this.commonFormModalProps,
      },
    });
  }

  renderChangeEmailModal() {
    this.setState({
      formModalProps: {
        modalTitle: "Change Email Address",
        modalCaption: "Email is used to reset your password and important notifications.",
        form: "changeUserEmail",
        formContent: (
          <div>
            <Field
              component={renderTextField}
              label="New Email Address"
              name="email_address"
              className="mt--40"
              fullWidth
            />
            <Field
              component={renderTextField}
              label="Enter Password"
              name="password"
              type="password"
              className="mt--40"
              fullWidth
            />
          </div>
        ),
        submitBtnText: "Change Email",
        action: ({ email_address = "", password = "" }) =>
          this.props.updateEmail(this.props.user.id, {
            email_address,
            password,
          }),
        validate: formHelp.gen({
          email_address: formHelp.validEmailRequired,
          password: true,
        }),
        ...this.commonFormModalProps,
      },
    });
  }

  renderChangePassModal() {
    this.setState({
      formModalProps: {
        modalTitle: "Change Password",
        form: "changeUserPassword",
        formContent: (
          <div>
            <Field
              component={renderTextField}
              label="Enter Password"
              name="password"
              className="mt--40"
              type="password"
              fullWidth
            />
            <Field
              component={renderTextField}
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              className="mt--40"
              fullWidth
            />
          </div>
        ),
        submitBtnText: "Change Password",
        action: ({ password }) => this.props.updatePassword(password),
        validate: formHelp.gen({
          password: true,
          confirmPassword: ({ confirmPassword, password }) => {
            if (!confirmPassword) {
              return "Required";
            }
            if (confirmPassword !== password) {
              return "Passwords do not match";
            }
            return null;
          },
        }),
        touchOnChange: true,
        ...this.commonFormModalProps,
      },
    });
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        <SectionHeading>Username and Email</SectionHeading>
        <SectionText>
          You need to enter your password to authenticate and change username or email.
        </SectionText>
        <Grid container className="mt--24">
          <Grid item xs={8}>
            <SectionInfo caption="Master account username" info={user && user.username} />
          </Grid>
          <Grid item xs={4}>
            <Button onClick={this.renderChangeUsernameModal} fullWidth variant="outlined">
              Change Username
            </Button>
          </Grid>
          <Grid item xs={8}>
            <SectionInfo
              className="mt--24"
              caption="Master account email"
              info={user && user.email_address}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={this.renderChangeEmailModal}
              fullWidth
              className="mt--24"
              variant="outlined"
            >
              Change Email
            </Button>
          </Grid>
          <Grid className="mt--40" item xs={8}>
            <SectionHeading>Password</SectionHeading>
            <SectionText>Change your password.</SectionText>
          </Grid>
          <Grid className="mt--40" item xs={4}>
            <Button
              onClick={this.renderChangePassModal}
              fullWidth
              variant="outlined"
              className="mt--24"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
        {this.renderFormModal()}
      </div>
    );
  }
}

const mapState = (state) => ({
  user: state.user,
});

export default connect(mapState, actions)(PubSecuritySettings);
