import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { connect as connectWallet } from "actions/web3";
import Steps from "../index";
import {
  ImgWrapper,
  Message,
  Name,
  Address,
  AddressWrapper,
  AddressSpan,
} from "../styles";
import Actions from "./Actions";
import { isValidWalletAddress } from "actions";
import { CheckCircle, Error } from "@material-ui/icons";
import Loader from "components/loaders";

export const Step3 = React.forwardRef(({ setActiveStep }, ref) => {
  const dispatch = useDispatch();
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);
  const web3 = useSelector((state) => state.web3);
  const walletConnected = web3.connected;

  const [loader, setLoader] = useState("");
  const [errorMsg, setError] = useState("");
  const [validAddress, setValidAddress] = useState(false);
  const disabled = !!loader || !validAddress || !web3.connected;

  const handleWalletConnect = () => {
    dispatch(connectWallet());
  };

  const changeStepHandler = (value) => {
    setActiveStep(value, value === "+");
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const isValid = () => {
    if (!!web3.connected && !loader) {
      setLoader(true);
      isValidWalletAddress(web3.connected).then((res) => {
        sleep(1000).then(() => {
          if (res === false) {
            setError("");
            setValidAddress(true);
            setLoader(false);
          } else if (res === true) {
            setError("That address is already in use!");
            setValidAddress(false);
            setLoader(false);
          }
        });
      });
    }
  };

  useEffect(() => {
    if (!!web3.connected) {
      isValid();
    }
  }, [web3.connected]);

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      actions={
        <Actions
          setActiveStep={changeStepHandler}
          current={2}
          disabled={disabled}
          address={walletConnected}
        />
      }
    >
      <ImgWrapper>
        <AddressWrapper>
          <Address>
            {walletConnected ? (
              <>
                <div>
                  <AddressSpan>
                    <span>{walletConnected}</span>
                  </AddressSpan>
                  {loader ? (
                    <>
                      <Loader />
                    </>
                  ) : validAddress ? (
                    <CheckCircle style={{ fontSize: "20px", fill: "#5FD754" }} />
                  ) : (
                    <Error style={{ fontSize: "20px", fill: "#DC3545" }} />
                  )}
                </div>
                <Typography
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: "600",
                  }}
                  variant="caption"
                  align="center"
                >
                  {errorMsg}
                </Typography>
              </>
            ) : (
              <Button variant="contained" color="primary" onClick={handleWalletConnect}>
                Unlock Wallet
              </Button>
            )}
          </Address>
        </AddressWrapper>
      </ImgWrapper>

      <Name>Connect Your Wallet</Name>

      <Message>
        Unlock your wallet to link your wallet address with your Game X Change profile.
      </Message>
    </Steps>
  );
});

export default Step3;
