import React from "react";
import { SignInWrapper } from "../SignIn/styles";
import GamesList from "../SignIn/GamesList";
import FormContainer from "../SignIn/Form/FormContainer";
import Form from "./Form";

const Content = () => {
  return (
    <SignInWrapper>
      <GamesList />
      <FormContainer name="Sign up">
        <Form />
      </FormContainer>
    </SignInWrapper>
  );
};

export default Content;
