import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Grid, Paper, Divider, Button } from "@material-ui/core";
import Text from "components/Text";
// import Treasure from '../assets/bg/treasure.svg';

class ChallengesCard extends Component {
  renderButton() {
    let link = "/challenges";
    let text = "Check your Achievements";

    if (!this.props.user.gid_steam) {
      link = "/settings/connected";
      text = "Connect your steam account";
    } else if (this.props.vault) {
      if (this.props.vault.attributes.earned_currency > 0) {
        text = `Claim ${this.props.vault.attributes.earned_currency} Tap`;
        link = `/vaults/${this.props.vault.id}`;
      } else {
        link = "/challenges";
        text = "Check your Achievements";
      }
    }

    return (
      <Button variant="text" color="primary" fullWidth component={Link} to={link}>
        {text}
      </Button>
    );
  }

  render() {
    return (
      <Grid container className="challenges">
        <Grid item xs={12}>
          <Divider className="thin" />
        </Grid>
        <Grid item xs={12}>
          <Paper className="challenges__content">
            {/* <Treasure className="m--auto display-block" /> */}
            {this.props.recentAchievements.map((achievement) => (
              <Paper key={achievement.name}>
                {achievement.achieved ? (
                  <Grid container>
                    <Grid item xs={2} md={2} className="pa--24">
                      <img
                        height="48"
                        width="48"
                        src={achievement.icon}
                        alt="achievement"
                      />
                    </Grid>
                    <Grid item xs={10} md={10} className="pt--24 pb--24">
                      <Text inline variant="p1">
                        {achievement.gameName}
                      </Text>
                      <Text className="ml--4" inline variant="p1" color="textSecondary">
                        challenge completed
                      </Text>
                      <Text variant="p1">
                        {achievement.description || "???"}
                        <br />
                        Reward {achievement.tapval} TTT
                      </Text>
                      <Text variant="caption" color="textSecondary">
                        {moment(achievement.unlocktime).format("D MMM, H:mma")}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className="thin" />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Paper>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider className="thin" />
        </Grid>
        <Grid item xs={12}>
          <Paper className="pl--24 pr--24 pt--16 pb--16">{this.renderButton()}</Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  user: state.user,
  web3: state.web3,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesCard);
