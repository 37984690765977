import {
  PUB_DASHBOARD,
  REGISTER_ERROR,
  OWNER_ORGANIZATION,
  GAME_UPDATE,
  ORGANIZATION_MEMBERS,
  UPDATE_ORGANIZATION_MEMBER,
  ORGANIZATION_INVITES,
  ORGANIZATION_ID,
  PUB_GAME_VIEW_LIST,
} from "../actions/types";

const initial = {
  /*
  ids: [],
  byId: {}, */
};

export default (state = initial, action) => {
  switch (action.type) {
    case PUB_DASHBOARD: {
      return {
        ...state,
        currentOrganization: action.currentOrganization.attributes,
        currentOrgId:
          action.currentOrganization.attributes &&
          action.currentOrganization.attributes.id,
      };
    }
    case GAME_UPDATE: {
      return {
        ...state,
        gameUpdate: action.message,
      };
    }
    case PUB_GAME_VIEW_LIST: {
      return { ...state, dashboard_games: action.games };
    }
    case REGISTER_ERROR: {
      return {
        ...state,
        registerError: action.registerError,
      };
    }
    case OWNER_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: action.currentOrganization,
      };
    }
    case ORGANIZATION_ID: {
      return {
        ...state,
        currentOrgId: action.payload,
      };
    }
    case ORGANIZATION_MEMBERS: {
      return {
        ...state,
        members: action.payload,
      };
    }
    case ORGANIZATION_INVITES: {
      return {
        ...state,
        invitees: action.payload,
      };
    }
    case UPDATE_ORGANIZATION_MEMBER: {
      return {
        ...state,
        members: {
          ...state.members,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
