import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Select,
  Grid,
  Paper,
  Divider,
  MenuItem,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import Text from "components/Text";
import LoadingButton from "components/LoadingButton";
import {
  clearErrors,
  startConversionFromTap,
  finishConversionFromTap,
  startConversionFromIngame,
  getCurrentUser,
  connect,
  getConversionBalance,
} from "actions/index";
import { getTransactionReceipt } from "utils/web3Calls";
import * as Form from "utils/form";
// import * as ClaimAPI from "utils/claimAPI";
import { ReactComponent as Exchange } from "assets/icons/exchange.svg";
import Bottle from "assets/icons/GXP.png";
import { getAllowanceOfTokenByName, getBalanceOfTokenByName } from "utils/reduxUtils";
import {
  getBalanceOf,
  getConversionAllowance,
  getGXPRequirements,
  approveFor,
} from "actions/web3";
import {
  CONVERSION_INFO,
  getTokenFromAddressByChain,
  TOKEN_INFO,
} from "blockchain/tokenInfo";
import * as Web3Utils from "blockchain/web3Utils";
import { BN, toBaseUnit } from "blockchain/web3Utils";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";
import { CurrencyText, CurrencyImage } from "./styles";

const ConversionCard = (props) => {
  const dispatch = useDispatch();
  const {
    vault,
    game,
    pendingOut,
    pendingIn,
    conversionRate,
    needsToken,
    valid,
    blockchainCurrencyIndex,
    setBlockchainCurrencyIndex,
    fetchVaultInfo,
  } = props;
  const [step, setStep] = useState(1);
  const [currencyId, setCurrencyId] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [fromTap, setFromTap] = useState(false);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [txInfo, setTxInfo] = useState(false);
  const [txError, setTxError] = useState(false);
  const [skipApproval, setSkipApproval] = useState(false);
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const balances = useSelector((state) => state.balances);
  const allowances = useSelector((state) => state.allowances);

  const [rateInfo, setRateInfo] = useState({});
  const token = TOKEN_INFO[rateInfo?.contract_name] || {};
  const { symbol } = token;
  const needsGXP = web3?.needsGXP;

  useEffect(() => {
    if (user.eth_wallet_address) {
      //Ensure we have most up to date user info
      dispatch(getCurrentUser());
    }
  }, []);

  useEffect(() => {
    const { decimals, contract_name } = rateInfo;
    if (game && contract_name) {
      getConversionBalance(contract_name).then((balance) =>
        setMaxAmount(parseInt(balance.substring(0, balance.length - decimals)))
      );
      const conversionAllowance = getAllowanceOfTokenByName(
        contract_name,
        CONVERSION_INFO[web3.chainId],
        web3.chainId,
        allowances
      );
      if (!conversionAllowance) {
        dispatch(getConversionAllowance(contract_name));
      }
    }
  }, [game, rateInfo]);

  useEffect(() => {
    dispatch(getGXPRequirements(web3.connected));
  }, [web3.chainId, web3.connected]);

  useEffect(() => {
    setRateInfo(
      currencyId
        ? game?.currency_schemas[blockchainCurrencyIndex]
        : game?.premium_currency_schemas[blockchainCurrencyIndex] || {}
    );
  }, [currencyId, blockchainCurrencyIndex]);

  const wrongWallet =
    web3.connected.localeCompare(user.eth_wallet_address, undefined, {
      sensitivity: "accent",
    }) !== 0;

  const getSecondStepContent = () => {
    return (
      <Grid container className="pa--32" justifyContent="space-between">
        <Grid item xs={12} className="mt--80">
          <Grid item xs={12} className="mt--8">
            {txError ? (
              <Text className="text__red">{txError}</Text>
            ) : (
              <>
                <a
                  target="_blank"
                  href={`https://bscscan.com/tx/${txInfo.hash}`}
                  rel="noopener noreferrer"
                >
                  <Text>Track it on BscScan! Transaction Hash: {txInfo.hash}</Text>
                </a>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getThirdStepContent = () => {
    return (
      <Grid container className="pa--32" justifyContent="space-between">
        <Grid item xs={12} className="mt--80">
          <Grid item xs={12} className="mt--8">
            {txError ? (
              <Text className="text__red">{txError}</Text>
            ) : (
              <>
                <Text>
                  Awaiting Transaction completion, try not to navigate away from this
                  page.
                </Text>
              </>
            )}
          </Grid>
          {Boolean(txError) && (
            <Grid item xs={12} className="mt--16">
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setStep(1);
                }}
              >
                Back
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const getFourthStepContent = () => {
    return (
      <Grid container className="pb--32 pl--32 pr--32" justifyContent="space-between">
        <Grid item xs={12} className="mt--80">
          <Grid item xs={12} className="mt--8">
            {txError ? (
              <Text className="text__red">{txError}</Text>
            ) : (
              <>
                <Text align="center" className="pb--16">
                  Conversion Complete!
                </Text>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getAmountError = () => {
    const bnAmount = Web3Utils.toBaseUnit(amount.toString(), token.decimals || 18);
    const balance = getBalanceOfTokenByName(
      game.currency_schemas[blockchainCurrencyIndex].contract_name,
      web3.chainId,
      balances
    );
    let amountError = "";

    if (!fromTap && maxAmount < convertedAmount) {
      amountError = "Not enough tokens in pool, please try again later.";
    }

    if (fromTap) {
      if (bnAmount.gt(new BN(balance))) {
        amountError = "Not enough currency";
      }
    } else if (currencyId) {
      if (amount > vault.earned_currency) {
        amountError = "Not enough currency";
      }
    } else if (amount > vault.premium_currency) {
      amountError = "Not enough currency";
    }
    return amountError;
  };

  const getAllowanceError = () => {
    let error = false;
    const bnAmount = Web3Utils.toBaseUnit(amount.toString(), token.decimals || 18);
    const allowance = getAllowanceOfTokenByName(
      rateInfo?.contract_name,
      CONVERSION_INFO[web3.chainId],
      web3.chainId,
      allowances
    );
    if (bnAmount.gt(new BN(allowance))) {
      error = "Not enough currency approved";
    }
    return error;
  };

  const approve = async () => {
    const bnAmount = Web3Utils.toBaseUnit(
      "1000000000000",
      token.decimals || 18
    ).toString();
    dispatch(
      approveFor(token.addresses[web3.chainId], bnAmount, CONVERSION_INFO[web3.chainId])
    )
      .then(() => {
        setSkipApproval(true);
      })
      .catch();
  };

  const startConversion = async () => {
    const { eth_wallet_address } = user;
    if (!web3.connected) {
      dispatch(connect());
      return;
    }
    dispatch(clearErrors());
    setButtonDisabled(true);
    const bnAmount = Web3Utils.toBaseUnit(
      amount.toString(),
      token.decimals || 18
    ).toString();
    const tokenAddress = token.addresses[web3.chainId];
    if (fromTap) {
      // Convert tap to ingame
      const result = await startConversionFromTap(
        web3.chainId,
        vault.id,
        game.id,
        currencyId,
        bnAmount,
        tokenAddress,
        eth_wallet_address
      ).catch((error) => {
        setTxError(error?.response?.data || error.message || error.toString());
        setStep(2);
        setButtonDisabled(false);
        return false;
      });
      if (!result) {
        return;
      }
      setTxInfo(result);
      setTxError("");
      setStep(3);
    } else {
      // Convert ingame to tap
      startConversionFromIngame(vault.id, currencyId, amount, tokenAddress)
        .then((result) => {
          fetchVaultInfo();
          // fetch GXP balance
          dispatch(
            getBalanceOf(
              TOKEN_INFO.GameXChange.addresses[web3.chainId],
              user.eth_wallet_address
            )
          );
          setTxInfo(false);
          setTxError("");
          setStep(4);
          setButtonDisabled(false);
        })
        .catch((error) => {
          setTxInfo(false);
          setTxError(error?.response?.data || error.message || error.toString());
          setStep(2);
          setButtonDisabled(false);
        });
    }
  };

  useEffect(() => {
    if (txInfo) {
      const startTimer = setInterval(() => {
        if (txInfo.hash && fromTap) {
          getTransactionReceipt(txInfo.hash).then((done) => {
            if (done) {
              clearInterval(startTimer);
              finishConversionFromTap(txInfo.id, vault.id)
                .then(() => {
                  fetchVaultInfo();
                  // fetch GXP balance
                  dispatch(
                    getBalanceOf(
                      TOKEN_INFO.GameXChange.addresses[web3.chainId],
                      user.eth_wallet_address
                    )
                  );
                  setTxInfo(false);
                  setTxError("");
                  setStep(4);
                  setButtonDisabled(false);
                })
                .catch((err) => {
                  setTxInfo(false);
                  setTxError(err);
                  setStep(4);
                  setButtonDisabled(false);
                });
            }
          });
        }
      }, 10000);
      return () => {
        clearInterval(startTimer);
      };
    }
  }, [txInfo]);
  /*
  const completeFromTapConversion = () => {
    ClaimAPI.convertFromTap(vault.id, currencyId, user.eth_wallet_address, txHash);
  };
  */

  useEffect(() => {
    const { premium_usd_rate, earned_usd_rate } = conversionRate;
    const { usd_price } = rateInfo;
    const unconvertedAmount = amount;
    let newConvertedAmount = 0;
    let rate = (currencyId === 0 ? premium_usd_rate : earned_usd_rate) / usd_price;
    rate *= vault.multiplier;
    if (fromTap) {
      newConvertedAmount = Math.floor(unconvertedAmount / rate);
      setConvertedAmount(newConvertedAmount);
    } else {
      newConvertedAmount = unconvertedAmount * rate;

      // REMEMBER: Throw in fee information here
      const newFee = newConvertedAmount * 0.15;
      newConvertedAmount -= newFee;

      if (newConvertedAmount < 0) {
        newConvertedAmount = 0;
      }
      if (unconvertedAmount > 0) {
        setConvertedAmount(Math.floor(newConvertedAmount * 100) / 100);
        setFee(Math.floor(newFee * 100) / 100);
      } else {
        setConvertedAmount(0);
        setFee(0);
      }
    }
  }, [rateInfo, conversionRate, vault.multiplier, currencyId, fromTap, amount]);

  const renderFrom = () => {
    const amountError = getAmountError();
    return (
      <>
        <Grid container className="outlined">
          <Grid item xs={9}>
            <Field
              name="amount"
              component={Form.renderTextField}
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: "mt--0",
              }}
              label="From"
              type="number"
              onChange={(data) => {
                setAmount(data.currentTarget.valueAsNumber);
              }}
              error={Boolean(amountError)}
            />
          </Grid>
          <Grid item xs={3}>
            {fromTap ? (
              <Select
                value={blockchainCurrencyIndex}
                onChange={(event) => {
                  setBlockchainCurrencyIndex(event.target.value);
                }}
                disableUnderline
              >
                {(currencyId ? game.currency_schemas : game.premium_currency_schemas).map(
                  (currency, index) => (
                    <MenuItem key={currency.contract_name} value={index}>
                      <CurrencyImage
                        height="22px"
                        src={currency.image || Bottle}
                        alt="currency"
                      />
                      <CurrencyText>
                        {TOKEN_INFO[currency.contract_name]?.name}
                      </CurrencyText>
                    </MenuItem>
                  )
                )}
              </Select>
            ) : (
              <Select
                value={currencyId}
                onChange={(event) => {
                  setCurrencyId(event.target.value);
                }}
                disableUnderline
              >
                {game.earned_currency_name && (
                  <MenuItem value={1}>
                    <CurrencyImage
                      height="22px"
                      src={
                        game.earned_currency_image ||
                        "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_earned.png"
                      }
                    />
                    <CurrencyText>{game.earned_currency_name}</CurrencyText>
                  </MenuItem>
                )}
                {game.premium_currency_name && (
                  <MenuItem value={0}>
                    <CurrencyImage
                      height="22px"
                      src={
                        game.premium_currency_image ||
                        "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_premium.png"
                      }
                    />
                    <CurrencyText>{game.premium_currency_name}</CurrencyText>
                  </MenuItem>
                )}
              </Select>
            )}
          </Grid>
        </Grid>
        <FormHelperText className="text__red">{amountError}</FormHelperText>
      </>
    );
  };

  const renderTo = () => {
    return (
      <Grid container className="outlined">
        <Grid item xs={9}>
          <TextField
            name="convertedTapAmount"
            fullWidth
            InputProps={{
              disableUnderline: true,
              className: "mt--0",
              readOnly: true,
            }}
            label="To"
            value={convertedAmount ? convertedAmount.toString() : ""}
          />
        </Grid>
        <Grid item xs={3}>
          {!fromTap ? (
            <Select
              value={blockchainCurrencyIndex}
              onChange={(event) => {
                setBlockchainCurrencyIndex(event.target.value);
              }}
              disableUnderline
            >
              {(currencyId ? game.currency_schemas : game.premium_currency_schemas).map(
                (currency, index) => (
                  <MenuItem key={currency.contract_name} value={index}>
                    <CurrencyImage
                      height="22px"
                      src={currency.image || Bottle}
                      alt="currency"
                    />
                    <CurrencyText>
                      {TOKEN_INFO[currency.contract_name]?.name}
                    </CurrencyText>
                  </MenuItem>
                )
              )}
            </Select>
          ) : (
            <Select
              value={currencyId}
              onChange={(event) => {
                setCurrencyId(event.target.value);
              }}
              disableUnderline
            >
              {game.earned_currency_name && (
                <MenuItem value={1}>
                  <CurrencyImage
                    height="22px"
                    src={
                      game.earned_currency_image ||
                      "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_earned.png"
                    }
                  />
                  <CurrencyText>{game.earned_currency_name}</CurrencyText>
                </MenuItem>
              )}
              {game.premium_currency_name && (
                <MenuItem value={0}>
                  <CurrencyImage
                    height="22px"
                    src={
                      game.premium_currency_image ||
                      "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_premium.png"
                    }
                  />
                  <CurrencyText>{game.premium_currency_name}</CurrencyText>
                </MenuItem>
              )}
            </Select>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderNextButton = () => {
    let amountError = getAmountError();
    const allowanceError = getAllowanceError();
    if (user.eth_wallet_address) {
      let text;
      if (fromTap) {
        if (currencyId === 0) {
          text = `Convert ${symbol} To ${game.premium_currency_name}`;
        } else {
          text = `Convert ${symbol} To ${game.earned_currency_name}`;
        }
      } else if (currencyId === 0) {
        text = `Convert ${game.premium_currency_name} To ${symbol}`;
      } else {
        text = `Convert ${game.earned_currency_name} To ${symbol}`;
      }

      if ((!fromTap && pendingOut) || (fromTap && pendingIn)) {
        amountError = "You have an existing pending conversion";
      }

      if (amountError) {
        text = amountError;
      }
      if (!web3.connected) {
        text = "Unlock Wallet";
      }
      if (web3.badNetwork) {
        text = "Ensure you are connected to the right network";
      }
      if (wrongWallet) {
        text = "Ensure you are connected to the right wallet";
      }
      if (needsGXP) {
        text = `You must hold 2200 GXP in order to convert`;
      } else if (needsToken) {
        if (game.game_economy_type.required_token_amount > 10) {
          const requiredToken = getTokenFromAddressByChain(
            game.game_economy_type.required_token_address,
            web3.chainId
          );
          text = `You must hold ${Web3Utils.getDisplayVal(
            game.game_economy_type.required_token_amount,
            requiredToken.decimals
          )} ${game.needsTokenName} for this game in order to convert`;
        } else {
          text = `You must hold ${game.game_economy_type.required_token_amount} ${game.needsTokenName} for this game in order to convert`;
        }
      }
      if (fromTap && allowanceError && !skipApproval) {
        return (
          <Grid item xs={12} className="mt--80">
            <LoadingButton
              variant="outlined"
              fullWidth
              size="large"
              disabled={
                (!!web3.connected &&
                  (convertedAmount <= 0 || !valid || wrongWallet || buttonDisabled)) ||
                needsToken ||
                needsGXP
              }
              loadingText="pending"
              loading={buttonDisabled}
              onClick={props.handleSubmit((data) => {
                approve();
              })}
            >
              {`Approve ${symbol}`}
            </LoadingButton>
          </Grid>
        );
      }
      return (
        <Grid item xs={12} className="mt--80">
          <LoadingButton
            variant="outlined"
            fullWidth
            size="large"
            disabled={
              (!!web3.connected &&
                (convertedAmount <= 0 ||
                  Boolean(amountError) ||
                  !valid ||
                  wrongWallet ||
                  buttonDisabled)) ||
              needsToken ||
              needsGXP
            }
            loadingText="pending"
            loading={buttonDisabled}
            onClick={props.handleSubmit((data) => {
              startConversion(data);
            })}
          >
            {text}
          </LoadingButton>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} className="mt--80">
        <Button
          fullWidth
          variant="outlined"
          size="large"
          component={Link}
          to="/onboarding/wallet/add"
        >
          Connect your eth/bsc wallet
        </Button>
      </Grid>
    );
  };

  const getFirstStepContent = () => {
    return (
      <Grid container spacing={3} className="pa--40 conversionCard">
        <Grid item container justifyContent="space-between">
          {renderFrom()}
          <Button
            className="mt--32 mb--32"
            variant="outlined"
            fullWidth
            size="large"
            onClick={() => {
              setFromTap(!fromTap);
            }}
          >
            <Exchange className="mr--8" />
            Switch Direction
          </Button>
        </Grid>
        <Grid item container justifyContent="space-between">
          {renderTo()}
          {/*
          <Text variant="caption" color="textSecondary" className="mb--24">
            Minimum amount:
            {this.state.currencyId === '1'
              ? earnedConversionRate
              : premiumConversionRate}
          </Text>
          */}
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            className="mt--16 pubcut"
          >
            {!fromTap && (
              <>
                <StandardToolTip
                  title={`The value you see in the box is what you'll recieve after the platform & publisher's cut.`}
                  text="Platform/Publisher cut"
                />
                <Text variant="caption" color="textSecondary">
                  -{fee} ${symbol}
                </Text>
              </>
            )}
          </Grid>
          {renderNextButton()}
          <Text>Avoid reducing the gas fee, the transaction will likely fail.</Text>
        </Grid>
      </Grid>
    );
  };

  const getStepContent = () => {
    switch (step) {
      case 1:
        return getFirstStepContent();
      case 2:
        return getSecondStepContent();
      case 3:
        // new conversion system prefers users don't navigate away from the page so we can log it.
        return getThirdStepContent();
      case 4:
        return getFourthStepContent();
      default:
        return "Error";
    }
  };

  if (!game) {
    return "";
  }
  return (
    <Paper className="conversion-card">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item container style={{ padding: "12px" }}>
            <Text variant="h5" color="textSecondary">
              Convert currencies
            </Text>
          </Grid>
          <Divider />
        </Grid>
        {getStepContent()}
      </Grid>
    </Paper>
  );
};

export default reduxForm({ form: "conversionCard" })(ConversionCard);
