import React, { PureComponent } from "react";
import { Button } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "utils/form";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

class ChangePassForm extends PureComponent {
  render() {
    return (
      <div className="modal-570">
        <Field
          component={renderTextField}
          label="Enter Password"
          name="password"
          type="password"
          className="mt--48"
          fullWidth
        />
        <Field
          component={renderTextField}
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          className="mt--48"
          fullWidth
        />
        <Button
          className="mt--48"
          variant="outlined"
          fullWidth
          onClick={this.props.handleSubmit(this.props.onSubmit)}
        >
          Change Password
        </Button>
        <Button onClick={this.props.onClose} className="mt--24" fullWidth>
          Cancel
        </Button>
      </div>
    );
  }
}

export default reduxForm({ form: "changePassword", validate })(ChangePassForm);
