import React, { useState, useEffect } from "react";
import { Button, Grid, DialogActions, Dialog, Typography } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Loader from "components/loaders";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import { getTransactionReceiptMined } from "utils/web3Calls";
import { removeLiquidity } from "utils/web3/exchange";
import {
  FlexRow,
  LiquidityDescription,
  CurrencyText,
  Descriptions,
  Description,
  AutoGrid,
  BigCurrencyText,
} from "components/Exchange/styles";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";

const RemoveModal = ({
  amount,
  pair,
  fromTotal,
  toTotal,
  exchangeRate,
  open,
  percentage,
  closeHandler,
}) => {
  const modal = FormStyled();
  const classes = useStyles();

  const [priceData, setPriceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handler = async () => {
    setLoading(true);
    try {
      const hash = await removeLiquidity(pair, amount, { percentage });
      const receipt = await getTransactionReceiptMined(hash);
      closeHandler("success");
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPriceData({
      [`${pair.liquidityToken.name} LP Tokens Burned`]: (
        <div style={{ display: "Flex" }}>
          <LiquidityDescription>{amount.toFixed()}</LiquidityDescription>
        </div>
      ),
      Price: `1 ${pair.token0.symbol} = ${
        Math.round((1 / exchangeRate) * 100000) / 100000
      } ${pair.token1.symbol}`,
      "": `1 ${pair.token1.symbol} = ${Math.round(exchangeRate * 100000) / 100000} ${
        pair.token0.symbol
      }`,
    });
  }, [pair, exchangeRate]);

  const descriptions = (data) => {
    const res = [];

    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <CurrencyText>{key}</CurrencyText>
          <LiquidityDescription>{data[key]}</LiquidityDescription>
        </Description>
      );
    });

    return res;
  };

  const renderButton = () => {
    return (
      <>
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => handler(pair.balance, percentage)}
          disabled={loading}
        >
          Confirm Withdrawal
          {loading && <Loader />}
        </Button>
        {error && <Description>{error.toString()}</Description>}
      </>
    );
  };
  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>You will receive</DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid item xs={12}>
            <Grid container item justifyContent="space-between">
              <BigCurrencyText>
                {Math.floor(fromTotal * percentage) / 100}
              </BigCurrencyText>
              <FlexRow>
                <BigCurrencyText>{pair.token0.symbol}</BigCurrencyText>
              </FlexRow>
            </Grid>
            <Typography variant="subtitle1">+</Typography>
            <AutoGrid>
              <Grid container item justifyContent="space-between">
                <BigCurrencyText>
                  {Math.floor(toTotal * percentage) / 100}
                </BigCurrencyText>
                <FlexRow>
                  <BigCurrencyText>{pair.token1.symbol}</BigCurrencyText>
                </FlexRow>
              </Grid>

              <LiquidityDescription>
                Output is estimated. If the price changes by more than 0.5% your
                transaction will revert.
              </LiquidityDescription>
            </AutoGrid>
            <Descriptions>{descriptions(priceData)}</Descriptions>
          </Grid>
        </Grid>
      </ConvertModalContainer>

      <DialogActions>{renderButton()}</DialogActions>
    </Dialog>
  );
};

export default RemoveModal;
