import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as SteamSettings from "utils/steam";
import { getCurrentUser, updateUser } from "../../../../actions/user";
import { openAlert } from "../../../../reducers/redux/Alert/actions";
import TextFields from "./TextField/TextFields";
import AvatarPicker from "./AvatarPicker";

const TabProfile = () => {
  const user = useSelector((state) => state.user);
  const location = useSelector(({ router }) => router.location);
  const dispatch = useDispatch();
  const history = useHistory();
  const referralLink = `https://play.gamexchange.app/referral/${user.referral_code}`;

  const [values, setValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.username,
    country_of_origin: user.country_of_origin,
    avatar: user.profile_avatar_url,
  });

  useEffect(() => {
    if (location.search && location.search.includes("steamcommunity.com")) {
      const info = new URLSearchParams(location.search);
      info.set("openid.mode", "check_authentication");
      info.set("id", user._id);
      SteamSettings.verifyUser(info)
        .then((res) => {
          if (res.data.errors) {
            history.push("/usr/settings");
            dispatch(
              openAlert({
                text: "Error: That Steam account is already in use.",
                severity: "error",
                anchor: { vertical: "top", horizontal: "right" },
              })
            );
          } else {
            history.push("/usr/settings");
            dispatch(
              openAlert({
                text: "Steam account connected successfully!",
                severity: "success",
                anchor: { vertical: "top", horizontal: "right" },
              })
            );
            dispatch(getCurrentUser());
          }
        })
        .catch((err) => {
          dispatch(
            openAlert({
              text: "Error: " + err.message,
              severity: "error",
              anchor: { vertical: "top", horizontal: "right" },
            })
          );
        });
    }
  }, [dispatch, history, location.search, user._id]);

  const handler = (name) => (e) => {
    setValues((stateObj) => ({ ...stateObj, [name]: e.target.value }));
  };

  const copyValue = () => {
    navigator.clipboard.writeText(referralLink);

    dispatch(
      openAlert({
        text: "Text copied",
        severity: "success",
        anchor: { vertical: "top", horizontal: "right" },
      })
    );
  };

  const submitChanges = (e) => {
    e.preventDefault();
    dispatch(updateUser(values));
    dispatch(
      openAlert({
        text: "Profile Successfully Updated!",
        severity: "success",
        anchor: { vertical: "top", horizontal: "right" },
      })
    );
  };

  return (
    <form onSubmit={submitChanges}>
      <AvatarPicker />
      <TextFields user={user} values={values} handler={handler} copyValue={copyValue} />
    </form>
  );
};

export default TabProfile;
