import { GAS_PRICE_GWEI } from "blockchain/gasPrices";
import {
  EXCHANGE_SET_SLIPPAGE,
  EXCHANGE_SET_GAS_PRICE,
  EXCHANGE_ADD_PAIR,
} from "./types";

const INITIAL_ALLOWED_SLIPPAGE = 50;

const initialState = {
  gasPrice: GAS_PRICE_GWEI.default,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
};

const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_SET_SLIPPAGE:
      return {
        ...state,
        ...action.data,
      };
    case EXCHANGE_SET_GAS_PRICE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default ExchangeReducer;
