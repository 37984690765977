import React from "react";
import { Button } from "@material-ui/core";
import { FlexRow, FlexColumn } from "components/Exchange/styles";
import { SubHeader, CustomButton } from "components/Exchange/styles";
import { useGasPriceManager, GAS_PRICE_GWEI, GAS_PRICE } from "blockchain/gasPrices";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const GasSettings = () => {
  const [gasPrice, setGasPrice] = useGasPriceManager();

  return (
    <FlexColumn>
      <FlexRow>
        <SubHeader>Default Transaction Speed (GWEI)</SubHeader>
        <StandardToolTip title="Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees" />
      </FlexRow>
      <FlexRow>
        <CustomButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.default);
          }}
          variant="contained"
          color={gasPrice === GAS_PRICE_GWEI.default ? "primary" : "secondary"}
        >
          {`Standard ${GAS_PRICE.default}`}
        </CustomButton>
        <CustomButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.fast);
          }}
          variant="contained"
          color={gasPrice === GAS_PRICE_GWEI.fast ? "primary" : "secondary"}
        >
          {`Fast ${GAS_PRICE.fast}`}
        </CustomButton>
        <CustomButton
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.instant);
          }}
          variant="contained"
          color={gasPrice === GAS_PRICE_GWEI.instant ? "primary" : "secondary"}
        >
          {`Instant ${GAS_PRICE.instant}`}
        </CustomButton>
      </FlexRow>
    </FlexColumn>
  );
};

export default GasSettings;
