import { Grid, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceOf } from "actions";
import { getPLTNFTs } from "actions/NFTs";
import { isCompletedTx } from "blockchain";
import { PARTNER_TOKEN_RUN_ADDRESSES } from "blockchain/tokenInfo";
import { loadedPartialScreen, loadingPartialScreen } from "dux/loading";
import appError from "utils/appError";
import { mintLTNFT } from "utils/web3Calls";
import PLTNFT from "./PLTNFT";
import { FlexGrid } from "../styles";

const Content = React.memo(
  ({ allowanceHandler, setSuccessModal, connectHandler, getHandler }) => {
    const dispatch = useDispatch();
    const web3 = useSelector((state) => state.web3);
    const pltNfts = useSelector((state) => state.nft.pltNfts);
    const paymentContracts = useSelector((state) => state.paymentContracts);

    const pltnftAllowanceHandler = (id) => {
      const pltnft = pltNfts.find(({ id: ltnftId }) => ltnftId === id);

      const { blockchainId, paymentToken } = pltnft;

      allowanceHandler(
        blockchainId,
        paymentToken,
        PARTNER_TOKEN_RUN_ADDRESSES[web3.chainId]
      );
    };

    const pltnftMintHandler = useCallback(
      async (id) => {
        //ensure we have most up to date price
        dispatch(loadingPartialScreen("nftButtons--is-minting-nft"));
        dispatch(getPLTNFTs(web3));
        const pltnft = pltNfts.find(({ id: ltnftId }) => ltnftId === id);
        const { blockchainId } = pltnft.blockchainId;

        // attempt to mint token
        mintLTNFT(
          PARTNER_TOKEN_RUN_ADDRESSES[web3.chainId],
          pltnft.runId,
          pltnft.paymentToken,
          parseFloat(pltnft.currentTokenPrice) + parseFloat(pltnft.priceIncrease)
        )
          .then((txn) => {
            const timer = setInterval(() => {
              isCompletedTx(blockchainId, txn).then(() => {
                clearInterval(timer);
                dispatch(getPLTNFTs(web3)).then(() => {
                  dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
                  setSuccessModal(true);
                  dispatch(getBalanceOf(pltnft.paymentToken, web3.connected));
                });
              });
            }, 1000);
          })
          .catch((err) => {
            appError("The following error occured in the LTNFT mint handler", err);
            dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
          });
      },
      [dispatch, pltNfts, web3, setSuccessModal]
    );

    return (
      pltNfts.length > 0 && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">Limited Time NFT Auctions</Typography>
            </Grid>
            <Grid container item>
              {pltNfts.map((ltnft) => (
                <Grid container item xl={3} lg={4} md={6} xs={12}>
                  <PLTNFT
                    pltnftData={{
                      ...ltnft,
                      paymentContracts,
                      allowanceHandler: () => pltnftAllowanceHandler(ltnft.id),
                      mintHandler: () => pltnftMintHandler(ltnft.id),
                      getHandler: (payment, price) => getHandler(payment, price),
                      connectHandler,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      )
    );
  }
);

export default Content;
