import styled from "styled-components";
import Select from "@material-ui/core/Select";
import colors from "utils/colors";

export const TabsRow = styled.div`
  margin: 54px 0 32px 0;
  display: flex;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const SelectWrap = styled.div`
  @media (min-width: 480px) {
    display: none;
  }
`;

export const SelectItem = styled(Select)`
  width: 100%;
  background-color: #373a43;
  border-radius: 12px;
  height: 56px;
  margin: 48px 0 32px;
  color: #808191;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  .MuiSelect-selectMenu {
    width: 100%;
    padding: 18px 24px;
    border-radius: 12px;
  }

  .MuiSelect-icon {
    color: #ffffff;
    right: 20px;
  }

  .MuiSelect-select:focus {
    background-color: #373a43;
    border-radius: 12px;
  }

  &:before,
  &:after {
    display: none;
  }
`;

export const TabsController = styled.button`
  border: ${({ active }) =>
    active ? `2px solid ${colors.blurple}` : `2px solid ${colors.blurple}`};
  border-radius: 12px;
  background-color: ${({ active }) => (active ? colors.blurple : colors.paper)};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 18px;
  color: #ffffff;
  margin-right: 13px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;
