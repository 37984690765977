import React, { Fragment, PureComponent } from "react";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "components/LoadingSpinner";
import Text from "components/Text";

export default class LoadingButton extends PureComponent {
  render() {
    const { children, loading, loadingText, ...props } = this.props;
    let content;
    if (loading) {
      content = (
        <>
          <LoadingSpinner size="small" />
          <Text className="ml--8" inline>
            {loadingText || ""}
          </Text>
        </>
      );
    } else {
      content = children;
    }
    return <Button {...props}>{content}</Button>;
  }
}
