import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import { Logo, Text, HeaderText, FlexRow } from "components/Leaderboards/styles";
import { styled } from "@material-ui/core/styles";
import mysteryAvatar from "images/profile/mysteryAvatar.png";
import { getTokenImageBySymbol } from "blockchain/tokenInfo";
import unknownToken from "assets/icons/unknownToken.png";
import { TokenPair } from "../styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  root: {
    display: "flex",
    overflowX: "hide",
  },
  table: {
    backgroundColor: "rgb(31 31 31)",
    borderRadius: "20px",
    padding: "0px 20px !important",
    "@media (min-width: 1281px)": {
      width: "100%",
    },
  },
  header: {
    backgroundColor: "rgb(0 0 0)",
    "&:first-child": {
      borderTopLeftRadius: "10px",
      paddingLeft: "40px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      paddingRight: "40px",
    },
  },
  cell: {
    //paddingRight: 4,
    //paddingLeft: 5,
    "&:first-child": {
      paddingLeft: "40px",
    },
    "&:last-child": {
      paddingRight: "40px",
    },
  },
  footer: {
    backgroundColor: "rgb(0 0 0)",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottom: "none",
  },
});

const TopPairs = ({ topPairs }) => {
  const classes = useStyles();
  if (topPairs === undefined) {
    return null;
  }

  const imageOnErrorHandler = (ev) => {
    ev.currentTarget.src = unknownToken;
  };

  return (
    <TableContainer className={classes.root}>
      <Table align="center" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="left">
              <HeaderText>Top Pairs</HeaderText>
            </TableCell>
            <TableCell className={classes.header} align="right">
              <HeaderText>Liquidity</HeaderText>
            </TableCell>
            <TableCell className={classes.header} align="right">
              <HeaderText>Token A</HeaderText>
            </TableCell>
            <TableCell className={classes.header} align="right">
              <HeaderText>Token B</HeaderText>
            </TableCell>
            {/* <TableCell className={classes.header} align="center">
              <HeaderText>Fees (24hr)</HeaderText>
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {topPairs &&
            topPairs.length > 0 &&
            topPairs.map((row, index) => (
              <StyledTableRow key={row._id}>
                {/* <TableCell align="center" className={classes.cell}>
                  <Text>{row.id}</Text>
                </TableCell> */}
                <TableCell align="left" justifyContent="left" className={classes.cell}>
                  <FlexRow>
                    <Text
                      style={{
                        margin: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {index + 1}
                    </Text>
                    <TokenPair>
                      <img
                        src={getTokenImageBySymbol(row.tokenASymbol)}
                        onError={imageOnErrorHandler}
                      />
                      <img
                        src={getTokenImageBySymbol(row.tokenBSymbol)}
                        onError={imageOnErrorHandler}
                      />
                    </TokenPair>
                    <Text
                      style={{
                        margin: "auto",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.name}
                    </Text>
                  </FlexRow>
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  <Text>${row.liquidity.toLocaleString()}</Text>
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  <Text>
                    ${row.tokenA >= 1 ? row.tokenA.toFixed(2) : row.tokenA.toPrecision(2)}
                  </Text>
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  <Text>
                    ${row.tokenB >= 1 ? row.tokenB.toFixed(2) : row.tokenB.toPrecision(2)}
                  </Text>
                </TableCell>
                {/* <TableCell align="center" className={classes.cell}>
                  <Text>{row.fees}</Text>
                </TableCell> */}
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5} className={classes.footer} />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TopPairs;
