import React, { PureComponent } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "utils/colors";
import DiscardChangesModal from "components/DiscardChangesModal";

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: ${colors.defaultBackground};
  opacity: 0.95;
  z-index: 2;
  & > div {
    flex: 1 0 100%;
    height: 74px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 58px;
  }
`;

export default class SaveFooter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showDiscardChangesModal: false,
    };

    this.handleDiscardData = this.handleDiscardData.bind(this);
    this.handleSaveData = this.handleSaveData.bind(this);
  }

  handleSaveData() {
    const {
      onSave = () => {
        alert("onSave function not set");
      },
    } = this.props;

    onSave();
    if (this.state.showDiscardChangesModal) {
      this.setState({
        showDiscardChangesModal: false,
      });
    }
  }

  handleDiscardData() {
    if (!this.state.showDiscardChangesModal) {
      this.setState({ showDiscardChangesModal: true });
    } else {
      this.handleCloseDiscardChangesModal();
      const {
        onDiscard = () => {
          alert("onDiscard function not set");
        },
      } = this.props;
      onDiscard();
    }
  }

  handleCloseDiscardChangesModal() {
    this.setState({ showDiscardChangesModal: false });
  }

  renderDiscardChangesModal() {
    if (!this.state.showDiscardChangesModal) {
      return null;
    }
    return (
      <DiscardChangesModal
        onDiscard={this.handleDiscardData}
        onSave={this.handleSaveData}
      />
    );
  }

  render() {
    const { discardDisabled, saveDisabled } = this.props;

    return (
      <Footer>
        <div className="max-widths">
          <Button
            onClick={this.handleSaveData}
            variant="contained"
            color="primary"
            disabled={saveDisabled}
          >
            Save Changes
          </Button>
          <Button
            onClick={this.handleDiscardData}
            variant="text"
            className="mr--24"
            disabled={discardDisabled}
          >
            Discard Changes
          </Button>
        </div>
        {this.renderDiscardChangesModal()}
      </Footer>
    );
  }
}
