import styled from "styled-components";
import { MyPaper } from "styles";
import Button from "@material-ui/core/Button";
import { button, Flex } from "../../../styles";
import { NftButton } from "../../Marketplace/styles";

export const StakingItem = styled(MyPaper)`
  margin: 0 15px 15px 0;
  box-sizing: border-box;
  padding: 12px;
  padding-bottom: 0px;
  font-family: "Inter", sans-serif;
  line-height: 24px;
  background-color: #242731;

  @media (max-width: 480px) {
    max-width: 100%;
    margin-right: 0;
    width: 100%;
    border-radius: 32px 24px;
  }
`;

export const Image = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: #56678942;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: ${({ game, isTBA }) => (game ? (isTBA ? "default" : "pointer") : "default")};

  & img,
  video {
    border-radius: 20px;
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: -10px;
  }
`;

export const OverlayImage = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${({ game, isTBA }) => (game ? (isTBA ? "default" : "pointer") : "default")};

  & img {
    border-radius: 50px;
    border: 5px solid #6e43e6;
    background-color: #1b0057;
    width: 100px;
    height: 100px;
    object-fit: cover;
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 1;
  }
`;

export const Name = styled(Flex)`
  font-weight: 800;
  font-size: 20px;
  height: 30px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 14px 10px 0;

  & > div {
    margin-left: 10px;
  }
`;

export const Descriptions = styled.ul`
  list-style-type: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const Description = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  @media (max-width: 480px) {
    //margin-bottom: 16px;

    & span {
      &:first-child {
        color: #808191;
      }
    }
  }
`;

export const Actions = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 12px 0 12px 0;
`;

export const ButtonMobile = styled(NftButton)`
  padding: 18px 43px;
  border-radius: 16px;
`;

export const StakingItemContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  min-height: 355px;
`;

export const StakingItemText = styled(Flex)`
  padding: 0 20px;
`;

export const MobileContent = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  border: 1px solid #e4e4e41a;
  width: 100%;
  align-items: center;
  padding: 75px 20px 24px;
  border-radius: 32px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  & img {
    max-width: 100%;
    margin-bottom: 58px;
  }
`;

export const InfoMobile = styled(Flex)`
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  padding: 20px 32px 36px;
`;

export const NameMobile = styled.div`
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px 40px;
`;

export const DetailMobileButtonWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const DetailMobileButton = styled(button)`
  font-weight: 400;
  font-size: 14px;
  background-color: inherit;
  color: #808191;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: #20242d;
  }
`;

export const StakingButton = styled(Button)`
  min-width: 40px !important;
`;
