import update from "immutability-helper";
import {
  FEATURE_VIEW,
  MODAL,
  REFERRAL_LEADERBOARD,
  CONVERSION_LEADERBOARD,
} from "../actions/types";

const initial = {};

export default (state = initial, action) => {
  switch (action.type) {
    case FEATURE_VIEW: {
      return {
        ...state,
        marketFeature: {
          data: action.data,
          game: action.game,
          subtype: action.subtype,
        },
      };
    }
    case MODAL: {
      return {
        ...state,
        modal: action.modal,
      };
    }
    case REFERRAL_LEADERBOARD: {
      return update(state, {
        referralLeaderboard: {
          $apply() {
            const newLeaderboard = [];
            for (let i = 0; i < action.leaderboard.length; i += 1) {
              const leader = action.leaderboard[i];
              newLeaderboard.push({
                id: leader._id,
                score: leader.referral_score,
                rank: leader.referralLeaderboardRank,
                username: leader.username,
                avatar: leader.avatar,
              });
            }

            return newLeaderboard;
          },
        },
      });
    }
    case CONVERSION_LEADERBOARD: {
      return update(state, {
        conversionLeaderboard: {
          $apply() {
            const newLeaderboard = [];
            for (let i = 0; i < action.leaderboard.length; i += 1) {
              const leader = action.leaderboard[i];
              newLeaderboard.push({
                id: leader._id,
                score: leader.transaction_score,
                rank: leader.transactionLeaderboardRank,
                username: leader.username,
                avatar: leader.avatar,
              });
            }

            return newLeaderboard;
          },
        },
      });
    }
    default: {
      return state;
    }
  }
};
