import React from "react";
import { NftItem, NftText, NftWrapper, NftTextBox, NftBadge } from "../styles";

const List = ({ list = [], children }) => {
  return list.map(
    (
      {
        id,
        loading,
        claimed,
        achieved,
        achievementName,
        image,
        achievementDisplayName,
        description,
      },
      index
    ) => (
      <NftItem key={id}>
        <NftWrapper>
          {image.split(".").pop() === "mp4" ? (
            <video autoPlay loop>
              <source src={image} type="video/mp4" />
            </video>
          ) : (
            <img src={image} alt="NFT" />
          )}
        </NftWrapper>
        <NftTextBox>
          <NftBadge>NFT</NftBadge>
          <NftText variant="subtitle1">{achievementDisplayName}</NftText>
          <NftText variant="subtitle2">{description}</NftText>
        </NftTextBox>
        {typeof children === "function"
          ? children(id, loading, claimed, achieved, achievementName, index)
          : children}
      </NftItem>
    )
  );
};

export default List;
