import React from "react";
import Logo from "../Logo/Logo";
import Content from "../Exchange/Add/Content";

const Exchange = React.memo(() => {
  return (
    <div>
      <Logo />
      <Content />
    </div>
  );
});

export default Exchange;
