import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { HeaderText, PaperContainer, PodiumGrid } from "./styles";
import { ButtonInfo } from "components/ContentElements/info/styles";
import TrophyIcon from "@material-ui/icons/EmojiEvents";
import Podium from "./Podium";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const useStyles = makeStyles({
  pedestalContainer: {
    margin: "0 auto 20px",
    "@media (min-width: 1281px)": {
      maxWidth: "max-content",
    },
  },
  trophy: {
    marginRight: "10px",
    width: "32px",
    height: "32px",
  },
});

const tooltipText = () => {
  return (
    <>
      <Typography style={{ fontSize: "12px" }}>
        Earn 25 points whenever a user signs up to the platform using your referral link.
      </Typography>
      <Typography style={{ fontSize: "12px" }}>
        Earn 250 points every time one of your referrals does a conversion from platform
        to blockchain.
      </Typography>
    </>
  );
};

const TopPerformers = ({ leaderboard }) => {
  const classes = useStyles();
  if (leaderboard === undefined) {
    return null;
  }

  const topThree = leaderboard.slice(0, 3);
  if (topThree.length < 3) {
    for (let i = topThree.length; i < 3; i += 1) {
      topThree.push({ id: "", score: "-", rank: i + 1, username: "???" });
    }
  }
  return (
    <PaperContainer className={classes.pedestalContainer}>
      <Grid
        container
        justifyContent="flex-start"
        alignContent="center"
        style={{ marginBottom: "10px" }}
      >
        <TrophyIcon className={classes.trophy} />
        <HeaderText>Top Performers</HeaderText>
        <StandardToolTip title={tooltipText()} />
      </Grid>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid container justifyContent="space-between">
          {topThree.map((leader, index) => (
            <PodiumGrid
              container
              lg={4}
              md={index === 0 ? 12 : 5}
              xs={12}
              justifyContent="center"
              rank={leader.rank}
              index={index}
            >
              <Podium
                ranking={leader.rank}
                username={leader.username}
                avatar={leader.avatar}
              />
            </PodiumGrid>
          ))}
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default TopPerformers;
