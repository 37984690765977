import { IconButton } from "@material-ui/core";
import React from "react";
import { SettingsAvatarWrapper, SettingsImg, SettingsSubTitle } from "./style";

const MainAvatar = ({ avatar, handleClick }) => (
  <SettingsAvatarWrapper>
    <SettingsSubTitle>Your Avatar</SettingsSubTitle>
    <IconButton onClick={handleClick}>
      <SettingsImg src={avatar} />
    </IconButton>
  </SettingsAvatarWrapper>
);

export default MainAvatar;
