import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNFTsForGame } from "utils/nftAPI";
import { Grid } from "@material-ui/core";
import errorHandler from "utils/errorHandler";
import * as claimAPI from "utils/apiRequest";
import { fetchChallengeGameVault, steamClaimValue } from "actions/index";
import { useHistory } from "react-router-dom";
import appError from "utils/appError";
import breakpoints from "utils/breakpoints";
import useWindowSize from "hooks/useWindowSize";
import { steamGetUserAchievements } from "actions/steam";
import HeaderActions from "./content/HeaderActions";
import ListAchievements from "./listAvailable";
import ContentTabs from "./content/ContentTabs";
import { ContentWrapper, ContentHeaderName } from "../../Marketplace/styles";
import { AchievementContentHeaderText, Category } from "./styles";

const Content = ({ current }) => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const [availableNfts, setAvailableNfts] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { width } = useWindowSize();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.Signup.auth);
  const challenges = useSelector((state) => state.challenges);
  const vaults = useSelector((state) => state.vaults);

  useEffect(() => {
    if (user.gid_steam && current.steam_game_id) {
      dispatch(steamGetUserAchievements(current.steam_game_id));
    }
    /*
>>>>>>> develop
    getNFTsForGame("steam" + current.steam_game_id, auth).then((nfts) => {
      if (nfts.length > 0) {
        const updatedNfts = nfts;
        nfts.forEach((element, index) => {
          const achievement = current.achievements.find(
            (child) => child.achievementName === element.achievement_name
          );
          updatedNfts[index].achieved = achievement.achieved;
        });

        setAvailableNfts(updatedNfts);
      }
    });
    */
  }, [dispatch, auth, user.gid_steam, current.steam_game_id]);

  const MintHandler = (achievementName, gameId, index) => {
    // deep copy object to force component to update, disabling button
    let updatedAvailableNfts = [...availableNfts];
    updatedAvailableNfts[index].loading = true;
    setAvailableNfts(updatedAvailableNfts);

    claimAPI
      .makeNFTClaim(user.id, achievementName, gameId)
      .then((result) => {
        updatedAvailableNfts = [...availableNfts];
        updatedAvailableNfts[index].loading = false;
        setAvailableNfts(updatedAvailableNfts);
      })
      .catch((error) => {
        console.error(error);
        updatedAvailableNfts = [...availableNfts];
        updatedAvailableNfts[index].loading = false;
        setAvailableNfts(updatedAvailableNfts);
      });
  };

  useEffect(() => {
    if (auth) {
      dispatch(fetchChallengeGameVault());
    }
  }, [dispatch, auth]);

  const claimHandler = () => {
    const challengeVault = vaults.challengeVault?.id;
    if (challengeVault) {
      setLoading(true);
      dispatch(steamClaimValue(current.steam_game_id))
        .then(() => history.push(`/vaults/${challengeVault}`))
        .catch((err) => {
          appError("The following error occured claiming steam value", err);
          setLoading(false);
          setError(errorHandler(err));
        });
    }
  };

  const steamHandler = () => {
    history.push("/usr/settings");
  };

  const claimValue = challenges.claimRemaining[current.steam_game_id];

  return (
    <ContentWrapper style={{ marginTop: "0px" }}>
      <div>
        {width <= breakpoints.sm && (
          <ContentHeaderName>{current.title}</ContentHeaderName>
        )}
        {availableNfts.length > 0 && (
          <Grid>
            <Category>{current.category ?? "NFT’s Available"}</Category>
            <ContentTabs
              availableNfts={availableNfts}
              setActiveTab={setActiveTab}
              current={current}
              MintHandler={MintHandler}
              activeTab={activeTab}
            />
          </Grid>
        )}
        <AchievementContentHeaderText>
          <HeaderActions
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            claimHandler={claimHandler}
            steamHandler={steamHandler}
            claimValue={claimValue}
            loading={loading}
            error={error}
          />
          <Grid container justifyContent="space-between">
            {width > breakpoints.sm && (
              <Grid item>
                <div>Collected in-game Achievements</div>
              </Grid>
            )}
            <Grid item>
              <div>{`${current.achieved ? current.achieved : 0}/${
                current.achievements.length
              } Achievements ${"\uD83D\uDC51"}`}</div>
            </Grid>
          </Grid>
        </AchievementContentHeaderText>
      </div>
      <ListAchievements list={current.achievements} />
    </ContentWrapper>
  );
};

export default Content;
