import React, { useEffect, useState } from "react";
import { Button, Grid, DialogActions, Dialog } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Loader from "components/loaders";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import {
  FlexRow,
  LiquidityDescription,
  AutoGrid,
  BigCurrencyText,
} from "components/Exchange/styles";
import { approveFor, getTransactionReceiptMined } from "utils/web3Calls";
import { Routers } from "blockchain/tokenInfo";
import { getAllowanceOf } from "actions";
import { useDispatch } from "react-redux";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";

const ApprovalModalContainer = ({ to, token, open, closeHandler, successHandler }) => {
  const dispatch = useDispatch();
  const modal = FormStyled();
  const classes = useStyles();
  const [success, onSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handler = async () => {
    setLoading(true);
    try {
      const hash = await approveFor(
        token.address,
        `1000000000000${"0".repeat(token.decimals)}`,
        to || Routers[token.chainId]
      );
      const receipt = await getTransactionReceiptMined(hash);
      onSuccess(receipt);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(getAllowanceOf(token.address, to || Routers[token.chainId]));
        setLoading(false);
      }, 5000);
      successHandler();
    }
  }, [success]);

  const renderButton = () => {
    return (
      <Button
        className={classes.button}
        size="large"
        color="primary"
        variant="contained"
        onClick={() => handler()}
        disabled={loading || success}
      >
        {success ? "Success" : `Approve ${token?.symbol}`}
        {loading && <Loader />}
      </Button>
    );
  };

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>Approve</DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid item xs={12}>
            <AutoGrid>
              <Grid container>
                <BigCurrencyText>{token?.symbol}</BigCurrencyText>
                <FlexRow>
                  {/*<BigCurrencyImage src={GXPBottle} alt="currency" />*/}
                </FlexRow>
              </Grid>
              <LiquidityDescription>
                Approval is needed in order for the exchange to trade your tokens.
              </LiquidityDescription>
            </AutoGrid>
          </Grid>
        </Grid>
      </ConvertModalContainer>

      <DialogActions>{renderButton()}</DialogActions>
    </Dialog>
  );
};

export default ApprovalModalContainer;
