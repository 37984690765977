import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  withStyles,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Text from "components/Text";
import * as ImgServer from "utils/imgServer";
import Star from "assets/icons/star.svg";
import Steam from "assets/icons/format/steam.svg";
import Check from "assets/icons/checkmark.svg";

const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {},
  content: {
    "&.light": {
      background: theme.palette.dsMin1,
    },
    background: theme.palette.dsMin3,
    position: "relative",
    minHeight: "192px",
    "&.short": {
      minHeight: "146px",
    },
  },
});

function SimpleGameCard(props) {
  const {
    classes,
    game,
    vault,
    className,
    publisher,
    banner,
    challenge,
    steamChallenge,
    shortenText,
  } = props;

  function renderPlatformIcons() {
    if (!game.platform) {
      return "";
    }
    return game.platform.map((platformitem) => (
      <img
        key={platformitem}
        className="mr--8"
        alt={platformitem}
        src={`https://img.tapplatform.io/stat/icons/format/${platformitem}.svg`}
      />
    ));
  }

  function renderStars() {
    return (
      <div>
        <Text variant="h6" color={game.achieved ? "textPrimary" : "textSecondary"}>
          {game.achieved ? game.achieved : 0}/{game.achievements.length}{" "}
          <Star className={game.achieved ? "star gold-star" : "star grey-star"} />
        </Text>
      </div>
    );
  }

  let link = props.vault ? `/vaults/${vault.id}` : `/game/${game.id}`;
  if (challenge) {
    link = `/challenge/game/${game.id}`;
  } else if (steamChallenge) {
    link = `/challenge/steam/${game.steam_appid}`;
  } else if (publisher) {
    link = `/pub/org/${game.id}/gamepage`;
  }

  if (shortenText && game.short_description.length > shortenText) {
    game.short_description = game.short_description.slice(0, shortenText).split(" ");
    game.short_description.pop();
    game.short_description = `${game.short_description.join(" ")}...`;
  }

  return (
    <div className={className ? `${className} border-default` : "border-default"}>
      <Card className={classes.card || ""}>
        <CardMedia
          component={Link}
          to={link}
          className={classes.media ? `${classes.media} pos--rel` : "pos--rel"}
          image={ImgServer.gameImage(game, "feature", game.image_url)}
          title={game.title}
        >
          {banner && game.tap_enabled && (
            <div className="tap-banner bg--primaryBase">
              <Text className="mb--8 ml--24">Tap Coin Support</Text>
            </div>
          )}
        </CardMedia>
        <CardContent className={classes.content}>
          <Text
            variant="h6"
            className={classes.text ? `${classes.textTitle} mb--8` : "mb--8"}
            component={Link}
            to={link}
          >
            {game.title}
          </Text>
          {vault ? (
            <div>
              <Grid container>
                {game.earned_currency_name && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Text variant="p1" className=" bold mb--8">
                          {game.earned_currency_name}
                        </Text>
                      </Grid>
                      <Grid item>
                        <Text variant="p1" className=" bold mb--8">
                          {vault.earned_currency}
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {game.premium_currency_name && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Text variant="p1">{game.premium_currency_name}</Text>
                      </Grid>
                      <Grid item>
                        <Text variant="p1">{vault.premium_currency}</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          ) : (
            <>
              <Text
                varaint="p1"
                className={classes.textDescription}
                color="textSecondary"
                component={Link}
                to={link}
              >
                {game.short_description}
              </Text>
              {steamChallenge && (
                <Button
                  align="left"
                  className="steam-connect"
                  disabled={game.achieved > 1}
                  variant="outlined"
                  component={Link}
                  to={`https://store.steampowered.com/app/${game.steam_appid}`}
                  target="_blank"
                >
                  {game.achieved > 1 ? (
                    <Check className="check-icon" />
                  ) : (
                    <Steam className="steam-icon" />
                  )}
                  {game.achieved > 1 ? "Connected" : "Available On Steam"}
                </Button>
              )}
              <div variant="p1" align="right" className="platform-icons">
                {steamChallenge ? renderStars() : renderPlatformIcons()}
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

SimpleGameCard.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line
  game: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    banner: PropTypes.bool,
  }).isRequired,
  vault: PropTypes.object, // eslint-disable-line
};

SimpleGameCard.defaultProps = {
  vault: null,
};

export default withStyles(styles)(SimpleGameCard);
