import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";
import * as Info from "../../assets/info";

class TOS extends Component {
  render() {
    return (
      <Grid container className="TOS">
        <img
          className="tap-logo"
          src="https://img.tapplatform.io/stat/icons/brand/Taplogo.svg"
          alt="Tap Logo"
        />
        <Paper className="mt--80">{Info.termsOfService}</Paper>
      </Grid>
    );
  }
}

export default TOS;
