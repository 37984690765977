import React from "react";
import { useSelector } from "react-redux";
import { GetLater } from "../styles";
import Info from "./Info";
import ButtonPrev from "../../../../buttons/ButtonPrev";
import ButtonNext from "../../../../buttons/ButtonNext";

const Actions = ({ setActiveStep, getLater, link, text, current, info }) => {
  const step = useSelector(({ Signup }) => Signup.activeWindow);

  const handlerChange = (value) => {
    current === step && setActiveStep(value);
  };

  return (
    <>
      <ButtonPrev handler={() => handlerChange("-")} />

      {getLater && (
        <GetLater onClick={() => handlerChange("+")} disabled={!(current === step)}>
          Get Later
        </GetLater>
      )}

      {!!info && <Info text={info} arrow="bottom" />}

      <ButtonNext
        handler={() => {
          handlerChange("+");
          {
            link && window.open(link, "_blank");
          }
        }}
        disabled={!(current === step)}
        text={text ?? "Begin"}
      />
    </>
  );
};

export default Actions;
