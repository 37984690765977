import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { DialogText } from "./styles";
import useScroll from "../../../hooks/useScroll";

const PrivacyPolicy = ({ handler }) => {
  const ref = useScroll(handler);

  return (
    <DialogText ref={ref}>
      <Grid container>
        <Grid item xs={12} className="register__TOS-title">
          <Typography color="inherit" variant="display1">
            Privacy Policy
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            Truly Simplistic Innovations Inc. (&quot;we&quot; or &quot;Game X
            Change&quot;) respects the privacy of its online visitors and customers of its
            products and services and complies with applicable laws for the protection of
            your privacy, including, without limitation, the British Columbia Privacy Act
            (&quot;BCPA&quot;), the Personal Information Protection Act (&quot;PIPA&quot;)
            and, for our users based in the European Union, the General Data Protection
            Regulation (&quot;GDPR&quot;).
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            1. Definitions
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              Wherever we talk about Personal Data below, we mean any information that can
              either itself identify you as an individual (&quot;Personally Identifying
              Information&quot;) or that can be connected to you indirectly by linking it
              to Personally Identifying Information. Game X Change also processes
              anonymous data, aggregated or not, to analyze and produce statistics related
              to the habits, usage patterns, and demographics of customers as a group or
              as individuals. Such anonymous data does not allow the identification of the
              customers to which it relates. Game X Change may share anonymous data,
              aggregated or not, with third parties.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            2. Consent to Collection and Why The Tap Projec Collects and Processes Data
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              2.1 Consent
              <br />
              Game X Change may obtain your consent to our collection, use and disclosure
              of your Personal Data, either expressly for a stated purpose, or impliedly
              when the purposes are indicated by the relevant circumstances.We have
              outlined the various circumstances in which we collect, use and disclose
              your Personal Data in section 6 of this Privacy Policy below.
              <br />
              By providing us with Personal Data, you consent to the collection, use and
              disclosure of your Personal Data in accordance with this Privacy Policy.
              <br />
              As required by law, we will seek your consent before using Personal Data for
              any purpose beyond the scope of your original consent. You may withdraw your
              consent at any time, subject to legal and contractual restrictions and
              reasonable notice. Please note, however, that withdrawing consent may affect
              our ability to provide services to you.
            </Typography>
          </Grid>

          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              2.2 Why We Collect and Process Data
              <br />
              Game X Change collects and processes Personal Data for the following
              reasons:
              <br />
              <br />
              a) where it is necessary for the performance of our agreement with you to
              provide a full-featured gaming service and deliver associated Content and
              Services;
              <br />
              b) where it is necessary for compliance with legal obligations that we are
              subject to (e.g. our obligations to keep certain information under tax
              laws);
              <br />
              c) where it is necessary for the purposes of the legitimate and legal
              interests of Game X Change or a third party (e.g. the interests of our other
              customers), except where such interests are overridden by your prevailing
              legitimate interests and rights; or
              <br />
              d) where you have given consent to it.
              <br />
              <br />
              These reasons for collecting and processing Personal Data determine and
              limit what Personal Data we collect and how we use it (section 3. below),
              how long we store it (section 4. below), who has access to it (section 5.
              below) and what rights and other control mechanisms are available to you as
              a user (section 6. below).
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            3. What Data We Collect and Process
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              3.1 Basic Account Data
              <br />
              When setting up an Account, Game X Change will collect your email address,
              date of birth and country of residence. You are also required to choose a
              user name and a password. The provision of this information is necessary to
              register a Game X Change User account. During setup of your account, the
              account is automatically assigned a number (the &quot;User ID&quot;) that is
              later used to reference your user account without directly exposing
              Personally Identifying Information about you.
              <br />
              We do not require you to provide or use your real name for the setup of a
              Game X Change User Account.
              <br />
              <br />
              3.2 Transaction and Payment Data
              <br />
              In order to make an Ethereum transactions on Game X Change (e.g. to purchase
              Subscriptions for Content and Services or to fund your Game X Change
              Wallet), you may need to provide your Ethereum wallet address to Game X
              Change to enable the transaction. Game X Change will process and transmit to
              the payment and receive the information that is publicly accessible via
              etherscan (ie : transaction amount, date, to/from information, hash etc).
              <br />
              <br />
              3.3 Other Data You Explicitly Submit
              <br />
              We will collect and process Personal Data whenever you explicitly provide it
              to us or send it as part of communication with others on Game X Change, e.g.
              in Game X Change Community Forums, chats, or when you provide feedback or
              other user generated content. This data includes: Information that you post,
              comment or follow in any of our Content and Services;
              <br />
              Information sent through chat;
              <br />
              Information you provide when you request information or support from us or
              purchase Content and Services from us, including information necessary to
              process your orders with the relevant payment merchant or, in the case of
              physical goods, shipping providers; Information you provide to us when
              participating in competitions, contests and tournaments or responding to
              surveys, e.g. your contact details.
              <br />
              <br />
              3.4 Your Use of Game X Change Client and Websites
              <br />
              We collect a variety of information through your general interaction with
              the websites, Content and Services offered by Game X Change. Personal Data
              we collect may include, but is not limited to, browser and device
              information, data collected through automated electronic interactions and
              application usage data. Likewise, we will track your interactions across our
              websites and applications to verify that you are not a bot and to optimize
              our services.
              <br />
              <br />
              3.5 Your Use of Games and other Subscriptions
              <br />
              In order to provide you with services, we need to collect, store and use
              various information about your activity in our Content and Services.
              &quot;Content-Related Information&quot; includes your User ID, as well as
              what is usually referred to as &quot;game statistics&quot; and analytics. By
              game statistics we mean information about your games&#145; preferences,
              progress in the games, playtime, as well as information about the device you
              are using, including what operating system you are using, device settings,
              unique device identifiers, and crash data.
              <br />
              <br />
              3.6 Tracking Data and Cookies
              <br />
              We use &quot;Cookies&quot;, which are text files placed on your computer, to
              help us analyze how users use our services, and similar technologies (e.g.
              web beacons, pixels, ad tags and device identifiers) to recognize you and/or
              your device(s) on, off and across different devices and our services, as
              well as to improve the services we are offering, to improve marketing,
              analytics or website functionality. The use of Cookies is standard on the
              internet. Although most web browsers automatically accept cookies, the
              decision of whether to accept or not is yours. You may adjust your browser
              settings to prevent the reception of cookies, or to provide notification
              whenever a cookie is sent to you. You may refuse the use of cookies by
              selecting the appropriate settings on your browser. However, please note
              that if you do this, you may not be able to access the full functionality of
              our websites. When you visit any of our services, our servers log your
              global IP address, which is a number that is automatically assigned to the
              network your computer is part of.
              <br />
              <br />
              3.7 Google Analytics
              <br />
              This website uses Google Analytics, a web analytics service provided by
              Google, Inc. (&quot;Google&quot;). Google Analytics uses Cookies to help the
              website operators analyze how visitors use the site. The information
              generated by the cookie about the visitors&#145; use of the website will
              generally be transmitted to and stored by Google on servers in the United
              States. On Game X Change website, IP anonymization has been activated. The
              IP addresses of users visiting Game X Change will be shortened. Only in
              exceptional cases will a complete IP address be transferred to a Google
              server in the United States and converted to a shortened one there. On
              behalf of Game X Change, Google will use this information for the purpose of
              evaluating the website for its users, in order to compile reports on website
              activity, and to provide other services relating to website activity and
              internet usage for website operators. Google will not associate the IP
              address transferred in the context of Google Analytics with any other data
              held by Google. You may refuse the use of cookies by selecting the
              appropriate settings on your browser. However, please note that in this case
              you may not be able to use the full functionality of this website.
              <br />
              <br />
              3.8 Content Recommendations
              <br />
              We may process information collected under this section 3 so that content,
              products and services shown on the Game X Change store pages and also in
              update messages displayed when launching Game X Change Client can be
              tailored to meet your needs and populated with relevant recommendations and
              offers. This is done to improve your customer experience. You can prevent
              the processing of your data in this way by turning off the automatic loading
              of Game X Change store page and also Game X Change notifications in the
              &quot;Interface&quot; section of Game X Change Client settings. Subject to
              your separate consent or where explicitly permitted under applicable laws on
              email marketing, Game X Change may send you marketing messages about
              products and services offered by Game X Change - &quot;Truly Simplistic
              Innovations&quot; to your email address. In such a case we may also use your
              collected information to customise such marketing messages as well as
              collect information on whether you opened such messages and which links in
              their text you followed. You can opt out or withdraw your consent to receive
              marketing emails at any time by either withdrawing the consent on the same
              page where you previously provided it or clicking the
              &quot;unsubscribe&quot; link provided in every marketing email.
              <br />
              <br />
              3.9 Information Required to Detect Violations
              <br />
              We collect certain data that is required for the detection, investigation
              and prevention of fraud, cheating and other violations of the SSA and
              applicable laws (&quot;Violations&quot;). This data is used only for the
              purposes of detection, investigation, prevention and, where applicable,
              responding to such Violations and stored only for the minimum amount of time
              needed for this purpose. If the data indicates that a Violation has
              occurred, we will further store the data for the establishment, exercise
              and/or defense of legal claims during the applicable statute of limitations
              or until a legal case related to it has been resolved. Please note that the
              specific data stored for this purpose may not be disclosed to you if the
              disclosure will compromise the mechanism through which we detect,
              investigate and prevent such Violations.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            4. How Long We Store Data
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              We will only store your information as long as necessary to fulfil the
              purposes for which the information is collected and processed or — where the
              applicable law provides for a longer storage and retention period — for the
              storage and retention period required by law. After that your Personal Data
              will be deleted, blocked or anonymized, as provided by applicable law.
              <Grid item xs={12} className="mt--16">
                <Typography color="title">In particular:</Typography>
              </Grid>
              If you terminate your Game X Change User Account, your Personal Data will be
              marked for deletion except to the degree legal requirements or other
              prevailing legitimate purposes dictate a longer storage term.
              <br />
              In certain cases Personal Data cannot be completely deleted in order to
              ensure the consistency of the gameplay experience or Game X Change Community
              Market. For instance, matches you have played that affect other
              players&#145; matchmaking data and scores will not be deleted; rather, your
              connection to these matches will be permanently anonymized.
              <br />
              Please note that Game X Change - &quot;Truly Simplistic Innovations&quot; is
              required to retain certain transactional data under statutory commercial and
              tax law for a period of up to ten (10) years.
              <br />
              If you withdraw your consent to the processing of your Personal Data or of
              the Personal Data of your child is based, we will delete your Personal Data
              or the Personal Data of your child without undue delay to the extent that
              the collection and processing of the Personal Data was based on the
              withdrawn consent.
              <br />
              If you exercise a right to object to the processing of your Personal Data,
              we will review your objection and delete your Personal Data that we
              processed for the purpose to which you objected without undue delay, unless
              another legal basis for processing and retaining this data exists or unless
              applicable law requires us to retain the data.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            5. Who Has Access to Data
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              5.1 Game X Change - &quot;Truly Simplistic Innovations&quot; and its
              subsidiaries may share your Personal Data with each other and use it to the
              degree necessary to achieve the purposes listed in section 2.2 above. In the
              event of a reorganization, sale or merger we may transfer Personal Data to
              the relevant third party subject to applicable laws.
              <br />
              <br />
              5.2 We may also share your Personal Data with our third party providers that
              provide customer support services in connection with goods, Content and
              Services distributed via Game X Change. Your Personal Data will be used in
              accordance with this Privacy Policy and only as far as this is necessary for
              performing customer support services.
              <br />
              <br />
              5.3 In accordance with internet standards, we may also share certain
              information (including your IP address and the identification of Game X
              Change content you wish to access) with our third party network providers
              that provide content delivery network services and game server services in
              connection with Game X Change . Our content delivery network providers
              enable the delivery of digital content you have requested, e.g. when using
              Game X Change , by using a system of distributed servers that deliver the
              content to you, based on your geographic location.
              <br />
              <br />
              5.4 We make certain data related to your Game X Change User Account
              available to other players and our partners through Game X Change API’s.
              This information can be accessed by anyone by querying your User ID or
              username. At a minimum, the public persona name you have chosen to represent
              you on Game X Change and your Avatar picture are accessible this way, as
              well as whether you have received a ban for cheating in a multiplayer game.
              The accessibility of any additional info about you can be controlled through
              your Game X Change Community user profile page. Data publicly available on
              your profile page can be accessed automatically through Game X Change API.
              In addition to the publicly available information, game developers and
              publishers have access to certain information from Game X Change API
              directly relating to the users of the games they operate. This information
              includes as a minimum your ownership of the game in question. Depending on
              which Game X Change services are implemented in the game it may also include
              leaderboard information, your progress in the game, achievements you have
              completed, your multiplayer game matchmaking information, in-game items and
              other information needed to operate the game and provide support for it.
              While we do not knowingly share Personally Identifying Information about you
              through Game X Change API such as your real name or your email address, any
              information you share about yourself on your public Game X Change Profile
              can be accessed through Game X Change API, including information that may
              make you identifiable.
              <br />
              <br />
              5.5 Game X Change community includes message boards, forums and/or chat
              areas, where users can exchange ideas and communicate with each other. When
              posting a message to a board, forum or chat area, please be aware that the
              information is being made publicly available online; therefore, you are
              doing so at your own risk. If your Personal Data is posted on one of our
              community forums against your will, please use the reporting function and
              Game X Change help site to request its removal.
              <br />
              <br />
              5.6 Game X Change - &quot;Truly Simplistic Innovations&quot; may allow you
              to link your Game X Change User Account to an account offered by a third
              party. If you consent to link the accounts, Game X Change - &quot;Truly
              Simplistic Innovations&quot; may collect and combine information you allowed
              Game X Change - &quot;Truly Simplistic Innovations&quot; to receive from a
              third party with information of your Game X Change User Account to the
              degree allowed by your consent at the time. If the linking of the accounts
              const module = require(&#145;module&#145;);uires the transmission of
              information about your person from Game X Change - &quot;Truly Simplistic
              Innovations&quot; to a third party, you will be informed about it before the
              linking takes place and you will be given the opportunity to consent to the
              linking and the transmission of your information. The third party&#145;s use
              of your information will be subject to the third party&#145;s privacy
              policy, which we encourage you to review.
              <br />
              <br />
              5.7 Game X Change - &quot;Truly Simplistic Innovations&quot; may release
              Personal Data to comply with court orders or laws and regulations that
              require us to disclose such information.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            6. Your Rights and Control Mechanisms
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              The data protection laws of British Columbia and other territories grant
              their citizens certain rights in relation to their Personal Data. While
              other jurisdictions may provide fewer rights to their citizens, we make the
              tools designed to exercise such rights available to our customers worldwide.
              To allow you to exercise your data protection rights in a simple way we are
              providing a dedicated section on Game X Change Settings. This gives you
              access to your Personal Data, allows you to rectify and delete it where
              necessary and to object to its use where you feel necessary. To access it,
              log into Game X Change at and choose the menu items Settings.
              <br />
              <br />
              As a resident of British Columbia or the European Union, you have the
              following rights in relation to your Personal Data:
              <br />
              <br />
              6.1 Right of Access.
              <br />
              You have the right to access the Personal Data that we hold about you,
              including (i) information regarding whether your Personal Data is retained,
              (ii) access to and/or (iii) duplicates of the Personal Data retained. You
              can use the right to access your Personal Data through Game X Change. If the
              request affects the rights and freedoms of others or is manifestly unfounded
              or excessive, we reserve the right to charge a reasonable fee (taking into
              account the administrative costs of providing the information or
              communication or taking the action requested) or refuse to act on the
              request.
              <br />
              <br />
              6.2 Right to Rectification.
              <br />
              If we process your Personal Data, we shall endeavor to ensure by
              implementing suitable measures that your Personal Data is accurate and
              up-to-date for the purposes for which it was collected. If your Personal
              Data is inaccurate or incomplete, you can change the information you
              provided via Game X Change.
              <br />
              <br />
              6.3. Right to Erasure.
              <br />
              You have the right to obtain deletion of Personal Data concerning you if the
              reason why we collect it (see section 2. above) does not exist anymore or if
              there is another legal ground for its deletion. For individual items of
              Personal Data please edit them through Game X Change or request the deletion
              via Game X Change support page. You can also request the deletion of your
              Game X Change user account via Game X Change support page.
              <br />
              <br />
              As a result of deleting your Game X Change User Account, you will lose
              access to Game X Change services, including Game X Change User Account,
              Subscriptions and game-related information linked to Game X Change User
              Account and the possibility to access other services you are using Game X
              Change User Account for.
              <br />
              <br />
              We allow you to restore your Game X Change User Account during a grace
              period of 30 (thirty) days from the time you request deletion of your Game X
              Change User Account. This functionality prevents you from losing your
              account by mistake, by losing your account credentials or due to hacking.
              During the suspension period, we will be able to finalize financial and
              other activities that you may have initiated before sending Game X Change
              User Account deletion request. After the grace period, Personal Data
              associated with your account will be deleted subject to section 4. above.
              <br />
              <br />
              In some cases, deletion of your Game X Change User Account, and therefore
              Personal Data deletion, is complicated. Namely, if your account has a
              business relationship with Game X Change, such as due to your work for a
              game developer, you will only be able to delete your Game X Change User
              Account after you have transferred this role to another user or have
              dissolved the business relationship. In some cases, considering the
              complexity and number of the requests, the period for Personal Data erasure
              may be extended, but for no longer than two further months.
              <br />
              <br />
              6.4 Right to Object.
              <br />
              When our processing of your Personal Data is not based on legitimate
              interests according to the BCPA, PIPA, or GDPR you have the right to object
              to this processing. If you object we will no longer process your Personal
              Data unless there are compelling and prevailing legitimate grounds for the
              processing as described in the BCPA, PIPA, or GDPR; in particular if the
              data is necessary for the establishment, exercise or defense of legal
              claims. You also have the right to lodge a complaint at a supervisory
              authority.
              <br />
              6.5 Right to Data Portability
              <br />
              In certain circumstances, you can ask that we transmit the Personal Data we
              hold about you to another data controller.
              <br />
              6.6 Right to withdraw consent
              <br />
              You have the right to withdraw at any time your consent to our processing
              your Personal Data. This includes opting out of marketing messages you
              receive from us.
              <br />
              Should you wish to exercise any of the rights listed above, we may request
              you to provide us with additional documents to verify your identity.
              <br />
              You also have the right to lodge a complaint at with a locala supervisory
              authority. if you believe that we have not complied with applicable data
              protection laws.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            7. Transfer of Personal Data
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              Game X Change is global. Your Personal Data may be transferred outside of
              the country from which it was originally collected and may be shared within
              Game X Change group, and also with third parties located in other
              jurisdictions, including those that do not have similar data protection
              laws. All international transfers of Personal Data will be made in
              accordance with applicable data protection laws.
              <br />
              When we transfer Personal Data internationally, we put in place safeguards
              in accordance with applicable law – including Articles 44 to 50 pf the EU
              General Data Protection Regulation. If you would like to know more about our
              data transfer practices and what measures we take to safeguard your Personal
              Data, please contact our privacy team at: contact@tapproject.net or
              team@tapproject.net.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            8. Security of your Personal Data
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              We have policies and procedures in place to prevent the loss, unauthorized
              access, modification or disclosure of your Personal Data.
              <br />
              No method of transmitting or storing information is completely secure. While
              we take appropriate measures to protect your Personal Data, there is always
              a risk that it may be lost, intercepted or misused. Game X Change makes no
              warranty with respect to the integrity of any electric communications
              between us and you.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            9. Disclosure without consent
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              PIPA allows Game X Change - &quot;Truly Simplistic Innovations&quot; to
              disclose information without consent in certain situations;
              <br />
              <br />
              When a treaty requires or allows for disclosure without consent and the
              treaty is made under an Act or Regulation of British Columbia or Canada
              (section 18(1) (h)). • When the disclosure is necessary to comply with a
              subpoena, warrant or order by a court or other agency with jurisdiction to
              compel the production of personal information (section 18(1)(i)). For
              example, an organization may disclose personal information without consent
              when a court order is served on the organization. • When the disclosure is
              to a public body or a law enforcement agency in Canada to assist an
              investigation of an offence under the laws of Canada or a province of Canada
              (section 18(1)(j)). For example, disclosing personal information to WorkSafe
              BC to carry out an investigation of a workplace accident. • When the
              information is disclosed to respond to an emergency that threatens the
              health or safety of an individual or the public and if notice of the
              disclosure is mailed to the last known address of the individual to whom the
              personal information relates (section 18(1)(k)). For example, if an
              individual makes a serious threat against another person, the information
              may be disclosed to prevent the person from being injured, as long as you
              notify the individual about the disclosure. • When disclosure is needed to
              contact next of kin or a friend of an injured, ill or deceased individual
              (section 18(1)(l)). • When the disclosure is to a lawyer representing your
              organization (section 18(1) (m)). • When the disclosure is to an archival
              institution if the collection of the personal information is reasonable for
              research or archival purposes (section 18(1)(n)).
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            10. Contact Info
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              You can contact Game X Change&#145;s data protection officer at the address
              below.
              <br />
              While we review any request sent by mail, please be aware that to combat
              fraud, harassment and identity theft, the only way to access, rectify or
              delete your data is through logging in with your Game X Change User Account
              and selecting the menu items &#8212;&#062; Settings
              <br />
              <br />
              Game X Change
              <br />
              Att. Data Protection officer
              <br />
              250 - 997 Seymour St.
              <br />
              Vancouver, BC, Canada
              <br />
              V6B 3M1
              <br />
              <br />
              For ease you may additionally submit to contact@tapproject.net or
              team@tapproject.net
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            11. Additional Information for Users from British Columbia
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              Game X Change - &quot;Truly Simplistic Innovations&quot; complies with the
              BCPA and PIPA. If there is any conflict between the terms in this privacy
              policy and the PIPA, PIPA shall govern. Please visit https://www.oipc.bc.ca/
              In compliance with the guidelines, Game X Change - &quot;Truly Simplistic
              Innovations&quot; commits to resolve complaints about our collection or use
              of your personal information.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit" variant="body1">
            10. Right to refuse request
          </Typography>
          <Grid item xs={12} className="mt--16">
            <Typography color="inherit">
              Game X Change must refuse access to an individual’s personal information in
              the following circumstances: • The disclosure could reasonably be expected
              to threaten the safety or physical or mental health of another individual
              (section 23(4)(a)). • The disclosure could reasonably be expected to cause
              immediate or serious harm to the safety or to the physical or mental health
              of the individual who made the request (section 23(4)(b)). • The disclosure
              would reveal personal information about another individual (section
              23(4)(c)). • The disclosure would reveal the identity of the person who
              provided you with the applicant’s personal information, and that person does
              not consent to the disclosure of his or her identity (section 23(4)(d)). For
              example, an applicant might be able to determine a person’s identity based
              on his or her handwriting, special knowledge or presence at an incident
              involving the applicant.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </DialogText>
  );
};

export default PrivacyPolicy;
