import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { redeemCode } from "utils/apiRequest";
import { ForgotPasswordWrapper } from "components/SignIn/styles";
import { ButtonClose } from "components/SignIn/Form/styles";
import { ReactComponent as Close } from "images/close.svg";
import FormContainer from "components/SignIn/Form/FormContainer";
import BackGroundImage from "components/SignUp/BackGroundImage";
import image from "images/signIn/background.png";

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: "center",
    marginTop: "24px",
    fontSize: "18px",
    fontWeight: 700,
  },
  textField: {
    margin: "24px 0",
  },
}));

const Redeem = React.memo(() => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const classes = useStyles();

  if (!user?.isLoggedIn) {
    history.push("/sign-in");
  }

  const closeHandler = () => {
    history.push("/dashboard");
  };

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleRedeemCode = () => {
    setError("");
    setSuccess("");
    redeemCode(code)
      .then((res) => {
        setCode("");
        setSuccess(
          res.data.data.earned_currency +
            " points have been added to your " +
            res.data.data.vaultName +
            " vault!"
        );
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          setError(e.response.data.message);
        } else {
          setError("Error: " + e.message);
        }
      });
  };

  return (
    <>
      <BackGroundImage image={image} />
      <ForgotPasswordWrapper>
        <FormContainer name="Redeem Your Gaming Wealth!">
          <ButtonClose onClick={closeHandler}>
            <Close />
          </ButtonClose>
          <Grid item xs={12}>
            <Typography variant="body2">
              Have a code? Enter it here to redeem your rewards!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={code}
              id="code"
              label="Code"
              onChange={(e) => handleChange(e)}
              name="code"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!code}
              onClick={handleRedeemCode}
              style={{ marginBottom: "12px" }}
            >
              Redeem Code
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                history.push("/dashboard");
              }}
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          {!!error && (
            <Typography
              style={{
                color: "red",
              }}
              className={classes.message}
            >
              {error}
            </Typography>
          )}
          {!!success && (
            <Typography
              style={{
                color: "green",
              }}
              className={classes.message}
            >
              {success}
            </Typography>
          )}
        </FormContainer>
      </ForgotPasswordWrapper>
    </>
  );
});

export default Redeem;
