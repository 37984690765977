import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../components/Layout/index";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user);
  const authenticated = user.isLoggedIn;
  const terms = user.terms_and_privacy_policy_accepted;

  const history = useHistory();

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          terms ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            history.push("/sign-up/steps")
          )
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
};

export default PrivateRoute;
