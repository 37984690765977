import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { ReadBlue } from "../../SignUp/registrationSteps/steps/step2/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "&&": {
      padding: "1.5rem",
      borderRadius: "16px",
      backgroundColor: "#242731",
      display: "flex",
      color: "#F3F3F37F",
      fontWeight: "400",
      lineHeight: "20px",
      fontSize: "14px",
      fontFamily: '"Public Sans", sans-serif',
      maxWidth: "515px",
      maxHeight: "80vh",
    },
  },
}));

export const DialogTitle = styled.span`
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 24px;
`;

export const DialogWarning = styled(ReadBlue)`
  padding: 16px 0;
`;

export const DialogText = styled.div`
  overflow-y: auto;
  max-height: 40vh;

  & p {
    margin-bottom: 0;
  }
`;

export const Actions = styled.div`
  margin-top: 40px;
  display: flex;
`;
