import React from "react";
import {
  makeStyles,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CurrencyText, CurrencyImage } from "components/Exchange/styles";
import { TOKEN_INFO, getTokenImageBySymbol } from "blockchain/tokenInfo";
import unknownToken from "assets/icons/unknownToken.png";

const useStyles = makeStyles((theme) => ({
  field: {
    border: "1px solid gray",
    backgroundColor: "#28282f",
    borderRadius: "15px",
    padding: "0px 0px 0px 16px",
    minHeight: "58px",
    ">div": {
      padding: "11px 0px 5px 0px",
      ">img": {
        margin: "6px auto!important",
        display: "block",
      },
    },
    "& input, & label": {
      textAlign: "right",
      fontSize: "16px",
      fontWeight: "900",
      textAlign: "left",
    },
  },
  select: {
    maxWidth: "min-content",
    borderRadius: "10px",
    fontWeight: "900",
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  noIcon: {
    verticalAlign: "middle",
    height: "24px",
    marginRight: "5px",
    borderRadius: "15px",
  },
  balance: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Work Sans",
    color: "#999ba3",
  },
  endAdornment: {
    "& p": {
      color: "#79A17FAA",
      fontSize: 12,
      fontWeight: 600,
      cursor: "pointer",
      border: "2px solid",
      padding: "0 5px",
      margin: "0 10px 10px 0",
      borderRadius: "10px",
    },
    "& p:hover": {
      color: "#79A17FFF",
    },
    "& p:active": {
      transform: "translateY(3px)",
    },
  },

  endAdornmentAfter: {
    "& img": {
      position: "absolute",
      right: 18,
    },
  },
  textField: {
    "& > div": {
      margin: "0px",
      paddingTop: "16px",
    },
  },
}));

const SwapInput = ({
  label,
  currencyOptions,
  selectedCurrency,
  onChangeCurrency,
  onChangeAmount,
  amount,
  balance,
  hideMax,
  token,
}) => {
  const classes = useStyles();

  const imageOnErrorHandler = (ev) => {
    ev.currentTarget.src = unknownToken;
  };

  return (
    <Grid container className={classes.lastInput}>
      {!!currencyOptions && (
        <Grid container justifyContent="space-between">
          <Select
            value={selectedCurrency}
            onChange={onChangeCurrency}
            IconComponent={ExpandMoreIcon}
            disableUnderline
            displayEmpty
            className={classes.select}
            renderValue={
              selectedCurrency !== ""
                ? null
                : () => <CurrencyText>Select a currency</CurrencyText>
            }
          >
            {currencyOptions.map((currency, index) => (
              <MenuItem
                key={currency.name || currency.contract_name}
                value={index}
                disableGutters
                disabled={selectedCurrency === index}
              >
                <CurrencyImage
                  src={getTokenImageBySymbol(currency.symbol)}
                  onError={imageOnErrorHandler}
                  alt="currency"
                />
                <CurrencyText>
                  {currency.symbol || TOKEN_INFO[currency.contract_name]?.symbol}
                </CurrencyText>
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption" className={classes.balance}>
            Balance: ~{parseFloat((((balance ?? 0) * 100) / 100).toFixed(4))}
          </Typography>
        </Grid>
      )}
      <Grid container className={classes.field}>
        <TextField
          name="amount"
          className={classes.textField}
          fullWidth
          InputProps={{
            disableUnderline: true,
            endAdornment: !hideMax && (
              <InputAdornment
                onClick={() => {
                  if (token?.native) {
                    onChangeAmount(balance - 0.003 || 0);
                  } else {
                    onChangeAmount(balance.toString().slice(0, -1).concat("0") || 0);
                  }
                }}
                className={classes.endAdornment}
                position="end"
              >
                MAX
              </InputAdornment>
            ),
          }}
          autoComplete="off"
          placeholder="0"
          label={label}
          type="text"
          value={amount}
          onChange={onChangeAmount}
        />
      </Grid>
    </Grid>
  );
};

export default SwapInput;
