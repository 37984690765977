import { Button, Grid } from "@material-ui/core";
import {
  approveFor,
  connect as web3Connect,
  enterStake,
  getAllowanceOf,
  getStakeInfo,
  leaveStake,
  getStakePages,
  claimStake,
} from "actions";
import {
  currentNetwork,
  isValidNetwork,
  transactionFinishedLoop,
} from "blockchain/web3Utils";
import { TOKEN_TYPES } from "blockchain/tokenInfo";
import PageHeader from "components/pageComponents/PageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "reducers/redux/Modal/actions";
// import * as genericAxios from "utils/genericAxios";
import { Link } from "react-router-dom";
import useStakingData from "../../hooks/useStakingData";
import { ContentName, ContentWrapper } from "../Marketplace/styles";
import StakingModalContainer from "../Modal/StakeModals";
import ContentTabs from "./content/ContentTabs";

const Content = () => {
  const [list, partnerList] = useStakingData();
  const filteredList = list.filter((stake) => {
    if (stake.id == 13) {
      return true;
    }
    if (stake.id <= 9) {
      return true;
    }
    return false;
  });

  const [fromValue, setFromValue] = useState("");
  // const [fiatRatio, setFiatRatio] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openModalConvert, setOpenModalConvert] = useState(false);
  const [message, setMessage] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const web3 = useSelector((state) => state.web3);
  const dispatch = useDispatch();

  useEffect(() => {
    currentNetwork().then((network) => {
      if (isValidNetwork(network)) {
        dispatch(getStakePages(TOKEN_TYPES.ERC721, 1, 20, true));
      }
    });
  }, [dispatch, web3.connected, web3.chainId]);

  const detailsHandler = (info, name) => {
    dispatch(
      openModal({
        header: name,
        successButton: "Close",
        values: [info.text],
        delay: 100,
      })
    );
  };

  const stakeHandler = (mode, index) => {
    setOpenModalConvert({ mode, index });
  };

  const successHandler = (
    mode,
    { stakeId, type, nftAddr, token, balance, allowance, stakeAddress }
  ) => {
    setButtonLoading(true);
    let realValue = fromValue;
    //dispatch(stakingItem(id, value));
    switch (mode) {
      case "approve":
        //Set allowance to 1 Billion TTT so users only have to approve once
        dispatch(approveFor(token, "1000000000000000000000000000", stakeAddress))
          .then((hash) => {
            transactionFinishedLoop(hash, () => {
              dispatch(getAllowanceOf(token, stakeAddress))
                .then(() => {
                  setButtonLoading(false);
                  setMessage("");
                })
                .catch((e) => {
                  setMessage(e);
                  setButtonLoading(false);
                });
            });
          })
          .catch((e) => {
            setMessage(e);
            setButtonLoading(false);
          });
        break;
      case "stake":
        realValue = fromValue === "Max" ? Math.min(balance, allowance) : fromValue;
        dispatch(enterStake(type, stakeId, nftAddr, realValue.toString()))
          .then((hash) => {
            transactionFinishedLoop(hash, () => {
              setButtonLoading(false);
              setMessage("");
              setOpenModalConvert(null);
              dispatch(getStakeInfo(type, stakeId));
            });
          })
          .catch((e) => {
            setMessage(e);
            setButtonLoading(false);
          });
        break;
      case "withdraw":
        // realValue = fromValue === "Max" ? fromValue;
        dispatch(leaveStake(type, stakeId, 0))
          .then((hash) => {
            transactionFinishedLoop(hash, () => {
              setButtonLoading(false);
              setMessage("");
              setOpenModalConvert(null);
              dispatch(getStakeInfo(type, stakeId));
            });
          })
          .catch((e) => {
            setMessage(e);
            setButtonLoading(false);
          });
        break;
      case "claim":
      default:
        dispatch(claimStake(type, stakeId, fromValue || 99))
          .then((hash) => {
            transactionFinishedLoop(hash, () => {
              setButtonLoading(false);
              setMessage("");
              setOpenModalConvert(null);
              dispatch(getStakeInfo(type, stakeId));
            });
          })
          .catch((e) => {
            setMessage(e);
            setButtonLoading(false);
          });
        break;
    }
  };

  const rejectHandler = () => {
    setOpenModalConvert(null);
  };

  const connectHandler = () => {
    dispatch(web3Connect());
  };

  return (
    <ContentWrapper>
      <PageHeader h1="Staking" subtitle="Stake Items to earn additional Rewards" />
      {activeTab === 0 && <ContentName>Stake NFPs + Tokens to earn GXG!</ContentName>}
      {activeTab === 1 && <ContentName>Stake GXG and earn other tokens!</ContentName>}
      <p className="display--inline mr--8">Can't find a staking pool?</p>
      <Button variant="contained" color="primary" component={Link} to="/staking">
        Check the active stakes
      </Button>
      <Grid className="mt--24" container justifyContent="center">
        {/*
        <Grid item container justifyContent="center">
          <FlexGrid>
            <ExpButton
              variant="contained"
              color="primary"
              disabled={!activeTab}
              onClick={() => setActiveTab((prev) => --prev)}
            >
              Earn Exp
            </ExpButton>
            <ExpButton
              variant="contained"
              color="primary"
              disabled={activeTab}
              onClick={() => setActiveTab((prev) => ++prev)}
            >
              Stake Exp
            </ExpButton>
          </FlexGrid>
        </Grid>
            */}
        <Grid container justifyContent="flex-start" spacing={4} alignItems="flex-start">
          <ContentTabs
            earnExp={filteredList}
            stakeExp={partnerList}
            activeTab={activeTab}
            connected={web3.connected}
            detailsHandler={detailsHandler}
            stakeHandler={stakeHandler}
            connectHandler={connectHandler}
          />
        </Grid>
      </Grid>
      {typeof openModalConvert?.index === "number" && (
        <StakingModalContainer
          message={message}
          fromValue={fromValue}
          setFromValue={setFromValue}
          buttonLoading={buttonLoading}
          web3={web3}
          open={!!openModalConvert}
          mode={openModalConvert ? openModalConvert.mode : false}
          index={openModalConvert.index}
          successHandler={successHandler}
          rejectHandler={rejectHandler}
        />
      )}
    </ContentWrapper>
  );
};

export default Content;
