import React from "react";
import Loader from "../loaders";
import { FormButton } from "./styles";

const ButtonForm = ({ disabled, loader }) => (
  <FormButton disabled={disabled || loader} type="submit">
    <span>Continue</span>
    {loader && <Loader />}
  </FormButton>
);

export default ButtonForm;
