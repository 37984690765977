// Access Related Actions
export const CREDENTIAL_CLEAR = "CREDENTIAL_CLEAR";
export const CREDENTIAL_UPDATE = "CREDENTIAL_UPDATE";
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const VALID_USERNAME = "VALID_USERNAME";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const NONUSER_ADDRESS = "NONUSER_ADDRESS";
export const REFERRAL_CODE = "REFERRAL_CODE";
export const UPDATE_CURRENCIES = "UPDATE_CURRENCIES";

// Game Related Actions
export const GAME_DELETE = "GAME_DELETE";
export const GAME_VIEW = "GAME_VIEW";
export const GAME_VIEW_LIST = "GAME_VIEW_LIST";
export const FEATURE_VIEW = "FEATURE_VIEW";
export const GAME_PUBLISHER = "GAME_PUBLISHER";
export const MODAL = "MODAL";
export const REFERRAL_LEADERBOARD = "REFERRAL_LEADERBOARD";
export const CONVERSION_LEADERBOARD = "CONVERSION_LEADERBOARD";

// Challenge related Actions
export const CHALLENGES_CLEAR = "CHALLENGES_CLEAR";

// Third-party related Actions
export const STEAM_USER_ACHIEVEMENTS = "STEAM_USER_ACHIEVEMENTS";
export const STEAM_ACHIEVEMENTS = "STEAM_ACHIEVEMENTS";
export const SEASONAL_CHALLENGE_GAMES = "SEASONAL_CHALLENGE_GAMES";
export const STEAM_USER_CLAIM = "STEAM_USER_CLAIM";
export const STEAM_USER_CLAIM_REMAINING = "STEAM_USER_CLAIM_REMAINING";
export const STEAM_USER_NAME = "STEAM_USER_NAME";

// Vault Related Actions
export const VAULT_DELETED = "VAULT_DELETED";
export const VAULT_VIEW = "VAULT_VIEW";
export const VAULT_VIEW_LIST = "VAULT_VIEW_LIST";
export const VAULT_GET = "VAULT_GET";
export const VAULT_GET_CHALLENGE = "VAULT_GET_CHALLENGE";
export const CREATE_VAULT = "CREATE_VAULT";
export const CONVERSION_RATE = "CONVERSION_RATE";
export const VAULT_TRANSACTIONS = "VAULT_TRANSACTIONS";
export const VAULT_LIMITS = "VAULT_LIMITS";
export const VAULT_FULL_INFO = "VAULT_FULL_INFO";
export const VAULT_CONVERSION_FEE = "VAULT_CONVERSION_FEE";
export const VAULT_CLEAR = "VAULT_CLEAR";
export const CONVERSION_STATS = "CONVERSION_STATS";

// Raffle Related ACtions
export const WEB3_RAFFLE_ALLOWANCE = "WEB3_RAFFLE_ALLOWANCE";

// Web3 Related Actions
export const WEB3_CONNECTED = "WEB3_CONNECTED";
export const WEB3_BAD_NETWORK = "WEB3_BAD_NETWORK";
export const WEB3_BALANCE = "WEB3_BALANCE";
export const WEB3_ALLOWANCE = "WEB3_ALLOWANCE";
export const WEB3_ETH_BALANCE = "WEB3_ETH_BALANCE";
export const WEB3_GAS_PRICE = "WEB3_GAS_PRICE";
export const WEB3_RECENT_DEPOSITS = "WEB3_RECENT_DEPOSITS";
export const WEB3_RECENT_WITHDRAWALS = "WEB3_RECENT_WITHDRAWALS";
export const WEB3_RECENT_TRANSACTIONS = "WEB3_RECENT_TRANSACTIONS";
export const WEB3_OVERRIDE = "WEB3_OVERRIDE";
export const WEB3_WALLET_COUNT = "WEB3_WALLET_COUNT";
export const WEB3_WALLET_GASCOST = "WEB3_WALLET_GASCOST";
export const WEB3_WALLET_CREATE = "WEB3_WALLET_CREATE";
export const WEB3_HAS_WALLET = "WEB3_HAS_WALLET";
export const WEB3_WALLET_ERROR = "WEB3_WALLET_ERROR";
export const WEB3_CLEAR_ERRORS = "WEB3_CLEAR_ERRORS";
export const WEB3_WALLET_SEND_INFO = "WEB3_WALLET_SEND_INFO";
export const WEB3_WALLET_SEND = "WEB3_WALLET_SEND ";
export const WEB3_WALLET_ALLOW_INFO = "WEB3_WALLET_ALLOW_INFO";
export const WEB3_CONVERSION_ALLOWANCE = "WEB3_CONVERSION_ALLOWANCE";
export const WEB3_BURN_AMOUNT = "WEB3_BURN_AMOUNT";
export const WEB3_SAVE_ADDRESS = "WEB3_SAVE_ADDRESS";
export const WEB3_TOTAL_STAKED = "WEB3_TOTAL_STAKED";
export const WEB3_GXP_REQUIREMENTS = "WEB3_GXP_REQUIREMENTS";
export const WEB3_NEEDS_GXP = "WEB3_NEEDS_GXP";

// Dega-related Actions
export const WEB3_DEGA_BALANCE = "WEB3_DEGA_BALANCE";

// Publisher Related Actions
export const PUBLISHER_MD5 = "PUBLISHER_MD5";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const OWNER_ORGANIZATION = "OWNER_ORGANIZATION";
export const PUB_DASHBOARD = "PUB_DASHBOARD";
export const GAME_UPDATE = "GAME_UPDATE";
export const GAME_PUBLISHER_STATS = "GAME_PUBLISHER_STATS";
export const ORGANIZATION_MEMBERS = "ORGANIZATION_MEMBERS";
export const ORGANIZATION_ID = "ORGANIZATION_ID";
export const ORGANIZATION_INVITES = "ORGANIZATION_INVITES";
export const UPDATE_ORGANIZATION_MEMBER = "UPDATE_ORGANIZATION_MEMBER";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const SDK_PASSWORD = "SDK_PASSWORD";

// Generic Actions
export const FORM_ERROR = "FORM_ERROR";
export const FORM_SUCCESS = "FORM_SUCCESS";

// Snackbar snacks
export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_SET_CONTENT = "SNACKBAR_SET_CONTENT";
export const SNACKBAR_CLOSE = "SNACKBAR_CLOSE";
export const SNACKBAR_CLEAR_CONTENT = "SNACKBAR_CLEAR_CONTENT";
export const PUB_GAME_VIEW_LIST = "PUB_GAME_VIEW_LIST";

//NFT related actions
export const GET_LTNFTS = "GET_LTNFTS";
export const GET_PLTNFTS = "GET_PLTNFTS";
export const GET_NFTS = "GET_NFTS";
export const GET_FEATURED_NFTS = "GET_FEATURED_NFTS";
export const GET_SPONSORED_NFT = "GET_SPONSORED_NFT";

// Payment Contract
export const ADD_PAYMENT_CONTRACT = "ADD_PAYMENT_CONTRACT";
