import Typography from "@material-ui/core/Typography";
import { signIn, registerUser } from "actions/user";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReactComponent as Close } from "../../../images/icons/arrowBack.svg";
import ButtonForm from "../../buttons/ButtonForm";
import InputForm from "../../inputs/InputForm";
import {
  ButtonCloseAlwaysView,
  FormLink,
  InputContainer,
  WrapperSmallInput,
} from "../../SignIn/Form/styles";

const renderTextField = ({ label, input, meta: { touched, error }, ...custom }) => (
  <div>
    <InputForm label={label} error={!!(touched && error)} {...input} {...custom} />
    {touched && error && <span>{error}</span>}
  </div>
);

const required = (value) => (value || typeof value === "number" ? undefined : "Required");
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength20 = maxLength(20);
const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
const minLength2 = minLength(2);
const minLength6 = minLength(6);
const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? "Only alphanumeric characters" : undefined;
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const Form = (props) => {
  const { invalid } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { referralcode } = useParams();

  const [loader, setLoader] = useState("");
  const [errorMsg, setError] = useState("");
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email_address: "",
    password: "",
    referred_id: referralcode,
  });

  const disabled = !(
    values.first_name &&
    values.last_name &&
    values.username &&
    values.email_address &&
    values.password &&
    values.password_confirm
  );

  const handler = (name) => (e) => {
    setValues((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const closeHandler = () => {
    history.push("/sign-in");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(registerUser(values))
      .then(() => {
        setLoader(false);
        dispatch(signIn(values.username, values.password));
        history.push("/sign-up/steps");
      })
      .catch((err) => {
        setError(err);
        setLoader(false);
      });
  };

  return (
    <>
      <ButtonCloseAlwaysView onClick={closeHandler}>
        <Close />
      </ButtonCloseAlwaysView>
      <FormLink>
        <span>
          Already a user? <Link to="/sign-in">Login now</Link>
        </span>
      </FormLink>

      <form onSubmit={formSubmit}>
        <InputContainer>
          <WrapperSmallInput>
            <Field
              required
              label="First name"
              value={values.first_name}
              onChange={handler("first_name")}
              name="first_name"
              component={renderTextField}
              validate={[required, minLength2, maxLength20, alphaNumeric]}
            />

            <Field
              required
              label="Last name"
              value={values.last_name}
              onChange={handler("last_name")}
              name="last_name"
              component={renderTextField}
              validate={[required, minLength2, maxLength20, alphaNumeric]}
            />
          </WrapperSmallInput>

          <Field
            required
            label="Username"
            value={values.username}
            onChange={handler("username")}
            name="username"
            component={renderTextField}
            warn={alphaNumeric}
            validate={[required, minLength2, maxLength20, alphaNumeric]}
          />

          <Field
            required
            label="Email Address"
            type="email"
            value={values.email_address}
            onChange={handler("email_address")}
            name="email"
            component={renderTextField}
            validate={[required, email]}
          />

          <Field
            required
            label="Password"
            type="password"
            value={values.password}
            onChange={handler("password")}
            name="password"
            component={renderTextField}
            validate={[required, minLength6]}
          />

          <Field
            required
            label="Confirm Password"
            type="password"
            value={values.password}
            onChange={handler("password_confirm")}
            name="password_confirm"
            component={renderTextField}
            validate={[required, minLength6]}
          />

          {values.referred_id && (
            <InputForm
              label="Referral Code"
              value={values.referred_id}
              disableValue
              name="referred_id"
            />
          )}
        </InputContainer>

        <ButtonForm
          disabled={disabled || loader || invalid}
          loader={loader}
          onClick={() => registerUser(values)}
        />
        <Typography variant="h6" align="center">
          {errorMsg}
        </Typography>
      </form>
    </>
  );
};

export default reduxForm({
  form: "signUp",
})(Form);
