import Web3 from "web3";
import TruffleContract from "@truffle/contract";
import IERC20Artifact from "ethereum_abi/IERC20.json";
import IERC721Artifact from "ethereum_abi/IERC721.json";
// import IERC223Artifact from "ethereum_abi/IERC223.json";
import IERC1155Artifact from "ethereum_abi/IERC1155.json";
import * as Web3Calls from "utils/web3Calls";
import { Interface } from "@ethersproject/abi";
import {
  TOKEN_INFO,
  SUPPORTED_BLOCKCHAINS,
  TOKEN_TYPES,
  getTokenFromAddressByChain,
  formatToChain,
} from "blockchain/tokenInfo";
import Token from "utils/web3/models/token";
import { performMultiCall } from "utils/web3/index";

const { utils } = Web3;
export const { BN } = utils;

let web3ProviderInit;
if (typeof window.ethereum !== "undefined") {
  web3ProviderInit = window.ethereum;
} else {
  web3ProviderInit = new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org");
}

const web3Provider = web3ProviderInit;

export const web3 = new Web3(web3Provider);
export const connected = false;

const IERC20 = TruffleContract(IERC20Artifact);
// const IERC223 = TruffleContract(IERC223Artifact);
const IERC721 = TruffleContract(IERC721Artifact);
const IERC1155 = TruffleContract(IERC1155Artifact);
/**
 * Sets the provider for all the contracts
 * @param {Web3Provider} provider
 */
export const setTruffleProvider = (provider) => {
  IERC20.setProvider(provider);
  IERC721.setProvider(provider);
  IERC1155.setProvider(provider);
};

setTruffleProvider(web3Provider);

/*
  web3Provider.request({ method: "eth_requestAccounts" }).then(addresses => {
    return web3.eth.getChainId().then(chainId => {
      connected = true;
      return { address: addresses[0], chainId};
    })
  });
*/

// utils
/**
 * Check if the provided address exists
 * @param {string} realAddress
 * @returns {boolean} returns true if address exists
 */
export const isAddress = (realAddress) => web3.utils.isAddress(realAddress);

export const parseNetworkId = (chainId) =>
  chainId.toString().includes("0x") ? parseInt(chainId.replace("0x", ""), 16) : chainId;

export const currentNetwork = () =>
  web3.eth.getChainId().then((id) => parseNetworkId(id));

export const isValidNetwork = (chainId) => {
  let valid = false;
  SUPPORTED_BLOCKCHAINS.some((key) => {
    if (key === chainId) {
      valid = true;
      return true;
    }
  });
  return valid;
};

export const getExchangeToken = async (address, chainId) => {
  const existingToken = getTokenFromAddressByChain();
  if (existingToken) {
    return formatToChain(existingToken);
  }
  const tokenContract = await Web3Calls.getContractInstance("IERC20", address);
  const decimals = await tokenContract.decimals();
  const symbol = await tokenContract.symbol();
  const name = await tokenContract.name();
  return new Token(chainId, address, decimals, symbol, name);
};

export const getExchangeTokens = async (addresses, chainId) => {
  const pair = await Web3Calls.getContract("IERC20");
  const iface = new Interface(pair.abi);
  const decimalsData = iface.encodeFunctionData("decimals");
  const symbolData = iface.encodeFunctionData("symbol");
  const nameData = iface.encodeFunctionData("name");
  const unknownAddresses = [];
  const knownAddresses = [];

  addresses.forEach((address) => {
    const existingToken = getTokenFromAddressByChain(address, chainId);
    if (existingToken) {
      knownAddresses.push(formatToChain(existingToken, chainId));
    } else {
      unknownAddresses.push(address);
    }
  });
  const decimals = await performMultiCall(
    "decimals",
    iface,
    unknownAddresses,
    decimalsData
  );
  const symbols = await performMultiCall("symbol", iface, unknownAddresses, symbolData);
  const names = await performMultiCall("name", iface, unknownAddresses, nameData);
  unknownAddresses.forEach((unknown, i) => {
    knownAddresses.push(
      new Token(chainId, unknown, decimals[i][0], symbols[i][0], names[i][0])
    );
  });
  return knownAddresses;
};

/**
 * Check if the specified transaction is completed
 * https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html?highlight=getTransactionReceipt#gettransactionreceipt
 * @param {string} hash
 * @returns A transaction receipt object, or null if no receipt was found:
 */
export const isCompletedTx = (hash) => web3.eth.getTransactionReceipt(hash);

// way to get currently selected address
export const selectedAddress = () =>
  web3Provider.selectedAddress || web3Provider.address || web3Provider.accounts[0];

/**
 * Changes the network the user is currently using.
 * @param {*} chain
 * @returns {Web3Provider|Promise}
 */
export const addChain = async (chain) =>
  web3.eth.getChainId().then(async (curChain) => {
    if (parseNetworkId(chain.chainId) != curChain) {
      try {
        await web3Provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chain.chainId) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask. Different on mobile for some reason, might need to just check for any errors
        if (switchError.code === 4902) {
          try {
            await web3Provider.request({
              method: "wallet_addEthereumChain",
              params: [chain],
            });
          } catch (addError) {
            console.log("Error while adding ", addError);
          }
        }
      }
    }
    return new Promise((resolve, reject) => resolve());
  });

// ETH functions
/**
 * Gets the user's current eth balance
 * @param {*} userAddress
 * @returns {Promise}
 */
export const ethBalance = (userAddress) =>
  web3.eth.getBalance(userAddress || selectedAddress());

// ERC20 functions
/**
 * Gets the user's current balance for an ERC20 token
 * @param {string} tokenAddress
 * @param {string} userAddress
 * @returns {Promise}
 */
export const balanceOfERC20Token = (tokenAddress, userAddress) =>
  IERC20.at(tokenAddress).then((instance) =>
    instance.balanceOf.call(userAddress || selectedAddress())
  );

/**
 * Gets the user's current allowance to a target for an ERC20 token
 * @param {string} tokenAddress
 * @param {string} targetAddress
 * @param {string} userAddress
 * @returns {Promise}
 */
export const allowanceOfERC20Token = (tokenAddress, targetAddress, userAddress) =>
  IERC20.at(tokenAddress).then((instance) =>
    instance.allowance.call(userAddress || selectedAddress(), targetAddress)
  );

/**
 * Sets the user's current allowance to a target for an ERC20 token
 * @param {string} tokenAddress
 * @param {string} targetAddress
 * @param {string} amount
 * @param {string} userAddress
 * @returns {Promise}
 */
export const allowERC20Token = (tokenAddress, targetAddress, amount, userAddress) =>
  IERC20.at(tokenAddress).then((instance) =>
    instance.approve(targetAddress, amount, {
      from: userAddress || selectedAddress(),
    })
  );

/**
 * Transfers an ERC20 token from the user to a target
 * @param {string} tokenAddress
 * @param {string} targetAddress
 * @param {string} amount
 * @param {string} userAddress
 * @returns {Promise}
 */
export const transferERC20Token = (tokenAddress, targetAddress, amount, userAddress) =>
  IERC20.at(tokenAddress).then((instance) =>
    instance.transfer(targetAddress, amount, {
      from: userAddress || selectedAddress(),
    })
  );

export const displayFormatWithSuffix = (displayAmount) => {
  const suffixes = ["", "K", "M", "B", "T"];
  let i = 0;
  let finalVal = displayAmount;
  while (finalVal >= 1000) {
    finalVal /= 1000;
    i++;
  }
  return `${parseFloat(parseFloat(finalVal).toFixed(2))}${suffixes[i]}`;
};

/**
 * Turns a BN into a float amount using the number of decimals in a token
 * @param {BN} amount
 * @param {number} decimals
 * @returns {float}
 */
export const getDisplayVal = (amount, decimals, formatWithSuffix) => {
  let realAmount = amount;
  if (!decimals) {
    return amount ? amount.toString() : 0;
  }
  if (typeof amount === "object") {
    realAmount = amount.toString();
  }
  var displayAmount;
  if (realAmount.length > decimals) {
    var end = realAmount.slice(realAmount.length - decimals);
    var beginning = realAmount.slice(0, realAmount.length - decimals);
    displayAmount = parseFloat(beginning + "." + end);
  } else {
    var prepend = decimals - realAmount.length;
    var prefix = "0.";
    for (let index = 0; index < prepend; index++) {
      prefix = prefix.concat("0");
    }
    displayAmount = parseFloat(prefix + realAmount.toString());
  }
  if (formatWithSuffix) {
    displayFormatWithSuffix(displayAmount);
  }
  return displayAmount;
};

export const doBigMath = (amount, operator, otherNumber) => {
  const a = new BN(amount);
  const b = new BN(otherNumber);
  switch (operator) {
    case "-":
      return a.sub(b);
    case "*":
      return a.mul(b);
    case "/":
      return a.div(b);
    default:
      return a.add(b);
  }
};

export const toBaseUnit = (value, decimals, safe = false) => {
  if (!(typeof value === "string" || value instanceof String)) {
    throw new Error("Pass strings to prevent floating point precision issues.");
  }
  const ten = new BN(10);
  const base = ten.pow(new BN(decimals));

  // Is it negative?
  const negative = value.substring(0, 1) === "-";
  if (negative) {
    value = value.substring(1);
  }

  if (value === ".") {
    throw new Error(
      `Invalid value ${value} cannot be converted to` +
        ` base unit with ${decimals} decimals.`
    );
  }

  // Split it into a whole and fractional part
  const comps = value.split(".");
  if (comps.length > 2) {
    throw new Error("Too many decimal points");
  }

  let whole = comps[0];
  let fraction = comps[1];

  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > decimals) {
    if (safe) {
      throw new Error("Too many decimal places");
    }
    fraction = fraction.substring(0, decimals);
  }

  while (fraction.length < decimals) {
    fraction += "0";
  }

  whole = new BN(whole);
  fraction = new BN(fraction);
  let wei = whole.mul(base).add(fraction);

  if (negative) {
    wei = wei.neg();
  }

  return new BN(wei.toString(10), 10);
};

// ERC721 functions
/**
 * Gets the first 20 ERC721 tokens owned by a user from a contract
 * @param {string} tokenName
 * @param {number} networkId
 * @returns {array}
 */
export const getERC721OwnerTokens = async (tokenName, networkId) => {
  var tokenData = TOKEN_INFO[tokenName];
  var tokenAddress = tokenData.addresses[networkId];
  const instance = await IERC721.at(tokenAddress);
  var balance = await instance.balanceOf(selectedAddress());
  if (parseInt(balance.toString) > 20) {
    balance = 20;
  }
  const promiseTokens = [];
  for (let index = 0; index < balance; index++) {
    promiseTokens.push(instance.tokenOfOwnerByIndex(selectedAddress(), index));
  }
  const tokenList = await Promise.all(promiseTokens);
  const returnTokens = [];
  tokenList.forEach((element) => {
    returnTokens.push(element.toString());
  });
  return returnTokens;
};

/**
 * Gets metadata info for a token, including an image url and name
 * @param {string} tokenName
 * @param {number} tokenId
 * @param {number} networkId
 * @returns {Object}
 */
export const getERC721Token = async (tokenName, tokenId, networkId) => {
  var token = TOKEN_INFO[tokenName];
  if (token.urlPrefix) {
    return `${token.urlPrefix}/${tokenId}`;
  }
  var tokenMetadataURL = IERC721.at(token.addresses[networkId]).then((instance) =>
    instance.tokenURI(tokenId)
  );
  return tokenMetadataURL;
};

export const round = (int, decimals) => Math.round(int * 10 ** decimals) / 10 ** decimals;

export const roundFromWei = (int, unit, decimals) =>
  round(Web3Calls.fromWei(int, unit), decimals);

export const BNGTECompare = (one, two) => {
  return new BN(one).gte(new BN(two));
};

export const roundEtherBalance = (balance) => roundFromWei(balance, "ether", 2);

export const conversionRatio = (number) =>
  number < 1
    ? [1, Math.round((1 / number) * 100) / 100]
    : [Math.round(number * 100) / 100, 1];

export const dripEligible = (amount) => amount > 60000;
export const dripRate = (amount) => {
  if (amount) {
    return 0;
  }
  return Math.round((amount > 250000 ? 18750 / 250000 : 1800 / 60000) * 100);
};

const transactionFinsished = (hash, callback) => {
  Web3Calls.getTransaction(hash).then((info) => {
    if (info?.blockNumber !== null) {
      callback(hash);
    }
  });
};

export const transactionFinishedLoop = (hash, callback) => {
  let loop;
  const callback2 = () => {
    clearInterval(loop);
    callback(hash);
  };
  loop = setInterval(transactionFinsished, 8000, hash, callback2);
};

export const getTokenAddress = async (name) => {
  const chainId = await Web3Calls.currentNetwork();
  return TOKEN_INFO[name]?.addresses[chainId];
};

const parseStake721 = (
  contract,
  stake,
  stakeId,
  NFTsStaked,
  userNFTsStaked,
  type,
  chainId
) => {
  const NFT = getTokenFromAddressByChain(stake.whitelistedNftContract, chainId);
  const FT = getTokenFromAddressByChain(stake.secondaryStakingToken, chainId);
  const rewardFT = getTokenFromAddressByChain(stake.rewardsTokenContract, chainId);
  return {
    contract,
    type,
    hoursPerCycle: parseInt(stake.cycleLengthInSeconds.toString()) / 3600,
    rewardPeriodInCycles: parseInt(stake.periodLengthInCycles.toString()),
    rewardToken: {
      address: stake.rewardsTokenContract,
      name: rewardFT.name,
      decimals: rewardFT.decimals || 18,
      exchangeRate: rewardFT.exchangeRate,
      symbol: rewardFT.symbol,
    },
    secondaryToken: {
      amount: stake.secondaryAmount.toString(),
      address: stake.secondaryStakingToken,
      name: FT.name,
      decimals: FT.decimals,
      exchangeRate: FT.exchangeRate,
      symbol: FT.symbol,
    },
    primaryToken: {
      address: stake.whitelistedNftContract,
      name: NFT.name,
      decimals: NFT.decimals || 0,
      symbol: NFT.symbol,
      image: NFT.image,
    },
    id: stakeId.toString(),
    NFTsStaked: NFTsStaked.toString(),
    userNFTsStaked: userNFTsStaked.toString(),
  };
};

const parseStakePage721 = (contract, stakingPage, type, chainId) => {
  const results = [];
  stakingPage.stakes.forEach((stake, index) => {
    results[index] = parseStake721(
      contract,
      stake,
      stakingPage.stakeIds[index],
      stakingPage.staked[index],
      stakingPage.userStaked[index],
      type,
      chainId
    );
    results[index].realIndex = index;
  });
  return results;
};

export const parseStakePage = (contract, stakingPage, type, chainId) => {
  if (type == TOKEN_TYPES.ERC721) {
    return parseStakePage721(contract, stakingPage, type, chainId);
  }
};

export const parseStake = (contract, id, stakeInfo, type, chainId) => {
  if (type == TOKEN_TYPES.ERC721) {
    return parseStake721(
      contract,
      stakeInfo.stake,
      id,
      stakeInfo.staked,
      stakeInfo.userStaked,
      type,
      chainId
    );
  }
};
