import React from "react";
import image from "images/signUp/background.png";
import BackGroundImage from "../SignUp/BackGroundImage";
import Content from "../SignUp/Content";

const SignUp = React.memo(() => {
  return (
    <>
      <BackGroundImage image={image} />
      <Content />
    </>
  );
});

export default SignUp;
