import GameGridItem from "components/GameGridItem";
import React from "react";
import { useSelector } from "react-redux";
import { useButtonStyles } from "../../../styles";
import {
  GameItemBody,
  GameDescription,
  GamesItem,
  InnerTextShadow,
} from "../../Dashboard/styles";
import { ChallengeName, CraftPackInfo } from "../styles";

const CraftPack = ({ list, openModalCraftPack }) => {
  const classes = useButtonStyles();
  const gold = useSelector((state) => state.PlayerCurrency.gold);

  return list.map(({ id, image, name, about, goldCost, nft }) => (
    <GamesItem elevation={3}>
      <img src={image} alt={name} />
      <GameItemBody>
        <ChallengeName>Total Gold: {gold.count}</ChallengeName>
        <GameDescription>{about}</GameDescription>
        <CraftPackInfo>
          {goldCost} Gold = {nft} NFT Pack
        </CraftPackInfo>
      </GameItemBody>
      <InnerTextShadow />
    </GamesItem>
  ));
};

export default CraftPack;
