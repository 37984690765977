import React, { useEffect, useState } from "react";
import NFTModal from "./NFTModal";

const NFTModalContainer = ({
  buttonLoading,
  open,
  mode,
  successHandler,
  rejectHandler,
  ...state
}) => {
  return (
    <NFTModal
      buttonLoading={buttonLoading}
      successHandler={successHandler}
      title="NFT"
      closeHandler={rejectHandler}
      open={open}
      {...state}
    />
  );
};

export default NFTModalContainer;
