import { REMOVE_OFFER, GET_OFFERS_AND_REPLACE } from "./types";
import { getOffers } from "../../../utils/apiRequest";

export const getAndReplaceOffers = () => (dispatch) =>
  getOffers()
    .then((response) => {
      dispatch({
        type: GET_OFFERS_AND_REPLACE,
        offers: response.data,
      });
    })
    .catch((error) => {
      // console.log(error);
    });

export const removeOffer = (id) => {
  return {
    type: REMOVE_OFFER,
    id,
  };
};
