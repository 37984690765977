import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginGames } from "reducers/redux/LoginGamesList/actions";
import { Content, Game, ListWrapper, Name } from "./styles";

export default React.memo(() => {
  const gamesList = useSelector(({ LoginGamesList }) => LoginGamesList.gamesList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLoginGames);
  }, [dispatch]);

  const renderGenres = (genres) => (
    <Typography variant="body1">
      {!!genres &&
        genres.map(({ id, description }, i) => {
          if (i > 2) return null;
          const last = i === 2 || i > genres.length - 2;
          return (
            <React.Fragment key={id}>
              {description}
              {!last && ", "}
            </React.Fragment>
          );
        })}
    </Typography>
  );

  return (
    <>
      <ListWrapper>
        {!!gamesList.games &&
          gamesList.games.map(({ steamGameId, imageUrl, title, genres }) => (
            <Game key={steamGameId}>
              <img src={imageUrl} alt={title} />
              <Content>
                <Name>{title}</Name>
                {renderGenres(genres)}
              </Content>
            </Game>
          ))}
      </ListWrapper>
    </>
  );
});
