import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Logo, Text, HeaderText, FlexRow } from "./styles";
import { styled } from "@material-ui/core/styles";
import mysteryAvatar from "images/profile/mysteryAvatar.png";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  table: {
    backgroundColor: "rgb(31 31 31)",
    borderRadius: "20px",
    padding: "0px 20px !important",
    "@media (min-width: 1281px)": {
      width: "80%",
    },
  },
  header: {
    backgroundColor: "rgb(0 0 0)",
    "&:first-child": {
      borderTopLeftRadius: "20px",
      paddingLeft: "40px",
    },
    "&:last-child": {
      borderTopRightRadius: "20px",
      paddingRight: "40px",
    },
  },
});

const LeaderboardTable = ({ leaderboard }) => {
  const classes = useStyles();
  if (leaderboard === undefined) {
    return null;
  }

  return (
    <TableContainer>
      <Table align="center" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} align="center">
              <HeaderText>Rank</HeaderText>
            </TableCell>
            <TableCell className={classes.header} align="left">
              <HeaderText>Player</HeaderText>
            </TableCell>
            <TableCell className={classes.header} align="center">
              <HeaderText>Total Points Earned</HeaderText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboard &&
            leaderboard.length > 0 &&
            leaderboard.map((row) => (
              <StyledTableRow key={row._id}>
                <TableCell align="center">
                  <Text>{row.rank}</Text>
                </TableCell>
                <TableCell align="left" justifyContent="left">
                  <FlexRow>
                    <Logo>
                      <img src={row.avatar ?? mysteryAvatar} alt="user avatar" />
                    </Logo>
                    <Text style={{ margin: "auto" }}>{row.username}</Text>
                  </FlexRow>
                </TableCell>
                <TableCell align="center">
                  <Text>{row.score}</Text>
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderboardTable;
