import { balanceOfTokenByName, getUserUnclaimedNFTs } from "actions";
import PageHeader from "components/pageComponents/PageHeader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_PLAYER_CURRENCY_NFT } from "reducers/redux/Profile/PlayerCurrency/actions";
import { ContentWrapper } from "../Marketplace/styles";
import NFTs from "./NFTs/Content";
import Currencies from "./Currencies/Content";
import Vaults from "./Vaults/Content";
import ProfileHeader from "./ProfileHeader";
import ProfileStats from "./ProfileStats";
import testBG from "images/profile/profileTestBG.png";
import { Grid } from "@material-ui/core";
import NFTModalContainer from "../Modal/NFTModal";

const Content = React.memo(() => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);

  /*useEffect(() => {
    if (web3.connected && !web3.badNetwork) {
      dispatch(balanceOfTokenByName("TapNFTs", user.eth_wallet_address)).then((res) => {
        dispatch(getUserUnclaimedNFTs()).then((res2) => {
          dispatch({ type: SET_PLAYER_CURRENCY_NFT, data: res + res2.length });
        });
      });
    }
  }, [user.eth_wallet_address, web3.badNetwork, web3.connected, dispatch]);*/

  return (
    <ContentWrapper>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <ProfileHeader backgroundImage={testBG} />
        </Grid>
        <Grid container item spacing={2}>
          <Grid container item lg={5} xs={12}>
            <Grid container item spacing={2}>
              <ProfileStats />
              <Vaults />
            </Grid>
          </Grid>
          <Grid container item lg={7} xs={12}>
            <Grid container item spacing={2}>
              <NFTs />
              <Currencies />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <NFTModalContainer />
    </ContentWrapper>
  );
});

export default Content;
