import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchChallengeGameVault, fetchVaults } from "actions";
import GameGridList from "components/GameGridList";
import ProfilePaper from "../ProfilePaper";
import CraftPack from "../items/CraftPack";
import SteamPoints from "../items/SteamPoints";
import VaultPoints from "../items/VaultPoints";
import { Grid } from "@material-ui/core";

const Vaults = React.memo(() => {
  const listCraftPack = useSelector((state) => state?.NFT?.craftList);
  const vaults = useSelector((state) => state.vaults);
  const games = useSelector((state) => state.games);
  const challengeVault = vaults?.challengeVault;
  const vaultsById = vaults?.byId || {};
  const nonChallengeVaults = Object.values(vaultsById).filter(
    (vault) => vault?.id !== challengeVault?.id
  );
  const nonChallengeVaultsWithGameInfo = nonChallengeVaults.map((vault) => {
    const gamesById = games?.byId || {};
    const game = gamesById[vault.gameId];
    return {
      vault,
      game,
    };
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchChallengeGameVault());
    dispatch(fetchVaults());
  }, [dispatch]);

  const onClickHandler = (e, vaultId) => {
    e.preventDefault();
    history.push(`/vaults/${vaultId}`);
  };

  return (
    <ProfilePaper title="Vaults" expanding>
      <Grid container item xs={12} spacing={2}>
        {challengeVault && (
          <Grid container item xl={4} lg={6} md={4} sm={6} xs={12}>
            <SteamPoints
              onClickHandler={onClickHandler}
              challengeVault={challengeVault}
            />
          </Grid>
        )}
        {nonChallengeVaultsWithGameInfo.length > 0 && (
          <VaultPoints
            onClickHandler={onClickHandler}
            list={nonChallengeVaultsWithGameInfo}
          />
        )}
        {!!listCraftPack && (
          <Grid container item xl={4} lg={6} md={4} sm={6} xs={12}>
            <CraftPack list={listCraftPack} />
          </Grid>
        )}
      </Grid>
    </ProfilePaper>
  );
});

export default Vaults;
