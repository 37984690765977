import {
  WEB3_BALANCE,
  WEB3_BAD_NETWORK,
  WEB3_GAS_PRICE,
  WEB3_ETH_BALANCE,
  WEB3_RECENT_DEPOSITS,
  WEB3_RECENT_WITHDRAWALS,
  WEB3_RECENT_TRANSACTIONS,
  WEB3_WALLET_COUNT,
  WEB3_WALLET_GASCOST,
  WEB3_WALLET_CREATE,
  WEB3_SAVE_ADDRESS,
  WEB3_HAS_WALLET,
  WEB3_WALLET_ERROR,
  WEB3_CLEAR_ERRORS,
  WEB3_WALLET_SEND_INFO,
  WEB3_WALLET_ALLOW_INFO,
  WEB3_CONVERSION_ALLOWANCE,
  WEB3_CONNECTED,
  WEB3_BURN_AMOUNT,
  WEB3_TOTAL_STAKED,
  WEB3_GXP_REQUIREMENTS,
  WEB3_NEEDS_GXP,
} from "../actions/types";

const web3Reducer = (
  state = {
    gasPrice: 0,
    ethBalance: 0,
    conversionAllowance: null,
    yieldAllowance: 0,
    balance: 0,
    connected: "",
    badNetwork: false,
    totalStaked: 0,
    allowances: {},
    GXPRequirements: null,
    needsGXP: true,
  },
  action
) => {
  switch (action.type) {
    case WEB3_BALANCE: {
      return { ...state, balance: action.balance };
    }
    case WEB3_ETH_BALANCE: {
      return { ...state, ethBalance: action.ethBalance };
    }
    case WEB3_GAS_PRICE: {
      return { ...state, gasPrice: action.gasPrice };
    }
    case WEB3_RECENT_DEPOSITS: {
      return { ...state, recentDeposits: action.recentDeposits };
    }
    case WEB3_RECENT_WITHDRAWALS: {
      return { ...state, recentWithdrawals: action.recentWithdrawals };
    }
    case WEB3_RECENT_TRANSACTIONS: {
      return {
        ...state,
        recentTransactions: action.recentTransactions,
        totalDeposits: action.totalDeposits,
        totalWithdrawals: action.totalWithdrawals,
      };
    }
    case WEB3_BURN_AMOUNT: {
      return {
        ...state,
        burnAmount: action.amount,
      };
    }
    case WEB3_WALLET_SEND_INFO: {
      return {
        ...state,
        tapWalletSendGasLimit: action.tapWalletSendGasLimit,
      };
    }
    case WEB3_WALLET_ALLOW_INFO: {
      return {
        ...state,
        tapWalletAllowGasLimit: action.tapWalletAllowGasLimit,
      };
    }
    case WEB3_CONVERSION_ALLOWANCE: {
      return {
        ...state,
        conversionAllowance: action.conversionAllowance,
      };
    }
    case WEB3_CONNECTED: {
      return {
        ...state,
        connected: action.connected || state.connected,
        chainId: action.chainId || state.chainId,
        badNetwork: false,
      };
    }
    case WEB3_BAD_NETWORK: {
      return {
        ...state,
        connected: action.connected || state.connected,
        badNetwork: true,
      };
    }
    case WEB3_WALLET_COUNT: {
      return {
        ...state,
        tapWalletCount: action.tapWalletCount,
      };
    }
    case WEB3_WALLET_GASCOST: {
      return {
        ...state,
        tapWalletGasLimit: action.tapWalletGasLimit,
      };
    }
    case WEB3_WALLET_CREATE: {
      return {
        ...state,
        newTapWallet: action.newTapWallet,
      };
    }
    case WEB3_SAVE_ADDRESS: {
      return {
        ...state,
        address: action.address,
      };
    }
    case WEB3_HAS_WALLET: {
      return {
        ...state,
        newTapWallet: action.tapWallet,
      };
    }
    case WEB3_WALLET_ERROR: {
      return {
        ...state,
        walletError: action.walletError,
      };
    }
    case WEB3_CLEAR_ERRORS: {
      return {
        ...state,
        walletError: false,
        keyError: false,
      };
    }
    case WEB3_TOTAL_STAKED: {
      return {
        ...state,
        totalStaked: action.totalStaked,
      };
    }
    case WEB3_GXP_REQUIREMENTS: {
      return {
        ...state,
        GXPRequirements: action.GXPRequirements,
      };
    }
    case WEB3_NEEDS_GXP: {
      return {
        ...state,
        needsGXP: action.needsGXP,
      };
    }
    default: {
      return state;
    }
  }
};

export default web3Reducer;
