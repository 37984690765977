import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import colors from "utils/colors";
import LoadingSpinner from "components/LoadingSpinner";
import Text from "components/Text";

const LoadingScreen = styled.div`
  position: fixed;
  background: ${colors.defaultBackground};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1500;
  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
`;

const INIT_STATE = {
  shouldRender: false,
  fadingOut: false,
};
class FullScreenLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INIT_STATE,
    };
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const { somethingLoading } = this.props;
    const { shouldRender } = this.state;
    if (somethingLoading && !shouldRender) {
      this.setState({
        shouldRender: true,
        fadingOut: false,
      });
      return null;
    }
    if (!somethingLoading && prevProps.somethingLoading) {
      this.setState({
        shouldRender: true,
        fadingOut: true,
      });
      this.timer = setTimeout(() => {
        this.setState({
          shouldRender: false,
          fadingOut: false,
        });
      }, 1000);
      return null;
    }
    return null;
  }
  /* eslint-enable react/no-did-update-set-state */

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { fadingOut, shouldRender } = this.state;
    return (
      shouldRender && (
        <LoadingScreen fadeOut={fadingOut}>
          <LoadingSpinner size="xlarge" responsive />
          <div className="mt--40">
            <Text variant="h6">Putting Everything Together..</Text>
            <Text variant="h6">..We&#x27;ll be ready in a second..</Text>
          </div>
        </LoadingScreen>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  somethingLoading: Object.values(state.loading.fullScreen).reduce(
    (value, nxt) =>
      // if any value is true than something is loading and therefore we show the loading screen
      value || nxt,
    false
  ),
});

export default connect(mapStateToProps)(FullScreenLoading);
