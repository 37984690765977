import { REMOVE_OFFER, GET_OFFERS_AND_REPLACE } from "./types";

export const initialState = {
  menu: [],
  info: [],
  offers: [],
};

const MenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS_AND_REPLACE:
      return {
        ...state,
        offers: action.offers,
      };
    case REMOVE_OFFER:
      return {
        ...state,
        offers: state.offers.filter((el) => el.id !== action.id),
      };
    default:
      return state;
  }
};

export default MenuReducer;
