import styled from "styled-components";
import breakpoints from "utils/breakpoints";
import { Paper, Button, Badge } from "@material-ui/core";
import { GameDescription, GameName } from "../Dashboard/styles";
import Check from "@material-ui/icons/Check";
import { Flex } from "styles";

export const ProfileHeaderWrapper = styled.div`
  margin: 0 0 50px 0;
`;

export const WrapperHeaderWrapper = styled.div`
  margin: 0 0 30px 0;
`;

export const CurrencyCardWrapper = styled(Paper)`
  box-sizing: border-box;
  width: 100%;
  height: max-content;
  border-radius: 25px !important;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;

export const CurrencyCardImageWrapper = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 20px 20px 0 0 !important;
  background: #29303c !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`;

export const CurrencyCardImage = styled.div`
  width: 100px !important;
  height: 100px !important;
  margin: auto;

  & img {
    width: 100%;
    align-self: center;
  }
`;

export const CurrencyCardTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  margin: 0 0 10px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CurrencyCardRow = styled.div`
  display: flex;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  justify-content: center;
  margin: 10px 0;
`;

export const ChallengeName = styled(GameName)`
  line-height: 24px;
  margin-bottom: 5px;
`;

export const CurrencyFlexGrid = styled(Flex)`
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center;
  }
`;

export const CraftPackInfo = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #abb5be;
  margin-right: -20px;
`;

export const Follow = styled(Paper)`
  display: flex;
  max-width: 900px;
  height: 160px;
  border-radius: 25px !important;
  background-color: gray;
  padding: 20px 40px;
  align-items: center;
`;

export const FollowAvatar = styled.div`
  position: absolute;
  z-index: 1;

  & img {
    width: 180px;
    border-radius: 100px;
    padding: 15px;
  }

  @media (max-width: 960px) {
    position: relative;
    & img {
      width: 100%;
    }
  }
`;

export const AvatarWrapper = styled.div`
  margin-left: 50px;
  margin-right: 30px;
  width: 180px;
  max-width: 180px;

  @media (max-width: 960px) {
    margin: auto;
    width: 40vw;
  }
`;

export const ProfileHeaderContent = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  z-index: 0;

  @media (max-width: 960px) {
    flex-direction: column;
    margin: 0 auto 30px;
  }
`;

export const BottomBarContent = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 80px;
  justify-content: flex-start;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const FollowButton = styled(Button)`
  font-size: 14px;
  border-radius: 15px !important;
  height: 50px;
  width: 100px;
  margin-right: 60px !important;
`;

export const FollowSubTextWrapper = styled(Flex)``;

export const FollowTextWrapper = styled.div`
  display: grid;
  align-content: center;
`;

export const FollowName = styled.h2`
  font-size: 42px;
  margin: 5px;
  font-family: "Poppins";
`;

export const FollowSubText = styled.h4`
  margin: 0px 5px;
  font-weight: 400;
  display: inherit;
  font-family: "Poppins";
`;

export const FollowBoldSubText = styled.h4`
  font-weight: 700;
  margin: 0px 5px;
`;

export const FollowBadge = styled(Badge)`
  & .MuiBadge-badge {
    min-width: 30px;
    height: 30px;
    background: #7fba7a;
    border-radius: 25px;
    box-shadow: 0 0 0 3px #23272b;
  }
`;

export const FollowFlexWrapper = styled(Flex)`
  flex: 1;
`;

export const StyledCheck = styled(Check)`
  stroke: white;
  stroke-width: 2;
`;

export const FollowCheckBadge = styled(Badge)`
  & .MuiBadge-badge {
    min-width: 40px;
    height: 40px;
    background: #7fba7a;
    border-radius: 25px;
    top: 35px;
    right: -30px;
  }
`;

export const BottomBar = styled.div`
  background-color: #452191;
  z-index: 0;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "")};
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -180px 130px 40px #000000;
  border-radius: 20px;
  top: 0;
  left: 0;
`;

export const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 16%;

    @media (max-width: 960px) {
      padding-top: 2%;
    }
  }

  & > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const ImgText = styled.div`
  font-weight: 500;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  max-width: 700px;
  z-index: 1;
  padding-bottom: 110px;
  padding-left: 64px;

  @media (max-width: 1024px) {
    padding-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;
    font-size: 24px;
    padding-left: 30px;
  }

  @media (max-width: 480px) {
    padding-bottom: 50px;
    font-size: 18px;
    padding-left: 30px;
  }
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 15px !important;
  padding: 20px 40px;
  box-shadow: none !important;

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  margin: auto 0;

  @media (max-width: 960px) {
    width: 50%;
  }
`;

export const Dot = styled.span`
  border-radius: 5px;
  height: 10px;
  width: 10px;
  padding: 0;
  margin: auto 5px;
  background-color: red;
  background-color: ${({ color }) => color ?? "#000"};
`;
