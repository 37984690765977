import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Snackbar as MUISnackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../actions";

const styles = (theme) => ({
  success: {
    backgroundColor: theme.palette.greenBase,
  },
  error: {
    backgroundColor: theme.palette.redBase,
  },
  info: {
    backgroundColor: theme.palette.primaryBase,
  },
  warning: {
    backgroundColor: "orange",
  },
  content: {
    justifyContent: "center",
  },
});

class Snackbar extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }

  handleClose() {
    this.props.closeSnackbar();
  }

  handleExited() {
    // auto clear snackbar content onClose
    this.props.clearSnackbarContent();
  }

  render() {
    if (!this.props.content) return null;
    const { message, variant, msgVariant } = this.props.content;
    return (
      <MUISnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.props.open}
        autoHideDuration={3500}
        onClose={this.handleClose}
        onExited={this.handleExited}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContent
          variant={variant}
          className={this.props.classes[msgVariant]}
          classes={{ root: this.props.classes.content }}
          message={message}
        />
      </MUISnackbar>
    );
  }
}

const mapState = (state) => ({
  open: state.snackbar.open,
  content: state.snackbar.content,
});

export default compose(connect(mapState, actions), withStyles(styles))(Snackbar);
