import React, { useEffect, useMemo, useState } from "react";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { signOut, steamGetCurrentUser } from "actions/index";
import TabPassword from "./TabPassword";
import TabProfile from "./TabProfile";
import { SelectItem, SelectWrap, TabsController, TabsRow } from "./style";

const TabsBody = (props) => {
  const [profile, setProfile] = useState(true);
  const [fetchSteam, setFetchSteam] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (
      !fetchSteam &&
      !user.steam_name &&
      user.gid_steam &&
      user.gid_steam.length == 17
    ) {
      setFetchSteam(true);
      dispatch(steamGetCurrentUser(user.gid_steam));
    }
  }, [user, fetchSteam, dispatch]);

  const switchProfile = () => {
    setProfile(true);
  };

  const switchPassword = () => {
    setProfile(false);
  };

  const logout = () => {
    dispatch(signOut());
  };

  const viewTab = useMemo(() => (profile ? <TabProfile /> : <TabPassword />), [profile]);
  return (
    <>
      <TabsRow>
        <TabsController active={profile} onClick={switchProfile}>
          Your Profile
        </TabsController>
        <TabsController active={!profile} onClick={switchPassword}>
          Password
        </TabsController>
        <TabsController onClick={logout}>Logout</TabsController>
      </TabsRow>

      <SelectWrap>
        <SelectItem
          IconComponent={KeyboardArrowDownRoundedIcon}
          onChange={(e) => setProfile(e.target.value)}
          value={profile}
        >
          <MenuItem value>Profile</MenuItem>
          <MenuItem value={false}>Password</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </SelectItem>
      </SelectWrap>
      <div>{viewTab}</div>
    </>
  );
};

export default TabsBody;
