export default function NumbersFormat(num, size) {
  if (!num) return "0";

  const numString = num.toString();
  let string = numString;
  const index = numString.search(/[A-Z]/g);
  const a = index === -1 ? "" : numString.slice(index);

  if (index !== -1) {
    string = numString.slice(0, index);
  }

  if (!string?.toString()?.length) return "";
  // Nine Zeroes for Billions
  return (
    (Math.abs(Number(string)) >= 1.0e9
      ? parseFloat((Math.abs(Number(string)) / 1.0e9).toFixed(2)) + "B " + a
      : // Six Zeroes for Millions
      Math.abs(Number(string)) >= 1.0e6
      ? parseFloat((Math.abs(Number(string)) / 1.0e6).toFixed(2)) + "M " + a
      : // Three Zeroes for Thousands
      Math.abs(Number(string)) >= (size === "k" ? 1.0e3 : 1.0e5)
      ? parseFloat((Math.abs(Number(string)) / 1.0e3).toFixed(2)) + "K " + a
      : parseFloat(Math.abs(Number(string)).toFixed(2))
    ).toString() +
    " " +
    a
  );
}
