import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Close } from "images/close.svg";
import { connect } from "react-redux";
import { signIn } from "actions/user";
import InputForm from "../../inputs/InputForm";
import ButtonForm from "../../buttons/ButtonForm";
import { ButtonClose, FormLink, InputContainer } from "./styles";

const Form = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState("");
  const [values, setValues] = useState({
    name: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handler = (name) => (e) => {
    setValues((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const closeHandler = () => {
    history.push("/dashboard");
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    props
      .signIn(values.name, values.password)
      .then(() => {
        setLoader(false);
        if (history.location.from == "/sign-in") {
          history.push("/dashboard");
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        setError(err);
        setLoader(false);
      });
  };

  return (
    <>
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>
      <FormLink>
        <span>
          New user? <Link to="/sign-up">Create an account</Link>
        </span>
        <br />
        <br />
        <Link to="/forgot">Forgot your password?</Link>
      </FormLink>

      <form onSubmit={formSubmit}>
        <InputContainer>
          <InputForm
            label="Username"
            autoComplete="username"
            value={values.name}
            handler={handler("name")}
            name="name"
            required
          />

          <InputForm
            label="Password"
            type="password"
            autoComplete="current-password"
            value={values.password}
            handler={handler("password")}
            name="password"
          />
        </InputContainer>

        <ButtonForm
          disabled={!(values.password && values.name) || loader}
          loader={loader}
          onClick={() => signIn(values.name, values.password)}
        />
        <Typography align="center">{error}</Typography>
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { signIn })(Form);
