import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getDisplayVal, doBigMath } from "blockchain/web3Utils";
import Info from "components/ContentElements/info";
import CardContainer from "components/containers/CardContainer";
import { getGXPRequirements } from "actions/web3";
import { Actions, StakingButton } from "./styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
    backgroundColor: theme.palette.text.secondary,
  },
  heading: {
    fontSize: "18px",
    fontFamily: "Work Sans",
    color: theme.palette.text.secondary,
    "& .MuiTypography-root": {
      color: theme.palette.text.secondary,
    },
  },
  stakeButton: {
    fontSize: "18px",
    width: "3.5rem",
    height: "3.5rem",
  },
  claimButton: {
    fontSize: "18px",
    height: "3.5rem",
    padding: "0px 40px 0px 40px",
  },
  description: {
    "& .MuiTypography-root": {
      lineHeight: "30px",
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: theme.typography.fontWeightLight,
    },
    "& .MuiTypography-body2": {
      fontSize: "16px",
      fontFamily: "Poppins",
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .MuiTypography-caption": {
      fontSize: "16px",
      color: theme.palette.text.secondary,
    },
  },
  accordian: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
  },
  accordianSummary: {
    padding: 0,
    margin: 0,
    "&.MuiAccordionSummary-root": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "auto",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
      "& svg": {
        fontSize: "18px",
      },
    },
  },
  accordianDetails: {
    padding: 0,
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontFamily: "Work Sans",
      color: theme.palette.text.secondary,
    },
  },
  expandIcon: {
    padding: 0,
  },
}));

const List = ({
  list = [],
  detailsHandler,
  stakeHandler,
  connectHandler,
  connected,
  yieldBalance,
  expectedReward,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const web3 = useSelector((state) => state.web3);
  const currencies = useSelector((state) => state.currency);
  const { needsGXP } = web3;

  useEffect(() => {
    dispatch(getGXPRequirements(web3.connected));
  }, [web3.chainId, web3.connected]);

  return list.map(({ id, name, data, info, realIndex, ...stake }, index) => {
    const calculateAPR = () => {
      if (!stake.rewards) {
        return "Unlock Wallet";
      }
      if (stake.secondaryToken.amount < 2) {
        return "??? %";
      }
      if (stake.rewards.rewardsPerCycle === "0") {
        return "Pool Inactive";
      }
      const hoursPerYear = 8766;
      const cyclesPerYear = (1 / stake.hoursPerCycle) * hoursPerYear;

      const totalForYear = doBigMath(
        stake.rewards.rewardsPerCycle,
        "*",
        cyclesPerYear
      ).toString();
      const totalForToken = doBigMath(
        totalForYear,
        "/",
        Math.max(stake.NFTsStaked, 1)
      ).toString();

      const secondaryTokenPrice = currencies[stake.secondaryToken.address];
      const rewardTokenPrice = currencies[stake.rewardToken.address];
      if (secondaryTokenPrice && rewardTokenPrice) {
        const ratio = rewardTokenPrice.usd_price / secondaryTokenPrice.usd_price;
        let secondaryTokenVal = 1;
        let rewardTokenVal = 1;
        if (stake.secondaryToken.decimals) {
          secondaryTokenVal = parseFloat(
            getDisplayVal(stake.secondaryToken.amount, stake.secondaryToken.decimals)
          );
        }
        if (stake.rewardToken.decimals) {
          rewardTokenVal = parseFloat(
            getDisplayVal(totalForToken, stake.rewardToken.decimals)
          );
        }

        const rewardRatio = (rewardTokenVal / (secondaryTokenVal * 2)) * ratio;

        const asPercent = rewardRatio * 100;

        return asPercent + "%";
      }
      const asPercent = doBigMath(
        doBigMath(totalForToken, "*", 100),
        "/",
        doBigMath(stake.secondaryToken.amount, "*", 2)
      ).toString();
      return asPercent + "%";
    };

    const image = stake.primaryToken?.image || stake.secondaryToken?.image || "";
    const deposit = `1 ${stake.primaryToken?.symbol || "???"} + ${
      stake.secondaryToken && stake.secondaryToken.decimals
        ? getDisplayVal(stake.secondaryToken.amount, stake.secondaryToken.decimals)
        : 1
    } ${stake.secondaryToken?.symbol}`;
    let earn =
      (stake.rewards &&
        (
          parseFloat(
            getDisplayVal(
              doBigMath(
                stake.rewards.rewardsPerCycle,
                "*",
                stake.rewardPeriodInCycles
              ).toString(),
              stake.rewardToken.decimals
            )
          ) / Math.max(parseInt(stake.NFTsStaked), 1)
        ).toFixed(2)) ||
      ("???" / stake.rewardPeriodInCycles) * stake.hoursPerCycle ||
      "???";
    earn += " " + stake.rewardToken?.symbol;
    const earned = stake.rewards
      ? getDisplayVal(stake.rewards.amount, stake.rewardToken.decimals)
      : "???";
    const tokenEarn = calculateAPR();
    const descriptionData = {
      "Deposit:": deposit,
      "Earn:": earn,
      "Earned:": earned,
      "Token % Earn Per Year:": tokenEarn,
    };

    const myDeposit =
      stake.secondaryToken &&
      parseInt(stake.userNFTsStaked) *
        2 *
        parseFloat(
          getDisplayVal(stake.secondaryToken.amount, stake.secondaryToken.decimals)
        );

    const tvl =
      stake.secondaryToken &&
      parseInt(stake.NFTsStaked) *
        2 *
        getDisplayVal(stake.secondaryToken.amount, stake.secondaryToken.decimals);

    const accordianData = {
      "My Deposit:": myDeposit,
      "TVL:": tvl,
      "Performance Fee:": (
        <span style={{ display: "flex", textAlign: "center" }}>
          <Info
            className="display--inline-block"
            text="An early withdrawal fee of 3.5% will be applied if you claim within 72 hours of staking. Once you've un-staked, a 24h cooldown is implemented before you can re-stake again in this pool."
          />{" "}
          <span style={{ marginTop: "3px", marginLeft: "8px" }}>2%</span>
        </span>
      ),
    };

    const renderButtons = () => {
      if (web3.connected) {
        if (web3.badNetwork) {
          return (
            <Button variant="contained" color="primary" fullWidth disabled>
              Check your network
            </Button>
          );
        }
        if (needsGXP) {
          return (
            <Button variant="contained" color="primary" fullWidth disabled>
              You must hold 2200 GXP in order to stake
            </Button>
          );
        }
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.claimButton}
              onClick={() => stakeHandler("claim", realIndex)}
            >
              Claim
            </Button>
            <Grid direction="row" container spacing={2} justifyContent="flex-end">
              <Grid item>
                <StakingButton
                  variant="contained"
                  color="primary"
                  className={classes.stakeButton}
                  onClick={() => stakeHandler("stake", realIndex)}
                >
                  +
                </StakingButton>
              </Grid>
              <Grid item>
                <StakingButton
                  variant="contained"
                  color="primary"
                  className={classes.stakeButton}
                  onClick={() => stakeHandler("unstake", realIndex)}
                >
                  -
                </StakingButton>
              </Grid>
            </Grid>
          </>
        );
      }
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={() => connectHandler()}
        >
          Unlock Wallet
        </Button>
      );
    };

    return (
      <CardContainer
        id={id}
        image={image}
        name={stake.primaryToken?.name}
        stake={stake}
        descriptionData={descriptionData}
        accordianData={accordianData}
      >
        <Actions>{renderButtons()}</Actions>
      </CardContainer>
    );
  });
};

export default List;
