import GameGridList from "components/GameGridList";
import { ContentName } from "components/Marketplace/styles";
import React from "react";
import List from "../index";
import SeasonalChallengesList from "./SeasonalChallengesList";

const SteamPCChallenges = ({ list }) => {
  if (list.length === 0) {
    return null;
  }
  return (
    <>
      <ContentName>Seasonal Challenges</ContentName>
      <GameGridList>
        <List list={list} link="/dashboard">
          {(nftCounts, Achievements) => (
            <SeasonalChallengesList nftCounts={nftCounts} Achievements={Achievements} />
          )}
        </List>
      </GameGridList>
    </>
  );
};

export default SteamPCChallenges;
