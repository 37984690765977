import styled from "styled-components";
import { ButtonClose } from "../Modal/styles";

export const SettingsWrapper = styled.div`
  box-sizing: border-box;
  width: 628px;
  margin: 0 auto;
  background-color: #242731;
  border-radius: 24px;
  padding: 32px;
  position: relative;

  & h2 {
    padding: 0;
  }

  @media (max-width: 1024px) {
    width: 61.5vw;
  }

  @media (max-width: 576px) {
    width: 70vw;
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const SettingsClose = styled(ButtonClose)`
  top: 32px;
  right: 22px;
  height: 15px;
  width: 15px;

  & svg {
    width: 12px;
    height: 12px;
  }
`;
