import { useSelector } from "react-redux";
import { useCallback } from "react";

const useStakingList = (open) => {
  const list = useSelector(({ Staking }) => [
    ...Staking.stakingList,
    ...Staking.partnerList,
  ]);

  const getCurrent = useCallback(
    (id) => {
      return list.find((el) => el.id === id);
    },
    [list]
  );

  return { list, getCurrent };
};

export default useStakingList;
