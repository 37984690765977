import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ModalBodyContainer } from "../../Dashboard/Achievement/claimGamePoints/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    "&&": {
      backgroundColor: "#0F0F0FCC",
      backdropFilter: "blur(10px)",
      display: "flex",
      lineHeight: "19px",
      fontSize: "16px",
      fontFamily: "Inter sans-serif !important",
    },
  },
  close: {
    position: "absolute",
    top: "15px",
    left: "15px",
    zIndex: 10,
  },
  sidebar: {
    backgroundColor: "#23272B",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      height: "100%",
      flex: "0 0 500px",
    },
    padding: "0 15px",
  },
  detailsScroll: {
    flex: "1",
    overflowY: "scroll",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    padding: "40px 15px 15px",
    "& > *": {
      marginBottom: "20px",
    },
  },
  mobileName: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    padding: "40px 15px 15px",
    "& > *": {
      marginBottom: "20px",
    },
  },
  details: {
    minWidth: "100%",
    padding: "20px",
  },
  buttons: {
    "& > button": {
      flex: "1",
      padding: "10px",
      width: "100%",
      color: "gray",
      fontSize: "20px",
      borderBottom: "2px solid grey",
      borderRadius: "0px",
      transition: "none",
    },
  },
  active: {
    borderBottom: "2px solid goldenrod !important",
    color: "white !important",
  },
  NFTWrapper: {
    marginLeft: "auto",
    marginRight: "auto",
    alignContent: "center",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 64px)",
    },
    width: "85%",
    padding: "0 15px 0 15px",
  },
  NFT: {
    borderRadius: "25px",
    maxHeight: "80%",
    width: "auto",
    maxWidth: "100%",
    zIndex: 1,
  },
  verticalDivider: {
    backgroundColor: theme.palette.primary.main,
    width: "2px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nftsRemaining: {
    "& > *": {
      border: "1px solid white",
      padding: "8px",
      width: "max-content",
      flex: "1 1 0",
      textAlign: "center",
    },
    "& > :first-child": {
      color: "#000000",
      background: "white",
    },
    "& > :last-child": {
      color: "goldenrod",
    },
  },
  purchaseDetails: {
    minHeight: "200px",
    "& > :first-child": {
      marginBottom: "20px",
    },
  },
  tokenSymbol: {
    width: "35px",
  },
}));

export const ConvertModalContainer = styled(ModalBodyContainer)`
  margin: 3px 16px 10px 16px;
  padding: 0 !important;
`;
