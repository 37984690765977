import {
  GAME_DELETE,
  GAME_VIEW_LIST,
  GAME_VIEW,
  GAME_PUBLISHER_STATS,
  REMOVE_IMAGE,
  SDK_PASSWORD,
  GAME_PUBLISHER,
} from "../actions/types";

const initial = {
  ids: [],
  byId: {},
};

export default (state = initial, action) => {
  switch (action.type) {
    case GAME_VIEW_LIST: {
      return { ...action.games };
    }
    case GAME_VIEW: {
      return {
        ...state,
        currentGame: action.currentGame,
      };
    }
    case GAME_PUBLISHER: {
      return {
        ...state,
        currentPublisher: action.currentPublisher,
      };
    }
    case REMOVE_IMAGE: {
      const additionalImagesURL = [...state.currentGame.additional_images_url];
      additionalImagesURL.splice(action.index, 1);
      return {
        ...state,
        currentGame: {
          ...state.currentGame,
          additional_images_url: additionalImagesURL,
        },
      };
    }
    case GAME_PUBLISHER_STATS: {
      return {
        ...state,
        currentGameVault: {
          conversion: action.conversion,
          limits: action.limits,
          exportCount: action.exportCount,
          vaultCount: action.vaultCount,
          totalTTTConversion: action.totalTTTConversion,
          totalGameConversion: action.totalGameConversion,
          totalExportedEarnedCount: action.totalExportedEarnedCount,
          totalExportedPremiumCount: action.totalExportedPremiumCount,
          totalEarnedAllCount: action.totalEarnedAllCount,
          totalPremiumAllCount: action.totalPremiumAllCount,
          totalConvertedEarnedCount: action.totalConvertedEarnedCount,
          totalConvertedPremiumCount: action.totalConvertedPremiumCount,
        },
      };
    }
    case SDK_PASSWORD:
      return {
        ...state,
        currentGame: {
          ...state.currentGame,
          has_password: action.has_password,
          sdk_password: action.sdk_password || 0,
        },
      };
    // case GAME_CREATE: {
    //   return state;
    // }
    // case GAME_EDIT: {
    //   return state;
    // }
    case GAME_DELETE: {
      const { id } = action.game;
      const copy = { ...state.byId };
      delete copy[id];

      return {
        ...state,
        byId: copy,
        ids: state.ids.filter((i) => i !== id),
      };
    }

    default: {
      return state;
    }
  }
};
