import styled from "styled-components";

export const LoaderCycle = styled.div`
  border: 2px solid #f3f3f3 !important;
  border-top: 2px solid #3498db !important;
  border-radius: 50% !important;
  width: 12px !important;
  height: 12px !important;
  animation: spin 1s linear infinite !important;
  margin-left: 5px !important;
  padding: 7px !important;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
