import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  DialogActions,
  Dialog,
  FormHelperText,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import Text from "components/Text";
import { ReactComponent as Close } from "images/close.svg";
import DarkCoin from "images/header/GXP.png";
import { getDisplayVal } from "blockchain";
import { TOKEN_TYPES } from "blockchain/tokenInfo";
import Info from "components/ContentElements/info";
import Loader from "components/loaders";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import { BNGTECompare } from "blockchain/web3Utils";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";

const StakeModal = ({
  open,
  buttonLoading,
  fromValue,
  handlerChangeValue,
  closeHandler,
  successHandler,
  count,
  currency,
  yieldAllowance,
  balance,
  amountError,
  index,
  message,
  ...props
}) => {
  const modal = FormStyled();
  const classes = useStyles();

  const stakeInfo = useSelector((state) => state.Staking.stakingList[index]);
  const { contract, secondaryToken, primaryToken, id, type } = stakeInfo;
  const tokenAddr = secondaryToken.address;
  const nftAddr = primaryToken.address;
  const stakeAllowance = useSelector(
    (state) => state.allowances[tokenAddr]?.[contract] || 0
  );
  const secondaryBalance = useSelector((state) => state.balances[tokenAddr] || 0);
  const hasEnough = BNGTECompare(secondaryBalance, secondaryToken.amount);
  const allowsEnough = BNGTECompare(stakeAllowance, secondaryToken.amount);
  const primaryBalance = useSelector((state) => state.balances[nftAddr] || 0);
  const handlerInfo = {
    token: tokenAddr,
    nftAddr,
    balance: secondaryBalance,
    allowance: stakeAllowance,
    stakeAddress: contract,
    stakeId: id,
    type,
  };

  const renderFormHelper = () => {
    if (type === TOKEN_TYPES.ERC721) {
      return `${primaryBalance} ${primaryToken.symbol} &
      ${
        secondaryBalance
          ? getDisplayVal(secondaryBalance, secondaryToken.decimals, true)
          : 0
      } ${secondaryToken.symbol}`;
    }
    return `${
      secondaryBalance
        ? getDisplayVal(secondaryBalance, secondaryToken.decimals, true)
        : 0
    } ${secondaryToken.symbol}`;
  };

  const renderFormPlaceholderHelper = () => {
    if (type === TOKEN_TYPES.ERC721) {
      return `1 ${primaryToken.symbol} + ${getDisplayVal(
        secondaryToken.amount,
        secondaryToken.decimals
      )} ${secondaryToken.symbol}`;
    }
  };

  const renderStakeButton = () => {
    if (allowsEnough && hasEnough) {
      return (
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => successHandler("stake", handlerInfo)}
          disabled={primaryBalance <= 0 || buttonLoading || amountError}
        >
          {primaryBalance == 0 ? "No NFTs to stake" : amountError || "Stake"}
          {buttonLoading && <Loader />}
        </Button>
      );
    }
    return (
      <Button
        className={classes.button}
        size="large"
        color="primary"
        variant="contained"
        onClick={() => successHandler("approve", handlerInfo)}
        disabled={!hasEnough || primaryBalance <= 0 || buttonLoading}
      >
        {hasEnough
          ? primaryBalance == 0
            ? "No NFTs to stake"
            : "Approve"
          : `Not enough ${secondaryToken.symbol}`}
        {buttonLoading && <Loader />}
      </Button>
    );
  };
  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>
        Stake <Info text="1 NFP and equivalent GXP value are added each time you stake" />
      </DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid className="stakecard--bottomtextalign" item xs={12}>
            <div className="cardcontent-wrapper">
              {primaryBalance > 0 &&
                allowsEnough > 0 &&
                secondaryBalance > secondaryToken.amount && (
                  <FormControl variant="filled" className={classes.root}>
                    <OutlinedInput
                      id="inputFrom"
                      value={fromValue}
                      placeholder={
                        type === TOKEN_TYPES.ERC721 ? renderFormPlaceholderHelper() : 0
                      }
                      onChange={(e) => handlerChangeValue(e, "stake")}
                      endAdornment={
                        type !== TOKEN_TYPES.ERC721 && (
                          <>
                            <InputAdornment
                              className={classes.endAdornment}
                              position="end"
                              onClick={(e) => handlerChangeValue(e, "max")}
                            >
                              Use Max
                            </InputAdornment>
                            <InputAdornment
                              className={classes.endAdornmentAfter}
                              position="end"
                            >
                              <img width="32" src={DarkCoin} alt="" />
                            </InputAdornment>
                          </>
                        )
                      }
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      disabled={type === TOKEN_TYPES.ERC721}
                      className={`${classes.input}
                    ${amountError && classes.error}`}
                      labelWidth={0}
                    />
                    <FormHelperText>
                      <Text variant="p2" className={classes.balance}>
                        Balance: {renderFormHelper()}
                      </Text>
                    </FormHelperText>
                  </FormControl>
                )}
              <Text variant="p2">{message}</Text>
            </div>
          </Grid>
        </Grid>
      </ConvertModalContainer>

      <DialogActions>{renderStakeButton()}</DialogActions>
    </Dialog>
  );
};

export default StakeModal;
