import React from "react";
import { useSelector } from "react-redux";
import Steps from "../index";
import { ImgWrapper, Message, Name } from "../styles";
import img from "../../../../../images/signUp/step1/GXPBottle.jpg";
import * as links from "utils/links";

const Step1 = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  const changeStepHandler = (value) => {
    setActiveStep(value, true);
  };

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      setActiveStep={changeStepHandler}
      getLater
      link={links.BUY_GXP_XCHANGE_LINK}
      text="Buy 2200 GXP"
      current={3}
    >
      <ImgWrapper>
        <img src={img} alt="game x change" />
      </ImgWrapper>

      <Name>Step 1. Buy GXP</Name>

      <Message>
        Each wallet is required to hold 2200 GXP tokens in order to use any of the
        features on the Game X Change platform. This will allow you to purchase from the
        Marketplace, Convert game currency, stake tokens and receive rewards!
      </Message>
    </Steps>
  );
});

export default Step1;
