import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import {
  Name,
  FlexColumn,
  Subtitle,
  ContainerItemHeader,
  ContainerItemContent,
  ContainerItemFooter,
  ContainerItem,
} from "components/Exchange/styles";
import LiquidityAccordian from "./LiquidityAccordian";
import GlobalSettings from "../GlobalSettings";

const Liquidity = ({ userPairs, web3 }) => {
  const history = useHistory();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!web3.connected || !!web3.badNetwork) {
      setMessage("Connect to a wallet to view your liquidity.");
    } else if (userPairs.length < 1) {
      setMessage("No liquidity found.");
    } else {
      setMessage("");
    }
  }, [web3.badNetwork, web3.connected, web3.chainId, userPairs]);

  return (
    <ContainerItem elevation={3}>
      <ContainerItemHeader>
        <FlexColumn>
          <Name>Your Liquidity</Name>
          <Subtitle>Remove liquidity to receive tokens back</Subtitle>
        </FlexColumn>
        <GlobalSettings />
      </ContainerItemHeader>
      <ContainerItemContent
        style={{ backgroundColor: "#100c18", borderBottom: "1px solid #999ba3" }}
      >
        {userPairs &&
          userPairs.map((pair) => (
            <LiquidityAccordian key={pair.liquidityToken.address} pair={pair} />
          ))}
        {!!message && (
          <div style={{ textAlign: "center" }}>
            <Typography variant="subtitle2" noWrap>
              {message}
            </Typography>
          </div>
        )}
      </ContainerItemContent>
      <ContainerItemFooter>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => history.push("/xchange/add")}
          style={{ marginTop: "20px" }}
        >
          + Add Liquidity
        </Button>
      </ContainerItemFooter>
    </ContainerItem>
  );
};

export default Liquidity;
