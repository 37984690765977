import React, { memo } from "react";
import { Typography } from "@material-ui/core";

const MenuText = memo(({ children, open }) => {
  if (!open) {
    return null;
  }
  return (
    <Typography style={{ fontWeight: 600, fontSize: "14px" }}>{children}</Typography>
  );
});

export default MenuText;
