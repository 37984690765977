import React, { useEffect, useState } from "react";
import { Button, Grid, DialogActions, Dialog, Typography } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Loader from "components/loaders";
import { getTransactionReceiptMined } from "utils/web3Calls";
import { addLiquidity } from "utils/web3/exchange";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import { getBalanceOf, getAllowanceOf } from "actions";
import { useDispatch } from "react-redux";
import { Routers } from "blockchain/tokenInfo";
import GXPBottle from "assets/icons/GXP.png";
import {
  LiquidityDescription,
  CurrencyText,
  CurrencyImage,
  Descriptions,
  Description,
  AutoGrid,
} from "components/Exchange/styles";
import { useUserSlippageTolerance } from "blockchain/gasPrices";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";

const AddModal = ({ amounts, pair, share, open, closeHandler }) => {
  const dispatch = useDispatch();
  const modal = FormStyled();
  const classes = useStyles();
  const realAmounts = pair.reversePair ? [...amounts].reverse() : amounts;

  const { token0, token1 } = pair;
  const fixed0 = parseFloat(pair?.reserve0?.toFixed());
  const fixed1 = parseFloat(pair?.reserve1?.toFixed());
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance();
  // get total supplu, then use share to figure out how many a user will get

  const [loading, setLoading] = useState(false);
  const [success, onSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      dispatch(getBalanceOf(token0.address));
      dispatch(getBalanceOf(token1.address));
      dispatch(getAllowanceOf(token0.address, Routers[token0.chainId]));
      dispatch(getAllowanceOf(token1.address, Routers[token1.chainId]));
      closeHandler("success");
    }
  }, [success]);

  let ratio = 1;

  if (fixed0 > 0 && fixed1 > 0) {
    ratio = fixed0 / fixed1;
    ratio = Math.round(ratio * 100000000000) / 100000000000;
  } else {
    ratio = Math.round((realAmounts[1] / realAmounts[0]) * 100) / 100;
  }

  const total = Math.floor(pair.balance.toFixed() * share) / 100;

  const priceData = {
    [`${token0.symbol} Deposited:`]: (
      <>
        {/*<CurrencyImage src={GXPBottle} alt="currency" />*/}
        <LiquidityDescription>{realAmounts[0]}</LiquidityDescription>
      </>
    ),
    [`${token1.symbol} Deposited:`]: (
      <>
        {/*<CurrencyImage src={GXPBottle} alt="currency" />*/}
        <LiquidityDescription>{realAmounts[1]}</LiquidityDescription>
      </>
    ),
    "Rates:": `1 ${token0.symbol} = ${ratio} ${token1.symbol}`,
    "": `1 ${token1.symbol} = ${Math.round((1 / ratio) * 10000) / 10000} ${
      token0.symbol
    }`,
    "Share of Pool:": `${share}%`,
  };

  const descriptions = (data) => {
    const res = [];

    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <CurrencyText>{key}</CurrencyText>
          <LiquidityDescription>{data[key]}</LiquidityDescription>
        </Description>
      );
    });

    return res;
  };

  const handler = async () => {
    setLoading(true);
    try {
      const hash = await addLiquidity(pair, realAmounts, userSlippageTolerance);
      const receipt = await getTransactionReceiptMined(hash);
      onSuccess(receipt);
    } catch {
      setLoading(false);
    }
  };

  const renderStakeButton = () => {
    return (
      <Button
        className={classes.button}
        size="large"
        color="primary"
        variant="contained"
        onClick={() => handler()}
        disabled={loading || success}
      >
        {success ? "Success!" : "Confirm Supply"}
        {loading && <Loader />}
      </Button>
    );
  };
  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>You will receive</DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid item xs={12}>
            <AutoGrid>
              <Typography variant="subtitle1">
                {total}{" "}
                {pair.reversePair
                  ? `${pair.token1.symbol}/${pair.token0.symbol}`
                  : `${pair.token0.symbol}/${pair.token1.symbol}`}{" "}
                Pool Tokens
              </Typography>
            </AutoGrid>
            <Descriptions>{descriptions(priceData)}</Descriptions>
          </Grid>
        </Grid>
      </ConvertModalContainer>

      <DialogActions>{renderStakeButton()}</DialogActions>
    </Dialog>
  );
};

export default AddModal;
