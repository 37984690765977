import axios from "axios";
import { IS_PROD, CURRENT_SEASON } from "utils/environments";
import { getBearerTokenFromLocalStorage } from "./bearerToken";

const createAxiosInstance = () => {
  let completeBearerToken = null;
  const localToken = getBearerTokenFromLocalStorage();
  if (localToken) {
    completeBearerToken = `Bearer ${localToken}`;
  }

  return axios.create({
    baseURL: IS_PROD ? "https://gxc-api.gamexchange.app" : "http://70.70.62.21:33336/",
    headers: {
      common: {
        Authorization: completeBearerToken,
      },
    },
  });
};

// Access
export const signIn = (username, password) =>
  createAxiosInstance().post("/web/v1/users/sign_in", {
    username,
    password,
  });

export const signOut = () => createAxiosInstance().delete("/web/v1/users/sign_out");
export const getCurrentUser = () => createAxiosInstance().get("/web/v1/users/current");
export const resetPassword = (token, password, confirmPassword) =>
  createAxiosInstance().patch(`/web/v1/users/resetPassword/${token}`, {
    password,
    password_confirm: confirmPassword,
  });
export const updateEmail = (id, session) =>
  createAxiosInstance().post(`/web/v1/users/${id}/update_email_address`, {
    session,
  });
export const updateUsername = (id, session) =>
  createAxiosInstance().post(`/web/v1/users/${id}/update_username`, {
    session,
  });
export const updateUser = (user) =>
  createAxiosInstance().patch(`/web/v1/users/updateMe`, user);
export const getReferral = () =>
  createAxiosInstance().get(`/web/v2/referrals/code/my_code`);
export const getConversionStats = () =>
  createAxiosInstance().get(`/web/v1/users/profile/myconversionstats`);
export const recoverPassword = (email) =>
  createAxiosInstance().post(`/web/v1/users/forgotPassword/`, {
    email_address: email,
  });
export const updatePassword = (password) =>
  createAxiosInstance().patch(
    "/web/v1/users/updatePassword/",
    { session: { password } },
    {
      headers: {
        Authorization: `Bearer ${getBearerTokenFromLocalStorage()}`,
      },
    }
  );
// gamification/levels
export const getLevelInfo = (id) => createAxiosInstance().get(`/web/v1/levels/${id}`);
// Currency price info by chain_id+address
export const getCurrencyChainPrice = (params) =>
  createAxiosInstance().get(`/web/v1/currency/fetch`, {
    params,
  });

// Registration
export const register = (user) =>
  createAxiosInstance().post("/web/v1/users/sign_up", user);
export const validUsername = (username) =>
  createAxiosInstance().get(`/web/v1/users/search/username/${username}`);
export const validEmail = (email) =>
  createAxiosInstance().get(`/web/v1/users/search/email/${email}`);
export const resendEmailValidation = () =>
  createAxiosInstance().post("/web/v1/users/account_welcome/resend");
export const verifyEmailToken = (token) =>
  createAxiosInstance().get(`/web/v1/users/account_welcome/${token}`);
export const validWalletAddress = (walletAddress) =>
  createAxiosInstance().get(`/web/v1/users/verifyethwalletaddress/${walletAddress}`);

// Redeem Codes
export const redeemCode = (code) =>
  createAxiosInstance().post("/sdk/v3/vaults/redeemablepoints", { code });

/*
 *  Publisher-specific calls
 */
export const registerPublisher = (user) =>
  createAxiosInstance().post("/web/v1/publisher/sign_up", { user });
export const registerCompanyInfo = (organization) =>
  createAxiosInstance().post("/web/v1/organizations/", { organization });
export const updateCompany = (id, data) =>
  createAxiosInstance().put(`/web/v1/organizations/${id}`, data);
export const fetchOrganization = () => createAxiosInstance().get(`/web/v1/organizations`);
export const updateOrganization = (id, data) =>
  createAxiosInstance().put(`/web/v1/organizations/${id}`, data);
export const fetchPubGames = (id) =>
  createAxiosInstance().get(`/web/v1/organizations/${id}/games`);
export const deleteSDKPassword = (id) =>
  createAxiosInstance().delete(`/web/v1/gamesecretkey/game/${id}`);
export const getSDKPassword = (id) =>
  createAxiosInstance().get(`/web/v1/gamesecretkey/game/${id}`);
export const hasSDPKassword = (id) =>
  createAxiosInstance().get(`/web/v1/games/search/${id}`);
export const createSDKPassword = (id, gameSecretKey) =>
  createAxiosInstance().post(`/web/v1/gamesecretkey/game/${id}`, {
    game_secret_key: gameSecretKey,
  });

// organization endpoints
export const pubInviteNewMember = (id, data) =>
  createAxiosInstance().post(`/web/v1/organizations/${id}/invite_member`, data);
export const cancelInviteNewMember = (orgId, inviteId) =>
  createAxiosInstance().delete(
    `/web/v1/organizations/${orgId}/cancel_invite_member/${inviteId}`
  );
export const resendInviteNewMember = (id, data) =>
  createAxiosInstance().post(`/web/v1/organizations/${id}/resend_invite_member`, data);
export const fetchOrganizationMembers = (id) =>
  createAxiosInstance().get(`/web/v1/organizations/${id}/member_info`);
export const updateOrganizationMember = (orgId, memberId, data) =>
  createAxiosInstance().put(
    `/web/v1/organizations/${orgId}/update_member_role/${memberId}`,
    data
  );
export const fetchOrgMemberInvites = (id) =>
  createAxiosInstance().get(`/web/v1/organizations/${id}/check_invite_status`);

/*
 *  Games
 */
export const fetchGames = () => createAxiosInstance().get("/web/v1/games/");
export const fetchGame = (id) => createAxiosInstance().get(`/web/v1/games/${id}`);
export const fetchGamePublisher = (id) =>
  createAxiosInstance().get(`web/v1/games/${id}/developer_info`);
export const fetchNTEGames = () => createAxiosInstance().get("/web/v1/games/nte");
export const createGame = (game) => createAxiosInstance().post("/web/v1/games", { game });
export const updateGame = (game) =>
  createAxiosInstance().put(`/web/v1/games/${game.id}`, { game });
export const deleteGame = (id) => createAxiosInstance().get(`/web/v1/games/${id}`);
export const createGameVault = (id, vault, type) =>
  createAxiosInstance().post(`/web/v1/${type}currencyvaults/game/${id}`, vault);
export const updateGameVault = (id, vault, type) =>
  createAxiosInstance().put(`/web/v1/${type}currencyvaults/game/${id}`, vault);

// Market feature
export const getMarketFeature = () =>
  createAxiosInstance().get("/web/v1/featureannouncements/latest");

/*
 *  Vaults
 */
export const fetchVaults = () =>
  createAxiosInstance().defaults.headers.common.Authorization !== "null"
    ? createAxiosInstance().get("/web/v1/vaults")
    : new Promise((resolve, reject) => setTimeout(reject, 10));
export const fetchGameVault = (gameId) =>
  createAxiosInstance().get(`/web/v1/vaults/user/${gameId}`);
export const fetchVault = (id) => createAxiosInstance().get(`/web/v1/vaults/${id}`);
export const createVault = (gameId) =>
  createAxiosInstance().post(`/web/v1/vaults/create/${gameId}`);
export const updateVault = (id) => createAxiosInstance().put(`/web/v1/vaults/${id}`);
export const deleteVault = (id) => createAxiosInstance().delete(`/web/v1/vaults/${id}`);
export const getVaultImports = (id) =>
  createAxiosInstance().get(`/web/v1/transactions/vault/${id}/in`);
export const getVaultExports = (id) =>
  createAxiosInstance().get(`/web/v1/transactions/vault/${id}/out`);
export const getVaultLimits = (id, type) =>
  createAxiosInstance().get(`/web/v1/${type}currencyvaults/game/${id}`);
export const getMyChallengeGameVault = () =>
  createAxiosInstance().get(`/web/v1/vaults/mychallengeGameVault`);
export const canUpgradeVault = (gameId) =>
  createAxiosInstance().get(`/web/v1/vaults/user/${gameId}/upgradeVault`);
export const upgradeVault = (gameId, chainId) =>
  createAxiosInstance().put(`/web/v1/vaults/user/${gameId}/upgradeVault`, { chainId });

// Conversion
export const getConversionFeeCount = (gameId, from, to, type) =>
  createAxiosInstance().get(
    `/web/v1/publishers/game/${gameId}/count/${from}/${to}/${type}`
  );

export const startFromTap = (chainId, amount, gameId, currencyId, tokenAddress) =>
  createAxiosInstance().post("/web/v1/conversion/from/start", {
    chainId,
    amount,
    gameId,
    currencyId,
    tokenAddress,
  });

export const convertFromTap = (
  hash,
  chainId,
  vaultId,
  currencyId,
  amount,
  contractAddress
) =>
  createAxiosInstance().post("/web/v1/conversion/from", {
    hash,
    chainId,
    vaultId,
    currencyId,
    amount,
    contractAddress,
  });

export const updateFromTap = (id, vaultId) =>
  createAxiosInstance()
    .post("/web/v1/conversion/from/finish", {
      id,
      vaultId,
    })
    .then((response) => response.data);

export const convertToTap = (vaultId, currencyId, amount, tokenAddress, chainId) =>
  createAxiosInstance().post("/web/v1/conversion/to", {
    vaultId,
    currencyId,
    amount,
    tokenAddress,
    chainId,
  });

export const updateToTap = (claimId, hash) =>
  createAxiosInstance()
    .post("/web/v1/conversion/to/finish", {
      claimId,
      hash,
    })
    .then((response) => response.data);

export const makeNFTClaim = (userId, gameId, achievementName) =>
  createAxiosInstance().post("/web/v1/steamNft/create", {
    userId,
    gameId,
    achievementName,
  });

export const claimNFT = async (trackerId, chainId, dismantle) => {
  return createAxiosInstance().post("/web/v1/steamNft/claim", {
    trackerId,
    chainId,
    dismantle,
  });
};

export const finishNFTClaim = async (claimId, hash) => {
  return createAxiosInstance().post("/web/v1/steamNft/finish", { claimId, hash });
};

// Todo,  /page/1/limit/10
export const getConversionClaims = (wallet, gameid) =>
  createAxiosInstance().get(
    `web/v2/claims/claim/wallet_address/${wallet}/game/${gameid}`
  );

export const getConversionClaimsPage = (wallet, gameid, page, limit) =>
  createAxiosInstance().get(
    `web/v2/claims/claim/wallet_address/${wallet}/game/${gameid}/page/${page}/limit/${limit}`
  );
export const getConversionClaimsCount = (wallet, gameid) =>
  createAxiosInstance().get(
    `web/v2/claims/claim/wallet_address/${wallet}/game/${gameid}/count`
  );

export const getGXPRequirements = () =>
  createAxiosInstance().get("web/v1/gxcrequirements");
/*
 * NFTs
 */
export const getUserUnclaimedNFTs = () =>
  createAxiosInstance().get(`web/v2/nft_trackers/unclaimed_undismantled`);
export const getUserNFTsByGame = (gameId) =>
  createAxiosInstance().get(`web/v2/nft_trackers/game/${gameId}`);

/*
 *  challenge info
 */
// seasonal
export const getUserSteamClaim = (id, gameid) =>
  createAxiosInstance().get(
    `/challenge/v1/seasonalchallengeclaims/users/${id}/steam/${gameid}/current_claim_value`
  );
export const makeUserSteamClaim = (gameid) =>
  createAxiosInstance().post(`/web/v1/seasonalchallengeuserclaims/${gameid}/default`);
export const getSeasonalGames = (season = CURRENT_SEASON) =>
  createAxiosInstance().get(`/web/v1/challengegames/seasonal/${season}/all`);
export const getSeasonalGameIds = (season = CURRENT_SEASON) =>
  createAxiosInstance().get(`web/v1/challengegames/seasonal/${season}/login_view`);
// permanent
export const getUserClaim = (id, gameid) =>
  createAxiosInstance().get(
    `/challenge/v1/permanentchallengeclaims/users/${id}/game/${gameid}/current_claim_value`
  );
export const makeUserClaim = (gameid) =>
  createAxiosInstance().post(`/web/v1/permanentchallengeuserclaims/${gameid}/default`);
export const getPermanentGames = () =>
  createAxiosInstance().get(`/web/v1/challengegames/permanent`);

// Leaderboards
export const fetchReferralLeaderboard = () =>
  createAxiosInstance().get(`/web/v2/referrals/leaderboard`);
export const fetchConversionLeaderboard = () =>
  createAxiosInstance().get(`/web/v1/transactions/leaderboard`);

// Vault info for publishers
export const getTotalVaultCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_vault_count`);
export const getStandardExportCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_standard_export_count`);
export const getBalancedExportCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_balanced_export_count`);
export const getTotalConversionTTTCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_conversion_ttt_count`);
export const getTotalConversionGameCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_conversion_game_count`);
export const getTotalExportedEarnedCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_exported_earned_count`);
export const getTotalExportedPremiumCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_exported_premium_count`);
export const getTotalConvertedEarnedCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_converted_earned_count`);
export const getTotalConvertedPremiumCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_converted_premium_count`);
export const getTotalEarnedAllCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_earned_all_vault_count`);
export const getTotalPremiumAllCount = (gameId) =>
  createAxiosInstance().get(`/web/v1/games/${gameId}/total_earned_all_vault_count`);

// Roles
export const fetchRoles = () => createAxiosInstance().get("/web/v1/roles");
export const fetchRole = (id) => createAxiosInstance().get(`/web/v1/roles/${id}`);
export const createRole = () => {};
export const updateRole = (id) => createAxiosInstance().get(`/web/v1/roles/${id}`);
export const deleteRole = (id) => createAxiosInstance().get(`/web/v1/roles/${id}`);

export const getOffers = () => createAxiosInstance().get("/web/v2/offers?device=desktop");
