import {
  VAULT_DELETED,
  VAULT_VIEW_LIST,
  CREATE_VAULT,
  CONVERSION_RATE,
  VAULT_GET,
  VAULT_TRANSACTIONS,
  VAULT_LIMITS,
  VAULT_FULL_INFO,
  VAULT_GET_CHALLENGE,
  VAULT_CLEAR,
  CONVERSION_STATS,
} from "../actions/types";

const initial = {
  ids: [],
  byId: {},
  challengeVault: {},
};

export default (state = initial, action) => {
  switch (action.type) {
    case VAULT_VIEW_LIST: {
      return {
        ...state,
        ids: action.vaults.ids,
        byId: action.vaults.byId,
      };
    }
    case CREATE_VAULT: {
      return {
        ...state,
        newVault: action.vault,
      };
    }
    case CONVERSION_RATE: {
      return {
        ...state,
        conversionRate: action.conversion,
      };
    }
    case VAULT_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.transactions,
      };
    }
    case VAULT_LIMITS: {
      return {
        ...state,
        vaultLimits: action.vaultLimits,
      };
    }
    case VAULT_DELETED: {
      return {
        ...state,
        vaultDeleted: action.vault,
      };
    }
    case VAULT_GET: {
      return {
        ...state,
        currentVault: action.currentVault,
      };
    }
    case VAULT_GET_CHALLENGE: {
      return {
        ...state,
        challengeVault: { ...action.data, id: action.data.id },
      };
    }
    case VAULT_FULL_INFO: {
      return {
        ...state,
        gameInfo: action.gameInfo,
        vaultInfo: action.vaultInfo,
        transactions: action.transactions,
        vaultLimits: action.vaultLimits,
        conversionRate: action.conversionRate,
        conversionFee: action.conversionFee,
      };
    }
    case VAULT_CLEAR: {
      return {
        ...initial,
      };
    }
    case CONVERSION_STATS: {
      return {
        ...state,
        conversion_stats: action.conversionStats,
      };
    }
    default: {
      return state;
    }
  }
};
