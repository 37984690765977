import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
import Torus from "@toruslabs/torus-embed";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";
import { Bitski } from "bitski";
import ethProvider from "eth-provider";
import TruffleContract from "@truffle/contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import MarketArtifact from "ethereum_abi/TapMarket.json";
import IERC721Artifact from "ethereum_abi/IERC721.json";
import IERC20Artifact from "ethereum_abi/IERC20.json";
import StargateArtifact from "ethereum_abi/Stargate.json";
import TapConversionArtifact from "ethereum_abi/TapConversion.json";
import PLTNFTArtifact from "ethereum_abi/PLTNFT.json";
import CommonStakingArtifact from "ethereum_abi/CommonStaking.json";
import ChainLinkArtifact from "ethereum_abi/ChainLink.json";
import SwapPairArtifact from "ethereum_abi/exchange/SwapPair.json";
import SwapFactoryArtifact from "ethereum_abi/exchange/SwapFactory.json";
import SwapRouterArtifact from "ethereum_abi/exchange/SwapRouter.json";
import Multicall2Artifact from "ethereum_abi/Multicall2.json";
import PaymentArtifact from "ethereum_abi/PaymentTracker.json";

import {
  TOKEN_INFO,
  TOKEN_TYPES,
  getTokenFromAddressByChain,
  PARTNER_TOKEN_RUN_ADDRESSES,
  Payments,
  ZERO_ADDRESS,
} from "blockchain/tokenInfo";
import { getDisplayVal, parseNetworkId } from "blockchain/web3Utils";
import { USDAddresses } from "./chainlinkInfo";
import { IS_PROD } from "./environments";

let web3Provider;
// gets metamask and other web3 injectors
let web3Connected = false;

if (typeof window.ethereum !== "undefined") {
  web3Provider = window.ethereum;
} else if (window.web3) {
  web3Provider = window.web3.currentProvider;
} else {
  web3Provider = new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org");
}

let currentAddress = false;
const fee = "710000000000000";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      //infuraId: "694414f07cdb4fc3ba9490e8933b1894",
      network: "binance",
      rpc: {
        56: "https://bsc-dataseed.binance.org",
      },
    },
  },
  authereum: {
    package: Authereum,
  },
  torus: {
    package: Torus,
  },
  frame: {
    package: ethProvider,
  },
  burnerconnect: {
    package: BurnerConnectProvider,
  },
  bitski: {
    package: Bitski,
    options: {
      clientId: "449b297e-6eee-4507-bb6c-14a8b8da5222",
      callbackUrl: IS_PROD
        ? "https://play.gamexchange.app/callback.html"
        : "http://localhost:3000/callback.html",
    },
  },
  /*
  mewconnect: {
    package: MewConnect,
    options: {
      infuraId: "694414f07cdb4fc3ba9490e8933b1894"
    }
  }
  */
};

const web3Modal = new Web3Modal({
  providerOptions,
  theme: {
    background: "#24262b",
    main: "#FFF",
    //border: "rgba(195, 195, 195, 0.14)",
    hover: "#222121",
  },
});

let web3 = new Web3(web3Provider);

export const getWeb3 = () => web3;

const Contracts = {
  CommonStaking: TruffleContract(CommonStakingArtifact),
  IERC20: TruffleContract(IERC20Artifact),
  IERC721: TruffleContract(IERC721Artifact),
  Stargate: TruffleContract(StargateArtifact),
  Market: TruffleContract(MarketArtifact),
  PLTNFT: TruffleContract(PLTNFTArtifact),
  TapConversion: TruffleContract(TapConversionArtifact),
  ChainLink: TruffleContract(ChainLinkArtifact),
  SwapPair: TruffleContract(SwapPairArtifact),
  SwapFactory: TruffleContract(SwapFactoryArtifact),
  SwapRouter: TruffleContract(SwapRouterArtifact),
  Multicall: TruffleContract(Multicall2Artifact),
  Payment: TruffleContract(PaymentArtifact),
};

export const getContract = (name) => Contracts[name];

export const getContractInstance = (name, address) => {
  const contract = Contracts[name];
  return contract.at(address);
};

export const avgBlocktime = 15;
const setTruffleProvider = () => {
  Object.keys(Contracts).forEach((key) => {
    Contracts[key].setProvider(web3Provider);
  });

  web3Connected = true;
};

setTruffleProvider();

// Wrapper stuff for web3 to use promises
const promisify = (inner) =>
  new Promise((resolve, reject) =>
    inner((err, res) => {
      if (err) {
        reject(err);
      }
      resolve(res);
    })
  );

export const isConnected = () => web3Connected;

export const checkConnection = () => {
  // Check if User is already connected by retrieving the accounts
  return web3?.eth?.getAccounts().then(async (addresses) =>
    web3?.eth?.getChainId().then((chainId) => {
      if (Array.isArray(addresses)) {
        if (addresses.length === 0) {
          return { address: "", chainId: parseNetworkId(chainId) };
        }
        [currentAddress] = addresses;
        return { address: addresses[0], chainId: parseNetworkId(chainId) };
      }
      const address = addresses;
      return { address, chainId: parseNetworkId(chainId) };
    })
  );
};

export const connect = async () => {
  await web3Modal.clearCachedProvider();
  localStorage.setItem("walletconnect", "");
  return web3Modal.connect().then((info) => {
    web3Provider = info;
    web3 = new Web3(info);
    setTruffleProvider();
    let realAddress = "";
    if (info.selectedAddress) realAddress = info.selectedAddress;
    if (info.address) realAddress = info.address;
    if (info.accounts?.[0]) realAddress = info.accounts?.[0];
    const chainId = info.chainId || info.network.chainId;
    currentAddress = realAddress;
    return {
      address: realAddress,
      chainId: parseNetworkId(chainId),
    };
  });
};

export const chainLinkPrice = async (symbol, chainId) => {
  if (USDAddresses?.[chainId]?.[symbol]) {
    const priceFeed = await Contracts.ChainLink.at(USDAddresses[chainId][symbol]);
    const roundData = await priceFeed.latestRoundData();
    const decimals = await priceFeed.decimals();
    return roundData.answer.toNumber() / Math.pow(10, decimals.toNumber());
  }
  throw new Error("Price not available on this network");
};

// listeners for address, network changes
/**
 * Listens for changes in the address the user is currently using
 * @param {*} listener
 */
export const networkAddressChange = (listener, dispatch) => {
  if (typeof web3Provider.on === "function") {
    web3Provider.on("accountsChanged", (addresses) => {
      [currentAddress] = addresses;
      dispatch(listener(addresses[0]));
    });
  }
};

/**
 * Listens for changes in the network the user is currently using
 * @param {*} listener
 */
export const networkChange = (listener, dispatch) => {
  if (typeof web3Provider.on === "function") {
    web3Provider.on("chainChanged", (chainId) =>
      dispatch(
        listener(
          chainId.includes("0x") ? parseInt(chainId.replace("0x", ""), 16) : chainId
        )
      )
    );
  }
};

export const fromWei = (number, unit) => {
  if (number) {
    const newNumber = web3.utils.fromWei(number, unit);
    return newNumber;
  }
  return 0;
};

export const toWei = (number, unit) => web3.utils.toWei(number, unit);

// Generic web3 functions

export const isAddress = (address) => web3.utils.isAddress(address);
export const currentNetwork = () =>
  web3.eth.getChainId().then((res) => {
    if (typeof res === "number") {
      return res;
    }
    return res.includes("0x") ? parseInt(res.replace("0x", ""), 16) : res;
  });

export const selectedAddress = () => {
  if (currentAddress) return currentAddress;
  if (web3Provider.selectedAddress) return web3Provider.selectedAddress;
  if (web3Provider.address) return web3Provider.address;
  if (web3Provider.accounts?.[0]) return web3Provider.accounts?.[0];
  return false;
};

export const getEthBalance = (account) =>
  promisify((cb) => web3.eth.getBalance(account, cb));
export const getGasPrice = () => promisify((cb) => web3.eth.getGasPrice(cb));
export const getBlockByNumber = (number) =>
  promisify((cb) => web3.eth.getBlock(number, cb));
export const getTransaction = (hash) =>
  promisify((cb) => web3.eth.getTransaction(hash, cb));
export const getTransactionReceipt = (hash) => web3.eth.getTransactionReceipt(hash);

export const getTransactionReceiptMined = (txHash, interval) => {
  const transactionReceiptAsync = (resolve, reject) => {
    web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
      if (error) {
        reject(error);
      } else if (receipt == null) {
        setTimeout(() => transactionReceiptAsync(resolve, reject), interval || 10000);
      } else {
        resolve(receipt);
      }
    });
  };
  if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync);
  }
  throw new Error("Invalid Type: " + txHash);
};

// Yield functions

export const getBalanceOf = (tokenAddress, userAddress) => {
  if (tokenAddress === ZERO_ADDRESS) {
    return getEthBalance(userAddress || selectedAddress());
  }
  return Contracts.IERC20.at(tokenAddress).then((instance) =>
    instance.balanceOf(userAddress || selectedAddress())
  );
};

export const getTotalSupplyOf = (tokenAddress) => {
  return Contracts.IERC20.at(tokenAddress).then((instance) => instance.totalSupply());
};

// Staking general
export const getAllowance = async (token, to, from) => {
  if (token === ZERO_ADDRESS) {
    return new Promise((resolve, reject) => {
      resolve("1000000000000000000000000");
    });
  }
  const instance = await Contracts.IERC20.at(token);
  const amount = instance.allowance(from || selectedAddress(), to).catch(() => {
    return Contracts.IERC721.at(token).then((instance2) =>
      instance2
        .isApprovedForAll(from || selectedAddress(), to)
        .then((res) => (res ? 1 : 0))
    );
  });
  return amount;
};

// ERC721 + ERC20 => ERC20 Staking
export const getStakePages = (stake, page = 1, length = 10, active = true) =>
  Contracts.CommonStaking.at(stake).then((instance) =>
    active
      ? instance.pagination(0, page, length, { from: selectedAddress() })
      : instance.inactivePagination(0, page, length, {
          from: selectedAddress(),
        })
  );

export const getStake = (stake, id) =>
  Contracts.CommonStaking.at(stake).then((instance) =>
    instance.single(id, { from: selectedAddress() })
  );

export const approveFor = async (tokenAddress, amount, toAddress, from) => {
  const erc20 = await Contracts.IERC20.at(tokenAddress);
  return new Promise((resolve, reject) => {
    erc20.approve
      .call(toAddress, amount, { from: from || selectedAddress() })
      .then(() => {
        erc20
          .approve(toAddress, amount, { from: from || selectedAddress() })
          .on("transactionHash", (hash) => {
            resolve(hash);
          })
          .on("error", (error) => {
            reject(error);
          });
      })
      .catch(async () => {
        const erc721 = await Contracts.IERC721.at(tokenAddress);
        erc721
          .setApprovalForAll(toAddress, true, { from: from || selectedAddress() })
          .on("transactionHash", (hash) => {
            resolve(hash);
          })
          .on("error", (error) => {
            reject(error);
          });
      });
  });
};

export const stakeERC721For20 = async (
  stakeAddress,
  stakeIndex,
  stakeTokenAddress,
  secondaryTokenAddress,
  fromAddress
) => {
  const from = fromAddress || selectedAddress();
  const tokenInstance = await Contracts.IERC721.at(stakeTokenAddress);
  const firstToken = await tokenInstance.tokenOfOwnerByIndex(from, "0");
  const tokenInstance2 = await Contracts.IERC721.at(secondaryTokenAddress);
  const secondaryToken = await tokenInstance2
    .tokenOfOwnerByIndex(from, "0")
    .catch(() => "0");
  const data = web3.eth.abi.encodeParameters(
    ["uint256", "uint256"],
    [stakeIndex, secondaryToken]
  );
  return new Promise((resolve, reject) =>
    tokenInstance.methods["safeTransferFrom(address,address,uint256,bytes)"](
      from,
      stakeAddress,
      firstToken,
      data,
      {
        from,
      }
    )
      .on("transactionHash", (hash) => {
        resolve(hash);
      })
      .on("error", (error) => {
        reject(error);
      })
  );
};

export const stakeOwnedTokens = async (stake, NFTAddress, owner) =>
  Contracts.CommonStaking.at(stake).then(async (instance) =>
    instance.ownedTokens(NFTAddress, owner || selectedAddress()).then((res) => res)
  );

export const unstake = async (stake, stakeIndex, tokenIndex, from) =>
  new Promise((resolve, reject) => {
    Contracts.CommonStaking.at(stake).then(async (instance) => {
      await instance
        .unstake(stakeIndex, tokenIndex, {
          from: from || selectedAddress(),
        })
        .on("transactionHash", (hash) => {
          resolve(hash);
        })
        .on("error", (error) => {
          reject(error);
        });
    });
  });

export const estimateRewards = async (stake, stakeIndex, address) => {
  const instance = await Contracts.CommonStaking.at(stake);
  return instance.estimateRewards.call(stakeIndex, 999, address || selectedAddress());
};

export const claimERC721For20Rewards = async (
  stake,
  stakeIndex,
  maxPeriods = 99,
  address
) =>
  new Promise((resolve, reject) => {
    Contracts.CommonStaking.at(stake).then(async (instance) => {
      await instance
        .claimRewards(stakeIndex, maxPeriods, { from: address || selectedAddress() })
        .on("transactionHash", (hash) => {
          resolve(hash);
        })
        .on("error", (error) => {
          reject(error);
        });
    });
  });

// Tap Conversion functions
export const getNonce = (contractAddress, tokenContract, from) =>
  new Promise((resolve, reject) => {
    Contracts.TapConversion.at(contractAddress).then(async (instance) => {
      const nonce = await instance.getNonce(tokenContract, from);
      resolve(nonce.toNumber());
    });
  });

export const startConversionFromTap = (
  contractAddress,
  tokenAddress,
  fromAddress,
  amount,
  publisher,
  publisherAmount,
  sig
) =>
  new Promise((resolve, reject) => {
    Contracts.TapConversion.at(contractAddress).then(async (instance) => {
      instance.transferTo
        .estimateGas(tokenAddress, amount, publisher, publisherAmount, sig, {
          from: fromAddress,
          value: fee,
        })
        .then((amnt) => {
          instance
            .transferTo(tokenAddress, amount, publisher, publisherAmount, sig, {
              from: fromAddress,
              value: fee,
              gasLimit: Math.floor(amnt * 1.2),
              gas: Math.floor(amnt * 1.2),
            })
            .on("transactionHash", (hash) => {
              resolve(hash);
            })
            .on("error", (error) => {
              reject(error);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  });

export const startConversionFromIngame = (
  contractAddress,
  tokenAddress,
  amount,
  publisher,
  publisherAmount,
  sig,
  fromAddress
) =>
  new Promise((resolve, reject) => {
    Contracts.TapConversion.at(contractAddress).then(async (instance) => {
      instance.transferFrom
        .estimateGas(tokenAddress, amount, publisher, publisherAmount, sig, {
          from: fromAddress,
          value: fee,
        })
        .then((amnt) => {
          instance
            .transferFrom(tokenAddress, amount, publisher, publisherAmount, sig, {
              from: fromAddress,
              value: fee,
              gasLimit: Math.floor(amnt * 1.2),
              gas: Math.floor(amnt * 1.2),
            })
            .on("transactionHash", (hash) => {
              resolve(hash);
            })
            .on("error", (error) => {
              reject(error);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  });

// Tapcoin functions
/**
 * Recieves a token from the Stargate
 * @param {tokenTypes} tokenType
 * @param {string} stargateAddress
 * @param {string} tokenAddress
 * @param {string} toAddress
 * @param {string} amount
 * @param {string} tokenId
 * @param {string} data
 * @param {string} signature
 * @param {string} afee
 * @returns
 */
export const stargateReceiveTokenType = (
  tokenType,
  stargateAddress,
  tokenAddress,
  toAddress,
  amount,
  tokenId,
  data,
  signature,
  afee
) =>
  new Promise(async (resolve, reject) => {
    const instance = await Contracts.Stargate.at(stargateAddress);
    let funcCall;
    switch (tokenType) {
      case TOKEN_TYPES.ERC20:
        funcCall = instance.receiveERC20(tokenAddress, toAddress, amount, signature, {
          from: toAddress,
          value: afee,
        });
        break;
      case TOKEN_TYPES.ERC1155:
        funcCall = instance.receiveERC1155(
          tokenAddress,
          toAddress,
          tokenId,
          amount,
          signature,
          {
            from: toAddress,
            value: afee,
          }
        );
        break;
      case TOKEN_TYPES.ERC721:
      default:
        funcCall = instance.receiveERC721(
          tokenAddress,
          toAddress,
          tokenId,
          data,
          signature,
          {
            from: toAddress,
            value: afee,
          }
        );

        break;
    }
    funcCall
      .on("transactionHash", (hash) => {
        resolve(hash);
      })
      .on("error", (error) => {
        reject(error);
      });
  });

// ERC721 functions
export const getTokenMetaDataURL = async (address, tokenId) => {
  var tokenMetadataURL = Contracts.IERC721.at(address).then((instance) =>
    instance.tokenURI(tokenId)
  );
  // use axios to call this URL and fetch token info as a JSON response
  return tokenMetadataURL;
};

/**
 * Fetches whats for sale from the market.
 * @param {uint256} start
 * @param {uint256} length
 * @returns {Object}
 */
export const fetchMarketListings = (start, length) =>
  Contracts.Market.deployed().then((instance) =>
    instance.getSales(0, start, length).then((response) => {
      const matchedResponse = [];

      response[1].forEach((element, index) => {
        matchedResponse[index] = {
          ...response[0][index],
          id: element.toNumber(),
          participationAmount: response[2][index].requiredOwnership,
          participationAddress: response[2][index].contractAddress,
        };
      });
      const newResponse = { listings: matchedResponse };
      return newResponse;
    })
  );

/**
 * Fetches a single NFT from the market.
 * @param {uint256} tokenId
 * @returns {Object} listing
 */
export const fetchTokenSales = (tokenId) =>
  Contracts.Market.deployed()
    .then((instance) =>
      instance._tokenSales(tokenId).then((tokenSale) => {
        return instance
          ._requiredOwnerships(tokenSale.requiredOwnershipIndex)
          .then((requiredOwnerships) => {
            const matchedResponse = {
              ...tokenSale,
              id: tokenId,
              participationAmount: requiredOwnerships.requiredOwnership,
              participationAddress: requiredOwnerships.contractAddress,
            };
            return matchedResponse;
          });
      })
    )
    .catch((e) => {
      console.error("Error deploying Market", e);
    });

/**
 * Buys something for sale from the Contracts.Market.
 * @param {uint256} saleId
 * @param {uint256} amount
 * @param {string} from
 */
export const buyFromMarket = (saleId, amount, from) =>
  new Promise((resolve, reject) => {
    Contracts.Market.deployed().then((instance) =>
      instance
        .buy(saleId, amount, { from })
        .on("transactionHash", (hash) => {
          resolve(hash);
        })
        .on("error", (error) => {
          reject(error);
        })
    );
  });

export const getMarketAddress = () =>
  Contracts.Market.deployed().then((instance) => instance.address);

// LTNFT function
/**
 * Mints a Limited Time NFT
 * @param {string} ltnftAddress
 * @param {string} id
 * @param {string} paymentToken
 * @param {string} paymentValue
 * @returns {Promise}
 */
export const mintLTNFT = (ltnftAddress, id, paymentToken, paymentValue) =>
  new Promise((resolve, reject) =>
    Contracts.PLTNFT.at(ltnftAddress).then((instance) =>
      instance
        .mint(selectedAddress(), id, {
          from: selectedAddress(),
          value:
            paymentToken === ZERO_ADDRESS
              ? web3.utils.toWei(paymentValue.toString(), "ether")
              : "0",
        })
        .on("transactionHash", (hash) => {
          resolve(hash);
        })
        .on("error", (error) => {
          reject(error);
        })
    )
  );

/**
 * Gets info about a Partner Limited Time NFT
 * @param {string} ltnftAddress
 * @param {string} id
 * @returns {Promise}
 */
export const getPLTNFTInfo = (name, chain, id) => {
  if (TOKEN_INFO[name]) {
    const ltnftAddress = PARTNER_TOKEN_RUN_ADDRESSES[chain];
    return Contracts.PLTNFT.at(ltnftAddress)
      .then((instance) =>
        instance.getTokenRunInfo(id).then(async (result) => {
          var chainId = await currentNetwork();
          const buyWith = getTokenFromAddressByChain(result.paymentToken, chainId);
          if (!buyWith?.decimals) {
            buyWith.decimals = 18;
          }
          const curPrice = getDisplayVal(
            result.currentPrice.toString(),
            buyWith.decimals
          );
          const priceIncrease = getDisplayVal(
            result.priceIncrease.toString(),
            buyWith.decimals
          );
          const maxTokenPrice = getDisplayVal(
            result.currentPrice
              .add(result.priceIncrease.mul(result.maxTokenId.sub(result.currentTokenId)))
              .toString(),
            buyWith.decimals
          );
          return {
            currentTokenPrice: curPrice,
            priceIncrease,
            currentTokenId: result.currentTokenId.toNumber(),
            maxTokenId: result.maxTokenId.toNumber(),
            tokenSteps: result.maxTokenId.sub(result.currentTokenId).toNumber(),
            maxTokenPrice,
            paymentToken: result.paymentToken,
          };
        })
      )
      .catch((error) => console.log(error));
  }
  return false;
};

// Payment contract
export const sendPayment = (tokenAddress, index, amount, chain, from) =>
  new Promise((resolve, reject) => {
    Contracts.Payment.at(Payments[chain]).then((instance) => {
      instance
        .sendERC20(tokenAddress, index, amount, { from })
        .on("transactionHash", (hash) => {
          resolve(hash);
        })
        .on("error", (error) => {
          reject(error);
        });
    });
  });
