import React, { PureComponent } from "react";
import { reduxForm } from "redux-form";
import Dialog from "@material-ui/core/Dialog";

import DiscardChangesModal from "components/DiscardChangesModal";
import { handleApiErrorForReduxForm } from "utils/apiErrors";
import Text from "components/Text";
import LoadingButton from "components/LoadingButton";

class FormModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmClose: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  handleSubmit(...args) {
    const resetConfirmClose = () => {
      // if the save was triggered by the confrim close modal..
      if (this.state.confirmClose) {
        this.setState({ confirmClose: false });
      }
    };

    return this.props
      .action(...args)
      .then(() => {
        this.props.renderSuccessSnackbar();
        this.props.onClose();
      })
      .catch((err) => {
        resetConfirmClose();
        return handleApiErrorForReduxForm(err);
      });
  }

  handleOnClose() {
    const { pristine, onClose } = this.props;
    if (this.state.confirmClose) {
      onClose();
      return null;
    }
    if (!pristine) {
      this.setState({
        confirmClose: true,
      });
      return null;
    }
    onClose();
    return null;
  }

  render() {
    const {
      modalTitle,
      modalCaption,
      formContent,
      submitBtnText,
      handleSubmit,
      error,
      pristine,
      invalid,
      submitting,
    } = this.props;

    if (this.state.confirmClose) {
      return (
        <DiscardChangesModal
          onDiscard={this.handleOnClose}
          onSave={handleSubmit(this.handleSubmit)}
        />
      );
    }

    return (
      <Dialog open onClose={this.handleOnClose}>
        <div className="ma--40">
          <Text variant="h6">{modalTitle}</Text>
          <Text variant="p2" secondary>
            {modalCaption}
          </Text>
          {formContent}
          <div className="mt--56">
            {error && <Text error>{error}</Text>}
            <LoadingButton
              fullWidth
              onClick={handleSubmit(this.handleSubmit)}
              disabled={pristine || invalid}
              variant="outlined"
              loading={submitting}
            >
              {submitBtnText}
            </LoadingButton>
          </div>
          <Text
            secondary
            onClick={this.handleOnClose}
            variant="caption"
            className="cursor--pointer display--inline-block"
          >
            Cancel
          </Text>
        </div>
      </Dialog>
    );
  }
}

export default reduxForm({})(FormModal);
