import React from "react";
import image from "images/signIn/background.png";
import BackGroundImage from "../SignUp/BackGroundImage";
import Content from "../SignIn/Content";

const SignIn = () => {
  return (
    <>
      <BackGroundImage image={image} />
      <Content />
    </>
  );
};

export default SignIn;
