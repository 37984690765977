/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { Fragment, Component } from "react";
import {
  Button,
  TextField,
  Checkbox,
  GridList,
  GridListTile,
  RadioGroup,
} from "@material-ui/core";
import Select from "react-select";
import Text from "components/Text";

export const renderTextField = ({
  input,
  label,
  InputProps,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && error != null}
    InputProps={InputProps}
    {...input}
    {...custom}
  />
);

export const renderDatePickerField = ({
  input,
  label,
  InputProps,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    label={label}
    helperText={touched && error}
    error={touched && error != null}
    InputProps={InputProps}
    {...input}
    onChange={(event) => input.onChange(event.target.value)}
    {...custom}
  />
);

export const renderSelectField = ({
  input,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <TextField
    select
    helperText={touched && error}
    error={touched && error != null}
    {...input}
    onChange={(event) => input.onChange(event.target.value)}
    {...custom}
  >
    {children}
  </TextField>
);

export const renderRadioGroup = ({
  input,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <RadioGroup
    value={input.value}
    helperText={touched && error}
    onChange={(event) => input.onChange(event.target.value)}
    {...custom}
  >
    {children}
  </RadioGroup>
);

export class FieldFileInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.state = { picture: "" };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.name !== this.props.input.name) {
      this.removeImage();
    }
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;

    this.getBase64(e.target.files[0]).then((val) => {
      this.setState({ picture: val });
    });
    onChange(e.target.files[0]);
  }

  getBase64(file) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // eslint-disable-line
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  removeImage() {
    const {
      input: { onChange },
    } = this.props;
    this.setState({ picture: null });
    onChange("");
  }

  render() {
    const {
      id,
      src,
      imgStyle,
      style,
      text,
      input,
      meta,
      nolabel,
      imglabel,
      noImage,
      removeText,
      className,
      children,
      ...custom
    } = this.props; // whatever props you send to the component from redux-form Field
    return (
      <div style={style} className={className}>
        {!noImage ? (
          imglabel ? (
            <>
              <label htmlFor={id}>
                <img
                  className="img-upload imglabel"
                  src={this.state.picture ? this.state.picture : src}
                  style={imgStyle}
                  alt="Uploaded File"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
                  }}
                />
              </label>
              {removeText && this.state.picture && (
                <Button
                  onClick={() => this.removeImage()}
                  variant="text"
                  className="center normal"
                >
                  Remove Image
                </Button>
              )}
            </>
          ) : (
            <img
              className="img-upload"
              src={this.state.picture ? this.state.picture : src}
              style={imgStyle}
              alt="Uploaded File"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
              }}
            />
          )
        ) : (
          ""
        )}
        {children}
        {!nolabel && (
          <label htmlFor={id}>
            <Button component="span" variant="outlined" fullWidth>
              {text}
            </Button>
          </label>
        )}
        <input
          style={{ display: "none" }}
          id={id}
          {...custom}
          type="file"
          accept=".jpg, .png, .jpeg"
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export class MultiFieldFileInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.state = {
      pictures: [],
      picture_files: [],
      existing_images: props.existingImages || [],
    };
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    this.setState(
      {
        picture_files: [...this.state.picture_files, e.target.files[0]],
      },
      () => {
        onChange(this.state.picture_files);
      }
    );
    this.getBase64(e.target.files[0]).then((val) => {
      this.setState({
        pictures: [...this.state.pictures, val],
      });
    });
  }

  getBase64(file) {
    // eslint-disable-line
    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // eslint-disable-line
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  removeExistingImage(index) {
    const splicedPictures = [...this.state.existing_images];
    splicedPictures.splice(index, 1);
    this.setState({ existing_images: splicedPictures });
  }

  removeImage(index) {
    const {
      input: { onChange },
    } = this.props;
    const splicedPictures = [...this.state.pictures];
    const splicedPictureFiles = [...this.state.picture_files];
    splicedPictures.splice(index, 1);
    splicedPictureFiles.splice(index, 1);
    this.setState(
      {
        pictures: splicedPictures,
        picture_files: splicedPictureFiles,
      },
      () => {
        onChange(splicedPictureFiles);
      }
    );
  }

  render() {
    const {
      id,
      src,
      imgStyle,
      style,
      removeText,
      text,
      input,
      className,
      children,
      gameId,
      existingImages,
      existingNames,
      ...custom
    } = this.props; // whatever props you send to the component from redux-form Field
    return (
      <GridList
        cols={4.33}
        spacing={2}
        cellHeight={140}
        style={style}
        className={className ? `${className} single` : "single"}
      >
        {children}
        {this.state.pictures.map((picture, index) => (
          <GridListTile key={picture}>
            <img src={picture} style={imgStyle} alt="uploaded file" />
            <input
              name={`picture[${index}]`}
              value={picture}
              style={{ display: "none" }}
            />
            {removeText && (
              <Button
                onClick={() => this.removeImage(index)}
                variant="text"
                size="small"
                className="center normal"
              >
                Remove Image
              </Button>
            )}
          </GridListTile>
        ))}
        <GridListTile>
          <label htmlFor={id} className="imglabel">
            <img
              className="MuiGridListTile-imgFullHeight-297"
              src={src}
              style={imgStyle}
              alt="Uploaded File"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
              }}
            />
          </label>
          <input
            style={{ display: "none" }}
            id={id}
            {...custom}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={this.onChange}
          />
        </GridListTile>
      </GridList>
    );
  }
}

export const renderCheckbox = ({ input, ...props }) => (
  <div className="checkbox">
    <Checkbox onChange={(event) => input.onChange(event.target.checked)} {...props} />
  </div>
);

export const renderChipMultiSelect = ({
  input,
  options,
  name,
  id,
  label,
  className,
  ...custom
}) => (
  <div className={className}>
    {input.value.length > 0 && <Text variant="caption">{label}</Text>}
    <Select
      {...input}
      id={id}
      name={name}
      placeholder={label}
      options={options}
      value={input.value}
      onChange={(value) => {
        input.onChange(value);
      }}
      onBlur={(value) => input.onBlur(input.value)}
      styles={{
        border: "none",
        menu: (base) => ({
          ...base,
          color: "white",
          backgroundColor: "#27272d",
          marginTop: "0px",
          padding: "0px",
          borderRadius: "0px",
        }),
        menuList: (base) => ({
          ...base,
          padding: "0px",
          margin: "0px",
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "16px",
          color: "#ffffff",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? "#ebeeff19" : "#27272d",
        }),
        control: (base) => ({
          // none of react-selects styles are passed to <View />
          ...base,
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }),
        container: (base) => ({
          ...base,
          borderBottom: "1px solid white",
          borderRadius: "0px",
        }),
        input: (base) => ({
          ...base,
          color: "#ffffff",
          border: "none",
        }),
        valueContainer: (base) => ({
          ...base,
          border: "none",
          paddingLeft: "0px",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        multiValue: (styles) => ({
          ...styles,
          padding: "7px 13px",
          marginRight: "8px",
          backgroundColor: "transparent",
          border: "1px solid white",
          borderRadius: "16px",
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          fontSize: "14px",
          fontWeight: "500",
          color: "#ffffff",
          lineHeight: "1.36",
          padding: "0px",
        }),
        multiValueRemove: (base) => ({
          ...base,
          padding: "2px",
          marginTop: "2px",
        }),
      }}
      {...custom}
    />
  </div>
);
