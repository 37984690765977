import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeNav, openNav } from "dux/nav";
import breakpoints from "utils/breakpoints";

import useWindowSize from "../../hooks/useWindowSize";
import Header from "../Header";
import NavBar from "../Nav/NavBar";
import { useStyles } from "./Layout.styles";

const Layout = React.memo(({ children }) => {
  const nav = useSelector((state) => state.nav);
  const { width } = useWindowSize();
  const classes = useStyles(nav.open);
  const dispatch = useDispatch();

  useEffect(() => {
    if (width < breakpoints.md) {
      dispatch(closeNav());
    } else {
      dispatch(openNav());
    }
  }, [dispatch, width]);

  return (
    <div className={classes.layout}>
      <NavBar />
      <div className={classes.content}>
        <Header />
        {children}
      </div>
    </div>
  );
});

export default Layout;
