// Ex. Use a primary color in other js files like this...
// import colors from "./colors";
// var color = colors.white;

const basicColors = {
  white: "#ffffff",
  black: "#000000",
  transparent: "transparent",
};

const tapBrandColors = {
  blurple: "#6e43e6",
  blurpleLight: "#7847ff",
  blurpleDark: "#643dd1",
  goldLight: "#ffc42f",
  gold: "#cf9e24",
  goldDark: "#b48612",
  gray: "#343a40",
};

const wizardBrandColors = {
  wizSkyblue: "#93d2d8",
  wizDarkSlateGrey: "#434a5d",
  wizMediumPurple: "#917fec",
  wizDarkSlateBlue: "#305f92",
};

const textColors = {
  primaryText: "#ffffff",
  secondaryText: "#999ba3",
};

const background = {
  defaultBackground: "#1F2128",
  paper: "#23272b",
};

export default {
  ...tapBrandColors,
  ...wizardBrandColors,
  ...background,
  ...textColors,
  ...basicColors,
};
