import { Button } from "@material-ui/core";
import { recoverPassword } from "actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "reducers/redux/Modal/actions";

const TabPassword = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleResetPasswordButton = () => {
    dispatch(recoverPassword(user.email_address));
    dispatch(
      openModal({
        header: "Password reset email sent",
        successButton: "Close",
        values: [
          "Check the email you registered with for instructions on the next steps for resetting your password.",
        ],
      })
    );
  };

  return (
    <Button color="secondary" variant="contained" onClick={handleResetPasswordButton}>
      Reset Password
    </Button>
  );
};

export default TabPassword;
