import React from "react";
import { useSelector } from "react-redux";
import Steps from "../index";
import { ImgWrapper, Message, Name } from "../styles";
import img from "../../../../../images/signUp/step1/blockchain.png";
import Buttons from "./Buttons";
import { DisabledWrapper } from "./styles";
import { NextButton } from "../../../../buttons/styles";

const Blockchain = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      actions={<Buttons setActiveStep={setActiveStep} current={1} />}
    >
      <ImgWrapper>
        <img src={img} alt="tap project" />
      </ImgWrapper>

      <Name>Supported Blockchains</Name>

      <Message>
        Game X Change will work on multiple blockchains. Currently it supports Binance
        Smart Chain.
      </Message>
      <DisabledWrapper>
        <NextButton disabled>Binance Smart Chain</NextButton>
      </DisabledWrapper>
    </Steps>
  );
});

export default Blockchain;
