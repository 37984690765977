import React from "react";
import { BackgroundImageWrapper } from "./styles";

const BackGroundImage = ({ image }) => {
  return (
    <BackgroundImageWrapper>
      <img src={image} alt="background" />
    </BackgroundImageWrapper>
  );
};

export default BackGroundImage;
