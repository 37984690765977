import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useButtonStyles } from "../../../styles";
import NFTContainer from "components/containers/NFTContainer";
import { Button, Typography } from "@material-ui/core";
import { NftActions } from "components/Dashboard/Achievement/styles";
import { OuterNFTWrapper } from "components/Marketplace/styles.js";
import { openNFTModal } from "reducers/redux/NFTModal/actions";

const NFTList = ({ dismantleHandler, mintHandler, nftData }) => {
  const dispatch = useDispatch();

  const onClickHandler = (e, data) => {
    e.stopPropagation();
    dismantleHandler(data);
  };

  if (!nftData) return <></>;
  return (
    <OuterNFTWrapper>
      <NFTContainer nftData={nftData} onClick={() => dispatch(openNFTModal(nftData))}>
        <NftActions>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => onClickHandler(e, nftData)}
            fullWidth
          >
            <Typography>Dismantle</Typography>
          </Button>
          {/* <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => dispatch(openNFTModal(nftData))}
          >
            <Typography>View Details</Typography>
          </Button> */}
        </NftActions>
      </NFTContainer>
    </OuterNFTWrapper>
  );
};

export default NFTList;
