import React, { Component } from "react";
import { Button, Grid, FormHelperText } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  updateUser as actionUpdateUser,
  connect as actionConnect,
  isAddress,
} from "actions/index";
import * as Form from "utils/form";
import Wallet from "assets/icons/lmg/Wallet.svg";
import Text from "components/Text";
import { history } from "../../../store/index";

class AddWallet extends Component {
  constructor(props) {
    super(props);
    this.state = { addressError: null };
  }

  componentDidMount() {
    this.props.connect().then(() => {
      this.props.initialize({ eth_wallet_address: this.props.web3.connected });
    });
  }

  validate(address) {
    if (!address || !isAddress(address)) {
      this.setState({ addressError: "Not a valid address" });
    } else {
      this.setState({ addressError: null });
    }
  }

  render() {
    const { pristine, handleSubmit, updateUser } = this.props;
    return (
      <Grid container className="create-wallet">
        <Grid item container justifyContent="center" xs={12}>
          <Wallet width={124} height={124} />
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Text variant="display1">ETH wallet address</Text>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Text>
            Please add your ETH wallet address to the field below. We recommend using
            Metamask and creating a new Eth Wallet Address.
          </Text>
          <Text className="mt--16">
            This wallet is linked to your Game conversions and your Steam PC Challenges -
            Any claimed game points from Marketplace or Challenges go to this wallet.
          </Text>
          <Text color="textSecondary" className="mt--16">
            Please note - use a wallet in which you control private keys or your tokens
            may be lost
          </Text>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Field
            onChange={(data) => this.validate(data.target.value)}
            name="eth_wallet_address"
            component={Form.renderTextField}
            label="Wallet Address"
            fullWidth
          />
          <FormHelperText className="text__red text--centered">
            {this.state.addressError}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} className="mt--80">
          <Button
            variant="contained"
            fullWidth
            disabled={pristine || Boolean(this.state.addressError)}
            onClick={handleSubmit((data) => {
              updateUser(data);
              history.push("/onboarding/geteth");
            })}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  web3: state.web3,
});

const mapDispatchToProps = {
  updateUser: actionUpdateUser,
  connect: actionConnect,
};

export default compose(
  reduxForm({ form: "addWallet", initialValues: {} }),
  connect(mapStateToProps, mapDispatchToProps)
)(AddWallet);
