import { useEffect, useRef } from "react";

const useScroll = (handler) => {
  const ref = useRef();

  useEffect(() => {
    const refCurrent = ref.current;
    if (!refCurrent) return;

    const id = setTimeout(() => {
      if (
        refCurrent.scrollHeight === refCurrent.offsetHeight &&
        !!refCurrent.scrollHeight
      ) {
        handler(null, true);
      }
    }, 0);

    refCurrent.addEventListener("scroll", handler, { passive: true });

    return () => {
      clearTimeout(id);
      if (!refCurrent) return;
      refCurrent.removeEventListener("scroll", handler);
    };
  }, [ref, handler]);

  return ref;
};

export default useScroll;
