import React, { PureComponent, Fragment } from "react";
import {
  Paper,
  Grid,
  MenuItem,
  Typography,
  Button,
  Dialog,
  Radio,
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { handleApiErrorForReduxForm } from "utils/apiErrors";
import Text from "components/Text";
import SaveFooter from "components/SaveFooter";
import {
  renderTextField,
  FieldFileInput,
  renderSelectField,
  renderRadioGroup,
} from "utils/form";
import * as Info from "assets/info";
import Person from "assets/icons/person.svg";
import ConnectedAccounts from "assets/icons/connectedAccounts.svg";
import Security from "assets/icons/security.svg";
import * as actions from "actions";
import LoadingSpinner from "components/LoadingSpinner";
import * as ImgServer from "utils/imgServer";
import * as badgeIcons from "utils/badgeIcons";
import Image from "components/utils/Image";
import ChangeEmailForm from "./ChangeEmailForm";
import ChangeUserForm from "./ChangeUserForm";
import ChangePassForm from "./ChangePassForm";
import SettingsMenu from "./SettingsMenu";
import { history } from "../../../store/index";

export const TABS = {
  PERSONAL_INFO: "personal-info",
  CONNECTED_ACCOUNTS: "connected-accounts",
  PASS_SECURITY: "password-security",
  ETHEREUM_WALLET: "ethereum-wallet",
};

// helper components
export const SectionInfo = ({ caption = "", info = "", ...props }) => (
  <div {...props}>
    <Text variant="caption" secondary>
      {caption}
    </Text>
    <Text variant="p1">{info}</Text>
  </div>
);

export const SectionField = (props) => (
  <Field className="mt--24" fullWidth component={renderTextField} {...props} />
);

export const SectionHeading = (props) => (
  <Text variant="s1" className="mb--8" {...props} />
);

export const SectionText = (props) => <Text variant="body2" secondary {...props} />;

export const SectionDivider = ({ smallMargin }) => (
  <div
    className={classNames("settings-page__content-divider", {
      "content-divider--small": smallMargin,
    })}
  />
);

class Settings extends PureComponent {
  static handleAPIErrors(err) {
    return handleApiErrorForReduxForm(err);
  }

  constructor(props) {
    super(props);
    let defaultTab = TABS.PERSONAL_INFO;
    if (this.props.match.params.tab) {
      switch (this.props.match.params.tab) {
        case "connected":
          defaultTab = TABS.CONNECTED_ACCOUNTS;
          break;
        case "security":
          defaultTab = TABS.PASS_SECURITY;
          break;
        default:
          break;
      }
    }
    this.state = {
      activeTab: defaultTab,
      isSaving: false,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmitUserUpdate = this.handleSubmitUserUpdate.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentUser();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user.gid_steam &&
      nextProps.user.gid_steam !== this.props.user.gid_steam
    ) {
      this.props.steamGetCurrentUser(this.props.user.gid_steam).then(() => {
        this.props.fetchSeasonalGames();
      });
    }
  }

  navigateHome() {
    this.props.history.push("/");
  }

  handleMenuClick(activeTab) {
    this.setState({ activeTab });
  }

  handleCloseModal() {
    this.setState({ modal: null });
  }

  handleSubmitUserUpdate(data) {
    this.setState({ isSaving: true });
    // todo: promise.all the apex and steam api calls to verify username
    let checkSteam = new Promise((resolve) => setTimeout(resolve, 10));
    let checkApex = new Promise((resolve) => setTimeout(resolve, 10));
    if (!this.props.user.gid_steam && data.gid_steam) {
      checkSteam = this.props.checkSteamUser(data.gid_steam);
    }
    if (!this.props.user.gid_origin && data.gid_origin) {
      checkApex = this.props.checkApexUser(data.gid_origin, data.platform_origin);
    }

    Promise.all([checkSteam, checkApex])
      .then(() => {
        this.props
          .updateUserProfile(this.props.user.id, data)
          .then(() => {
            this.setState({ isSaving: false });
            this.renderSuccessSnackbar();
            this.props.getCurrentUser();
          })
          .catch((error) => {
            this.renderErrorSnackbar(error);
          });
      })
      .catch((error) => {
        if (error.message) {
          this.renderErrorSnackbar(error.message);
        } else {
          this.renderErrorSnackbar(error);
        }
      });
  }

  handleChangeUsername(data) {
    const { id } = this.props.user;
    return this.props
      .updateUsername(id || "", data)
      .then(() => {
        this.handleCloseModal();
        this.props.getCurrentUser();
        this.renderSuccessSnackbar();
      })
      .catch(Settings.handleAPIErrors);
  }

  handleChangePassword({ password }) {
    this.props.updatePassword(password).then(() => {
      this.handleCloseModal();
      this.props.getCurrentUser();
      this.renderSuccessSnackbar();
    });
  }

  // no camel case rule "email_address"
  // eslint-disable-next-line
  handleChangeEmail({ email_address = "", password = "" }) {
    return this.props
      .updateEmail(this.props.user.id, {
        email_address,
        password,
      })
      .then(() => {
        this.handleCloseModal();
        this.props.getCurrentUser();
        this.renderSuccessSnackbar();
      })
      .catch(Settings.handleAPIErrors);
  }

  renderSuccessSnackbar() {
    actions.renderSuccessSnackbar("Changes Saved");
  }

  renderErrorSnackbar(error = "something went wrong") {
    actions.renderErrorSnackbar(error);
  }

  renderDialogs() {
    const { modal } = this.state;
    return (
      <>
        <Dialog open={modal === "CHANGE_EMAIL"} onClose={this.handleCloseModal}>
          <ChangeEmailForm
            onSubmit={this.handleChangeEmail}
            onClose={this.handleCloseModal}
          />
        </Dialog>
        <Dialog open={modal === "CHANGE_USERNAME"} onClose={this.handleCloseModal}>
          <ChangeUserForm
            onSubmit={this.handleChangeUsername}
            onClose={this.handleCloseModal}
          />
        </Dialog>
        <Dialog
          open={modal === "CHANGE_PASSWORD"}
          onClose={this.handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <ChangePassForm
            onSubmit={this.handleChangePassword}
            onClose={this.handleCloseModal}
          />
        </Dialog>
        <Dialog open={modal === "UNSAVED_CHANGES"} onClose={this.handleCloseModal}>
          <div className="modal-570">
            <Typography variant="display1" className="text__white">
              You have unsaved changes
            </Typography>
            <Typography variant="body2" className="text__white mt--8">
              Save or discard changes to continue
            </Typography>
            <div
              className="mt--32"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => {
                  this.props.reset();
                  this.navigateHome();
                }}
                className="btn-red"
                style={{ flex: 0.45 }}
              >
                Discard
              </Button>
              <Button
                onClick={this.props.handleSubmit((data) => {
                  this.handleSubmitUserUpdate(data).then(() => {
                    this.navigateHome();
                  });
                })}
                className="btn-green"
                style={{ flex: 0.45 }}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  renderMenu() {
    return (
      <SettingsMenu
        onClick={this.handleMenuClick}
        activeId={this.state.activeTab}
        menuItems={[
          {
            id: TABS.PERSONAL_INFO,
            text: "Personal Info",
            icon: <Person />,
          },
          {
            id: TABS.CONNECTED_ACCOUNTS,
            text: "Connected Accounts",
            icon: <ConnectedAccounts />,
          },
          {
            id: TABS.PASS_SECURITY,
            text: "Password & Security",
            icon: <Security />,
          },
        ]}
      />
    );
  }

  renderContent() {
    const { user } = this.props;

    if (this.state.activeTab === TABS.PERSONAL_INFO) {
      return (
        <div>
          <Grid container>
            <Grid item xs={6}>
              <SectionHeading>Profile Image</SectionHeading>
              <SectionText>JPEG or PNG file, max 2MB.</SectionText>
              <label htmlFor="user-profile" className=" mt--24 fullWidth display-block">
                <Button component="span" fullWidth variant="outlined">
                  Upload Avatar Image
                </Button>
              </label>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
              <div className="settings-page__avatar-img">
                <Field
                  name="profile_image"
                  imgStyle={{
                    width: "148px",
                    height: "148px",
                  }}
                  nolabel
                  src={
                    this.props.user.profile_avatar_url &&
                    user.profile_avatar_url !== "File failed to upload"
                      ? ImgServer.userImage(this.props.user.id)
                      : `http://api.adorable.io/avatars/${this.props.user.username}`
                  }
                  id="user-profile"
                  component={FieldFileInput}
                />
              </div>
            </Grid>
          </Grid>
          <SectionDivider />
          <Text variant="s1" className="mb--8 float-left">
            Badge Customization
          </Text>
          <div className="badge">
            <Image
              alt={`${this.props.user.username} badge`}
              src={`https://img.tapplatform.io/stat/icons/levels/${Math.ceil(
                this.props.user.levelInfo.level / 4
              )}-72.png`}
              src2="https://img.tapplatform.io/stat/icons/levels/1-72.png"
            />
            <Image
              alt="badge"
              src={`https://img.tapplatform.io/stat/icons/badge/${this.props.thisForm.profile_banner_url}.png`}
              src2="https://img.tapplatform.io/stat/icons/badge/010-space-invaders.png"
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2}>
              <Field
                component={renderRadioGroup}
                name="profile_banner_url"
                className="display--none"
              >
                {badgeIcons.packNames.map((packName) =>
                  badgeIcons.iconPacks[packName].icons.map((icon) => (
                    <Radio id={`badge-radio-${icon}`} value={icon} />
                  ))
                )}
              </Field>
              {badgeIcons.packNames.map((packName) =>
                badgeIcons.iconPacks[packName].icons.map((icon) => (
                  <Grid item xs={3} sm={2} md={1}>
                    <label className="cursor--pointer" htmlFor={`badge-radio-${icon}`}>
                      <img
                        className={
                          icon === this.props.thisForm.profile_banner_url
                            ? "border4-greenBase fullWidth"
                            : "border4-grey fullWidth"
                        }
                        alt="badge"
                        src={`https://img.tapplatform.io/stat/icons/badge/${icon}.png`}
                      />
                    </label>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
          <SectionDivider />
          <SectionHeading>Personal Details</SectionHeading>
          <SectionText>
            Your Real Name is private and will not be displayed to other users
          </SectionText>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SectionField label="First Name" name="first_name" />
              <Field
                fullWidth
                name="country_of_origin"
                component={renderSelectField}
                label="Country of Origin"
                required
              >
                {Info.countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <SectionField label="Last Name" name="last_name" />
            </Grid>
          </Grid>
        </div>
      );
    }
    if (this.state.activeTab === TABS.CONNECTED_ACCOUNTS) {
      return (
        <div>
          <SectionHeading>Game Accounts</SectionHeading>
          <SectionText>
            To play Esports your username/gamertag is needed, so other players can find
            you. Changing your username/gamertag may cause issues.
          </SectionText>
          <SectionField
            label="Epic Account Display Name"
            name="gid_epic_games"
            disabled={!!this.props.initialValues.gid_epic_games}
          />
          {this.props.initialValues.gid_epic_games ? (
            <Typography
              onClick={() => {
                // Intercom("show");
              }}
              component={Link}
              to="#"
            >
              If you need to change your epic games display name, contact support
            </Typography>
          ) : (
            <Typography variant="caption" className="text__red">
              Do try and use a unique display name. The API may pick up the wrong account
              info if there are duplicates, including accounts with different
              capitalizations. e.g. &quot;Ninja&quot; and &quot;ninja&quot;
            </Typography>
          )}
          <SectionField
            label="Steam Vanity URL"
            name="gid_steam"
            normalize={(value) => {
              const newVal = value.split("/");
              if (newVal[newVal.length - 1].length > 0) {
                return newVal[newVal.length - 1];
              }
              if (newVal.length > 1) {
                return newVal[newVal.length - 2];
              }
              return value;
            }}
            disabled={Boolean(this.props.initialValues.gid_steam)}
          />
          {this.props.initialValues.gid_steam ? (
            <Typography
              onClick={() => {
                // Intercom("show");
              }}
              component={Link}
              to="#"
            >
              If you need to change your steam id, contact support
            </Typography>
          ) : (
            <>
              <Typography variant="caption" className="text__white">
                e.g.{" "}
                <a href="http://steamcommunity.com/id/gabelogannewell">
                  http://steamcommunity.com/id/gabelogannewell
                </a>{" "}
                would use &quot;gabelogannewell&quot;. You can find this in your profile
                after clicking &quot;edit profile&quot; and looking for the &quot;Custom
                URL&quot; field.
              </Typography>
              <Typography variant="caption" className="text__red">
                To prevent exploitation, you cannot change your steam id without
                contacting support once you have set it.
              </Typography>
            </>
          )}
          {!this.props.initialValues.gid_origin_old ? (
            <Grid container>
              <Grid item xs={9}>
                <SectionField label="Apex Legends Account Username" name="gid_origin" />
              </Grid>
              <Grid item xs={3}>
                <SectionField
                  name="platform_origin"
                  component={renderSelectField}
                  label="Platform"
                  fullWidth
                  required={Boolean(this.props.thisForm.gid_origin)}
                >
                  {[
                    { name: "PC", value: "pc" },
                    { name: "XBOX", value: "xbl" },
                    { name: "PS4", value: "psn" },
                  ].map((platform) => (
                    <MenuItem key={platform.value} value={platform.value}>
                      {platform.name}
                    </MenuItem>
                  ))}
                </SectionField>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={9}>
                <SectionField
                  label="Apex Legends Account Username"
                  name="gid_origin_old"
                  readOnly
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <SectionField
                  name="platform_origin"
                  component={renderSelectField}
                  label="Platform"
                  fullWidth
                  readOnly
                  disabled={this.props.initialValues.platform_origin}
                >
                  {[
                    { name: "PC", value: "pc" },
                    { name: "XBOX", value: "xbl" },
                    { name: "PS4", value: "psn" },
                  ].map((platform) => (
                    <MenuItem key={platform.value} value={platform.value}>
                      {platform.name}
                    </MenuItem>
                  ))}
                </SectionField>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  onClick={() => {
                    // Intercom("show");
                  }}
                  component={Link}
                  to="#"
                >
                  If you need to change your apex id, contact support
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      );
    }

    if (this.state.activeTab === TABS.PASS_SECURITY) {
      return (
        <div>
          <SectionHeading>User Name and Details</SectionHeading>
          <SectionText>Change your password.</SectionText>
          <Grid container className="mt--24">
            <Grid item xs={8}>
              <SectionInfo caption="Username" info={user && user.username} />
              <SectionInfo
                className="mt--24"
                caption="Email Address"
                info={user && user.email_address}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  this.setState({ modal: "CHANGE_USERNAME" });
                }}
                fullWidth
                variant="outlined"
              >
                Change Username
              </Button>
              <Button
                onClick={() => {
                  this.setState({ modal: "CHANGE_EMAIL" });
                }}
                fullWidth
                className="mt--24"
                variant="outlined"
              >
                Change Email
              </Button>
            </Grid>
          </Grid>
          <SectionDivider />
          <SectionHeading>Password</SectionHeading>
          <SectionText>Change your password.</SectionText>
          <Button
            onClick={() => {
              this.setState({ modal: "CHANGE_PASSWORD" });
            }}
            variant="outlined"
            className="mt--24"
          >
            Reset Password
          </Button>
        </div>
      );
    }

    if (this.state.activeTab === TABS.ETHEREUM_WALLET) {
      return (
        <div>
          <SectionHeading>Wallet Address</SectionHeading>
          <SectionText>
            The tap wallet is a smart contract linked to your Ethereum address, and
            therefor uses the same private key.
          </SectionText>
          <SectionInfo
            caption="Ethereum Address"
            info={(user && user.eth_wallet_address) || "N/A"}
            className="mt--32 text--ellipsis"
          />
          <SectionInfo
            caption="Linked Tap Wallet Adress"
            info={(user && user.wallet_address) || "N/A"}
            className="mt--32 text--ellipsis"
          />
          <Button variant="outlined" className="mt--32">
            Change Etherum Address
          </Button>
          <SectionDivider />
          <SectionHeading>Private Key Warning</SectionHeading>
          <SectionText>
            We are a client-side interface that allows you to interact with the Ethereum
            blockchain, and you wallet, which stores your TTT tokens. We are not a web
            wallet, and don’t have your private key. This keep funds save from hacks
            attempts, if you keep your prive key safe and remember your wallet address.
          </SectionText>
        </div>
      );
    }
    return null;
  }

  renderFooterActions() {
    return (
      <div>
        <Button
          className="btn--large"
          onClick={() => {
            if (this.props.dirty) {
              this.setState({ modal: "UNSAVED_CHANGES" });
            } else {
              this.navigateHome();
            }
          }}
        >
          <Typography variant="body2">Cancel</Typography>
        </Button>
        <Button
          className="btn-green btn--large ml--16"
          primary="true"
          onClick={this.props.handleSubmit(this.handleSubmitUserUpdate)}
        >
          {this.state.isSaving ? (
            <LoadingSpinner />
          ) : (
            <Typography variant="body2" className="bold">
              Save Changes
            </Typography>
          )}
        </Button>
      </div>
    );
  }

  renderSaveFooter() {
    const { handleSubmit, reset, pristine, invalid } = this.props;
    return (
      <SaveFooter
        onSave={handleSubmit(this.handleSubmitUserUpdate)}
        onDiscard={() => {
          // reset the form and navigate to wallet
          reset();
          history.push("/wallet");
        }}
        discardDisabled={pristine}
        saveDisabled={invalid || pristine}
      />
    );
  }

  render() {
    return (
      <>
        <div className="settings-page">
          {this.renderDialogs()}
          {this.renderMenu()}
          <Paper className="settings-page__content">{this.renderContent()}</Paper>
        </div>
        {this.renderSaveFooter()}
      </>
    );
  }
}

const mapState = (state) => {
  const initialValues = JSON.parse(JSON.stringify(state.user));
  if (state.user.gid_origin) {
    initialValues.gid_origin_old = ` ${state.user.gid_origin}`.slice(1);
  }
  return {
    user: state.user,
    initialValues,
    thisForm: (state.form.settingsPage && state.form.settingsPage.values) || {},
  };
};

export default compose(
  connect(mapState, actions),
  reduxForm({ form: "settingsPage", enableReinitialize: true })
)(Settings);
