import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import AddGame from "components/containers/publisher/AddGame";
import PubRegister from "components/containers/publisher/Register";
import CompanyRegister from "components/containers/publisher/CompanyInfo";
import PubDashboard from "components/containers/publisher/PubDashboard";
import PubSettings from "components/containers/publisher/settings/PubSettings";
import GamePage from "components/containers/publisher/GamePage";
import PubWalletQuestion from "components/containers/publisher/onboarding/WalletQuestion";
import PubWallet from "components/containers/publisher/Wallet";

const Publisher = ({ match, ...props }) => {
  return (
    <Switch>
      <Route path="/pub/onboarding/wallet/:step" component={PubWalletQuestion} />
      {/* // */}
      {/* Available to publishers with or without wallet addresses */}
      {/* // */}
      <Route path="/pub/onboarding/company/:step" component={CompanyRegister} />
      <Route path="/pub/addGame/:step" component={AddGame} />

      <Route path="/pub/onboarding/account/:step" component={PubRegister} />
      <Route path="/pub/org/dashboard" component={PubDashboard} />
      <Route path="/pub/org/wallet" component={PubWallet} />
      <Route path="/pub/org/settings" component={PubSettings} />
      <Route path="/pub/org/:id/gamepage" component={GamePage} />
      <Redirect to="/pub/org/dashboard" />
    </Switch>
  );
};

export default withRouter(Publisher);
