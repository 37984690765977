import { makeStyles } from "@material-ui/core";

const drawerOpenDesktopWidth = 256;
const drawerClosedDesktopWidth = 81;
const drawerMobileOpenWidth = 256;
const drawerMobileClosedWidth = 0;

export const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    flexShrink: 0,
    width: (props) => (props.open ? drawerMobileOpenWidth : drawerMobileClosedWidth),
    [theme.breakpoints.up("sm")]: {
      width: (props) => (props.open ? drawerOpenDesktopWidth : drawerClosedDesktopWidth),
    },
  },
  drawerOpen: {
    width: drawerMobileOpenWidth,
    [theme.breakpoints.up("sm")]: {
      width: drawerOpenDesktopWidth,
      scrollbarWidth: "thin",
      scrollBarColor: "darkgrey",
      overflowY: "scroll",
      paddingRight: 5,
      border: "none",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#1a1a1a",
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: drawerMobileClosedWidth,
    [theme.breakpoints.up("sm")]: {
      width: drawerClosedDesktopWidth,
      border: "none",
    },
    backgroundColor: theme.palette.background.default,
  },
}));
