import React from "react";
import { Grid } from "@material-ui/core";
import Vaults from "./Vaults";

const Content = React.memo(() => {
  return (
    <Grid container item>
      <Grid container item xs={12} direction="column">
        <Vaults />
      </Grid>
    </Grid>
  );
});

export default Content;
