import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { ContentHeaderText, NftActions, NftDesc, NftName } from "../Marketplace/styles";
import { Flex } from "../../styles";

export const DashboardContentHeaderText = styled(ContentHeaderText)`
  margin-bottom: 39px;

  @media (max-width: 480px) {
    margin-bottom: 2px;
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 16%;
  }

  & > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const GamesItem = styled(Paper)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px !important;
  overflow: hidden;
  height: 200px;

  & > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1b0057;
  }
`;

export const GamesImg = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const InnerTextShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 70px 50px -15px #111, inset 0px -70px 50px -15px #111;
  top: 0;
  left: 0;
`;

export const GameItemBody = styled(Flex)`
  padding: 12px;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;
  justify-content: flex-start;
`;

export const GameName = styled(NftName)`
  font-size: 20px;
`;

export const GameDescription = styled(NftDesc)`
  line-height: 20px;
  font-family: "Work Sans";
  font-size: 15px;
`;

export const GameActions = styled(NftActions)`
  flex-grow: 1;
  justify-content: space-between;
  margin: 19px 0 0 0;
`;

export const GamesStatistic = styled(Flex)`
  flex-direction: column;
  color: #abb5be;
  text-align: right;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
`;
