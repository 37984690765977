import React, { useState } from "react";
import { useSelector } from "react-redux";
import Steps from "../index";
import {
  ConceptWrapper,
  ConfirmCheckbox,
  Description,
  Header,
  ReadBlue,
  TextField,
} from "./styles";
import { ReactComponent as Success } from "../../../../../images/icons/success.svg";
import { ReactComponent as ArrowLink } from "../../../../../images/icons/arrowLink.svg";
import { ReactComponent as Read } from "../../../../../images/icons/readCheckbox.svg";
import { ReactComponent as ReadNoActive } from "../../../../../images/icons/readCheckboxNoActive.svg";
import Actions from "./Actions";
import AuthModal from "../../../../Modal/AuthModals";
import TermsOfService from "../../../../Modal/AuthModals/TermsOfService";
import PrivacyPolicy from "../../../../Modal/AuthModals/PrivacyPolicy";

const Concept = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  const [open, setOpen] = useState({
    terms: null,
    policy: null,
  });

  const [read, setRead] = useState(false);

  const changeStepHandler = (value) => {
    setActiveStep(value, value === "+");
  };

  const clickHandler =
    (name, value = false) =>
    () => {
      setOpen((prev) => ({ ...prev, [name]: value }));
    };

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      actions={
        <Actions setActiveStep={changeStepHandler} current={5} open={open} read={read} />
      }
    >
      <ConceptWrapper>
        <Header>We need your consent</Header>
        <Description>
          by accepting this invitation you are creating a new account.
        </Description>
        <ReadBlue>Read CAREFULLY</ReadBlue>

        <TextField onClick={clickHandler("terms")}>
          <div>Terms and conditions</div>
          {open.terms ? <Success /> : <ArrowLink />}
        </TextField>

        <TextField onClick={clickHandler("policy")}>
          <div>Privacy policy</div>
          {open.policy ? <Success /> : <ArrowLink />}
        </TextField>

        <ConfirmCheckbox onClick={() => setRead((p) => !p)}>
          {read ? <Read /> : <ReadNoActive />}
          <div>I read the documentation</div>
        </ConfirmCheckbox>
      </ConceptWrapper>

      <AuthModal
        open={open.terms === false}
        successHandler={clickHandler("terms", true)}
        rejectHandler={clickHandler("terms", null)}
      >
        {(handler) => <TermsOfService handler={handler} />}
      </AuthModal>

      <AuthModal
        open={open.policy === false}
        successHandler={clickHandler("policy", true)}
        rejectHandler={clickHandler("policy", null)}
      >
        {(handler) => <PrivacyPolicy handler={handler} />}
      </AuthModal>
    </Steps>
  );
});

export default Concept;
