import React from "react";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import Text from "components/Text";
import * as Web3Math from "blockchain/web3Utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.deSecondary,
  },
  currencyPaddingLeft: {
    paddingLeft: "15px",
  },
}));

const VaultCard = ({
  className,
  toSymbol,
  earned_value,
  premium_value,
  earned_name,
  premium_name,
  earned_img,
  premium_img,
}) => {
  const classes = useStyles();
  return (
    <Grid container className={className} spacing={2}>
      <Grid item>
        <Typography variant="h5" className="color--dsPlus3">
          Currencies
        </Typography>
      </Grid>
      {earned_name && (
        <Grid item xs={12}>
          <Paper className={`vault-card ${classes.paper}`} elevation={3}>
            <Grid container>
              <Grid item xs={2}>
                <img src={earned_img} alt="Currency Icon" />
              </Grid>
              <Grid item container xs={10}>
                <Grid container justifyContent="space-around" alignItems="center">
                  <Typography
                    className={`${classes.currencyPaddingLeft} color--dsPlus3`}
                    variant="h5"
                  >
                    {earned_name}
                  </Typography>
                  <Typography variant="subtitle1" className="color--dsPlus3">
                    {earned_value < 1
                      ? `1 : ${Web3Math.round(1 / earned_value, 2)} ${toSymbol}`
                      : `${Web3Math.round(earned_value, 2)} : 1 ${toSymbol}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      {premium_name && (
        <Grid item xs={12}>
          <Paper className={`vault-card ${classes.paper}`} elevation={3}>
            <Grid container>
              <Grid item xs={2}>
                <img src={premium_img} alt="Currency Icon" />
              </Grid>
              <Grid item container xs={10}>
                <Grid container justifyContent="space-around" alignItems="center">
                  <Typography
                    className={`${classes.currencyPaddingLeft} color--dsPlus3`}
                    variant="h5"
                  >
                    {premium_name}
                  </Typography>
                  <Typography variant="subtitle1" className="color--dsPlus3">
                    {premium_value < 1
                      ? `1 : ${Web3Math.round(1 / premium_value, 2)} ${toSymbol}`
                      : `${Web3Math.round(premium_value, 2)} : 1 ${toSymbol}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default VaultCard;
