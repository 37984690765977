import { getSeasonalGameIds } from "utils/apiRequest";
import appError from "utils/appError";
import { fetchSteamGame } from "utils/steam";
import {
  POPULATE_LOGIN_GAMES_LIST_IDS,
  POPULATE_LOGIN_GAMES_LIST,
  ERROR_LOADING_LOGIN_GAMES,
  REQUEST_LOGIN_GAMES_LIST,
  FINISH_LOADING_LOGIN_GAMES_LIST,
} from "./types";

export const loadGamesListIdsToState = (dispatch) =>
  getSeasonalGameIds()
    .then((res) => {
      const steamGameIds = res.data.map(({ steam_game_id }) => steam_game_id);
      dispatch({
        type: POPULATE_LOGIN_GAMES_LIST_IDS,
        data: steamGameIds,
      });
      return steamGameIds;
    })
    .catch((err) => {
      appError("The following error occured loading steam games ids...", err);
      dispatch({
        type: ERROR_LOADING_LOGIN_GAMES,
        data: { error: err, message: "Error loading game list ids" },
      });
      return [];
    });

export const loadSeasonalGamesByIdToState =
  (ids = []) =>
  (dispatch) =>
    Promise.all(
      ids.map((id) =>
        fetchSteamGame(id).catch((err) => {
          dispatch({
            type: ERROR_LOADING_LOGIN_GAMES,
            data: {
              error: err,
              message: "Error loading game by id from steam",
            },
          });
          return null;
        })
      )
    ).then((games) => {
      const gameListAttributes = games
        .filter((game) => game) // filter null values (errors from fetching game)
        .map(({ data }) => data);

      dispatch({
        type: POPULATE_LOGIN_GAMES_LIST,
        data: gameListAttributes,
      });
    });

export const fetchLoginGames = async (dispatch) => {
  dispatch({ type: REQUEST_LOGIN_GAMES_LIST });
  const steamIds = await loadGamesListIdsToState(dispatch);
  await loadSeasonalGamesByIdToState(steamIds)(dispatch);
  dispatch({ type: FINISH_LOADING_LOGIN_GAMES_LIST });
};
