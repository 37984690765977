import styled from "styled-components";

export const GamesListWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;

  & > div {
    width: 330px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    width: 200px;
  }

  @media (min-width: 1440px) {
    img {
      width: 10vw;
    }
  }
`;

export const Text = styled.div`
  font-weight: 600;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 20px;
  text-align: center;

  @media (min-width: 1440px) {
    font-size: 20px;
  }

  @media (min-width: 1920px) {
    margin-top: 10px;
    font-size: 24px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 33px;
  max-height: 360px;

  @media (min-width: 1440px) {
    max-height: 500px;
  }

  @media (min-width: 1920px) {
    max-height: 520px;
  }
`;

export const Game = styled.div`
  border-radius: 0.5rem;
  background-color: #1b1d2188;
  margin-bottom: 1rem;
  display: flex;
  color: #808191;

  & > img {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 72px;
  }

  @media (min-width: 1440px) {
    img {
      width: 28%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 24px 19px 21px;
  line-height: 16px;

  @media (min-width: 1440px) {
    font-size: 16px;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
  }
`;

export const Name = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  margin-bottom: 2px;

  @media (min-width: 1440px) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
  }
`;

export const Online = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;

  &::before {
    content: "";
    display: block;
    background-color: #ff754c;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
  }

  @media (min-width: 1440px) {
    margin-top: 13px;
  }
`;

export const MoreButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;

  & img {
    margin-right: 14px;
    margin-left: 2px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
  }

  @media (min-width: 1920px) {
    font-size: 20px;

    & img {
      width: 30px;
      height: 30px;
    }
  }
`;
