import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import colors from "utils/colors";
import { button, text } from "../../../styles";

export const MenuWrapper = styled.div`
  font-family: "Inter", sans-serif;
  color: #808191;
  padding: 0 10px;
  height: 100%;
  margin-top: 75px;
`;

export const ListItemWrapper = styled(NavLink)`
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      display: block;
      margin: 0;
    `};
`;

export const NavButtonText = styled.span`
  font-weight: 600;
  display: ${({ open }) => (open ? "inline" : "none")};
`;

export const ListItem = styled.div`
  padding: 0 18px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  height: 56px;
  align-items: center;
  transition: background-color 0.3s;
  border-radius: 12px;
  white-space: pre-line;
  word-wrap: break-word;

  &:hover {
    background-color: #1b1c23;
  }
`;

export const MenuNameBlock = styled.div`
  margin-top: 33px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  padding-left: ${({ open }) => (open ? "30px" : "0px")};
  text-align: ${({ open }) => (open ? "left" : "center")};
`;

export const Offers = styled.div``;

export const Offer = styled.div`
  overflow: hidden;
  position: relative;
  margin-bottom: 37px;
  white-space: normal;
  height: 350px;
  width: 100%;
  background: ${colors.defaultBackground};
  border-radius: 15px;
  border-radius: 15px 15px 0 0;

  & > img {
    min-height: 100px;
    max-height: 300px;
    position: absolute;
    left: 50%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    transform: translateX(-50%);
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 16px;
  background: #242731;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Name = styled(text)`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  position: absolute;
  width: 168px;
  left: 24px;
  top: 138px;
`;

export const Description = styled(text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  width: 168px;
  left: 24px;
  top: 214px;
`;

export const OfferButton = styled(button)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  position: absolute;
  width: 166px;
  left: 24px;
  top: 270px;
  white-space: normal;
  height: 56px;
  border-radius: 16px;
`;

export const useStyles = makeStyles((theme) => ({
  active: {
    "&& > *": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "12px",
      color: "#ffffff",
      display: "flex",

      "& > svg": {
        display: "inline-block",
      },
    },
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    width: (open) => (open ? "236px" : "40px"),
    marginLeft: (open) => (open ? "0" : "12px"),
    top: 0,
    left: "0px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: "75px",

    [theme.breakpoints.down("xs")]: {
      display: (open) => (open ? "flex" : "none"),
    },
  },
  svg: {
    fill: "transparent !important",
    marginRight: "16px",
  },
  logo: {
    width: "50px",
  },
  arrowsvg: {
    fill: "transparent !important",
    display: "none",
    marginLeft: "auto",
  },
  divider: {
    "&&": {
      backgroundColor: "rgba(240, 243, 246, 0.1)",
      margin: "15px 35px",
    },
  },
  navBurger: {
    marginLeft: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  social: {
    "& svg": {
      width: "24px",
      height: "24px",
      "& path": {
        fill: "#fff",
      },
    },
  },
}));
