import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Button, Card } from "@material-ui/core";
import * as actions from "actions";
import * as PublisherActions from "actions/publisher";
import SimpleGameCard from "components/Cards/SimpleGameCard";
import Text from "components/Text";

class PubDashboard extends PureComponent {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.fetchPubGames(this.props.match.params.id);
    } else {
      this.props.fetchDashboard();
    }
  }

  get gameDatas() {
    if (!this.props.games) {
      return [];
    }
    return this.props.games.ids.map((gameId) => ({
      ...this.props.games.byId[gameId],
    }));
  }

  render() {
    if (!this.props.organization) {
      return (
        <Grid item xs={12} className="dashboard__no-games">
          <Text variant="h4">Ensure you&apos;ve registered your organization.</Text>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            className="mt--40"
            component={Link}
            to="/pub/onboarding/company/info"
          >
            Complete registration
          </Button>
        </Grid>
      );
    }
    if (this.gameDatas.length > 0) {
      return (
        <Grid container spacing={3} className="dashboard__games mt--80">
          {this.gameDatas.map((game) => (
            <Grid item xs={6} key={game.id}>
              <SimpleGameCard game={game} publisher classes={{ card: "pub-gamecard" }} />
            </Grid>
          ))}
          <Grid item xs={6}>
            <Link to="/pub/addGame/basic/">
              <Card className="border-default">
                <div
                  style={{
                    marginTop: "32%",
                    marginBottom: "32%",
                  }}
                >
                  <img
                    variant="h2"
                    className="pub-addgame border-leSecondary br--circle pa--4"
                    src="https://img.tapplatform.io/stat/icons/plus.svg"
                    alt="add new game"
                  />
                </div>
              </Card>
            </Link>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} className="dashboard__no-games">
        <Text variant="h4">
          Add your game
          <br />
          for players to discover
        </Text>
        <Text variant="p1" className="mt--8" color="textSecondary">
          You can now add your game to the Tap platform to create awareness for your game.
        </Text>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          className="mt--40"
          component={Link}
          to="/pub/addGame/basic/"
        >
          Add Your First Game
        </Button>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  games: state.publisher.dashboard_games,
  organization: state.publisher.currentOrganization || false,
  user: state.user,
});

const mapDispatch = (dispatch) => ({
  fetchPubGames: (id) => dispatch(actions.fetchPubGames(id)),
  fetchDashboard: () => dispatch(PublisherActions.fetchDashboard()),
});

export default connect(mapState, mapDispatch)(PubDashboard);
