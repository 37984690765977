import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getNFTs } from "actions/NFTs";
import { isCompletedTx } from "blockchain";
import { loadedPartialScreen, loadingPartialScreen } from "dux/loading";
import appError from "utils/appError";
import { buyFromMarket } from "utils/web3Calls";
import { useHistory } from "react-router-dom";
import NFTList from "./NFTList";
import { ContentName } from "../styles";

const Content = React.memo(
  ({ allowanceHandler, setSuccessModal, connectHandler, getHandler }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const web3 = useSelector((state) => state.web3);
    const nfts = useSelector((state) => state.nft.nfts);
    const paymentContracts = useSelector((state) => state.paymentContracts);

    const nftAllowanceHandler = (id) => {
      const nft = nfts.find(({ saleId: nftId }) => nftId === id);

      const { blockchainId, paymentContract, marketContract } = nft;

      allowanceHandler(blockchainId, paymentContract, marketContract);
    };

    const nftMintingHandler = useCallback(
      (id) => {
        dispatch(loadingPartialScreen("nftButtons--is-minting-nft"));
        const nft = nfts.find(({ saleId: nftId }) => nftId === id);
        const { blockchainId } = nft.blockchainId;
        buyFromMarket(nft.saleId, 0, web3.connected)
          .then((txn) => {
            const timer = setInterval(() => {
              isCompletedTx(blockchainId, txn).then(() => {
                clearInterval(timer);
                dispatch(getNFTs(0, 10, web3)).then(() => {
                  dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
                  setSuccessModal(true);
                });
              });
            }, 1000);
          })
          .catch((err) => {
            appError("The following error occured in the NFT purchase handler", err);
            dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
          });
      },
      [dispatch, nfts, web3, setSuccessModal]
    );

    return (
      nfts.length > 0 && (
        <>
          <ContentName>NFTs For Sale</ContentName>
          <Grid container justifyContent="center">
            <Grid container spacing={2}>
              {nfts.map((nft) => (
                <Grid container item xl={3} lg={4} md={6} xs={12}>
                  <NFTList
                    key={nft.id}
                    nftData={{
                      ...nft,
                      paymentContracts,
                      allowanceHandler: () => nftAllowanceHandler(nft.saleId),
                      mintingHandler: () => nftMintingHandler(nft.saleId),
                      getHandler: (payment, price) => getHandler(payment, price),
                      connectHandler,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )
    );
  }
);

export default Content;
