// The goal of this hook is to update web3 data across the app and keep it consistent

import {} from "blockchain/web3Utils";
import { networkAddressChange, networkChange } from "utils/web3Calls";
import { SUPPORTED_BLOCKCHAINS } from "blockchain/tokenInfo";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoConnect, balanceOfTokenByName } from "actions";
import { WEB3_CONNECTED, WEB3_BAD_NETWORK } from "actions/types";

const networkListener = (chainId) => (dispatch) => {
  let validNetwork = false;
  SUPPORTED_BLOCKCHAINS.forEach((key) => {
    if (key === chainId) {
      validNetwork = true;
    }
  });
  if (validNetwork) {
    dispatch({
      type: WEB3_CONNECTED,
      chainId,
    });
  } else {
    dispatch({
      type: WEB3_BAD_NETWORK,
    });
  }
};

const addressListener = (connected) => (dispatch) => {
  dispatch({ type: WEB3_CONNECTED, connected });
};

const getEssentialNetworkData = (dispatch, connected) => {
  if (connected) {
    networkAddressChange(addressListener, dispatch);
    networkChange(networkListener, dispatch);
    dispatch(balanceOfTokenByName("GameXChange"));
  }
};

const useWeb3NetworkData = () => {
  const dispatch = useDispatch();

  const web3 = useSelector((state) => state.web3);

  const { connected } = web3;

  useEffect(() => {
    getEssentialNetworkData(dispatch, connected);
  }, [dispatch, connected]);

  useEffect(() => {
    // initial connection
    dispatch(autoConnect());
  }, [dispatch]);
};

export default useWeb3NetworkData;
