export const OPEN_NAV = "OPEN_NAV";
export const CLOSE_NAV = "CLOSE_NAV";
export const TOGGLE_NAV = "TOGGLE_NAV";

const NAV_INIT_STATE = {
  open: true,
};

export const closeNav = () => ({
  type: CLOSE_NAV,
});

export const openNav = () => ({
  type: OPEN_NAV,
});

export const toggleNav = () => ({
  type: TOGGLE_NAV,
});

export default (state = NAV_INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_NAV:
      return {
        open: true,
      };
    case CLOSE_NAV:
      return {
        open: false,
      };
    case TOGGLE_NAV:
      return {
        open: !state.open,
      };
    default:
      return state;
  }
};
