import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import * as web3Utils from "blockchain/web3Utils";
import { getTokenFromAddressByChain } from "blockchain/tokenInfo";

const renderImage = (transaction) => {
  let src = "https://img.tapplatform.io/stat/icons/conversion/";
  if (transaction.completed) {
    src += "converting-completed.svg";
  } else {
    src += "converting-start.svg";
  }
  return <img src={src} alt="status" width="30" />;
};

const renderStatus = (transaction, handleConversion) => {
  if (transaction.completed) {
    return <Typography variant="body1">Converting completed</Typography>;
  }
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">Pending</Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConversion(transaction)}
        >
          Claim
        </Button>
      </Grid>
    </>
  );
};

const renderValue = (tx, game, conversionRate) => {
  const token = getTokenFromAddressByChain(tx.contract_address, tx.chain_id);
  let text = "";
  const convertedValue =
    Math.round(parseFloat(web3Utils.getDisplayVal(tx.to_amount, token.decimals)) * 85) /
    100;

  text = `${convertedValue} ${token.symbol}`;
  return text;
};

const renderTransactions = ({ transactions, game, conversionRate, handleConversion }) =>
  transactions.map((transaction) => (
    <Grid item xs={12} key={`${transaction.type}-${transaction.id}`}>
      <Paper className="padding-16 border-default" square>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={1}>
                {renderImage(transaction)}
              </Grid>
              <Grid item container alignItems="center" xs={8}>
                {renderStatus(transaction, handleConversion)}
              </Grid>
              <Grid item xs={3} align="right">
                <Typography variant="body1">
                  {renderValue(transaction, game, conversionRate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ));

const VaultTransactions = (props) => (
  <Grid container className="latest-transactions">
    {renderTransactions(props)}
  </Grid>
);

export default VaultTransactions;
