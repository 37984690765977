import React, { PureComponent } from "react";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import * as R from "ramda";

import * as actions from "actions";
import Security from "assets/icons/security.svg";
import Person from "assets/icons/person.svg";
import Info from "assets/icons/info.svg";
import Wallet from "assets/icons/wallet.svg";
import SettingsMenu from "components/containers/settings/SettingsMenu";
import PubBusinessSettings from "./PubBuisnessSettings";
import PubUsersSettings from "./PubUsersSettings";
import PubSecuritySettings from "./PubSecuritySettings";
import PubEthereumWallet from "./PubEthereumWallet";

const TABS = {
  BUISNESS_INFO: "buisness_info",
  USERS: "users",
  PASS_SECURITY: "password_security",
  ETH_WALLET: "eth_wallet",
};

class PubSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: TABS.BUISNESS_INFO,
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchAllOrganizationData();
  }

  handleMenuClick(tabId) {
    this.setState({
      activeTab: tabId,
    });
  }

  renderMenu() {
    return (
      <SettingsMenu
        onClick={this.handleMenuClick}
        activeId={this.state.activeTab}
        menuItems={[
          {
            id: TABS.BUISNESS_INFO,
            text: "Business Info",
            icon: <Info />,
          },
          {
            id: TABS.USERS,
            text: "Users",
            icon: <Person />,
          },
          {
            id: TABS.PASS_SECURITY,
            text: "Password & Security",
            icon: <Security />,
          },
          {
            id: TABS.ETH_WALLET,
            text: "Ethereum Wallet",
            icon: <Wallet />,
          },
        ]}
      />
    );
  }

  renderContent() {
    const { user = {} } = this.props;
    const content = R.cond([
      [R.equals(TABS.BUISNESS_INFO), R.always(<PubBusinessSettings />)],
      [R.equals(TABS.USERS), R.always(<PubUsersSettings />)],
      [R.equals(TABS.PASS_SECURITY), R.always(<PubSecuritySettings />)],
      [
        R.equals(TABS.ETH_WALLET),
        R.always(<PubEthereumWallet ethWallet={user.eth_wallet_address} />),
      ],
    ])(this.state.activeTab);

    return <Paper className="settings-page__content">{content}</Paper>;
  }

  render() {
    return (
      <div className="settings-page">
        {this.renderMenu()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapState = (state) => ({
  user: state.user,
});

export default connect(mapState, actions)(PubSettings);
