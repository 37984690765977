// helper to make sure we don't call something that isn't a function
function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

export default {
  // function to generate the validation function
  // function expects all property names to be validated as they are named on the form
  // pass true to simply validate non blank value
  // pass a function for specific validation, funciton must return the error message
  // (warning passing a function will not automatically validate field isn't blank)
  gen(fields) {
    return (values) => {
      const errors = {};
      Object.keys(fields).forEach((fieldName) => {
        // if true just check for non blank
        if (fields[fieldName] === true) {
          if (!values[fieldName]) {
            errors[fieldName] = "Required.";
          }
        } else if (isFunction(fields[fieldName])) {
          errors[fieldName] = fields[fieldName](values, values[fieldName]);
        }
      });
      return errors;
    };
  },
  // here's an example of a validation check
  // we call is required because it's checking both the format of the email, and that the email isn't blank
  // PARAMS
  // first param is the values obj
  // second param is value of field being validated
  // RETURN
  // NOTE: return the error message not an errors object like traditional reduxForm validate
  // return null for no error
  validEmailRequired(_, email) {
    if (!email) {
      return "Required";
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return "Invalid email address";
    }
    return null;
  },
  validUsernameRequired(_, username) {
    if (!username) {
      return "Required";
    }
    if (username.length < 3 || username.length > 20) {
      return "Username must be between 3 and 20 characters";
    }
    return null;
  },
};

/** **********
 // example, creating a basic validation function

const validate = formHelp.gen({
  email: formHelp.validEmailRequired,
  password: true,
});
*********** */
