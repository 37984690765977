import React from "react";
import { useSelector } from "react-redux";
import Steps from "../index";
import { ImgWrapper, Message, Name } from "../styles";
import ReferralCode from "./ReferralCode";

export const Step3 = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  const changeStepHandler = (value) => {
    setActiveStep(value, value === "-");
  };

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      setActiveStep={changeStepHandler}
      text="Continue"
      current={4}
    >
      <ImgWrapper>
        <ReferralCode />
      </ImgWrapper>

      <Name>Step 2. Refer a friend!</Name>

      <Message>
        Referring earns you additional rewards on Game X Change! Every 5 friends you refer
        earns you an exclusive NFT pack!
      </Message>
    </Steps>
  );
});

export default Step3;
