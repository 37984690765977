import React from "react";
import {
  CurrencyCardImage,
  CurrencyCardRow,
  CurrencyCardTitle,
  CurrencyCardWrapper,
  CurrencyCardImageWrapper,
} from "../styles";
import NumbersFormat from "../../../assist/numbersFormat";
import { Grid } from "@material-ui/core";

const ListCurrency = ({ list }) =>
  list.map(({ id, image, name, count, currency }) => (
    <Grid item container justifyContent="space-between" lg={3} md={4} sm={6} xs={12}>
      <CurrencyCardWrapper elevation={3} key={id}>
        <CurrencyCardImageWrapper>
          <CurrencyCardTitle>{name}</CurrencyCardTitle>
          <CurrencyCardImage>
            <img src={image} alt="" />
          </CurrencyCardImage>
        </CurrencyCardImageWrapper>

        <CurrencyCardRow>
          {name === "NFTs" ? count : NumbersFormat(count)} {currency}
        </CurrencyCardRow>
      </CurrencyCardWrapper>
    </Grid>
  ));
export default ListCurrency;
