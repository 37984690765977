import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrev from "../../../../buttons/ButtonPrev";
import { setAddress } from "../../../../../reducers/redux/Signup/actions";
import { NextButton } from "../../../../buttons/styles";

const Actions = ({ setActiveStep, current, disabled, address }) => {
  const dispatch = useDispatch();
  const step = useSelector(({ Signup }) => Signup.activeWindow);

  const handlerChange = (value) => {
    current === step && setActiveStep(value);

    if (value === "+") {
      dispatch(setAddress(address));
    }
  };

  return (
    <>
      <ButtonPrev handler={() => handlerChange("-")} />
      <NextButton
        onClick={() => handlerChange("+")}
        disabled={!(current === step) || disabled}
      >
        Continue
      </NextButton>
    </>
  );
};

export default Actions;
