import React, { useState } from "react";
import { escapeRegExp } from "utils/helpers";
import { Button, Input, Box, Typography } from "@material-ui/core";
import { FlexRow, FlexColumn } from "components/Exchange/styles";
import { SubHeader, CustomInput, CustomButton } from "components/Exchange/styles";
import { useUserSlippageTolerance } from "blockchain/gasPrices";

import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const slippageErrorMessage = {
  InvalidInput: "InvalidInput",
  RiskyLow: "RiskyLow",
  RiskyHigh: "RiskyHigh",
};

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d{0,2}$`);

const SlippageSettings = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance();
  const [slippageInput, setSlippageInput] = useState("");

  const slippageInputIsValid =
    slippageInput === "" ||
    (userSlippageTolerance / 100).toFixed(2) ===
      Number.parseFloat(slippageInput).toFixed(2);

  let slippageError;
  if (slippageInput !== "" && !slippageInputIsValid) {
    slippageError = slippageErrorMessage.InvalidInput;
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = slippageErrorMessage.RiskyLow;
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = slippageErrorMessage.RiskyHigh;
  } else {
    slippageError = undefined;
  }

  const parseCustomSlippage = (value) => {
    if (value === "" || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value);

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt(
          (Number.parseFloat(value) * 100).toString()
        );
        if (
          !Number.isNaN(valueAsIntFromRoundedFloat) &&
          valueAsIntFromRoundedFloat < 5000
        ) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <FlexColumn>
      <FlexColumn>
        <FlexRow>
          <SubHeader>Slippage Tolerance</SubHeader>
          <StandardToolTip
            title={
              "Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution."
            }
          />
        </FlexRow>
        <FlexRow>
          <CustomButton
            scale="sm"
            onClick={() => {
              setSlippageInput("");
              setUserSlippageTolerance(10);
            }}
            variant="contained"
            color={userSlippageTolerance === 10 ? "primary" : "secondary"}
          >
            0.1%
          </CustomButton>
          <CustomButton
            scale="sm"
            onClick={() => {
              setSlippageInput("");
              setUserSlippageTolerance(50);
            }}
            variant="contained"
            color={userSlippageTolerance === 50 ? "primary" : "secondary"}
          >
            0.5%
          </CustomButton>
          <CustomButton
            scale="sm"
            onClick={() => {
              setSlippageInput("");
              setUserSlippageTolerance(100);
            }}
            variant="contained"
            color={userSlippageTolerance === 100 ? "primary" : "secondary"}
          >
            1.0%
          </CustomButton>
          <FlexRow style={{ alignItems: "center" }}>
            <Box width="76px">
              <CustomInput
                scale="sm"
                inputMode="decimal"
                pattern="^[0-9]*[.,]?[0-9]{0,2}$"
                placeholder={(userSlippageTolerance / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((userSlippageTolerance / 100).toFixed(2));
                }}
                onChange={(event) => {
                  if (event.currentTarget.validity.valid) {
                    parseCustomSlippage(event.target.value.replace(/,/g, "."));
                  }
                }}
                isWarning={!slippageInputIsValid}
                isSuccess={![10, 50, 100].includes(userSlippageTolerance)}
              />
            </Box>
            <SubHeader style={{ margin: 0 }}>%</SubHeader>
          </FlexRow>
        </FlexRow>
        {!!slippageError && (
          <Typography
            style={{
              fontSize: "14px",
              marginTop: "8px",
              color:
                slippageError === slippageErrorMessage.InvalidInput ? "red" : "#F3841E",
              fontFamily: "Work Sans",
              fontWeight: 600,
            }}
          >
            {slippageError === slippageErrorMessage.InvalidInput
              ? "Enter a valid slippage percentage"
              : slippageError === slippageErrorMessage.RiskyLow
              ? "Your transaction may fail"
              : "Your transaction may be frontrun"}
          </Typography>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

export default SlippageSettings;
