import {
  CLEAR_STEP,
  SET_ACTIVE_STEP,
  SET_ACTIVE_WINDOW,
  SET_BLOCK_CHAIN,
  SET_ADDRESS,
  SUCCESS_AUTH,
} from "./types";

export const initialState = {
  auth: false,
  activeStep: 0,
  activeWindow: 0,
  length: 4,
  selectedBlockchain: null,
  walletAddress: null,
};

const SignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOCK_CHAIN:
      return {
        ...state,
        selectedBlockchain: action.blockchain,
      };

    case SET_ADDRESS:
      return {
        ...state,
        walletAddress: action.address,
      };

    case SET_ACTIVE_WINDOW:
      return {
        ...state,
        activeWindow: action.active,
      };

    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.active,
      };

    case CLEAR_STEP:
      return {
        ...state,
        activeStep: 0,
        activeWindow: 0,
        selectedBlockchain: null,
      };

    case SUCCESS_AUTH:
      return {
        ...state,
        auth: true,
      };

    default:
      return state;
  }
};

export default SignupReducer;
