export default function errorHandler(err) {
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        return err.response.data.message;
      }
      if (err.response.data.msg) {
        return err.response.data.msg;
      }
      return err.response.data;
    }
    return err.response;
  }
  if (err.data && err.data.message) {
    return err.data.message;
  }
  if (err.message) {
    return err.message;
  }
  return err;
}
