import { WEB3_BALANCE_SET, WEB3_BALANCE_CLEAR } from "./types";

const initialState = {};

const Web3AllowanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEB3_BALANCE_SET: {
      return {
        ...state,
        [action.contract]: action.balance || 0,
      };
    }
    case WEB3_BALANCE_CLEAR: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default Web3AllowanceReducer;
