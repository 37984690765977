import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Grid, Paper, Button } from "@material-ui/core";
import {
  fetchfullVault,
  resumeConversionFromIngame,
  finishConversionFromTap,
  getBalanceOf,
  getAllowanceOf,
} from "actions";
import Text from "components/Text";
import * as ImgServer from "utils/imgServer";
import { TOKEN_INFO, upgradeToken, Payments } from "blockchain/tokenInfo";
import errorHandler from "utils/errorHandler";
import DetailedVaultCard from "../Cards/DetailedVaultCard";
import VaultOutTransactions from "./VaultOutTransactions";
import VaultInTransactions from "./VaultInTransactions";
import VaultUpgrade from "./VaultUpgrade";
import CardHeader from "../CardHeader";
import ConversionCard from "./ConversionCard";
import LoadingSpinner from "../LoadingSpinner";

const VaultDetails = () => {
  const [activeTab, setActiveTab] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const allowances = useSelector((state) => state.allowances);
  const balances = useSelector((state) => state.balances);
  const params = useParams();
  const [game, setGame] = useState(false);
  const [blockchainCurrencyIndex, setBlockchainCurrencyIndex] = useState(0);
  const [inTransactions, setInTransactions] = useState([]);
  const [outTransactions, setOutTransactions] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [vaultInfo, setVaultInfo] = useState({
    conversionClaims: {},
    transactions: {},
    pendingOut: false,
    pendingIn: false,
    needsToken: true,
  });
  const { vault } = vaultInfo;
  const [rateInfo, setRateInfo] = useState({});
  const [premiumRateInfo, setPremiumRateInfo] = useState({});
  const [upgradeAllowance, setUpgradeAllowance] = useState("0");
  const [upgradeBalance, setUpgradeBalance] = useState("0");

  useEffect(() => {
    if (allowances[upgradeToken.addresses[web3.chainId]]) {
      setUpgradeAllowance(
        allowances[upgradeToken.addresses[web3.chainId]][Payments[web3.chainId]]
      );
    }
  }, [allowances, web3.chainId]);

  useEffect(() => {
    setUpgradeBalance(balances[upgradeToken.addresses[web3.chainId]]);
  }, [balances, web3.chainId]);

  useEffect(() => {
    if (game) {
      if (blockchainCurrencyIndex < game.currency_schemas.length) {
        setRateInfo(game.currency_schemas[blockchainCurrencyIndex]);
      } else {
        setRateInfo(game.currency_schemas[0] || {});
      }
      if (blockchainCurrencyIndex < game.premium_currency_schemas.length) {
        setPremiumRateInfo(game.premium_currency_schemas[blockchainCurrencyIndex]);
      } else {
        setPremiumRateInfo(game.premium_currency_schemas[0] || {});
      }
    }
  }, [game, blockchainCurrencyIndex]);

  const fetchVaultInfo = useCallback(() => {
    fetchfullVault(params.id, user.eth_wallet_address.toLowerCase()).then((info) => {
      setGame(info.game);
      setInTransactions(info.vaultImports);
      setOutTransactions(info.vaultExports);
      setVaultInfo({
        vault: info.vault,
        pendingOut: info.pendingOut,
        pendingIn: info.pendingIn,
        needsToken: info.needsToken,
      });
    });
  }, [user.eth_wallet_address, params.id]);

  useEffect(() => {
    if (
      web3.connected &&
      !web3.badNetwork &&
      user.eth_wallet_address &&
      rateInfo.contract_name
    ) {
      if (TOKEN_INFO[rateInfo.contract_name]?.addresses[web3.chainId]) {
        dispatch(
          getBalanceOf(
            TOKEN_INFO[rateInfo.contract_name]?.addresses[web3.chainId],
            user.eth_wallet_address
          )
        );
      }
      // Get upgrade token balance, set
      const upgradeAddress = upgradeToken.addresses[web3.chainId];
      dispatch(getBalanceOf(upgradeAddress, user.eth_wallet_address));
      dispatch(
        getAllowanceOf(upgradeAddress, Payments[web3.chainId], user.eth_wallet_address)
      );
    }
  }, [
    dispatch,
    web3.connected,
    web3.badNetwork,
    web3.chainId,
    user.eth_wallet_address,
    rateInfo.contract_name,
  ]);

  useEffect(() => {
    fetchVaultInfo();
  }, [fetchVaultInfo, web3.chainId]);

  const handleFromConversion = (transaction) => {
    resumeConversionFromIngame(transaction)
      .then((res) => {
        const newConversionClaims = outTransactions;
        for (let index = 0; index < newConversionClaims.length; index++) {
          const claim = newConversionClaims[index];
          if (claim.id == transaction.id) {
            newConversionClaims[index].primary_transaction_hash = res;
            setOutTransactions(newConversionClaims);
            dispatch(
              getBalanceOf(
                TOKEN_INFO[rateInfo?.contract_name]?.addresses[web3.chainId],
                user.eth_wallet_address
              )
            );
            fetchVaultInfo();
            break;
          }
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };

  const handleToConversion = (transaction) => {
    finishConversionFromTap(transaction.id, vault.id)
      .then((res) => {
        const newTxs = inTransactions;
        for (let index = 0; index < newTxs.length; index++) {
          const tx = newTxs[index];
          if (tx.id == transaction.id) {
            newTxs[index].completed = true;
            setInTransactions(newTxs);
            dispatch(
              getBalanceOf(
                user.eth_wallet_address,
                TOKEN_INFO[rateInfo?.contract_name]?.addresses[web3.chainId]
              )
            );
            fetchVaultInfo();
            break;
          }
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };
  const { game_economy_type: vaultLimits, conversion_rate_card: conversionRate } =
    game || {};
  if (!vault || !game || !vaultLimits || !conversionRate) {
    return <LoadingSpinner size="xlarge" responsive centered />;
  }
  return (
    <div style={{ backgroundImage: `url('${game.game_card_background_image}')` }}>
      {/*renderBanner()*/}
      <Grid container spacing={3} className="vault-details">
        <Grid item xs={12}>
          <Text component={Link} to={`/game/${game.id}`} variant="h3" className="mb--16">
            {game.title}
          </Text>
        </Grid>
        <Grid item xs={12} sm={game.premium_currency_name ? 6 : 12}>
          <DetailedVaultCard
            className="border-default"
            vals={{
              currencySymbol: rateInfo.symbol,
              name: game.earned_currency_name,
              value:
                rateInfo.usd_price /
                conversionRate.earned_usd_rate /
                (vault.multiplier || 1),
              import_max: vaultLimits.daily_earned_currency_import_max,
              export_max: vaultLimits.daily_earned_currency_export_max,
              amount: vault.earned_currency,
              earned_count: vault.earned_currency_count,
              img: ImgServer.gameImage(game, "earned", game.earned_currency_image),
              imgFallback: "https://img.tapplatform.io/stat/EarnedCurrencyFallback.png",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {Boolean(game.premium_currency_name) && (
            <DetailedVaultCard
              className="border-default"
              vals={{
                currencySymbol: premiumRateInfo.symbol,
                name: game.premium_currency_name,
                value:
                  premiumRateInfo.usd_price /
                  conversionRate.premium_usd_rate /
                  (vault.multiplier || 1),
                amount: vault.premium_currency,
                import_max: vaultLimits.daily_premium_currency_import_max,
                export_max: vaultLimits.daily_premium_currency_export_max,
                earned_count: vault.premium_currency_count,
                img: ImgServer.gameImage(game, "premium", game.premium_currency_image),
                imgFallback:
                  "https://img.tapplatform.io/stat/PremiumCurrencyFallback.png",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} className="mt--24">
          <VaultUpgrade
            game={game}
            vault={vault}
            upgradeAllowance={upgradeAllowance}
            upgradeBalance={upgradeBalance}
            upgradeSuccess={fetchVaultInfo}
            fetchVaultInfo={fetchVaultInfo}
          />
        </Grid>
        <Grid item xs={12} className="mt--24">
          <ConversionCard
            fetchVaultInfo={fetchVaultInfo}
            blockchainCurrencyIndex={blockchainCurrencyIndex}
            setBlockchainCurrencyIndex={setBlockchainCurrencyIndex}
            vals={params.id}
            game={game}
            vault={vault}
            conversionRate={conversionRate}
            pendingOut={vaultInfo.pendingOut}
            pendingIn={vaultInfo.pendingIn}
            needsToken={vaultInfo.needsToken}
          />
        </Grid>
        <Grid item xs={12} className="mt--24">
          <Paper className="vault-card">
            <Grid container>
              <Grid item xs={6}>
                Daily Export Limit:{" "}
                {`${vault.daily_export_count} / ${vaultLimits.daily_user_export_count_max}`}
              </Grid>
              <Grid item xs={6}>
                Daily Import Limit:{" "}
                {`${vault.daily_import_count} / ${vaultLimits.daily_user_import_count_max}`}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className="mt--24">
          <CardHeader
            text={`Transactions (${activeTab ? "Imports" : "Exports"})`}
            actionButton={
              <Button variant="outlined" onClick={() => setActiveTab(!activeTab)}>
                View {activeTab ? "Exports" : "Imports"}
              </Button>
            }
          />
          <Text>{errorHandler(errorMsg)}</Text>
          {activeTab && inTransactions && (
            <VaultInTransactions
              transactions={inTransactions}
              game={game}
              conversionRate={conversionRate}
              handleConversion={handleToConversion}
            />
          )}
          {!activeTab && outTransactions && (
            <VaultOutTransactions
              transactions={outTransactions}
              game={game}
              conversionRate={conversionRate}
              handleConversion={handleFromConversion}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default VaultDetails;
