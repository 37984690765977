import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

import LeaderboardTable from "./LeaderboardTable";
import TopPerformers from "./TopPerformers";

const useStyles = makeStyles({
  grid: {
    flexGrow: "0.8",
    maxWidth: "80vw",
    minWidth: "340px",
    justifyContent: "center",
  },
});

const Leaderboard = ({ leaderboard }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      className={classes.grid}
    >
      <TopPerformers leaderboard={leaderboard} />
      <LeaderboardTable leaderboard={leaderboard} />
    </Grid>
  );
};

export default Leaderboard;
