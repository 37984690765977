import { isEmpty } from "ramda";

export const isStr = (variable) => {
  if (typeof variable === "string" || variable instanceof String) return true;
  return false;
};

export const isStrDefault = (variable, defaultStr = "") => {
  const strOutput = isStr(variable) && variable;
  return strOutput || defaultStr;
};

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

export const readableInputNames = (name) =>
  name.charAt(0).toUpperCase() + name.slice(1).toLowerCase().replace("_", " ");

export const capitalizeAllWords = (text) => {
  const pieces = text.split(" ");
  for (let i = 0; i < pieces.length; i += 1) {
    const j = pieces[i].charAt(0).toUpperCase();
    pieces[i] = j + pieces[i].substr(1).toLowerCase();
  }
  return pieces.join(" ");
};

export const getNumberWithOrdinal = (n) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const roundIntToDecimals = (int, decimals) => {
  const rounder = Math.pow(10, decimals);
  return Math.round(int * rounder) / rounder;
};

export const addhttp = (url) => (!/^(f|ht)tps?:\/\//i.test(url) ? url : `http://${url}`);

export const includesInArray = (arr1, arr2) => {
  var includes = false;
  arr2.every((check) => {
    if (arr1.includes(check)) {
      includes = true;
      return false;
    }
    return true;
  });
  return includes;
};

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const notEmpty = (obj) => {
  // first check that we are not getting undefined
  if (obj != null && obj !== undefined) {
    return !isEmpty(obj);
  }
  return false;
};

export default {
  isStr,
  isStrDefault,
  getNumberWithOrdinal,
  addhttp,
  notEmpty,
};
