import React from "react";
import List from "../list";

const ContentTabs = React.memo(
  ({
    activeTab,
    earnExp,
    stakeExp,
    yieldBalance,
    expectedReward,
    connected,
    detailsHandler,
    stakeHandler,
    connectHandler,
  }) => {
    if (activeTab === 0) {
      return (
        <List
          list={earnExp}
          yieldBalance={yieldBalance}
          expectedReward={expectedReward}
          connected={connected}
          detailsHandler={detailsHandler}
          stakeHandler={stakeHandler}
          connectHandler={connectHandler}
        />
      );
    }
    if (activeTab === 1) {
      return (
        <List
          list={stakeExp}
          yieldBalance={yieldBalance}
          expectedReward={expectedReward}
          connected={connected}
          detailsHandler={detailsHandler}
          stakeHandler={stakeHandler}
          connectHandler={connectHandler}
        />
      );
    }

    return <></>;
  }
);

export default ContentTabs;
