import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import Text from "components/Text";
import { resetPassword, updatePasswordReset } from "actions";
import { SignInWrapper } from "components/SignIn/styles";
import * as Form from "utils/form";
import LoadingButton from "components/LoadingButton";
import FormContainer from "components/SignIn/Form/FormContainer";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.handleChange = this.handleChange.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
  }

  handlePasswordReset(token, password, confirmPassword) {
    this.props.updatePasswordReset(token, password, confirmPassword);
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <SignInWrapper>
        <FormContainer name="Reset Password">
          <Grid container className="forgot" spacing={2}>
            <Grid item xs={12}>
              <Text variant="h5" className="forgot__header">
                New Password
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="password"
                component={Form.renderTextField}
                label="Enter new password"
                type="password"
                fullWidth
                className="mt--124"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="confirmPassword"
                component={Form.renderTextField}
                label="Retype password"
                type="password"
                fullWidth
                className="mt--124"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                className="forgot__reset-button"
                variant="contained"
                color="primary"
                fullWidth
                loading={this.state.loading}
                disabled={this.props.pristine || !this.props.valid || this.state.loading}
                onClick={this.props.handleSubmit((data) => {
                  this.setState({ loading: true });
                  this.handlePasswordReset(
                    this.props.match.params.token,
                    data.password,
                    data.confirmPassword
                  );
                })}
              >
                Change Password
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                component={Link}
                color="secondary"
                to="/login"
                fullWidth
                className="mt--24"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </FormContainer>
      </SignInWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updatePasswordReset,
  resetPassword,
};

export default withRouter(
  compose(
    reduxForm({ form: "new-password", validate }),
    connect(mapStateToProps, mapDispatchToProps)
  )(ResetPassword)
);
