import React, { PureComponent } from "react";
import { Button, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "utils/form";
import * as formHelpAsync from "utils/formHelpAsync";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  }

  if (!values.username) {
    errors.username = "Required";
  }

  return errors;
};

const asyncValidate = (values) => formHelpAsync.isValidUsername(values);

class ChangeUserForm extends PureComponent {
  render() {
    const { error } = this.props;
    return (
      <div className="modal-570">
        <Field
          component={renderTextField}
          label="New Username"
          name="username"
          fullWidth
        />
        <Field
          component={renderTextField}
          label="Enter Password"
          name="password"
          type="password"
          className="mt--48"
          fullWidth
        />
        <Button
          className="mt--48"
          variant="outlined"
          fullWidth
          onClick={this.props.handleSubmit(this.props.onSubmit)}
        >
          Change Username
        </Button>
        {error && (
          <Typography className="text__red text--centered" variant="body2">
            {error}
          </Typography>
        )}
        <Button onClick={this.props.onClose} className="mt--24" fullWidth>
          Cancel
        </Button>
      </div>
    );
  }
}

export default reduxForm({ form: "changeEmail", validate, asyncValidate })(
  ChangeUserForm
);
