import Web3 from "web3";
import { SET_PLAYER_CURRENCY_CHALLENGE_TTT } from "reducers/redux/Profile/PlayerCurrency/actions";
import appError from "utils/appError";
import { SET_NFTS } from "reducers/redux/Profile/NFT/types";
import {
  TOKEN_TYPES,
  STAKES,
  CONVERSION_INFO,
  getTokenFromAddressByChain,
} from "blockchain/tokenInfo";
import { history } from "../store/index";
import * as ApiRequest from "../utils/apiRequest";
import * as Web3Calls from "../utils/web3Calls";
import * as nftAPI from "../utils/nftAPI";
import {
  GAME_PUBLISHER_STATS,
  GAME_VIEW,
  VAULT_DELETED,
  VAULT_GET_CHALLENGE,
  VAULT_VIEW_LIST,
  VAULT_GET,
  CONVERSION_STATS,
} from "./types";

const { utils } = Web3;
var { BN } = utils;

const flattenResponse = (response) => ({
  ids: response.map((vault) => vault.id),
  byId: response.reduce(
    (previous, vault) => ({
      ...previous,
      [vault.id]: vault,
    }),
    {}
  ),
});

export const deleteVault = (vault) => (dispatch) =>
  ApiRequest.deleteVault(vault).then(() => {
    dispatch({
      type: VAULT_DELETED,
      vault,
    });
  });

export const fetchVaults = () => (dispatch) => {
  ApiRequest.fetchVaults().then((response) =>
    dispatch({
      type: VAULT_VIEW_LIST,
      vaults: flattenResponse(response.data.vaults),
    })
  );
};

export const fetchGameVault = (gameId) => (dispatch) => {
  ApiRequest.fetchGameVault(gameId).then((response) => {
    dispatch({
      type: VAULT_GET,
      currentVault: response.data,
    });
  });
};

export const fetchChallengeGameVault = () => (dispatch) =>
  ApiRequest.getMyChallengeGameVault()
    .then((response) => {
      const vault = response.data.vaults[0];
      dispatch({
        type: VAULT_GET_CHALLENGE,
        data: vault,
      });
      dispatch({
        type: SET_PLAYER_CURRENCY_CHALLENGE_TTT,
        data: vault.earned_currency,
      });
    })
    .catch((err) => {
      appError("The Following error occured while fetching challenge vault...", err);
    });

export const fetchfullVault = async (gameId, wallet) => {
  let vault = "";
  try {
    const res = await ApiRequest.fetchGameVault(gameId);
    vault = res.data;
  } catch (error) {
    appError(
      "The following error occured fetching game vault.. will attempt to create new vault..",
      error
    );
    const res = await ApiRequest.createVault(gameId);
    vault = res.data;
  }

  // todo: get balanced or standard imports/exports using vault.type
  const game = await ApiRequest.fetchGame(gameId).then((res) => res.data);

  const importPromise = ApiRequest.getVaultImports(game._id);
  const exportPromise = ApiRequest.getVaultExports(game._id);
  const importExports = await Promise.all([importPromise, exportPromise]);
  const chainId = await Web3Calls.currentNetwork();
  let needsToken = false;

  if (game.game_economy_type.required_token_address) {
    const requiredToken = getTokenFromAddressByChain(
      game.game_economy_type.required_token_address,
      chainId
    );
    const BnRequiredAmount = new BN(game.game_economy_type.required_token_amount);
    game.needsTokenName = requiredToken?.name;
    needsToken = true;
    try {
      const ownsAmount = await Web3Calls.getBalanceOf(
        game.game_economy_type.required_token_address,
        wallet
      );
      needsToken = ownsAmount.lt(BnRequiredAmount);
    } catch {}
    if (needsToken) {
      try {
        // todo: handle staked token types
        const stakeAddress = STAKES[chainId][TOKEN_TYPES.ERC721];
        const ownsAmount = await Web3Calls.stakeOwnedTokens(
          stakeAddress,
          game.game_economy_type.required_token_address,
          wallet
        );
        needsToken = ownsAmount.lt(BnRequiredAmount);
      } catch {}
    }
  }

  let pendingOut = false;
  let pendingIn = false;

  const vaultImports = importExports[0].data.map((tx, index) => {
    if (!tx.completed && index === 0) {
      pendingIn = true;
    }
    return tx;
  });
  const vaultExports = importExports[1].data.map((tx, index) => {
    if (!tx.completed && index === 0) {
      pendingOut = true;
    }
    return tx;
  });
  const conversionAddress = CONVERSION_INFO[chainId];
  try {
    // temporary fix
    if (vaultExports.length > 0) {
      if (vaultExports[0].contract_address && vaultExports[0].contract_address !== "0") {
        const nonce = await Web3Calls.getNonce(
          conversionAddress,
          vaultExports[0].contract_address,
          vaultExports[0].receiver_address
        );
        vaultExports.every((item, index) => {
          if (nonce > item.nonce) {
            vaultExports[index].completed = true;
          }
        });
        if (pendingOut) {
          if (nonce > vaultExports[0].nonce) {
            vaultExports[0].completed = true;
            pendingOut = false;
          }
        } else if (nonce === vaultExports[0].nonce) {
          vaultExports[0].completed = false;
          pendingOut = true;
        }
      }
    }
  } catch {}
  return {
    game,
    vault,
    vaultImports,
    vaultExports,
    pendingOut,
    pendingIn,
    needsToken,
  };
};

export const fecthPublisherGameStats = (gameId) => (dispatch) => {
  ApiRequest.fetchGame(gameId).then((gameData) => {
    //const conversionData = ApiRequest.fetchConversionRate(gameId);
    const vaultLimits = new Promise((resolve) => setTimeout(resolve([]), 10));
    /*if (gameData.data.data.attributes.game_economy_type) {
      vaultLimits = ApiRequest.getVaultLimits(
        gameId,
        gameData.data.data.attributes.game_economy_type
      );
    }*/
    const totalVaultCount = ApiRequest.getTotalVaultCount(gameId);
    const totalTTTConversion = ApiRequest.getTotalConversionTTTCount(gameId);
    const totalGameConversion = ApiRequest.getTotalConversionGameCount(gameId);

    const totalExportedEarnedCount = ApiRequest.getTotalExportedEarnedCount(gameId);
    const totalExportedPremiumCount = ApiRequest.getTotalExportedPremiumCount(gameId);
    const totalEarnedAllCount = ApiRequest.getTotalEarnedAllCount(gameId);
    const totalPremiumAllCount = ApiRequest.getTotalPremiumAllCount(gameId);
    const TotalConvertedPremiumCount = ApiRequest.getTotalConvertedPremiumCount(gameId);
    const TotalConvertedEarnedCount = ApiRequest.getTotalConvertedEarnedCount(gameId);

    let exportCount = new Promise((resolve) => setTimeout(resolve, 10));
    if (gameData.data.data.attributes.game_economy_type === "standard") {
      exportCount = ApiRequest.getStandardExportCount(gameId);
    } else {
      exportCount = ApiRequest.getBalancedExportCount(gameId);
    }

    Promise.all([
      //conversionData,
      vaultLimits,
      exportCount,
      totalVaultCount,
      totalTTTConversion,
      totalGameConversion,
      totalExportedEarnedCount,
      totalExportedPremiumCount,
      totalEarnedAllCount,
      totalPremiumAllCount,
      TotalConvertedEarnedCount,
      TotalConvertedPremiumCount,
    ]).then((values) => {
      for (let i = 0; i < values.length; i += 1) {
        if (!values[i].data) {
          values[i] = { data: { data: { attributes: null } } };
        }
        if (values[i].data.data === null) {
          values[i].data.data = { attributes: null };
        }
      }
      gameData.data.data.attributes.id = gameData.data.data.id;
      dispatch({
        type: GAME_VIEW,
        currentGame: gameData.data.data.attributes,
      });
      dispatch({
        type: GAME_PUBLISHER_STATS,
        conversion: values[0].data.data.attributes,
        limits: values[1].data.data.attributes,
        exportCount: values[2].data.count,
        vaultCount: values[3].data.count,
        totalTTTConversion: values[4].data.count,
        totalGameConversion: values[5].data.count,
        totalExportedEarnedCount: values[6].data.count,
        totalExportedPremiumCount: values[7].data.count,
        totalEarnedAllCount: values[8].data.count,
        totalPremiumAllCount: values[9].data.count,
        totalConvertedEarnedCount: values[10].data.count,
        totalConvertedPremiumCount: values[11].data.count,
      });
    });
  });
};
export const createVault = (gameId, push = true) =>
  ApiRequest.createVault(gameId)
    .then(() => {
      if (push) {
        history.push(`/vaults/${gameId}`);
      }
    })
    .catch((err) => {
      history.push(`/vaults/${gameId}`);
    });

export const getUserUnclaimedNFTs = () => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.getUserUnclaimedNFTs().then((response) => {
      if (response.data.data) {
        const promises = [];
        const formattedResponse = response.data.data.map((nft) => {
          promises.push(nftAPI.getNFT(nft.attributes.nft_id));
          return { ...nft.attributes, id: nft.id };
        });
        Promise.all(promises).then((nftData) => {
          const totalResponse = formattedResponse.map((nft, index) => {
            return { ...nft, meta: nftData[index].data.data[0] };
          });
          dispatch({
            type: SET_NFTS,
            data: totalResponse,
          });
          resolve(totalResponse);
        });
      }
    });
  });

export const getConversionStats = () => (dispatch) => {
  ApiRequest.getConversionStats().then((response) => {
    if (response.data.data) {
      dispatch({
        type: CONVERSION_STATS,
        conversionStats: response.data.data,
      });
    }
  });
};

export const canUpgradeVault = (gameId) =>
  ApiRequest.canUpgradeVault(gameId).then((response) => {
    return response.data;
  });

export const upgradeVault = async (gameId, chainId, from) => {
  const upgradeInfo = await ApiRequest.upgradeVault(gameId, chainId).then(
    (res) => res.data
  );
  if (upgradeInfo.tokenAddress) {
    const hash = await Web3Calls.sendPayment(
      upgradeInfo.tokenAddress,
      upgradeInfo.index,
      upgradeInfo.bnAmount,
      chainId,
      from
    );
    await Web3Calls.getTransactionReceiptMined(hash);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        ApiRequest.upgradeVault(gameId, chainId).then((res) => resolve(res.data));
      }, 10000);
    });
  }
  return new Promise((resolve, reject) => {
    resolve();
  });
};
