import React from "react";
import image from "images/signIn/background.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Content from "../SignUp/registrationSteps/Content";
import BackGroundImage from "../SignUp/BackGroundImage";

const SignUp = React.memo(() => {
  const user = useSelector((state) => state.user);
  const termsRedirect = useSelector(
    (state) => state.user.terms_and_privacy_policy_accepted
  );
  const history = useHistory();

  if (!user.isLoggedIn) {
    history.push("/sign-up");
  } else if (termsRedirect == true) {
    history.push("/dashboard");
  }

  return (
    <>
      <BackGroundImage image={image} />
      <Content />
    </>
  );
});

export default SignUp;
