import {
  SNACKBAR_OPEN,
  SNACKBAR_SET_CONTENT,
  SNACKBAR_CLOSE,
  SNACKBAR_CLEAR_CONTENT,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN: {
      return {
        ...state,
        open: true,
      };
    }
    case SNACKBAR_SET_CONTENT: {
      return {
        ...state,
        content: action.payload,
      };
    }
    case SNACKBAR_CLEAR_CONTENT: {
      return {
        ...state,
        content: null,
      };
    }
    case SNACKBAR_CLOSE: {
      return {
        ...state,
        open: false,
      };
    }
    default: {
      return state;
    }
  }
};
