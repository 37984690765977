import React from "react";
import Content from "../Profile/Content";

const MainPage = React.memo(() => {
  return (
    <div>
      <Content />
    </div>
  );
});

export default MainPage;
