import axios from "axios";
import { IS_PROD } from "utils/environments";

const LTNFTID_URL = IS_PROD
  ? "https://nft-api.gamexchange.app/web/v1"
  : "http://70.70.62.21:33334/web/v1";

const instance = axios.create();

/**
 * Gets a Limited Time NFT's ID
 * @param {number} id Unique Identifier
 * @param {string} contractName Index Name in TokenInfo array in TokenInfo.js
 * @returns
 */
export const getLTNFTID = (id, contractName) =>
  instance
    .get(`${LTNFTID_URL}/nfts/contract/${contractName}/nft/${id}`)
    .then((res) => res.data.data[0]);

/**
 * Get Limited Time Minting for a specific time window
 * @param {number} time Unix Epoch Time
 * @returns
 */
export const getLTMRuns = (collectionId) => {
  const time = Date.now();
  return instance
    .get(
      `${LTNFTID_URL}/ltnfts?collectionId=${collectionId}&active=true&startDate[lte]=${time}&endDate[gte]=${time}`
    )
    .then((res) => res.data.data);
};

/**
 * Gets the URL
 * @param {*} url URL
 * @returns
 */
export const get = (url) => instance.get(url);
