import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import {
  fetchVaults,
  fetchGames,
  getBalanceOf,
  getEthBalance,
  getGasPrice,
} from "actions";
import { TOKEN_INFO } from "blockchain/tokenInfo";
import WalletCard from "components/Cards/WalletCard";
import { roundEtherBalance } from "blockchain/web3Utils";
import Text from "components/Text";

class Wallet extends Component {
  componentDidMount() {
    this.props.getBalanceOf(
      TOKEN_INFO.GameXChange.addresses[this.props.web3.chainId],
      this.props.user.eth_wallet_address
    );
    this.props.getEthBalance(this.props.user.eth_wallet_address);
    this.props.getGasPrice();
    this.props.fetchVaults();
    this.props.fetchGames();
  }

  render() {
    return (
      <Grid container spacing={3} className="pub-wallet wallet">
        <Grid item xs={12} className="wallet__content">
          <Grid container>
            <Grid item xs={12} className="mt--16">
              <Paper className="border-default">
                <Grid
                  container
                  justifyContent="space-between"
                  className="pub-wallet__balance-card"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Text variant="p1" color="textSecondary">
                      Balance
                    </Text>
                  </Grid>
                  <Grid item xs={6}>
                    <Text
                      component={Link}
                      to="/eth/sendtap"
                      variant="p1"
                      align="right"
                      color="textSecondary"
                    >
                      Send coins
                    </Text>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} className="pub-wallet__balance-card">
                    <Grid
                      container
                      spacing={5}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Text variant="h3" color="inherit">
                          Tap coin
                        </Text>
                      </Grid>
                      <Grid item>
                        <Text variant="n3" color="inherit">
                          {roundEtherBalance(this.props.web3.balance)}
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    className="pub-wallet__balance-card"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Text variant="h6">Ethereum</Text>
                    </Grid>
                    <Grid item>
                      <Text variant="n6">
                        {this.props.web3.gasPrice}
                        {this.props.web3.ethBalance}
                      </Text>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} className=" mt--24 border-default">
              <Paper className="card-header padding-24">
                <Grid container justifyContent="space-between" alignItems="center">
                  <Text variant="h5" color="textSecondary">
                    Wallet Details
                  </Text>
                </Grid>
              </Paper>
              {this.props.web3.balance !== null && this.props.user.eth_wallet_address && (
                <WalletCard
                  role_id={this.props.user.role_id}
                  eth={this.props.user.eth_wallet_address}
                  amount={roundEtherBalance(this.props.web3.balance)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  fetchVaults,
  fetchGames,
  getBalanceOf,
  getEthBalance,
  getGasPrice,
};

const mapStateToProps = (state) => {
  state.games.ids.map((id) => state.games.byId[id]);
  return {
    user: state.user,
    games: state.games.byId,
    vaults: state.vaults.ids.map((id) => state.vaults.byId[id]),
    web3: state.web3,
  };
};

Wallet.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
