import React, { useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { createVault } from "actions/index";
import { GameDescription } from "components/Dashboard/styles";
import { Actions } from "components/Staking/list/styles";
import MaxLinesTextContainer from "components/MaxLinesTextContainer";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useButtonStyles } from "styles";
import CardContainer from "components/containers/CardContainer";
import { getTokenFromAddress } from "blockchain";
import {
  connect as connectWallet,
  getGXPRequirements,
  balancesOfTokensByName,
} from "actions/web3";
import * as links from "utils/links";
import { ContentName } from "../../../Marketplace/styles";

const GameXChangeConnectedGames = React.memo(({ list, link }) => {
  const history = useHistory();
  const classes = useButtonStyles();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const balances = useSelector((state) => state.balances);
  const dispatch = useDispatch();
  const userWallet = web3.connected;
  const needsGXP = web3?.needsGXP;

  useEffect(() => {
    if (dispatch && list && user.eth_wallet_address) {
      //dispatch(clearBalance());
      const allTokenNames = [];
      Object.keys(list).map((index) => {
        if (list[index].game_economy_type?.required_token_address) {
          const token = getTokenFromAddress(
            list[index].game_economy_type.required_token_address
          );
          if (token.key) {
            allTokenNames.push(token.key);
            // dispatch(balanceOfTokenByName(token.key, web3.connected));
          }
        }
      });
      dispatch(balancesOfTokensByName(allTokenNames, user.eth_wallet_address));
    }
  }, [dispatch, list, user.eth_wallet_address]);

  useEffect(() => {
    dispatch(getGXPRequirements(web3.connected));
  }, [web3.chainId, web3.connected]);

  const handleWalletConnect = () => {
    dispatch(connectWallet());
  };
  return (
    !!Object.keys(list).length && (
      <div>
        <ContentName>Play & Earn Integrated Games</ContentName>
        <Grid container justifyContent="center">
          <Grid container justifyContent="flex-start" spacing={2} alignItems="flex-start">
            {Object.entries(list)
              .sort((a, b) => {
                return ("" + a[1].status).localeCompare(b[1].status);
              })
              .map((index) => {
                const {
                  id,
                  image_url,
                  title,
                  short_description,
                  overlay_image_url,
                  purchase_link_url,
                  game_economy_type,
                  required,
                  earn,
                  AGY,
                  token,
                  status,
                } = list[index[0]];

                var needsToken = true;
                if (!!userWallet && !!token) {
                  const tokenAddress = token?.addresses[web3.chainId];
                  if (
                    balances[tokenAddress] >= game_economy_type?.required_token_amount
                  ) {
                    needsToken = false;
                  }
                }
                const isTBA = !!(status === "tba");
                const descriptionData = {
                  "GXP Required:": "2200 GXP",
                  "Required:": required,
                  "Earn:": earn,
                  "Annual Gaming Yield:": AGY,
                };

                return (
                  <CardContainer
                    id={id}
                    image={image_url}
                    overlayImage={overlay_image_url}
                    name={title}
                    descriptionData={descriptionData}
                    isTBA={isTBA}
                    link={link}
                    game
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ marginTop: "auto" }}
                    >
                      <MaxLinesTextContainer
                        maxLines={isTBA ? 4 : 2}
                        styledComponent={GameDescription}
                        text={short_description}
                      />
                    </Grid>
                    <Actions style={{ marginTop: "auto" }}>
                      <Grid
                        direction="column"
                        justifyContent="space-around"
                        container
                        spacing={4}
                      >
                        <Grid xs item>
                          {!web3.connected && !isTBA && (
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              onClick={() => {
                                handleWalletConnect();
                              }}
                              className={classes.button}
                            >
                              Unlock Wallet
                            </Button>
                          )}
                          {web3.connected && (!!needsToken || !!needsGXP) && !isTBA && (
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              onClick={() => {
                                needsGXP
                                  ? window.open(links.BUY_GXP_XCHANGE_LINK, "_blank")
                                  : window.open(purchase_link_url, "_blank");
                              }}
                              className={classes.button}
                            >
                              Get {needsGXP ? "GXP" : token?.symbol}
                            </Button>
                          )}
                          {((web3.connected && !needsToken && !needsGXP) || !!isTBA) && (
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              disabled={!!isTBA}
                              onClick={() => {
                                if (user.username) {
                                  createVault(id);
                                } else {
                                  history.push("/sign-in");
                                }
                              }}
                              className={classes.button}
                            >
                              Convert
                            </Button>
                          )}
                        </Grid>
                        <Grid xs item style={{ paddingTop: "0px" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            size="large"
                            disabled={!!isTBA}
                            onClick={() => {
                              history.push(`${link}/${id}`);
                            }}
                          >
                            View Game
                          </Button>
                        </Grid>
                      </Grid>
                    </Actions>
                  </CardContainer>
                );
              })}
          </Grid>
        </Grid>
      </div>
    )
  );
});

export default GameXChangeConnectedGames;
