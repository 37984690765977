import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import gxgCoin from "images/staking/tapcoinStaking/gxgCoin.png";
import mystery from "images/nft/mysteryToken.png";
import { Badge } from "components/ContentElements/info/styles";
import {
  Descriptions,
  Description,
  Name,
  StakingItemContent,
  StakingItem,
  Image,
  OverlayImage,
} from "components/Staking/list/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "5px",
    backgroundColor: theme.palette.text.secondary,
  },
  heading: {
    fontSize: "18px",
    fontFamily: "Work Sans",
    color: theme.palette.text.secondary,
    "& .MuiTypography-root": {
      color: theme.palette.text.secondary,
    },
  },
  stakeButton: {
    fontSize: "18px",
    width: "3.5rem",
    height: "3.5rem",
  },
  claimButton: {
    fontSize: "18px",
    height: "3.5rem",
    padding: "0px 40px 0px 40px",
  },
  description: {
    "& .MuiTypography-root": {
      lineHeight: "30px",
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: theme.typography.fontWeightLight,
    },
    "& .MuiTypography-body2": {
      fontSize: "16px",
      fontFamily: "Poppins",
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .MuiTypography-caption": {
      fontSize: "16px",
      color: theme.palette.text.secondary,
    },
  },
  accordian: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
  },
  accordianSummary: {
    padding: 0,
    margin: 0,
    "&.MuiAccordionSummary-root": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "auto",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
      "& svg": {
        fontSize: "18px",
      },
    },
  },
  accordianDetails: {
    padding: 0,
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontFamily: "Work Sans",
      color: theme.palette.text.secondary,
    },
  },
  expandIcon: {
    padding: 0,
  },
}));

const CardContainer = ({
  id,
  image,
  overlayImage,
  name,
  accordian,
  children,
  descriptionData,
  progress,
  accordianData,
  link,
  game,
  isTBA,
}) => {
  const history = useHistory();
  const descriptions = (data) => {
    const res = [];
    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <Typography variant="caption">{key}</Typography>
          <Typography variant="body2" style={{ fontFamily: "Work Sans" }}>
            {data[key]}
          </Typography>
        </Description>
      );
    });

    return res;
  };

  const imageOnErrorHandler = (ev) => {
    ev.currentTarget.src = mystery;
  };

  const classes = useStyles();
  return (
    <Grid item xl={3} lg={4} md={6} sm={12}>
      <StakingItem elevation={3} key={id}>
        <Image
          game={game}
          isTBA={isTBA}
          onClick={() => {
            game && !isTBA && history.push(`${link}/${id}`);
          }}
        >
          {image.split(".").pop() === "mp4" ? (
            <video style={{ zIndex: 1 }} autoPlay loop>
              <source src={image} type="video/mp4" />
            </video>
          ) : (
            <img onError={imageOnErrorHandler} src={image} />
          )}
        </Image>
        <OverlayImage
          game={game}
          isTBA={isTBA}
          onClick={() => {
            game && !isTBA && history.push(`${link}/${id}`);
          }}
        >
          <img src={overlayImage || gxgCoin} />
        </OverlayImage>
        <StakingItemContent className={classes.description}>
          <Name>
            <span>{name}</span>
            {!isTBA && <Badge>1X</Badge>}
          </Name>
          {!isTBA && (
            <>
              <Descriptions>{descriptions(descriptionData)}</Descriptions>
              {!!progress && (
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{ margin: "0px 0 10px", width: "100%" }}
                />
              )}
            </>
          )}
          {!!isTBA && (
            <Typography
              variant="caption"
              justifyContent="start"
              style={{ marginTop: "50px", marginBottom: "40px", fontWeight: "600" }}
            >
              Listing Date: TBA
            </Typography>
          )}
          {children}
          {accordianData && (
            <>
              <Divider variant="middle" light className={classes.root} />
              <div className={classes.root}>
                <Accordion square className={classes.accordian}>
                  <Grid container justifyContent="center">
                    <AccordionSummary
                      className={classes.accordianSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="caption" className={classes.heading}>
                        Details
                      </Typography>
                    </AccordionSummary>
                  </Grid>
                  <AccordionDetails className={classes.accordianDetails}>
                    <Descriptions>{descriptions(accordianData)}</Descriptions>
                  </AccordionDetails>
                </Accordion>
              </div>
            </>
          )}
        </StakingItemContent>
      </StakingItem>
    </Grid>
  );
};

export default CardContainer;
