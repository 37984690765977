import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { CURRENT_SEASON } from "utils/environments";
import MaxLinesTextContainer from "components/MaxLinesTextContainer";
import {
  loadedPartialScreen,
  loadingPartialScreen,
  steamClaimValue,
  connect as connectWallet,
} from "actions";
import appError from "utils/appError";
import { openAlert } from "reducers/redux/Alert/actions";
import { Actions } from "components/Staking/list/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContainer from "components/containers/CardContainer";
import { useButtonStyles } from "../../../styles";
// import { NftButton, SteamButton, SteamIcon } from "../../Marketplace/styles";
import { GameDescription } from "../styles";

const List = React.memo(({ list, children, link }) => {
  const classes = useButtonStyles();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const challenges = useSelector((state) => state.challenges);
  const balances = useSelector((state) => state.balances);
  const { partialScreen } = useSelector((state) => state.loading);
  const history = useHistory();
  const dispatch = useDispatch();
  const userWallet = web3.connected;

  return Object.entries(list)
    .sort((a, b) => {
      return ("" + a[1].status).localeCompare(b[1].status);
    })
    .map((index) => {
      const {
        id,
        steam_game_id,
        slug,
        image_url,
        title,
        short_description,
        nfts,
        achieved,
        achievements,
        overlay_image_url,
        purchase_link_url,
        game_economy_type,
        required,
        earn,
        token,
        status,
        total_payout_achievable,
      } = list[index[0]];
      let claimedNftCount = 0;
      if (nfts?.length > 0) {
        nfts.forEach((nft) => {
          if (nft.claimed) {
            claimedNftCount += 1;
          }
        });
      }
      const numNFTs = claimedNftCount + "/" + (nfts ? nfts.length : 0);
      const numAchievements = (achieved || 0) + "/" + achievements.length;
      const claimValue = challenges.claimRemaining[steam_game_id] || 0;
      const claimProgress = ((claimValue / total_payout_achievable) * 100).toFixed(1);

      const partialScreenLoadingName = `claim-button-${steam_game_id}`;
      const buttonLoading = partialScreen[partialScreenLoadingName];

      var needsToken = true;
      if (userWallet) {
        const tokenAddress = token?.addresses[web3.chainId];
        if (balances[tokenAddress] >= game_economy_type?.required_token_amount) {
          needsToken = false;
        }
      }
      const isTBA = !!(status === "tba");
      const descriptionData = {
        "Required:": required || `Season ${CURRENT_SEASON} Battle Pass`,
        "Earn:": earn || "Challenge Points",
        "Earned:": claimValue + "/" + total_payout_achievable + ` (${claimProgress}%)`,
      };

      const clickHandler = () => {
        if (!user.isLoggedIn) {
          history.push("/sign-in");
        } else {
          dispatch(loadingPartialScreen(partialScreenLoadingName));
          dispatch(steamClaimValue(user.id, steam_game_id))
            .then((res) => {
              dispatch(loadedPartialScreen(partialScreenLoadingName));
              dispatch(
                openAlert({
                  text: `${res.attributes.claim_value} GXP Claimed Successfully!`,
                  severity: "success",
                  anchor: { vertical: "top", horizontal: "right" },
                })
              );
            })
            .catch((err) => {
              dispatch(loadedPartialScreen(partialScreenLoadingName));
              appError("The following error occured while claiming steam value...", err);
              dispatch(
                openAlert({
                  text: "An Error Occurred! Please try again later.",
                  severity: "error",
                  anchor: { vertical: "top", horizontal: "right" },
                })
              );
            });
        }
      };

      const handleWalletConnect = () => {
        dispatch(connectWallet());
      };

      return (
        <CardContainer
          id={steam_game_id}
          image={image_url}
          overlayImage={overlay_image_url}
          name={title}
          descriptionData={descriptionData}
          progress={claimProgress}
          isTBA={isTBA}
          link={link}
          game
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ marginTop: "auto" }}
          >
            <MaxLinesTextContainer
              maxLines="3"
              styledComponent={GameDescription}
              text={short_description}
            />
          </Grid>
          <Actions style={{ marginTop: "auto" }}>
            <Grid direction="column" justifyContent="flex-end" container spacing={4}>
              <Grid xs item>
                {!web3.connected && !isTBA && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => {
                      handleWalletConnect();
                    }}
                    className={classes.button}
                  >
                    Unlock Wallet
                  </Button>
                )}
                {web3.connected && !!needsToken && !isTBA && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    onClick={() => {
                      purchase_link_url
                        ? window.open(purchase_link_url, "_blank")
                        : history.push("/marketplace");
                    }}
                    className={classes.button}
                  >
                    Get {token?.symbol}
                  </Button>
                )}
                {((web3.connected && !needsToken) || !!isTBA) && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                    disabled={!!isTBA}
                    onClick={() => {
                      if (user.username) {
                        //dispatch(createVault(id));
                      } else {
                        history.push("/sign-in");
                      }
                    }}
                    className={classes.button}
                  >
                    {buttonLoading ? (
                      <CircularProgress />
                    ) : (
                      <>{claimValue ? `Claim ${claimValue} GXP` : "Claim"}</>
                    )}
                  </Button>
                )}
              </Grid>
              <Grid xs item style={{ paddingTop: "0px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="large"
                  disabled={!!isTBA}
                  onClick={() => {
                    history.push(`${link}/${steam_game_id}`);
                  }}
                >
                  View Game
                </Button>
              </Grid>
            </Grid>
          </Actions>
        </CardContainer>
      );
    });
});

export default List;
