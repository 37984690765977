import { Button, Typography, makeStyles } from "@material-ui/core";
import { CURRENT_SEASON, SEASON_END_DATE } from "utils/environments";
import NumbersFormat from "assist/numbersFormat";
import NavBurger from "components/NavBurger";
import { openNav } from "dux/nav";
import DarkCoin from "images/header/GXP.png";
import GoldenCoin from "images/header/goldcoin.png";
import NFTCoin from "images/header/nftCoin.png";
import React, { useEffect, useRef } from "react";
import { getDisplayVal } from "blockchain";
import { useDispatch, useSelector } from "react-redux";
import {
  connect as connectWallet,
  getBalanceOf,
  balanceOfTokenByName,
  getUserUnclaimedNFTs,
} from "actions";
import {
  SET_PLAYER_CURRENCY_NFT,
  SET_PLAYER_CURRENCY_GOLD_COUNT,
} from "reducers/redux/Profile/PlayerCurrency/actions";
import RadioButtonCheckedSharpIcon from "@material-ui/icons/RadioButtonCheckedSharp";
import { Link } from "react-router-dom";
import { BLOCKCHAIN_INFO, TOKEN_INFO } from "blockchain/tokenInfo";
import Countdown, { calcTimeDelta } from "react-countdown";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";
import {
  HeaderBinance,
  HeaderCurrency,
  HeaderCountdown,
  CountdownWrapper,
  Logo,
  ButtonInfo,
  useStyles,
} from "./styles";

const style = makeStyles((theme) => ({
  tooltipButton: {
    margin: "auto 0 auto 10px",
    backgroundColor: "#cc0000",
  },
  s1: {
    textAlign: "center",
    "@media(max-width: 480px)": {
      display: "none",
    },
  },
}));

const Header = React.memo(() => {
  const nav = useSelector((state) => state.nav);
  const classes = useStyles(nav.open);
  const ref = useRef();
  const user = useSelector((store) => store.user);
  const gold = useSelector((state) => state.PlayerCurrency.gold);
  const PlayerCurrency = useSelector((state) => state.PlayerCurrency);
  const walletConnected = useSelector(({ web3 }) => web3.connected);
  const web3 = useSelector((state) => state.web3);
  const tttBalance = useSelector(
    (state) => state.balances[TOKEN_INFO.GameXChange?.addresses[web3.chainId]]
  );
  const dispatch = useDispatch();
  const styles = style();

  useEffect(() => {
    if (!user?.isLoggedIn) return;
    if (!web3.connected || web3.badNetwork) return;
    //dispatch(balanceOfTokenByName("GameXChange", user.eth_wallet_address));
    /*dispatch(balanceOfTokenByName("TapNFTs", user.eth_wallet_address)).then((res) => {
      dispatch(getUserUnclaimedNFTs()).then((res2) => {
        dispatch({ type: SET_PLAYER_CURRENCY_NFT, data: res + res2.length });
      });
    });*/
    dispatch({ type: SET_PLAYER_CURRENCY_GOLD_COUNT, data: user.gold });
  }, [user, web3.connected, web3.badNetwork, web3.chainId, dispatch]);

  const handleWalletConnect = () => {
    dispatch(connectWallet());
  };

  const invalidAddress =
    walletConnected?.length > 0
      ? walletConnected.localeCompare(user.eth_wallet_address, undefined, {
          sensitivity: "accent",
        }) !== 0
      : 0;

  const walletSlice = invalidAddress ? 6 : 8;

  const HeaderInvalidNetwork = () => (
    <HeaderBinance invalidNetwork={web3.badNetwork}>
      <span>Invalid Network</span>
      <RadioButtonCheckedSharpIcon />
    </HeaderBinance>
  );

  const HeaderUnlockWallet = () => (
    <HeaderCurrency>
      <Button variant="contained" color="primary" onClick={handleWalletConnect}>
        Unlock Wallet
      </Button>
    </HeaderCurrency>
  );

  const HeaderSeasonTimer = () => {
    const deltaTime = calcTimeDelta(new Date(SEASON_END_DATE));

    return (
      <HeaderCountdown>
        <Typography variant="h6" className={styles.s1}>
          Season {CURRENT_SEASON} Ends:
        </Typography>
        <CountdownWrapper>
          <Countdown date={Date.now() + deltaTime.total} />
        </CountdownWrapper>
      </HeaderCountdown>
    );
  };

  const Web3BadNetworkConditional = () =>
    web3.badNetwork ? <HeaderInvalidNetwork /> : <HeaderUnlockWallet />;

  return (
    <header className={classes.header}>
      {!nav.open && (
        <NavBurger className={classes.headerBurger} onClick={() => dispatch(openNav())} />
      )}
      <HeaderSeasonTimer />
      {walletConnected ? (
        <>
          <HeaderBinance invalidNetwork={web3.badNetwork}>
            <span>
              {web3.badNetwork ? "Invalid Network" : BLOCKCHAIN_INFO[web3.chainId].name}
            </span>
            <RadioButtonCheckedSharpIcon />
          </HeaderBinance>
          <HeaderCurrency>
            <span>{NumbersFormat(getDisplayVal(tttBalance || "0", 18), "k")}</span>
            <img src={DarkCoin} alt="" style={{ width: "30px" }} />
          </HeaderCurrency>
        </>
      ) : (
        <Web3BadNetworkConditional />
      )}
      {user.isLoggedIn ? (
        <>
          {/*<HeaderCurrency>
            <span>{NumbersFormat(gold.count)}</span>
            <img src={GoldenCoin} alt="" />
          </HeaderCurrency>
          <HeaderCurrency>
            <span>{PlayerCurrency.nfts.count}</span>
            <img src={NFTCoin} alt="" />
          </HeaderCurrency>*/}
          {user.avatar && (
            <Logo ref={ref.current}>
              <img src={user.avatar} alt="user avatar" />
            </Logo>
          )}
          {walletConnected?.length > 0 && (
            <HeaderBinance invalidAddress={invalidAddress}>
              <span>{`${walletConnected.slice(0, walletSlice)}...${walletConnected.slice(
                walletConnected.length - walletSlice
              )}`}</span>
              {invalidAddress && (
                <StandardToolTip
                  title="Warning: The connected wallet address does not match the address on your account!"
                  warning
                  buttonStyle={styles.tooltipButton}
                />
              )}
            </HeaderBinance>
          )}
        </>
      ) : (
        <HeaderCurrency>
          <Link to="/sign-in">
            <Button variant="contained" color="primary">
              Login
            </Button>
          </Link>
        </HeaderCurrency>
      )}
    </header>
  );
});

export default Header;
