import React from "react";
import { GamesStatistic } from "../../styles";

const SteamPCChallengesList = React.memo(({ nftCounts, Achievements }) => (
  <GamesStatistic>
    <div>{nftCounts} NFTs</div>
    <div>{Achievements} Achievements</div>
  </GamesStatistic>
));

export default SteamPCChallengesList;
