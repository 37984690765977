import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { setModal } from "actions/index";
import Text from "components/Text";

class GetWallet extends Component {
  render() {
    return (
      <Grid item xs={12} className="dashboard__no-games">
        <Text variant="h3">Create your Account</Text>
        <Text variant="h5" color="textSecondary">
          To create your own tap wallet and move your currency between games
        </Text>
        <Text variant="p2" className="mt--24" color="textSecondary">
          Your Tap Wallet is a smart contract linked to your Ethereum wallet. Giving you
          the ability to use and hold game currencies and items, which can be converted to
          Tap coin and back into other games currencies.
        </Text>

        <Button
          variant="text"
          color="primary"
          size="large"
          className="mt--40"
          component="span"
          onClick={() => {
            this.props.setModal("REGISTER");
          }}
        >
          Create Your Account
        </Button>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  modal: state.etc.modal,
});

const mapDispatchToProps = {
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetWallet);
