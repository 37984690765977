import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogText, DialogTitle } from "./styles";

const ModalBody = React.memo(({ title, values = [] }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    {!!values.length && (
      <DialogContent>
        <DialogText>
          {Array.isArray(values) ? (
            values.map((el, i) => <div key={el}>{el}</div>)
          ) : (
            <div>{values}</div>
          )}
        </DialogText>
      </DialogContent>
    )}
  </>
));

export default ModalBody;
