import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { memo, useState } from "react";
import Countdown, { calcTimeDelta } from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { PARTNER_TOKEN_RUN_ADDRESSES, BLOCKCHAIN_INFO } from "blockchain/tokenInfo";
import { openNFTModal } from "reducers/redux/NFTModal/actions";
import ContractButton from "components/buttons/ContractButton";
import NFTContainer from "components/containers/NFTContainer";
import { OuterNFTWrapper } from "../styles.js";

const PLTNFT = memo(({ pltnftData }) => {
  const {
    description,
    startDate,
    endDate,
    image,
    name,
    paymentContracts,
    currentTokenPrice,
    maxTokenId,
    currentTokenId,
    paymentToken,
    allowanceHandler,
    mintHandler,
    connectHandler,
    tokenSymbol,
    exchangeRate,
    requiredParticipationToken,
    requiredMinimumParticipationAmount,
  } = pltnftData;

  const modalData = {
    image,
    count: maxTokenId - (currentTokenId - 1),
    currentTokenPrice,
    name,
    description,
    tokenSymbol,
    exchangeRate,
  };

  const dispatch = useDispatch();
  const start = new Date(startDate);
  const now = new Date();

  const [started, setStarted] = useState(now > start);

  const deltaStartTime = calcTimeDelta(start);
  const deltaTime = calcTimeDelta(new Date(endDate));
  const web3 = useSelector((state) => state.web3);

  const loading = useSelector((state) => state.loading);
  let buttonIsLoading = false;
  if (loading.partialScreen["nftButtons--is-loading-nfts"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-handling-allowance"]) {
    buttonIsLoading = true;
  }
  if (loading.partialScreen["nftButtons--is-minting-nft"]) {
    buttonIsLoading = true;
  }

  return (
    <OuterNFTWrapper>
      <NFTContainer
        onClick={() => dispatch(openNFTModal(modalData))}
        nftData={pltnftData}
      />
      <Typography variant="h3" style={{ textAlign: "center" }}>
        {!started && "Time Left Until Start "}
      </Typography>
      <Countdown
        date={Date.now() + deltaStartTime.total}
        onComplete={() => setStarted(true)}
        daysInHours
      >
        <div>
          {deltaTime && (
            <Typography variant="h3" style={{ textAlign: "center" }}>
              Time Left: <Countdown date={Date.now() + deltaTime.total} daysInHours />
            </Typography>
          )}
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <ContractButton
              loading={buttonIsLoading}
              web3Connected={web3.connected}
              connectHandler={connectHandler}
              tokenSymbol={tokenSymbol}
              userWalletAllowance={
                paymentContracts?.[PARTNER_TOKEN_RUN_ADDRESSES[web3.chainId]]?.allowance
              }
              currentTokenPrice={currentTokenPrice}
              allowanceHandler={allowanceHandler}
              mintHandler={mintHandler}
              userBalance={
                paymentContracts?.[PARTNER_TOKEN_RUN_ADDRESSES[web3.chainId]]?.balance
              }
              paymentToken={paymentToken}
              hasParticipationAmount={
                paymentContracts?.[PARTNER_TOKEN_RUN_ADDRESSES[web3.chainId]]?.balance >=
                requiredMinimumParticipationAmount
              }
              participationAmount={requiredMinimumParticipationAmount}
              participationName={requiredParticipationToken}
              needsGXP={web3?.needsGXP}
            />
          </Grid>
        </div>
      </Countdown>
    </OuterNFTWrapper>
  );
});

export default PLTNFT;
