import React from "react";
import { NextButton } from "./styles";

const ButtonNext = ({ handler, disabled, text }) => (
  <NextButton onClick={handler} disabled={disabled}>
    {text}
  </NextButton>
);

export default ButtonNext;
