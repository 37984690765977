import React, { useEffect, useState } from "react";
import useStakingList from "hooks/useStakingList";
import useMoney from "hooks/useMoney";
import AddModal from "./AddModal";
import RemoveModal from "./RemoveModal";

const LiquidityModalContainer = ({
  rejectHandler,
  mode,
  /*balance,
  yieldAllowance,
  yieldBalance,
  yieldTotalBalance,
  buttonLoading,
  open,
  mode,
  successHandler,
  fromValue,
  setFromValue,
  */
  ...state
}) => {
  /*const [amountError, setAmountError] = useState("");
  const { getCurrent } = useStakingList(open);
  const current = getCurrent(open?.id);
  const { getCurrency } = useMoney();
  const money = getCurrency(current?.idCurrencies);

  useEffect(() => {
    setFromValue("");
    setAmountError("");
  }, [open, setFromValue, setAmountError]);

  const handlerChangeValue = (e, modalMode) => {
    let { value } = e.target;
    if (value && value.length > 0 && /^-?\d*\.?\d*$/.test(value)) {
      value = parseFloat(value);
      if (value == 0) {
        setAmountError("Amount cannot be 0");
      } else {
        setAmountError("");
      }
      setFromValue(e.target.value);
      if (modalMode === "stake") {
        if (value > parseFloat(balance)) {
          setAmountError("Amount cannot be greater than balance");
        }
      } else if (modalMode === "unstake") {
        if (value > parseFloat(yieldBalance)) {
          setAmountError("Amount cannot be greater than yield");
        }
      }
    } else if (modalMode === "max") {
      setFromValue("Max");
      setAmountError("");
    } else if (value === "Ma" || value.length < 1) {
      setFromValue("");
      setAmountError("");
    }
  };*/

  if (mode === "Add") {
    return (
      <AddModal
        closeHandler={rejectHandler}
        {...state}
        /*amountError={amountError}
      buttonLoading={buttonLoading}
      fromValue={fromValue}
      successHandler={successHandler}
      handlerChangeValue={handlerChangeValue}
      title="Stake"*/
      />
    );
  }
  if (mode === "Remove") {
    return (
      <RemoveModal
        closeHandler={rejectHandler}
        {...state}
        /*amountError={amountError}
      buttonLoading={buttonLoading}
      fromValue={fromValue}
      successHandler={successHandler}
      handlerChangeValue={handlerChangeValue}
      title="Stake"
      
      
      {...state}*/
      />
    );
  }
  return <></>;
};

export default LiquidityModalContainer;
