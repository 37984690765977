import React from "react";
import { FormName } from "./styles";
import { FormWrapper } from "../../buttons/styles";

export default ({ name, children }) => {
  return (
    <FormWrapper>
      <FormName>{name}</FormName>
      {children}
    </FormWrapper>
  );
};
