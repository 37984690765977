import axios from "axios";
import { IS_PROD } from "utils/environments";

const imageScriptURL = IS_PROD
  ? "https://img.tapplatform.io/stat/Scripts"
  : "http://159.203.228.102/Projects/uploader_test";
const imgFolder = IS_PROD
  ? "https://img.tapplatform.io/uploads"
  : "http://matthewwebb.tk/Projects/uploader_test/uploads";

export const getImageMD5 = (file) =>
  axios.post(`${imageScriptURL}/getMD5.php`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const getImageMD5s = (files) =>
  axios.post(`${imageScriptURL}/getMD5.php`, files, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
/** data should be formatted as followed :
 * {
 *  imageFile: image, || imageFiles: [images],
 *  id: id,
 *  type enum (publisher, game_feature, game_background, earned_currency, premium_currency);
 * }
 * */
export const postImage = (data) =>
  axios.post(`${imageScriptURL}/post.php`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const pubImage = (pubID, name, hash) => {
  switch (name) {
    case "logo":
      return `${imgFolder}/publisher/${pubID}/${name}?${hash}`;
    default:
      return "";
  }
};

export const gameImage = (game, name, hash) => {
  let gameID;
  if (game.id === undefined) {
    gameID = game;
  } else {
    gameID = game.id;
  }
  if (hash && hash.indexOf("http") !== -1) {
    return hash;
  }

  switch (name) {
    case "gallery":
      return `${imgFolder}/game/${gameID}/${name}/${hash}`;
    case "feature":
      return `${imgFolder}/game/${gameID}/${name}?${game.image_url}`;
    case "background":
      return `${imgFolder}/game/${gameID}/${name}?${game.game_card_background_image}`;
    case "premium":
      if (!game.premium_currency_image || game.premium_currency_image === "null") {
        return "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_premium.png";
      }
      return `${imgFolder}/game/${gameID}/${name}?${game.premium_currency_image}`;
    case "earned":
      if (!game.earned_currency_image || game.earned_currency_image === "null") {
        return "https://img.tapplatform.io/stat/Jugegostudio_Stunntrunner_earned.png";
      }
      return `${imgFolder}/game/${gameID}/${name}?${game.earned_currency_image}`;
    case "feature_character":
      return `${imgFolder}/game/${gameID}/character?${game.feature_character_url}`;
    default:
      return `${imgFolder}/game/${gameID}/${name}?${game.image_url}`;
  }
};

export const userImage = (id) => `${imgFolder}/user/${id}/avatar`;
