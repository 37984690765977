import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { button } from "../../../../../styles";

export const ButtonInfoContainer = styled.div`
  margin-right: 8px;
`;

export const ButtonInfo = styled(button)`
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  max-height: 21px;
  background-color: #7c7d82;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333a40",
    maxWidth: "280px",
    padding: "10px 20px",
    borderRadius: "4px",
    // fontFamily: '"Inter", sans-serif',
    fontFamily: '"Poppins',
    fontWeight: "400px",
    fontSize: "14px",
    lineHeight: "21px",
    position: "relative",
  },

  tooltipArrow: {
    "&::before": {
      backgroundColor: "#333a40",
    },
  },
}));
