import React from "react";
import "chart.js/auto";
import { Pie, Doughnut } from "react-chartjs-2";
import palette from "google-palette";

/*const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label(context) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.raw !== null) {
            label += Math.abs(context.raw);
          }
          return label;
        },
      },
    },
  },
  responsive: false,
};*/

const PieChart = ({ conversionData }) => {
  if (!conversionData.length) return <></>;

  var labels = [];
  var values = [];
  conversionData.forEach((element) => {
    labels.push(element.title);
    values.push(element.value);
  });
  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: palette("tol", conversionData.length).map(function (hex) {
          return "#" + hex;
        }),
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      //options={options}
      //width={275}
      //height={275}
      options={{
        //responsive: true,
        maintainAspectRatio: true,
        plugins: { legend: { display: false, position: "right", align: "center" } },
      }}
    />
  );
};

export default PieChart;
