import styled from "styled-components";
import { Flex } from "../../../styles";

export const FormName = styled.div`
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  line-height: 60px;
  margin-bottom: 20px;
`;

const text = styled.span`
  font-family: "Inter", sans-serif;
  line-height: 16px;
  font-weight: 500;
  color: #808191;
`;

export const FormLink = styled(text)`
  & a {
    text-decoration: none;
    color: #3f8cff;
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  display: flex;
  padding: 20px;
  text-align: center;
  justify-content: center;
  top: 24px;
  right: 24px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: #242731;
  }

  @media (max-width: 480px) {
    display: none;
    right: -20px;
    top: 0;
  }
`;

export const ButtonCloseAlwaysView = styled(ButtonClose)`
  @media (max-width: 480px) {
    display: block;
  }
`;

export const InputContainer = styled.div`
  padding-top: 18px;

  & > div {
    margin-top: 24px;
  }
`;

export const WrapperSmallInput = styled(Flex)`
  padding-top: 18px;
  margin-top: 0 !important;

  & > div {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;

    & > div {
      margin-right: 0;

      &:last-child {
        margin-top: 24px;
      }
    }
  }
`;
