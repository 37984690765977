import {
  allowanceOfERC20Token,
  balanceOfERC20Token,
  getChainBalance,
  getTokenFromAddressByChain,
} from "blockchain";
import appError from "utils/appError";
import { ZERO_ADDRESS } from "blockchain/tokenInfo";
import { ADD_PAYMENT_CONTRACT } from "./types";

export const getPaymentContract =
  (paymentAddress, blockchainId, targetAddress) => async (dispatch) => {
    const token = getTokenFromAddressByChain(paymentAddress, blockchainId);

    // further code assumes web3 is connected..

    if (paymentAddress === ZERO_ADDRESS) {
      const balance = await getChainBalance();
      const allowance = balance;
      dispatch({
        type: ADD_PAYMENT_CONTRACT,
        data: { balance, allowance, contractAddress: targetAddress },
      });

      return Promise.resolve({ balance, allowance, contractAddress: targetAddress });
    }

    // further code assumes not having a zero address..

    if (!paymentAddress) {
      return Promise.resolve(null);
    }
    try {
      const [balance, allowance] = await Promise.all([
        balanceOfERC20Token(blockchainId, token.key),
        allowanceOfERC20Token(blockchainId, token.key, targetAddress),
      ]);

      dispatch({
        type: ADD_PAYMENT_CONTRACT,
        data: { balance, allowance, contractAddress: targetAddress },
      });

      return Promise.resolve({ balance, allowance, contractAddress: targetAddress });
    } catch (e) {
      appError("The following error occurred while getting payment contracts... ", e);
    }
  };
