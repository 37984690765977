import NFTLable from "images/profile/playerCurrency/NFT-label.png";
import GXP from "images/profile/playerCurrency/GXPBottle.png";
import Steam from "images/profile/playerCurrency/steam.png";
import Coin from "images/profile/playerCurrency/coin.png";

import {
  SET_PLAYER_CURRENCY_CHALLENGE_TTT,
  SET_PLAYER_CURRENCY_GOLD_COUNT,
  SET_PLAYER_CURRENCY_TTT_COUNT,
  SET_PLAYER_CURRENCY_NFT,
} from "./actions";

const initialState = {
  tapcoins: {
    id: 1,
    image: GXP,
    name: "GXP",
    count: 0,
    currency: "GXP",
  },
  challenge: {
    id: 2,
    image: Steam,
    name: "Challenge Points",
    count: 0,
    currency: "GXP",
  },
  //gold: { id: 3, image: Coin, name: "Gold", count: 0, currency: "Gold" },
  nfts: { id: 3, image: NFTLable, name: "NFTs", count: 0, currency: "NFTs" },
};

const PlayerCurrencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER_CURRENCY_TTT_COUNT: {
      return { ...state, tapcoins: { ...state.tapcoins, count: action.data } };
    }
    case SET_PLAYER_CURRENCY_GOLD_COUNT: {
      return { ...state, gold: { ...state.gold, count: action.data } };
    }
    case SET_PLAYER_CURRENCY_CHALLENGE_TTT: {
      return {
        ...state,
        challenge: { ...state.challenge, count: action.data },
      };
    }
    case SET_PLAYER_CURRENCY_NFT: {
      return {
        ...state,
        nfts: { ...state.nfts, count: action.data },
      };
    }
    default:
      return state;
  }
};

export default PlayerCurrencyReducer;
