import { Typography, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { FlexRow, FlexColumn, ChartWrapper, Dot } from "./styles";
import ProfilePaper from "./ProfilePaper";
import PieChart from "./PieChart";
import palette from "google-palette";

const useStyles = makeStyles((theme) => ({
  center: {
    justifyContent: "center",
    "@media(max-width: 480px)": {
      whiteSpace: "nowrap",
      justifyContent: "space-between",
    },
  },
  image: {
    borderRadius: "25px",
    width: "48px",
    height: "48px",
  },
  imageWrapper: {
    justifyContent: "center",
    marginRight: "10px",
    marginBottom: "17px",
  },
  value: {
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "14px",
  },
  description: {
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontSize: "12px",
    color: "slategrey",
  },
  statsWrapper: {
    justifyContent: "space-between",
    flexDirection: "column",
    alignSelf: "flex-start",
    whiteSpace: "nowrap",
  },
}));

const ProfileStats = React.memo(() => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const conversionStats = useSelector((state) => state.vaults.conversion_stats);

  if (!conversionStats?.length) return <></>;

  const renderPoints = (stats) => {
    const points = palette("tol", stats.length).map(function (hex, index) {
      return (
        <Grid container item lg={2} md={3} sm={3} xs={2}>
          <FlexRow>
            <FlexColumn className={classes.imageWrapper}>
              <Dot color={"#" + hex} />
            </FlexColumn>
            <FlexColumn className={classes.center}>
              <Typography className={classes.value}>{stats[index].title}</Typography>
              <Typography className={classes.description}>
                {stats[index].value} Points
              </Typography>
            </FlexColumn>
          </FlexRow>
        </Grid>
      );
    });
    return points;
  };

  return (
    <ProfilePaper title="Stats">
      <Grid container direction="row" xs={12} spacing={2}>
        <Grid container item md={4} xs={12} justifyContent="center">
          <ChartWrapper>
            <PieChart conversionData={conversionStats} />
          </ChartWrapper>
        </Grid>

        <Grid container item md={8} xs={12} className={classes.statsWrapper}>
          {renderPoints(conversionStats)}
        </Grid>
      </Grid>
    </ProfilePaper>
  );
});

export default ProfileStats;
