import React, { useState, useEffect } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import SwapIcon from "@material-ui/icons/SwapVertSharp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LoopIcon from "@material-ui/icons/Loop";
import ExchangeButton from "components/buttons/ExchangeButton";
import ApprovalModalContainer from "components/Modal/ApprovalModal";
import ExchangeModalContainer from "components/Modal/ExchangeModal";
import {
  getPairsForTrade,
  getTradeExactIn,
  makeTradeExactIn,
  getTradeExactOut,
  makeTradeExactOut,
  getTradeBalances,
  getCurrenciesWithPrices,
  getTopPairs,
} from "utils/web3/exchange";
import { exchangeTokenList, Routers } from "blockchain/tokenInfo";
import { notEmpty, roundIntToDecimals } from "utils/helpers";
import { getDisplayVal } from "blockchain/web3Utils";
import {
  Name,
  FlexColumn,
  Subtitle,
  ContainerItemHeader,
  ContainerItemContent,
  ContainerItemFooter,
  ContainerItem,
  LiquidityDescription,
  Descriptions,
  Description,
  SubHeader,
} from "components/Exchange/styles";

import usePrevious from "hooks/usePrevious";
import { useSelector, useDispatch } from "react-redux";
import { TradeType } from "utils/constants";
import GlobalSettings from "../GlobalSettings";
import ContainerInput from "../ContainerInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    marginBottom: "5px",
    backgroundColor: theme.palette.text.secondary,
  },
  iconButton: {
    borderRadius: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    height: "32px",
    width: "32px",
    backgroundColor: "#6e43e6",
  },
  priceButton: {
    marginLeft: "5px",
    height: "16px",
    width: "16px",
  },
  priceData: {
    marginTop: "20px",
  },
}));

const Swap = ({
  setActiveTab,
  connected,
  badNetwork,
  connectHandler,
  settingsHandler,
  showStats,
  setShowStats,
  setTopPairs,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [trade, setTrade] = useState();
  // const [currencyOptions, setCurrencyOptions] = useState([]);
  const [fromCurrency, setFromCurrency] = useState(1);
  // const prevFromCurrency = usePrevious(fromCurrency);
  const [toCurrency, setToCurrency] = useState(2);
  // const prevToCurrency = usePrevious(toCurrency);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [priceData, setPriceData] = useState();
  const [amount, setAmount] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const [amountInFromCurrency, setAmountInFromCurrency] = useState(true);
  const [reversePrice, setReversePrice] = useState(false);
  const [fromAllowance, setFromAllowance] = useState();
  const [fromBalance, setFromBalance] = useState();
  const [toBalance, setToBalance] = useState();

  const [openModalApproval, setOpenModalApproval] = useState(null);
  const [openModalExchange, setOpenModalExchange] = useState(null);

  const [pairReserves, setPairReserves] = useState([]);
  const exchangeSettings = useSelector((state) => state.Exchange);
  const pricedCurrencies = useSelector((state) => state.ExchangeData?.currencies);
  const allowances = useSelector((state) => state.allowances);
  const balances = useSelector((state) => state.balances);
  const web3 = useSelector((state) => state.web3);

  useEffect(() => {
    setPriceData({
      "Price:": "???",
      "Slippage Tolerance:": `${exchangeSettings.userSlippageTolerance / 100}%`,
    });
  }, [exchangeSettings.userSlippageTolerance]);
  useEffect(() => {
    if (currencies[fromCurrency] && currencies[toCurrency]) {
      if (exchangeRate == 0) {
        setPriceData({
          "Price:": "???",
          "Slippage Tolerance:": `${exchangeSettings.userSlippageTolerance / 100}%`,
        });
      } else {
        setPriceData({
          "Price:": (
            <>
              {!reversePrice ? (
                <span>
                  {roundIntToDecimals(exchangeRate, 10)} {currencies[fromCurrency].symbol}{" "}
                  per {currencies[toCurrency].symbol}
                </span>
              ) : (
                <span>
                  {roundIntToDecimals(1 / exchangeRate, 10)}{" "}
                  {currencies[toCurrency].symbol} per {currencies[fromCurrency].symbol}
                </span>
              )}
              <IconButton
                className={classes.priceButton}
                onClick={() => setReversePrice((prevState) => !prevState)}
              >
                <LoopIcon />
              </IconButton>
            </>
          ),
          "Slippage Tolerance:": `${exchangeSettings.userSlippageTolerance / 100}%`,
        });
      }
    }
  }, [reversePrice, fromCurrency, toCurrency, exchangeRate]);

  const rejectHandler = () => {
    setOpenModalExchange(null);
    setOpenModalApproval(null);
  };

  const successHandler = () => {
    dispatch(getTradeBalances(currencies[fromCurrency], currencies[toCurrency]));
    getPairsForTrade(currencies[fromCurrency], currencies[toCurrency], pairReserves).then(
      (pairs) => {
        setPairReserves(pairs);
      }
    );
  };

  useEffect(() => {
    if (!web3.chainId) {
      return;
    }
    if (currencies[fromCurrency]) {
      const fromAddress = currencies[fromCurrency].address;
      if (allowances[fromAddress]) {
        const allowance = allowances[fromAddress][Routers[web3.chainId]];
        if (allowance !== undefined) {
          setFromAllowance(
            parseFloat(getDisplayVal(allowance, currencies[fromCurrency].decimals))
          );
        }
      }
    }
  }, [currencies, allowances, fromCurrency, web3.chainId]);

  useEffect(() => {
    if (currencies[fromCurrency]) {
      const fromAddress = currencies[fromCurrency].address;
      const balance = balances[fromAddress];
      if (balance) {
        setFromBalance(getDisplayVal(balance, currencies[fromCurrency].decimals));
      }
    }
  }, [currencies, balances, fromCurrency, web3.chainId]);

  useEffect(() => {
    if (currencies[toCurrency]) {
      const toAddress = currencies[toCurrency].address;
      const balance = balances[toAddress];
      if (balance) {
        setToBalance(getDisplayVal(balance, currencies[toCurrency].decimals));
      }
    }
  }, [currencies, balances, toCurrency, web3.chainId]);

  const descriptions = (data) => {
    const res = [];
    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <SubHeader>{key}</SubHeader>
          <LiquidityDescription>{data[key]}</LiquidityDescription>
        </Description>
      );
    });

    return res;
  };

  useEffect(() => {
    setCurrencies(exchangeTokenList(web3.chainId));
  }, [web3.chainId]);

  useEffect(() => {
    dispatch(getCurrenciesWithPrices()); //Temporary until prices are cached on server
  }, []);

  useEffect(() => {
    if (notEmpty(currencies[fromCurrency]) && notEmpty(currencies[toCurrency])) {
      //If currencies change, grab new exchange rate data for From field from API
      getPairsForTrade(
        currencies[fromCurrency],
        currencies[toCurrency],
        pairReserves
      ).then((pairs) => {
        setPairReserves(pairs);
      });
      dispatch(getTradeBalances(currencies[fromCurrency], currencies[toCurrency]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies, fromCurrency, toCurrency]);

  useEffect(() => {
    setLoading(true);
    if (
      !amountInFromCurrency &&
      toAmount > 0 &&
      pairReserves.length > 0 &&
      currencies[fromCurrency] &&
      currencies[toCurrency]
    ) {
      let info;
      if (trade && trade.tradeType === TradeType.EXACT_OUTPUT) {
        info = getTradeExactOut(trade, toAmount);
      }
      if (!info) {
        info = makeTradeExactOut(
          toAmount,
          currencies[fromCurrency],
          currencies[toCurrency],
          pairReserves
        );
      }
      if (info) {
        setTrade(info);
        const input = parseFloat(info.inputAmount.toSignificant());
        setExchangeRate(0);
        setExchangeRate(input / toAmount);
      } else {
        // check for direct link
        setTrade(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [pairReserves, toAmount]);

  useEffect(() => {
    setLoading(true);
    if (
      amountInFromCurrency &&
      amount > 0 &&
      pairReserves.length > 0 &&
      currencies[fromCurrency] &&
      currencies[toCurrency]
    ) {
      let info;
      if (trade && trade.tradeType === TradeType.EXACT_INPUT) {
        info = getTradeExactIn(trade, amount);
      }
      if (!info) {
        info = makeTradeExactIn(
          amount,
          currencies[fromCurrency],
          currencies[toCurrency],
          pairReserves
        );
      }
      if (info) {
        setTrade(info);
        const output = parseFloat(info.outputAmount.toSignificant());
        setExchangeRate(amount / output);
      } else {
        setTrade(false);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [pairReserves, amount]);

  useEffect(() => {
    if (pairReserves.length > 0 && Object.keys(pricedCurrencies).length > 0) {
      getTopPairs(pairReserves, pricedCurrencies).then((res) => {
        setTopPairs(res);
      });
    }
  }, [pairReserves, pricedCurrencies]);

  useEffect(() => {
    if (exchangeRate > 0) {
      if (amountInFromCurrency) {
        setToAmount((parseFloat(amount) / exchangeRate).toPrecision(6) / 1);
      } else {
        setAmount((parseFloat(toAmount) * exchangeRate).toPrecision(6) / 1);
      }
    }
  }, [exchangeRate, amount, toAmount]);

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handleSwap = () => {
    // do the swap!
    setOpenModalExchange({
      trade,
      from: currencies[fromCurrency],
      to: currencies[toCurrency],
    });
  };

  const handleApprove = (token) => {
    setOpenModalApproval({ token });
  };

  const handleFromAmountChange = (e) => {
    if (!isNumber(e) && e.length > 0) {
      return;
    }

    setToAmount(0);
    setAmount(e);
    setAmountInFromCurrency(true);
  };

  const handleToAmountChange = (e) => {
    if (!isNumber(e) && e.length > 0) {
      return;
    }
    setAmount(0);
    setToAmount(e);
    setAmountInFromCurrency(false);
  };

  const handleSwitchDirection = () => {
    var temp = fromCurrency;
    setFromCurrency(toCurrency);
    setToCurrency(temp);
    setExchangeRate(0);
    setToAmount(0);
    setTrade(false);
    setAmount(0);
    setAmountInFromCurrency(!amountInFromCurrency);
  };

  const handleFromCurrencyChange = (e) => {
    setTrade(false);
    if (e === toCurrency) {
      handleSwitchDirection();
    } else {
      setExchangeRate(0);
      setFromCurrency(e);
      if (amountInFromCurrency) {
        setToAmount(0);
      } else {
        setAmount(0);
      }
    }
  };

  const handleToCurrencyChange = (e) => {
    setTrade(false);
    if (e === fromCurrency) {
      handleSwitchDirection();
    } else {
      setExchangeRate(0);
      setToCurrency(e);
      if (!amountInFromCurrency) {
        setAmount(0);
      } else {
        setToAmount(0);
      }
    }
  };

  return (
    <ContainerItem elevation={3}>
      <ContainerItemHeader>
        <IconButton onClick={() => setShowStats((prevState) => !prevState)}>
          {showStats ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
        <FlexColumn>
          <Name style={{ justifyContent: "center" }}>Swap</Name>
          <Subtitle style={{ textAlign: "center" }}>Trade tokens in an instant</Subtitle>
        </FlexColumn>
        <GlobalSettings />
      </ContainerItemHeader>
      <ContainerItemContent>
        {currencies.length > 0 && (
          <ContainerInput
            label="From"
            currencyOptions={currencies}
            selectedCurrency={fromCurrency}
            onChangeCurrency={(e) => handleFromCurrencyChange(e.target.value)}
            onChangeAmount={(e) => handleFromAmountChange(e?.target?.value ?? e)}
            amount={amount || ""}
            balance={fromBalance}
          />
        )}
        <IconButton
          variant="contained"
          color="primary"
          onClick={(e) => handleSwitchDirection(e)}
          className={classes.iconButton}
        >
          <SwapIcon />
        </IconButton>
        {currencies.length > 0 && (
          <ContainerInput
            label="To"
            currencyOptions={currencies}
            selectedCurrency={toCurrency}
            onChangeCurrency={(e) => handleToCurrencyChange(e.target.value)}
            onChangeAmount={(e) => handleToAmountChange(e?.target?.value ?? e)}
            amount={toAmount || ""}
            balance={toBalance}
            hideMax
            className={classes.lastInput}
          />
        )}
        <Descriptions className={classes.priceData}>
          {priceData && descriptions(priceData)}
        </Descriptions>
      </ContainerItemContent>
      <ContainerItemFooter>
        <ExchangeButton
          loading={loading}
          //allowanceHandler={() => allowanceHandler(saleId)}
          noTrade={!trade}
          connected={connected}
          badNetwork={badNetwork}
          connectHandler={connectHandler}
          swapHandler={handleSwap}
          approveHandler={handleApprove}
          fromApproval={fromAllowance}
          fromBalance={fromBalance}
          fromAmount={amount}
          toAmount={toAmount}
          amountInFromCurrency={amountInFromCurrency}
          fromCurrency={currencies[fromCurrency]}
          toCurrency={currencies[toCurrency]}
        />
      </ContainerItemFooter>
      {!!openModalExchange && (
        <ExchangeModalContainer
          open={!!openModalExchange}
          // message={message}
          closeHandler={rejectHandler}
          successHandler={successHandler}
          rejectHandler={rejectHandler}
          web3={web3}
          {...openModalExchange}
        />
      )}
      {!!openModalApproval && (
        <ApprovalModalContainer
          open={!!openModalApproval}
          closeHandler={rejectHandler}
          successHandler={successHandler}
          rejectHandler={rejectHandler}
          web3={web3}
          {...openModalApproval}
        />
      )}
    </ContainerItem>
  );
};

export default Swap;
