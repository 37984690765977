import { SvgIcon } from "@material-ui/core";
import { closeNav } from "dux/nav";
import { ReactComponent as Arrow } from "images/icons/arrowLink.svg";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import breakpoints from "utils/breakpoints";
import useWindowSize from "../../../hooks/useWindowSize";
import { ListItem, ListItemWrapper, useStyles } from "./styles";
import MenuText from "./MenuText";

const ListMenu = React.memo(({ list }) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const nav = useSelector((state) => state.nav);
  const classes = useStyles(nav.open);
  const dispatch = useDispatch();

  const itemName = (name) =>
    name.find((el) => el.route === location.pathname)?.text ||
    name.find((el) => el.route === "*")?.text;

  const closeIfTabletOrMobile = useCallback(() => {
    if (width < breakpoints.md) {
      dispatch(closeNav());
    }
  }, [dispatch, width]);

  return list.map(({ name, image, id, route, viewBox, exact, disabled, fill }) => (
    <ListItemWrapper
      exact={exact || false}
      to={route}
      open={nav.open}
      key={id}
      disabled={disabled}
      activeClassName={classes.active}
      onClick={closeIfTabletOrMobile}
    >
      <ListItem key={id}>
        <SvgIcon
          fill={fill}
          component={image}
          viewBox={viewBox}
          classes={{ root: classes.svg }}
        />
        <MenuText open={nav.open}>{itemName(name)}</MenuText>
        {nav.open && (
          <SvgIcon
            component={Arrow}
            viewBox={viewBox}
            classes={{ root: classes.arrowsvg }}
          />
        )}
      </ListItem>
    </ListItemWrapper>
  ));
});

export default ListMenu;
