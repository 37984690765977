import { fetchMarketListings, fetchTokenSales } from "actions";
import { getTokenFromAddress, getPLTNFTInfo } from "blockchain";
import {
  TOKEN_INFO,
  PARTNER_TOKEN_RUN_ADDRESSES,
  LTNFT_TOKEN_SYMBOL,
  getTokenFromAddressByChain,
} from "blockchain/tokenInfo";
import { loadedPartialScreen } from "dux/loading";
import appError from "utils/appError";
import { getFeatured, getSponsored, getMarketplaceNFT } from "utils/nftAPI";
import { getLTMRuns } from "utils/genericAxios";
import {
  getMarketAddress,
  getTokenMetaDataURL,
  getBalanceOf,
  getAllowance,
  chainLinkPrice,
} from "utils/web3Calls";
import { WEB3_ALLOWANCE_SET } from "reducers/redux/Web3/Allowance/types";
import { WEB3_BALANCE_SET } from "reducers/redux/Web3/Balances/types";
import { getPaymentContract } from "./paymentContracts";
import { GET_PLTNFTS, GET_NFTS, GET_FEATURED_NFTS, GET_SPONSORED_NFT } from "./types";

const PARTNER_COLLECTION_ID = "61441e976be367ab40398d87";

export const getNFTs = (start, length, web3Store) => async (dispatch) => {
  let marketContract = false;
  try {
    marketContract = await getMarketAddress().catch();
  } catch {}
  if (!marketContract) {
    return null;
  }
  return fetchMarketListings(start, length)
    .then((response) => {
      const paymentTokens = {};
      const tokenInfoPromises = response.listings.map(async (listing) => {
        const payment = getTokenFromAddress(listing.paymentContract);
        const blockchainId = web3Store.chainId;

        const holderTokenInfo = getTokenFromAddressByChain(
          listing.participationAddress,
          blockchainId
        );

        let USDPrice = false;
        try {
          USDPrice = await chainLinkPrice(payment.symbol, web3Store.chainId);
        } catch (e) {}

        const listingNFTData = {
          currentTokenPrice: listing.price,
          creator: listing.creator,
          paymentContract: listing.paymentContract,
          marketContract,
          saleId: listing.id,
          USDPrice,
          payment,
          participationToken: holderTokenInfo,
          participationAddress: listing.participationAddress,
          participationAmount: listing.participationAmount,
          count: listing.tokenIdFinish - listing.tokenIdCurrent + 1,
          countMax: listing.tokenIdFinish - listing.tokenIdStart + 1,
          blockchainId,
        };
        paymentTokens[listing.paymentContract] = true;
        paymentTokens[listing.participationAddress] = true;

        return getTokenMetaDataURL(listing.marketContract, listing.tokenIdCurrent)
          .then((url) =>
            fetch(url).then((nftResponse) =>
              nftResponse.json().then((nftJSON) => {
                const { _id: id, ...restNftJSON } = nftJSON;
                return { ...listingNFTData, ...restNftJSON, id };
              })
            )
          )
          .catch(() => {
            return getMarketplaceNFT(
              blockchainId,
              listing.marketContract,
              listing.tokenIdCurrent
            ).then((restNftJSON) => {
              return {
                ...listingNFTData,
                name: restNftJSON.name,
                image: restNftJSON.image,
                ...restNftJSON,
              };
            });
          });
      });
      return Promise.all(tokenInfoPromises).then((data) => {
        if (web3Store.connected) {
          Object.keys(paymentTokens).forEach((contract) => {
            getBalanceOf(contract).then((result) => {
              dispatch({
                type: WEB3_BALANCE_SET,
                contract,
                balance: result.toString(),
              });
            });
            getAllowance(contract, marketContract).then((result) => {
              dispatch({
                type: WEB3_ALLOWANCE_SET,
                toContract: marketContract,
                contract,
                allowance: result.toString(),
              });
            });
          });
        }
        return data;
      });
    })
    .then((nfts) => {
      dispatch({
        type: GET_NFTS,
        nfts,
      });
    })
    .catch((err) => {
      appError("the following error occured getting nfts...", err);
    });
};

export const getPLTNFTs = (web3Store) => async (dispatch) => {
  const ltmRunResponse = await getLTMRuns(PARTNER_COLLECTION_ID);

  const nfts = await Promise.all(
    ltmRunResponse.ltnft.map(async (nft) => {
      const {
        active,
        blockchainId,
        description,
        endDate,
        id,
        image,
        name,
        runId,
        startDate,
        requiredMinimumParticipationAmount,
        requiredParticipationToken,
      } = nft;

      const LTNFTInfo = await getPLTNFTInfo(LTNFT_TOKEN_SYMBOL, blockchainId, runId);

      if (!LTNFTInfo) {
        return null;
      }
      const { paymentToken: address } = LTNFTInfo;

      // get payment contract for nfts
      await dispatch(
        getPaymentContract(
          address,
          blockchainId,
          PARTNER_TOKEN_RUN_ADDRESSES[blockchainId],
          web3Store
        )
      );

      if (requiredParticipationToken && requiredMinimumParticipationAmount > 0) {
        getPaymentContract(
          TOKEN_INFO[requiredParticipationToken].addresses[blockchainId],
          blockchainId,
          PARTNER_TOKEN_RUN_ADDRESSES[blockchainId],
          web3Store
        );
      }

      const token = getTokenFromAddressByChain(address, blockchainId);
      const { name: tokenName, symbol: tokenSymbol, exchangeRate } = token;
      let USDPrice = false;
      try {
        USDPrice = await chainLinkPrice(token.symbol, web3Store.chainId);
      } catch (e) {}

      return {
        ...LTNFTInfo,
        active,
        blockchainId,
        description,
        endDate,
        id,
        image,
        name,
        runId,
        USDPrice,
        startDate,
        tokenSymbol,
        tokenName,
        exchangeRate,
        requiredMinimumParticipationAmount,
        requiredParticipationToken,
        requiredParticipationTokenAddress: requiredParticipationToken
          ? TOKEN_INFO[requiredParticipationToken].addresses[blockchainId]
          : false,
      };
    })
  );

  dispatch(loadedPartialScreen(`nftButtons--is-loading-nfts`));

  dispatch({
    type: GET_PLTNFTS,
    nfts: nfts.filter((nft) => nft),
  });
};

export const getFeaturedNFTs = (web3Store) => async (dispatch) => {
  const featuredNFTResponse = await getFeatured().catch((e) => {
    console.error("Error getting featured NFTs", e);
  });
  if (!featuredNFTResponse) {
    return;
  }
  const marketContract = await getMarketAddress().catch();
  const nfts = await Promise.all(
    featuredNFTResponse.map(async (nft) => {
      const {
        _id: id,
        description,
        longDescription,
        image,
        name,
        runId,
        startDate,
        requiredMinimumParticipationAmount,
        requiredParticipationToken,
        marketplace,
      } = nft;
      const { marketplaceContractIndex } = marketplace;
      if (!marketplaceContractIndex) return null;
      const paymentTokens = {};
      return fetchTokenSales(marketplaceContractIndex)
        .then(async (res) => {
          const featuredNFTInfo = {
            blockchainId: web3Store.chainId,
            creator: res.creator,
            marketContract,
            saleId: res.id,
            marketId: res.marketContract,
            marketIndex: res.marketIndex,
            participationAddress: res.participationAddress,
            participationAmount: res.participationAmount.toString(),
            paymentContract: res.paymentContract,
            payment: getTokenFromAddressByChain(res.paymentContract, web3Store.chainId),
            currentTokenPrice: res.price.toString(),
            requiredOwnershipIndex: res.requiredOwnershipIndex,
            count: res.tokenIdFinish.toString() - res.tokenIdCurrent.toString() + 1,
            countMax: res.tokenIdFinish.toString() - res.tokenIdStart.toString() + 1,
          };
          paymentTokens[res.paymentContract] = true;
          paymentTokens[res.participationAddress] = true;

          if (web3Store.connected) {
            Object.keys(paymentTokens).forEach((contract) => {
              getBalanceOf(contract).then((result) => {
                dispatch({
                  type: WEB3_BALANCE_SET,
                  contract,
                  balance: result.toString(),
                });
              });
              getAllowance(contract, res.marketContract).then((result) => {
                dispatch({
                  type: WEB3_ALLOWANCE_SET,
                  toContract: res.marketContract,
                  contract,
                  allowance: result.toString(),
                });
              });
            });
          }

          let USDPrice = false;
          try {
            USDPrice = await chainLinkPrice(
              featuredNFTInfo.payment.symbol,
              web3Store.chainId
            );
          } catch (e) {}

          return {
            ...featuredNFTInfo,
            description,
            longDescription,
            id,
            image,
            name,
            runId,
            USDPrice,
            startDate,
            requiredMinimumParticipationAmount,
            requiredParticipationToken,
            requiredParticipationTokenAddress: requiredParticipationToken
              ? TOKEN_INFO[requiredParticipationToken].addresses[res.blockchainId]
              : false,
          };
        })
        .catch((e) => {
          console.error("Featured NFTs", e);
        });
    })
  );

  dispatch({
    type: GET_FEATURED_NFTS,
    nfts: nfts.filter((nft) => nft),
  });
};

export const getSponsoredNFT = (web3Store) => async (dispatch) => {
  const sponsoredNFTResponse = await getSponsored();
  if (!sponsoredNFTResponse || sponsoredNFTResponse.length == 0) {
    console.error("No Sponsored NFTs could be found");
    return;
  }

  const sponsoredNFT = sponsoredNFTResponse.find(
    (element) => !!element.marketplace.marketplaceContractIndex
  );

  if (!sponsoredNFT) {
    console.error(
      `No marketplace Contract Index found for Sponsored NFT ${sponsoredNFT?._id}`
    );
    return;
  }
  const {
    description,
    longDescription,
    _id: id,
    image,
    name,
    requiredMinimumParticipationAmount,
    requiredParticipationToken,
    marketplace,
  } = sponsoredNFT;
  const marketContract = await getMarketAddress().catch();
  const { marketplaceContractIndex } = marketplace;
  const paymentTokens = {};
  fetchTokenSales(marketplaceContractIndex)
    .then(async (res) => {
      const featuredNFTInfo = {
        blockchainId: web3Store.chainId,
        creator: res.creator,
        marketContract,
        saleId: marketplaceContractIndex,
        marketId: res.marketContract,
        marketIndex: res.marketIndex,
        participationAddress: res.participationAddress,
        participationAmount: res.participationAmount.toString(),
        paymentContract: res.paymentContract,
        payment: getTokenFromAddress(res.paymentContract),
        currentTokenPrice: res.price.toString(),
        requiredOwnershipIndex: res.requiredOwnershipIndex,
        count: res.tokenIdFinish.toString() - res.tokenIdCurrent.toString() + 1,
        countMax: res.tokenIdFinish.toString() - res.tokenIdStart.toString() + 1,
      };
      paymentTokens[res.paymentContract] = true;
      paymentTokens[res.participationAddress] = true;

      if (web3Store.connected) {
        Object.keys(paymentTokens).forEach((contract) => {
          getBalanceOf(contract).then((result) => {
            dispatch({
              type: WEB3_BALANCE_SET,
              contract,
              balance: result.toString(),
            });
          });
          getAllowance(contract, res.marketContract).then((result) => {
            dispatch({
              type: WEB3_ALLOWANCE_SET,
              toContract: res.marketContract,
              contract,
              allowance: result.toString(),
            });
          });
        });
      }

      const sponsored = {
        ...featuredNFTInfo,
        description,
        longDescription,
        id,
        image,
        name,
        requiredMinimumParticipationAmount,
        requiredParticipationToken,
        requiredParticipationTokenAddress: requiredParticipationToken
          ? TOKEN_INFO[requiredParticipationToken].addresses[res.blockchainId]
          : false,
      };

      dispatch({
        type: GET_SPONSORED_NFT,
        nfts: sponsored,
      });
    })
    .catch((e) =>
      console.error(
        `Error with fetching token with marketplaceContractIndex of ${marketplaceContractIndex}:`,
        e
      )
    );
};
