import ReactGA from "react-ga";
import { SUCCESS_AUTH, SUCCESS_DEAUTH } from "reducers/redux/Signup/types";
import appError from "utils/appError";
import {
  deleteBearerTokenFromLocalStorage,
  setBearerTokenToLocalStorage,
} from "utils/bearerToken";
import { IS_PROD } from "utils/environments";
import { history } from "../store/index";
import * as ApiRequest from "../utils/apiRequest";
import {
  CHALLENGES_CLEAR,
  CREDENTIAL_CLEAR,
  CREDENTIAL_UPDATE,
  FORM_ERROR,
  FORM_SUCCESS,
  VALIDATE_EMAIL,
  REFERRAL_CODE,
  VAULT_CLEAR,
  UPDATE_CURRENCIES,
  REFERRAL_LEADERBOARD,
  CONVERSION_LEADERBOARD,
} from "./types";

const setAccountInfo = (response, token = false) => {
  if (token) {
    setBearerTokenToLocalStorage(token);
  }
  window.current_wallet_address = response.eth_wallet_address;
  IS_PROD && ReactGA.set({ userId: response.id });
  IS_PROD && ReactGA.set({ username: response.username });

  IS_PROD &&
    window.fcWidget.user.get(() => {
      window.fcWidget.user.setProperties({
        firstName: response.first_name,
        lastName: response.last_name,
        email: response.email_address,
      });
    });
};

export const getReferral = () => (dispatch) => {
  ApiRequest.getReferral().then((response) => {
    dispatch({
      type: REFERRAL_CODE,
      referral: response.data.data.referralCode.code,
    });
  });
};

export const fetchReferralLeaderboard = () => (dispatch) => {
  ApiRequest.fetchReferralLeaderboard().then((response) => {
    dispatch({
      type: REFERRAL_LEADERBOARD,
      leaderboard: response.data.data,
    });
  });
};

export const fetchConversionLeaderboard = () => (dispatch) => {
  ApiRequest.fetchConversionLeaderboard().then((response) => {
    dispatch({
      type: CONVERSION_LEADERBOARD,
      leaderboard: response.data.data,
    });
  });
};

export const fetchLeaderboards = () => (dispatch) => {
  dispatch(fetchReferralLeaderboard());
  dispatch(fetchConversionLeaderboard());
};

export const signIn = (username, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.signIn(username, password)
      .then((response) => {
        setAccountInfo(response.data.data, response.data.token);
        // ensure consistent user state
        ApiRequest.getCurrentUser().then((response2) => {
          const { user } = response2.data.data;
          dispatch({
            type: CREDENTIAL_UPDATE,
            user,
          });
          dispatch({
            type: SUCCESS_AUTH,
          });
          resolve();
        });
      })
      .catch((error) => {
        let msg;
        if (error.response && error.response.status === 418) {
          history.push("/blocked");
        } else {
          try {
            msg = error.response.data.message || error.response.data || error.message;
          } catch (e) {
            msg = `Could not connect to server. ${error.message}`;
          }
        }
        reject(msg);
      });
  });

export const signOut = () => (dispatch) => {
  dispatch({ type: CREDENTIAL_CLEAR });
  history.push("/dashboard");
  deleteBearerTokenFromLocalStorage();
  ApiRequest.signOut();
  dispatch({ type: VAULT_CLEAR });
  dispatch({ type: CHALLENGES_CLEAR });
};

export const getCurrentUser = () => (dispatch) => {
  return ApiRequest.getCurrentUser()
    .then((userRes) => {
      const userData = userRes.data.data.user;
      setAccountInfo(userData);
      const user = userData;
      dispatch({
        type: CREDENTIAL_UPDATE,
        user,
      });
      dispatch({
        type: SUCCESS_AUTH,
      });
      dispatch(getReferral());
      return user;
      //   });
    })
    .catch((err) => {
      // if user is unauthenticated delete the token from local storage
      // and force them to log in again
      if (
        err?.response?.status === 401 ||
        (!IS_PROD && err?.message === "Network Error") ||
        err?.response?.data?.message === "invalid token"
      ) {
        deleteBearerTokenFromLocalStorage();
      }
      dispatch({
        type: SUCCESS_AUTH,
      });
      appError("The following error occured getting current user...", err);
    });
};

export const registerUser = (user) => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.register(user)
      .then((response) => {
        IS_PROD && ReactGA.event({ category: "User", action: "Created an account" });
        setAccountInfo(response.data.data, response.data.token);
        getCurrentUser();
        resolve("Success");
      })
      .catch((error) => {
        let msg;
        try {
          msg = error.response.data.message;
        } catch (e) {
          msg = "Could not connect to server.";
        }
        reject(msg);
      });
  });

export const registerPublisher = (user) => (dispatch) => {
  ApiRequest.registerPublisher(user).then((response) => {
    IS_PROD && ReactGA.event({ category: "Publisher", action: "Created an account" });
    setAccountInfo(response);
    dispatch({
      type: CREDENTIAL_UPDATE,
      user: response.data.data,
    });
    history.push("/pub/onboarding/company/info");
  });
};

export const isValidUsername = (username) =>
  ApiRequest.validUsername(username).then((response) => response.data.status);
export const isValidEmail = (email) =>
  ApiRequest.validEmail(email).then((response) => response.data.status);
export const isValidWalletAddress = (walletAddress) =>
  ApiRequest.validWalletAddress(walletAddress).then(
    (response) => response.data.data.duplicate
  );

export const updateUser = (user, to) => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.updateUser(user)
      .then((response) => {
        dispatch(getReferral());
        const userInfo = response.data.data.user;
        dispatch({
          type: CREDENTIAL_UPDATE,
          user: userInfo,
        });
        if (to) {
          history.push(to);
        } else {
          resolve("Success");
        }
      })
      .catch((error) => {
        dispatch({
          type: FORM_ERROR,
          formError: "Failed to update info",
        });
        reject(Error(`Error: ${error}`));
      });
  });

export const updateUserProfile = (id, user) => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.updateUser(user)
      .then((response) => {
        const userInfo = response.data.data.user;

        dispatch({
          type: CREDENTIAL_UPDATE,
          user: userInfo,
        });
        resolve("Info Changed");
      })
      .catch(() => {
        dispatch({
          type: FORM_ERROR,
          formError: "Failed to update info",
        });
        reject(Error("Error Occured"));
      });
  });

export const recoverPassword = (email) => (dispatch) => {
  ApiRequest.recoverPassword(email);
};

export const resetPassword = (token) => (dispatch) => {
  ApiRequest.resetPassword(token).then((response) =>
    setBearerTokenToLocalStorage(response.data.token)
  );
};

export const verifyEmailToken = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.verifyEmailToken(token)
      .then((response) => {
        setBearerTokenToLocalStorage(response.data.token);
        dispatch({
          type: VALIDATE_EMAIL,
        });
        resolve();
      })
      .catch(() => {
        reject(Error("Invalid token, or token has expired"));
      });
  });

export const updatePasswordReset = (token, password, confirmPassword) => (dispatch) =>
  ApiRequest.resetPassword(token, password, confirmPassword)
    .then((response) => {
      setAccountInfo(response.data.data, response.data.token);
      ApiRequest.getCurrentUser().then((response2) => {
        const { user } = response2.data.data;
        dispatch({
          type: CREDENTIAL_UPDATE,
          user,
        });
        dispatch({
          type: SUCCESS_AUTH,
        });
      });
      history.push("/usr/settings");
    })
    .catch((err) => {
      console.error("The following error occured while updating password...");
      console.error(err);
    });

export const resendEmailValidation = () => (dispatch) =>
  new Promise((resolve, reject) => {
    ApiRequest.resendEmailValidation()
      .then(() => {
        resolve("Email Sent!");
        dispatch({
          type: FORM_SUCCESS,
          formError: "Email sent",
        });
      })
      .catch(() => {
        reject(Error("Error Occured"));
        dispatch({
          type: FORM_ERROR,
          formError: "Failed to send email",
        });
      });
  });

export const clearFormErrors = () => (dispatch) => {
  dispatch({
    type: FORM_ERROR,
    formError: "",
  });
};
export const updatePassword = (password) => () => ApiRequest.updatePassword(password);

const refreshUserInfo = (dispatch) => () => getCurrentUser()(dispatch);

export const updateEmail = (id, session) => (dispatch) =>
  ApiRequest.updateEmail(id, session).then(refreshUserInfo(dispatch));

export const updateUsername = (id, session) => (dispatch) =>
  ApiRequest.updateUsername(id, session).then(refreshUserInfo(dispatch));

export const updateCurrencies = (id, value) => {
  return {
    type: UPDATE_CURRENCIES,
    data: {
      id,
      value,
    },
  };
};
