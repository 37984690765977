import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Grid, TextField } from "@material-ui/core";
import Text from "components/Text";
import { recoverPassword, setModal } from "actions/index";
import { history } from "store/index";
import { ForgotPasswordWrapper } from "components/SignIn/styles";
import { ButtonClose } from "components/SignIn/Form/styles";
import { ReactComponent as Close } from "images/close.svg";
import FormContainer from "components/SignIn/Form/FormContainer";

import BackGroundImage from "components/SignUp/BackGroundImage";
import image from "images/signIn/background.png";

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      sent: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
  }

  handlePasswordReset() {
    this.props.recoverPassword(this.state.email);
    this.setState({ sent: true });
  }

  handleChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  closeHandler = () => {
    history.push("/sign-in");
  };

  render() {
    return (
      <>
        <BackGroundImage image={image} />
        <ForgotPasswordWrapper>
          <FormContainer className="forgot-wrapper" name="Forgot Your Password?">
            <ButtonClose onClick={this.closeHandler}>
              <Close />
            </ButtonClose>
            <Grid item xs={12}>
              <Text variant="h5" className="forgot__header">
                {!this.state.sent ? "Hi There" : "Check your inbox"}
              </Text>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Text className="mt--16">
                {!this.state.sent
                  ? "Having an identity crisis? Type in your email, and you will recive an email with your username, and a link to reset your password within 5 minutes."
                  : "We sent you an email to reset your password, incase you don’t want to reset your password, you can ignore it. Check your spam folder."}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="mt--124"
                fullWidth
                value={this.state.email}
                id="email"
                label="Email"
                onChange={this.handleChange}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="forgot__reset-button"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!this.state.email || this.state.sent}
                onClick={this.handlePasswordReset}
              >
                {!this.state.sent ? "Reset Password" : "You can close this window"}
              </Button>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  this.props.setModal("LOGIN");
                  history.push("/sign-in");
                }}
                fullWidth
                className="mt--24"
              >
                Cancel
              </Button>
            </Grid>
          </FormContainer>
        </ForgotPasswordWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  recoverPassword,
  setModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgot));
