import React from "react";
import { Link } from "react-router-dom";
import { useButtonStyles } from "../../../styles";
import {
  GameActions,
  GameDescription,
  GameItemBody,
  GamesItem,
  InnerTextShadow,
} from "../../Dashboard/styles";
import { NftButton } from "../../Marketplace/styles";
import { ChallengeName } from "../styles";
import { Grid, Typography } from "@material-ui/core";

const VaultPoints = ({ onClickHandler, list }) => {
  const classes = useButtonStyles();

  return list.map(({ game, vault }) => {
    if (!game || !vault) return null;
    const {
      image_url: imageUrl,
      id: gameId,
      title,
      earned_currency_name: earnedCurrencyName,
      premium_currency_name: premiumCurrencyName,
    } = game;

    const {
      earned_currency: earnedCurrency,
      premium_currency: premiumCurrency,
      level,
    } = vault;

    return (
      <Grid container item xl={4} lg={6} md={4} sm={6} xs={12}>
        <Link style={{ width: "100%" }} to={`/game/${gameId}`}>
          <GamesItem elevation={3}>
            <img src={imageUrl} alt="Unclaimed Points" />
            <InnerTextShadow />
            <GameItemBody>
              <ChallengeName>{title}</ChallengeName>
              <GameDescription>
                {earnedCurrencyName || "Currency"}: {earnedCurrency}
              </GameDescription>
              <GameDescription>
                {premiumCurrencyName && premiumCurrencyName + " : " + premiumCurrency}
              </GameDescription>
              <GameActions>
                <NftButton
                  className={classes.button}
                  onClick={(e) => {
                    onClickHandler(e, gameId);
                  }}
                >
                  Convert
                </NftButton>
                <Typography variant="h6">LVL: {level}</Typography>
              </GameActions>
            </GameItemBody>
          </GamesItem>
        </Link>
      </Grid>
    );
  });
};

export default VaultPoints;
