import { Typography } from "@material-ui/core";
import React, { memo } from "react";

const PageHeader = memo(({ h1, subtitle }) => {
  return (
    <div style={{ padding: "0 0 1.2em" }}>
      <Typography variant="h1">{h1}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </div>
  );
});

export default PageHeader;
