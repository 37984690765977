import { Tooltip } from "@material-ui/core";
import { ButtonInfo } from "components/ContentElements/info/styles";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    maxWidth: "none",
    fontFamily: "Poppins",
    fontSize: "12px",
    maxWidth: "300px",
  },
  tooltip: {
    fontSize: "20px",
  },
  tooltipButton: {
    margin: "auto 0 auto 10px",
    backgroundColor: "#6e43e6",
  },
}));

const StandardToolTip = ({ title, tooltipStyle, buttonStyle, text, warning }) => {
  const classes = useStyles();

  const isFunction = typeof title === "function" ? true : false;

  return (
    <span style={{ display: "flex" }}>
      <span>{text}</span>
      <Tooltip
        arrow
        classes={{
          tooltip: tooltipStyle || classes.customTooltip,
          // tooltip: classes.customTooltip,
        }}
        title={isFunction ? title() : title}
      >
        <ButtonInfo className={buttonStyle || classes.tooltipButton}>
          <Typography variant="subtitle2" style={{ fontWeight: "600", marginTop: "1px" }}>
            {warning ? "!" : "?"}
          </Typography>
        </ButtonInfo>
      </Tooltip>
    </span>
  );
};

export default StandardToolTip;
