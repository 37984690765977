import { CLOSE_ALERT, OPEN_ALERT } from "./types";

const initialState = {
  data: null,
};

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ALERT:
      return {
        ...state,
        data: action.data,
      };

    case CLOSE_ALERT:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default AlertReducer;
