import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, getAllowanceOf, approveFor, getGXPRequirements } from "actions";
import { getPLTNFTs, getNFTs, getFeaturedNFTs, getSponsoredNFT } from "actions/NFTs";
import { isCompletedTx } from "blockchain";
import { getAvailableNetworks, TOKEN_INFO, ZERO_ADDRESS } from "blockchain/tokenInfo";
import { addChain, getDisplayVal } from "blockchain/web3Utils";
import Logo from "components/Logo/Logo";
import SuccessModal from "components/Modal/Success";
import PageHeader from "components/pageComponents/PageHeader";
import { loadedPartialScreen, loadingPartialScreen } from "dux/loading";
import appError from "utils/appError";
import { useHistory } from "react-router-dom";
import FeaturedNFT from "./FeaturedNFT/Content";
import PLTNFT from "./PLTNFT/Content";
import NFTList from "./NFTList/Content";
import NFTModalContainer from "../Modal/NFTModal";

import { ContentWrapper } from "./styles";

const Content = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const web3 = useSelector((state) => state.web3);
  const CONTRACT_ALLOWANCE = "1000000000000000000000000000";

  const [overrideBadNetwork, setOverrideBadNetwork] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (web3.connected) {
      dispatch(getGXPRequirements(web3.connected));
      dispatch(loadedPartialScreen("nftButtons--is-loading-nfts"));
    }
  }, [web3.chainId, web3.connected]);

  useEffect(() => {
    if (!web3.badNetwork && web3.chainId) {
      dispatch(getNFTs(0, 10, web3));
      dispatch(getPLTNFTs(web3));
      dispatch(getFeaturedNFTs(web3));
      dispatch(getSponsoredNFT(web3));
    }
  }, [web3.chainId]);

  const connectHandler = useCallback(() => {
    dispatch(loadingPartialScreen("nftButtons--is-loading-nfts"));
    dispatch(connect());
  }, [dispatch]);

  const allowanceHandler = useCallback(
    (blockchainId, paymentAddress, targetAddress) => {
      dispatch(loadingPartialScreen("nftButtons--is-handling-allowance"));

      dispatch(approveFor(paymentAddress, CONTRACT_ALLOWANCE, targetAddress))
        .then((txn) => {
          const timer = setInterval(() => {
            isCompletedTx(blockchainId, txn).then(() => {
              setTimeout(() => {
                clearInterval(timer);
                dispatch(getAllowanceOf(paymentAddress, targetAddress)).then(() => {
                  dispatch(loadedPartialScreen("nftButtons--is-handling-allowance"));
                });
              }, 5000);
            });
          }, 5000);
        })
        .catch((err) => {
          appError("The following error occurred when setting allowance: ", err);
          dispatch(loadedPartialScreen("nftButtons--is-handling-allowance"));
        });
    },
    [dispatch]
  );

  const getHandler = (payment, price) => {
    if (payment.LP) {
      let token1 = TOKEN_INFO[payment.LP[0]].addresses[web3.chainId];
      let token2 = TOKEN_INFO[payment.LP[1]].addresses[web3.chainId];
      if (TOKEN_INFO[payment.LP[0]].mirrorsNative == web3.chainId) {
        token1 = ZERO_ADDRESS;
      } else if (TOKEN_INFO[payment.LP[1]].mirrorsNative == web3.chainId) {
        token2 = ZERO_ADDRESS;
      }
      history.push(`/xchange/add/${token1}/${token2}/${getDisplayVal(price, 18)}`);
    } else {
      history.push(`/xchange`);
    }
  };

  if (web3.badNetwork) {
    return (
      <div>
        <Dialog
          open={!overrideBadNetwork}
          onClose={() => setOverrideBadNetwork(true)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Wrong Network!</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography>
                  Please ensure you are using one of the following networks... (Click one
                  to switch networks, if your wallet supports it, e.g. Metamask)
                </Typography>
              </Grid>
              <Grid item container direction="row" justifyContent="space-evenly">
                {getAvailableNetworks().map((chain) => (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => addChain(chain.chainParameters)}
                    >
                      {chain.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Logo />
      </div>
    );
  }

  return (
    <div>
      <Logo />
      <ContentWrapper>
        <PageHeader
          h1="NFT Marketplace"
          subtitle="Purchase Limited Time NFTs and Great NFT Hunt items!"
        />

        <PLTNFT
          allowanceHandler={allowanceHandler}
          setSuccessModal={setSuccessModal}
          connectHandler={connectHandler}
          getHandler={getHandler}
        />
        <FeaturedNFT
          allowanceHandler={allowanceHandler}
          setSuccessModal={setSuccessModal}
          connectHandler={connectHandler}
          getHandler={getHandler}
        />
        <NFTList
          allowanceHandler={allowanceHandler}
          setSuccessModal={setSuccessModal}
          connectHandler={connectHandler}
          getHandler={getHandler}
        />
      </ContentWrapper>
      <NFTModalContainer />
      <SuccessModal
        title="Success!"
        message="NFT purchased successfully."
        open={successModal}
        closeHandler={() => setSuccessModal(false)}
      />
    </div>
  );
});

export default Content;
