import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGasPrice,
  updateUserSlippageTolerance,
} from "reducers/redux/Exchange/actions";
import * as Web3Call from "utils/web3Calls";

export const GAS_PRICE = {
  default: "5",
  fast: "6",
  instant: "7",
};

export const GAS_PRICE_GWEI = {
  default: Math.round(Web3Call.toWei(GAS_PRICE.default, "gwei")).toString(),
  fast: Math.round(Web3Call.toWei(GAS_PRICE.fast, "gwei")).toString(),
  instant: Math.round(Web3Call.toWei(GAS_PRICE.instant, "gwei")).toString(),
};

export const useGasPrice = () => {
  const userGas = useSelector((state) => state.Exchange.gasPrice);
  return userGas;
};

export const useGasPriceManager = () => {
  const dispatch = useDispatch();
  const userGasPrice = useGasPrice();

  const setGasPrice = useCallback(
    (gasPrice) => {
      dispatch(updateGasPrice({ gasPrice }));
    },
    [dispatch]
  );

  return [userGasPrice, setGasPrice];
};

export const useUserSlippageTolerance = () => {
  const dispatch = useDispatch();
  const userSlippageTolerance = useSelector((state) => {
    return state.Exchange.userSlippageTolerance;
  });

  const setUserSlippageTolerance = useCallback(
    (slippage) => {
      dispatch(updateUserSlippageTolerance({ userSlippageTolerance: slippage }));
    },
    [dispatch]
  );

  return [userSlippageTolerance, setUserSlippageTolerance];
};
