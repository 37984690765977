import React from "react";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import { countries } from "assets/info";
import { LabelForm } from "components/inputs/styles";
import {
  SettingsButton,
  SettingsCopyIcon,
  SettingsIcon,
  SettingsTextFields,
} from "../style";
import Steam from "../../../../../images/settings/steam.svg";
import Copy from "../../../../../images/settings/copy.svg";
import SimpleRating from "../Rating";
import InputForm from "../../../../inputs/InputForm";

const TextFields = ({ user, values, handler, copyValue, error }) => {
  const params = new URLSearchParams({
    "openid.ns": "http://specs.openid.net/auth/2.0",
    "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
    "openid.claimed_id": "http://specs.openid.net/auth/2.0/identifier_select",
    "openid.mode": "checkid_setup",
    "openid.return_to": window.location.href,
    "openid.realm": window.location.origin,
  });
  const authUrl = `https://steamcommunity.com/openid/login?${params.toString()}`;
  const referralLink = `https://play.gamexchange.app/referral/${user.referral_code}`;
  var slicedWalletAddress;
  if (!!user?.eth_wallet_address) {
    slicedWalletAddress =
      user?.eth_wallet_address.slice(0, 8) +
      "..." +
      user?.eth_wallet_address.slice(user?.eth_wallet_address.length - 8);
  }

  return (
    <>
      <SettingsTextFields>
        <InputForm
          label="Your First Name"
          value={values.first_name}
          handler={handler("first_name")}
          name="firstName"
          error={error}
        />
        <InputForm
          label="Your Last Name"
          value={values.last_name}
          handler={handler("last_name")}
          name="lastName"
          error={error}
        />
        <InputForm
          label="Username"
          value={values.username}
          handler={handler("username")}
          name="username"
          error={error}
          disableValue
        />
        <FormControl variant="outlined" fullWidth color="secondary">
          <LabelForm>Location</LabelForm>
          <Select
            value={values.country_of_origin}
            onChange={handler("country_of_origin")}
            id="location"
            labelId="location"
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputForm
          label="Referral Link"
          value={referralLink}
          name="referralCode"
          disableValue
          error={error}
        >
          <SettingsCopyIcon onClick={copyValue} src={Copy} />
        </InputForm>

        <InputForm
          label="Steam Account Link"
          value={user.steam_name}
          name="referralCode"
          disableValue
          error={error}
          link={authUrl}
          steam={user.steam_name}
        >
          {!user.steam_name && (
            <a href={authUrl}>
              <SettingsIcon src={Steam} />
            </a>
          )}
        </InputForm>

        <InputForm
          label="Wallet Address"
          value={slicedWalletAddress || "No Wallet Connected"}
          name="walletAddress"
          disableValue
          error={error}
        />
      </SettingsTextFields>
      <SettingsButton type="submit" color="primary" variant="contained">
        Update Profile
      </SettingsButton>
    </>
  );
};

export default TextFields;
