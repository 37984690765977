import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { button } from "../../styles";
import { CloseIcon } from "../Nav/Menu/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "&&": {
      borderRadius: "12px",
      display: "flex",
      maxWidth: "100%",
      minWidth: "400px",
      width: "auto",
      lineHeight: "19px",
      fontSize: "16px",
      fontFamily: "Inter sans-serif !important",

      "& > *": {
        padding: "16px",
      },

      "& > div > p": {
        marginBottom: "3px",
      },

      "@media(max-width: 768px)": {
        maxWidth: "90vw",
        minWidth: "90vw",
      },
    },
  },
}));

export const Button = styled(button)`
  padding: 10px 16px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`;

export const WhiteButton = styled(Button)`
  background-color: #fff;
  color: #000;
  margin-right: 56px;

  @media (max-width: 480px) {
    margin-right: 20px;
  }

  &:hover {
    background-color: #efefef;
  }
`;

export const ButtonClose = styled(CloseIcon)`
  background-color: inherit;
  width: 20px;
  height: 20px;
  padding: 10px;
  right: 13px;
  top: 10px;
`;

export const ProfileButtonCloce = styled.button`
  background-color: #fff !important;
  color: #000;
  margin-right: 56px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px !important;
  margin-bottom: 8px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`;

export const DialogText = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  color: #fff;
  font-family: "Inter", sans-serif;
  min-height: 65px;

  & > div {
    line-height: 1.5;
  }
`;

export const DialogTitle = styled.h2`
  flex: 1 1 0;
  color: #fff;
  font-weight: 500;
  font-size: 1.5em;
  min-height: 32px;
  padding: 16px;
  line-height: 1.6;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  display: inline-flex;

  & > div {
    line-height: 1.5;
  }
`;
