import React from "react";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  CurrencyImage,
  Subtitle,
  LiquidityDescription,
  CurrencyText,
} from "components/Exchange/styles";
import { Descriptions, Description } from "components/Staking/list/styles";
import unknownToken from "assets/icons/unknownToken.png";
import { getTokenImageBySymbol } from "blockchain/tokenInfo";

const useStyles = makeStyles(() => ({
  accordian: {
    borderRadius: "15px !important",
  },
  accordianDetails: {
    padding: "0px 16px 16px",
  },
  container: {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: "0px",
    },
  },
  descriptions: {
    marginTop: "0px",
  },
}));

const imageOnErrorHandler = (ev) => {
  ev.currentTarget.src = unknownToken;
};

const descriptions = (pair) => {
  const data = {
    [`Pooled ${pair.token0.symbol}`]: pair.reserve0.toFixed(2),
    [`Pooled ${pair.token1.symbol}`]: pair.reserve1.toFixed(2),
    "Share of Pool": `${Math.round((pair.balance / pair.totalSupply) * 10000) / 100}%`,
  };
  const res = [];

  Object.keys(data).forEach((key) => {
    res.push(
      <Description key={key}>
        <CurrencyText>{key}</CurrencyText>
        <LiquidityDescription>{data[key]}</LiquidityDescription>
      </Description>
    );
  });

  return res;
};

const LiquidityAccordian = ({ pair }) => {
  const history = useHistory();
  const classes = useStyles();
  const { balance, token0, token1 } = pair;
  return (
    <div className={classes.container}>
      <Accordion className={classes.accordian}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <CurrencyImage
              src={getTokenImageBySymbol(token0.symbol)}
              onError={imageOnErrorHandler}
              alt="currency"
            />
            <CurrencyImage
              src={getTokenImageBySymbol(token1.symbol)}
              onError={imageOnErrorHandler}
              alt="currency"
            />
            <CurrencyText>{token0.symbol}</CurrencyText>
            <CurrencyText>/</CurrencyText>
            <CurrencyText>{token1.symbol}</CurrencyText>
            <Grid container>
              <Subtitle>{balance}</Subtitle>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianDetails}>
          <Grid container>
            <Descriptions className={classes.descriptions}>
              {descriptions(pair)}
            </Descriptions>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>
                history.push(
                  `/xchange/remove/${token0.chainId}/${token0.address}/${token1.address}`
                )
              }
              style={{ marginBottom: "10px" }}
            >
              Remove
            </Button>
            <Button
              color="primary"
              fullWidth
              onClick={() =>
                history.push(`/xchange/add/${token0.address}/${token1.address}`)
              }
            >
              + Add Liquidity Instead
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default LiquidityAccordian;
