import React from "react";
import { useButtonStyles } from "styles";
import LoadingButton from "components/LoadingButton";
import { useSelector } from "react-redux";
import { history } from "store/index";
import { NftActions } from "../styles";

const Actions = ({
  index,
  loading,
  achieved,
  achievementName,
  claimed,
  MintHandler,
  current,
}) => {
  const classes = useButtonStyles();
  const auth = useSelector(({ Signup }) => Signup.auth);

  const renderText = () => {
    if (!auth) {
      return "Sign in to Claim";
    }
    return claimed ? "Claimed" : "Claim";
  };

  return (
    <NftActions>
      <LoadingButton
        style={{ width: "100%" }}
        loading={loading}
        className={classes.button}
        onClick={() =>
          auth
            ? MintHandler(current.steam_game_id, achievementName, index)
            : history.push("/sign-in")
        }
        disabled={!achieved || claimed || loading}
      >
        {renderText()}
      </LoadingButton>
    </NftActions>
  );
};

export default Actions;
