import appError from "utils/appError";
import { getNFTsForGame } from "utils/nftAPI";
import { getUserSteamClaim, getSeasonalGames } from "../utils/apiRequest";
import { makeUserSteamClaim, fetchSteamGame, getUser } from "../utils/steam";
import {
  SEASONAL_CHALLENGE_GAMES,
  STEAM_USER_ACHIEVEMENTS,
  STEAM_USER_CLAIM,
  STEAM_USER_CLAIM_REMAINING,
  STEAM_USER_NAME,
} from "./types";

export const steamGetUserAchievements = (gameId) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    fetchSteamGame(gameId)
      .then((response) => {
        const data = response.data.achievements.sort((a, b) => {
          if (a.achieved && !b.achieved) {
            return -1;
          }
          if (!a.achieved && b.achieved) {
            return 1;
          }
          if (a.tapval !== b.tapval) {
            return a.tapval > b.tapval ? -1 : 1;
          }
          return a.name > b.name ? 1 : -1;
        });
        dispatch({
          type: STEAM_USER_ACHIEVEMENTS,
          achievements: data,
          gameId,
          achieved: response.data.achieved,
        });
        resolveFunction(response);
      })
      .catch((error) => {
        rejectFunction(
          Error(
            `Could not get the achievement info for gameid:${gameId}. Error: ${error}`
          )
        );
      });
  });

export const steamFetchClaimedValue = (userid, gameid) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    getUserSteamClaim(userid, gameid)
      .then((response) => {
        if (response.data) {
          if (response.data.data[0] && response.data.data[0].attributes) {
            dispatch({
              type: STEAM_USER_CLAIM,
              claim_value: response.data.data[0].attributes.claim_value,
              id: response.data.data[0].attributes.steam_game_id,
            });
            resolveFunction(response.data.data[0]);
          } else {
            dispatch({
              type: STEAM_USER_CLAIM,
              claim_value: response.data.data.attributes.claim_value,
              id: response.data.data.attributes.steam_game_id,
            });
            resolveFunction(response.data.data);
          }
        }
      })
      .catch((error) => {
        rejectFunction(error);
      });
  });

export const steamClaimValue = (userid, gameid) => (dispatch) =>
  makeUserSteamClaim(userid, gameid).then((result) => {
    dispatch({
      type: STEAM_USER_CLAIM,
      claim_value: result.data.claim_value,
      id: result.data.steam_game_id,
    });
    dispatch({
      type: STEAM_USER_CLAIM_REMAINING,
      data: 0,
      id: result.data.steam_game_id,
    });
    return result.data;
  });

export const fetchSeasonalGames = (steamId, userId) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    getSeasonalGames()
      .then((gameList) => {
        const ids = [];
        gameList.data.data.challengeGame.forEach((gameId) => {
          ids.push(gameId.steam_game_id);
        });
        const games = { ids: [], byId: {} };
        const promises = [];
        const nftPromises = [];
        for (let index = 0; index < ids.length; index += 1) {
          const gameid = ids[index];
          promises[index] = fetchSteamGame(gameid)
            .then((result) => ({ success: true, result }))
            .catch((error) => ({ success: false, error }));
          /*
          nftPromises[index] = getNFTsForGame("steam" + ids[index], userId)
            .then((nfts) => ({ success: true, nfts }))
            .catch((error) => ({ success: false, error }));
            */
        }

        Promise.all(promises).then((response) => {
          //Promise.all(nftPromises).then((response2) => {
          for (let index = 0; index < ids.length; index += 1) {
            const game = response[index].result?.data;
            /*const { nfts } = response2[index];
              const updatedNfts = nfts;
              if (nfts && game) {
                nfts.forEach((element, key) => {
                  const achievement = game.achievements.find(
                    (child) => child.name === element.achievement_name
                  );
                  updatedNfts[key].achievement = achievement;
                });
                game.nfts = nfts;
              }
              */

            if (userId && game.achievements) {
              dispatch({
                type: STEAM_USER_CLAIM_REMAINING,
                data: game.unclaimedTap,
                id: game.steam_game_id,
              });
              /*
                  const uAch = response[ids.length + index].data.playerstats.achievements;
                  if (game) {
                    game.achieved = 0;
                    game.claimableTap = 0;
                    for (let i = 0; i < game.achievements.length; i += 1) {
                      for (let j = 0; j < uAch.length; j += 1) {
                        if (uAch[j].apiname === game.achievements[i].name) {
                          game.achievements[i].achieved = uAch[j].achieved;
                          if (uAch[j].achieved) {
                            game.achieved += 1;
                            game.claimableTap += game.achievements[i].tapval;
                          }
                          game.achievements[i].unlocktime = uAch[j].unlocktime;
                          break;
                        }
                      }
                    }
                  }*/
            }

            if (typeof game === "object" && game !== null) {
              if (game.achievements) {
                game.achievements.sort((a, b) => {
                  if (a.achieved && !b.achieved) {
                    return -1;
                  }
                  if (!a.achieved && b.achieved) {
                    return 1;
                  }
                  if (a.tapval !== b.tapval) {
                    return a.tapval > b.tapval ? -1 : 1;
                  }
                  return a.name > b.name ? 1 : -1;
                });
              } else {
                game.achievements = [];
              }
              games.byId[ids[index]] = game;
              games.ids[index] = ids[index];
            }
          }
          if (games.length < 1) {
            resolveFunction();
          } else {
            dispatch({
              type: SEASONAL_CHALLENGE_GAMES,
              games,
            });
            resolveFunction(games);
          }
          //});
        });
      })
      .catch((error) => {
        rejectFunction(
          Error(`Could not fetch game info from steam proxy:${error.message}`)
        );
      });
  });

export const steamGetCurrentUser = (gidSteam) => (dispatch) =>
  getUser(gidSteam)
    .then((response) => {
      dispatch({
        type: STEAM_USER_NAME,
        steamname: response.data,
      });
      return gidSteam;
    })
    .catch((err) => {
      appError("The following error occured while getting steam user...", err);
    });

export const checkSteamUser = (id) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    getUser(id)
      .then((response) => {
        if (response.data.response.success === 1) {
          dispatch({
            type: STEAM_USER_NAME,
            steamname: response.data,
          });
          resolveFunction(true);
        } else {
          rejectFunction(Error(`Could not find a steam user with the name ${id}`));
        }
      })
      .catch(() => {
        rejectFunction(Error(`Could not find a steam user with the name ${id}`));
      });
  });
