import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, SvgIcon, Divider } from "@material-ui/core";
import NavBurger from "components/NavBurger";
import { toggleNav } from "dux/nav";
import { getSwapURL } from "blockchain";
import { ReactComponent as Battle } from "images/icons/Battle.svg";
import { ReactComponent as Games } from "images/icons/Games.svg";
import { ReactComponent as Market } from "images/icons/Market.svg";
import { ReactComponent as Profile } from "images/icons/Profile.svg";
import { ReactComponent as Setting } from "images/icons/Setting.svg";
import { ReactComponent as Trophy } from "images/icons/Trophy.svg";
import { ReactComponent as Staking } from "images/icons/Staking.svg";
import { ReactComponent as Help } from "images/icons/Help.svg";
import { ReactComponent as Swap } from "images/icons/Swap.svg";
import { ReactComponent as Voucher } from "images/icons/Voucher.svg";
import TapLogo from "images/logos/logo.png";
import GXPBottle from "assets/icons/GXP.png";
import { ReactComponent as Discord } from "assets/icons/discord.svg";
import { ReactComponent as Telegram } from "assets/icons/telegram.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import ListMenu from "./List";
import ListOffers from "./Offers";
import { ListItem, MenuWrapper, useStyles } from "./styles";
import MenuText from "./MenuText";

const Menu = React.memo(() => {
  const nav = useSelector((state) => state.nav);
  const web3 = useSelector((state) => state.web3);
  const [buyTapLink, setBuyTapLink] = useState(getSwapURL(web3.chainId));
  const classes = useStyles(nav.open);

  useEffect(() => {
    window?.ethereum?.on("chainChanged", (networkId) => {
      setBuyTapLink(getSwapURL(parseInt(networkId)));
    });
  }, []);

  const MENU_ITEMS = [
    {
      id: 1,
      image: Games,
      name: [{ route: "*", text: "Games" }],
      route: "/dashboard",
    },
    {
      id: 2,
      image: Staking,
      name: [{ route: "*", text: "Staking" }],
      route: "/staking",
    },
    {
      id: 3,
      image: Market,
      name: [{ route: "*", text: "NFT Market" }],
      route: "/marketplace",
    },
    {
      id: 4,
      image: Swap,
      name: [{ route: "*", text: "XChange" }],
      route: "/xchange",
    },
    {
      id: 5,
      image: Trophy,
      name: [{ route: "*", text: "Leaderboards" }],
      route: "/leaderboards",
    },
    /*{
      id: 4,
      image: Battle,
      name: [{ route: "*", text: "Battle (coming soon)" }],
      route: "/battle",
      disabled: true,
    },*/
  ];

  const info = [
    {
      id: 1,
      image: Profile,
      name: [{ route: "*", text: "Profile" }],
      route: "/usr/profile",
    },
    {
      id: 2,
      image: Setting,
      name: [{ route: "*", text: "Settings" }],
      route: "/usr/settings",
    },
    {
      id: 3,
      image: Voucher,
      name: [{ route: "*", text: "Redeem" }],
      route: "/redeem",
    },
  ];

  const dispatch = useDispatch();

  return (
    <MenuWrapper>
      <div
        className={classes.toolbar}
        onClick={() => dispatch(toggleNav())}
        onKeyPress={() => dispatch(toggleNav())}
        role="button"
        tabIndex="0"
      >
        <img className={classes.logo} src={TapLogo} alt="Tap Logo" />

        {nav.open && <NavBurger classes={{ root: classes.burger }} />}
      </div>

      <ListMenu list={MENU_ITEMS} />
      <Divider classes={{ root: classes.divider }} />

      <ListMenu list={info} />
      <ListItem>
        <SvgIcon component={Help} classes={{ root: classes.svg }} />
        <a
          href="https://game-x-change.gitbook.io/game-x-change/"
          target="_blank"
          rel="noreferrer"
        >
          <MenuText open={nav.open}>Help</MenuText>
        </a>
      </ListItem>

      <ListItem>
        <img
          style={{ width: "24px", marginRight: "16px" }}
          src={GXPBottle}
          alt="GXP Bottle"
        />
        <a href={buyTapLink} target="_blank" rel="noreferrer">
          <MenuText open={nav.open}>Buy GXP</MenuText>
        </a>
      </ListItem>
      <Divider classes={{ root: classes.divider }} />
      <Grid
        container
        justifyContent="center"
        classes={{ root: classes.social }}
        className="footer__icon-group"
      >
        <IconButton href="https://t.me/GameXChange" target="_blank">
          <Telegram />
        </IconButton>
        <IconButton href="https://discord.gg/fBpj8qP" target="_blank">
          <Discord />
        </IconButton>
        <IconButton href="https://twitter.com/gamex_change" target="_blank">
          <Twitter />
        </IconButton>
      </Grid>
      <Divider classes={{ root: classes.divider }} />

      {nav.open && <ListOffers />}
    </MenuWrapper>
  );
});

export default Menu;
