import update from "immutability-helper";
import {
  CHALLENGES_CLEAR,
  SEASONAL_CHALLENGE_GAMES,
  STEAM_USER_ACHIEVEMENTS,
  STEAM_USER_CLAIM,
  STEAM_USER_CLAIM_REMAINING,
} from "../actions/types";

const initial = {
  seasonalGames: { ids: [], byId: {} },
  claims: {},
  claimRemaining: {},
};

export default (state = initial, action) => {
  switch (action.type) {
    case SEASONAL_CHALLENGE_GAMES: {
      return {
        ...state,
        seasonalGames: action.games,
      };
    }
    case STEAM_USER_CLAIM: {
      return update(state, {
        claims: {
          [action.id]: { $set: action.claim_value },
        },
      });
    }
    case STEAM_USER_CLAIM_REMAINING: {
      return update(state, {
        claimRemaining: {
          [action.id]: { $set: action.data },
        },
      });
    }
    case STEAM_USER_ACHIEVEMENTS: {
      return update(state, {
        seasonalGames: {
          byId: {
            [action.gameId]: {
              achievements: {
                $apply(achievements) {
                  return action.achievements;
                },
              },
              achieved: { $set: action.achieved },
            },
          },
        },
      });
    }
    case CHALLENGES_CLEAR: {
      return { ...initial };
    }
    default: {
      return state;
    }
  }
};
/*

*/
