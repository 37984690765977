import React, { Component } from "react";
import { Button, Grid, Divider, Paper } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { registerPublisher } from "actions/index";
import * as Form from "utils/form";
import Text from "components/Text";
import * as formHelpAsync from "utils/formHelpAsync";
import { history } from "../../../store/index";
import * as Info from "../../../assets/info";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "first_name",
    "last_name",
    "email_address",
    "username",
    "password",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (
    values.email_address &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)
  ) {
    errors.email_address = "Invalid email address";
  }
  if (values.username && (values.username.length < 3 || values.username.length > 20)) {
    errors.username = "Username must be between 3 and 20 characters";
  }
  if (values.password && (values.password.length < 6 || values.password.length > 100)) {
    errors.password = "Password must be between 6 and 100 characters";
  }

  return errors;
};

const asyncValidate = (values) => formHelpAsync.isValidUsername(values);

class Register extends Component {
  getStepContent(step) {
    switch (step) {
      case "new":
        return this.getFirstStepContent();
      case "TOS":
        if (!this.props.form.register.values) {
          history.push("/pub/onboarding/account/new");
        }
        return this.getTOSContent();
      default:
        return "Invalid URL";
    }
  }

  getFirstStepContent() {
    return (
      <form>
        <Grid container className="pub-register register">
          <Text variant="h4">Create a publisher account to add your game.</Text>
          <Text variant="p1" color="textSecondary">
            We are excited to welcome you! Add your game for players to discover, and use
            the SDK to add blockchain support. Allow cross platform game currencies to
            increase player engagement.
          </Text>
          <Grid item xs={12} className="mt--32">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Field
                  name="first_name"
                  component={Form.renderTextField}
                  label="First Name"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="last_name"
                  component={Form.renderTextField}
                  label="Last Name"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--40">
            <Field
              name="email_address"
              component={Form.renderTextField}
              label="Email"
              type="email"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} className="mt--40">
            <Field
              name="username"
              component={Form.renderTextField}
              label="Username"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} className="mt--40">
            <Field
              name="password"
              component={Form.renderTextField}
              label="Password"
              type="password"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} className="mt--40">
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={() => {
                history.push("/pub/onboarding/account/TOS");
              }}
              disabled={!this.props.valid}
            >
              Next
            </Button>
          </Grid>
          <Grid item xs={12} className="mt--40">
            <Divider />
            <Text variant="h6" className="mt--40">
              Not a game publisher?
            </Text>
            <Text variant="p2" color="textSecondary">
              No worries! This button will take you to the player registration form.
            </Text>
            <Button
              variant="text"
              color="secondary"
              className="mt--24"
              component={Link}
              to="/onboarding/account/new"
              fullWidth
            >
              Create a player account
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  getTOSContent() {
    return (
      <Grid container className="TOS pub-register register">
        <Paper>{Info.termsOfService}</Paper>
        <Grid container>
          <Grid item xs={12} className="mt--40">
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={this.props.handleSubmit((data) => {
                this.props.registerPublisher(data);
              })}
            >
              Accept Terms
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    return <div>{this.getStepContent(this.props.match.params.step)}</div>;
  }
}
const mapStateToProps = (state) => ({
  form: state.form,
});

const mapDispatchToProps = {
  registerPublisher,
};
export default compose(
  reduxForm({ form: "register", validate, asyncValidate }),
  connect(mapStateToProps, mapDispatchToProps)
)(Register);
