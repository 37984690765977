import {
  EXCHANGE_ADD_PAIR,
  EXCHANGE_SET_PAIRS,
  EXCHANGE_SET_TRADES,
  EXCHANGE_SET_CURRENCIES,
} from "./types";

const initialState = {
  liquidityPairs: [],
  tradePairs: [],
  currencies: {},
};

const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXCHANGE_ADD_PAIR:
      return {
        ...state,
        liquidityPairs: [...state.liquidityPairs, action.pair],
      };
    case EXCHANGE_SET_PAIRS:
      return {
        ...state,
        liquidityPairs: action.pairs,
      };
    case EXCHANGE_SET_TRADES:
      return {
        ...state,
        tradePairs: action.pairs,
      };
    case EXCHANGE_SET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
      };
    default:
      return state;
  }
};

export default ExchangeReducer;
