import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Blue, Code, ReferralCodeWrapper, ReferralLink } from "./styles";
import { checkLink } from "../../../../../assist/checkLink";
import { openAlert } from "../../../../../reducers/redux/Alert/actions";

const ReferralCode = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const link = `https://play.gamexchange.app/referral/${user.referral_code}`;

  const copyToBuffer = () => {
    navigator.clipboard.writeText(link);
    dispatch(
      openAlert({
        text: "Text copied",
        severity: "success",
        anchor: { vertical: "top", horizontal: "right" },
      })
    );
  };

  return (
    <ReferralCodeWrapper>
      <Code>
        <div>
          <ReferralLink href={checkLink(link)} target="_blank">
            <span>{link}</span>
          </ReferralLink>
          <Blue onClick={copyToBuffer}>COPY</Blue>
        </div>
      </Code>
    </ReferralCodeWrapper>
  );
};

export default ReferralCode;
