export const getBearerTokenFromLocalStorage = () => {
  return window.localStorage.getItem("jwt") || "";
};

export const setBearerTokenToLocalStorage = (token) => {
  if (!token) {
    throw new Error("Cannot set an empty Bearer Token");
  }
  return window.localStorage.setItem("jwt", token);
};

export const deleteBearerTokenFromLocalStorage = () => {
  window.localStorage.removeItem("jwt");
};
