import styled from "styled-components";
import { Flex, MyPaper } from "styles";
import { Typography, Grid, OutlinedInput, Button } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";

export const InfoContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #242731;
  border-radius: 25px;
  z-index: 1;
`;

export const InfoHeader = styled(Typography)`
  margin-bottom: 20px !important;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const InnerInfoContainer = styled(Grid)`
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  border: 1px solid #383b45;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    div {
      width: 100%;
      text-align: center;
      align-content: center;
    }
  }
`;

export const ContainerItem = styled(MyPaper)`
  font-family: "Inter", sans-serif;
  background-color: #242731;
  max-width: 450px;
  min-width: 400px;

  @media (max-width: 480px) {
    max-width: 100%;
    margin-right: 0;
    width: 100%;
    border-radius: 32px 24px;
    min-width: 336px;
  }
`;

export const ContainerItemHeader = styled(Flex)`
  padding: 24px;
  border-bottom: 1px solid #999ba3;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerItemContent = styled(Flex)`
  flex-direction: column;
  padding: 15px 20px;
`;

export const ContainerItemFooter = styled(Flex)`
  flex-direction: column;
  padding: 0 20px 20px;
`;

export const ContainerInfo = styled(Flex)`
  flex-direction: column;
  padding: 15px 15px 0;
`;

export const LiquidityPair = styled(Flex)`
  flex-direction: column;
  padding: 15px 20px;
  background-color: blue;
`;

export const Name = styled(Flex)`
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
`;

export const Subtitle = styled(Flex)`
  font-weight: 500;
  font-size: 14px;
  font-family: "Work Sans";
  color: #999ba3;
`;

export const LiquidityStatsSubtitle = styled(Flex)`
  font-weight: 700;
  font-size: 12px;
  font-family: "Work Sans";
  color: #999ba3;
  justify-content: center;
`;

export const LiquidityStatsHeader = styled(Flex)`
  font-weight: 500;
  font-size: 18px;
  font-family: "Work Sans";
  justify-content: center;
`;

export const CurrencyText = styled.span`
  display: inline;
  whitespace: normal;
  font-weight: 600;
  font-size: 15px;
  font-family: "Poppins";
`;

export const BigCurrencyText = styled.span`
  //display: inline;
  //whitespace: normal;
  font-size: 28px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.235;
`;

export const LiquidityDescription = styled.span`
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 400;
`;

export const CurrencyImage = styled.img`
  vertical-align: middle;
  height: 24px;
  margin-right: 5px;
  border-radius: 15px;
  background-color: #000;
`;

export const BigCurrencyImage = styled.img`
  height: 32px;
  margin-right: 5px;
  border-radius: 15px;
  background-color: #000;
`;

export const RemoveLiquidityInfoBox = styled(Flex)`
  width: 100%;
  padding: 20px;
  background-color: #28282f;
  border: 1px solid gray;
  border-radius: 15px;
  margin-bottom: 15px;
`;

export const Descriptions = styled.ul`
  list-style-type: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;

  @media (max-width: 480px) {
    padding: 0;
  }
`;

export const Description = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (max-width: 480px) {
    margin-bottom: 16px;

    & span {
      &:first-child {
        color: #808191;
      }
    }
  }
`;

export const FooterInfoContainer = styled(Flex)`
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #383b45;
  margin-top: 20px;
  background-color: #28282f;
`;

export const FooterInfoText = styled.span`
  font-family: "Work Sans";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 1.4;
`;

export const SubHeader = styled.span`
  font-size: 14px;
  font-family: "Work Sans";
  font-weight: 600;
  margin-bottom: 10px;
  color: #6e43e6;
`;

export const AutoGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  row-gap: 15px;
  padding-bottom: 15px;
`;

export const CustomButton = styled(Button)`
  border: 0px none !important;
  border-radius: 16px !important;
  font-family: "Poppins" !important;
  justify-content: center;
  height: 32px;
  margin-right: 5px !important;
`;

export const CustomInput = styled.input`
  background-color: rgb(55, 47, 71);
  border-radius: 16px;
  box-shadow: rgba(74, 74, 104, 0.1) 0px 2px 2px -1px inset;
  color: rgb(244, 238, 255);
  display: block;
  font-size: 1rem;
  font-family: "Poppins";
  font-weight: 700;
  height: 32px;
  outline: currentcolor none 0px;
  padding: 0px 16px;
  width: 100%;
  border: 1px solid rgb(38, 33, 48);
`;

export const TokenPair = styled.div`
  white-space: nowrap;
  position: relative;
  width: 48px;
  height: 48px;
  margin: 0 50px 0 20px;

  & > :first-child {
    z-index: 2;
    position: relative;
    height: 100%;
    width: 100%;
  }
  & > :last-child {
    z-index: 1;
    position: absolute;
    left: 30px;
    height: 100%;
    width: 100%;
  }
`;
