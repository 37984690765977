import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "Work Sans",
    color: "#999ba3",
    fontWeight: "500",
    paddingBottom: "10px",
    "@media (max-width: 480px)": {
      paddingBottom: "0px",
      paddingTop: "10px",
      "& > div:first-child": {
        paddingTop: "0px",
      },
    },
  },
  value: {
    fontFamily: "Work Sans",
    fontWeight: "400",
  },
}));

const InfoStats = React.memo(({ title, value }) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={12}>
      <Typography variant="subtitle1" className={classes.header}>
        {title}
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography variant="h3" align="center" className={classes.value}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default InfoStats;
