import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Actions, useStyles } from "./styles";
import ButtonPrev from "../../buttons/ButtonPrev";
import ButtonNext from "../../buttons/ButtonNext";

const AuthModal = ({ open, children, successHandler, rejectHandler }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);

  const handler = (e, read) => {
    if (read) {
      setDisabled(false);
    } else if (e.target.scrollTop + e.target.clientHeight + 10 > e.target.scrollHeight) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setDisabled(open);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={rejectHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.root }}
    >
      <div>{children(handler)}</div>
      <Actions>
        <ButtonPrev handler={rejectHandler} />
        <ButtonNext handler={successHandler} disabled={disabled} text="Accept" />
      </Actions>
    </Dialog>
  );
};

export default AuthModal;
