import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const SettingsTextFields = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    flex: 0 0 47%;

    @media (max-width: 1024px) {
      flex: 0 0 100%;
    }
  }

  label,
  .MuiFormLabel-root.Mui-focused {
    margin-top: 25px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 16px;
    font-size: 12px;
    color: #808191 !important;
    position: static;
  }

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

export const SettingsIcon = styled.img`
  position: absolute;
  bottom: calc(50% - 28px);
  transform: translateY(50%);
  right: 14px;
  cursor: pointer;
`;

export const RegisterIcon = styled(SettingsIcon)`
  top: calc(50% + 17px);
  transform: translateY(-50%);
`;

export const SettingsCopyIcon = styled(SettingsIcon)`
  cursor: pointer;
`;

export const SettingsButton = styled(Button)`
  padding: 18px 43px;
  background: rgba(228, 228, 228, 0.1);
  border-radius: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #ffffff;
  border: none;
  transition: 0.3s;
  margin-top: 20px !important;

  &:hover {
    background: #6c5dd3;
  }

  span {
    font-size: 14px;
    text-transform: none;
  }

  @media (max-width: 998px) {
    display: block;
    margin: 0 auto;
  }
`;

export const SettingsLabelLoad = styled.label`
  padding: 18px 43px;
  background: rgba(228, 228, 228, 0.1);
  border-radius: 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  margin-right: 14px;

  @media (max-width: 878px) {
    margin-bottom: 14px;
  }

  &:hover {
    background: #6c5dd3;
  }
`;
