import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BLOCKCHAIN_INFO, TOKEN_TYPES } from "blockchain/tokenInfo";
import { SET_PLAYER_CURRENCY_GOLD_COUNT } from "reducers/redux/Profile/PlayerCurrency/actions";
import appError from "utils/appError";
import { claimNFT, finishNFTClaim } from "utils/apiRequest";
import { stargateReceiveTokenType } from "utils/web3Calls";
import { openModal } from "reducers/redux/Modal/actions";
import { removeNtf } from "reducers/redux/Profile/NFT/actions";
import NFTList from "../items/NFTList";
import { CurrencyFlexGrid } from "../styles";
import ProfilePaper from "../ProfilePaper";
import GameGridList from "components/GameGridList";
import { Grid } from "@material-ui/core";

const NFTs = React.memo(() => {
  const listNFT = useSelector((state) => state?.NFT?.nftList);
  const gold = useSelector((state) => state.PlayerCurrency.gold);
  const dispatch = useDispatch();
  const chainId = useSelector((state) => state.web3.chainId);

  const ModalMintSuccess = (hash) => {
    dispatch(
      openModal({
        header: `Congratulations!`,
        successButton: "Close",
        values: [`You’ve successfully minted this NFT! Transaction hash: ${hash}`],
      })
    );
  };

  const ModalMintLoading = () => {
    dispatch(
      openModal({
        header: `Loading...`,
        noClose: true,
        noSuccess: true,
      })
    );
  };

  const ModalDisassembleSuccess = () => {
    dispatch(
      openModal({
        header: `Congratulations!`,
        successButton: "Close",
        values: ["You’ve successfully dismantled this NFT!"],
      })
    );
  };

  const deleteHandler = (id) => {
    dispatch(removeNtf(id));
  };

  const dismantleHandler = (data) => {
    const { id } = data;
    dispatch(
      openModal({
        closeButton: true,
        values: [
          "Dismantling NFTs gives you 1000 gold but burns your NFT!",
          "Gold can be used craft NFTs. This process is irreversible!",
        ],
        successHandler: () => {
          claimNFT(id, chainId, true).then(() => {
            dispatch({ type: SET_PLAYER_CURRENCY_GOLD_COUNT, data: gold.count + 1000 });
            deleteHandler(id);
            ModalDisassembleSuccess();
          });
        },
      })
    );
  };

  const mintHandler = (mintData) => {
    const { trackerId, meta } = mintData;
    dispatch(
      openModal({
        header: `Details`,
        closeButton: true,
        values: [
          meta.name,
          meta.description,
          "Minting an NFT is irreversible. Once you mint it you may no longer dismantle it. You may only mint one NFT at a time.",
        ],
        successHandler: () => {
          ModalMintLoading();
          claimNFT(trackerId, false).then((claimResponse) => {
            const {
              id,
              wallet_address,
              amount,
              token_id,
              contract_address,
              data,
              chain_id,
              signature,
            } = claimResponse.data;
            const stargateAddress = BLOCKCHAIN_INFO[chain_id].stargates.main;
            const { fee } = BLOCKCHAIN_INFO[chain_id];
            stargateReceiveTokenType(
              TOKEN_TYPES.ERC721,
              stargateAddress,
              contract_address,
              wallet_address,
              amount,
              token_id,
              data,
              signature,
              fee
            )
              .then((tx) => {
                finishNFTClaim(id, tx);
                deleteHandler(trackerId);
                ModalMintSuccess(tx);
              })
              .catch((error) => {
                appError(
                  "The following error occured attempting to handle nft stuff..",
                  error
                );
                return error;
              });
          });
          // todo catch errors
        },
      })
    );
  };

  return (
    <ProfilePaper title="NFTs" expanding>
      <Grid container justifyContent="flex-start" spacing={3}>
        {!!listNFT &&
          listNFT.length > 0 &&
          listNFT.map((nftData) => (
            <Grid container item xl={4} lg={6} md={6}>
              <NFTList
                nftData={nftData}
                dismantleHandler={dismantleHandler}
                mintHandler={mintHandler}
              />
            </Grid>
          ))}
      </Grid>
    </ProfilePaper>
  );
});

export default NFTs;
