// Types
const LOADING_FULL_SCREEN = "LOADING_FULL_SCREEN";
const LOADED_FULL_SCREEN = "LOADED_FULL_SCREEN";
const LOADING_PARTIAL_SCREEN = "LOADING_PARTIAL_SCREEN";
const LOADED_PARTIAL_SCREEN = "LOADED_PARTIAL_SCREEN";

export const LOADING_MEMBERS = {
  APP_DATA: "APP_DATA",
  DEFAULT_UNSPECIFIC: "DEFAULT_UNSPECIFIC",
};

// actions
export const loadFullScreen = (section = LOADING_MEMBERS.DEFAULT_UNSPECIFIC) => ({
  type: LOADING_FULL_SCREEN,
  payload: section,
});

export const loadedFullScreen = (section = LOADING_MEMBERS.DEFAULT_UNSPECIFIC) => ({
  type: LOADED_FULL_SCREEN,
  payload: section,
});

export const loadingPartialScreen = (name) => ({
  type: LOADING_PARTIAL_SCREEN,
  name,
});

export const loadedPartialScreen = (name) => ({
  type: LOADED_PARTIAL_SCREEN,
  name,
});

const INIT_STATE = {
  fullScreen: {
    loadingAppData: false,
  },
  partialScreen: {},
};

// reducer
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_FULL_SCREEN:
      return {
        ...state,
        fullScreen: { ...state.fullScreen, [action.payload]: true },
      };
    case LOADED_FULL_SCREEN:
      return {
        ...state,
        fullScreen: { ...state.fullScreen, [action.payload]: false },
      };
    case LOADING_PARTIAL_SCREEN:
      return {
        ...state,
        partialScreen: { ...state.partialScreen, [action.name]: true },
      };
    case LOADED_PARTIAL_SCREEN:
      return {
        ...state,
        partialScreen: { ...state.partialScreen, [action.name]: false },
      };
    default:
      return state;
  }
};
