import React from "react";
import { FormInput, InputFormWrapper, LabelForm, InputButton } from "./styles";

const InputForm = ({
  handler,
  value,
  label,
  id,
  type = "text",
  name,
  error,
  disableValue,
  children,
  required = false,
  link,
  steam,
  ...custom
}) => (
  <InputFormWrapper error={error ? error?.text : false}>
    <LabelForm htmlFor={id || label}>{label}</LabelForm>
    {!link || steam ? (
      <FormInput
        id={id || label}
        name={id || label}
        onChange={handler}
        value={value || ""}
        type={type}
        error={error}
        disabled={disableValue}
        required={required}
        {...custom}
      >
        {children}
      </FormInput>
    ) : (
      <a href={link}>
        <InputButton
          id={id || label}
          name={id || label}
          onChange={handler}
          value={value || ""}
          error={error?.name === name}
          required={required}
        >
          Connect your Steam Account
        </InputButton>
      </a>
    )}
    {children}
  </InputFormWrapper>
);

export default InputForm;
