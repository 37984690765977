import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import Text from "components/Text";
import { verifyEmailToken, resendEmailValidation } from "../../actions";
import LoadingSpinner from "../LoadingSpinner";
import { history } from "../../store/index";

class EmailVerification extends Component {
  componentWillMount() {
    let tokenError = "Verifying Email...";
    if (this.props.user) {
      if (this.props.user.email_address_verified) {
        tokenError = "Email already verified";
      } else if (!this.props.match.params.token) {
        tokenError = "Check your email account, or resend the verification email";
      }
    }
    this.setState({
      isSaving: false,
      tokenError,
    });
  }

  componentDidMount() {
    if (this.props.match.params.token && !this.props.user.email_address_verified) {
      this.props
        .verifyEmailToken(this.props.match.params.token)
        .then(() => {
          this.setState({
            tokenError: "Email Verified",
          });
        })
        .catch(() => {
          this.setState({
            tokenError: "Invalid token, or token has expired",
          });
        });
    }
  }

  render() {
    return (
      <Grid container spacing={3} className="email_verification">
        <Grid item xs={12}>
          <Text variant="h4" align="center" className="email__header">
            {this.state.tokenError}
          </Text>
          {!this.props.user.email_address_verified && (
            <>
              <Button
                onClick={() => {
                  this.setState({ isSaving: true });
                  this.props.resendEmailValidation().then(() => {
                    this.setState({ isSaving: false });
                  });
                }}
                variant="text"
                className="mt--32 m--auto btn-linkfix"
              >
                {this.state.isSaving ? <LoadingSpinner /> : "Resend email"}
              </Button>
              <Text align="center">
                {this.props.user.formSuccess
                  ? this.props.user.formSuccess
                  : this.props.user.formError}
              </Text>
            </>
          )}
          {this.props.user.email_address_verified &&
          (this.state.tokenError === "Email Verified" ||
            this.state.tokenError === "Email already verified") ? (
            <Button  // eslint-disable-line
              variant="text"
              color="primary"
              className="mt--32 m--auto btn-play"
              onClick={() => {
                history.push("/market");
              }}
            >
              Lets Play!
            </Button>
          ) : (
            ""
          )}
          {this.props.user.role_id === 1 &&
            this.props.user.email_address_verified &&
            !this.props.user.country_of_origin && (
              <Button
                component={Link}
                to="/completeinfo"
                variant="text"
                className="mt--32 m--auto btn-linkfix"
              >
                Complete your account setup (1/2)
              </Button>
            )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  verifyEmailToken,
  resendEmailValidation,
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(EmailVerification)
);
