import React from "react";
import { GamesListWrapper, Logo, Text } from "./styles";
import logo from "../../../images/logos/signInCamo.png";
import List from "./List";

export default () => {
  return (
    <GamesListWrapper>
      <div>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
        <Text>Blockchain Gaming Platform</Text>
        <List />
      </div>
    </GamesListWrapper>
  );
};
