import React from "react";
import { IS_PROD } from "utils/environments";
import { Grid, Typography } from "@material-ui/core";

export const challengeGameId = IS_PROD ? 51 : 82;

export const roles = IS_PROD
  ? { admin: 0, user: 1, publisher: 2 }
  : { admin: 0, user: 1, publisher: 2 };

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "The Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bissau",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Cook Islands",
  "Colombia",
  "Comoros",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Democratic Republic of Congo",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "North Korea",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const platforms = [
  { value: "ios", label: "iOS" },
  { value: "android", label: "Android" },
  { value: "xbox", label: "Xbox" },
  { value: "ps4", label: "PS4" },
];

export const engines = ["Unity", "Unreal", "Other"];

export const gameElements = [
  { value: "Achivements", label: "Achivements" },
  { value: "In-App purchases", label: "In-App purchases" },
  { value: "Local multiplayer", label: "Local multiplayer" },
  { value: "Online multiplayer", label: "Online multiplayer" },
  { value: "Cooperative", label: "Cooperative" },
  { value: "Single player", label: "Single player" },
  { value: "Cloud saves", label: "Cloud saves" },
  { value: "Leaderboards", label: "Leaderboards" },
  { value: "Controller support", label: "Controller support" },
  { value: "Cross-platform", label: "Cross-platform" },
  { value: "Split Screen", label: "Split Screen" },
  { value: "Discord rich presence", label: "Discord rich presence" },
  { value: "Discord game Invites", label: "Discord game Invites" },
  { value: "Steam trading Cards", label: "Steam trading Cards" },
  { value: "Valve Anti-Cheat", label: "Valve Anti-Cheat" },
  { value: "Steam workshop", label: "Steam workshop" },
  { value: "E-sport tournaments", label: "E-sport tournaments" },
];

export const categories = [
  { value: "Action", label: "Action" },
  { value: "Role play / RPG ", label: "Role play / RPG " },
  { value: "Adventure", label: "Adventure" },
  { value: "Casual", label: "Casual" },
  { value: "Indie", label: "Indie" },
  { value: "MMO", label: "MMO" },
  { value: "Driving/Racing", label: "Driving/Racing" },
  { value: "Simulation", label: "Simulation" },
  { value: "Sport", label: "Sport" },
  { value: "Strategy", label: "Strategy" },
  { value: "Fighting", label: "Fighting" },
  { value: "Defender", label: "Defender" },
  { value: "Shooter", label: "Shooter" },
  { value: "Role playing", label: "Role playing" },
  { value: "Hack and slash", label: "Hack and slash" },
  { value: "Platformer", label: "Platformer" },
  { value: "Metroidvania", label: "Metroidvania" },
  { value: "Music/Rhythm", label: "Music/Rhythm" },
  { value: "Open-world", label: "Open-world" },
  { value: "Real-time strategy / RTS", label: "Real-time strategy / RTS" },
  { value: "Horror", label: "Horror" },
  { value: "Sandbox", label: "Sandbox" },
  { value: "Survival", label: "Survival" },
  { value: "Trivia/Board Game", label: "Trivia/Board Game" },
  { value: "Turn based strategy", label: "Turn based strategy" },
  { value: "E-sport", label: "E-sport" },
  { value: "Experimental", label: "Experimental" },
  { value: "Puzzle", label: "Puzzle" },
  { value: "Indie", label: "Indie" },
  { value: "AAA", label: "AAA" },
];

export const ageContent = [
  { value: "Alcohol Reference", label: "Alcohol Reference" },

  { value: "animated blood", label: "Animated Blood" },

  { value: "blood", label: "Blood" },

  { value: "blood and gore", label: "Blood and Gore" },

  { value: "cartoon violence", label: "Cartoon Violence" },

  { value: "comic mischief", label: "Comic Mischief" },

  { value: "crude humor", label: "Crude Humor" },

  { value: "drug reference", label: "Drug Reference" },

  { value: "fantasy violence", label: "Fantasy Violence" },

  { value: "intense violence", label: "Intense Violence" },

  { value: "language", label: "Language" },

  { value: "lyrics", label: "Lyrics" },

  { value: "mature humor", label: "Mature Humor" },

  { value: "nudity", label: "Nudity" },

  { value: "partial nudity", label: "Partial Nudity" },

  { value: "real gambling", label: "Real Gambling" },

  { value: "sexual content", label: "Sexual Content" },

  { value: "sexual themes", label: "Sexual Themes" },

  { value: "sexual violence", label: "Sexual Violence" },

  { value: "simulated gambling", label: "Simulated Gambling" },

  { value: "strong language", label: "Strong Language" },

  { value: "strong lyrics", label: "Strong Lyrics" },

  { value: "strong sexual content", label: "Strong Sexual Content" },

  { value: "suggestive themes", label: "Suggestive Themes" },

  { value: "tobacco reference", label: "Tobacco Reference" },

  { value: "use of alcohol", label: "Use of Alcohol" },

  { value: "use of drugs", label: "Use of Drugs" },

  { value: "use of tobacco", label: "Use of Tobacco" },

  { value: "violence", label: "Violence" },

  { value: "violent references", label: "Violent References" },
];

export const ageRating = [
  { value: "everyone", label: "everyone" },
  { value: "everyone10+", label: "everyone 10+" },
  { value: "teen", label: "teen" },
  { value: "mature", label: "mature" },
  { value: "adults only", label: "adults only" },
];

export const interactiveContent = [
  { value: "In-Game Purchases", label: "In-Game Purchases" },
  { value: "Users Interact", label: "Users Interact" },
  { value: "Shares Location", label: "Shares Location" },
  { value: "Unrestricted Internet", label: "Unrestricted Internet" },
];

export const termsOfService = (
  <Grid container>
    <Grid item xs={12} className="register__TOS-title">
      <Typography color="inherit" variant="display1">
        Terms of Service
      </Typography>
      {/* content start */}
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="display1">
          User agreement
        </Typography>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          1. REGISTRATION AS A SUBSCRIBER; APPLICATION OF TERMS TO YOU; YOUR ACCOUNT
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Platform(&quot;Site&quot; or www.tapproject.net) is an online service
            offered by The Tap Project - &quot;Truly Simplistic Innovations Inc.&quot;
            (&quot;The Tap Project&quot; or &quot;we&quot;).
            <br />
            You become a subscriber of The Tap Platform (&quot;Subscriber&quot;) by
            registering for a user account with The Tap Platform. This User Agreement,
            along with the Privacy Policy, applies to all users of The Tap Platform. The
            Tap Platform is offered to you on the condition of your acceptance without
            modification of the terms, conditions, and notices contained in this User
            Agreement.Your indication of acceptance of this User Agreement during the
            registration process constitutes your agreement to all such terms of this User
            Agreement.
            <br />
            <br />
            A. Contracting Party
            <br />
            For any interaction with The Tap Platform your contractual relationship is
            with The Tap Project.
            <br />
            All transactions for Subscriptions (defined below) you make on The Tap
            Platform are transactions entered into with The Tap Project, unless otherwise
            indicated at the time of the transaction (such as in the case of purchases
            from another Subscriber in a Subscription Marketplace).
            <br />
            <br />
            B. Subscriptions; Content and Services
            <br />
            As a Subscriber you may obtain access to certain services, software and
            content available to Subscribers. The Tap Platform client software and any
            other software, content, and updates you download or access via The Tap
            Platform, including but not limited to The Tap Project or third-party video
            games and in-game content, and any virtual items or currency you trade, sell
            or purchase in a The Tap Platform Subscription Marketplace are referred to in
            this Agreement as &quot;Content and Services&quot;. The rights to access
            and/or use any Contents and Services accessible through The Tap Platform are
            referred to in this Agreement as &quot;Subscriptions.&quot;
            <br />
            Each Subscription allows you to access particular Content and Services. Some
            Subscriptions may impose additional terms specific to that Subscription
            (&quot;Subscription Terms&quot;) (for example, an end user license agreement
            specific to a particular game, User Agreement specific to a particular product
            or feature of The Tap Platform). The Tap Project Privacy Policy and Tap
            Platform User Agreement are binding on you once you indicate your acceptance
            of them or of this Agreement.
            <br />
            <br />
            C. Your Account
            <br />
            When you complete The Tap Platform’s registration process, you create a Tap
            Platform account (&quot;Account&quot;). You may not reveal, share or otherwise
            allow others to use your password or Account except as otherwise specifically
            authorized by The Tap Project. You are responsible for the confidentiality of
            your login and password and for the security of your computer system. The Tap
            Project is not responsible for the use of your password and Account or for all
            of the communication and activity on The Tap Platform that results from use of
            your login name and password by you, by any person to whom you may have
            intentionally or through negligence disclosed your login and/or password in
            violation of this confidentiality provision. Unless it results from The Tap
            Project&apos;s negligence or fault, The Tap Project is not responsible for the
            use of your Account by a person who fraudulently used your login and password
            without your permission. If you believe that the confidentiality of your login
            and/or password may have been compromised, you must notify The Tap Project via
            the support form or contact email at{" "}
            <a href="mailto:team@tapproject.net">team@tapproject.net</a> or{" "}
            <a href="mailto:contact@tapproject.net">contact@tapproject.net</a>. Your
            Account, including any information pertaining to it (e.g.: contact
            information, Account history and Subscriptions, etc.), is strictly personal.
            You may therefore not sell or charge others for the right to use your Account,
            or otherwise transfer your Account. You also may not sell, or charge others
            for the right to use, or otherwise transfer any of your Subscriptions, except
            as expressly permitted by this Agreement or with the express consent of The
            Tap Project.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          2. BILLING, PAYMENT AND USE OF BLOCKCHAIN WALLETS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            All charges incurred on The Tap Platform, and all purchases made with The Tap
            Platform Wallet, are final and payable in advance.
            <br />
            <br />
            A. Payment Authorization
            <br />
            When you provide your wallet address and information to The Tap Project or to
            one of its processors, you represent to The Tap Project that you are the
            authorized user of the address, key or account associated with that payment.
            The Tap Project may require you to provide your address or other information
            in order to meet their obligations under applicable tax law. The Tap Project
            does not hold access to your private key. The subscriber is responsible for
            protecting and guarding access to their wallet, their Tap Platform Wallet
            (defined below) or any other wallets associated to The Tap Platform.
            <br />
            If your use of The Tap Platform is subject to any type of use or sales tax,
            then The Tap Project may also charge you for those taxes, in addition to the
            Subscription and other fees published in the User Agreement. The subscriber
            understands that every conversion is subject to a transactional fee and/or gas
            fee on both the ethereum network and The Tap Platform. It is acknowledged that
            every transaction is subject to a fee/gas and the fees/gas will be taken off
            during the transaction.
            <br />
            You agree that you will not use IP proxying or other methods to disguise the
            place of your residence, whether to circumvent geographical restrictions on
            game content, to purchase at pricing not applicable to your geographical
            location, or for any other purpose. If you do this, The Tap Project may
            terminate your access to your Account.
            <br />
            <br />
            B. Responsibility for Charges Associated With Your Account
            <br />
            As the Subscription holder, you are responsible for all charges incurred,
            including applicable taxes, and all purchases or conversions made by you or
            anyone that uses your Account, including your family or friends. If you cancel
            your Account, The Tap Project reserves the right to collect fees, surcharges
            or costs incurred before cancellation. Any delinquent or unpaid Accounts must
            be settled before The Tap Project will allow you to register again.
            <br />
            <br />
            C. The Tap Platform Wallet
            <br />
            The Tap Platform may make available an account balance associated with your
            Account (the &quot;The Tap Platform Wallet&quot;). The Tap Platform Wallet is
            neither a bank account nor any kind of payment instrument. The Tap Platform
            Wallet is a smart wallet that is written in the form of a smart contract on
            the Ethereum network. The Tap Platform Wallet does not house your private
            keys. The subscriber remains in full control of his/her own private keys and
            they are used to sign transactions with your smart wallet. The Tap Project may
            change or impose different balance and usage limits on The Tap Platform Wallet
            from time to time.
            <br />
            Your continued use of your Tap Platform Account for more than thirty (30) days
            after the execution of such changes, will constitute your acceptance of the
            changes. If you don’t agree to the changes, you may terminate your Tap
            Platform Account and/or cease to use your The Tap Platform Wallet. The Tap
            Project cannot refund any credits remaining on your Tap Platform wallet as the
            ability to access an individuals Tap Platform Wallet remains solely with each
            user.
            <br />
            You may use Tap Coins to purchase Subscriptions, including making in-game
            purchases where The Tap Platform Wallet transactions are enabled, and Tap
            Platform Hardware. Funds added to The Tap Platform Wallet are non-refundable
            and are transferable to other Ethereum based wallets. Tap Coins do not
            constitute a personal property right. Tap Coins can be used to purchase
            Subscriptions, join tournaments, purchase avatars, keys, and be used in any
            functions or related content via The Tap Platform (including but not limited
            to games and other applications offered through The Tap Platform Store, or in
            The Tap Platform Subscription Marketplace) and Hardware. The Subscriber
            understands that if they choose to convert their game score into Tap Coins,
            that score, and associated points, virtual currency, skins, assets, or
            environments, as applicable, will no longer belong to the Subscriber and will
            no longer appear in their game.
            <br />
            <br />
            D. Free Subscriptions
            <br />
            In some cases, The Tap Project may offer a free Subscription to certain
            services, software and content. As with all Subscriptions, you are always
            responsible for any Internet service provider, telephone, and other connection
            fees that you may incur when using The Tap Platform, even when The Tap Project
            offers a free Subscription.
            <br />
            <br />
            E. Third Party Sites
            <br />
            The Tap Platform may provide links to other third party sites. Some of these
            sites may charge separate fees, which are not included in and are in addition
            to any Subscription or other fees that you may pay to The Tap Project. The Tap
            Platform may also provide access to third-party vendors, who provide content,
            goods and/or services on The Tap Platform or the Internet. Any separate
            charges or obligations you incur in your dealings with these third parties are
            your responsibility. The Tap Project makes no representation or warranty that
            any service or subscription offered via third-party vendors will not change or
            be suspended or terminated.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          3. ONLINE CONDUCT, CHEATING AND ILLEGAL BEHAVIOR
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            Your online conduct and interaction with other Subscribers should be guided by
            common sense and basic etiquette. Depending on User Agreements imposed by
            third parties who host particular games or other services, additional
            requirements may also be applicable to a particular Subscription.
            <br />
            The Tap Platform and the Content and Services may include functionality
            designed to identify software or hardware processes or functionality that may
            give a player an unfair competitive advantage when playing multiplayer
            versions of any Content and Services or modifications of Content and Services
            (&quot;Cheats&quot;). You agree that you will not create Cheats or assist
            third parties in any way to create or use Cheats. You agree that you will not
            directly or indirectly disable, circumvent, or otherwise interfere with the
            operation of software designed to prevent or report the use of Cheats. You
            acknowledge and agree that either The Tap Project or any online multiplayer
            host may refuse to allow you to participate in certain online multiplayer
            games if you use Cheats in connection with The Tap Platform or the Content and
            Services. Further, you acknowledge and agree that an online multiplayer host
            may report your use of Cheats to The Tap Project, and The Tap Project may
            communicate your history of use of Cheats to other online multiplayer hosts.
            The Tap Project may terminate your Account or a particular Subscription for
            any conduct or activity that is illegal, constitutes a Cheat, or otherwise
            negatively affects the enjoyment of The Tap Platform by other Subscribers. You
            acknowledge that The Tap Project is not required to provide you notice before
            terminating your Subscription(s) and/or Account.
            <br />
            You may not use Cheats, automation software (bots), mods, hacks, or any other
            unauthorized third-party software, to modify or automate any Subscription
            Marketplace process.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          4. TAP PLATFORM COPYRIGHTS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Platform and contents and elements of The Tap Platform (including,
            e.g. both editorial content and the code used in programming the platform) are
            protected by Canadian and international copyright laws. Except as explicitly
            provided in this User Agreement, you may not reproduce, distribute, transmit,
            display, prepare derivative works, perform, or use in any other way that is
            prohibited by copyright law without consent of the copyright holder, any
            copyrighted material found on or in The Tap Project’s digital properties. You
            may copy and print content for your personal, non-commercial use only,
            provided that you include all copyright and other notices contained in the
            content and that you do not modify the content. To request permission from The
            Tap Project for any other use, please email us at info@tapproject.net.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          5. TAP PLATFORM MARKS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Project, The Tap Project program names (including The Tap Platform)
            and logos are trademarks or service marks of The Tap Project or its partners.
            The Tap Project reserves all rights to these marks. You may not use any of The
            Tap Project marks, logos, or graphics without the prior written consent of The
            Tap Project. All other trademarks and service marks appearing on the digital
            platform are the property of their respective owners, and should be used only
            with prior written consent from the appropriate owner.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          6. PRIVACY AND DATA PROTECTION
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Projectis committed to safeguarding the privacy of your personal
            information. Your use of www.tapproject.net is subject to The Tap
            Project&apos;s Privacy Policy. Please review our Privacy Policy, which also
            governs The Tap Platform and informs users of our data collection practices.
            You can access our Privacy Policy here:
            <a href="https://tapproject.net/#privacy_policy">
              https://tapproject.net/#privacy_policy
            </a>
            . Our Privacy Policy includes terms for our users based in the European
            Economic Area (EEA) who are subject to the requirements of the EU General Data
            Protection Regulation.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          7. ELECTRONIC COMMUNICATIONS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            Visiting www.tapproject.net or sending emails to The Tap Project constitutes
            electronic communications. You consent to receive electronic communications
            and you agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically, via email and on The Tap
            Platform, satisfy any legal requirement that such communications be in
            writing.
            <br />
            The Tap Project does not knowingly collect, either online or offline, personal
            information from persons under the age of thirteen. If you are under 18, you
            may use www.tapproject.net only with permission of a parent or guardian.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          8. NO UNLAWFUL OR PROHIBITED USE OF INTELLECTUAL PROPERTY
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            You are granted a non-exclusive, non-transferable, revocable license to access
            and use www.tapproject.net strictly in accordance with thisUser Agreement. As
            a condition of your use of The Tap Platform, you warrant to The Tap Project
            that you will not use The Tap Platform for any purpose that is unlawful or
            prohibited by this User Agreement. You may not use The Tap Platform in any
            manner which could damage, disable, overburden, or impair The Tap Platform or
            interfere with any other party&apos;s use and enjoyment of The Tap Platform.
            You may not obtain or attempt to obtain any materials or information through
            any means not intentionally made available or provided for through The Tap
            Platform.
            <br />
            All content included as part of yourSubscription, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software used on
            The Tap Platform, is the property of The Tap Project or its suppliers and
            protected by copyright and other laws that protect intellectual property and
            proprietary rights. You agree to observe and abide by all copyright and other
            proprietary notices, legends or other restrictions contained in any such
            content and will not make any changes thereto.
            <br />
            You will not modify, publish, transmit, reverse engineer, participate in the
            transfer or sale, create derivative works, or in any way exploit any of the
            content, in whole or in part, found on The Tap Platform. The Tap Project’s
            content is not for resale. Your use of The Tap Platform does not entitle you
            to make any unauthorized use of any protected content, and in particular you
            will not delete or alter any proprietary rights or attribution notices in any
            content. You will use protected content solely for your personal use, and will
            make no other use of the content without the express written permission of The
            Tap Project and the copyright owner. You agree that you do not acquire any
            ownership rights in any protected content. We do not grant you any licenses,
            express or implied, to the intellectual property of The Tap Project or our
            licensors except as expressly authorized by this User Agreement.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          9. INTERNATIONAL USERS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Platform is controlled, operated and administered by The Tap Project
            from our offices within Canada. If you access The Tap Platform from a location
            outside Canada, you are responsible for compliance with all local laws. You
            agree that you will not use The Tap Project content accessed through
            www.tapproject.net in any country or in any manner prohibited by any
            applicable laws, restrictions or regulations.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          10. INDEMNIFICATION
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            You agree to indemnify, defend and hold harmless The Tap Project, its
            officers, directors, employees, agents and third parties, for any losses,
            costs, liabilities and expenses (including reasonable attorney&apos;s fees)
            relating to or arising out of your use of or inability to use The Tap Platform
            or services, any user postings made by you, your violation of any terms of
            this User Agreement or your violation of any rights of a third party, or your
            violation of any applicable laws, rules or regulations. The Tap Project
            reserves the right, at its own cost, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you, in which
            event you will fully cooperate with The Tap Project in asserting any available
            defenses.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          11. ARBITRATION
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            In the event the parties are not able to resolve any dispute between them
            arising out of or concerning this User Agreement, or any provisions hereof,
            whether in contract, tort, or otherwise at law or in equity for damages or any
            other relief, then such dispute shall be resolved only by final and binding
            arbitration pursuant to arbitration in the Province of British Columbia. The
            Arbitrator’s award shall be final, and judgment may be entered upon it in any
            court having jurisdiction. In the event that any legal or equitable action,
            proceeding or arbitration arises out of or concerns this User Agreement, the
            prevailing party shall be entitled to recover its costs and reasonable
            attorney&apos;s fees. The parties agree to arbitrate all disputes and claims
            in regards to this User Agreement or any disputes arising as a result of this
            User Agreement, whether directly or indirectly, including Tort claims that are
            a result of this User Agreement. The entire dispute, including the scope and
            enforceability of this arbitration provision shall be determined by the
            Arbitrator. This arbitration provision shall survive the termination of this
            User Agreement.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          12. LIABILITY DISCLAIMER
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The information, software, products, and services included in or available
            through The Tap Platform may include inaccuracies or typographical errors.
            Changes are periodically added to the information herein. The Tap Project
            and/or its suppliers may make improvements and/or changes to The Tap Platform
            at any time.
            <br />
            The Tap Project and/or its suppliers make no representations about the
            suitability, reliability, availability, timeliness, and accuracy of the
            information, software, products, services and related graphics contained on
            The Tap Platform for any purpose. To the maximum extent permitted by
            applicable law, all such information, software, products, services and related
            graphics are provided &quot;as is&quot; without warranty or condition of any
            kind. The Tap Project and/or its suppliers hereby disclaim all warranties and
            conditions with regard to this information, software, products, services and
            related graphics, including all implied warranties or conditions of
            merchantability, fitness for a particular purpose, title and non-infringement.
            <br />
            To the maximum extent permitted by applicable law, in no event shall The Tap
            Project and/or its suppliers be liable for any direct, indirect, punitive,
            incidental, special, consequential damages or any damages whatsoever
            including, without limitation, damages for loss of use, data or profits,
            arising out of or in any way connected with the use or performance of The Tap
            Platform, with the delay or inability to use The Tap Platform or related
            services, the provision of or failure to provide services, or for any
            information, software, products, services and related graphics obtained
            through The Tap Platform, or otherwise arising out of the use of The Tap
            Platform, whether based on contract, tort, negligence, strict liability or
            otherwise, even if The Tap Project or any of its suppliers has been advised of
            the possibility of damages. If you are dissatisfied with any portion of The
            Tap Platform, or with thisUser Agreement, your sole and exclusive remedy is to
            discontinue using The Tap Platform.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          13. LIMITATION OF LIABILITY
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            In no event will The Tap Project, its directors, officers, or employees be
            liable for any indirect, consequential, special, incidental, or punitive
            damages, arising out of the use or inability to use the digital platform.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          14. COPYRIGHT AGENT
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            If you believe that your work has been reproduced on our digital platform in a
            way that constitutes copyright infringement, please provide the following
            information in writing to The Tap Project:
            <br />
            a. A physical or electronic signature of a person authorized to act on behalf
            of the owner of the copyright.
            <br />
            b. A description of the copyrighted work that you claim has been infringed.
            <br />
            c. A description of the material that you claim to be infringing or to be the
            subject of infringing activity and that is to be removed or access to which is
            to be disabled, and information sufficient to permit us to locate the
            material.
            <br />
            d. Your contact information, such as address, telephone number, and e-mail
            address.
            <br />
            e. A statement that you have a good faith belief that use of the material in
            the manner complained of is not authorized by the copyright owner, its agent,
            or the law.
            <br />
            f. A statement that the information in the notification is accurate and, under
            penalty of perjury, that you are the copyright owner or are authorized to act
            on behalf of the owner of a copyright that is allegedly infringed.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          15. TERMINATION AND RESTRICTION OF ACCESS
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Project reserves the right, in its sole discretion, to terminate or
            restrict your access to The Tap Platform and the related services or any
            portion thereof at any time, without notice. To the maximum extent permitted
            by law, this agreement is governed by the laws of the Province of British
            Columbia and you hereby consent to the exclusive jurisdiction and venue of
            courts in the Province of British Columbia in all disputes arising out of or
            relating to the use of The Tap Platform. Use of The Tap Platform is
            unauthorized in any jurisdiction that does not give effect to all provisions
            of this User Agreement, including, without limitation, this section.
            <br />
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and The Tap Project as a result of this
            agreement or use of The Tap Platform. The Tap Project&apos;s performance of
            this agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of The Tap Project&apos;s right
            to comply with governmental, court and law enforcement requests or
            requirements relating to your use of The Tap Platform or information provided
            to or gathered by The Tap Project with respect to such use. If any part of
            this agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty disclaimers and
            liability limitations set forth above, then the invalid or unenforceable
            provision will be deemed superseded by a valid, enforceable provision that
            most closely matches the intent of the original provision and the remainder of
            the agreement shall continue in effect.
            <br />
            Unless otherwise specified herein, this agreement constitutes the entire
            agreement between the user and The Tap Project with respect to The Tap
            Platform and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and The Tap
            Project with respect to The Tap Platform. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this agreement to the
            same extent and subject to the same conditions as other business documents and
            records originally generated and maintained in printed form. It is the express
            wish of the parties that this agreement and all related documents be written
            in English.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt--48">
        <Typography color="inherit" variant="body1">
          16. CONTACT US
        </Typography>
        <Grid item xs={12} className="mt--16">
          <Typography color="inherit">
            The Tap Project welcomes your questions or comments regarding this User
            Agreement:
            <br />
            Email Address:
            <br />
            info@tapproject.net
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export const privacyPolicy = (
  <Grid container>
    <Grid item xs={12} className="register__TOS-title">
      <Typography color="inherit" variant="display1">
        Privacy Policy
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit">
        Truly Simplistic Innovations Inc. (&quot;we&quot; or &quot;The Tap Project&quot;)
        respects the privacy of its online visitors and customers of its products and
        services and complies with applicable laws for the protection of your privacy,
        including, without limitation, the British Columbia Privacy Act
        (&quot;BCPA&quot;), the Personal Information Protection Act (&quot;PIPA&quot;)
        and, for our users based in the European Union, the General Data Protection
        Regulation (&quot;GDPR&quot;).
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        1. Definitions
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          Wherever we talk about Personal Data below, we mean any information that can
          either itself identify you as an individual (&quot;Personally Identifying
          Information&quot;) or that can be connected to you indirectly by linking it to
          Personally Identifying Information. The Tap Project also processes anonymous
          data, aggregated or not, to analyze and produce statistics related to the
          habits, usage patterns, and demographics of customers as a group or as
          individuals. Such anonymous data does not allow the identification of the
          customers to which it relates. The Tap Project may share anonymous data,
          aggregated or not, with third parties.
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        2. Consent to Collection and Why The Tap Projec Collects and Processes Data
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          2.1 Consent
          <br />
          The Tap Project may obtain your consent to our collection, use and disclosure of
          your Personal Data, either expressly for a stated purpose, or impliedly when the
          purposes are indicated by the relevant circumstances.We have outlined the
          various circumstances in which we collect, use and disclose your Personal Data
          in section 6 of this Privacy Policy below.
          <br />
          By providing us with Personal Data, you consent to the collection, use and
          disclosure of your Personal Data in accordance with this Privacy Policy.
          <br />
          As required by law, we will seek your consent before using Personal Data for any
          purpose beyond the scope of your original consent. You may withdraw your consent
          at any time, subject to legal and contractual restrictions and reasonable
          notice. Please note, however, that withdrawing consent may affect our ability to
          provide services to you.
        </Typography>
      </Grid>

      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          2.2 Why We Collect and Process Data
          <br />
          The Tap Project collects and processes Personal Data for the following reasons:
          <br />
          <br />
          a) where it is necessary for the performance of our agreement with you to
          provide a full-featured gaming service and deliver associated Content and
          Services;
          <br />
          b) where it is necessary for compliance with legal obligations that we are
          subject to (e.g. our obligations to keep certain information under tax laws);
          <br />
          c) where it is necessary for the purposes of the legitimate and legal interests
          of The Tap Project or a third party (e.g. the interests of our other customers),
          except where such interests are overridden by your prevailing legitimate
          interests and rights; or
          <br />
          d) where you have given consent to it.
          <br />
          <br />
          These reasons for collecting and processing Personal Data determine and limit
          what Personal Data we collect and how we use it (section 3. below), how long we
          store it (section 4. below), who has access to it (section 5. below) and what
          rights and other control mechanisms are available to you as a user (section 6.
          below).
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        3. What Data We Collect and Process
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          3.1 Basic Account Data
          <br />
          When setting up an Account, The Tap Project will collect your email address,
          date of birth and country of residence. You are also required to choose a user
          name and a password. The provision of this information is necessary to register
          a Tap Platform User account. During setup of your account, the account is
          automatically assigned a number (the &quot;User ID&quot;) that is later used to
          reference your user account without directly exposing Personally Identifying
          Information about you.
          <br />
          We do not require you to provide or use your real name for the setup of a Tap
          Project User Account.
          <br />
          <br />
          3.2 Transaction and Payment Data
          <br />
          In order to make an Ethereum transactions on Tap Project (e.g. to purchase
          Subscriptions for Content and Services or to fund your Tap Project Wallet), you
          may need to provide your Ethereum wallet address to The Tap Project to enable
          the transaction. The Tap Project will process and transmit to the payment and
          receive the information that is publicly accessible via etherscan (ie :
          transaction amount, date, to/from information, hash etc).
          <br />
          <br />
          3.3 Other Data You Explicitly Submit
          <br />
          We will collect and process Personal Data whenever you explicitly provide it to
          us or send it as part of communication with others on the Tap Platform, e.g. in
          Tap Project Community Forums, chats, or when you provide feedback or other user
          generated content. This data includes: Information that you post, comment or
          follow in any of our Content and Services;
          <br />
          Information sent through chat;
          <br />
          Information you provide when you request information or support from us or
          purchase Content and Services from us, including information necessary to
          process your orders with the relevant payment merchant or, in the case of
          physical goods, shipping providers; Information you provide to us when
          participating in competitions, contests and tournaments or responding to
          surveys, e.g. your contact details.
          <br />
          <br />
          3.4 Your Use of the Tap Project Client and Websites
          <br />
          We collect a variety of information through your general interaction with the
          websites, Content and Services offered by The Tap Project. Personal Data we
          collect may include, but is not limited to, browser and device information, data
          collected through automated electronic interactions and application usage data.
          Likewise, we will track your interactions across our websites and applications
          to verify that you are not a bot and to optimize our services.
          <br />
          <br />
          3.5 Your Use of Games and other Subscriptions
          <br />
          In order to provide you with services, we need to collect, store and use various
          information about your activity in our Content and Services.
          &quot;Content-Related Information&quot; includes your User ID, as well as what
          is usually referred to as &quot;game statistics&quot; and analytics. By game
          statistics we mean information about your games&#145; preferences, progress in
          the games, playtime, as well as information about the device you are using,
          including what operating system you are using, device settings, unique device
          identifiers, and crash data.
          <br />
          <br />
          3.6 Tracking Data and Cookies
          <br />
          We use &quot;Cookies&quot;, which are text files placed on your computer, to
          help us analyze how users use our services, and similar technologies (e.g. web
          beacons, pixels, ad tags and device identifiers) to recognize you and/or your
          device(s) on, off and across different devices and our services, as well as to
          improve the services we are offering, to improve marketing, analytics or website
          functionality. The use of Cookies is standard on the internet. Although most web
          browsers automatically accept cookies, the decision of whether to accept or not
          is yours. You may adjust your browser settings to prevent the reception of
          cookies, or to provide notification whenever a cookie is sent to you. You may
          refuse the use of cookies by selecting the appropriate settings on your browser.
          However, please note that if you do this, you may not be able to access the full
          functionality of our websites. When you visit any of our services, our servers
          log your global IP address, which is a number that is automatically assigned to
          the network your computer is part of.
          <br />
          <br />
          3.7 Google Analytics
          <br />
          This website uses Google Analytics, a web analytics service provided by Google,
          Inc. (&quot;Google&quot;). Google Analytics uses Cookies to help the website
          operators analyze how visitors use the site. The information generated by the
          cookie about the visitors&#145; use of the website will generally be transmitted
          to and stored by Google on servers in the United States. On The Tap Project
          website, IP anonymization has been activated. The IP addresses of users visiting
          Tap Project will be shortened. Only in exceptional cases will a complete IP
          address be transferred to a Google server in the United States and converted to
          a shortened one there. On behalf of The Tap Project, Google will use this
          information for the purpose of evaluating the website for its users, in order to
          compile reports on website activity, and to provide other services relating to
          website activity and internet usage for website operators. Google will not
          associate the IP address transferred in the context of Google Analytics with any
          other data held by Google. You may refuse the use of cookies by selecting the
          appropriate settings on your browser. However, please note that in this case you
          may not be able to use the full functionality of this website.
          <br />
          <br />
          3.8 Content Recommendations
          <br />
          We may process information collected under this section 3 so that content,
          products and services shown on the The Tap Project store pages and also in
          update messages displayed when launching the Tap Project Client can be tailored
          to meet your needs and populated with relevant recommendations and offers. This
          is done to improve your customer experience. You can prevent the processing of
          your data in this way by turning off the automatic loading of the Tap Project
          store page and also Tap Project notifications in the &quot;Interface&quot;
          section of the Tap Project Client settings. Subject to your separate consent or
          where explicitly permitted under applicable laws on email marketing, The Tap
          Project may send you marketing messages about products and services offered by
          The Tap Project - &quot;Truly Simplistic Innovations&quot; to your email
          address. In such a case we may also use your collected information to customise
          such marketing messages as well as collect information on whether you opened
          such messages and which links in their text you followed. You can opt out or
          withdraw your consent to receive marketing emails at any time by either
          withdrawing the consent on the same page where you previously provided it or
          clicking the &quot;unsubscribe&quot; link provided in every marketing email.
          <br />
          <br />
          3.9 Information Required to Detect Violations
          <br />
          We collect certain data that is required for the detection, investigation and
          prevention of fraud, cheating and other violations of the SSA and applicable
          laws (&quot;Violations&quot;). This data is used only for the purposes of
          detection, investigation, prevention and, where applicable, responding to such
          Violations and stored only for the minimum amount of time needed for this
          purpose. If the data indicates that a Violation has occurred, we will further
          store the data for the establishment, exercise and/or defense of legal claims
          during the applicable statute of limitations or until a legal case related to it
          has been resolved. Please note that the specific data stored for this purpose
          may not be disclosed to you if the disclosure will compromise the mechanism
          through which we detect, investigate and prevent such Violations.
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        4. How Long We Store Data
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          We will only store your information as long as necessary to fulfil the purposes
          for which the information is collected and processed or — where the applicable
          law provides for a longer storage and retention period — for the storage and
          retention period required by law. After that your Personal Data will be deleted,
          blocked or anonymized, as provided by applicable law.
          <Grid item xs={12} className="mt--16">
            <Typography color="title">In particular:</Typography>
          </Grid>
          If you terminate your Tap Project User Account, your Personal Data will be
          marked for deletion except to the degree legal requirements or other prevailing
          legitimate purposes dictate a longer storage term.
          <br />
          In certain cases Personal Data cannot be completely deleted in order to ensure
          the consistency of the gameplay experience or the Tap Project Community Market.
          For instance, matches you have played that affect other players&#145;
          matchmaking data and scores will not be deleted; rather, your connection to
          these matches will be permanently anonymized.
          <br />
          Please note that The Tap Project - &quot;Truly Simplistic Innovations&quot; is
          required to retain certain transactional data under statutory commercial and tax
          law for a period of up to ten (10) years.
          <br />
          If you withdraw your consent to the processing of your Personal Data or of the
          Personal Data of your child is based, we will delete your Personal Data or the
          Personal Data of your child without undue delay to the extent that the
          collection and processing of the Personal Data was based on the withdrawn
          consent.
          <br />
          If you exercise a right to object to the processing of your Personal Data, we
          will review your objection and delete your Personal Data that we processed for
          the purpose to which you objected without undue delay, unless another legal
          basis for processing and retaining this data exists or unless applicable law
          requires us to retain the data.
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        5. Who Has Access to Data
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          5.1 The Tap Project - &quot;Truly Simplistic Innovations&quot; and its
          subsidiaries may share your Personal Data with each other and use it to the
          degree necessary to achieve the purposes listed in section 2.2 above. In the
          event of a reorganization, sale or merger we may transfer Personal Data to the
          relevant third party subject to applicable laws.
          <br />
          <br />
          5.2 We may also share your Personal Data with our third party providers that
          provide customer support services in connection with goods, Content and Services
          distributed via Tap Project. Your Personal Data will be used in accordance with
          this Privacy Policy and only as far as this is necessary for performing customer
          support services.
          <br />
          <br />
          5.3 In accordance with internet standards, we may also share certain information
          (including your IP address and the identification of Tap Project content you
          wish to access) with our third party network providers that provide content
          delivery network services and game server services in connection with Tap
          Project . Our content delivery network providers enable the delivery of digital
          content you have requested, e.g. when using Tap Project , by using a system of
          distributed servers that deliver the content to you, based on your geographic
          location.
          <br />
          <br />
          5.4 We make certain data related to your Tap Project User Account available to
          other players and our partners through the Tap Project API’s. This information
          can be accessed by anyone by querying your User ID or username. At a minimum,
          the public persona name you have chosen to represent you on Tap Project and your
          Avatar picture are accessible this way, as well as whether you have received a
          ban for cheating in a multiplayer game. The accessibility of any additional info
          about you can be controlled through your Tap Project Community user profile
          page. Data publicly available on your profile page can be accessed automatically
          through the Tap Project API. In addition to the publicly available information,
          game developers and publishers have access to certain information from the Tap
          Project API directly relating to the users of the games they operate. This
          information includes as a minimum your ownership of the game in question.
          Depending on which Tap Project services are implemented in the game it may also
          include leaderboard information, your progress in the game, achievements you
          have completed, your multiplayer game matchmaking information, in-game items and
          other information needed to operate the game and provide support for it. While
          we do not knowingly share Personally Identifying Information about you through
          the Tap Project API such as your real name or your email address, any
          information you share about yourself on your public Tap Project Profile can be
          accessed through the Tap Project API, including information that may make you
          identifiable.
          <br />
          <br />
          5.5 The Tap Project community includes message boards, forums and/or chat areas,
          where users can exchange ideas and communicate with each other. When posting a
          message to a board, forum or chat area, please be aware that the information is
          being made publicly available online; therefore, you are doing so at your own
          risk. If your Personal Data is posted on one of our community forums against
          your will, please use the reporting function and the Tap Project help site to
          request its removal.
          <br />
          <br />
          5.6 The Tap Project - &quot;Truly Simplistic Innovations&quot; may allow you to
          link your Tap Project User Account to an account offered by a third party. If
          you consent to link the accounts, The Tap Project - &quot;Truly Simplistic
          Innovations&quot; may collect and combine information you allowed The Tap
          Project - &quot;Truly Simplistic Innovations&quot; to receive from a third party
          with information of your Tap Project User Account to the degree allowed by your
          consent at the time. If the linking of the accounts const module =
          require(&#145;module&#145;);uires the transmission of information about your
          person from The Tap Project - &quot;Truly Simplistic Innovations&quot; to a
          third party, you will be informed about it before the linking takes place and
          you will be given the opportunity to consent to the linking and the transmission
          of your information. The third party&#145;s use of your information will be
          subject to the third party&#145;s privacy policy, which we encourage you to
          review.
          <br />
          <br />
          5.7 The Tap Project - &quot;Truly Simplistic Innovations&quot; may release
          Personal Data to comply with court orders or laws and regulations that require
          us to disclose such information.
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        6. Your Rights and Control Mechanisms
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          The data protection laws of British Columbia and other territories grant their
          citizens certain rights in relation to their Personal Data. While other
          jurisdictions may provide fewer rights to their citizens, we make the tools
          designed to exercise such rights available to our customers worldwide. To allow
          you to exercise your data protection rights in a simple way we are providing a
          dedicated section on the Tap Platform Settings. This gives you access to your
          Personal Data, allows you to rectify and delete it where necessary and to object
          to its use where you feel necessary. To access it, log into the Tap Platform at
          and choose the menu items Settings.
          <br />
          <br />
          As a resident of British Columbia or the European Union, you have the following
          rights in relation to your Personal Data:
          <br />
          <br />
          6.1 Right of Access.
          <br />
          You have the right to access the Personal Data that we hold about you, including
          (i) information regarding whether your Personal Data is retained, (ii) access to
          and/or (iii) duplicates of the Personal Data retained. You can use the right to
          access your Personal Data through the Tap Platform. If the request affects the
          rights and freedoms of others or is manifestly unfounded or excessive, we
          reserve the right to charge a reasonable fee (taking into account the
          administrative costs of providing the information or communication or taking the
          action requested) or refuse to act on the request.
          <br />
          <br />
          6.2 Right to Rectification.
          <br />
          If we process your Personal Data, we shall endeavor to ensure by implementing
          suitable measures that your Personal Data is accurate and up-to-date for the
          purposes for which it was collected. If your Personal Data is inaccurate or
          incomplete, you can change the information you provided via the Tap Platform.
          <br />
          <br />
          6.3. Right to Erasure.
          <br />
          You have the right to obtain deletion of Personal Data concerning you if the
          reason why we collect it (see section 2. above) does not exist anymore or if
          there is another legal ground for its deletion. For individual items of Personal
          Data please edit them through the Tap Platform or request the deletion via the
          Tap Project support page. You can also request the deletion of your Tap Project
          user account via the Tap Project support page.
          <br />
          <br />
          As a result of deleting your Tap Project User Account, you will lose access to
          Tap Project services, including the Tap Project User Account, Subscriptions and
          game-related information linked to the Tap Project User Account and the
          possibility to access other services you are using the Tap Project User Account
          for.
          <br />
          <br />
          We allow you to restore your Tap Project User Account during a grace period of
          30 (thirty) days from the time you request deletion of your Tap Project User
          Account. This functionality prevents you from losing your account by mistake, by
          losing your account credentials or due to hacking. During the suspension period,
          we will be able to finalize financial and other activities that you may have
          initiated before sending the Tap Project User Account deletion request. After
          the grace period, Personal Data associated with your account will be deleted
          subject to section 4. above.
          <br />
          <br />
          In some cases, deletion of your Tap Project User Account, and therefore Personal
          Data deletion, is complicated. Namely, if your account has a business
          relationship with The Tap Project, such as due to your work for a game
          developer, you will only be able to delete your Tap Project User Account after
          you have transferred this role to another user or have dissolved the business
          relationship. In some cases, considering the complexity and number of the
          requests, the period for Personal Data erasure may be extended, but for no
          longer than two further months.
          <br />
          <br />
          6.4 Right to Object.
          <br />
          When our processing of your Personal Data is not based on legitimate interests
          according to the BCPA, PIPA, or GDPR you have the right to object to this
          processing. If you object we will no longer process your Personal Data unless
          there are compelling and prevailing legitimate grounds for the processing as
          described in the BCPA, PIPA, or GDPR; in particular if the data is necessary for
          the establishment, exercise or defense of legal claims. You also have the right
          to lodge a complaint at a supervisory authority.
          <br />
          6.5 Right to Data Portability
          <br />
          In certain circumstances, you can ask that we transmit the Personal Data we hold
          about you to another data controller.
          <br />
          6.6 Right to withdraw consent
          <br />
          You have the right to withdraw at any time your consent to our processing your
          Personal Data. This includes opting out of marketing messages you receive from
          us.
          <br />
          Should you wish to exercise any of the rights listed above, we may request you
          to provide us with additional documents to verify your identity.
          <br />
          You also have the right to lodge a complaint at with a locala supervisory
          authority. if you believe that we have not complied with applicable data
          protection laws.
        </Typography>
      </Grid>
    </Grid>

    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        7. Transfer of Personal Data
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          The Tap Project is global. Your Personal Data may be transferred outside of the
          country from which it was originally collected and may be shared within The Tap
          Project group, and also with third parties located in other jurisdictions,
          including those that do not have similar data protection laws. All international
          transfers of Personal Data will be made in accordance with applicable data
          protection laws.
          <br />
          When we transfer Personal Data internationally, we put in place safeguards in
          accordance with applicable law – including Articles 44 to 50 pf the EU General
          Data Protection Regulation. If you would like to know more about our data
          transfer practices and what measures we take to safeguard your Personal Data,
          please contact our privacy team at: contact@tapproject.net or
          team@tapproject.net.
        </Typography>
      </Grid>
    </Grid>

    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        8. Security of your Personal Data
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          We have policies and procedures in place to prevent the loss, unauthorized
          access, modification or disclosure of your Personal Data.
          <br />
          No method of transmitting or storing information is completely secure. While we
          take appropriate measures to protect your Personal Data, there is always a risk
          that it may be lost, intercepted or misused. The Tap Project makes no warranty
          with respect to the integrity of any electric communications between us and you.
        </Typography>
      </Grid>
    </Grid>

    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        9. Disclosure without consent
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          PIPA allows The Tap Project - &quot;Truly Simplistic Innovations&quot; to
          disclose information without consent in certain situations;
          <br />
          <br />
          When a treaty requires or allows for disclosure without consent and the treaty
          is made under an Act or Regulation of British Columbia or Canada (section 18(1)
          (h)). • When the disclosure is necessary to comply with a subpoena, warrant or
          order by a court or other agency with jurisdiction to compel the production of
          personal information (section 18(1)(i)). For example, an organization may
          disclose personal information without consent when a court order is served on
          the organization. • When the disclosure is to a public body or a law enforcement
          agency in Canada to assist an investigation of an offence under the laws of
          Canada or a province of Canada (section 18(1)(j)). For example, disclosing
          personal information to WorkSafe BC to carry out an investigation of a workplace
          accident. • When the information is disclosed to respond to an emergency that
          threatens the health or safety of an individual or the public and if notice of
          the disclosure is mailed to the last known address of the individual to whom the
          personal information relates (section 18(1)(k)). For example, if an individual
          makes a serious threat against another person, the information may be disclosed
          to prevent the person from being injured, as long as you notify the individual
          about the disclosure. • When disclosure is needed to contact next of kin or a
          friend of an injured, ill or deceased individual (section 18(1)(l)). • When the
          disclosure is to a lawyer representing your organization (section 18(1) (m)). •
          When the disclosure is to an archival institution if the collection of the
          personal information is reasonable for research or archival purposes (section
          18(1)(n)).
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        10. Contact Info
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          You can contact The Tap Project&#145;s data protection officer at the address
          below.
          <br />
          While we review any request sent by mail, please be aware that to combat fraud,
          harassment and identity theft, the only way to access, rectify or delete your
          data is through logging in with your Tap Project User Account and selecting the
          menu items &#8212;&#062; Settings
          <br />
          <br />
          The Tap Project
          <br />
          Att. Data Protection officer
          <br />
          250 - 997 Seymour St.
          <br />
          Vancouver, BC, Canada
          <br />
          V6B 3M1
          <br />
          <br />
          For ease you may additionally submit to contact@tapproject.net or
          team@tapproject.net
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        11. Additional Information for Users from British Columbia
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          The Tap Project - &quot;Truly Simplistic Innovations&quot; complies with the
          BCPA and PIPA. If there is any conflict between the terms in this privacy policy
          and the PIPA, PIPA shall govern. Please visit https://www.oipc.bc.ca/ In
          compliance with the guidelines, The Tap Project - &quot;Truly Simplistic
          Innovations&quot; commits to resolve complaints about our collection or use of
          your personal information.
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} className="mt--16">
      <Typography color="inherit" variant="body1">
        10. Right to refuse request
      </Typography>
      <Grid item xs={12} className="mt--16">
        <Typography color="inherit">
          The Tap Project must refuse access to an individual’s personal information in
          the following circumstances: • The disclosure could reasonably be expected to
          threaten the safety or physical or mental health of another individual (section
          23(4)(a)). • The disclosure could reasonably be expected to cause immediate or
          serious harm to the safety or to the physical or mental health of the individual
          who made the request (section 23(4)(b)). • The disclosure would reveal personal
          information about another individual (section 23(4)(c)). • The disclosure would
          reveal the identity of the person who provided you with the applicant’s personal
          information, and that person does not consent to the disclosure of his or her
          identity (section 23(4)(d)). For example, an applicant might be able to
          determine a person’s identity based on his or her handwriting, special knowledge
          or presence at an incident involving the applicant.
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

export const experienceTasks = [
  { title: "Adding Vaults", info: "Add another game to your vault", value: 50 },
  {
    title: "Importing Currency",
    info: " Import from the platform to in-game points",
    value: 50,
  },
  {
    title: "Exporting Currency",
    info: "Export to platform from in-game points",
    value: 20,
  },
  {
    title: "Converting to TTT",
    info: "Convert from platform to TTT",
    value: 300,
  },
  {
    title: "Converting from TTT",
    info: "Convert from TTT to platform",
    value: 300,
  },
];
