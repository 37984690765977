import React from "react";
import { Grid, Dialog } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import { AutoGrid } from "components/Exchange/styles";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";
import GasSettings from "./GasSettings";
import SlippageSettings from "./SlippageSettings";

const ExchangeSettingsModal = ({ open, closeHandler }) => {
  const modal = FormStyled();

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>Global Settings</DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid className="stakecard--bottomtextalign" item xs={12}>
            <div className="cardcontent-wrapper">
              <AutoGrid>
                <GasSettings />
                <SlippageSettings />
              </AutoGrid>
            </div>
          </Grid>
        </Grid>
      </ConvertModalContainer>
    </Dialog>
  );
};

export default ExchangeSettingsModal;
