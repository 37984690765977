import nav from "dux/nav";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { CREDENTIAL_CLEAR } from "../actions/types";
import loading from "../dux/loading";
import challenges from "./challenges";
import etc from "./etc";
import games from "./games";
import router from "./history";
import publisher from "./publisher";
import AllowanceReducer from "./redux/Web3/Allowance/AllowanceReducer";
import CurrencyReducer from "./redux/Currency/CurrencyReducer";
import BalanceReducer from "./redux/Web3/Balances/BalanceReducer";
import AlertReducer from "./redux/Alert/AlertReducer";
import DashboardReducer from "./redux/Dashboard/DashboardReducer";
import LoginGamesListReducer from "./redux/LoginGamesList/LoginGamesListReducer";
import MenuReducer from "./redux/Menu/MenuReducer";
import ModalReducer from "./redux/Modal/ModalReducer";
import NFTModalReducer from "./redux/NFTModal/ModalReducer";
import PlayerCurrencyReducer from "./redux/Profile/PlayerCurrency/PlayerCurrencyReducer";
import RedirectReducer from "./redux/Redirect/RedirectReducer";
import SignupReducer from "./redux/Signup/SignupReducer";
import StakingReducer from "./redux/Staking/StakingReducer";
import ExchangeReducer from "./redux/Exchange/ExchangeReducer";
import ExchangeDataReducer from "./redux/Exchange/ExchangeDataReducer";
import snackbar from "./snackbar";
import user from "./user";
import vaults from "./vaults";
import web3 from "./web3";
import nft from "./NFTs";
import paymentContracts from "./paymentContracts";

const reducers = (history) =>
  combineReducers({
    Menu: MenuReducer,
    Dashboard: DashboardReducer,
    Modal: ModalReducer,
    NFTModal: NFTModalReducer,
    PlayerCurrency: PlayerCurrencyReducer,
    Signup: SignupReducer,
    LoginGamesList: LoginGamesListReducer,
    Redirect: RedirectReducer,
    Alert: AlertReducer,
    Staking: StakingReducer,
    Exchange: ExchangeReducer,
    ExchangeData: ExchangeDataReducer,
    form: formReducer,
    games,
    router: router(history),
    user,
    vaults,
    web3,
    currency: CurrencyReducer,
    allowances: AllowanceReducer,
    balances: BalanceReducer,
    publisher,
    snackbar,
    loading,
    etc,
    challenges,
    nav,
    nft,
    paymentContracts,
  });

const rootReducer = (state, action) => {
  if (action && action.type === CREDENTIAL_CLEAR) {
    const { routing } = state;
    state = { routing };
  }
  return reducers(state, action);
};

export default rootReducer;
