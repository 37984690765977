import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Text from "./Text";

const WalletActionButtons = (props) => (
  <Grid
    container
    className="wallet__balance__buttons"
    spacing={3}
    justifyContent="space-between"
  >
    <Grid item xs={4}>
      <Button
        className="pa--16 jc--sb"
        fullWidth
        component={Link}
        to="https://changelly.com/buy?from=usd&to=eth&amount=25&ref_id=upx2grzoet5pjfnf"
        target="_blank"
      >
        <Text className="mt--0" variant="h6">
          Buy ETH
        </Text>
        <img
          src="https://img.tapplatform.io/stat/icons/ethcoin.svg"
          alt="Buy"
          width={24}
          height={24}
        />
      </Button>
    </Grid>
    <Grid item xs={4}>
      <Button
        className="pa--16 jc--sb"
        fullWidth
        component={Link}
        to="https://app.uniswap.org/#/swap"
        target="_blank"
      >
        <Text className="mt--0" variant="h6">
          Trade TTT
        </Text>
        <img
          src="https://img.tapplatform.io/stat/icons/exchange.svg"
          alt="Trade"
          width={24}
          height={24}
        />
      </Button>
    </Grid>
  </Grid>
);

export default WalletActionButtons;
