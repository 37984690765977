import styled from "styled-components";
import Rating from "@material-ui/lab/Rating";

export const RateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    bottom: -24px;
    left: 0;
    height: 20px;
    width: 100%;
    color: #f44336;
  }
`;

export const RateLabel = styled.label`
  font-family: "Inter", sans-serif;
  line-height: 16px;
  font-weight: 500;
  color: #808191;
  margin-bottom: 16px;
`;

export const RateStars = styled(Rating)`
  font-size: 30px !important;

  & > * {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
