export const BACKUP_FONTS = ["Arial", "sans-serif"];

const fonts = {
  INTER: ["inter", ...BACKUP_FONTS].join(","),
  POPPINS: ["Poppins", ...BACKUP_FONTS].join(","),
  WORK_SANS: ["Work Sans", ...BACKUP_FONTS].join(","),
  PRESS_START: ["Press Start", ...BACKUP_FONTS].join(","),
};

export default fonts;
