import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Grid, Typography, Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { updateUser, clearFormErrors, getEthBalance } from "actions/index";
import * as Form from "../../../../utils/form";
import NewWalletMyEther from "./NewWalletMyEther";
import NewWalletMetamask from "./NewWalletMetamask";
import Wallet from "../../../../assets/icons/lmg/Wallet.svg";
import Ethereum from "../../../../assets/icons/lmg/etherium.svg";
import EthCoin from "../../../../assets/icons/ethcoin.svg";

const minEthRequired = 0.04;

const validate = (values) => {
  const errors = {};
  const requiredFields = ["eth_wallet_address"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  if (values.eth_wallet_address && values.eth_wallet_address.length !== 42) {
    errors.eth_wallet_address =
      "Invalid wallet address length. ensure your address starts with 0x and is 42 characters long";
  }
  return errors;
};

class WalletQuestion extends Component {
  constructor(props) {
    super(props);
    this.getStepContent = this.getStepContent.bind(this);
  }

  componentWillMount() {
    this.setState({
      keyButtonDisabled: false,
    });
    this.props.getEthBalance(this.props.user.eth_wallet_address);
  }

  componentWillReceiveProps() {
    this.setState({
      keyButtonDisabled: false,
    });
    if (this.props.user.formError) {
      this.props.clearFormErrors();
    }
  }

  getStepContent(step) {
    switch (step) {
      case "info":
        return this.getZerothStepContent();
      case "warning":
        return this.getFirstStepContent();
      case "metamask":
      case "key":
        return this.getSecondStepContent();
      case "add":
        if (this.props.user.eth_wallet_address) {
          return this.getFourthStepContent();
        }
        return this.getThirdStepContent();
      case "geteth":
        return this.getFourthStepContent();
      default:
        return "Invalid URL";
    }
  }

  getZerothStepContent() {
    return (
      <Grid container className="pub-eth-onboarding">
        <Grid item xs={12}>
          <Typography color="inherit" variant="display2" className="bold">
            You need An Ethereum Wallet
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--24">
          <Typography color="inherit" className="font-size-16">
            We support all modern ETH wallets which support ERC-20 tokens. Using a
            currency exchange wallet may result in loss of your ERC-20 tokens. Please
            ensure that you hold your own private keys to the wallet, if not using
            Metamask. As we don’t store it.
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--48">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                component={Link}
                to="/pub/onboarding/wallet/metamask"
                className="btn-orange padding-16"
              >
                Use Metamask (Recommended)
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className="mt--48">
            <Button
              fullWidth
              className="padding-16"
              component={Link}
              to="/pub/onboarding/wallet/key"
              variant="outlined"
            >
              Use Private Key
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} className="mt--48">
            <Button
              fullWidth
              className="padding-16"
              component={Link}
              to="/pub/onboarding/wallet/warning"
              variant="outlined"
            >
              Use Private Key
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getFirstStepContent() {
    return (
      <Grid container className="pub-eth-onboarding">
        <Grid item xs={12}>
          <Typography color="inherit" variant="display2" className="bold">
            Don’t Lose Your Ethereum Wallet Private Key.
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--24">
          <Typography color="inherit" className="font-size-18 semi-bold text__red">
            Tap does not store or have access to wallet and private key! You should
            therefore not lose it!
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--24">
          <Typography color="inherit" className="font-size-16">
            Your private key is different from your Tap account password. As Blockchain is
            decentralized, meaning your in full control of your own assets. We therefore
            have not authority to recover your private key or lost funds.
          </Typography>
          <Typography color="inherit" className="mt--16 font-size-16">
            We are a client-side interface that allows you to interact with the Ethereum
            blockchain wallet, and Tap wallet which is connected to your ETH wallet
            through a smart contract. Which enables you to hold, move and trade in-game
            currencies and items.
          </Typography>
          <Grid item xs={12} className="mt--48">
            <Button
              fullWidth
              className="padding-16"
              component={Link}
              color="primary"
              to="/pub/onboarding/wallet/metamask"
              variant="contained"
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getSecondStepContent() {
    return (
      <Grid container className="create-wallet pub-eth-onboarding">
        <Grid item xs={12}>
          <Tabs value={this.props.location.pathname} indicatorColor="primary">
            <Tab
              label="Password Wallet"
              value="/pub/onboarding/wallet/metamask"
              component={Link}
              to="/pub/onboarding/wallet/metamask"
            >
              <NewWalletMetamask />
            </Tab>
            <Tab
              label="Key File"
              value="/pub/onboarding/wallet/key"
              component={Link}
              to="/pub/onboarding/wallet/key"
            >
              <NewWalletMyEther />
            </Tab>
          </Tabs>
        </Grid>
        {this.props.location.pathname === "/pub/onboarding/wallet/metamask" && (
          <NewWalletMetamask />
        )}
        {this.props.location.pathname === "/pub/onboarding/wallet/key" && (
          <NewWalletMyEther />
        )}
      </Grid>
    );
  }

  getThirdStepContent() {
    return (
      <Grid container className="create-wallet pub-eth-onboarding">
        <Grid item container justifyContent="center" xs={12}>
          <Wallet width={124} height={124} />
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Typography color="inherit" variant="display1" className="bold">
            ETH wallet address
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--32">
          <Typography color="inherit">
            Add your wallet address in the field below if you don’t use Metamask. You need
            to hold your private key, which you will use to sign transfers, as Tap does
            not hold your key.
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Field
            name="eth_wallet_address"
            component={Form.renderTextField}
            label="Wallet Address"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="mt--80">
          <Button
            variant="contained"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.user.formError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((data) => {
              this.props.updateUser(data, "/pub/onboarding/wallet/geteth");
            })}
          >
            Confirm
          </Button>
          <Typography className="text__red">{this.props.user.formError}</Typography>
        </Grid>
      </Grid>
    );
  }

  getFourthStepContent() {
    return (
      <Grid container className="create-wallet">
        <Grid item container justifyContent="center" xs={12}>
          <Ethereum width={124} height={124} />
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Typography color="inherit" variant="display1">
            Ethereum
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--16">
          <Typography>
            You can send ETH to your wallet, or buy with a credit card. You will need a
            minimum of {minEthRequired} ETH to make a wallet.
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justifyContent="space-between"
          className="mt--64"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subheading">Ethereum</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="headline">
                  {this.props.web3.ethBalance || "0"}
                </Typography>
              </Grid>
              <Grid item>
                <EthCoin width={48} height={48} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Typography variant="caption" color="inherit">
            Your ETH address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {this.props.user.eth_wallet_address || "ETH Wallet not registered with Tap"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              component={Link}
              target="_blank"
              to="https://old.changelly.com/exchange/USD/ETH/1?ref_id=upx2grzoet5pjfnf"
              className="mt--64"
            >
              Buy ETH With Credit Card
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!this.props.web3.ethBalance ||
            this.props.web3.ethBalance < minEthRequired ? (
              <Button
                variant="outlined"
                fullWidth
                className="mt--24"
                onClick={() => this.handleRefresh()}
              >
                Click To Check ETH Balance
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                className="mt--24"
                component={Link}
                to="/wallet"
              >
                Done
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth component={Link} to="/wallet" className="mt--24">
            Skip for now
          </Button>
        </Grid>
      </Grid>
    );
  }

  handleRefresh() {
    this.props.getEthBalance(this.props.user.eth_wallet_address);
  }

  render() {
    return this.getStepContent(this.props.match.params.step);
  }
}

const mapDispatchToProps = {
  updateUser,
  clearFormErrors,
  getEthBalance,
};

const mapStateToProps = (state) => ({
  user: state.user,
  web3: state.web3,
  thisForm: state.form.addWallet || {},
});

export default compose(
  reduxForm({ form: "addWallet", validate }),
  connect(mapStateToProps, mapDispatchToProps)
)(WalletQuestion);
