import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import Text from "components/Text";
import {
  fetchVaults,
  fetchGames,
  getBalanceOf,
  getEthBalance,
  getGasPrice,
  fetchGameVault,
} from "actions/index";
import { TOKEN_INFO } from "blockchain/tokenInfo";
import { challengeGameId } from "assets/info";
// import WalletIcon from 'assets/icons/wallet.svg';
import SimpleGameCard from "components/Cards/SimpleGameCard";
import ChallengesCard from "components/Cards/ChallengesCard";
import WalletActionButtons from "components/WalletActionButtons";
import BalanceCard from "components/Cards/BalanceCard";
import EtheriumCard from "components/Cards/EtheriumCard";
import WalletCard from "components/Cards/WalletCard";
import CardHeader from "components/CardHeader";
import LockerCard from "components/Cards/LockerCard";
import LevelCard from "components/Cards/LevelCard";
import { roundEtherBalance } from "blockchain/web3Utils";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getBalanceOf(
      TOKEN_INFO.GameXChange.addresses[this.props.web3.chainId],
      this.props.user.eth_wallet_address
    );
    this.props.getEthBalance(this.props.user.eth_wallet_address);
    this.props.getGasPrice();
    this.props.fetchVaults();
    this.props.fetchGames();
    this.props.fetchGameVault(challengeGameId);
  }

  renderVaults() {
    return this.props.vaults
      .slice()
      .sort((a, b) => {
        if (a.cooldown_endtime > b.cooldown_endtime) {
          return 1;
        }
        return -1;
      })
      .slice(0, 4)
      .map((vault) => {
        if (vault.game_id === challengeGameId) {
          return "";
        }
        return (
          <Grid key={vault.id} item xs={12} sm={6}>
            <SimpleGameCard
              classes={{
                media: "t--rounded",
                content: "short light b--rounded",
              }}
              game={this.props.games[vault.game_id] || {}}
              vault={vault}
            />
          </Grid>
        );
      });
  }

  /**
    -prepare to add rewards
   */

  render() {
    return (
      <Grid container spacing={3} className="wallet mt--8">
        <Grid item md={6} xs={12}>
          <BalanceCard balance={this.props.web3.balance} />
        </Grid>
        <Grid item md={6} xs={12}>
          <LevelCard
            level={this.props.user.levelInfo.level}
            current={this.props.user.score - this.props.user.levelInfo.required_score}
            total={this.props.user.levelInfo.score_to_next_level}
            class={this.props.user.levelInfo.class_name}
            badge={this.props.user.profile_banner_url}
          />
        </Grid>
        <Grid item xs={12} md={6} className="wallet__content">
          <Grid container>
            <WalletActionButtons />
            <Grid item xs={12} className="wallet__balance mt--24 border-default">
              <CardHeader text="Ethereum" />
              <EtheriumCard
                gasPrice={this.props.web3.gasPrice}
                ethBalance={this.props.web3.ethBalance}
              />
            </Grid>
            {/*
            <Grid
              item
              xs={12}
              className="wallet__latest-transactions  mt--24"
            >
              <CardHeader text="Recent" />
              <LatestTransactions
                transactions={this.props.web3.recentTransactions}
                userAddress={this.props.user.eth_wallet_address}
              />
            </Grid>
            */}
            <Grid item xs={12} className="mt--24 border-default">
              <CardHeader
                text={
                  this.props.challenge_vault
                    ? `Rewards ${this.props.challenge_vault.attributes.earned_currency} TTT`
                    : "Rewards 0 TTT"
                }
              />
              <ChallengesCard
                vault={this.props.challenge_vault}
                recentAchievements={this.props.recentAchievements}
              />
            </Grid>
            <Grid item xs={12} className="mt--24 border-default">
              <CardHeader
                text="Wallet details"
                actionButton={
                  !this.props.user.eth_wallet_address && (
                    <Button
                      variant="contained"
                      color="secondary"
                      component={Link}
                      to="/onboarding/wallet/add"
                    >
                      Get Eth Wallet
                    </Button>
                  )
                }
              />

              {this.props.user.eth_wallet_address && (
                <WalletCard
                  eth={this.props.user.eth_wallet_address}
                  tap={this.props.user.wallet_address}
                  amount={roundEtherBalance(this.props.web3.balance)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className="wallet__vaults">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              {this.props.vaults && (
                <CardHeader
                  text="Vaults"
                  className="border-default"
                  actionButton={
                    this.props.vaults.length > 4 ? (
                      <Text variant="h6" component={Link} to="/allvaults">
                        See all
                      </Text>
                    ) : (
                      ""
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
          {this.props.games.ids !== [] && (
            <Grid container spacing={3} className="wallet__vaults__content">
              {this.renderVaults()}
            </Grid>
          )}
          {this.props.user.eth_wallet_address && (
            <Grid item xs={12} className="mt--24 border-default">
              <CardHeader text="Locker" className=" MuiGrid-container-37" />
              <LockerCard />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  fetchVaults,
  fetchGames,
  getBalanceOf,
  getEthBalance,
  getGasPrice,
  fetchGameVault,
};

const mapStateToProps = (state) => {
  state.games.ids.map((id) => state.games.byId[id]);

  const recentAchievements = [];
  state.challenges.seasonalGames.ids.forEach((gameId) => {
    if (state.challenges.seasonalGames.byId[gameId].achievements[0]) {
      const tempAchievement = state.challenges.seasonalGames.byId[gameId].achievements[0];
      tempAchievement.gameName = state.challenges.seasonalGames.byId[gameId].title;
      recentAchievements.push(tempAchievement);
    }
  });

  return {
    user: state.user,
    games: state.games.byId,
    vaults: state.vaults.ids.map((id) => state.vaults.byId[id]),
    challenge_vault: state.vaults.currentVault,
    recentAchievements,
    web3: state.web3,
  };
};

Wallet.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
