import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_PLAYER_CURRENCY_GOLD_COUNT,
  SET_PLAYER_CURRENCY_TTT_COUNT,
} from "reducers/redux/Profile/PlayerCurrency/actions";
import { fetchChallengeGameVault } from "actions";
import { TOKEN_INFO } from "blockchain/tokenInfo";
import { getDisplayVal } from "blockchain";
import { getBalanceOf } from "actions/web3";
import ListCurrency from "../items/ListCurrency";
import { CurrencyFlexGrid } from "../styles";
import ProfilePaper from "../ProfilePaper";
import { Grid } from "@material-ui/core";

const Content = React.memo(() => {
  const PlayerCurrency = useSelector((state) => state.PlayerCurrency);
  const playerCurrencyList = Object.values(PlayerCurrency);
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);
  const tttBalance = useSelector(
    (state) => state.balances[TOKEN_INFO.GameXChange?.addresses[web3.chainId]]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.isLoggedIn) return;

    // grab the gold info from user and set it
    //dispatch({ type: SET_PLAYER_CURRENCY_GOLD_COUNT, data: user.gold });
    dispatch({
      type: SET_PLAYER_CURRENCY_TTT_COUNT,
      data: getDisplayVal(tttBalance || "0", 18),
    });

    if (web3.connected && !web3.badNetwork) {
      // fetch GXP balance
      dispatch(
        getBalanceOf(
          TOKEN_INFO.GameXChange.addresses[web3.chainId],
          user.eth_wallet_address
        )
      );
    }

    // fetch challenge game vault for steam GXP
    dispatch(fetchChallengeGameVault());
  }, [user, web3.connected, web3.badNetwork, tttBalance, web3.chainId, dispatch]);

  return (
    <ProfilePaper title="Currencies" expanding>
      <Grid container justifyContent="flex-start" spacing={4}>
        <ListCurrency list={playerCurrencyList} />
      </Grid>
    </ProfilePaper>
  );
});

export default Content;
