import React from "react";
import { SignInWrapper } from "./styles";
import GamesList from "./GamesList";
import FormContainer from "./Form/FormContainer";
import Form from "./Form";

export default () => {
  return (
    <SignInWrapper>
      <GamesList />
      <FormContainer name="Sign in">
        <Form />
      </FormContainer>
    </SignInWrapper>
  );
};
