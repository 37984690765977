import invariant from "tiny-invariant";

import { formatToChain } from "blockchain/tokenInfo";
import { ETHER } from "./currency";
import Token from "./token";
import Price from "./price";

export default class ExchangeRoute {
  constructor(pairs, input, output) {
    invariant(pairs.length > 0, "PAIRS");
    invariant(
      pairs.every((pair) => pair.chainId === pairs[0].chainId),
      "CHAIN_IDS"
    );
    invariant(
      (input instanceof Token && pairs[0].involvesToken(input)) ||
        (input === ETHER &&
          pairs[0].involvesToken(formatToChain("WETH", pairs[0].chainId))),
      "INPUT"
    );
    invariant(
      typeof output === "undefined" ||
        (output instanceof Token && pairs[pairs.length - 1].involvesToken(output)) ||
        (output === ETHER &&
          pairs[pairs.length - 1].involvesToken(formatToChain("WETH", pairs[0].chainId))),
      "OUTPUT"
    );

    const path = [
      input instanceof Token ? input : formatToChain("WETH", pairs[0].chainId),
    ];
    for (const [i, pair] of Array.from(pairs.entries())) {
      const currentInput = path[i];
      invariant(
        currentInput.equals(pair.token0) || currentInput.equals(pair.token1),
        "PATH"
      );
      const _output = currentInput.equals(pair.token0) ? pair.token1 : pair.token0;
      path.push(_output);
    }

    this.pairs = pairs;
    this.path = path;
    this.midPrice = Price.fromRoute(this);
    this.input = input;
    this.output = output ?? path[path.length - 1];
  }

  chainId() {
    return this.pairs[0].chainId;
  }
}
