import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

import { getNFTs, getFeaturedNFTs, getSponsoredNFT } from "actions/NFTs";
import { isCompletedTx } from "blockchain";
import { loadedPartialScreen, loadingPartialScreen } from "dux/loading";
import appError from "utils/appError";
import { buyFromMarket } from "utils/web3Calls";
import SponsoredNFT from "./SponsoredNFT";
import FeaturedNFT from "./FeaturedNFT";

const Content = React.memo(
  ({ allowanceHandler, setSuccessModal, connectHandler, getHandler }) => {
    const dispatch = useDispatch();
    const web3 = useSelector((state) => state.web3);
    const featuredNfts = useSelector((state) => state.nft.featuredNfts);
    const sponsoredNft = useSelector((state) => state.nft.sponsoredNft);
    const paymentContracts = useSelector((state) => state.paymentContracts);

    const featuredNFTAllowanceHandler = (id) => {
      const nft = featuredNfts.find(({ saleId: nftId }) => nftId === id);
      const { blockchainId, paymentContract, marketContract } = nft;

      allowanceHandler(blockchainId, paymentContract, marketContract);
    };

    const featuredNFTMintingHandler = useCallback(
      (id) => {
        dispatch(loadingPartialScreen("nftButtons--is-minting-nft"));
        const nft = featuredNfts.find(({ saleId: nftId }) => nftId === id);
        const { blockchainId } = nft.blockchainId;
        buyFromMarket(nft.saleId, 0, web3.connected)
          .then((txn) => {
            const timer = setInterval(() => {
              isCompletedTx(blockchainId, txn).then(() => {
                clearInterval(timer);
                dispatch(getFeaturedNFTs(web3)).then(() => {
                  dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
                  setSuccessModal(true);
                });
              });
            }, 1000);
          })
          .catch((err) => {
            appError("The following error occured in the NFT purchase handler", err);
            dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
          });
      },
      [dispatch, featuredNfts, web3, setSuccessModal]
    );

    const sponsoredNFTAllowanceHandler = () => {
      const nft = sponsoredNft;

      const { blockchainId, paymentContract, marketContract } = nft;

      allowanceHandler(blockchainId, paymentContract, marketContract);
    };

    const sponsoredNFTMintingHandler = useCallback(
      (id) => {
        dispatch(loadingPartialScreen("nftButtons--is-minting-nft"));
        const nft = sponsoredNft;
        const { blockchainId } = nft.blockchainId;
        buyFromMarket(nft.saleId, 0, web3.connected)
          .then((txn) => {
            const timer = setInterval(() => {
              isCompletedTx(blockchainId, txn).then(() => {
                clearInterval(timer);
                dispatch(getSponsoredNFT(web3)).then(() => {
                  dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
                  setSuccessModal(true);
                });
              });
            }, 1000);
          })
          .catch((err) => {
            appError("The following error occured in the NFT purchase handler", err);
            dispatch(loadedPartialScreen("nftButtons--is-minting-nft"));
          });
      },
      [dispatch, sponsoredNft, web3, setSuccessModal]
    );

    return (
      (featuredNfts.length > 0 || !!sponsoredNft) && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">Featured NFTs {`${"\ud83d\udd25"}`}</Typography>
            </Grid>
            <Grid item container xl={8} lg={6} md={12}>
              <SponsoredNFT
                sponsoredData={{
                  ...sponsoredNft,
                  paymentContracts,
                  allowanceHandler: () =>
                    sponsoredNFTAllowanceHandler(sponsoredNft.saleId),
                  mintingHandler: () => sponsoredNFTMintingHandler(sponsoredNft.saleId),
                  getHandler: (payment, price) => getHandler(payment, price),
                  connectHandler,
                }}
              />
            </Grid>
            <Grid item container xl={4} lg={6} md={12}>
              <Grid item direction="column" justifyContent="space-between" xs={12}>
                {featuredNfts.map((featured) => (
                  <FeaturedNFT
                    featuredData={{
                      ...featured,
                      paymentContracts,
                      allowanceHandler: () =>
                        featuredNFTAllowanceHandler(featured.saleId),
                      mintingHandler: () => featuredNFTMintingHandler(featured.saleId),
                      getHandler: (payment, price) => getHandler(payment, price),
                      connectHandler,
                    }}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    );
  }
);

export default Content;
