import React, { useState } from "react";
import { Grid, DialogActions, Dialog, Button } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Text from "components/Text";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import { useSelector } from "react-redux";
import { TOKEN_INFO, upgradeToken, Payments } from "blockchain/tokenInfo";
import { getDisplayVal } from "blockchain/web3Utils";
import { Link } from "react-router-dom";
import { upgradeVault } from "actions/index";
import LoadingButton from "components/LoadingButton";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { UpgradeVaultModalContainer } from "./styles";
import ApprovalModalContainer from "../ApprovalModal";

const UpgradeVaultModal = ({
  title,
  open,
  closeHandler,
  successHandler,
  upgradeCost,
  hasBalance,
  approvalSuccess,
  hasAllowance,
  gameId,
  // ...props
}) => {
  const web3 = useSelector((state) => state.web3);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const modal = FormStyled();
  const classes = useStyles();

  const tryUpgrade = async () => {
    setLoading(true);
    await upgradeVault(gameId, web3.chainId, user.eth_wallet_address);
    successHandler();
    setLoading(false);
    closeHandler();
  };
  const cost = getDisplayVal(upgradeCost, upgradeToken.decimals);

  if (!hasBalance) {
    return (
      <Dialog
        open={!!open}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: modal.root }}
      >
        <ButtonClose onClick={closeHandler}>
          <Close />
        </ButtonClose>

        <DialogTitle>{title}</DialogTitle>

        <UpgradeVaultModalContainer>
          <Grid container>
            <Grid className="stakecard--bottomtextalign" item xs={12}>
              <div className="cardcontent-wrapper">
                <Text variant="h4" className="pb--16">
                  Acquire {upgradeToken.symbol} tokens, which you can spend to upgrade
                  your vault
                </Text>
                <Text variant="h5" className="pb--32">
                  Upgrading your vault requires {cost} {upgradeToken.symbol} tokens.
                </Text>
              </div>
            </Grid>
          </Grid>
        </UpgradeVaultModalContainer>

        <DialogActions>
          <ButtonClose onClick={closeHandler} />
        </DialogActions>
      </Dialog>
    );
  }
  if (hasBalance && !hasAllowance) {
    return (
      <ApprovalModalContainer
        open={!!open}
        closeHandler={closeHandler}
        successHandler={approvalSuccess}
        token={{
          address: upgradeToken.addresses[web3.chainId],
          chainId: web3.chainId,
          ...upgradeToken,
        }}
        to={Payments[web3.chainId]}
      />
    );
  }
  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>{title}</DialogTitle>

      <UpgradeVaultModalContainer>
        <Grid container>
          <Grid className="stakecard--bottomtextalign" item xs={12}>
            <div className="cardcontent-wrapper">
              <Text variant="p2" className="pb--16">
                {`Upgrade your vault for ${getDisplayVal(
                  upgradeCost,
                  upgradeToken.decimals
                )} ${upgradeToken.symbol}`}
              </Text>
              <LoadingButton
                className={classes.button}
                size="large"
                color="primary"
                variant="contained"
                onClick={tryUpgrade}
                disabled={loading}
                loading={loading}
              >
                Spend {cost}
                {upgradeToken.symbol}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </UpgradeVaultModalContainer>

      <DialogActions>
        <ButtonClose onClick={closeHandler} />
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeVaultModal;
