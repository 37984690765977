import { Multicall } from "blockchain/tokenInfo";
import { getWeb3, getContractInstance } from "utils/web3Calls";

export const performMultiCall = async (functionName, iface, addresses, callData) => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  const multicall = await getContractInstance("Multicall", Multicall[chainId]);

  const calls = [];
  addresses.forEach((address) => {
    calls.push([address, callData]);
  });

  const results = await multicall.aggregate.call(calls);

  return results.returnData.map((result) => {
    if (result != "0x") {
      return iface.decodeFunctionResult(functionName, result);
    }
  });
};
