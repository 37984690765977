import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid, DialogActions, Dialog } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Loader from "components/loaders";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import {
  FlexRow,
  LiquidityDescription,
  AutoGrid,
  BigCurrencyText,
  Descriptions,
  Description,
  CurrencyText,
} from "components/Exchange/styles";
/*

import {
  FlexRow,
  LiquidityDescription,
  CurrencyText,
  AutoGrid,
  BigCurrencyText,
} from "components/Exchange/styles";
*/
import { getTransactionReceiptMined } from "utils/web3Calls";
import { exchangeTokens } from "utils/web3/exchange";

import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { ConvertModalContainer } from "./styles";

const ExchangeModalContainer = ({
  trade,
  from,
  to,
  open,
  closeHandler,
  successHandler,
}) => {
  const modal = FormStyled();
  const classes = useStyles();
  const [success, onSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tradeInfo, setTradeInfo] = useState(false);
  const [priceData, setPriceData] = useState({});
  const exchangeSettings = useSelector((state) => state.Exchange);

  const handler = async () => {
    setLoading(true);
    try {
      const hash = await exchangeTokens(
        trade,
        from,
        to,
        exchangeSettings.userSlippageTolerance
      );
      const receipt = await getTransactionReceiptMined(hash);
      onSuccess(receipt);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (trade) {
      const input = parseFloat(trade.inputAmount.toSignificant());
      const output = parseFloat(trade.outputAmount.toSignificant());
      const exchangeRate = input / output;
      setTradeInfo({ input, output, exchangeRate });
    }
  }, [trade]);

  useEffect(() => {
    if (success) {
      // dispatch(getAllowanceOf(token.address, Routers[token.chainId]));
      successHandler();
      setLoading(false);
    }
  }, [success]);

  const renderButton = () => {
    return (
      <Button
        className={classes.button}
        size="large"
        color="primary"
        variant="contained"
        onClick={() => handler()}
        disabled={loading || success}
      >
        {success ? "Success!" : `Trade ${from.symbol} For ${to.symbol}`}
        {loading && <Loader />}
      </Button>
    );
  };

  useEffect(() => {
    if (tradeInfo) {
      setPriceData({
        Price: `1 ${from.symbol} = ${(1 / tradeInfo.exchangeRate).toFixed(10)} ${
          to.symbol
        }`,
        "": `1 ${to.symbol} = ${tradeInfo.exchangeRate.toFixed(10)} ${from.symbol}`,
      });
    }
  }, [tradeInfo]);

  const descriptions = (data) => {
    const res = [];

    Object.keys(data).forEach((key) => {
      res.push(
        <Description key={key}>
          <CurrencyText>{key}</CurrencyText>
          <LiquidityDescription>{data[key]}</LiquidityDescription>
        </Description>
      );
    });

    return res;
  };

  if (!tradeInfo) {
    return "";
  }

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>Swap</DialogTitle>

      <ConvertModalContainer>
        <Grid container>
          <Grid item xs={12}>
            <AutoGrid>
              <Grid container>
                <BigCurrencyText>{trade?.symbol}</BigCurrencyText>
                <FlexRow>
                  {/*<BigCurrencyImage src={GXPBottle} alt="currency" />*/}
                </FlexRow>
              </Grid>
              <LiquidityDescription>
                Trade {tradeInfo.input} {from.symbol} for {tradeInfo.output} {to.symbol}
              </LiquidityDescription>
            </AutoGrid>
            <Descriptions>{descriptions(priceData)}</Descriptions>
          </Grid>
        </Grid>
      </ConvertModalContainer>

      <DialogActions>{renderButton()}</DialogActions>
    </Dialog>
  );
};

export default ExchangeModalContainer;
