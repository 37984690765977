import PageHeader from "components/pageComponents/PageHeader";
import React from "react";
import { useSelector } from "react-redux";
import { ContentWrapper } from "../Marketplace/styles";
import SeasonalChallenges from "./list/SeasonalChallenges/SeasonalChallenges";
import GameXChangeConnectedGames from "./list/GameXChangeConnectedGames/GameXChangeConnectedGames";

const Content = () => {
  const tapcoinConnectedGames = useSelector(({ games }) => games.byId);
  const seasonalChallenges = useSelector(
    ({ challenges }) => challenges.seasonalGames.byId
  );

  return (
    <ContentWrapper>
      <PageHeader h1="Games" subtitle="" />

      <GameXChangeConnectedGames list={tapcoinConnectedGames} link="/game" />

      <SeasonalChallenges list={seasonalChallenges} />
    </ContentWrapper>
  );
};

export default Content;
