import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

export const nonMuiTextVariants = {
  h1: {
    fontSize: "5.429rem", // 76px
    fontWeight: "300",
    lineHeight: "1.32",
  },
  h2: {
    fontSize: "3.429rem", // 48px
    fontWeight: "900",
    lineHeight: "1.31",
  },
  h3: {
    fontSize: "2.571rem", // 36px
    fontWeight: "900",
    lineHeight: "1.33",
  },
  h4: {
    fontSize: "1.714rem", // 24px
    fontWeight: "bold",
    lineHeight: "1.33",
  },
  h4sub: {
    fontSize: "1.714rem", // 24px
    fontWeight: "300",
    lineHeight: "1.38",
  },
  h5: {
    fontSize: "1.286rem", // 18px
    fontWeight: "300",
    lineHeight: "1.39",
  },
  h5sub: {
    fontSize: "1.286rem", // 18px
    fontWeight: "600",
    lineHeight: "1.39",
  },
  h6: {
    fontSize: "1.286rem", // 18px
    fontWeight: "bold",
    lineHeight: "1.33",
  },
  s1: {
    fontSize: "1.143rem", // 16px
    fontWeight: "bold",
    lineHeight: "1.31",
  },
  s2: {
    fontSize: "1rem", // 14px
    fontWeight: "bold",
    lineHeight: "1.36",
  },
  p1: {
    fontSize: "1.143rem", // 16px
    fontWeight: "400",
    lineHeight: "1.31",
  },
  p2: {
    fontSize: "1rem", // 14px
    fontWeight: "400",
    lineHeight: "1.36",
  },
  button: {
    fontSize: "1rem", // 14px
    fontWeight: "500",
    lineHeight: "1.14",
    letterSpacing: "0.4px",
  },
  caption: {
    fontSize: "0.8571rem", // 12px
    fontWeight: "700",
    lineHeight: "1.33",
  },
  Overline: {
    fontSize: "0.7143rem", // 10px
    fontWeight: "bold",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
  },
  code: {
    fontFamily: "PTMono",
    fontSize: "1rem", // 14px
    fontWeight: "400",
    lineHeight: "1.36",
  },
  n3: {
    fontSize: "2.571rem", // 36px
    fontWeight: "900",
    lineHeight: "1.33",
  },
  n4: {
    fontSize: "1.714rem", // 24px
    fontWeight: "900",
    lineHeight: "1.33",
  },
  n6: {
    fontSize: "1.286rem", // 18px
    fontWeight: "900",
    lineHeight: "1.33",
  },
  n7: {
    fontSize: "1.143rem", // 16px
    fontWeight: "900",
    lineHeight: "1.31",
  },
  n8: {
    fontSize: "0.7143rem", // 10px
    fontWeight: "900",
    lineHeight: "1.3",
  },
  //
  // utility props
  //
  zeroMargin: {
    margin: "0",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    height: "100%",
    width: "100%",
  },
};

const styles = () => ({
  // style new team variants
  root: {},
  ...nonMuiTextVariants,
});

const Text = ({
  children,
  classes,
  className,
  variant,
  zeroMargin,
  ellipsis,
  secondary,
  inline,
  error,
  ...props
}) => {
  let muiVariant;
  let muiClassName = className;

  // custom variants are based off the keys provided to styles function
  const customVariants = [...Object.keys(nonMuiTextVariants)];
  // delete unsupported classes
  const muiClasses = Object.keys(classes).reduce((col, nxt) => {
    if (!customVariants.includes(nxt)) {
      return {
        ...col,
        [nxt]: classes[nxt],
      };
    }
    return col;
  }, {});

  // add as to classname instead to receive the syles
  if (customVariants.includes(variant)) {
    muiClassName = `${classes[variant]} ${className}`;
  } else {
    // pass the variant intended for the Mui Typography
    muiVariant = variant;
  }
  // add class zeroMargin if flag was passed
  if (zeroMargin) {
    muiClassName = `${classes.zeroMargin} ${muiClassName}`;
  }
  // add class ellipsis if flag was passed
  if (ellipsis) {
    muiClassName = `${classes.ellipsis} ${muiClassName}`;
  }
  // add grey text class if secondary flag was passed
  if (secondary) {
    muiClassName = `tc--leSecondary ${muiClassName}`;
  }
  if (inline) {
    muiClassName = `display-inline ${muiClassName}`;
  }

  if (error) {
    muiClassName = `tc--redBase ${muiClassName}`;
  }
  return (
    <Typography
      variant={muiVariant}
      className={muiClassName}
      classes={muiClasses}
      {...props}
    >
      {children}
    </Typography>
  );
};

Text.propTypes = {
  zeroMargin: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.shape().isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
};
Text.defaultProps = {
  zeroMargin: false,
  className: "",
  variant: "body2",
  children: null,
};

export default withStyles(styles)(Text);
