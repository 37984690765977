import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { FlexRow, FlexColumn } from "./styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: "2vw",
  },
  center: {
    justifyContent: "center",
  },
  image: {
    borderRadius: "25px",
    width: "36px",
    height: "36px",
  },
  imageWrapper: {
    justifyContent: "center",
    marginRight: "10px",
  },
  value: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "14px",
  },
  description: {
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontSize: "12px",
    color: "slategrey",
  },
}));

const IconDoubleText = React.memo(({ image, description, value }) => {
  const classes = useStyles();
  return (
    <FlexRow className={classes.container}>
      <FlexColumn className={classes.imageWrapper}>
        <img src={image} className={classes.image} />
      </FlexColumn>
      <FlexColumn className={classes.center}>
        <Typography className={classes.value}>{value ?? "-"}</Typography>
        <Typography className={classes.description}>{description ?? "-"}</Typography>
      </FlexColumn>
    </FlexRow>
  );
});

export default IconDoubleText;
