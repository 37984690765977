import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

const renderImage = (transaction) => {
  let src = "https://img.tapplatform.io/stat/icons/conversion/";
  if (transaction.completed) {
    src += "converting-completed.svg";
  } else {
    src += "converting-start.svg";
  }
  return <img src={src} alt="status" width="30" />;
};

const renderStatus = (transaction, game, handleConversion) => {
  if (transaction.completed) {
    return <Typography variant="body1">Converting completed</Typography>;
  }
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">Pending</Typography>
      </Grid>
      <Grid item xs={6} align="right">
        <Button onClick={() => handleConversion(transaction)}>
          Claim{" "}
          {transaction.current_transaction_hash_status == "1"
            ? game.earned_currency_name
            : game.premium_currency_name}
        </Button>
      </Grid>
    </>
  );
};

const renderValue = (tx, game) => {
  const { virtual_currency, to_amount } = tx;
  const { earned_currency_name, premium_currency_name } = game;

  return `${to_amount} ${
    virtual_currency == "1" ? earned_currency_name : premium_currency_name
  }`;
};

const renderTransactions = (transactions, game, conversionRate, handleConversion) =>
  transactions.map((transaction) => (
    <Grid item xs={12} key={`${transaction.type}-${transaction.id}`}>
      <Paper className="padding-16 border-default" square>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={1}>
                {renderImage(transaction)}
              </Grid>
              <Grid item container alignItems="center" xs={8}>
                {renderStatus(transaction, game, handleConversion)}
              </Grid>
              <Grid item xs={3} align="right">
                <Typography variant="body1">{renderValue(transaction, game)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ));

const VaultTransactions = ({ transactions, game, conversionRate, handleConversion }) => (
  <Grid container className="latest-transactions">
    {renderTransactions(transactions, game, conversionRate, handleConversion)}
  </Grid>
);

export default VaultTransactions;
