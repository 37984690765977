import styled from "styled-components";
import Text from "components/Text";

export const CurrencyText = styled(Text)`
  display: inline;
  whitespace: normal;
`;

export const CurrencyImage = styled.img`
  vertical-align: top;
  height: 22px;
`;
