import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageHeader from "components/pageComponents/PageHeader";
import { ContentName, ContentWrapper } from "components/Marketplace/styles";
import { Routers } from "blockchain/tokenInfo";
import { FlexGrid, ExpButton } from "components/ContentElements/styles";
import { connect as web3Connect, getAllowanceOf, getBalanceOf } from "actions";
import { getSinglePairByAddresses } from "utils/web3/exchange";

import ApprovalModalContainer from "components/Modal/ApprovalModal";
import Remove from "./Remove";
import LiquidityModalContainer from "../../Modal/LiquidityModal";
//import ExchangeSettingsModalContainer from "../Modal/ExchangeSettingsModal";
//import LiquidityModalContainer from "../Modal/LiquidityModal";

const Content = () => {
  const [success, setSuccess] = useState(false);
  const params = useParams();

  const [pair, setPair] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [openModalExchangeSettings, setOpenModalExchangeSettings] = useState(null);
  const [openModalLiquidity, setOpenModalLiquidity] = useState(null);
  const [openModalApproval, setOpenModalApproval] = useState(null);
  const [message, setMessage] = useState(false);
  const web3 = useSelector((state) => state.web3);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getSinglePairByAddresses(params.from, params.to).then((res) => {
      setPair(res);
    });
  }, [success]);

  useEffect(() => {
    if (pair.liquidityToken) {
      dispatch(getAllowanceOf(pair.liquidityToken.address, Routers[web3.chainId]));
      dispatch(getBalanceOf(pair.liquidityToken.address));
    }
  }, [pair]);

  const rejectHandler = (mode) => {
    setOpenModalExchangeSettings(null);
    setOpenModalLiquidity(null);
    setOpenModalApproval(null);
  };

  const successHandler = () => {
    setSuccess(true);
  };
  const settingsHandler = () => {
    setOpenModalExchangeSettings(true);
  };

  const liquidityHandler = (options) => {
    setOpenModalLiquidity(options);
  };

  const approveHandler = (token) => {
    setOpenModalApproval({ token });
  };

  const connectHandler = () => {
    dispatch(web3Connect());
  };

  return (
    <ContentWrapper>
      <PageHeader h1="Game X Change" subtitle="Build your gaming wealth" />
      <Grid container justifyContent="center">
        <Grid item container justifyContent="center">
          <FlexGrid>
            <ExpButton
              variant="contained"
              color="primary"
              onClick={() => history.push("/xchange")}
            >
              Swap
            </ExpButton>
            <ExpButton variant="contained" color="primary" disabled>
              Liquidity
            </ExpButton>
          </FlexGrid>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-start" spacing={4} alignItems="flex-start">
        <Grid container spacing={3}>
          <Grid item container xs={12} justifyContent="center">
            {pair && (
              <Remove
                pair={pair}
                connected={web3.connected}
                badNetwork={web3.badNetwork}
                connectHandler={connectHandler}
                settingsHandler={settingsHandler}
                liquidityHandler={liquidityHandler}
                approveHandler={approveHandler}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!!openModalLiquidity && (
        <LiquidityModalContainer
          open={!!openModalLiquidity}
          message={message}
          rejectHandler={rejectHandler}
          web3={web3}
          {...openModalLiquidity}
        />
      )}
      {!!openModalApproval && (
        <ApprovalModalContainer
          open={!!openModalApproval}
          message={message}
          closeHandler={rejectHandler}
          successHandler={successHandler}
          rejectHandler={rejectHandler}
          web3={web3}
          {...openModalApproval}
        />
      )}
    </ContentWrapper>
  );
};

export default Content;
