import invariant from "tiny-invariant";
import JSBI from "jsbi";
import _Big from "big.js";
import toFormat from "toformat";
import { ETHER } from "./currency";
import { currencyEquals } from "./token";

import { Rounding } from "../../constants";
import { parseBigintIsh, TEN, SolidityType, validateSolidityTypeInstance } from "../math";
import Fraction from "./fraction";

const Big = toFormat(_Big);

export default class CurrencyAmount extends Fraction {
  /**
   * Helper that calls the constructor with the ETHER currency
   * @param amount ether amount in wei
   */
  static ether(amount) {
    return new CurrencyAmount(ETHER, amount);
  }

  // amount _must_ be raw, i.e. in the native representation
  constructor(currency, amount) {
    const parsedAmount = parseBigintIsh(amount);
    validateSolidityTypeInstance(parsedAmount, SolidityType.uint256);

    super(parsedAmount, JSBI.exponentiate(TEN, JSBI.BigInt(currency.decimals)));
    this.currency = currency;
  }

  get raw() {
    return this.numerator;
  }

  add(other) {
    invariant(currencyEquals(this.currency, other.currency), "TOKEN");
    return new CurrencyAmount(this.currency, JSBI.add(this.raw, other.raw));
  }

  sub(other) {
    invariant(currencyEquals(this.currency, other.currency), "TOKEN");
    return new CurrencyAmount(this.currency, JSBI.subtract(this.raw, other.raw));
  }

  div(other, decimals = 5) {
    if (other.raw) {
      return (
        JSBI.toNumber(
          JSBI.divide(
            JSBI.multiply(this.raw, JSBI.exponentiate(TEN, JSBI.BigInt(decimals))),
            other.raw
          )
        ) / Math.pow(10, decimals)
      );
    }

    return (
      JSBI.toNumber(
        JSBI.divide(
          JSBI.multiply(this.raw, JSBI.exponentiate(TEN, JSBI.BigInt(decimals))),
          JSBI.BigInt(other)
        )
      ) / Math.pow(10, decimals)
    );
  }

  divToBI(other, decimals = 5) {
    if (other.raw) {
      return new CurrencyAmount(
        this.currency,
        JSBI.divide(
          JSBI.divide(
            JSBI.multiply(this.raw, JSBI.exponentiate(TEN, JSBI.BigInt(decimals))),
            other.raw
          ),
          JSBI.exponentiate(TEN, JSBI.BigInt(decimals))
        )
      );
    }

    return new CurrencyAmount(
      this.currency,
      JSBI.divide(
        JSBI.divide(
          JSBI.multiply(this.raw, JSBI.exponentiate(TEN, JSBI.BigInt(decimals))),
          JSBI.BigInt(other)
        ),
        JSBI.exponentiate(TEN, JSBI.BigInt(decimals))
      )
    );
  }

  mul(other) {
    if (other instanceof JSBI) {
      return new CurrencyAmount(this.currency, JSBI.multiply(this.raw, other.raw));
    }
    return new CurrencyAmount(this.currency, JSBI.multiply(this.raw, JSBI.BigInt(other)));
  }

  toSignificant(significantDigits = 6, format, rounding = Rounding.ROUND_DOWN) {
    return super.toSignificant(significantDigits, format, rounding);
  }

  toFixed(
    decimalPlaces = this.currency.decimals,
    format,
    rounding = Rounding.ROUND_DOWN
  ) {
    invariant(decimalPlaces <= this.currency.decimals, "DECIMALS");
    return super.toFixed(decimalPlaces, format, rounding);
  }

  toExact(format = { groupSeparator: "" }) {
    Big.DP = this.currency.decimals;
    return new Big(this.numerator.toString())
      .div(this.denominator.toString())
      .toFormat(format);
  }
}
