/* eslint-disable jsx-a11y/label-has-for */
import React, { Component, Fragment } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import LoadingSpinner from "components/LoadingSpinner";
import {
  renderTextField,
  renderSelectField,
  renderChipMultiSelect,
  FieldFileInput,
  MultiFieldFileInput,
} from "utils/form";
// import AddGameCard from '../../AddGameCard';
import * as Info from "assets/info";
import {
  createGame,
  updateGame,
  onboardingUpdateGame,
  onboardingSimpleUpdateGame,
  createSDKPassword,
  onboardingUpdateCurrency,
  fetchGame,
  fetchOrganization,
  createGameVault,
} from "actions/index";
import Text from "components/Text";
import { history } from "../../../store/index";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "game_title",
    "release_year",
    "game_engine",
    "tsrs_age_rating",
    "feature",
    "short_description",
    "game_card_background_image",
    "full_description",
    "earned_currency_name",
    "premium_currency_name",
    "image",
    "category",
    "game_secret_key",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

class AddGame extends Component {
  constructor(props) {
    super(props);
    this.getStepContent = this.getStepContent.bind(this);
    if (props.location.search) {
      const parsed = qs.parse(props.location.search);
      this.props.fetchGame(parsed.id);
    }
    this.props.fetchOrganization();
  }

  componentWillMount() {
    this.setState({
      keyButtonDisabled: false,
      refresh: true,
    });
  }

  componentWillReceiveProps() {
    if (
      this.props.location.search &&
      !this.props.games.currentGame &&
      this.state.refresh
    ) {
      this.setState({
        refresh: false,
      });
      const parsed = qs.parse(this.props.location.search);
      this.props.fetchGame(parsed.id);
    }
  }

  getStepContent(step) {
    switch (step) {
      case "add":
        return this.getZerothStepContent();
      case "basic":
        return this.getFirstStepContent();
      case "descriptive":
        if (!this.props.thisForm.values) {
          history.push("/pub/addGame/basic");
        }
        return this.getDescriptiveContent();
      case "rating":
        if (!this.props.thisForm.values) {
          history.push("/pub/addGame/basic");
        }
        return this.getRatingContent();
      case "card":
        if (!this.props.thisForm.values) {
          history.push("/pub/addGame/basic");
        }
        return this.getSecondStepContent();
      case "details":
        if (this.props.thisForm.values && this.props.thisForm.values.release_year) {
          this.props.reset();
        }
        return this.getThirdStepContent();
      case "added":
        return this.getThirdAndAHalfStepContent();
      case "economy":
        return this.getFourthStepContent();
      case "sdk":
        return this.getFifthStepContent();
      case "earned":
        return this.getSixthStepContent();
      case "premium":
        return this.getSeventhStepContent();
      case "vault":
        return this.getVaultInfoContent();
      case "done":
        return this.getEighthStepContent();
      default:
        return "Invalid URL";
    }
  }

  getZerothStepContent() {
    return (
      <Grid container className="add-game">
        <Typography variant="display2" color="inherit" className="bold">
          Add your first game for players to discover
        </Typography>
        <Typography className="mt--32">
          You can now add your game to the Tap platform to create awareness for your game.
        </Typography>
        <Grid item xs={12} className="mt--64">
          <Button
            variant="text"
            fullWidth
            disabled={!this.props.valid}
            component={Link}
            to="/pub/addGame/basic"
            color="primary"
          >
            Add Game
          </Button>
        </Grid>
        <Grid item xs={12} className="mt--8">
          <Button
            fullWidth
            disabled={!this.props.valid}
            component={Link}
            to="/pub/org/dashboard"
          >
            Skip For Now
          </Button>
        </Grid>
      </Grid>
    );
  }

  getFirstStepContent() {
    return (
      <Grid container className="add-game">
        <Text variant="h4">Base Info</Text>
        <Text variant="p1" color="textSecondary">
          First the some basic information about the game.
        </Text>
        <Grid item xs={12} className="mt--16">
          <Field name="title" component={renderTextField} label="Game title" fullWidth />
          <Field
            className="mt--32"
            name="release_year"
            component={renderTextField}
            label="Release year"
            type="number"
            fullWidth
          />
          <Field
            className="mt--32"
            name="game_engine"
            component={renderSelectField}
            label="Game engine"
            fullWidth
          >
            {Info.engines.map((engine) => (
              <MenuItem key={engine} value={engine}>
                {engine}
              </MenuItem>
            ))}
          </Field>
          <Field
            className="mt--32"
            name="platform"
            component={renderChipMultiSelect}
            label="Available on"
            fullWidth
            options={Info.platforms}
            isMulti
          />
          <Button
            className="mt--56"
            variant="text"
            color="primary"
            fullWidth
            disabled={!this.props.valid}
            component={Link}
            to="/pub/addGame/descriptive"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }

  getDescriptiveContent() {
    return (
      <Grid container className="add-game">
        <Text variant="h4">Descriptive info</Text>
        <Text variant="p1" color="textSecondary">
          Add some tags for users to find your game, and links where they can acquire it.
          Double check that the urls for your games are all valid
        </Text>
        <Grid item xs={12}>
          <Field
            className="mt--56"
            name="game_element"
            component={renderChipMultiSelect}
            label="Game Elements"
            fullWidth
            options={Info.gameElements}
            isMulti
          />
          <Field
            className="mt--56"
            name="category"
            component={renderChipMultiSelect}
            label="Genre Tags"
            fullWidth
            options={Info.categories}
            isMulti
          />
          {this.props.thisForm.values &&
            this.props.thisForm.values.platform &&
            this.props.thisForm.values.platform.map(
              (platform, index) =>
                platform.value !== null && (
                  <Grid item xs={12} className="mt--40">
                    <Field
                      label={`${platform.value} store url`}
                      name={`game_store_url[${index}]`}
                      component={renderTextField}
                      fullWidth
                    />
                  </Grid>
                )
            )}
          <Field
            className="mt--40"
            label="Game Website url (optional)"
            name="website_url"
            component={renderTextField}
            fullWidth
          />
          <Field
            multiline
            className="mt--40"
            label="Copyright description"
            placeholder="Write your text here"
            name="copyright_description"
            component={renderTextField}
            fullWidth
          />
          <Button
            className="mt--56"
            variant="text"
            color="primary"
            fullWidth
            disabled={!this.props.valid}
            component={Link}
            to="/pub/addGame/rating"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    );
  }

  getRatingContent() {
    return (
      <Grid container className="add-game">
        <Text variant="h4">Age rating</Text>
        <Text variant="p2" color="textSecondary">
          To allow user to filter out mature content, all games on the platform will be
          assigned an age rating based on{" "}
          <a href="https://www.esrb.org/ratings/ratings_guide.aspx">ESRB standards</a>. We
          are not associated with the ESRB, and your game does not need to be rated by
          them to be on the Tap Platform
        </Text>
        <Field
          name="tsrs_age_rating"
          label="Age Rating"
          component={renderSelectField}
          fullWidth
        >
          {Info.ageRating.map((rating) => (
            <MenuItem key={rating.value} value={rating.value}>
              {rating.label}
            </MenuItem>
          ))}
        </Field>
        <Grid item xs={12} className="mt--32">
          <Field
            name="tsrs_content_descriptor"
            component={renderChipMultiSelect}
            label="Content"
            fullWidth
            options={Info.ageContent}
            isMulti
          />
        </Grid>
        <Grid item xs={12} className="mt--32">
          <Field
            name="tsrs_interactive_element"
            component={renderChipMultiSelect}
            label="Interactive Elements"
            fullWidth
            options={Info.interactiveContent}
            isMulti
          />
        </Grid>
        <Button
          variant="text"
          color="primary"
          fullWidth
          disabled={!this.props.valid}
          component={Link}
          className="mt--64"
          to="/pub/addGame/card"
        >
          Next
        </Button>
      </Grid>
    );
  }

  getSecondStepContent() {
    return (
      <Grid container className="add-game">
        <Grid item xs={12}>
          <Card
            className="teaser-card__content__game-card border-default"
            style={{ width: "372px" }}
          >
            <Field
              name="feature"
              imgStyle={{
                width: "100%",
                height: "186px",
              }}
              nolabel
              text="Upload Thumbnail Image"
              src="https://img.tapplatform.io/stat/icons/add-image-lg.png"
              id="file-upload"
              component={FieldFileInput}
            >
              <CardContent className="dark" style={{ minHeight: "208px" }}>
                <Text variant="h6" className="mb--4 mt--32">
                  {this.props.form.addGame.values && this.props.form.addGame.values.title
                    ? this.props.form.addGame.values.title
                    : "Game Title"}
                </Text>
                <Text variant="p1" color="textSecondary">
                  {this.props.form.addGame.values &&
                  this.props.form.addGame.values.short_description
                    ? this.props.form.addGame.values.short_description
                    : "Game Decription"}
                </Text>
                <Text variant="p1" align="right">
                  {this.props.thisForm.values &&
                    this.props.thisForm.values.platform &&
                    this.props.thisForm.values.platform.map(
                      (platform) =>
                        platform.value !== null && (
                          <img
                            className="mr--8"
                            alt={platform.value}
                            src={`https://img.tapplatform.io/stat/icons/format/${platform.value}.svg`}
                          />
                        )
                    )}
                </Text>
              </CardContent>
            </Field>
          </Card>

          <label htmlFor="file-upload">
            <Button
              className="mt--64"
              component="span"
              fullWidth
              variant="text"
              color="primary"
            >
              Upload Thumbnail Image
            </Button>
          </label>
          <Typography align="center" variant="caption" className="mt--16 text__white">
            16:9 format and max 2mb
          </Typography>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Field
            label="Game teaser description"
            placeholder="Write your text here"
            name="short_description"
            component={renderTextField}
            fullWidth
            multiline
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="caption" color="inherit">
          0/300
          </Typography>
        </Grid> */}
        <Button
          className="mt--124"
          variant="text"
          color="primary"
          fullWidth
          disabled={this.state.keyButtonDisabled || !this.props.valid}
          onClick={this.props.handleSubmit((data) => {
            this.setState({
              keyButtonDisabled: true,
            });
            this.props
              .createGame(data)
              .then(() => {
                this.setState({
                  keyButtonDisabled: false,
                  refresh: true,
                });
              })
              .catch(() => {
                this.setState({
                  keyButtonDisabled: false,
                });
              });
          })}
        >
          {this.state.keyButtonDisabled ? <LoadingSpinner /> : "Next"}
        </Button>
        <Text className="text__red">{this.props.publisher.registerError}</Text>
      </Grid>
    );
  }

  getThirdStepContent() {
    return (
      <Grid container className="add-game details">
        <Grid item xs={12}>
          <Text variant="s1">Featured Image</Text>
          <Text variant="p2" color="textSecondary">
            Images will be stretched/shrunk to 16:9. This image will be used to feature
            your game on the marketplace.
          </Text>
          <Field
            style={{
              width: "100%",
              marginTop: "20px",
            }}
            imgStyle={{
              height: "auto",
              width: "100%",
              maxWidth: "768px",
              maxHeight: "436px",
              borderRadius: "4px",
            }}
            nolabel
            imglabel
            removeText
            name="game_card_background_image"
            src="https://img.tapplatform.io/stat/icons/add-image-lg.png"
            id="file-upload"
            component={FieldFileInput}
          />
          <Text variant="s1">Gameplay images</Text>
          <Text variant="p2" color="textSecondary">
            Images will be stretched/shrunk to 16:9, recommend having minium 5 images,
            max. 16 images.
          </Text>
          <Field
            style={{
              marginTop: "24px",
            }}
            imgStyle={{
              height: "90px",
              width: "160px",
            }}
            removeText
            name="game_images"
            src="https://img.tapplatform.io/stat/icons/add-image-lg.png"
            id="multifile-upload"
            component={MultiFieldFileInput}
          />
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "372px", margin: "auto" }}>
          <Text variant="p2" color="textSecondary" align="center" className="mt--8">
            16:9 format and max 2 mb and no wording on the image
          </Text>
        </Grid>
        <Grid item xs={12} className="mt--48">
          <Field
            label="Youtube video url"
            name="video_url"
            component={renderTextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="mt--80">
          <Text variant="s1">About the game</Text>
          <Text variant="p2" color="textSecondary">
            Users like reading about the narrative of the game. Your description can be
            short or long, we leave that up to you, but try to avoid too much fluff text.
          </Text>
        </Grid>
        <Grid item xs={12} className="mt--32">
          <Field
            label="Game description"
            name="full_description"
            placeholder="Write your text here"
            component={renderTextField}
            fullWidth
            multiline
          />
        </Grid>
        <Button
          className="mt--80"
          variant="text"
          color="primary"
          fullWidth
          disabled={this.state.keyButtonDisabled || !this.props.valid}
          onClick={this.props.handleSubmit((data) => {
            this.setState({
              keyButtonDisabled: true,
            });
            const newData = data;
            newData.id = this.props.games.currentGame.id;
            this.props
              .onboardingUpdateGame(newData)
              .then(() => {
                this.setState(
                  {
                    keyButtonDisabled: false,
                    refresh: true,
                  },
                  () => {
                    history.push(
                      `/pub/addGame/added?id=${this.props.games.currentGame.id}`
                    );
                  }
                );
              })
              .catch(() => {
                this.setState({
                  keyButtonDisabled: false,
                });
              });
          })}
        >
          {this.state.keyButtonDisabled ? <LoadingSpinner /> : "Next"}
        </Button>
        <Text className="text__red">{this.props.publisher.registerError}</Text>
      </Grid>
    );
  }

  getThirdAndAHalfStepContent() {
    return (
      <Grid container className="add-game">
        <Text variant="h4">Want to join the universal game currency network?</Text>
        <Text color="textSecondary" variant="p2" className="mt--8">
          Add more info about your in-game currencies to get your unique address, to use
          with the SDK to support Tap coin.
        </Text>
        {this.props.games.currentGame && (
          <Grid item xs={12} className="mt--32">
            <Button
              variant="text"
              color="primary"
              fullWidth
              disabled={!this.props.valid}
              component={Link}
              to={`/pub/addGame/economy?id=${this.props.games.currentGame.id}`}
            >
              Continue
            </Button>
          </Grid>
        )}{" "}
        <Grid item xs={12} className="mt--32 mb--32">
          <Divider />
        </Grid>
        <Text variant="s2">Add my game for players to discover</Text>
        <Text color="textSecondary" variant="p2">
          We will review the data you&apos;ve provided and will try and get back to you as
          soon as we can. Once your game has been reviewed, it will appear in the
          marketplace for users to see.
        </Text>
        <Grid item xs={12} className="mt--32">
          <Button
            variant="outlined"
            fullWidth
            disabled={this.state.keyButtonDisabled || !this.props.valid}
            onClick={this.props.handleSubmit(() => {
              this.props
                .updateGame({
                  id: this.props.games.currentGame.id,
                  status: "submitted",
                })
                .then(() => {
                  this.setState(
                    {
                      keyButtonDisabled: false,
                    },
                    () => {
                      history.push("/pub/org/dashboard");
                    }
                  );
                })
                .catch(() => {
                  this.setState({
                    keyButtonDisabled: false,
                  });
                });
            })}
          >
            Skip Tap Coin For Now
          </Button>
        </Grid>
      </Grid>
    );
  }

  getFourthStepContent() {
    return (
      <Grid container className="add-game">
        <Text variant="h4">Select your game economy type</Text>
        <Text color="textSecondary" variant="p2" className="mt--8">
          Which economic system does your game have? The SDK for each is slightly
          different.
        </Text>
        <Grid
          item
          xs={12}
          className="mt--32 add-game__economy-card"
          onClick={this.props.handleSubmit(() => {
            const data = {};
            data.game_economy_type = "standard";
            data.id = this.props.games.currentGame.id;
            this.props
              .simpleUpdateGame(data)
              .then(() => {
                history.push(`/pub/addGame/earned?id=${data.id}`);
              })
              .catch(() => {
                this.setState({
                  keyButtonDisabled: false,
                });
              });
          })}
        >
          <Text variant="s2">Standard</Text>
          <Text variant="p2" color="inherit" className="mt--16">
            Open economy, where game currency does not move between players in the game.
          </Text>
        </Grid>
        <Grid
          item
          xs={12}
          className=" mt--24 add-game__economy-card"
          onClick={this.props.handleSubmit(() => {
            const data = {};
            data.game_economy_type = "balanced";
            data.id = this.props.games.currentGame.id;
            this.props
              .simpleUpdateGame(data)
              .then(() => {
                history.push(`/pub/addGame/sdk?id=${data.id}`);
              })
              .catch(() => {
                this.setState({
                  keyButtonDisabled: false,
                });
              });
          })}
        >
          <Text variant="s2">Balanced</Text>
          <Text variant="p2" color="inherit" className="mt--16">
            Closed economies, where currency is shared in the game and can move between
            players.
          </Text>
        </Grid>
      </Grid>
    );
  }

  getFifthStepContent() {
    // eslint-disable-line
    return (
      <Grid container className="add-game">
        <Text variant="h4">SDK Password</Text>
        <Text variant="p2" color="textSecondary" className="mt--16">
          This a unique password used with the SDK integration for balanced games. So the
          game can talk with the Tap server.
        </Text>
        <Field
          className="mt--64"
          name="secret_key"
          type="password"
          component={renderTextField}
          label="Enter password"
          fullWidth
        />
        <Grid item xs={12} className="mt--64">
          <Button
            variant="text"
            color="primary"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((form) => {
              this.setState(
                {
                  keyButtonDisabled: false,
                },
                () => {
                  const data = {};
                  data.secret_key = form.secret_key;
                  data.to = `/pub/addGame/earned?id=${this.props.games.currentGame.id}`;
                  this.props.createSDKPassword(this.props.games.currentGame.id, data);
                }
              );
            })}
          >
            Next
          </Button>
          <Text className="text__red">{this.props.publisher.registerError}</Text>
        </Grid>
      </Grid>
    );
  }

  getSixthStepContent() {
    return (
      <Grid container className="add-game">
        <Grid item xs={12}>
          <Field
            name="image"
            imgStyle={{
              marginBottom: "64px",
              marginTop: "32px",
              width: "128px",
              height: "auto",
            }}
            style={{ width: "100%" }}
            src="https://img.tapplatform.io/stat/icons/add-image.svg"
            id="file-upload"
            component={FieldFileInput}
            nolabel
          />
          <Text variant="h4">Set your earned currency</Text>
          <Text variant="p2" color="textSecondary" className="mt--4">
            The name and image of your earned game currency like score or gold.
          </Text>
          <Field
            className="mt--32 mb--32"
            name="earned_currency_name"
            component={renderTextField}
            label="Currency name"
            fullWidth
          />
          <label htmlFor="file-upload">
            <Button component="span" fullWidth variant="text" color="primary">
              Upload Currency Icon
            </Button>
          </label>
          <Text variant="p2" align="center" color="textSecondary" className="mt--8">
            Square aspect ratio PNG Image of the currency. Max 2MB
          </Text>
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Button
            variant="text"
            color="primary"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((data) => {
              this.setState({
                keyButtonDisabled: true,
              });
              const newData = { image: data.image };
              newData.image_name = "earned_currency_image";
              newData.id = this.props.games.currentGame.id;
              newData.earned_currency_name = data.earned_currency_name;
              this.props
                .onboardingUpdateCurrency(newData)
                .then(() => {
                  this.setState(
                    {
                      keyButtonDisabled: false,
                    },
                    () => {
                      history.push(`/pub/addGame/premium?id=${newData.id}`);
                    }
                  );
                })
                .catch(() => {
                  this.setState({
                    keyButtonDisabled: false,
                  });
                });
            })}
          >
            Next currency
          </Button>
          <Typography className="text__red">
            {this.props.publisher.registerError}
          </Typography>
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              history.push(`/pub/addGame/premium?id=${this.props.games.currentGame.id}`);
            }}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    );
  }

  getSeventhStepContent() {
    return (
      <Grid container className="add-game">
        <Grid item xs={12}>
          <Field
            name="image2"
            imgStyle={{
              marginBottom: "64px",
              marginTop: "32px",
              width: "128px",
              height: "auto",
            }}
            style={{ width: "100%" }}
            src="https://img.tapplatform.io/stat/icons/add-image.svg"
            id="file-upload2"
            component={FieldFileInput}
            nolabel
          />
          <Text variant="h4">Set your premium currency</Text>
          <Text variant="p2" color="textSecondary" className="mt--4">
            The name and image of your premium game currency like score or gold. This is
            the currency sold in your in-game store and has a value measured in USD.
          </Text>
          <Field
            className="mt--32 mb--32"
            name="premium_currency_name"
            component={renderTextField}
            label="Currency name"
            fullWidth
          />
          <label htmlFor="file-upload2">
            <Button component="span" fullWidth variant="text" color="primary">
              Upload Currency Icon
            </Button>
          </label>
          <Text variant="p2" align="center" color="textSecondary" className="mt--8">
            Square aspect ratio PNG Image of the currency. Max 2MB
          </Text>
          {/* <Grid container className="mt--64" spacing={3}>
          <Grid item xs={6}>
            <Field
              name="premium_currency_quantity"
              component={renderTextField}
              label="A Quantity of"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="premium_value_usd"
              component={renderTextField}
              label="USD price is"
              fullWidth
            />
          </Grid>
        </Grid> */}
        </Grid>
        <Grid item xs={12} className="mt--64">
          <Button
            variant="text"
            color="primary"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((data) => {
              this.setState({
                keyButtonDisabled: true,
              });
              const newData = { image: data.image2 };
              newData.image_name = "premium_currency_image";
              newData.id = this.props.games.currentGame.id;
              newData.premium_currency_name = data.premium_currency_name;
              this.props
                .onboardingUpdateCurrency(newData)
                .then(() => {
                  this.setState(
                    {
                      keyButtonDisabled: false,
                      refresh: true,
                    },
                    () => {
                      history.push(`/pub/addGame/vault?id=${newData.id}`);
                    }
                  );
                })
                .catch(() => {
                  this.setState({
                    keyButtonDisabled: false,
                  });
                });
            })}
          >
            Next
          </Button>
          <Typography className="text__red">
            {this.props.publisher.registerError}
          </Typography>
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              this.setState({
                refresh: false,
              });
              history.push(`/pub/addGame/vault?id=${this.props.games.currentGame.id}`);
            }}
          >
            Skip
          </Button>
        </Grid>
      </Grid>
    );
  }

  getVaultInfoContent() {
    if (!this.props.games.currentGame) {
      return false;
    }
    if (!this.props.games.currentGame.game_economy_type) {
      const parsed = qs.parse(this.props.location.search);
      this.props.fetchGame(parsed.id);
    }
    return (
      <Grid container className="add-game vault-info">
        <Grid item xs={12}>
          <Text variant="h4">Game Vault settings</Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            You can limit how much currency a player can import or export from your game
            to prevent them from pouring all their currency into or out of the game.
          </Text>
          {this.props.games.currentGame.game_economy_type === "balanced" && (
            <>
              <Text variant="h5">Closed Economy settings</Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                To prevent an ingame economy from collapsing, you can set limits on how
                much currency users can import and export in total.
              </Text>
              <Text variant="h5" className="mt--32">
                Minimum values
              </Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                The minimum amount determines how much currency users can export from the
                game. If too many users have exported currency, you&apos;ll encounter
                problems with there being not enough currency to go around. As uses import
                currency the &quot;allowance&quot; will go back up allowing users to
                withdraw more. You&apos;ll likely want the minimums to be 0.
              </Text>
              <Field
                className="mt--16"
                type="number"
                name="earned_currency_min"
                component={renderTextField}
                label="minimum amount of earned exported"
                fullWidth
              />
              <Field
                className="mt--32"
                type="number"
                name="premium_currency_min"
                component={renderTextField}
                label="minimum amount of premium exported"
                fullWidth
              />
              <Text variant="h5" className="mt--32">
                Maximum values
              </Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                The maximum amount determines how much currency users can import into the
                game. If too many users have imported currency, you&apos;ll encounter
                problems with inflation. As users export currency, other users will be
                able to import more currency. You&apos;ll likely want this number to be
                lower than half the amount of currency in your economy, but by how much is
                up to you.
              </Text>
              <Field
                className="mt--16"
                type="number"
                name="earned_currency_max"
                component={renderTextField}
                label="maximum amount of earned imported"
                fullWidth
              />
              <Field
                className="mt--32"
                type="number"
                name="premium_currency_max"
                component={renderTextField}
                label="maximum amount of premium imported"
                fullWidth
              />
            </>
          )}
          <Text variant="h5" className="mt--32">
            Player daily import/export limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            How many times do you want your users to be able to import/export from your
            game each day.
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_user_import_count_max"
            component={renderTextField}
            label="number of currency imports per day"
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_user_export_count_max"
            component={renderTextField}
            label="number of currency exports per day"
            fullWidth
          />
          <Text variant="h5" className="mt--32">
            earned vault limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            Each user can import and export a certain amount per day, how much should your
            users be able to?
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_earned_currency_import_max"
            component={renderTextField}
            label="number of earned imports per day"
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_earned_currency_export_max"
            component={renderTextField}
            label="number of earned exports per day"
            fullWidth
          />
          <Text variant="h5" className="mt--32">
            premium vault limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            Each user can import and export a certain amount per day, how much should your
            users be able to?
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_premium_currency_import_max"
            component={renderTextField}
            label="number of premium imports per day"
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_premium_currency_export_max"
            component={renderTextField}
            label="number of premium exports per day"
            fullWidth
          />
          <Button
            className="mt--40"
            variant="text"
            color="primary"
            fullWidth
            disabled={
              (this.state.keyButtonDisabled && !this.props.publisher.registerError) ||
              !this.props.valid
            }
            onClick={this.props.handleSubmit((form) => {
              this.setState({
                keyButtonDisabled: true,
              });

              const data = JSON.parse(JSON.stringify(form));
              this.props
                .createGameVault(
                  this.props.games.currentGame.id,
                  data,
                  this.props.games.currentGame.game_economy_type
                )
                .then(() => {
                  this.props
                    .updateGame({
                      id: this.props.games.currentGame.id,
                      status: "submitted",
                    })
                    .then(() => {
                      history.push("/pub/addGame/done");
                    });
                });
            })}
          >
            Next
          </Button>
          <Typography className="text__red">
            {this.props.publisher.registerError}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  getEighthStepContent() {
    return (
      <Grid container className="add-game">
        {/* image */}
        <Text variant="h4">Your request has been sent.</Text>
        <Text variant="p2" color="textSecondary" className="mt--8">
          We will run through the data you&apos;ve provided and will try and get back to
          you as soon as we can. We will notify you by email when you&apos;ve been
          approved, or if we have questions about your game.
          <br />
          <br />
          The
          <a href="https://partner.tapproject.net"> SDK documentation</a> will guide you
          through adding GameXChange to your game. Feel free to contact us if you have any
          questions.
        </Text>
        <Grid item xs={12} className="mt--64">
          <Button
            variant="text"
            color="primary"
            fullWidth
            disabled={!this.props.valid}
            component={Link}
            to="/pub/org/dashboard"
          >
            Done
          </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    if (
      this.props.games &&
      this.props.games.currentGame &&
      this.props.user &&
      this.props.user.id !== this.props.games.currentGame.user_id
    ) {
      return {};
    }
    return this.getStepContent(this.props.match.params.step);
  }
}
const mapStateToProps = (state) => ({
  form: state.form,
  games: state.games,
  user: state.user,
  publisher: state.publisher,
  thisForm: state.form.addGame,
});

const mapDispatchToProps = {
  createGame,
  updateGame,
  onboardingUpdateGame,
  simpleUpdateGame: onboardingSimpleUpdateGame,
  createSDKPassword,
  onboardingUpdateCurrency,
  fetchGame,
  fetchOrganization,
  createGameVault,
};
export default compose(
  reduxForm({
    form: "addGame",
    forceUnregisterOnUnmount: true,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AddGame);
