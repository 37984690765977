import React from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import Vaults from "components/containers/Vaults";
import Wallet from "components/containers/Wallet";
import Experience from "components/containers/Experience";
import PubWallet from "components/containers/publisher/Wallet";
import PubSettings from "components/containers/publisher/settings/PubSettings";
import Settings from "components/containers/Settings";
import AddWallet from "components/containers/onboarding/AddWallet";
import Profile from "components/containers/Profile";

const EthRoutes = ({ match, isPub, ...props }) => {
  const WalletComponent = isPub ? PubWallet : Wallet;
  return (
    <Switch>
      <Route path="/usr/settings/:tab" component={isPub ? PubSettings : Settings} />
      <Route path="/usr/experience" component={Experience} />
      <Route path="/usr/wallet" component={WalletComponent} />
      <Route path="/usr/allvaults" component={Vaults} />
      <Route path="/usr/settings" component={isPub ? PubSettings : Settings} />
      <Route exact path="/usr/profile" component={Profile} />
      <Route path="/usr/onboarding/wallet/add" component={AddWallet} />
    </Switch>
  );
};

export default withRouter(EthRoutes);
