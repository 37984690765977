// Base Mui and styled components live here

import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

export const text = styled.div`
  color: white;
`;

export const button = styled.button`
  outline: 0;
  border: 0;
  color: #fff;
  cursor: pointer;
`;

export const useButtonStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: ({ disable }) =>
      disable ? "#3C3C3CFF" : theme.palette.primary.main,
    cursor: ({ disable }) => (disable ? "auto !important" : "pointer"),

    "&:hover": {
      backgroundColor: ({ disable }) =>
        disable ? "#3C3C3CFF" : theme.palette.primary.dark,
    },
  },
}));

export const Flex = styled.div`
  display: flex;
`;

export const input = styled.input`
  outline: 0;
  border: 0;
  color: #fff;
`;

export const MyPaper = styled(Paper)`
  border-radius: 20px !important;
  color: #fff !important;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
