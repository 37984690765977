import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import * as ImgServer from "utils/imgServer";

const SubSlider = ({ nav, setIsPlaying }) => {
  const params = useParams();
  const games = useSelector((state) => state.games);
  const game = games?.byId[params?.id] || {};
  const galleryImages = game?.additional_images_url;

  let totalGalleryCount = game.additional_images_url
    ? game.additional_images_url.length
    : 0;
  if (game.video_url) {
    totalGalleryCount += 1;
  }

  if (!game || !galleryImages?.length > 0) {
    return null;
  }

  const settings = {
    className: "center",
    centerMode: true,
    slidesToShow: Math.min(5, totalGalleryCount),
    slidesToScroll: Math.min(3, totalGalleryCount),
    infinite: !!(totalGalleryCount > 5),
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: Math.min(3, totalGalleryCount),
          slidesToScroll: Math.min(3, totalGalleryCount),
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: () => {
      setIsPlaying(false);
    },
  };

  return (
    <Grid item xs={12} className="game-details__carousel">
      <Slider id="subSlider" className="subSlider" asNavFor={nav} {...settings}>
        {game.video_url && galleryImages.length > 0 && (
          <ReactPlayer url={game.video_url} />
        )}
        {galleryImages.map((picture) => (
          <div key={picture}>
            <img
              src={ImgServer.gameImage(game.id, "gallery", picture)}
              alt="uploaded file"
              style={{ minWidth: "1px", height: "100%" }}
            />
          </div>
        ))}
      </Slider>
    </Grid>
  );
};

export default SubSlider;
