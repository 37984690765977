import background from "images/marketplace/background.png";
import Rectangle37 from "images/dashboard/Rectangle37.png";
import Rectangle38 from "images/dashboard/Rectangle38.png";
import Rectangle39 from "images/dashboard/Rectangle39.png";
import Rectangle40 from "images/dashboard/Rectangle40/Rectangle40.png";
import Rectangle40_1 from "images/dashboard/Rectangle40/Rectangle40_1.png";
import Rectangle41 from "images/dashboard/Rectangle41.png";
import Rectangle42 from "images/dashboard/Rectangle42.png";
import Rectangle43 from "images/dashboard/Rectangle43.png";
import Rectangle44 from "images/dashboard/Rectangle44.png";
import Rectangle45 from "images/dashboard/Rectangle45.png";
import Rectangle46 from "images/dashboard/Rectangle46.png";
import Rectangle47 from "images/dashboard/Rectangle47.png";
import Rectangle48 from "images/dashboard/Rectangle48.png";
import Nft1 from "images/achievement/Nft1.png";
import Nft2 from "images/achievement/Nft2.png";

const initialState = {
  backgroundImage: background, //Can be used as a back-up in case of API being down?
  tapcoinConnectedGames: [
    {
      id: "0",
      name: "Stunt Runner",
      description:
        "Apex Legends is a free-to-play battle royale game developed by Respawn Entertainment and Electronic Arts.",
      image: Rectangle37,
      price: "15000 TTT",
    },
    {
      id: "1",
      name: "Panda Commander",
      description:
        "PlayerUnknown's Battlegrounds is an online multiplayer battle royale game",
      image: Rectangle38,
      price: "15000 TTT",
    },
    {
      id: "2",
      name: "Blocky Car Clash",
      description: "Grand Theft Auto V is a 2013 action-adventure game ",
      image: Rectangle39,
      price: "15000 TTT",
    },
  ],
  steamPCChallenges: [
    {
      id: "4",
      name: "Apex Legends",
      description:
        "Apex Legends is a free-to-play battle royale game developed by Respawn Entertainment and Electronic Arts.",
      image: Rectangle40,
      imagesBackGround: [Rectangle40_1],
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      achievements: [
        {
          id: 0,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description: "kILL 1000 Enemies while playing as Octane",
        },
        {
          id: 1,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 2,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 3,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 4,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 5,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 6,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
      ],
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "5",
      name: "Player Unknown Battlegrounds",
      description:
        "PlayerUnknown's Battlegrounds is an online multiplayer battle royale game",
      image: Rectangle41,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      achievements: [
        {
          id: 0,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description: "kILL 1000 Enemies while playing as Octane",
        },
        {
          id: 1,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 2,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 3,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 4,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 5,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 6,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
      ],
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "6",
      name: "Grand Theft Auto V",
      description: "Grand Theft Auto V is a 2013 action-adventure game",
      image: Rectangle42,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      achievements: [
        {
          id: 0,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description: "kILL 1000 Enemies while playing as Octane",
        },
        {
          id: 1,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 2,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 3,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 4,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 5,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 6,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
      ],
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "7",
      name: "Hades",
      description:
        "Hades is a roguelike action dungeon crawler video game developed and published by Supergiant Games.",
      image: Rectangle43,
      nftCounts: "0/10",
      Achievements: "12/35",
      achievements: [
        {
          id: 0,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description: "kILL 1000 Enemies while playing as Octane",
        },
        {
          id: 1,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 2,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 3,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 4,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 5,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
        {
          id: 6,
          count: "5000 TTT",
          name: "Kill 1000 Enemies",
          description:
            "With supporting text below as a natural lead-in to additional content.",
        },
      ],
      price: "15000 TTT",
    },
    {
      id: "8",
      name: "Rocket League",
      description:
        "Rocket League is a vehicular soccer video game developed and published by Psyonix.",
      image: Rectangle44,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "9",
      name: "Counter Strike Go",
      description:
        "Counter-Strike: Global Offensive is a multiplayer first-person shooter developed by Valve.",
      image: Rectangle45,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "10",
      name: "Among Us",
      description:
        "Among Us is an online multiplayer social deduction game developed and published Innersloth",
      image: Rectangle46,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "11",
      name: "Rust",
      description:
        "Rust is a multiplayer-only survival video game developed by Facepunch Studios.",
      image: Rectangle47,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
    {
      id: "12",
      name: "Destiny 2",
      description:
        "Destiny 2 is a free-to-play online-only multiplayer first-person shooter video game developed by Bungie.",
      image: Rectangle48,
      nftCounts: "0/10",
      Achievements: "12/35",
      price: "15000 TTT",
      availableNfts: [
        {
          id: 0,
          name: "Unknown NFT",
          description: "This NFT is unknown. Unlock the achievement to reveal the card!",
          image: Nft1,
          price: "15000 TTT",
          btnBgc: "dark",
        },
        {
          id: 1,
          name: "Dragons Dream",
          description: "Get 5 kills in a row!",
          image: Nft2,
          price: "15000 TTT",
        },
      ],
    },
  ],
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default DashboardReducer;
