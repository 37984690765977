import { useMemo } from "react";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "../reducers/index";

export const history = createBrowserHistory();
export const middlewares = [thunk, routerMiddleware(history)];

const persistConfig = {
  key: "primary",
  whitelist: ["Exchange"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

// eslint-disable-next-line import/no-mutable-exports
let store;

const makeStore = (preloadedState = undefined) => {
  return createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
};

export const initializeStore = (preloadedState = undefined) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });

    // Reset the current store
    store = undefined;
  }

  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
};

store = initializeStore();

export default store;

export const persistor = persistStore(store);

export function useStore(initialState) {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
