import React from "react";
import { StepActions, StepWrapper } from "./styles";
import Stepper from "./elements/Stepper";
import Actions from "./elements/Actions";

const Steps = React.forwardRef(
  (
    {
      children,
      setActiveStep,
      step,
      length,
      actions,
      getLater,
      link,
      text,
      current,
      info,
    },
    ref
  ) => (
    <StepWrapper ref={ref}>
      {children}

      <Stepper length={length} active={step} />
      <StepActions>
        {actions ?? (
          <Actions
            setActiveStep={setActiveStep}
            getLater={getLater}
            link={link}
            text={text}
            current={current}
            info={info}
          />
        )}
      </StepActions>
    </StepWrapper>
  )
);

export default Steps;
