/* eslint-disable react/sort-comp */
import React, { PureComponent, Fragment } from "react";
import { IS_PROD } from "utils/environments";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  ListItemIcon,
  MenuList,
  MenuItem,
  GridListTile,
  Divider,
  Dialog,
} from "@material-ui/core";
import * as R from "ramda";
import {
  fetchOrganization,
  fecthPublisherGameStats,
  getSDKPassword,
  openSnackbar,
  setSnackbarContent,
  fetchGame,
  removeExistingImage,
} from "actions";
import * as Info from "assets/info";
import * as ImgServer from "utils/imgServer";
import {
  renderTextField,
  renderChipMultiSelect,
  FieldFileInput,
  MultiFieldFileInput,
} from "utils/form";
import * as Web3Math from "blockchain/web3Utils";
import Graph from "assets/icons/graphLine.svg";
// import Promotional from 'assets/icons/hot.svg';
import Star from "assets/icons/star.svg";
import InfoIcon from "assets/icons/info.svg";
import TapSymbol from "assets/icons/tapSymbol.svg";
import ConnectedAccounts from "assets/icons/connectedAccounts.svg";
import EthSymbol from "assets/icons/ethSymbole.svg";
import Settings from "assets/icons/settings.svg";
import FooterActionBar from "components/FooterActionBar";
import LoadingSpinner from "components/LoadingSpinner";
import Text from "components/Text";
import { history } from "../../../store/index";

const TABS = {
  Stats: "stats",
  Featured: "featured",
  GamePage: "gamepage",
  Currencies: "currencies",
  Links: "links",
  SDK: "sdk",
  Promotional: "promotional",
  Settings: "settings",
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["short_description"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const SectionDivider = () => <div className="game-page__content-divider" />;

class GamePage extends PureComponent {
  static handleAPIErrors(err) {
    let errors;
    if (err.response) {
      errors = err.response.data;
    } else {
      errors = { title: err.message };
    }
    if (!errors) {
      throw new SubmissionError({
        _error: "Something Went Wrong, Try Again",
      });
    }
    if (errors.length > 0) {
      throw new SubmissionError({ _error: errors[0].title });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: TABS.Featured,
      isSaving: false,
    };
    this.handleSubmitGameUpdate = this.handleSubmitGameUpdate.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.fetchOrganization();
    this.props.fecthPublisherGameStats(match.params.id);
    this.props.getSDKPassword(match.params.id);
  }

  handleCloseModal() {
    this.setState({ modal: null });
  }

  conversionRate(number) {
    const numbers = Web3Math.conversionRatio(number);
    return (
      <Text variant="s1">
        {numbers[0]} : {numbers[1]} GXP
      </Text>
    );
  }

  handleSubmitGameUpdate(data) {
    const { game } = this.props;
    this.setState({ isSaving: true });
    const newData = JSON.parse(JSON.stringify(data));
    newData.earned_currency_file = data.earned_currency_file;
    newData.premium_currency_file = data.premium_currency_file;
    newData.feature_character_file = data.feature_character_file;
    newData.background_file = data.background_file;
    newData.feature_file = data.feature_file;
    newData.game_images = data.game_images;
    newData.id = game.id;
    this.props
      .updateGame(newData)
      .then(() => {
        this.setState({ isSaving: false });
        this.renderSuccessSnackbar();
      })
      .catch((error) => {
        this.setState({ isSaving: false });
        this.renderErrorSnackbar(error);
      });
  }

  handleMenuClick(activeTab) {
    this.setState({ activeTab });
  }

  navigateHome() {
    this.props.history.push("/");
  }

  renderDialogs() {
    const { modal } = this.state;
    return (
      <>
        <Dialog open={modal === "UNSAVED_CHANGES"} onClose={this.handleCloseModal}>
          <div className="modal-570">
            <Text variant="h4">You have unsaved changes</Text>
            <Text variant="p2">Save or discard changes to continue</Text>
            <div
              className="mt--32"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => {
                  this.props.reset();
                  this.navigateHome();
                }}
                className="btn-red"
                style={{ flex: 0.45 }}
              >
                Discard
              </Button>
              <Button
                onClick={this.props.handleSubmit((data) => {
                  this.handleSubmitGameUpdate(data).then(() => {
                    this.navigateHome();
                  });
                })}
                className="btn-green"
                style={{ flex: 0.45 }}
              >
                Save
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  renderSuccessSnackbar() {
    this.props.openSnackbar();
    this.props.setSnackbarContent({
      msgVariant: "success",
      message: (
        <span
          style={{
            display: "flex",
            width: "100%",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          {/* <Checkmark style={{ marginRight: '10px' }} /> */}
          <Text variant="s2">Changes Saved</Text>
        </span>
      ),
    });
  }

  renderErrorSnackbar(error) {
    this.props.openSnackbar();
    this.props.setSnackbarContent({
      msgVariant: "error",
      message: (
        <span
          style={{
            display: "flex",
            width: "100%",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          {/* <Checkmark style={{ marginRight: '10px' }} /> */}
          <Text variant="s2">An error occured. {error}</Text>
        </span>
      ),
    });
  }

  renderMenu() {
    const { game } = this.props;
    const { activeTab } = this.state;
    const MenuText = (props) => (
      <Text
        variant="p2"
        className={`game-page__menu__text${props.name === activeTab ? "--active" : ""}`}
        {...props}
      />
    );
    const MenuIcon = (props) => (
      <ListItemIcon
        className={`game-page__menu__icon${props.name === activeTab ? "--active" : ""}`}
        {...props}
      />
    );
    return (
      <Paper className="game-page__menu">
        <MenuList className="game-page__menu-list">
          {((game.tap_enabled && game.status === "approved") || !IS_PROD) && (
            <MenuItem
              onClick={() => {
                this.handleMenuClick(TABS.Stats);
              }}
            >
              <MenuIcon name={TABS.Stats}>
                <Graph />
              </MenuIcon>
              <MenuText name={TABS.Stats}>Stats</MenuText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.Featured);
            }}
          >
            <MenuIcon name={TABS.Featured}>
              <Star />
            </MenuIcon>
            <MenuText name={TABS.Featured}>Featured</MenuText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.GamePage);
            }}
          >
            <MenuIcon name={TABS.GamePage}>
              <InfoIcon />
            </MenuIcon>
            <MenuText name={TABS.GamePage}>Game Page</MenuText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.Currencies);
            }}
          >
            <MenuIcon name={TABS.Currencies}>
              <TapSymbol />
            </MenuIcon>
            <MenuText name={TABS.Currencies}>Currencies</MenuText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.Links);
            }}
          >
            <MenuIcon name={TABS.Links}>
              <ConnectedAccounts />
            </MenuIcon>
            <MenuText name={TABS.Links}>Links</MenuText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.SDK);
            }}
          >
            <MenuIcon name={TABS.SDK}>
              <EthSymbol />
            </MenuIcon>
            <MenuText name={TABS.SDK}>SDK</MenuText>
          </MenuItem>
          {/*
          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.Promotional);
            }}
          >
            <MenuIcon name={TABS.Promotional}>
              <Promotional />
            </MenuIcon>
            <MenuText name={TABS.Promotional}>Promotional</MenuText>
          </MenuItem>
          */}
          <MenuItem
            onClick={() => {
              this.handleMenuClick(TABS.Settings);
            }}
          >
            <MenuIcon name={TABS.Settings}>
              <Settings />
            </MenuIcon>
            <MenuText name={TABS.Settings}>Settings</MenuText>
          </MenuItem>
        </MenuList>
      </Paper>
    );
  }

  renderInfo() {
    const { vault, game } = this.props;
    const firstRow = [
      {
        title: "Daily Import limit",
        data: vault.limits.daily_user_import_count_max.toLocaleString(),
      },
      {
        title: "Total Vaults",
        data: vault.vaultCount.toLocaleString(),
      },
      {
        title: "Total conversions to GXP",
        data: vault.totalTTTConversion.toLocaleString(),
      },
    ];

    const secondRow = [
      {
        title: "Daily Export limit",
        data: vault.limits.daily_user_export_count_max.toLocaleString(),
      },
      {
        title: "Total exports to vault",
        data: vault.exportCount.toLocaleString(),
      },
      {
        title: "Total conversions to game currency",
        data: vault.totalGameConversion.toLocaleString(),
      },
    ];

    const renderDataRow = (datas) =>
      datas.map((data) => (
        <Grid item xs={4} key={data.title}>
          <Text variant="caption" color="textSecondary">
            {data.title}
          </Text>
          <Text variant="p1">{data.data}</Text>
        </Grid>
      ));

    return (
      <Grid container className="game-card__content">
        <Grid item xs={12}>
          <Text variant="h3">{game.title}</Text>
        </Grid>
        <Grid container item xs={12} className="mt--16">
          {renderDataRow(firstRow)}
        </Grid>
        <Grid container item xs={12} className="mt--16">
          {renderDataRow(secondRow)}
        </Grid>
      </Grid>
    );
  }

  renderEarnedInfo() {
    const { vault, game } = this.props;
    return (
      <Grid container className="game-card__content">
        <Grid item xs={12}>
          <Text variant="h4">Earned {game.earned_currency_name}</Text>
        </Grid>
        <Grid container item xs={12} className="mt--16">
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Conversion Rate to Tap
            </Text>
            {this.conversionRate(
              vault.conversion.game_earned_coin / vault.conversion.tap_premium_payout
            )}
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Daily conversion limit
            </Text>
            <Text variant="p1">
              {vault.limits.daily_earned_currency_import_max || "?"}
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Total exported {game.earned_currency_name}
            </Text>
            <Text variant="p1">{vault.totalExportedEarnedCount}</Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Total converted {game.earned_currency_name}
            </Text>
            <Text variant="p1">{vault.totalConvertedEarnedCount}</Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Currently in vaults
            </Text>
            <Text variant="p1">{vault.totalEarnedAllCount}</Text>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderPremiumInfo() {
    const { vault, game } = this.props;
    return (
      <Grid container className="game-card__content">
        <Grid item xs={12}>
          <Text variant="h4">Premium {game.premium_currency_name}</Text>
        </Grid>
        <Grid container item xs={12} className="mt--16">
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Conversion Rate to Tap
            </Text>
            {this.conversionRate(
              vault.conversion.game_premium_coin / vault.conversion.tap_premium_payout
            )}
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Daily conversion limit
            </Text>
            <Text variant="p1">
              {vault.limits.daily_premium_currency_import_max || "?"}
            </Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Total exported {game.premium_currency_name}
            </Text>
            <Text variant="p1">{vault.totalExportedPremiumCount}</Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Total converted {game.premium_currency_name}
            </Text>
            <Text variant="p1">{vault.totalConvertedPremiumCount}</Text>
          </Grid>
          <Grid item xs={4}>
            <Text variant="caption" color="textSecondary">
              Currently in vaults
            </Text>
            <Text variant="p1">{vault.totalPremiumAllCount}</Text>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderEarnedCurrency() {
    const { game } = this.props;
    const { earned_currency_image } = this.state;
    return (
      <Grid container className="currency__content game-card__content">
        <Grid item xs={6} container justifyContent="center">
          <Grid container alignItems="flex-start" className="currency__content__img">
            <Text variant="h6">Earned currency</Text>
            <p className="body2 mt--16 text__secondary">
              This is the base currency in the game like score, gold coins. We recommend a
              square asspect ratio image going edge to edge for the best look.
            </p>
            <p className="caption fullWidth mt--20-20 text__secondary">
              Earned Currency Name
            </p>
            <Field
              className="mb--32 text__white"
              name="earned_currency_name"
              component={renderTextField}
              fullWidth
            />
            <label className="fullWidth" htmlFor="earned-currency-field">
              <Button component="span" fullWidth variant="outlined">
                Upload Currency Icon
              </Button>
            </label>
            <p className="body2 mt--16 text__secondary margin-auto">
              Square aspect ratio transparent PNG Image
            </p>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <img
            src={earned_currency_image || ImgServer.gameImage(game, "earned")}
            style={{
              width: "30px",
              height: "30px",
              margin: "110px 0px 40px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
            }}
            alt="small earned currency"
          />
          <img
            src={earned_currency_image || ImgServer.gameImage(game, "earned")}
            style={{
              width: "60px",
              height: "60px",
              margin: "80px 20px 28px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
            }}
            alt="medium earned currency"
          />
          <div style={{ marginBottom: "-100px" }}>
            <img
              src={earned_currency_image || ImgServer.gameImage(game, "earned")}
              style={{
                width: "140px",
                height: "140px",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
              }}
              alt="large earned currency"
            />
            <p className="overline text--left text__secondary">
              Square aspect ratio, try to <br />
              reach all 4 sides.
            </p>
          </div>
          <Field
            className="display-none"
            name="earned_currency_file"
            nolabel
            src={ImgServer.gameImage(game, "earned")}
            id="earned-currency-field"
            component={FieldFileInput}
          />
        </Grid>
      </Grid>
    );
  }

  renderPremiumCurrency() {
    const { game } = this.props;
    const { premium_currency_image } = this.state;
    return (
      <Grid container className="currency__content game-card__content">
        <Grid item xs={6} container justifyContent="center">
          <Grid container alignItems="flex-start" className="currency__content__img">
            <Text variant="h6">Premium currency</Text>
            <p className="body2 mt--16 text__secondary">
              This is the most valuable currency in the game which is often sold in your
              in-game store.
            </p>
            <p className="caption fullWidth mt--20-20 text__secondary">
              Premium Currency Name
            </p>
            <Field
              className="mb--32 text__white"
              name="premium_currency_name"
              component={renderTextField}
              fullWidth
            />
            <label className="fullWidth" htmlFor="premium-currency-field">
              <Button component="span" fullWidth variant="outlined">
                Upload Currency Icon
              </Button>
            </label>
            <p className="body2 mt--16 text__secondary margin-auto">
              Square aspect ratio transparent PNG Image
            </p>
          </Grid>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <img
            src={premium_currency_image || ImgServer.gameImage(game, "premium")}
            style={{
              width: "30px",
              height: "30px",
              margin: "110px 0px 40px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
            }}
            alt="small premium currency"
          />
          <img
            src={premium_currency_image || ImgServer.gameImage(game, "premium")}
            style={{
              width: "60px",
              height: "60px",
              margin: "80px 20px 28px",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
            }}
            alt="medium premium currency"
          />
          <div style={{ marginBottom: "-100px" }}>
            <img
              src={premium_currency_image || ImgServer.gameImage(game, "premium")}
              style={{
                width: "140px",
                height: "140px",
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://img.tapplatform.io/stat/icons/add-image.svg";
              }}
              alt="large premium currency"
            />
            <p className="overline text--left text__secondary">
              Square aspect ratio, try to <br />
              reach all 4 sides.
            </p>
          </div>
          <Field
            className="display-none"
            name="premium_currency_file"
            nolabel
            src={ImgServer.gameImage(game, "premium")}
            id="premium-currency-field"
            component={FieldFileInput}
          />
        </Grid>
      </Grid>
    );
  }

  renderVaultFields() {
    const { game, thisForm } = this.props;
    return (
      <Grid className="game-card__content">
        <Grid item xs={12} md={6}>
          <Text variant="h4">Game Vault settings</Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            You can limit how much currency a player can import or export from your game
            to prevent them from pouring all their currency into or out of the game.
          </Text>
          {game.game_economy_type === "balanced" && (
            <>
              <Text variant="h5">Closed Economy settings</Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                To prevent an ingame economy from collapsing, you can set limits on how
                much currency users can import and export in total.
              </Text>
              <Text variant="h5" className="mt--32">
                Minimum values
              </Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                The minimum amount determines how much currency users can export from the
                game. If too many users have exported currency, you&apos;ll encounter
                problems with there being not enough currency to go around. As uses import
                currency the &quot;allowance&quot; will go back up allowing users to
                withdraw more. You&apos;ll likely want the minimums to be 0.
              </Text>
              <Field
                className="mt--16"
                type="number"
                name="earned_currency_min"
                component={renderTextField}
                label={`minimum amount of ${game.earned_currency_name} exported`}
                fullWidth
              />
              <Field
                className="mt--32"
                type="number"
                name="premium_currency_min"
                component={renderTextField}
                label={`minimum amount of ${game.premium_currency_name} exported`}
                fullWidth
              />
              <Text variant="h5" className="mt--32">
                Maximum values
              </Text>
              <Text variant="p2" color="textSecondary" className="mt--16">
                The maximum amount determines how much currency users can import into the
                game. If too many users have imported currency, you&apos;ll encounter
                problems with inflation. As users export currency, other users will be
                able to import more currency. You&apos;ll likely want this number to be
                lower than half the amount of currency in your economy, but by how much is
                up to you.
              </Text>
              <Field
                className="mt--16"
                type="number"
                name="earned_currency_max"
                component={renderTextField}
                label={`maximum amount of ${game.earned_currency_name} imported`}
                fullWidth
              />
              <Field
                className="mt--32"
                type="number"
                name="premium_currency_max"
                component={renderTextField}
                label={`maximum amount of ${game.premium_currency_name} imported`}
                fullWidth
              />
            </>
          )}
          <Text variant="h5" className="mt--32">
            Player daily import/export limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            How many times do you want your users to be able to import/export from your
            game each day.
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_user_import_count_max"
            component={renderTextField}
            label="number of currency imports per day"
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_user_export_count_max"
            component={renderTextField}
            label="number of currency exports per day"
            fullWidth
          />
          <Text variant="h5" className="mt--32">
            {R.propOr("Earned", "earned_currency_name", thisForm.values)} currency vault
            limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            Each user can import and export a certain amount per day, how much should your
            users be able to do?
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_earned_currency_import_max"
            component={renderTextField}
            label={`number of ${R.propOr(
              "Earned",
              "earned_currency_name",
              thisForm.values
            )} imports per day`}
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_earned_currency_export_max"
            component={renderTextField}
            label={`number of ${R.propOr(
              "Earned",
              "earned_currency_name",
              thisForm.values
            )} exports per day`}
            fullWidth
          />
          <Text variant="h5" className="mt--32">
            {R.propOr("Premium", "premium_currency_name", thisForm.values)} currency vault
            limits
          </Text>
          <Text variant="p2" color="textSecondary" className="mt--16">
            Each user can import and export a certain amount per day, how much should your
            users be able to do?
          </Text>
          <Field
            className="mt--32"
            type="number"
            name="daily_premium_currency_import_max"
            component={renderTextField}
            label={`number of ${R.propOr(
              "Premium",
              "premium_currency_name",
              thisForm.values
            )} imports per day`}
            fullWidth
          />
          <Field
            className="mt--32"
            type="number"
            name="daily_premium_currency_export_max"
            component={renderTextField}
            label={`number of ${R.propOr(
              "Premium",
              "premium_currency_name",
              thisForm.values
            )} exports per day`}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  renderPromotional() {
    const { game } = this.props;
    return (
      <Grid container className="promo__content game-card__content" spacing={5}>
        <Grid item xs={12} className="promo__content__edit-btns">
          <Text variant="h6">Game Character</Text>
          <p className="body2 mt--16 text__secondary">
            If you have one, you can upload an image of your main character with a
            transparent background.
          </p>
          <p className="text--centered body2 mt--4 text__secondary">
            ~373*451px, Transparent PNG file max size 2 mb
          </p>
          <div
            className="promo__content__banner-info"
            style={{
              width: "373px",
              height: "451px",
              border: "2px solid #ffffff",
              position: "relative",
            }}
          >
            <Field
              name="feature_character_file"
              imgStyle={{
                height: "auto",
                width: "100%",
              }}
              nolabel
              imglabel
              src={ImgServer.gameImage(game, "feature_character")}
              id="feature_character"
              component={FieldFileInput}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  renderTeaserCard() {
    const { thisForm, game } = this.props;
    return (
      <Grid container className="teaser-card__content game-card__content">
        <Grid item xs={12} container className="teaser__content__edit">
          <Grid item xs={12} className="teaser__content__edit-btns">
            <Text variant="h6">Thumbnail Card</Text>
            <Text variant="p2" className="mt--16 text__secondary">
              The thumnail card is what should inspire users to learn more.
            </Text>
          </Grid>
          <Grid item xs={12} className="mt--32">
            <Card className="teaser-card__content__game-card dark">
              <Field
                name="feature_file"
                imgStyle={{
                  width: "570px",
                  height: "321px",
                }}
                nolabel
                src={ImgServer.gameImage(game, "feature")}
                id="feature-field"
                component={FieldFileInput}
              >
                <CardContent>
                  <Text variant="h4">{game.title}</Text>
                  <Text variant="p1" className="body1 mt--16">
                    {thisForm.values && thisForm.values.short_description}
                  </Text>
                  <Text variant="p1" align="right">
                    {thisForm.values &&
                      thisForm.values.platform &&
                      thisForm.values.platform.map(
                        (platform) =>
                          platform.value !== null && (
                            <img
                              key={platform.value}
                              className="mr--8"
                              alt={platform.value}
                              src={`https://img.tapplatform.io/stat/icons/format/${platform.value}.svg`}
                            />
                          )
                      )}
                  </Text>
                </CardContent>
              </Field>
            </Card>
            <label htmlFor="feature-field">
              <Button component="span" fullWidth variant="outlined" className="mt--32">
                Upload Featured Image
              </Button>
            </label>
            <Text
              variant="p2"
              color="textSecondary"
              className="text--centered mt--4 margin-auto"
            >
              Max 2MB PNG or JPEG and with no wording on image
            </Text>
            <Field
              multiline
              placeholder="Write your text here"
              className="mt--32"
              label="Teaser description"
              name="short_description"
              component={renderTextField}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderGameCard() {
    const { game } = this.props;
    return (
      <>
        <Grid container className="game-card__content" spacing={5}>
          <Grid item xs={12}>
            <Text variant="s1">Featured Game Image</Text>
            <Text variant="p2" color="textSecondary">
              Images will be fit to 16:9 aspect ratio. Max 2MB This image will be used to
              feature your game on the marketplace.
            </Text>
            <Field
              className="mt--40"
              imgStyle={{
                width: "668px",
                height: "380px",
              }}
              name="background_file"
              nolabel
              imglabel
              removeText
              src={ImgServer.gameImage(game, "background")}
              id="background-field"
              component={FieldFileInput}
            />
            <Text variant="s1" className="mt--24">
              Gameplay Images
            </Text>
            <Text variant="p2" color="textSecondary">
              Images will be cropped to 16:9, recommend having minium 5 images, max. 16
              images. Max 2MB each.
            </Text>
            <Field
              style={{
                margin: "24px -8px 0px",
              }}
              imgStyle={{
                height: "90px",
                width: "160px",
              }}
              removeText
              name="game_images"
              src="https://img.tapplatform.io/stat/icons/add-image-lg.png"
              id="multifile-upload"
              component={MultiFieldFileInput}
            >
              {game.additional_images_url.map((picture, index) => (
                <GridListTile key={picture}>
                  <img
                    src={ImgServer.gameImage(game.id, "gallery", picture)}
                    style={{
                      height: "90px",
                      width: "160px",
                    }}
                    alt="uploaded file"
                  />
                  <Field
                    readOnly
                    component={renderTextField}
                    name={`existing_additional_images_url[${index}]`}
                    value={picture}
                    style={{ display: "none" }}
                  />
                  <Button
                    onClick={() => this.props.removeExistingImage(index)}
                    variant="text"
                    size="small"
                    className="center normal"
                  >
                    Remove Image
                  </Button>
                </GridListTile>
              ))}
            </Field>
            <Text variant="s1" className="mt--24">
              Videos
            </Text>
            <Text variant="p2" color="textSecondary">
              Your featured video will be first thing players see, leave a good
              impression. Its optional, but we strongly recommend having one!
            </Text>
            <Field
              className="mt--16"
              name="video_url"
              component={renderTextField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Text variant="s1" className="mt--24">
              About the game
            </Text>
            <Text variant="p2" color="textSecondary">
              Users like reading about the narrative of the game. Your description can be
              short or long, we leave that up to you, but avoid to much fluff text.
            </Text>
            <Field
              multiline
              label="Description"
              name="full_description"
              placeholder="Write your text here"
              component={renderTextField}
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    );
  }

  renderLinks() {
    const { thisForm } = this.props;
    return (
      <Grid container className="other__content__inner-content game-card__content">
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          item
          xs={8}
          className="other__content__input-column"
        >
          <Text variant="h6">Game links</Text>
          <p className="body2 mt--16 text__secondary">
            Links to where users can find your play or buy your game for each device.
          </p>
          <div className="mt--32">
            <p className="caption text__secondary mb--8">Devices</p>
            <Field
              name="platform"
              component={renderChipMultiSelect}
              fullWidth
              options={Info.platforms}
              isMulti
            />
            {thisForm.values &&
              thisForm.values.platform &&
              thisForm.values.platform.map((platform, index) => (
                <div key={platform.label ? platform.label : platform} className="mt--32">
                  <Field
                    name={`game_store_url[${index}]`}
                    label={`${platform.label ? platform.label : platform} url`}
                    component={renderTextField}
                    fullWidth
                  />
                </div>
              ))}
            <Field
              className="mt--40"
              label="Game Website url"
              name="website_url"
              component={renderTextField}
              fullWidth
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  renderSDK() {
    const { game } = this.props;
    return (
      <Grid container className="game-card__content">
        {game.game_economy_type === "balanced" && (
          <Grid item xs={12} className="mb--32">
            <Text variant="h6">SDK address</Text>
            <Text variant="p2" color="textSecondary">
              When your game has been approved for Tap coin, your unique password to use
              with SDK plug-in will be show here.
            </Text>
            <Text variant="h6">{game.sdk_password}</Text>
          </Grid>
        )}
        <Grid item xs={12} className="mb--32">
          <Text variant="h6">Game ID</Text>
          <Text variant="p2" color="textSecondary">
            When your game has been approved for Tap coin, your unique id to use with SDK
            plug-in will be show here. Visit the SDK guide for instructions on how to add
            the plug-in.
          </Text>
          <Text variant="h6">{game.id}</Text>
        </Grid>
      </Grid>
    );
  }

  renderTags() {
    return (
      <Grid container className="game-card__content">
        <Grid justifyContent="space-between" item xs={8} container>
          <Grid item xs={12} className="mb--32">
            <Text variant="h6">Descriptive tags</Text>
            <Field
              className="mt--24"
              name="category"
              component={renderChipMultiSelect}
              label="Genre Tags"
              fullWidth
              options={Info.categories}
              isMulti
            />
            <Field
              className="mt--32"
              name="game_element"
              component={renderChipMultiSelect}
              label="Game Elements"
              fullWidth
              options={Info.gameElements}
              isMulti
            />
            <Field
              className="mt--32"
              name="release_year"
              component={renderTextField}
              label="Release year"
              type="number"
              fullWidth
            />
            <Divider />
            <Field
              multiline
              className="mt--40"
              label="Copyright description"
              placeholder="Write your text here"
              name="copyright_description"
              component={renderTextField}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  /*
const TABS = {
  Stats: 'stats',
  Featured: 'featured',
  GamePage: 'gamepage',
  Currencies: 'currencies',
  Links: 'links',
  SDK: 'sdk',
  Promotional: 'promotional',
  Settings: 'settings',
};
*/

  renderStatus() {
    const { game, vault } = this.props;
    if (game.status === "approved") {
      return "";
    }
    return (
      <>
        {vault.limits.daily_user_import_count_max === "?" && (
          <Grid item xs={12} className="mb--40 bg--yellowBase pa--8">
            <Button
              className="display--inline float--right mt--0"
              onClick={() => {
                history.push(`/pub/addGame/economy?id=${game.id}`);
              }}
            >
              Start adding tapcoin support
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={`mb--40 bg--${this.StatusGetColor(game)} pa--8`}>
          <Text className="display--inline float--left mt--0">
            Game status: {game.status}
          </Text>
          {this.StatusDisplayButton(game) && (
            <Button
              className="display--inline float--right mt--0"
              onClick={this.props.handleSubmit((data) => {
                const newData = data;
                newData.status = "resubmitted";
                this.handleSubmitGameUpdate(newData).then(() => {
                  this.props.fetchGame(game.id);
                });
              })}
            >
              Save Changes and resubmit
            </Button>
          )}
        </Grid>
      </>
    );
  }

  StatusGetColor(game) {
    switch (game.status) {
      case "incomplete":
      case "revise":
        return "yellowBase";
      case "approved":
        return "greenBase";
      case "denied":
        return "redBase";
      case "submitted":
      case "resubmitted":
      default:
        return "primaryLight";
    }
  }

  StatusDisplayButton(game) {
    switch (game.status) {
      case "resubmitted":
      case "submitted":
      case "approved":
      default:
        return false;
      case "incomplete":
      case "revise":
      case "denied":
        return true;
    }
  }

  renderContent() {
    switch (this.state.activeTab) {
      case TABS.Stats:
        return (
          <>
            {this.renderInfo()}
            <SectionDivider />
            {this.renderEarnedInfo()}
            <SectionDivider />
            {this.renderPremiumInfo()}
          </>
        );
      case TABS.Featured:
        return (
          <>
            {this.renderTeaserCard()}
            <SectionDivider />
            {this.renderPromotional()}
          </>
        );
      case TABS.Currencies:
        return (
          <>
            {this.renderEarnedCurrency()}
            <SectionDivider />
            {this.renderPremiumCurrency()}
            <SectionDivider />
            {this.renderVaultFields()}
          </>
        );
      case TABS.Links:
        return <>{this.renderLinks()}</>;
      case TABS.SDK:
        return this.renderSDK();
      /* case TABS.Promotional:
        return <Fragment>{this.renderPromotional()}</Fragment>;
      */
      case TABS.Settings:
        return <>{this.renderTags()}</>;
      case TABS.GamePage:
      default:
        return <>{this.renderGameCard()}</>;
    }
  }

  renderFooterActions() {
    return (
      <div>
        <Button
          className="btn--large"
          onClick={() => {
            if (this.props.dirty) {
              this.setState({ modal: "UNSAVED_CHANGES" });
            } else {
              this.navigateHome();
            }
          }}
        >
          <Text variant="p2">Cancel</Text>
        </Button>
        <Button
          className="btn-green btn--large ml--16"
          primary="true"
          onClick={this.props.handleSubmit(this.handleSubmitGameUpdate)}
        >
          {this.state.isSaving ? (
            <LoadingSpinner />
          ) : (
            <Text variant="s2">Save Changes</Text>
          )}
        </Button>
      </div>
    );
  }

  render() {
    const { game, thisForm, organization } = this.props;
    if (thisForm.values.earned_currency_file) {
      const reader = new FileReader();
      reader.onload = (e) => this.setState({ earned_currency_image: e.target.result });

      reader.readAsDataURL(thisForm.values.earned_currency_file);
    }
    if (thisForm.values.premium_currency_file) {
      const reader = new FileReader();
      reader.onload = (e) => this.setState({ premium_currency_image: e.target.result });
      reader.readAsDataURL(thisForm.values.premium_currency_file);
    }

    if (game.user_id && organization && game.user_id !== organization.user_id) {
      history.push("/pub/org/dashboard");
    }
    if (game.id) {
      return (
        <>
          <Grid container alignItems="flex-start" className="game-page">
            {this.renderStatus()}
            {this.renderMenu()}
            <Paper className="game-page__content">{this.renderContent()}</Paper>
          </Grid>
          <FooterActionBar actions={this.renderFooterActions()} />
        </>
      );
    }
    return "";
  }
}

const convertToChip = (info) => {
  const retval = [];
  if (Array.isArray(info)) {
    for (let index = 0; index < info.length; index += 1) {
      retval[index] = { label: info[index], value: info[index] };
    }
    return retval;
  }
  return [info];
};

const mapState = (state) => {
  let initialValues = { platform: [] };
  if (state.games.currentGame && state.games.currentGame.title) {
    initialValues = JSON.parse(JSON.stringify(state.games.currentGame));
    initialValues.platform = convertToChip(state.games.currentGame.platform);
    initialValues.category = convertToChip(state.games.currentGame.category);
    initialValues.game_element = convertToChip(state.games.currentGame.game_element);
  }
  let currentGameVault = {};
  if (state.games.currentGameVault) {
    currentGameVault = JSON.parse(JSON.stringify(state.games.currentGameVault));
    if (R.prop("limits", state.games.currentGameVault)) {
      Object.keys(state.games.currentGameVault.limits).forEach((key) => {
        initialValues[key] = state.games.currentGameVault.limits[key];
      });
    } else {
      currentGameVault.limits = {
        daily_user_import_count_max: "?",
        daily_user_export_count_max: "?",
      };
    }
    if (!R.prop("conversion", state.games.currentGameVault)) {
      currentGameVault.conversion = {
        game_earned_coin: 0,
        tap_premium_payout: 1,
      };
    }
  }
  if (!currentGameVault.limits) {
    currentGameVault.limits = {
      daily_user_import_count_max: "?",
      daily_user_export_count_max: "?",
    };
  }
  if (!currentGameVault.conversion) {
    currentGameVault.conversion = 0;
  }

  return {
    user: state.user,
    game: state.games.currentGame || {},
    vault: currentGameVault,
    initialValues,
    thisForm: state.form.gamePage || { values: {} },
    organization: state.publisher.currentOrganization || {},
    errors: state.publisher.gameUpdate,
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fecthPublisherGameStats,
  getSDKPassword,
  openSnackbar,
  setSnackbarContent,
  fetchGame,
  removeExistingImage,
};

export default compose(
  connect(mapState, mapDispatchToProps),
  reduxForm({ form: "gamePage", validate, enableReinitialize: true })
)(GamePage);
