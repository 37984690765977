import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import Text from "components/Text";
import Slider from "react-slick";
import { getCurrentUser } from "actions/index";
import Image from "components/utils/Image";
import Fillbar from "components/utils/Fillbar";
import * as Info from "assets/info";

class Experience extends Component {
  componentDidMount() {
    this.props.getCurrentUser();
  }

  render() {
    const { levelInfo } = this.props.user;
    const badges = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <Grid container justifyContent="center" className="experience">
        <Grid item xs={12}>
          <Text align="center" variant="h2">
            Tap EXP Rewards
          </Text>
          <Text
            className="mt--16 m--auto width-440"
            align="center"
            color="textSecondary"
            variant="p2"
          >
            The Tap EXP system offers rewards for users who complete tasks on the
            platform. Earn enough XP and level up to Mastery!
          </Text>
        </Grid>
        <Grid container item xs={12} sm={8} md={6} lg={4} className="mt--32">
          <Grid className="badge" item xs={5} sm={3}>
            <Image
              alt="badge"
              src={`https://img.tapplatform.io/stat/icons/levels/${Math.ceil(
                levelInfo.level / 4
              )}-72.png`}
              src2="https://img.tapplatform.io/stat/icons/levels/1-72.png"
            />
            <Image
              alt="badge"
              src={`https://img.tapplatform.io/stat/icons/badge/${this.props.user.profile_banner_url}.png`}
              src2="https://img.tapplatform.io/stat/icons/badge/010-space-invaders.png"
            />
          </Grid>
          <Grid item xs={7} sm={9}>
            <Text variant="h5sub" inline className="mr--4 ml--4">
              LEVEL
            </Text>
            <Text variant="h4" inline className="mr--8">
              {levelInfo.level || "1"}
            </Text>
            <Text variant="h4" inline className="mr--8">
              |
            </Text>
            <Text variant="h4" inline className="mr--4">
              {levelInfo.class || "C"}
            </Text>
            <Text variant="h5sub" inline>
              Class
            </Text>
            <Fillbar
              className="mt--8"
              color="yellowBase"
              progress={
                ((this.props.user.score - levelInfo.required_score) /
                  levelInfo.score_to_next_level) *
                100
              }
            />
            <div className="mt--16">
              <Text variant="h6" inline className="ml--4">
                {this.props.user.score - levelInfo.required_score || "0"} /{" "}
              </Text>
              <Text variant="h6" inline color="textSecondary">
                {levelInfo.score_to_next_level || "0"}
              </Text>
            </div>
          </Grid>
        </Grid>
        <Grid className="pa--32" item xs={12}>
          <Slider id="subSlider" slidesToShow={8} slidesToScroll={1} infinite={false}>
            {badges.map((badge) => (
              <div key={badge}>
                <Image
                  alt="badge"
                  className={`margin-auto ${
                    badge > Math.ceil(levelInfo.level / 4) ? "filter-grayscale100" : ""
                  }`}
                  src={`https://img.tapplatform.io/stat/icons/levels/${badge}-72.png`}
                  src2="https://img.tapplatform.io/stat/icons/levels/1-72.png"
                />
              </div>
            ))}
          </Slider>
        </Grid>
        {Info.experienceTasks.map((task) => (
          <Grid item xs={12} id={task.title}>
            <Paper className="pa--24">
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={3} md={2}>
                  <Text>{task.title}</Text>
                </Grid>
                <Grid item xs={7} md={8}>
                  <Text>{task.info}</Text>
                </Grid>
                <Grid item xs={2}>
                  <Paper className="pa--8 bg--primaryBase">
                    <Text className="mt--0" align="center">
                      {task.value} EXP
                    </Text>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  web3: state.web3,
});

const mapDispatchToProps = {
  getCurrentUser,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Experience);
