import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import Text from "components/Text";

export default class DiscardChangesModal extends PureComponent {
  render() {
    const { onSave, onDiscard } = this.props;

    return (
      <Dialog open>
        <div
          className="ma--40"
          style={{
            maxWidth: `${372 - 80}px`,
          }}
        >
          <Text variant="h6">You have unsaved changes</Text>
          <Text className="tc--leSecondary">
            Save or discard your changes to continue on your journey.
          </Text>
          <div
            className="mt--40"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={onSave} variant="contained" color="primary">
              Save Changes
            </Button>
            <Button onClick={onDiscard} variant="contained" color="secondary">
              Discard
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
