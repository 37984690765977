import { CLOSE_ALERT, OPEN_ALERT } from "./types";

export const openAlert = (data) => {
  return {
    type: OPEN_ALERT,
    data,
  };
};

export const closeAlert = (data) => {
  return {
    type: CLOSE_ALERT,
    data,
  };
};
