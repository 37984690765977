import React, { useState } from "react";
import PageHeader from "components/pageComponents/PageHeader";
import { ContentWrapper, FlexGrid, ExpButton } from "components/ContentElements/styles";
import { Grid } from "@material-ui/core";
import ContentTabs from "./content/ContentTabs";
import { ContentName } from "../Marketplace/styles";

const Content = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <ContentWrapper>
      <PageHeader h1="Leaderboards" subtitle="" />
      {/* {activeTab === 0 && <ContentName>Conversion Leaderboards</ContentName>} */}
      {activeTab === 1 && <ContentName>Referral Leaderboards</ContentName>}
      <Grid container justifyContent="center">
        {/* <FlexGrid>
          <ExpButton
            variant="contained"
            color="primary"
            disabled={!activeTab}
            onClick={() => setActiveTab((prev) => --prev)}
          >
            Conversions
          </ExpButton>
          <ExpButton
            variant="contained"
            color="primary"
            disabled={activeTab}
            onClick={() => setActiveTab((prev) => ++prev)}
          >
            Referrals
          </ExpButton>
        </FlexGrid> */}

        <Grid container justifyContent="center" spacing={4} alignItems="flex-start">
          <ContentTabs activeTab={activeTab} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default Content;
