import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { button } from "../../../styles";

export const ButtonInfoContainer = styled.div`
  margin-left: 8px;
  margin-top: 8px;
`;

export const ButtonInfo = styled(button)`
  padding: 0px;
  min-height: 21px;
  min-width: 21px;
  max-height: 21px;
  max-width: 21px;
  background-color: #7c7d82;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Badge = styled.div`
  text-align: center;
  background-color: #6e43e6;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  padding: 0px 9px 0px 9px;
  line-height: 22px;
  font-size: 12px;
  font-weight: 700;
`;

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333a40",
    maxWidth: "280px",
    padding: "10px 20px",
    borderRadius: "4px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: "400px",
    fontSize: "14px",
    lineHeight: "21px",
    position: "relative",

    "& a": {
      color: "#fff",
    },
  },

  tooltipArrow: {
    "&::before": {
      backgroundColor: "#333a40",
    },
  },
}));
