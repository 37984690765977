import React from "react";
import {
  PodiumContainer,
  LeaderPodium,
  LeaderAvatar,
  LeaderMedal,
  LeaderName,
} from "./styles";
import { ReactComponent as FirstIcon } from "images/icons/first-place.svg";
import { ReactComponent as SecondIcon } from "images/icons/second-place.svg";
import { ReactComponent as ThirdIcon } from "images/icons/third-place.svg";
import mysteryAvatar from "images/profile/mysteryAvatar.png";

const Podium = ({ ranking, username, avatar }) => {
  const renderMedal = (rank) => {
    switch (rank) {
      case 1:
        return <FirstIcon />;
      case 2:
        return <SecondIcon />;
      case 3:
        return <ThirdIcon />;
      default:
        return null;
    }
  };

  return (
    <PodiumContainer rank={ranking}>
      <LeaderMedal>{renderMedal(ranking)}</LeaderMedal>
      <LeaderPodium>
        <LeaderAvatar>
          <img src={avatar ?? mysteryAvatar} alt="user avatar" />
        </LeaderAvatar>
        <LeaderName>{username}</LeaderName>
      </LeaderPodium>
    </PodiumContainer>
  );
};

export default Podium;
