import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Flex } from "../../styles";

export const ContentWrapper = styled.div`
  margin: 45px 58px 0 67px;

  @media (max-width: 768px) {
    margin: 15px 20px 0 20px;
  }

  @media (max-width: 480px) {
    margin: 0 24px;
  }
`;

export const ContentHeaderName = styled.div`
  font-weight: 600;
  font-family: "Poppins", sans-serif;

  @media (max-width: 480px) {
    font-size: 22px;
    margin: 20px 0 20px 0;
  }
`;

export const ContentHeaderText = styled(ContentHeaderName)`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 46px;

  @media (max-width: 480px) {
    margin-top: 0;
    margin-bottom: 37px;
  }
`;

export const ContentName = styled(ContentHeaderText)`
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 32px;

  @media (max-width: 480px) {
    font-size: 18px;
    margin-bottom: 37px;
  }
`;

export const FlexGrid = styled(Flex)`
  flex-wrap: wrap;
`;

export const NftItem = styled.div`
  position: relative;
  width: 318px;
  height: 260px;
  padding: 11px 8px 11px 16px;
  margin: 0 15px 30px 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: space-between;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 250px;
  }
`;

const NftText = styled.div`
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
`;

export const Nft = styled(NftText)`
  text-transform: uppercase;
`;

export const NftName = styled(NftText)`
  line-height: 29px;
  font-weight: 800;
  font-size: 24px;

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const NftDesc = styled(NftText)`
  font-weight: 400;
`;

export const NftActions = styled(Flex)`
  align-items: flex-end;
  margin-bottom: 7px;
`;
export const NftImg = styled.img`
  border-radius: 5px;
`;

export const NftButton = styled(Button)`
  padding: 10px 16px !important;
  font-size: 16px !important;
  line-height: 19px !important;

  @media (max-width: 330px) {
    padding: 10px 10px;
  }

  &:disabled {
    background-color: #483d8b;
    cursor: default;
  }
`;

export const NftButtonWide = styled(NftButton)`
  width: 60%;

  &:disabled {
    background-color: #483d8b;
    cursor: default;
  }
`;

export const NftDarkButton = styled.div`
  margin-left: 10px;

  & > button {
    background-color: #343a40;

    &:hover {
      background-color: #2c3136;
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const NftSold = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #ffc107;
`;

export const OuterNFTWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  height: 100%;

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

export const SteamButton = styled(Button)`
  box-sizing: border-box;
  padding: 18px 23px;
  color: #ffffff;
  background: linear-gradient(0.25turn, #5d8628, 25%, #416527);
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  height: 39px;
  width: 100%;

  & span {
    text-decoration: none !important;
    padding-right: 45px !important;
  }

  &:hover {
    text-decoration: none !important;
  }
`;

export const SteamIcon = styled.img`
  position: absolute;
  bottom: 19px;
  transform: translateY(50%);
  right: 14px;
  cursor: pointer;
`;
