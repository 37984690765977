import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const NewWalletMyEther = () => (
  <Grid container>
    <Grid item xs={12} className="mt--64">
      <Typography color="inherit" variant="display2" className="bold">
        My.Ether
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--32">
      <Typography color="inherit">
        My.Ether is pure decentralization, where you hold the key file to unlock your
        wallet, This also means there is no recovery if you lose your key file.
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--64">
      <Typography variant="" color="inherit">
        1. Make a ETH address. Do not lose your private key!
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--24">
      <Button
        variant="contained"
        fullWidth
        component={Link}
        target="_blank"
        to="https://www.myetherwallet.com/"
      >
        Open and follow the steps
      </Button>
    </Grid>
    <Grid item xs={12} className="mt--48">
      <Typography variant="" color="inherit">
        2. Click when complete
      </Typography>
    </Grid>
    <Grid item xs={12} className="mt--24">
      <Button
        variant="contained"
        fullWidth
        component={Link}
        to="/pub/onboarding/wallet/add"
      >
        Done
      </Button>
    </Grid>
  </Grid>
);

export default NewWalletMyEther;
