import React from "react";
import { RateLabel, RateStars, RateWrapper } from "./style";
import { StarIcon } from "../../../../../images/raiting/StarIcon";
import { BlackStarIcon } from "../../../../../images/raiting/BlackStarIcon";

const SimpleRating = ({ bonus }) => {
  return (
    <RateWrapper component="fieldset" mb={3} borderColor="transparent">
      <RateLabel component="legend">Bonus Referral Stars</RateLabel>
      <RateStars
        name="customized-color"
        defaultValue={bonus}
        precision={0.5}
        readOnly
        icon={<StarIcon viewBox="0 0 41 39" fontSize="inherit" />}
        emptyIcon={<BlackStarIcon viewBox="0 0 41 39" fontSize="inherit" />}
      />
    </RateWrapper>
  );
};

export default SimpleRating;
