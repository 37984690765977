import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Achievements, AchievementsBody, Description, Name } from "./styles";
import { ReactComponent as LockedIcon } from "assets/icons/locked.svg";
import { ReactComponent as UnlockedIcon } from "assets/icons/unlocked.svg";

const ListAchievements = ({ list = [] }) => (
  <>
    {list.map(
      ({
        name,
        displayName,
        description,
        icon,
        icongray,
        percentage,
        tapval,
        achieved,
      }) => (
        <Achievements achieved={achieved} key={name}>
          <AchievementsBody achieved={achieved}>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              lg={10}
              md={8}
              sm={6}
              xs={12}
            >
              <Grid item style={{ marginRight: "15px" }}>
                <img
                  height="92"
                  width="92"
                  src={achieved ? icon : icongray}
                  alt="achievement"
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item container>
                    <Name>{displayName}</Name>
                  </Grid>
                  <Grid item>
                    <Description>{description}</Description>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="space-around"
              lg={2}
              md={4}
              sm={6}
              xs={12}
              style={{
                borderLeft: achieved ? "solid 1px #303941" : "solid 1px #22272b",
                marginLeft: "5px",
              }}
            >
              <Typography variant="h4">{tapval} Points</Typography>
              <div style={{ width: "48px", height: "48px" }}>
                {!!achieved ? (
                  <UnlockedIcon style={{ fill: "#7749f8" }} />
                ) : (
                  <LockedIcon style={{ fill: "#22272b" }} />
                )}
              </div>
            </Grid>
          </AchievementsBody>
        </Achievements>
      )
    )}
  </>
);

export default ListAchievements;
