import React from "react";
import { ReactComponent as ArrowPrev } from "../../images/icons/arrowPrev.svg";
import { PrevButton } from "./styles";

const ButtonPrev = ({ handler }) => (
  <PrevButton onClick={handler}>
    <ArrowPrev />
    Previous
  </PrevButton>
);

export default ButtonPrev;
