import React from "react";
import { Grid, Paper } from "@material-ui/core";
import * as Web3Math from "blockchain/web3Utils";
import Text from "components/Text";

const DetailedVaultCard = ({ className, vals }) => {
  return (
    vals.name &&
    vals.value && (
      <Paper className={`${className} detailed-vault-card`}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12} className="mb--32">
            <img src={vals.img || vals.imgFallback} alt="Currency Icon" />
          </Grid>
          <Grid item xs={12} container spacing={1} justifyContent="space-between">
            <Text variant="h6">{vals.name}</Text>
            <Text variant="h6" align="right">
              {vals.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Text>
          </Grid>
          <Grid container item xs={12} className="mt--16" justifyContent="space-between">
            <Text variant="p2" color="textSecondary">
              Conversion rate
            </Text>
            <Text variant="p2" color="textSecondary">
              {vals.value < 1
                ? `1 : ${Web3Math.round(1 / vals.value, 2)} ${vals.currencySymbol}`
                : `${Web3Math.round(vals.value, 2)} : 1 ${vals.currencySymbol}`}
            </Text>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Text variant="p2" color="textSecondary">
              Value
            </Text>
            <Text variant="p2" color="textSecondary">
              {Web3Math.round(vals.amount / vals.value, 2)} {vals.currencySymbol}
            </Text>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Text variant="p2" color="textSecondary">
              Daily Import Limit
            </Text>
            <Text variant="p2" color="textSecondary">
              {vals.import_max} {vals.name}
            </Text>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Text variant="p2" color="textSecondary">
              Daily Export Limit
            </Text>
            <Text variant="p2" color="textSecondary">
              {vals.export_max} {vals.name}
            </Text>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Text variant="p2" color="textSecondary">
              Exports Remaining
            </Text>
            <Text variant="p2" color="textSecondary">
              {vals.export_max - vals.earned_count} {vals.name}
            </Text>
          </Grid>
        </Grid>
      </Paper>
    )
  );
};

DetailedVaultCard.propTypes = {};

DetailedVaultCard.defaultProps = {};

export default DetailedVaultCard;
