import axios from "axios";
import { IS_PROD } from "utils/environments";
import { getUserNFTsByGame } from "./apiRequest";

const createAxiosInstance = () => {
  return axios.create({
    baseURL: IS_PROD ? "https://nft-api.gamexchange.app" : "http://70.70.62.21:33334",
  });
};

export const getNFTsForGame = (gameIdWithPrefix, authed) =>
  createAxiosInstance()
    .get(`/web/v1/assets/?steamId=${gameIdWithPrefix}`)
    .then(async (data) => {
      var info = data.data.data.assets;
      const claimedNFTs = authed
        ? await getUserNFTsByGame(gameIdWithPrefix)
            .then((res) => res.data.data)
            .catch((err) => [])
        : [];

      for (let index = 0; index < info.length; index++) {
        if (claimedNFTs.length > 0) {
          for (let index2 = 0; index2 < claimedNFTs.length; index2++) {
            if (info[index].nftId == claimedNFTs[index2].attributes.nftId) {
              info[index].claimed = true;
            }
          }
        }
      }
      return info;
    });

export const getNFT = (id) =>
  createAxiosInstance().get(`/v1/nfts/contract/TapNFTs/nft/${id}`);

export const getFeatured = () =>
  createAxiosInstance()
    .get(`web/v1/assets/marketplace/featured`)
    .then((res) => {
      return res.data.data.assets;
    });

export const getSponsored = () =>
  createAxiosInstance()
    .get(`/web/v1/assets/marketplace/sponsored`)
    .then((res) => {
      return res.data.data.assets;
    });

export const getMarketplaceNFT = (blockchainId, contractAddress, tokenId) =>
  createAxiosInstance()
    .get(
      `/web/v1/nft/marketplace/blockchain/${blockchainId}/contract/${contractAddress}/token/${tokenId}`
    )
    .then((res) => res.data);
