import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Offers } from "../styles";
import {
  removeOffer,
  getAndReplaceOffers,
} from "../../../../reducers/redux/Menu/actions";
import List from "./List";

const ListOffers = React.memo(() => {
  const dispatch = useDispatch();
  const offers = useSelector(({ Menu }) => Menu.offers);

  const removeHandler = (id) => {
    dispatch(removeOffer(id));
  };

  useEffect(() => {
    dispatch(getAndReplaceOffers());
  }, [dispatch]);

  return (
    <Offers>
      <List removeHandler={removeHandler} offers={offers} />
    </Offers>
  );
});

export default ListOffers;
