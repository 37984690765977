import styled from "styled-components";
import { Flex } from "styles";
import { Paper, Grid } from "@material-ui/core";

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #0e002e;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    margin-right: 30px;
    cursor: pointer;
  }
`;

export const LeaderAvatar = styled(Logo)`
  width: 128px;
  height: 128px;
  margin: 0 auto 20px;
  @media (max-width: 960px) {
    width: 96px;
    height: 96px;
  }
`;

export const LeaderMedal = styled(Logo)`
  width: 96px;
  height: 96px;
  @media (max-width: 960px) {
    width: 64px;
    height: 64px;
  }
  margin: 0 auto 10px;
`;

export const LeaderName = styled.span`
  font-size: 32px;
  font-family: "Work Sans";
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 960px) {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const HeaderText = styled.span`
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
`;

export const Text = styled.span`
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 500;
`;

export const FlexRow = styled(Flex)`
  flex-direction: row;
  max-width: min-content;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
  text-align: center;
  &:not(:last-child) {
    margin-right: 25px;
  }
`;

export const PaperContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px !important;
  padding: 20px 40px;
`;

export const LeaderPodium = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px !important;
  padding: 20px;
  background-color: #1f1f1f !important;
  text-align: center;
`;

export const PodiumContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  width: 250px;
`;

export const PodiumGrid = styled(Grid)`
  transform: scale(0.8);
  &:first-child {
    transform: scale(1);
  }

  @media (min-width: 1281px) {
    order: ${({ index }) => (index === 0 ? "2" : index === 2 ? "3" : "1")};
  }

  @media (max-width: 1280px) {
    margin-bottom: 30px;
    transform: scale(1);
  }

  transform-origin: bottom;
`;
