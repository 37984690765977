import React, { PureComponent } from "react";
import {
  SectionHeading,
  SectionText,
  SectionDivider,
  SectionInfo,
} from "components/containers/settings/Settings";

export default class PubEthereumWallet extends PureComponent {
  render() {
    const { ethWallet = "None" } = this.props;
    return (
      <div>
        <SectionHeading>Linked Ethereum wallet address</SectionHeading>
        <SectionInfo
          className="mt--24"
          caption="Linked Ethreum wallet address"
          info={ethWallet}
        />
        <SectionDivider />
        <SectionHeading>Private key warning</SectionHeading>
        <SectionText>
          We are a client-side interface that allows you to interact with the Ethereum
          blockchain, which stores your TTT tokens. We are not a web wallet, and don’t
          have your private key. This keep funds safe from hacks attempts, if you keep
          your private key safe and remember your wallet address.
        </SectionText>
      </div>
    );
  }
}
