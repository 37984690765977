import { useRef, useEffect } from "react";

/**
 * Use the previous state value
 * @param {*} value Current state value
 * @param {*} defaultVal Default value of the state
 * @returns
 */
const usePrevious = (value, defaultVal) => {
  const ref = useRef(defaultVal);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export default usePrevious;
