import invariant from "tiny-invariant";
import warning from "tiny-warning";
import JSBI from "jsbi";
import { getAddress } from "@ethersproject/address";

export const SolidityType = {
  uint8: "uint8",
  uint256: "uint256",
};

// exports for internal consumption
export const ZERO = JSBI.BigInt(0);
export const ONE = JSBI.BigInt(1);
export const TWO = JSBI.BigInt(2);
export const THREE = JSBI.BigInt(3);
export const FIVE = JSBI.BigInt(5);
export const TEN = JSBI.BigInt(10);
export const _100 = JSBI.BigInt(100);
export const _998 = JSBI.BigInt(998);
export const _1000 = JSBI.BigInt(1000);

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt("0xff"),
  [SolidityType.uint256]: JSBI.BigInt(
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  ),
};

export function validateSolidityTypeInstance(value, solidityType) {
  invariant(JSBI.greaterThanOrEqual(value, ZERO), `${value} is not a ${solidityType}.`);
  invariant(
    JSBI.lessThanOrEqual(value, SOLIDITY_TYPE_MAXIMA[solidityType]),
    `${value} is not a ${solidityType}.`
  );
}

// warns if addresses are not checksummed
export function validateAndParseAddress(address) {
  try {
    const checksummedAddress = getAddress(address);
    warning(address === checksummedAddress, `${address} is not checksummed.`);
    return checksummedAddress;
  } catch (error) {
    throw (false, `${address} is not a valid address.`);
  }
}

export function parseBigintIsh(bigintIsh) {
  if (bigintIsh instanceof JSBI) {
    return bigintIsh;
  }
  if (typeof bigintIsh === "bigint") {
    return JSBI.BigInt(bigintIsh.toString());
  }
  return JSBI.BigInt(bigintIsh);
}

// mock the on-chain sqrt function
export function sqrt(y) {
  validateSolidityTypeInstance(y, SolidityType.uint256);
  let z = ZERO;
  let x;
  if (JSBI.greaterThan(y, THREE)) {
    z = y;
    x = JSBI.add(JSBI.divide(y, TWO), ONE);
    while (JSBI.lessThan(x, z)) {
      z = x;
      x = JSBI.divide(JSBI.add(JSBI.divide(y, x), x), TWO);
    }
  } else if (JSBI.notEqual(y, ZERO)) {
    z = ONE;
  }
  return z;
}

// given an array of items sorted by `comparator`, insert an item into its sort index and constrain the size to
// `maxSize` by removing the last item
export function sortedInsert(items, add, maxSize, comparator) {
  invariant(maxSize > 0, "MAX_SIZE_ZERO");
  // this is an invariant because the interface cannot return multiple removed items if items.length exceeds maxSize
  invariant(items.length <= maxSize, "ITEMS_SIZE");

  // short circuit first item add
  if (items.length === 0) {
    items.push(add);
    return null;
  }
  const isFull = items.length === maxSize;
  // short circuit if full and the additional item does not come before the last item
  if (isFull && comparator(items[items.length - 1], add) <= 0) {
    return add;
  }

  let lo = 0;
  let hi = items.length;

  while (lo < hi) {
    // eslint-disable-next-line no-bitwise
    const mid = (lo + hi) >>> 1;
    if (comparator(items[mid], add) <= 0) {
      lo = mid + 1;
    } else {
      hi = mid;
    }
  }
  items.splice(lo, 0, add);
  return isFull ? items.pop() : null;
}
