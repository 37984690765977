import styled from "styled-components";

export const SettingsAvatarWrapper = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(240, 243, 246, 0.1);
`;

export const SettingsImg = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
`;

export const SettingsSubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #808191;
  font-family: "Inter", sans-serif;
`;
