import { EXCHANGE_SET_SLIPPAGE, EXCHANGE_SET_GAS_PRICE } from "./types";

export const updateGasPrice = (data) => {
  return {
    type: EXCHANGE_SET_GAS_PRICE,
    data,
  };
};

export const updateUserSlippageTolerance = (data) => {
  return {
    type: EXCHANGE_SET_SLIPPAGE,
    data,
  };
};
