import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  Paper,
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Text from "components/Text";
import { ButtonInfo } from "components/ContentElements/info/styles";
import { canUpgradeVault } from "actions/index";
import UpgradeVaultModal from "components/Modal/VaultUpgradeModal";
import { upgradeToken } from "blockchain/tokenInfo";
import { BN, toBaseUnit } from "blockchain/web3Utils";
import { CheckCircle, Error } from "@material-ui/icons";
import { NftCount, NftText } from "../Dashboard/Achievement/styles";
import StandardToolTip from "components/StandardToolTip/StandardToolTip";

const useStyles = makeStyles((theme) => ({
  paperPadding: {
    padding: "10px 40px 40px 40px",
  },
  conversions: {
    marginBottom: "20px",
  },
  conversionsBarLeft: {
    height: "10px",
    marginBottom: "20px",
    borderRadius: "10px 0 0 10px",
  },
  conversionsBarRight: {
    height: "10px",
    marginBottom: "20px",
    borderRadius: "0 10px 10px 0",
    backgroundColor: "#906200",
    "& > div": {
      backgroundColor: "#cf9e24",
    },
  },
  customTooltip: {
    maxWidth: "none",
  },
  tooltipButton: {
    margin: "auto 0 auto 10px",
    backgroundColor: "#6e43e6",
  },
}));

const VaultUpgrade = ({
  game,
  vault,
  upgradeAllowance,
  upgradeBalance,
  upgradeSuccess,
  fetchVaultInfo,
}) => {
  const classes = useStyles();

  const [canUpgrade, setCanUpgrade] = useState(false);
  const [hasUpgradeBalance, setHasUpgradeBalance] = useState(false);
  const [hasUpgradeAllowance, setHasUpgradeAllowance] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("Upgrade");
  const web3 = useSelector((state) => state.web3);

  const normalizedProgress = Math.min((vault?.upgrade_eligibility_count * 100) / 8, 100);
  const bonusProgress = Math.max(((vault?.upgrade_eligibility_count - 8) * 100) / 4, 0);
  const minConversions = vault?.upgrade_eligibility_count >= 8;
  const noPoints = vault?.earned_currency == 0 && vault?.premium_currency == 0;

  useEffect(() => {
    if (vault.gameId && web3.chainId) {
      // Call upgrade function to check status
      canUpgradeVault(vault.gameId)
        .then((res) => {
          if (res.upgrade_cost) {
            setCanUpgrade(toBaseUnit(res.upgrade_cost.toString(), upgradeToken.decimals));
          } else {
            upgradeSuccess();
          }
        })
        .catch(() => {
          fetchVaultInfo();
        });
    }
  }, [vault.level, vault.gameId]);

  useEffect(() => {
    if (canUpgrade && upgradeBalance) {
      setHasUpgradeBalance(canUpgrade.lt(new BN(upgradeBalance)));
    }
  }, [canUpgrade, upgradeBalance]);

  useEffect(() => {
    if (canUpgrade && upgradeAllowance) {
      setHasUpgradeAllowance(canUpgrade.lt(new BN(upgradeAllowance)));
    }
  }, [canUpgrade, upgradeAllowance]);

  useEffect(() => {
    if (!minConversions) {
      setButtonText(
        `Upgrade available after ${8 - vault?.upgrade_eligibility_count} conversions`
      );
    } else if (!hasUpgradeBalance) {
      setButtonText("Get Upgrade Tokens");
    } else {
      setButtonText("Upgrade");
    }
  }, [minConversions, hasUpgradeBalance, vault?.upgrade_eligibility_count]);

  const upgradeButton = () => {
    setShowModal(true);
  };

  const checkRequirement = (value) =>
    value ? (
      <Grid container>
        <CheckCircle style={{ fontSize: "20px", fill: "#5FD754" }} />
        <Typography style={{ color: "#5FD754", fontWeight: "700", margin: "0 5px" }}>
          COMPLETED
        </Typography>
      </Grid>
    ) : (
      <Grid container>
        <Error style={{ fontSize: "20px", fill: "#DC3545" }} />
        <Typography style={{ color: "#DC3545", fontWeight: "700", margin: "0 5px" }}>
          INCOMPLETE
        </Typography>
      </Grid>
    );

  const tooltipText = () => {
    return (
      <>
        <Typography style={{ fontSize: "16px" }}>
          To upgrade to the next vault level
        </Typography>
        <Grid container alignItems="center">
          <div>{checkRequirement(!!minConversions)}</div>
          <Typography style={{ fontSize: "12px" }}>
            - Perform at least 8 conversions from game to platform
          </Typography>
        </Grid>
        <Grid container alignItems="center">
          <div>{checkRequirement(!!noPoints)} </div>
          <Typography style={{ fontSize: "12px" }}>
            - Convert all of your existing tokens from platform to blockchain
          </Typography>
        </Grid>
      </>
    );
  };

  if (!game || !vault) return null;

  return (
    <Paper className="conversion-card">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            item
            xs
            container
            justifyContent="space-between"
            alignContent="center"
            style={{ padding: "12px" }}
          >
            <Text variant="h5" color="textSecondary" style={{ margin: "auto 0" }}>
              Upgrade Vault - Level {vault?.level || "0"}
            </Text>
            <Grid item container justifyContent="flex-end" alignContent="center" xs={2}>
              <StandardToolTip title={tooltipText} />
            </Grid>
          </Grid>
          <Divider />
        </Grid>
        <Grid container className={classes.paperPadding}>
          <Grid item container justifyContent="space-between">
            <Grid container xs={12} item>
              <Typography variant="subtitle2">
                {vault.upgrade_eligibility_count}/12 Conversions Completed
              </Typography>
            </Grid>
            <Grid container direction="column" xs={8} item>
              <LinearProgress
                variant="determinate"
                value={normalizedProgress}
                style={{ margin: "10px 0 5px" }}
                className={classes.conversionsBarLeft}
              />
              <NftCount>
                <NftText variant="subtitle2">0</NftText>
              </NftCount>
            </Grid>
            <Grid
              container
              direction="column"
              xs={4}
              className={classes.conversions}
              item
            >
              <LinearProgress
                variant="determinate"
                value={bonusProgress > 0 ? bonusProgress : 0}
                style={{ margin: "10px 0 5px" }}
                className={classes.conversionsBarRight}
              />
              <NftCount>
                <NftText variant="subtitle2" style={{ marginLeft: "-5px" }}>
                  8
                </NftText>
                <NftText variant="subtitle2">12</NftText>
              </NftCount>
            </Grid>
            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => upgradeButton()}
                fullWidth
                disabled={!canUpgrade}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <UpgradeVaultModal
        open={showModal}
        closeHandler={() => setShowModal(false)}
        hasBalance={hasUpgradeBalance}
        upgradeCost={canUpgrade}
        successHandler={() => {
          setCanUpgrade(false);
          upgradeSuccess();
        }}
        hasAllowance={hasUpgradeAllowance}
        gameId={vault.gameId}
        title={`Upgrade Vault: LVL ${vault?.level} > LVL ${vault?.level + 1}`}
      />
    </Paper>
  );
};

export default VaultUpgrade;
