import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { button } from "../../../../styles";

export const ClaimGamePointsContainer = styled.div`
  background-color: #212529;
  max-width: 560px;
  margin: 100px auto;
  position: relative;
`;

export const ModalBodyContainer = styled.div`
  margin: 3px 70px 24px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ActionsContainer = styled.div`
  padding: 16px;
  & > p {
    padding: 0;
  }
`;

export const TotalCount = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #dee2e6;
  line-height: 15px;
`;

export const Arrow = styled(button)`
  width: 24px;
  height: 26px;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  border-radius: 4px;
  border: 1px solid #dde1e5;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "&:hover fieldset": {
      borderColor: "#a9adb3 !important",
    },
  },

  input: {
    "&&": {
      color: "#DEE2E6",
      background: "#68717A",
      borderRadius: 8,
      paddingBottom: "10px",
      fontSize: 34,
    },

    "& > fieldset": {
      borderColor: "#CED4DA",
    },
  },

  error: {
    "&&": {
      color: "#ED4337",
    },
  },

  endAdornment: {
    "& p": {
      color: "#79A17F",
      fontSize: 11,
      fontWeight: 600,
      alignItems: "flex-start",
      height: "6em",
      cursor: "pointer",
    },
  },

  endAdornmentAfter: {
    "& img": {
      position: "absolute",
      right: 18,
    },
  },

  balance: {
    position: "absolute",

    bottom: 0,
    marginBottom: 10,
  },

  button: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.primary.main,

    "&:disabled": {
      backgroundColor: "#483d8b",
    },
  },

  balance: {
    position: "absolute",

    bottom: 0,
    marginBottom: 10,
  },

  button: {
    width: "100%",
    margin: "auto",
  },
}));
