import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Typography, Slider, Button } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import GXPBottle from "assets/icons/GXP.png";
import {
  CurrencyImage,
  RemoveLiquidityInfoBox,
  LiquidityDescription,
  CurrencyText,
  Descriptions,
  Description,
  SubHeader,
} from "components/Exchange/styles";
import { roundIntToDecimals } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "434px",
  },
  iconButton: {
    borderRadius: "50px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    height: "32px",
    width: "32px",
    backgroundColor: "#6e43e6",
  },
  downArrow: {
    width: "100%",

    margin: "10px 0 10px",
  },
  firstProvider: {
    borderRadius: "15px",
    backgroundColor: "#FFB2371F",
    marginBottom: "20px",
    "& div": {
      "& svg": {
        fill: "#FFB237",
      },
    },
  },
  alertTitle: {
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Work Sans",
  },
  alertContent: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Work Sans",
    marginBottom: "8px",
  },
  amountButton: {
    borderRadius: "25px",
  },
  slider: {
    margin: "20px 0",
  },
  header: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
    marginBottom: "10px",
  },
  infoHeader: {
    fontSize: "14px",
    fontFamily: "Work Sans",
    fontWeight: "600",
    marginBottom: "10px",
  },
  arrowForward: {
    position: "absolute",
    top: "15px",
    left: "160px",
  },
}));

const SimpleRemove = ({
  fromTotal,
  toTotal,
  fromCurrency,
  toCurrency,
  setPercentage,
  setAmount,
  maxAmount,
  percentage,
  amount,
}) => {
  const classes = useStyles();

  const handleSetPercentage = (newPercentage) => {
    if (typeof newPercentage === "number") {
      setPercentage(newPercentage);
      setAmount((newPercentage * maxAmount) / 100);
    }
  };

  const handleChange = (event, newPercentage) => {
    handleSetPercentage(newPercentage);
  };

  return (
    <>
      <RemoveLiquidityInfoBox style={{ flexDirection: "column" }}>
        <Typography variant="h2">{percentage}%</Typography>
        <CurrencyText>Amount: {roundIntToDecimals(amount, 5)}</CurrencyText>
        <Slider
          defaultValue={0}
          value={percentage}
          min={0}
          max={100}
          step={1}
          onChange={handleChange}
          className={classes.slider}
        />
        <Grid container justifyContent="space-around">
          <Button
            variant="contained"
            color="primary"
            className={classes.amountButton}
            onClick={() => handleSetPercentage(25)}
          >
            25%
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.amountButton}
            onClick={() => handleSetPercentage(50)}
          >
            50%
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.amountButton}
            onClick={() => handleSetPercentage(75)}
          >
            75%
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.amountButton}
            onClick={() => handleSetPercentage(100)}
          >
            MAX
          </Button>
        </Grid>
      </RemoveLiquidityInfoBox>
      <ArrowDownwardIcon className={classes.downArrow} />
      <SubHeader>YOU WILL RECEIVE</SubHeader>
      <RemoveLiquidityInfoBox>
        <Descriptions>
          <Description>
            <Grid container>
              {/*<CurrencyImage src={GXPBottle} alt="currency" />*/}
              <CurrencyText>{fromCurrency.symbol}</CurrencyText>
            </Grid>
            <LiquidityDescription>
              {Math.floor(fromTotal * percentage) / 100}
            </LiquidityDescription>
          </Description>
          <Description>
            <Grid container>
              {/*<CurrencyImage src={GXPBottle} alt="currency" />*/}
              <CurrencyText>{toCurrency.symbol}</CurrencyText>
            </Grid>
            <LiquidityDescription>
              {Math.floor(toTotal * percentage) / 100}
            </LiquidityDescription>
          </Description>
        </Descriptions>
      </RemoveLiquidityInfoBox>
    </>
  );
};

export default SimpleRemove;
