import React from "react";
import { Grid, DialogActions, Dialog, Button } from "@material-ui/core";
import { ReactComponent as Close } from "images/close.svg";
import Text from "components/Text";
import { useStyles } from "components/Dashboard/Achievement/claimGamePoints/styles";
import { ButtonClose, DialogTitle, useStyles as FormStyled } from "../styles";
import { SuccessModalContainer } from "./styles";

const SuccessModal = ({ title, message, open, closeHandler, ...state }) => {
  const modal = FormStyled();
  const classes = useStyles();

  return (
    <Dialog
      open={!!open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: modal.root }}
    >
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <DialogTitle>{title}</DialogTitle>

      <SuccessModalContainer>
        <Grid container>
          <Grid className="stakecard--bottomtextalign" item xs={12}>
            <div className="cardcontent-wrapper">
              <Text variant="p2" className="pb--16">
                {message}
              </Text>
              <Button
                className={classes.button}
                size="large"
                color="primary"
                variant="contained"
                onClick={closeHandler}
              >
                Okay
              </Button>
            </div>
          </Grid>
        </Grid>
      </SuccessModalContainer>

      <DialogActions>
        <ButtonClose onClick={closeHandler} />
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
