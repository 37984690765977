import {
  GAME_DELETE,
  GAME_VIEW_LIST,
  GAME_VIEW,
  FEATURE_VIEW,
  GAME_PUBLISHER,
} from "./types";
import * as ApiRequest from "../utils/apiRequest";
import NumbersFormat from "assist/numbersFormat";
import { getTokenFromAddress } from "blockchain";
import * as Web3Utils from "blockchain/web3Utils";

export const flattenResponse = (response) => ({
  ids: response.data.map((game) => game.id),
  byId: response.data.reduce(
    (previous, game) => ({
      ...previous,
      [game.id]: {
        id: game.id,
        ...game,
      },
    }),
    {}
  ),
});

const calculateTokenStats = (game) => (dispatch) => {
  let token = "";
  let convertedRequired = "";
  let required = "-";
  const earn = game.token_ticker || "-";
  if (!!game.game_economy_type?.required_token_address) {
    token = getTokenFromAddress(game.game_economy_type.required_token_address);
    convertedRequired = Web3Utils.getDisplayVal(
      game.game_economy_type.required_token_amount,
      token.decimals || 0
    );
    required = `${NumbersFormat(convertedRequired)} ${token.symbol}`;
  }

  const calculateAGY = () => {
    const usdRate = game.currency_schemas[0].usd_price;
    var costOfRequire;
    if (game.game_economy_type?.required_token_amount > 1) {
      // if more than one token is required, safe to say its not an NFT
      costOfRequire = convertedRequired * usdRate;
    } else {
      costOfRequire = game.temp_nfp_price;
    }
    if (!costOfRequire) return "-";

    const earnRate = usdRate / game.conversion_rate_card.earned_usd_rate;
    const tokensPerPoint = Web3Utils.round(1 / earnRate, 2);
    const maxEarnPerDay =
      game.game_economy_type?.daily_earned_currency_export_max * tokensPerPoint * usdRate;
    const AGY = (maxEarnPerDay / costOfRequire) * 365;
    const AGYasPercent = (AGY * 100).toFixed(2);

    return AGYasPercent + "%";
  };

  const AGY = calculateAGY();

  const tokenData = {
    earn,
    required,
    AGY,
    token,
  };

  return tokenData;
};

export const fetchGames = () => (dispatch) => {
  Promise.all([ApiRequest.fetchGames()]).then((responses) => {
    const responseData = responses[0];
    var flattenedData = flattenResponse(responseData);
    for (const [key, value] of Object.entries(flattenedData.byId)) {
      const tokenData = dispatch(calculateTokenStats(value));
      flattenedData.byId[key] = { ...value, ...tokenData };
    }

    dispatch({
      type: GAME_VIEW_LIST,
      games: flattenedData,
    });
  });
};

export const fetchGame = (id) => (dispatch) => {
  ApiRequest.fetchGame(id).then((response) => {
    response.data.data.attributes.id = response.data.data.id;
    dispatch({
      type: GAME_VIEW,
      currentGame: response.data.data.attributes,
    });
  });
};

export const fetchGamePublisher = (id) => (dispatch) => {
  ApiRequest.fetchGamePublisher(id).then((response) => {
    if (response.data.data) {
      dispatch({
        type: GAME_PUBLISHER,
        currentPublisher: response.data.data.attributes.name,
      });
    }
  });
};

export const fetchMarketFeatured = () => (dispatch) => {
  // fetch from DB
  ApiRequest.getMarketFeature().then((feature) => {
    if (feature.data.announcement) {
      dispatch({
        type: FEATURE_VIEW,
        data: feature.data.announcement,
        subtype: "news",
      });
    }
  });
};

export const fetchNTEGames = () => (dispatch) =>
  ApiRequest.fetchNTEGames().then((response) => {
    dispatch({
      type: GAME_VIEW_LIST,
      games: flattenResponse(response),
    });
  });

export const deleteGame = (game) => ({
  type: GAME_DELETE,
  game,
});
