import React from "react";
import Content from "../Settings/Content";

const Settings = React.memo(() => {
  return (
    <div>
      <Content />
    </div>
  );
});

export default Settings;
