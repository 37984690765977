import ReactGA from "react-ga";
import { reset } from "redux-form";
import { IS_PROD } from "utils/environments";
import {
  PUB_DASHBOARD,
  REGISTER_ERROR,
  OWNER_ORGANIZATION,
  GAME_VIEW_LIST,
  GAME_UPDATE,
  ORGANIZATION_MEMBERS,
  ORGANIZATION_INVITES,
  PUB_GAME_VIEW_LIST,
  REMOVE_IMAGE,
  SDK_PASSWORD,
  ORGANIZATION_ID,
} from "./types";
import * as ApiRequest from "../utils/apiRequest";
import * as ImgServer from "../utils/imgServer";
import { history } from "../store/index";
import { flattenResponse } from "./games";

const getMD5 = (image) => ImgServer.getImageMD5(image);
const getMD5s = (images) => ImgServer.getImageMD5s(images);

export const fetchPubGames = (id) => (dispatch) => {
  ApiRequest.fetchPubGames(id).then((response) => {
    dispatch({
      type: GAME_VIEW_LIST,
      games: flattenResponse(response),
    });
  });
};

export const fetchOrganization = () => (dispatch) =>
  ApiRequest.fetchOrganization().then((response) => {
    dispatch({
      type: OWNER_ORGANIZATION,
      currentOrganization: {
        ...(response.data.data[0] && response.data.data[0].attributes),
      },
    });
    dispatch({
      type: ORGANIZATION_ID,
      payload: response.data.data[0] && response.data.data[0].id,
    });
    return response;
  });

export const updateOrganization = (id, data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    let image = new Promise((resolve) => setTimeout(resolve, 10));
    const fd = new FormData();
    if (data.organization.logo) {
      fd.append("imageFile", data.organization.logo);
      image = getMD5(fd);
    }
    image.then((md5) => {
      const newData = JSON.parse(JSON.stringify(data));
      newData.organization.logo = "";
      if (md5 && md5.status === 200) {
        newData.organization.image_url = md5.data;
      }
      ApiRequest.updateOrganization(id, newData)
        .then((response) => {
          if (md5 && md5.status === 200) {
            fd.append("id", id);
            fd.append("type", "publisher");
            image = ImgServer.postImage(fd);
          } else {
            image = new Promise((resolve) => setTimeout(resolve, 10));
          }
          image
            .then(() => {
              dispatch({
                type: OWNER_ORGANIZATION,
                currentOrganization: response.data.data.attributes,
              });
              resolveFunction("Success");
            })
            .catch((error) => {
              rejectFunction(
                Error(`Error Occured. Failed to upload image: ${error.message}`)
              );
            });
        })
        .catch((error) => {
          rejectFunction(Error(`Error Occured. Failed to upload info: ${error.message}`));
        });
    });
  });

export const removeExistingImage = (index) => (dispatch) => {
  dispatch({
    type: REMOVE_IMAGE,
    index,
  });
};

export const fetchDashboard = (id) => (dispatch) => {
  ApiRequest.fetchOrganization(id).then((response) => {
    if (response.data.data[0]) {
      ApiRequest.fetchPubGames(response.data.data[0].id).then((response2) => {
        dispatch({
          type: PUB_DASHBOARD,
          currentOrganization: response.data.data[0],
        });
        dispatch({
          type: PUB_GAME_VIEW_LIST,
          games: flattenResponse(response2),
        });
      });
    }
    // else, consider redirecting to onboarding so they can enter company info?
  });
};

export const createSDKPassword = (id, data) => (dispatch) => {
  ApiRequest.createSDKPassword(id, data)
    .then(() => {
      history.push(data.to);
    })
    .catch(() => {
      dispatch({
        type: REGISTER_ERROR,
        registerError: "Failed to set SDK password",
      });
    });
};

export const getSDKPassword = (id) => (dispatch) => {
  ApiRequest.hasSDPKassword(id)
    .then((response) => {
      if (response.data.data === true) {
        ApiRequest.getSDKPassword(id).then((response2) => {
          dispatch({
            type: SDK_PASSWORD,
            has_password: true,
            sdk_password: response2.data.data,
          });
        });
      } else {
        dispatch({
          type: SDK_PASSWORD,
          has_password: false,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: SDK_PASSWORD,
        has_password: false,
      });
    });
};

export const registerPublisherInfo = (data) => (dispatch) => {
  const fd = new FormData();
  fd.append("imageFile", data.logo);
  getMD5(fd)
    .then((response) => {
      const imageURL = response.data;
      const newData = JSON.parse(JSON.stringify(data));
      newData.legal_acknowledgment = "true";
      newData.accepted_terms_of_service_agreement = "true";
      newData.image_url = imageURL;
      ApiRequest.registerCompanyInfo(newData)
        .then((pubResponse) => {
          fd.append("id", pubResponse.data.data.id);
          fd.append("type", "publisher");
          ImgServer.postImage(fd).then((ImageResponse) => {
            IS_PROD &&
              ReactGA.event({
                category: "Publisher",
                action: "Submitted company info",
              });
            dispatch({
              type: REGISTER_ERROR,
              registerError: "",
            });
            ApiRequest.resendEmailValidation().then(() => {
              history.push("/pub/onboarding/company/verify");
            });
          });
        })
        .catch((error) => {
          dispatch({
            type: REGISTER_ERROR,
            registerError: `Failed to update info: ${error.message}`,
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: REGISTER_ERROR,
        registerError: `Failed to update info: ${error.message}`,
      });
    });
};

const filterChipInput = (data) => {
  let retval = [];
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i += 1) {
      retval[i] = data[i].value;
    }
  } else {
    retval = [data];
  }
  return retval;
};

export const createGame = (data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    const realData = JSON.parse(JSON.stringify(data));
    const fd = new FormData();
    fd.append("imageFile", data.feature);
    getMD5(fd)
      .then((response) => {
        realData.image_url = response.data;
        realData.release_year = parseInt(realData.release_year, 10);
        realData.tsrs_content_descriptor = filterChipInput(data.tsrs_content_descriptor);
        realData.tsrs_interactive_element = filterChipInput(
          data.tsrs_interactive_element
        );
        realData.platform = filterChipInput(data.platform);
        realData.category = filterChipInput(data.category);
        realData.game_element = filterChipInput(data.game_element);
        realData.game_engine = [data.game_engine];
        ApiRequest.createGame(realData)
          .then((apiResponse) => {
            fd.append("id", apiResponse.data.data.id);
            fd.append("type", "game_feature");
            ImgServer.postImage(fd)
              .then(() => {
                IS_PROD &&
                  ReactGA.event({
                    category: "Publisher",
                    action: "Added a game",
                  });
                dispatch(reset("addGame"));
                dispatch({
                  type: REGISTER_ERROR,
                  registerError: "",
                });
                resolveFunction("Success!");
                history.push(`/pub/addGame/details?id=${apiResponse.data.data.id}`);
              })
              .catch((error) => {
                rejectFunction(Error(`Failed to upload image: ${error.message}`));
              });
          })
          .catch((error) => {
            rejectFunction(Error(`Failed to create game: ${error.message}`));
          });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_ERROR,
          registerError: `Failed to verify image: ${error.message}`,
        });
        rejectFunction(Error(` Failed to verify image: ${error.message}`));
      });
  });

export const onboardingUpdateGame = (data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    const fd = new FormData();
    fd.append("imageFile", data.game_card_background_image);
    const mfd = new FormData();
    let promises = [];
    promises[0] = getMD5(fd);
    if (Array.isArray(data.game_images)) {
      for (let i = 0; i < data.game_images.length; i += 1) {
        mfd.append("imageFiles[]", data.game_images[i]);
        promises[1] = getMD5s(mfd);
      }
    }
    const newData = JSON.parse(JSON.stringify(data));

    Promise.all(promises)
      .then((responses) => {
        if (
          responses[0].status !== 200 ||
          (Array.isArray(data.game_images) && responses[1].status !== 200)
        ) {
          dispatch({
            type: REGISTER_ERROR,
            registerError: "Failed to upload image",
          });
        }
        newData.game_card_background_image = responses[0].data;
        newData.additional_images_url = Array.isArray(data.game_images)
          ? responses[1].data
          : [];
        ApiRequest.updateGame(newData)
          .then(() => {
            promises = [];
            fd.append("id", data.id);
            fd.append("type", "game_background");
            promises[0] = ImgServer.postImage(fd);
            if (Array.isArray(data.game_images)) {
              mfd.append("id", data.id);
              mfd.append("type", "game_images");
              promises[1] = ImgServer.postImage(mfd);
            }
            Promise.all(promises)
              .then(() => {
                resolveFunction("Success");
                dispatch({
                  type: REGISTER_ERROR,
                  registerError: "",
                });
              })
              .catch((error) => {
                rejectFunction(Error(`Failed to upload image ${error.message}`));
                dispatch({
                  type: REGISTER_ERROR,
                  registerError: `Error Occured. Failed to update game info: ${error.message}`,
                });
              });
          })
          .catch((error) => {
            rejectFunction(Error(`Failed to update game info ${error.message}`));
            dispatch({
              type: REGISTER_ERROR,
              registerError: `Error Occured. Failed to update game info: ${error.message}`,
            });
          });
      })
      .catch((error) => {
        rejectFunction(Error(`Failed to upload image ${error.message}`));
        dispatch({
          type: REGISTER_ERROR,
          registerError: `Error Occured. Failed to update game info: ${error.message}`,
        });
      });
  });

export const onboardingSimpleUpdateGame = (data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    ApiRequest.updateGame(data)
      .then(() => {
        resolveFunction("Success");
        dispatch({
          type: REGISTER_ERROR,
          registerError: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_ERROR,
          registerError: "Failed to update information",
        });
        rejectFunction(
          Error(`Error Occured. Failed to update game info: ${error.message}`)
        );
      });
  });

export const createGameVault = (id, data, type) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    const newData = JSON.parse(JSON.stringify(data));
    Object.keys(newData).forEach((key) => {
      newData[key] = Number(newData[key]);
    });
    const finalData = {};
    finalData[`${type}_currency_vault`] = newData;
    ApiRequest.createGameVault(id, finalData, type)
      .then(() => {
        resolveFunction("Success");
        dispatch({
          type: REGISTER_ERROR,
          registerError: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_ERROR,
          registerError: `Error Occured. Failed to create game vault info: ${error.message}`,
        });
        rejectFunction(
          Error(`Error Occured. Failed to create game vault info: ${error.message}`)
        );
      });
  });

export const onboardingUpdateCurrency = (data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    const fd = new FormData();
    fd.append("imageFile", data.image);
    getMD5(fd)
      .then((response) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData[data.image_name] = response.data;
        ApiRequest.updateGame(newData)
          .then((apiResponse) => {
            fd.append("id", data.id);
            let type = "";
            switch (data.image_name) {
              case "earned_currency_image":
                type = "earned_currency";
                break;
              case "premium_currency_image":
                type = "premium_currency";
                break;
              default:
                break;
            }
            fd.append("type", type);
            ImgServer.postImage(fd)
              .then(() => {
                resolveFunction("Success");
                dispatch({
                  type: REGISTER_ERROR,
                  registerError: "",
                });
                dispatch(reset("addGame"));
              })
              .catch((error) => {
                dispatch({
                  type: REGISTER_ERROR,
                  registerError: `Error Occured. Failed to update game info: ${error.message}`,
                });
                rejectFunction(
                  Error(`Error Occured. Failed to upload an image: ${error.message}`)
                );
              });
          })
          .catch((error) => {
            dispatch({
              type: REGISTER_ERROR,
              registerError: `Error Occured. Failed to update game info: ${error.message}`,
            });
            rejectFunction(
              Error(`Error Occured. Failed to update game info: ${error.message}`)
            );
          });
      })
      .catch((error) => {
        dispatch({
          type: REGISTER_ERROR,
          registerError: `Error Occured. Failed to update game info: ${error.message}`,
        });
        rejectFunction(
          Error(`Error Occured. Failed to upload an image: ${error.message}`)
        );
      });
  });

export const updateGame = (data) => (dispatch) =>
  new Promise((resolveFunction, rejectFunction) => {
    let premiumCurrency = new Promise((resolve) => setTimeout(resolve, 10));
    let earnedCurrency = new Promise((resolve) => setTimeout(resolve, 10));
    let feature = new Promise((resolve) => setTimeout(resolve, 10));
    let background = new Promise((resolve) => setTimeout(resolve, 10));
    let featureBackground = new Promise((resolve) => setTimeout(resolve, 10));
    let featureCharacter = new Promise((resolve) => setTimeout(resolve, 10));
    let gameImages = new Promise((resolve) => setTimeout(resolve, 10));

    const newData = JSON.parse(JSON.stringify(data));
    delete newData.status;
    delete newData.tap_enabled;
    const fd1 = new FormData();
    const fd2 = new FormData();
    const fd3 = new FormData();
    const fd4 = new FormData();
    const fd5 = new FormData();
    const fd6 = new FormData();
    const mfd = new FormData();
    if (data.premium_currency_file) {
      fd1.append("imageFile", data.premium_currency_file);
      premiumCurrency = getMD5(fd1);
    }
    if (data.earned_currency_file) {
      fd2.append("imageFile", data.earned_currency_file);
      earnedCurrency = getMD5(fd2);
    }
    if (data.feature_file) {
      fd3.append("imageFile", data.feature_file);
      feature = getMD5(fd3);
    }
    if (data.background_file) {
      fd4.append("imageFile", data.background_file);
      background = getMD5(fd4);
    }
    if (data.feature_background_file) {
      fd5.append("imageFile", data.feature_background_file);
      featureBackground = getMD5(fd5);
    }
    if (data.feature_character_file) {
      fd6.append("imageFile", data.feature_character_file);
      featureCharacter = getMD5(fd6);
    }
    if (data.game_images) {
      for (let i = 0; i < data.game_images.length; i += 1) {
        mfd.append("imageFiles[]", data.game_images[i]);
      }
      gameImages = getMD5s(mfd);
    }
    Promise.all([
      premiumCurrency,
      earnedCurrency,
      feature,
      background,
      featureBackground,
      featureCharacter,
      gameImages,
    ])
      .then((md5s) => {
        if (md5s[0] && md5s[0].status === 200) {
          newData.premium_currency_image = md5s[0].data;
        }
        if (md5s[1] && md5s[1].status === 200) {
          newData.earned_currency_image = md5s[1].data;
        }
        if (md5s[2] && md5s[2].status === 200) {
          newData.image_url = md5s[2].data;
        }
        if (md5s[3] && md5s[3].status === 200) {
          newData.game_card_background_image = md5s[3].data;
        }
        if (md5s[4] && md5s[4].status === 200) {
          newData.feature_background_url = md5s[4].data;
        }
        if (md5s[5] && md5s[5].status === 200) {
          newData.feature_character_url = md5s[5].data;
        }
        if (md5s[6] && md5s[6].status === 200) {
          Array.prototype.push.apply(newData.additional_images_url, md5s[6].data);
        }

        if (data.release_year) {
          newData.release_year = parseInt(data.release_year, 10);
        }
        if (data.platform) {
          newData.platform = filterChipInput(data.platform);
        }
        if (data.category) {
          newData.category = filterChipInput(data.category);
        }
        if (data.game_element) {
          newData.game_element = filterChipInput(data.game_element);
        }

        ApiRequest.updateGame(newData)
          .then((response) => {
            if (data.game_economy_type && data.daily_user_import_count_max) {
              const vaultData = {
                data: {
                  type: `${data.game_economy_type}_currency_vault`,
                  attributes: {
                    daily_earned_currency_export_max: Number(
                      data.daily_earned_currency_export_max
                    ),
                    daily_earned_currency_import_max: Number(
                      data.daily_earned_currency_import_max
                    ),
                    daily_premium_currency_export_max: Number(
                      data.daily_premium_currency_export_max
                    ),
                    daily_premium_currency_import_max: Number(
                      data.daily_premium_currency_import_max
                    ),
                    daily_user_export_count_max: Number(data.daily_user_export_count_max),
                    daily_user_import_count_max: Number(data.daily_user_import_count_max),
                  },
                },
              };
              if (data.game_economy_type === "balanced") {
                vaultData.data.attributes.earned_currency_max = Number(
                  data.earned_currency_max
                );
                vaultData.data.attributes.premium_currency_max = Number(
                  data.premium_currency_max
                );
                vaultData.data.attributes.earned_currency_min = Number(
                  data.earned_currency_min
                );
                vaultData.data.attributes.premium_currency_min = Number(
                  data.premium_currency_min
                );
              }
              ApiRequest.updateGameVault(
                data.id,
                vaultData,
                data.game_economy_type
              ).catch(() => {
                const createVaultData = {};
                createVaultData[`${data.game_economy_type}_currency_vault`] =
                  vaultData.data.attributes;
                ApiRequest.createGameVault(
                  data.id,
                  createVaultData,
                  data.game_economy_type
                );
              });
            }

            if (md5s[0] && md5s[0].status === 200) {
              fd1.append("id", data.id);
              fd1.append("type", "premium_currency");
              premiumCurrency = ImgServer.postImage(fd1);
            }
            if (md5s[1] && md5s[1].status === 200) {
              fd2.append("id", data.id);
              fd2.append("type", "earned_currency");
              earnedCurrency = ImgServer.postImage(fd2);
            }
            if (md5s[2] && md5s[2].status === 200) {
              fd3.append("id", data.id);
              fd3.append("type", "game_feature");
              feature = ImgServer.postImage(fd3);
            }
            if (md5s[3] && md5s[3].status === 200) {
              fd4.append("id", data.id);
              fd4.append("type", "game_background");
              background = ImgServer.postImage(fd4);
            }
            if (md5s[4] && md5s[4].status === 200) {
              fd5.append("id", data.id);
              fd5.append("type", "game_feature_background");
              featureBackground = ImgServer.postImage(fd5);
            }
            if (md5s[5] && md5s[5].status === 200) {
              fd6.append("id", data.id);
              fd6.append("type", "game_feature_character");
              featureCharacter = ImgServer.postImage(fd6);
            }
            if (md5s[6] && md5s[6].status === 200) {
              mfd.append("id", data.id);
              mfd.append("type", "game_images");
              gameImages = ImgServer.postImage(mfd);
            }

            Promise.all([
              premiumCurrency,
              earnedCurrency,
              feature,
              background,
              featureBackground,
              featureCharacter,
              gameImages,
            ])
              .then((success) => {
                dispatch({
                  type: GAME_UPDATE,
                  message: "Game Updated Sucessfully",
                });
                resolveFunction("Info Updated");
              })
              .catch((error) => {
                dispatch({
                  type: GAME_UPDATE,
                  message: `Failed to upload an image: ${error.message}`,
                });
                rejectFunction(
                  Error(`Error Occured. Failed to upload an image: ${error.message}`)
                );
              });
          })
          .catch((error) => {
            dispatch({
              type: GAME_UPDATE,
              message: `Failed to update Game Info: ${error.message}`,
            });
            rejectFunction(
              Error(`Error Occured. Failed to upload game info: ${error.message}`)
            );
          });
      })
      .catch((error) => {
        dispatch({
          type: GAME_UPDATE,
          message: `Failed to update Game Info: ${error.message}`,
        });
        rejectFunction(
          Error(`Error Occured. Failed to upload game info: ${error.message}`)
        );
      });
  });

export const fetchOrgMemberInvites = (id) => (dispatch) =>
  ApiRequest.fetchOrgMemberInvites(id).then((response) => {
    const payload = response.data.data.reduce(
      (col, invitee) => [...col, { ...invitee.attributes, invite_id: invitee.id }],
      []
    );
    dispatch({
      type: ORGANIZATION_INVITES,
      payload,
    });
  });

export const pubInviteNewMember =
  (...args) =>
  () =>
    ApiRequest.pubInviteNewMember(...args);

export const cancelInviteNewMember = (orgId, inviteId) => (dispatch) =>
  ApiRequest.cancelInviteNewMember(orgId, inviteId).then(
    fetchOrgMemberInvites(orgId)(dispatch)
  );

export const resendInviteNewMember = (orgId, data) => () =>
  ApiRequest.resendInviteNewMember(orgId, data);

export const fetchOrganizationMembers = (id) => (dispatch) =>
  ApiRequest.fetchOrganizationMembers(id).then((response) => {
    const payload = response.data.data.reduce(
      (col, member) => [...col, { ...member.attributes, member_id: member.id }],
      []
    );
    dispatch({
      type: ORGANIZATION_MEMBERS,
      payload,
    });
  });

export const updateOrganizationMember = (orgId, memberId, data) => (dispatch) =>
  ApiRequest.updateOrganizationMember(orgId, memberId, data).then(() => {
    fetchOrganizationMembers(orgId)(dispatch);
  });

export const fetchAllOrganizationData = () => (dispatch) =>
  fetchOrganization()(dispatch).then((response) =>
    Promise.all([
      fetchOrganizationMembers(response.data.data[0] && response.data.data[0].id)(
        dispatch
      ),
      fetchOrgMemberInvites(response.data.data[0] && response.data.data[0].id)(dispatch),
    ])
  );
