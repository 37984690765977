import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { createVault, fetchGameVault } from "actions/index";
import { Grid, Button, Paper, Typography, makeStyles } from "@material-ui/core";
import GamePlayButtons from "components/containers/GamePlayButtons";
import Text from "components/Text";
import MainSlider from "./MainSlider";
import SubSlider from "./SubSlider";
import VaultCard from "components/Cards/VaultCard";
import TOKEN_INFO from "blockchain/tokenInfo";
import MaxLinesTextContainer from "components/MaxLinesTextContainer";
import * as ImgServer from "utils/imgServer";
import { ReactComponent as CloudIcon } from "assets/icons/cloud.svg";
import { ReactComponent as SinglePlayerIcon } from "assets/icons/singlePlayer.svg";
import { ReactComponent as Discord } from "assets/icons/discord.svg";
import { ReactComponent as Telegram } from "assets/icons/telegram.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: "15px 15px 10px",
    width: "100%",
    borderRadius: "15px",
  },
  sidebarPaper: {
    backgroundColor: "#29303c",
    padding: 16,
  },
  title: {
    fontWeight: "700",
    marginBottom: "10px",
  },
  topDescription: {
    padding: "14px",
    flex: "1 1 auto",
    gap: "10px",
  },
  topPaper: {
    backgroundColor: "#29303c",
    borderRadius: "15px",
    "& img": {
      borderRadius: "15px 15px 0 0",
    },
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0 0 0 20px",

    "@media (max-width: 960px)": {
      flexDirection: "row",
      margin: "0 0 20px 0",
      "& img": {
        borderRadius: "15px 0 0 15px",
      },
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      "& img": {
        borderRadius: "15px 15px 0 0",
      },
    },
  },
  topImage: {
    width: "100%",
    height: "230px",
    objectFit: "fill",
    "@media (max-width: 960px)": {
      height: "100%",
    },
  },
  button: {
    marginTop: "16px",
  },
  subSlider: {
    maxWidth: "inherit",
    marginTop: "10px",
  },
  flexReverse: {
    flexDirection: "row",
    height: "auto",
    minHeight: "400px",
    "@media (max-width: 960px)": {
      flexDirection: "column-reverse",
    },
  },
  tags: {
    padding: "10px 16px",
    display: "flex",
    gap: "10px",
    marginBottom: "5px",
    alignItems: "flex-end",
    "&:last-child": {},
  },
  AGY: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px",
    "& :first-child": {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: "#808191",
    },
    "& :last-child": {
      fontWeight: "700",
      fontSize: "16px",
      fontFamily: "Work Sans",
    },
  },
  gameContainer: {
    maxWidth: "1600px",
    minWidth: "360px",
    width: "85vw",
    "@media (max-width: 480px)": {
      width: "95vw",
    },
  },
  svg: {
    fill: "transparent !important",
    marginRight: "16px",
  },
  social: {
    "& svg": {
      width: "24px",
      height: "20px",
      "& path": {
        fill: "#fff",
      },
    },
  },
}));
const Content = React.memo(() => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [nav, setNav] = useState();

  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const vaults = useSelector((state) => state.vaults);
  const vault = vaults?.currentVaults;
  const user = useSelector((state) => state.user);
  const games = useSelector((state) => state.games);
  const game = games?.byId[params?.id] || {};
  const defaultCurrency = game?.currency_schemas?.[0];
  const currencyRate = defaultCurrency?.usd_price || false;
  const symbol = TOKEN_INFO[defaultCurrency?.contract_name]?.symbol || "???";
  const premiumImg = ImgServer.gameImage(game, "premium", game.premium_currency_image);
  const earnedImg = ImgServer.gameImage(game, "earned", game.earned_currency_image);
  const socials = [
    { name: "Twitter", icon: <Twitter />, link: "https://twitter.com/gamex_change" },
    { name: "Discord", icon: <Discord />, link: "https://discord.gg/fBpj8qP" },
    { name: "Telegram", icon: <Telegram />, link: "https://t.me/GameXChange" },
  ];

  useEffect(() => {
    dispatch(fetchGameVault(game.id));
  }, []);

  if (!game) {
    return;
  }

  const renderVaultButtons = () => {
    // vault exists
    if (vaults?.currentVault && vaults?.currentVault?.id) {
      return (
        <Grid item xs={12}>
          <Text variant="h6">Convert Currency</Text>
          <Text variant="caption" color="textSecondary" className="normal">
            Turn your extra currency into Gold!
          </Text>
          <Button
            fullWidth
            component={Link}
            to={`/vaults/${params.id}`}
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
          >
            Go to Vault
          </Button>
        </Grid>
      );
    }

    // not logged in
    if (!user.isLoggedIn) {
      return (
        <Grid item xs={12}>
          <Text variant="h6">Add to account</Text>
          <Text variant="caption" color="textSecondary">
            Login to add this game to your account
          </Text>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to="/sign-in"
            size="large"
          >
            Login
          </Button>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <Text variant="h6">Add to account</Text>
        <Text variant="caption" color="textSecondary" className="normal">
          Required to convert game currencies
        </Text>
        <Button
          className={classes.button}
          fullWidth
          onClick={() => createVault(params.id)}
          variant="contained"
          color="primary"
          size="large"
        >
          Create Vault
        </Button>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid
        container
        direction="column"
        className={`${classes.gameContainer} game-details`}
        spacing={2}
      >
        <Grid item container>
          <Text variant="h3" className={classes.title}>
            {game.title}
          </Text>
          <Paper className={classes.paperContainer}>
            <Grid
              container
              justifyContent="space-between"
              xs={12}
              className={classes.flexReverse}
            >
              <Grid item container md={8} xs={12}>
                <div
                  style={{
                    width: "100%",
                    minHeight: "250px",
                  }}
                >
                  <MainSlider
                    setNav={setNav}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                  />
                </div>
              </Grid>
              <Grid item container md={4} xs={12}>
                <Paper className={classes.topPaper}>
                  <Grid item container>
                    <img src={game.image_url} className={classes.topImage} />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.topDescription}
                    justifyContent="space-between"
                  >
                    <MaxLinesTextContainer
                      maxLines={6}
                      styledComponent={Typography}
                      text={game.short_description}
                      variant="subtitle2"
                    />
                    {!!game?.AGY && (
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.AGY}
                      >
                        <Typography variant="caption">Annual Gaming Yield:</Typography>
                        <Typography variant="body2" style={{ fontFamily: "Work Sans" }}>
                          {game?.AGY || "-"}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <div className={classes.subSlider}>
              <SubSlider nav={nav} setIsPlaying={setIsPlaying} />
            </div>
          </Paper>
        </Grid>
        <Grid item container>
          <Paper className={classes.paperContainer}>
            <Grid container direction="row" justifyContent="center" spacing={3}>
              <Grid item md={9} xs={12}>
                <Typography variant="h5">ABOUT THIS GAME</Typography>
                <Text variant="subtitle2">{game.full_description}</Text>
              </Grid>
              <Grid item container direction="column" md={3} xs={12} spacing={1}>
                <Grid item>
                  <Paper className={classes.sidebarPaper} elevation={0} square>
                    {renderVaultButtons()}
                    {game.platform && <GamePlayButtons game={game} />}
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.sidebarPaper}>
                    <Paper className={classes.tags}>
                      <SinglePlayerIcon />
                      <Text variant="subtitle1">Single-Player</Text>
                    </Paper>
                    <Paper className={classes.tags}>
                      <CloudIcon />
                      <Text variant="subtitle1">Cloud</Text>
                    </Paper>
                    {game?.category.map((cat) => (
                      <Paper className={classes.tags}>
                        <Text variant="subtitle1">{cat}</Text>
                      </Paper>
                    ))}
                  </Paper>
                </Grid>

                <Grid item>
                  {game.conversion_rate_card && (
                    <Paper className={classes.sidebarPaper}>
                      <VaultCard
                        toSymbol={symbol}
                        premium_name={game.premium_currency_name}
                        premium_value={
                          currencyRate
                            ? currencyRate / game.conversion_rate_card.premium_usd_rate
                            : false
                        }
                        premium_amount={vault?.premium_currency || 0}
                        earned_name={game.earned_currency_name}
                        earned_value={
                          currencyRate
                            ? currencyRate / game.conversion_rate_card.earned_usd_rate
                            : false
                        }
                        earned_amount={vault?.earned_currency || 0}
                        premium_img={premiumImg}
                        earned_img={earnedImg}
                      />
                    </Paper>
                  )}
                </Grid>
                <Grid item>
                  <Paper className={classes.sidebarPaper}>
                    {socials.map((social) => (
                      <a href={social.link}>
                        <Paper
                          key={social.name}
                          className={classes.tags}
                          classes={{ root: classes.social }}
                        >
                          <div>{social.icon}</div>
                          <Text variant="subtitle1">{social.name}</Text>
                        </Paper>
                      </a>
                    ))}
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.sidebarPaper}>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                      <Text
                        variant="subtitle2"
                        color="textSecondary"
                        className="bold"
                        style={{ marginRight: "10px" }}
                      >
                        TITLE:
                      </Text>
                      <Text
                        variant="body1"
                        className="semi-bold"
                        style={{ alignSelf: "center" }}
                      >
                        {game.title}
                      </Text>
                    </Grid>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                      <Text
                        variant="subtitle2"
                        color="textSecondary"
                        className="bold"
                        style={{ marginRight: "10px" }}
                      >
                        GENRE:
                      </Text>
                      <Text
                        variant="body1"
                        className="semi-bold"
                        style={{ alignSelf: "center" }}
                      >
                        {(!!game?.category && game?.category[0]) || "-"}
                      </Text>
                    </Grid>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                      <Text
                        variant="subtitle2"
                        color="textSecondary"
                        className="bold"
                        style={{ marginRight: "10px" }}
                      >
                        DEVELOPER:
                      </Text>
                      <Text
                        variant="body1"
                        className="semi-bold"
                        style={{ alignSelf: "center" }}
                      >
                        {game?.developer || "Game X Change"}
                      </Text>
                    </Grid>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                      <Text
                        variant="subtitle2"
                        color="textSecondary"
                        className="bold"
                        style={{ marginRight: "10px" }}
                      >
                        PUBLISHER:
                      </Text>
                      <Text
                        variant="body1"
                        className="semi-bold"
                        style={{ alignSelf: "center" }}
                      >
                        {game?.publisher || "Game X Change"}
                      </Text>
                    </Grid>
                    <Grid container item justifyContent="space-between" wrap="nowrap">
                      <Text
                        variant="subtitle2"
                        color="textSecondary"
                        className="bold"
                        style={{ marginRight: "10px" }}
                      >
                        RELEASED:
                      </Text>
                      <Typography style={{ alignSelf: "center" }}>
                        {game.release_year}
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <a href={game.privacy_policy_url}>
                    <Paper className={classes.sidebarPaper}>
                      <Paper className={classes.tags}>
                        <SinglePlayerIcon />
                        <Text variant="subtitle1" style={{ fontSize: "16px" }}>
                          Terms of Service
                        </Text>
                      </Paper>
                    </Paper>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Content;
