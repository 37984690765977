import React, { memo, useState, useEffect } from "react";
import { Button, CircularProgress } from "@material-ui/core";

const ExchangeButton = memo(
  ({
    badNetwork,
    connectHandler,
    approveHandler,
    swapHandler,
    connected,
    loading,
    toAmount,
    fromAmount,
    fromBalance,
    toBalance,
    toApproval,
    fromApproval,
    fromCurrency,
    toCurrency,
    adding,
    removing,
    success,
    noTrade,
    // tokenSymbol,
    // userWalletAllowance,
    // currentTokenPrice,
    // allowanceHandler,
    // mintHandler,
    // userBalance,
    // paymentToken,
    // hasParticipationAmount,
    // participationAmount,
    // participationName,
    // needsGXP,
  }) => {
    const [buttonText, setButtonText] = useState("");
    const [disabled, setDisabled] = useState(false);

    const onClickHandler = (e) => {
      e.stopPropagation();
      if (!connected) {
        connectHandler();
      } else if (fromAmount > fromApproval) {
        approveHandler(fromCurrency);
      } else if (adding && toAmount > toApproval) {
        approveHandler(toCurrency);
      } else {
        swapHandler();
      }
    };

    useEffect(() => {
      if (loading) {
        setButtonText(<CircularProgress color="inherit" />);
        setDisabled(true);
      } else if (!connected) {
        setButtonText("Unlock Wallet");
        setDisabled(false);
      } else if (badNetwork) {
        setButtonText("Check your network");
        setDisabled(true);
      } else if ((!removing && !toCurrency) || !fromCurrency) {
        setButtonText("Invalid pair");
        setDisabled(true);
      } else if ((!removing && !toAmount) || !fromAmount) {
        if (!removing && noTrade && (toAmount || fromAmount)) {
          setButtonText("No trade route found");
        } else {
          setButtonText("Enter an amount");
        }
        setDisabled(true);
      } else if (fromAmount > fromBalance) {
        setButtonText(`Insufficient ${fromCurrency.symbol} balance`);
        setDisabled(true);
      } else if (adding ? toAmount > toBalance : toAmount < toBalance) {
        if (toAmount > toBalance) {
          setButtonText(`Insufficient ${toCurrency.symbol} balance`);
        } else {
          setButtonText("Insufficient TO balance");
        }
        setDisabled(true);
      } else if (fromAmount > fromApproval) {
        setButtonText(`Approve ${fromCurrency.symbol}`);
        setDisabled(false);
      } else if (adding && toAmount > toApproval) {
        setButtonText(`Approve ${toCurrency.symbol}`);
        setDisabled(false);
      } else if (success) {
        setButtonText("Success!");
        setDisabled(false);
      } else if (noTrade) {
        setButtonText("No trade route found");
        setDisabled(true);
      } else {
        setButtonText("Go!");
        setDisabled(false);
      }
    }, [
      loading,
      connected,
      badNetwork,
      toAmount,
      fromAmount,
      toBalance,
      fromBalance,
      toApproval,
      fromApproval,
      toCurrency?.symbol,
      fromCurrency?.symbol,
      adding,
    ]);
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => onClickHandler(e)}
        disabled={disabled}
        fullWidth
      >
        {buttonText}
      </Button>
    );
  }
);

export default ExchangeButton;
