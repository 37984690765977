import React, { PureComponent } from "react";
import { Button, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { renderTextField } from "utils/form";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Required";
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_address)) {
    errors.email_address = "Invalid email address";
  }
  return errors;
};

class ChangeEmailForm extends PureComponent {
  render() {
    const { error } = this.props;
    return (
      <div className="modal-570">
        <Field
          component={renderTextField}
          label="New Email Address"
          name="email_address"
          fullWidth
        />
        <Field
          component={renderTextField}
          label="Enter Password"
          name="password"
          type="password"
          className="mt--48"
          fullWidth
        />
        <Button
          className="mt--48"
          variant="outlined"
          fullWidth
          onClick={this.props.handleSubmit(this.props.onSubmit)}
        >
          Change Email Address
        </Button>
        {error && (
          <Typography className="text__red text--centered" variant="body2">
            {error}
          </Typography>
        )}
        <Button onClick={this.props.onClose} className="mt--24" fullWidth>
          Cancel
        </Button>
      </div>
    );
  }
}

export default reduxForm({ form: "changeEmail", validate })(ChangeEmailForm);
