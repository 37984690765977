import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearRedirect } from "../reducers/redux/Redirect/actions";

export default function UseRedirect() {
  const history = useHistory();
  const redirect = useSelector(({ Redirect }) => Redirect.to);
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }

    dispatch(clearRedirect());
  }, [redirect, dispatch, history]);

  return <></>;
}
