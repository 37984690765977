import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
//import SettingsModal from "./SettingsModal";
import SettingsIcon from "@material-ui/icons/Settings";
import { FlexColumn } from "components/Exchange/styles";
import ExchangeSettingsModalContainer from "../Modal/ExchangeSettingsModal";

const GlobalSettings = () => {
  const [openModalExchangeSettings, setOpenModalExchangeSettings] = useState(null);

  const rejectHandler = () => {
    setOpenModalExchangeSettings(null);
  };

  return (
    <>
      <FlexColumn>
        <IconButton onClick={() => setOpenModalExchangeSettings(true)}>
          <SettingsIcon />
        </IconButton>
      </FlexColumn>

      {!!openModalExchangeSettings && (
        <ExchangeSettingsModalContainer
          open={!!openModalExchangeSettings}
          rejectHandler={rejectHandler}
        />
      )}
    </>
  );
};

export default GlobalSettings;
