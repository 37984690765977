import { ADD_PAYMENT_CONTRACT } from "actions/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAYMENT_CONTRACT: {
      return { ...state, [action.data.contractAddress]: { ...action.data } };
    }
    default: {
      return state;
    }
  }
};
