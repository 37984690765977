import React from "react";
import Logo from "../Logo/Logo";
import Content from "../Staking/Archive";

const Achievement = React.memo(() => {
  return (
    <div>
      <Logo />
      <Content />
    </div>
  );
});

export default Achievement;
