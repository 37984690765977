import { TOKEN_INFO } from "blockchain/tokenInfo";

export const getAddressOfTokenByName = (name, chainId) => {
  return TOKEN_INFO?.[name].addresses[chainId];
};

export const getBalanceOfTokenByName = (name, chainId, balances) => {
  return balances?.[TOKEN_INFO[name].addresses[chainId]];
};

export const getAllowanceOfTokenByName = (name, to, chainId, allowances) => {
  try {
    return allowances?.[TOKEN_INFO[name].addresses[chainId]][to];
  } catch {
    return 0;
  }
};
