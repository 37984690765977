import React from "react";
import { Link } from "react-router-dom";
import { Grid, Paper } from "@material-ui/core";
import Fillbar from "components/utils/Fillbar";
import Image from "components/utils/Image";
import Text from "components/Text";

const LevelCard = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <Paper className="wallet-level-card">
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5} sm={4}>
                <Link to="/experience" className="badge">
                  <Image
                    alt="badge"
                    src={`https://img.tapplatform.io/stat/icons/levels/${Math.ceil(
                      props.level / 4
                    )}-72.png`}
                    src2="https://img.tapplatform.io/stat/icons/levels/1-72.png"
                  />
                  <Image
                    alt="badge"
                    src={`https://img.tapplatform.io/stat/icons/badge/${props.badge}.png`}
                    src2="https://img.tapplatform.io/stat/icons/badge/010-space-invaders.png"
                  />
                </Link>
              </Grid>
              <Grid item xs={7} sm={8} className="mt--32">
                <Text variant="h5sub" inline className="mr--4 ml--4">
                  LEVEL
                </Text>
                <Text variant="h4" inline className="mr--8">
                  {props.level || "1"}
                </Text>
                <Text variant="h4" inline className="mr--8">
                  |
                </Text>
                <Text variant="h4" inline className="mr--4">
                  {props.class || "C"}
                </Text>
                <Text variant="h5sub" inline>
                  Class
                </Text>
                <Fillbar progress={(props.current / props.total) * 100} />
                <Text variant="h6" inline className="ml--4">
                  {props.current || "0"} /{" "}
                </Text>
                <Text variant="h6" inline color="textSecondary">
                  {props.total || "0"}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

LevelCard.propTypes = {};

LevelCard.defaultProps = {};

export default LevelCard;
