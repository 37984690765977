import { Typography, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Avatar from "./Avatar";
import IconDoubleText from "./IconDoubleText";
import {
  FlexRow,
  FlexColumn,
  ImgWrapper,
  AvatarWrapper,
  Shadow,
  BottomBar,
  ProfileHeaderContent,
  UserInfo,
  BottomBarContent,
} from "./styles";
import mysteryAvatar from "images/profile/mysteryAvatar.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "30px",
    zIndex: 0,
    justifyContent: "center",
  },
}));

const ProfileHeader = React.memo(({ backgroundImage }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const d = new Date(user.createdAt);

  return (
    <ImgWrapper>
      <img src={backgroundImage} alt="" />
      <Shadow />
      <BottomBar />
      <ProfileHeaderContent>
        <AvatarWrapper>
          <Avatar image={user.avatar ?? mysteryAvatar} />
        </AvatarWrapper>
        <FlexColumn>
          <UserInfo>
            <FlexColumn>
              <Typography variant="h2">{user.username}</Typography>
              <Typography variant="caption" style={{ color: "grey" }}>
                Member since {d.toDateString()}
              </Typography>

              <FlexRow style={{ marginTop: "10px" }}>
                {!!user?.steam_name && (
                  <Typography style={{ marginRight: "30px" }}>
                    {user.steam_name}
                  </Typography>
                )}
              </FlexRow>
            </FlexColumn>
          </UserInfo>
          <BottomBarContent>
            <IconDoubleText
              image={mysteryAvatar}
              description="TOURNAMENT WINS"
              value="0"
            />
            <IconDoubleText image={mysteryAvatar} description="PROFILE VIEWS" value="0" />
            <IconDoubleText
              image={mysteryAvatar}
              description="TOTAL CURRENCY CONVERTED"
              value="0"
            />
            <IconDoubleText
              image={mysteryAvatar}
              description="REFERRAL SCORE"
              value="0"
            />
          </BottomBarContent>
        </FlexColumn>
      </ProfileHeaderContent>
    </ImgWrapper>
  );
});

export default ProfileHeader;
