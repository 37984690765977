import styled from "styled-components";
import breakpoints from "utils/breakpoints";
import { Typography, Grid } from "@material-ui/core";
import { Flex } from "../../../styles";

export const SponsoredNFTContainer = styled(Grid)`
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  width: 100%;
  height: 670px;
  padding: 20px;
  object-fit: cover;
  box-sizing: border-box;
  color: white;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 4px 2px 10px 3px rgb(0 0 0 / 30%), 8px 0px 8px 0px rgb(0 0 0 / 14%),
    12px 0px 12px 0px rgb(0 0 0 / 12%) !important;
`;

export const NFTImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  object-fit: cover;
  border-radius: 25px;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
`;

export const NFTVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  object-fit: cover;
  border-radius: 25px;
  z-index: -1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
`;

export const WideNFT = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  height: 210px;
  object-fit: cover;
  box-sizing: border-box;
  color: white;
  margin: 0 20px 20px 0;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 4px 2px 10px 3px rgb(0 0 0 / 30%), 8px 0px 8px 0px rgb(0 0 0 / 14%),
    12px 0px 12px 0px rgb(0 0 0 / 12%) !important;
`;

export const NftText = styled(Typography)`
  line-height: 24px !important;
`;

export const NftDesc = styled.span`
  font-size: 14px;
  font-family: "Work Sans";
  color: #808191;
`;

export const NftBold = styled(NftText)`
  font-weight: 600 !important;
`;

export const NftCount = styled.div`
  display: flex;
`;

export const NftActions = styled(Flex)`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  & > * {
    width: 100%;
    margin-bottom: 5px;
  }
  & > Button {
    margin-bottom: 5px;
    margin-right: 15px;
    width: max-content;
  }
`;
export const NftImg = styled.img`
  border-radius: 5px;
`;

export const NftTextBox = styled(Grid)`
  padding: 5px 20px 5px 20px;
  & > * {
    padding-top: 5px;
  }
`;

export const NftItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: white;
  margin: 0 20px 20px 0;
  border-radius: 25px;
  background-color: #12161f !important;
  box-shadow: 4px 2px 10px 3px rgb(0 0 0 / 30%), 8px 0px 8px 0px rgb(0 0 0 / 14%),
    12px 0px 12px 0px rgb(0 0 0 / 12%) !important;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  @media (max-width: ${breakpoints.sm}px) {
    width: 300px;
  }
`;

export const NftWrapper = styled(Grid)`
  position: relative;
  text-align: center;
  > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const NftBadge = styled.div`
  text-align: center;
  background-color: #484282;
  color: #fff;
  display: inline-block;
  border-radius: 8px;
  padding: 3px 20px 3px 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  max-width: max-content;
`;
