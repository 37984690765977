import { CLOSE_NFT_MODAL, OPEN_NFT_MODAL } from "./types";

const initialState = {
  data: null,
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NFT_MODAL:
      return {
        ...state,
        data: action.data,
      };

    case CLOSE_NFT_MODAL:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default ModalReducer;
