import fonts from "utils/fonts";
import fontWeights from "utils/fontWeights";
import Color from "color";
import breakpoints from "../utils/breakpoints";
import colors from "../utils/colors";

export default {
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontWeightLight: fontWeights.LIGHT,
    fontWeightRegular: fontWeights.REGULAR,
    fontWeightMedium: fontWeights.MEDIUM,
    fontWeightBold: fontWeights.BOLD,
    fontFamily: fonts.INTER,
    h1: {
      fontSize: 48,
      fontWeight: fontWeights.BOLD,
      fontFamily: fonts.POPPINS,
    },
    h2: {
      fontSize: 42,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.MEDIUM,
    },
    h3: {
      fontSize: 32,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.BOLD,
    },
    h4: { fontSize: 26, fontWeight: fontWeights.BOLD },
    h5: {
      fontSize: 20,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.MEDIUM,
    },
    h6: {
      fontSize: 16,
      fontWeight: fontWeights.BOLD,
    },
    body1: {
      fontSize: 14,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.MEDIUM,
      overflow: "hidden",
    },
    body2: {
      fontSize: 16,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.LIGHT,
    },
    subtitle1: {
      fontSize: 18,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.MEDIUM,
    },
    subtitle2: {
      fontSize: 15,
      fontFamily: fonts.POPPINS,
      fontWeight: fontWeights.LIGHT,
    },
    caption: {
      fontSize: 15,
    },
    button: {
      fontWeight: fontWeights.BOLD,
      textTransform: "none",
    },
  },
  palette: {
    type: "dark",
    background: {
      default: colors.defaultBackground,
      paper: colors.paper,
    },
    text: {
      primary: colors.primaryText,
      secondary: colors.secondaryText,
    },
    primary: {
      main: colors.blurple,
      light: Color(colors.blurple).lighten(0.1).toString(),
      dark: Color(colors.blurple).darken(0.1).toString(),
    },
    secondary: {
      main: colors.gray,
      light: Color(colors.gray).lighten(0.1).toString(),
      dark: Color(colors.gray).darken(0.1).toString(),
    },
  },
  radius: {
    xs: "2px",
    s: "4px",
    m: "8px",
    l: "16px",
  },
  overrides: {
    MuiTypography: {
      root: {
        color: colors.primaryText,
      },
    },
    MuiDivider: {
      root: {
        height: "2px",
        color: "#242322",
        "&.thin": {
          height: "1px",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          boxSizing: "content-box",
        },
        ".web3modal-modal-lightbox": {
          zIndex: "9999 !important",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#fff",
        fontSize: "1.143rem",
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(0, 20px) scale(1)",
      },
      shrink: {
        color: "#fff",
      },
      filled: {
        transform: "translate(12px, 12px) scale(1)",
      },
      outlined: {
        transform: "translate(24px, 22px) scale(1)",
        "&$shrink": {
          transform: "translate(28px, -6px) scale(0.75)",
        },
      },
    },
    MuiInput: {
      input: {
        fontSize: "1.143rem",
        lineHeight: "1.5",
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid rgb(255, 255, 255)",
        },
      },
      multiline: {
        minHeight: "60px",
        fontWeight: "300",
        lineHeight: "1.25",
      },
      inputMultiline: {
        minHeight: "60px",
        fontWeight: "300",
        lineHeight: "1.25",
      },
    },
    MuiFilledInput: {
      input: {
        padding: "22px 12px 5px",
      },
      underline: {
        "&:after": {
          margin: "0px 14px 1px",
        },
      },
      root: {
        "&.rounded": {
          borderRadius: "20px",
        },
        "&:before": {
          borderBottom: "none !important",
        },
        backgroundColor: `${colors.paper} !important`,
        "&: svg": {
          fill: "rgba(255, 255, 255, .5)",
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1rem",
        marginLeft: "12px",
      },
    },
    MuiSvgIcon: {
      root: {
        fill: "#fff",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "white",
      },
    },
    MuiTabs: {
      fixed: {
        overflow: "visible",
        borderBottom: "1px solid #45464E",
      },
    },
    MuiPopover: {
      paper: {
        overflowX: "hidden",
        overflowY: "visible",
        padding: "0px 8px 8px 8px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$disabled svg": {
          fill: "rgba(150,150,150, 0.5)",
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "455px",
      },
    },
    MuiCardContent: {
      root: {
        "&.dark": {
          backgroundColor: colors.defaultBackground,
        },
        "&.b--rounded": {
          borderRadius: "0px 0px 8px 8px",
        },
        "&.steam": {
          minHeight: "215px",
        },
      },
    },
    MuiCardMedia: {
      root: {
        "&.t--rounded": {
          borderRadius: "8px 8px 0px 0px",
        },
        "&.steam": {
          paddingTop: "47%",
          backgroundSize: "contain",
        },
        "&.a--rounded": {
          borderRadius: "8px",
        },
      },
    },
    MuiGridList: {
      root: {
        "&.single": {
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          overflowY: "hidden",
          transform: "translateZ(0)",
        },
      },
    },
    // Tables
    MuiTableCell: {
      head: {
        color: "#fff",
      },
    },
  },
};
