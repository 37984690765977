import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Typography, ListItemIcon, MenuItem, MenuList, Paper } from "@material-ui/core";

const MenuText = ({ activeId, name, ...props }) => (
  <Typography
    variant="body2"
    className={`settings-page__menu__text${name === activeId ? "--active" : ""}`}
    {...props}
  />
);
const MenuIcon = ({ activeId, name, ...props }) => (
  <ListItemIcon
    className={`settings-page__menu__icon${name === activeId ? "--active" : ""}`}
    {...props}
  />
);

class SettingsMenu extends PureComponent {
  render() {
    const { menuItems, activeId } = this.props;
    return (
      <Paper className="settings-page__menu">
        <MenuList className="settings-page__menu-list">
          {menuItems.map((item) => (
            <MenuItem
              key={item.text}
              onClick={() => {
                if (!this.props.onClick) return;
                this.props.onClick(item.id);
              }}
              {...item.props}
            >
              {item.icon && (
                <MenuIcon activeId={activeId} name={item.id}>
                  {item.icon}
                </MenuIcon>
              )}
              <MenuText name={item.id} activeId={activeId}>
                {item.text}
              </MenuText>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    );
  }
}

SettingsMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SettingsMenu;
