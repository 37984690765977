import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../images/settings/close.svg";
import { SettingsClose, SettingsWrapper } from "./style";
import TabsBody from "./Tabs/TabsBody";

const Content = React.memo(() => (
  <SettingsWrapper>
    <Link to="/">
      <SettingsClose>
        <Close />
      </SettingsClose>
    </Link>

    <Typography variant="h2">Account Settings</Typography>

    <TabsBody />
  </SettingsWrapper>
));

export default Content;
