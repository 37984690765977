import styled from "styled-components";
import { button } from "../../../../../styles";

export const ReferralCodeWrapper = styled.div`
  width: 100%;
`;

export const Code = styled.div`
  padding: 40px 50px 40px;

  & div {
    display: flex;
    justify-content: space-between;
    border: 1px solid #61646d;
    border-radius: 8px;
    padding: 13px 16px;
  }

  @media (max-width: 600px) {
    padding: 57px 0 0;
    width: 80%;
    margin: auto;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 0px;
    width: 100%;
  }
`;

export const Blue = styled.span`
  color: #2b4bf2;
  cursor: pointer;
  font-size: 14px;
`;

export const ReferralLink = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 14px;
`;

export const ConceptWrapper = styled.div`
  padding: 8px;
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  padding-bottom: 16px;
`;

export const Description = styled.div`
  padding: 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ReadBlue = styled.div`
  padding: 8px 0;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: #ab99ff;
  background: -webkit-linear-gradient(#ab99ff, #6c5dd3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  cursor: pointer;
`;
export const TextField = styled.div`
  border: 1px solid #303847;
  border-radius: 8px;
  padding: 15px 17px;
  color: #fff;
  margin: 8px 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & label {
    margin: 0;
  }
`;
export const ConfirmCheckbox = styled(button)`
  background-color: inherit;
  padding: 0;
  display: flex;
  align-items: center;

  & > div {
    font-size: 16px;
    line-height: 21px;
    margin-left: 25px;
  }
`;
