import React from "react";
import { useSelector } from "react-redux";
import Steps from "../index";
import { ImgWrapper, Message, Name } from "../styles";
import img from "../../../../../images/signUp/step3/step3.png";

const Step4 = React.forwardRef(({ setActiveStep }, ref) => {
  const step = useSelector(({ Signup }) => Signup.activeStep);
  const length = useSelector(({ Signup }) => Signup.length);

  const changeStepHandler = (value) => {
    setActiveStep(value, value === "-");
  };

  return (
    <Steps
      ref={ref}
      step={step}
      length={length}
      setActiveStep={changeStepHandler}
      text="Get Started!"
      current={6}
    >
      <ImgWrapper>
        <img src={img} alt="tap project" />
      </ImgWrapper>

      <Name>Step 3. Play and Earn!</Name>

      <Message>
        Playing games allows you to earn GXP and get paid to play! Play top games on Steam
        and earn exclusive integrated game NFT’s !
      </Message>
    </Steps>
  );
});

export default Step4;
