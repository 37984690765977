import React from "react";
import styled from "styled-components";

const MaxLinesTextContainerOuter = styled.div`
  overflow: hidden;
`;

const MaxLinesTextContainer = React.memo(
  ({ styledComponent: StyledComponent, text, maxLines, ...rest }) => {
    return (
      <MaxLinesTextContainerOuter>
        <StyledComponent
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: maxLines,
          }}
          {...rest}
        >
          {text}
        </StyledComponent>
      </MaxLinesTextContainerOuter>
    );
  }
);

export default MaxLinesTextContainer;
