import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { ReactComponent as Close } from "images/close.svg";
import { useDispatch } from "react-redux";
import { openModal } from "reducers/redux/Modal/actions";
import { ActionsContainer, ClaimGamePointsContainer } from "./styles";
import { Button, ButtonClose, WhiteButton } from "../../../Modal/styles";
import ModalHeader from "../../../Modal/ModalBody";
import { useButtonStyles } from "../../../../styles";
import ModalBody from "./ModalBody";

const ClaimGamePoints = ({ setActiveTab, current }) => {
  const dispatch = useDispatch();
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const classes = useButtonStyles({
    disable: !(toValue && fromValue === toValue),
  });

  const closeHandler = () => {
    setActiveTab(0);
  };

  const CongratulationsHandler = () => {
    dispatch(
      openModal({
        header: `Congratulations`,
        successButton: "Close",
        values: [`${toValue} GXP has been claimed!`, "view Transaction ID"],
      })
    );
  };

  const handlerSuccess = () => {
    if (!(toValue && fromValue === toValue)) return;

    dispatch(
      openModal({
        header: `Are you sure`,
        closeButton: true,
        values: [
          `Are you sure you would like to claim ${toValue} GXP?`,
          "This process is irreversible and will open your cryptocurrency wallet.",
        ],
        successHandler: CongratulationsHandler,
      })
    );
  };

  return (
    <ClaimGamePointsContainer>
      <ButtonClose onClick={closeHandler}>
        <Close />
      </ButtonClose>

      <ModalHeader title="Claim Game Points" />

      <ModalBody
        fromValue={fromValue}
        setFromValue={setFromValue}
        toValue={toValue}
        setToValue={setToValue}
      />

      <ActionsContainer>
        <DialogActions>
          <WhiteButton onClick={closeHandler}>Close</WhiteButton>
          <Button onClick={handlerSuccess} className={classes.button}>
            Confirm
          </Button>
        </DialogActions>
      </ActionsContainer>
    </ClaimGamePointsContainer>
  );
};

export default ClaimGamePoints;
