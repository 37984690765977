import React from "react";
import { Grid, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { roundEtherBalance } from "blockchain/web3Utils";
import Text from "components/Text";

const BalanceCard = (props) => (
  <Paper className="wallet__balance-card border-default">
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Text variant="h4" color="inherit">
          Coins
        </Text>
      </Grid>
      <Grid item>
        <Grid container spacing={5} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="h1" color="inherit" className="mt--0">
              {roundEtherBalance(props.balance)}
            </Text>
          </Grid>
          <Grid item>
            <img
              src="https://img.tapplatform.io/stat/icons/tapcoin.svg"
              alt="Tapcoin"
              className="mt--16"
              width={64}
              height={64}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

BalanceCard.propTypes = { balance: PropTypes.number };
BalanceCard.defaultProps = { balance: 0 };

export default BalanceCard;
